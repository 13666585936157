import _ from 'lodash';

export const isArrayEqual = (x: any[], y: any[]) =>
  _(x)
    .xorWith(y, _.isEqual)
    .isEmpty();

export const reorder = (
  list: any[],
  startIndex: number,
  endIndex: number
): any[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const removeDuplicatesByKeyArray = (
  array: any[],
  key: string = 'id'
) => {
  const newArray: any[] = [];
  array.forEach(item => {
    if (!newArray.find(e => e[key] === item[key])) {
      newArray.push(item);
    }
  });
  return newArray;
};
