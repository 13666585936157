import { Icon, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BaseLayout from "../../components/BaseLayout/BaseLayout";
import { User } from "../../interfaces";
import { AppState, sagaMiddleware } from "../../store";
import { setPayPalTime } from "../../store/sagas/user";
import "./PayPalOrderConfirmationPage.scss";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface Props {
  user: User;
}

const PayPalOrderConfirmationPage: React.FC<Props> = ({ user }) => {
  const [isLoading, setIsLoading] = useState(true);
  let query = useQuery();
  let history = useHistory();

  useEffect(() => {
    if (!query.get("token")) {
      return history.push("/");
    }

    sagaMiddleware.run<any>(setPayPalTime, (err?: string) => {
      setIsLoading(false);

      if (!!err) {
        return history.push("/");
      }
    });
  }, [query.get("token")]);

  return (
    <BaseLayout title="BabyPage - Dashboard" pageTitle="Dashboard">
      <div className="container">
        {isLoading ? (
          <div className="col-12 d-flex justify-content-center py-3">
            <Spin
              indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
            />
          </div>
        ) : (
          <div className="row justify-content-center">
            <div className="container">
              <div className="py-3">
                <h2 className="dark-gray m-0" style={{ fontWeight: 400 }}>
                  Thank you! Your purchase has been processed.
                </h2>
                <p className="dark-gray mt-1">
                  We love our customers, and we hope you love BabyPage. If you
                  have any problems, please contact us at{" "}
                  <a href="mailto:support@babypage.com">support@babypage.com</a>.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </BaseLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  user: state.user,
});

export default connect(mapStateToProps)(PayPalOrderConfirmationPage);
