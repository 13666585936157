import React from "react";

function Warning() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100.333"
      height="100"
      version="1"
      viewBox="0 0 679 577"
    >
      <path
        d="M3302 5714c-126-34-153-66-373-431-106-175-206-343-224-373-37-64-167-279-200-330-12-19-71-116-129-215-118-197-171-285-731-1216-209-348-410-682-447-743s-73-122-81-136c-26-46-669-1114-692-1150-12-19-71-117-131-217C3 416 35 482 35 365c0-86 3-106 24-145 30-58 86-116 136-142 96-50-69-48 3206-48 2855 0 3070 1 3120 17 73 23 118 52 167 109 43 48 82 146 82 206 0 40-20 117-39 151-29 51-533 892-550 917-13 20-528 876-616 1023-93 157-214 358-293 487-52 85-106 175-120 200-13 25-34 61-47 80-40 62-168 273-199 330-16 30-33 57-36 60-4 3-15 20-25 39-9 18-57 99-106 180-49 80-93 153-98 161-75 128-900 1497-938 1556-61 95-126 145-218 169-71 18-113 18-183-1zm287-1279c71-30 74-43 66-349-3-144-10-513-16-821-19-1107-29-1580-34-1596-12-36-82-54-210-54-140 0-188 13-203 53-7 16-17 366-27 897-9 479-20 1079-25 1334-6 329-5 470 2 487 13 28 51 54 93 65 16 4 93 7 170 5 110-2 149-6 184-21zm-33-3180c58-17 106-62 125-120 18-54 19-296 1-348-17-48-54-92-95-112-69-35-242-42-347-15-54 14-106 63-124 116-35 104-30 317 10 395 22 43 70 77 126 88 29 6 62 13 73 15 35 9 179-3 231-19z"
        transform="matrix(.1 0 0 -.1 0 577)"
        fill="#1c2e5e"
      ></path>
    </svg>
  );
}

export default Warning;