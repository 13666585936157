import { Col, Row } from "antd";
import React from "react";
import AdminMilestoneFormContainer from "../../../../components/AdminMilestoneForm/AdminMilestoneFormContainer";

const MilestoneNewPage: React.FC = () => {
  return (
    <div className="m-3">
      <h1 className="title">You're creating a new Milestone</h1>
      <Row>
        <Col span={14} offset={5}>
          <AdminMilestoneFormContainer milestoneId={0} />
        </Col>
      </Row>
    </div>
  );
};

export default MilestoneNewPage;
