import {call, select} from "redux-saga/effects";
import {AppState} from "..";
import api from "../../api";
import {GuestUserInterface} from "../../interfaces/guestUser";
import {parseRequestError} from "../../utils/error";

/**
 * Create Guest User
 */
export function* createGuestUser(
  data: GuestUserInterface,
  callback: (err?: string) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    yield call(
      api.post,
      "api/guest-users?lang=en",
      data,
      {
        headers: {
          Authorization: accessToken
        }
      }
    );

    callback(undefined);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * Get Guest Users
 */
export function* getGuestUsers(
  callback: (err: string | null, data?: any[]) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    const response = yield call(
      api.get,
      "api/guest-users?lang=en",
      {
        headers: {
          Authorization: accessToken
        }
      }
    );

    callback(null, response.data.data);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * Delete Guest Users By ID
 */
export function* deleteGuestUser(
  id: number,
  callback: (err: string | null) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    yield call(
      api.delete,
  `api/guest-users/${id}?lang=en`,
      {
        headers: {
          Authorization: accessToken
        }
      }
    );

    callback(null);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * Update Guest Users
 */
export function* updateGuestUser(
  id: number,
  data: GuestUserInterface,
  callback: (err: string | null) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    yield call(
      api.put,
      `api/guest-users/${id}?lang=en`,
      data,
      {
        headers: {
          Authorization: accessToken
        }
      }
    );

    callback(null);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * Create Guest User
 */
export function* validateInvitationCode(
  data: any,
  callback: (err: string | null, data?: any) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    const response = yield call(
      api.put,
      "api/guest-users/invitation-code?lang=en",
      data,
      {
        headers: {
          Authorization: accessToken
        }
      }
    );

    callback(null, response.data.data);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * Get Guest Users
 */
export function* getGuestUserInfo(
  invitationCode: string,
  callback: (err: string | null, data?: any) => void
) {
  if (!invitationCode) return;

  try {
    const response = yield call(
      api.get,
      `api/guest-user-info/${invitationCode}?lang=en`);

    callback(null, response.data.data);
  } catch (error) {
    callback(parseRequestError(error));
  }
}