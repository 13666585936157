import { Icon, Modal, Spin } from "antd";
import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import moment from "moment";
import BaseLayout from "../../components/BaseLayout/BaseLayout";
import BabyAge from "../../components/Dashboard/Babyage";
import Babypages from "../../components/Dashboard/Babypages";
import GrowthChart from "../../components/Dashboard/GrowthChart";
import RecentMilestones from "../../components/Dashboard/RecentMilestones";
import { useWindowSize } from "../../hooks/WindowsSize";
import { Baby, DashboardInfo, User } from "../../interfaces";
import { AppState, sagaMiddleware } from "../../store";
import { DashboardState } from "../../store/ducks/dashboard";
import { fetchDashboard } from "../../store/sagas/dashboard";
import { createGuestCustomer } from "../../store/sagas/customer";
import { useLastLocation } from "react-router-last-location";
import AddChildInfoModal from "../../components/AddChildInfoModal/AddChildInfoModal";
import UpdateBirthModal from "../../components/UpdateBirthModal/UpdateBirthModal";
import logoImg from "../../assets/img/logo_2.png";
import "./DashboardPage.scss";
import ManageUsers from "../../components/Dashboard/ManageUsers";
import BabyCarousel from "../../components/Dashboard/BabyCarousel";
import { useManageModal } from "../../hooks/useManageModal";
import { MilestoneState } from "../../store/ducks/milestones";

interface Props {
  user_level?: number;
  babySelected?: Baby;
  dashboard: DashboardState;
  user: User;
  milestones: MilestoneState;
}

const DashboardPage: React.FC<Props> = ({
  user_level,
  babySelected,
  dashboard,
  user,
  milestones
}) => {
  const [width] = useWindowSize();
  const lastLocation = useLastLocation();
  const [showModalExtraInfo, onModalExtraInfo] = useManageModal();
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showModalInfo, setShowModalInfo] = useState(false);
  const babyDueDate = babySelected?.baby_duedate;
  const isDueDate =
    babyDueDate &&
    moment(babyDueDate).diff(moment().format("YYYY-MM-DD"), "days") > 0
      ? true
      : false;
  const hasBorn = !!babySelected?.baby_hasborn
    ? !!babySelected?.baby_hasborn
    : false;
  useEffect(() => {
    if (!hasBorn && !isDueDate && !!babySelected) {
      setShowModalUpdate(true);
    } else {
      setShowModalUpdate(false);
    }
  }, [babySelected?.baby_birthdate]);

  useEffect(() => {
    if (!!lastLocation && lastLocation.pathname === "/step-3" && !isDueDate) {
      onModalExtraInfo(true);
    } else if (
      !!lastLocation &&
      lastLocation.pathname === "/step-3" &&
      isDueDate
    ) {
      setShowModalInfo(true);
    }

    sagaMiddleware.run<any>(fetchDashboard, (error: string | null) => {
      if (error) {
        Modal.error({
          title: "Error",
          content: error,
        });
      }
    });
  }, [lastLocation]);

  useEffect(() => {
    if (!!user.main_user && !user.main_user.stripe_id) {
      sagaMiddleware.run<any>(createGuestCustomer, (error: string | null) => {
        if (error !== null) {
          console.error(error);
        }
      });
    }
  }, [user]);

  const baby: DashboardInfo | undefined = useMemo(() => {
    if (dashboard && dashboard.dashboard.length && babySelected) {
      return dashboard.dashboard.find((d) => d.id === babySelected.id);
    }
    return undefined;
  }, [dashboard, babySelected]);

  const cardDividerClass = !baby?.baby_hasborn ? "dashboard-card-border" : "";

  return (
    <BaseLayout
      title="BabyPage - Dashboard"
      pageTitle="Dashboard"
      pageStyle={
        width > 767
          ? {
              backgroundColor: "#f0f0f0",
            }
          : { backgroundColor: "white" }
      }
    >
      <div
        className={`${width > 1200 ? "container" : "mx-3"} ${
          width > 767 ? "my-3" : ""
        }`}
      >
        {(dashboard.loading || milestones.loading ) && (
          <div className="col-12 d-flex justify-content-center">
            <Spin
              indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
            />
          </div>
        )}
        {(!!baby && !milestones.loading) &&  (
          <>
            <UpdateBirthModal
              visible={showModalUpdate && !(user.permission === "Read-Only")}
              close={() => {
                setShowModalUpdate(false);
              }}
              closeAndShowSecondModal={() => {
                setShowModalUpdate(false);
              }}
              babySelected={babySelected}
            />
            <div className="baby-carousel">
              <div className="row">
                <div className="col-12" style={{ backgroundColor: "#f0f0f0" }}>
                  <BabyCarousel />
                </div>
              </div>
            </div>
            {width > 767 ? (
              <div
                className={`row justify-content-center ${
                  width > 1024 ? "no-gutters" : ""
                }`}
              >
                <div className="col-6">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-11 col-dashboard">
                      <Babypages
                        baby={baby}
                        isGuestUser={user.guest_user}
                        hasFullAccess={user.permission === "Full Access"}
                      />
                    </div>

                    <div className="col-11 col-dashboard mt-3">
                      <BabyAge baby={baby} />
                    </div>

                    <div className="col-11 col-dashboard mt-3">
                      {!user.guest_user && (
                        <ManageUsers baby={baby}></ManageUsers>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-11 col-dashboard">
                      {!user.guest_user || user.permission === "Full Access" ? (
                        <RecentMilestones baby={baby} />
                      ) : (
                        <GrowthChart
                          baby={baby}
                          isGuestUser={user.guest_user}
                        />
                      )}
                    </div>

                    {(!user.guest_user ||
                      user.permission === "Full Access") && (
                      <div className="col-11 col-dashboard mt-3">
                        <GrowthChart
                          baby={baby}
                          isGuestUser={user.guest_user}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="row justify-content-center align-items-center">
                {!baby.baby_hasborn ? (
                  <div className={`col-12 col-dashboard`}>
                    <BabyAge baby={baby} />
                  </div>
                ) : (
                  <div
                    className="col-12 col-dashboard col-lg-6"
                    style={{ borderBottom: "2px solid #d8d8d8" }}
                  >
                    <Babypages
                      baby={baby}
                      isGuestUser={user.guest_user}
                      hasFullAccess={user.permission === "Full Access"}
                    />
                  </div>
                )}

                {(!user.guest_user || user.permission === "Full Access") && (
                  <div
                    className={`col-12 col-lg-6 col-dashboard ${cardDividerClass}`}
                  >
                    {!baby.baby_hasborn ? (
                      <Babypages
                        baby={baby}
                        isGuestUser={user.guest_user}
                        hasFullAccess={user.permission === "Full Access"}
                      />
                    ) : (
                      <RecentMilestones baby={baby} />
                    )}
                  </div>
                )}

                {!baby.baby_hasborn ? (
                  <div
                    className="col-12 col-dashboard col-lg-6"
                    style={{ borderBottom: "2px solid #d8d8d8" }}
                  >
                    <RecentMilestones baby={baby} />
                  </div>
                ) : (
                  <div className={`col-12 col-dashboard`}>
                    <BabyAge baby={baby} />
                  </div>
                )}
                {!user.guest_user && (
                  <div
                    className={`col-12 col-dashboard`}
                    style={{ borderBottom: "2px solid #d8d8d8" }}
                  >
                    <ManageUsers baby={baby}></ManageUsers>
                  </div>
                )}

                <div className="col-12 col-lg-6 col-dashboard">
                  <GrowthChart baby={baby} isGuestUser={user.guest_user} />
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <AddChildInfoModal
        visible={showModalExtraInfo}
        close={() => {
          onModalExtraInfo(false);
        }}
        closeAndShowSecondModal={() => {
          onModalExtraInfo(false);
          setShowModalInfo(true);
        }}
        babySelected={babySelected}
      />

      <Modal
        centered
        visible={showModalInfo}
        className="popup-info"
        closable={true}
        okButtonProps={{ style: { display: "none" } }}
        cancelText="OK"
        cancelButtonProps={{
          htmlType: "button",
          className: "ant-btn-primary",
          style: { width: "100px" },
        }}
        onCancel={() => setShowModalInfo(false)}
      >
        <div>
          <div className="img-wrapper">
            <img src={logoImg} alt="Plus Child" className="mx-auto d-table" />
          </div>

          <p className="text-center m-0 mt-5">
            To complete your child's profile later, simply go to My Account and
            select Manage Children in the top navigation.
          </p>
        </div>
      </Modal>
    </BaseLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  user_level: state.user.user_level,
  babySelected: state.baby.babySelected,
  dashboard: state.dashboard,
  user: state.user,
  milestones: state.milestones
});

export default connect(mapStateToProps)(DashboardPage);
