import React from "react";

interface Props {
  classes?: string;
}
const Facebook: React.FC<Props> = ({
classes
}) => {
  return (
    <a href="https://www.facebook.com/gobabypage" target="_blank">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 256 256"
        className={classes}
      >
        <path
          fill="#1a1a1a"
          strokeMiterlimit="10"
          d="M15 3C8.373 3 3 8.373 3 15s5.373 12 12 12 12-5.373 12-12S21.627 3 15 3zm4.181 8h-1.729C16.376 11 16 11.568 16 12.718V14h3.154l-.428 3H16v7.95a10.057 10.057 0 01-3-.151V17h-3v-3h3v-1.611C13 9.339 14.486 8 17.021 8c1.214 0 1.856.09 2.16.131z"
          fontFamily="none"
          fontSize="none"
          fontWeight="none"
          textAnchor="none"
          transform="scale(8.53333)"
        ></path>
      </svg>
    </a>
  );
}

export default Facebook;