import {
  Button,
  DatePicker,
  TimePicker,
  Icon,
  Input,
  InputNumber,
  Modal,
  notification,
  Select,
  Spin,
} from "antd";
import Form, { FormComponentProps } from "antd/lib/form";
import moment, { Moment } from "moment";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Baby } from "../../interfaces";
import { AppState, sagaMiddleware } from "../../store";
import { getBabyStorage, saveChild } from "../../store/sagas/baby";
import { trackAction } from "../../utils/marketing";
import { User } from "../../interfaces";
import logoImg from "../../assets/img/logo_2.png";
import "./SaveChild.scss";
const { Option } = Select;

function hasErrors(fieldsError: Record<string, string[] | undefined>) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

type Props = FormComponentProps<any> &
  RouteComponentProps<any, any, any> & {
    lastBaby: Function; // From Redux
    afterSave: () => void;
    step?: boolean;
    showDelete?: boolean;
    onDelete?: () => void;
    hideCustomDivider?: boolean;
    id?: string;
    shortForm?: boolean;
    extraInfo?: boolean;
    user?: User;
    updateBirthDate: boolean;
    babySelected?: Baby | undefined
    addChild?: boolean;
  };

class SaveChild extends React.PureComponent<Props> {
  state = {
    loading: false,
    height_id: undefined,
    weight_id: undefined,
    isDueDate: false,
    showModalDueDate: false,
    birth_time: undefined,
    has_born: false,
    hasDate: false,
    updateBirthError: false,
  };

  componentDidMount() {
    if (!!this.props.babySelected && !!this.props.id) {
      this.searchBabyId(this.props.id.toString());
    } else {
      this.props.form.validateFields();
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (!!prevProps && !!this.props.id && !!this.props.babySelected && this.props.babySelected.id !== prevProps.babySelected.id ) {
      !this.props.user?.guest_user && this.searchBabyId(this.props.id.toString());
    }
  }

  searchBabyId = (id: string) => {
    sagaMiddleware.run<any>(
      getBabyStorage,
      parseInt(id),
      (baby: Baby | undefined) => {
        if (!!baby) {
          const birthHeight =
            !!baby.heights && !!baby.heights.length ? baby.heights[0] : null;

          let height = !!birthHeight ? parseFloat(birthHeight.height) : null;
          if (height !== null && isNaN(height)) {
            height = null;
          }

          const birthWeight =
            !!baby.weights && !!baby.weights.length ? baby.weights[0] : null;

          let weight_lbs = !!birthWeight
            ? ~~(parseInt(birthWeight.oz) / 16)
            : null;
          if (weight_lbs !== null && isNaN(weight_lbs)) {
            weight_lbs = null;
          }

          let weight_oz = !!birthWeight ? parseInt(birthWeight.oz) % 16 : null;
          if (weight_oz !== null && isNaN(weight_oz)) {
            weight_oz = null;
          }

          this.setState({
            height_id: !!birthHeight ? birthHeight.id : undefined,
            weight_id: !!birthWeight ? birthWeight.id : undefined,
          });
          let date = `${baby.baby_birthdate.slice(0, 10)} ${baby.baby_birth_time}`

          this.setState({
            has_born: baby.baby_hasborn
          })

          let dateNow = new Date();
          let dateTest = moment(baby.baby_birthdate.slice(0, 10))
          let futureBirth = undefined ;

          if (dateTest.toDate() > dateNow && baby.baby_birthdate.slice(0,10) !== baby.baby_duedate) {
            futureBirth = dateTest
          }



          if(!baby.baby_hasborn) {
            this.props.form.setFieldsValue({
              baby_gender: baby.baby_gender,
              baby_first_name: baby.baby_first_name,
              baby_middle_name: baby.baby_middle_name,
              baby_last_name: baby.baby_last_name,
              baby_birthplace: baby.baby_birthplace,
              height,
              weight_lbs, 
              weight_oz,
               baby_birthtime: !!baby.baby_birth_time
               ? moment(date)
               : undefined,
               baby_duedate: !!baby.baby_duedate
                              ? moment(baby.baby_duedate.slice(0, 10))
                              : undefined,
               baby_birthdate: !!futureBirth ? futureBirth : undefined
            });
            this.setState({
              isDueDate : true
            })

          } else  {
            this.setState({
              isDueDate: false
            })
            this.props.form.setFieldsValue({
              baby_gender: baby.baby_gender,
              baby_first_name: baby.baby_first_name,
              baby_middle_name: baby.baby_middle_name,
              baby_last_name: baby.baby_last_name,
              baby_birthdate: !!baby.baby_birthdate
                ? moment(baby.baby_birthdate.slice(0, 10))
                : moment(),
              baby_birthplace: baby.baby_birthplace,
              height,
              weight_lbs,
              weight_oz,
               baby_birthtime: !!baby.baby_birth_time
               ? moment(date)
               : undefined,
               baby_duedate: !!baby.baby_duedate
                              ? moment(baby.baby_duedate.slice(0, 10))
                              : undefined,
            });
          }
        } else {
          Modal.error({
            title: "Error",
            content: "Baby not found",
          });
          this.props.history.push("/");
        }
      }
    );
  };

  validateWithOz = (_rule: any, value: any, callback: any) => {
    const { form } = this.props;
    if (!value && !form.getFieldValue("weight_lbs")) {
      callback("Weight need to have Lbs or Oz greater than 0");
    } else {
      if (
        !form.getFieldValue("weight_lbs") &&
        form.getFieldError("weight_lbs")
      ) {
        form.validateFields(["weight_lbs"], { force: true });
      }
      callback();
    }
  };

  validateWithLbs = (_rule: any, value: any, callback: any) => {
    const { form } = this.props;
    if (!value && !form.getFieldValue("weight_oz")) {
      callback("Weight need to have Lbs or Oz greater than 0");
    } else {
      if (!form.getFieldValue("weight_oz") && form.getFieldError("weight_oz")) {
        form.validateFields(["weight_oz"], { force: true });
      }
      callback();
    }
  };

  // On Submit (Sign in or Sign up)
  onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { isDueDate } = this.state;

   if (this.props.step) {
    this.props.form.validateFields((err: any, values: any) => {
      if (err === null) {
        this.setState({ loading: true });
        
        
        let date = values.baby_birthdate.toDate();
        let birthDate = values.baby_birthdate;
        let dateNow = new Date();
        if (date > dateNow) {
          this.setState({isDueDate: true})
        }

        if (isDueDate) {
          const { baby_gender, baby_first_name, baby_last_name } = values;
          const babyGender =
            !baby_gender || baby_gender === 2 ? 0 : baby_gender;
          const babyFirstName = baby_first_name || "Baby";
          const babyLastName = baby_last_name || this.props.user?.last_name;

          values.baby_gender = babyGender;
          values.baby_first_name = babyFirstName;
          values.baby_last_name = babyLastName;
          values.baby_birthdate = null;
          values.baby_duedate = birthDate
        }
        sagaMiddleware.run<any>(
          saveChild,
          {
            ...values,
            birth_time: null,
            id: !!this.props.id ? parseInt(this.props.id) : undefined,
            height_id: this.state.height_id ?? -1,
            weight_id: this.state.weight_id ?? -1,
          },
          (error?: any, description?: string) => {
            this.setState({ loading: false });
            if (!!error) {
              Modal.error({
                title: "Error",
                content: error,
              });
              return;
            } else if (!!description) {
            if(!this.props.id) { 
              trackAction(
                "AddChild",
                {
                  facebook: {
                    action: "User added a child",
                  },
                  amplitude: {
                    action: "User added a child",
                  },
                  pinterest: {
                    action: "User added a child",
                  },
                  google: {
                    event: "add_child",
                  },
                },
                true
              );
            }
              notification.success({
                message: "Success",
                description,
              });
            }
            this.props.afterSave();
          }
        );
      }
    });

   } else {
    this.props.form.validateFields((err: any, values: any) => {
      if (err === null) {
        this.setState({ loading: true });
        let time = "";
     
        if(this.state.birth_time) {
          time = values.baby_birthtime.format("hh:mm A");
        }
        let date = !!values.baby_birthdate ? values.baby_birthdate.toDate() : values.baby_duedate.toDate();
        let dateNow = new Date();
        if (date > dateNow) {
          this.setState({isDueDate: true})
        }

        if(this.props.updateBirthDate && date > dateNow) {
          values.baby_duedate = values.baby_birthdate
        }

        if (isDueDate) {
          const { baby_gender, baby_first_name, baby_last_name } = values;
          const babyGender =
            !baby_gender || baby_gender === 2 ? 0 : baby_gender;
          const babyFirstName = baby_first_name || "Baby";
          const babyLastName = baby_last_name || this.props.user?.last_name;

          values.baby_gender = babyGender;
          values.baby_first_name = babyFirstName;
          values.baby_last_name = babyLastName;
        }
        sagaMiddleware.run<any>(
          saveChild,
          {
            ...values,
            has_born:this.props.updateBirthDate || date <= dateNow ? date <= dateNow : this.state.has_born,
            birth_time: time !== "" ? time : null,
            id: !!this.props.id ? parseInt(this.props.id) : undefined,
            height_id: this.state.height_id ?? -1,
            weight_id: this.state.weight_id ?? -1,
          },
          (error?: any, description?: string) => {
            this.setState({ loading: false });
            if (!!error) {
              Modal.error({
                title: "Error",
                content: error,
              });
              return;
            } else if (!!description) {
            if(!this.props.id) { 
              trackAction(
                "AddChild",
                {
                  facebook: {
                    action: "User added a child",
                  },
                  amplitude: {
                    action: "User added a child",
                  },
                  pinterest: {
                    action: "User added a child",
                  },
                  google: {
                    event: "add_child",
                  },
                },
                true
              );
            }
              notification.success({
                message: "Success",
                description,
              });
            }
            this.props.afterSave();
          }
        );
      } else if(this.props.updateBirthDate) {
        this.setState({updateBirthError: true})
      }
    });
   }  
  };

  render() {
    const {
      getFieldsError,
      getFieldError,
      getFieldDecorator,
      isFieldTouched,
    } = this.props.form;
    const { isDueDate, showModalDueDate, hasDate } = this.state;
    const genderError =
      isFieldTouched("baby_gender") && getFieldError("baby_gender");
    const firstNameError =
      isFieldTouched("baby_first_name") && getFieldError("baby_first_name");
    const lastNameError =
      isFieldTouched("baby_last_name") && getFieldError("baby_last_name");
    const birthDateError =
      isFieldTouched("baby_birthdate") && getFieldError("baby_birthdate");
      const dueDateError =
      isFieldTouched("baby_duedate") && getFieldError("baby_duedate");
    const babyHeightError = !!this.props.step
      ? isFieldTouched("height") && getFieldError("height")
      : getFieldError("height");
    const babyWeightLbsError = !!this.props.step
      ? isFieldTouched("weight_lbs") && getFieldError("weight_lbs")
      : getFieldError("weight_lbs");
    const babyWeightOzError = !!this.props.step
      ? isFieldTouched("weight_oz") && getFieldError("weight_oz")
      : getFieldError("weight_oz");

    return this.state.loading ? (
      <div className="col-12 text-center mb-5">
        <Spin
          indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
        />
      </div>
    ) : (
      <>
        <Form onSubmit={this.onSubmit} className="w-100 add-child-form">
          <>
            <div
              className={`container ${
                !this.props.extraInfo ? "d-block" : "d-none"
              }`}
            >
              <div className="row justify-content-center align-items-center">                            
                <div className={`${this.props.shortForm ? "col-12 col-md-6" : "col-12 col-md-4" } ${this.props.updateBirthDate ? "d-none" : "d-block"}`}>
                  {this.props.step ? (
                     <Form.Item
                     validateStatus={birthDateError ? "error" : ""}
                     help={birthDateError || ""}
                     extra={this.props.shortForm ? "Birth Date / Due Date" : "Birth Date" }
                   >
                     {getFieldDecorator("baby_birthdate", {
                       rules: [
                         {
                           required: this.props.shortForm ? true : hasDate ? false : true,
                           message: "This field is required.",
                         },
                       ],
                     })(
                       <DatePicker
                         format={"MM/DD/YYYY"}
                         className="w-100 baby-date-picker"
                         disabledDate={(current: Moment | null) =>
                           current !== null &&
                           current > moment().add(1, "years").endOf("day")
                         }
                         style={{marginTop: "0.30rem"}}
                         onChange={momentDate => {
                           if (momentDate) {
                           let date = momentDate.toDate();
                           let dateNow = new Date();
                           if (date > dateNow) {
                             this.setState({isDueDate: true, showModalDueDate: true, hasDate: true})
                           } else {
                             this.setState({isDueDate: false, hasDate: true})
                           }
                         }
                         }}
                 
                       />
                     )}
                   </Form.Item>
                  ) : (
                    <>
                    <span className="child-input-label">First Name</span>
                    <Form.Item
                    validateStatus={firstNameError && !isDueDate ? "error" : ""}
                    help={firstNameError && !isDueDate ? firstNameError : ""}
                  >
                    {getFieldDecorator("baby_first_name", {
                      rules: [
                        {
                          required: isDueDate ? false : true,
                          message: "This field is required.",
                        },
                      ],
                    })(
                      <Input
                        className="w-100"
                        maxLength={30}
                        placeholder="First Name"
                      />
                    )}
                  </Form.Item>
                  </>
                  )}                
                </div>

                <div
                  className={`col-12 col-md-4 ${
                    !this.props.shortForm && !this.props.updateBirthDate ? "d-block" : "d-none"
                  }`}
                >
                  <span className="child-input-label">Middle Name</span>
                  <Form.Item >
                    {getFieldDecorator("baby_middle_name")(
                      <Input className="w-100" maxLength={30} />
                    )}
                  </Form.Item>
                </div>
                <div className={`${this.props.shortForm ? "col-12 col-md-6" : "col-12 col-md-4" } ${this.props.updateBirthDate ? "d-none" : "d-block"}`}>
                  {this.props.step ? (
                    <Form.Item
                    validateStatus={genderError ? "error" : ""}
                    help={genderError || ""}
                    extra="Child's Gender"
                  >
                    {getFieldDecorator("baby_gender", {
                      rules: [
                        {
                          required: isDueDate ? false : true,
                          message: "This field is required.",
                        },
                      ],
                    })(
                      <Select className="w-100" placeholder="I have a">
                        {isDueDate ? <Option value={2}>&nbsp;</Option> : ""}
                        <Option value={0}>Daughter</Option>
                        <Option value={1}>Son</Option>
                      </Select>
                    )}
                  </Form.Item>
                  ) : (
                    <>
                    <span className="child-input-label">Last Name</span>
                    <Form.Item
                    validateStatus={lastNameError && !isDueDate ? "error" : ""}
                    help={lastNameError && !isDueDate ? lastNameError : ""}
                  >
                    {getFieldDecorator("baby_last_name", {
                      rules: [
                        {
                          required: isDueDate ? false : true,
                          message: "This field is required.",
                        },
                      ],
                    })(
                      <Input
                        className="w-100"
                        maxLength={30}
                        placeholder={
                          "Last Name"
                        }
                      />
                    )}
                  </Form.Item>
                  </>
                  )}       
                </div>
                <div className={`col-12 col-md-4 ${this.props.updateBirthDate || this.props.step ? "d-none" : "d-block"}`}>
                  <span className="child-input-label">Gender</span>
                <Form.Item
                    validateStatus={genderError ? "error" : ""}
                    help={genderError || ""}                   
                  >
                    {getFieldDecorator("baby_gender", {
                      rules: [
                        {
                          required: isDueDate ? false : true,
                          message: "This field is required.",
                        },
                      ],
                    })(
                      <Select className="w-100" placeholder="I have a">
                        {isDueDate ? <Option value={2}>&nbsp;</Option> : ""}
                        <Option value={0}>Daughter</Option>
                        <Option value={1}>Son</Option>
                      </Select>
                    )}
                  </Form.Item>
                </div>

              <div className={this.props.shortForm ? "col 12 cold-md-6": "col 12 cold-md-4"}>
              {this.props.step ? (
                     <Form.Item
                     validateStatus={firstNameError && !isDueDate ? "error" : ""}
                     help={firstNameError && !isDueDate ? firstNameError : ""}
                     extra="Child's First Name"
                   >
                     {getFieldDecorator("baby_first_name", {
                       rules: [
                         {
                           required: isDueDate ? false : true,
                           message: "This field is required.",
                         },
                       ],
                     })(
                       <Input
                         className="w-100"
                         maxLength={30}
                         placeholder="First Name"
                       />
                     )}
                   </Form.Item>
                  ) : this.props.updateBirthDate ? (
                    <Form.Item
                    validateStatus={this.state.updateBirthError ? "error" : ""}
                    help={this.state.updateBirthError  ? "Please select a valid date." : ""}
                    extra={this.props.shortForm ? "Birth Date / Due Date" : "Birth Date" }
                  >
                    {getFieldDecorator("baby_birthdate", {
                      rules: [
                        {
                          required:this.props.updateBirthDate,
                          message: "This field is required.",
                        },
                      ],
                    })(
                      <DatePicker
                        format={"MM/DD/YYYY"}
                        className="w-100 baby-date-picker"
                        disabledDate={(current: Moment | null) =>
                          current !== null &&
                          current > moment().add(1, "years").endOf("day")
                        }
                        style={{marginTop: "0.30rem"}}
                        onChange={momentDate => {
                          if (momentDate) {
                          let date = momentDate.toDate();
                          let dateNow = new Date();
                          if (date > dateNow) {
                            this.setState({isDueDate: true, showModalDueDate: true, hasDate: true})
                          } else {
                            this.setState({isDueDate: false, hasDate: true})
                          }
                        }
                        }}
                
                      />
                    )}
                  </Form.Item>
                 
                  ) : (   <>
                    <span className="child-input-label">Due Date</span>
                    <Form.Item
                    validateStatus={dueDateError ? "error" : ""}
                    help={dueDateError || ""}
                  >
                    {getFieldDecorator("baby_duedate", {
                      rules: [
                        {
                          required: false,
                          message: "This field is required."
                        },
                      ],
                    })(
                      <DatePicker
                        format={"MM/DD/YYYY"}
                        className="w-100"
                        disabledDate={(current: Moment | null) =>
                          current !== null &&
                          current > moment().add(1, "years").endOf("day")
                        }
                        style={{marginTop: "0.30rem"}}
                        onChange={momentDate => {
                          if (momentDate) {
                          let date = momentDate.toDate();
                          let dateNow = new Date();
                          if (date > dateNow) {
                            this.setState({isDueDate: true, showModalDueDate: true, hasDate: true})
                          } else {
                            this.setState({isDueDate: false, hasDate: true})
                          }
                        }
                        }}
                
                      />
                    )}
                  </Form.Item>
                  </>)}
                </div>
              
                {this.props.step || this.props.updateBirthDate  ? null : (
                   <div className="col-12 col-md-4">
                   {this.props.shortForm ? null : <span className="child-input-label">Birth Date</span>}
                      <Form.Item
                    validateStatus={birthDateError ? "error" : ""}
                    help={birthDateError || ""}
                    extra={this.props.shortForm ? "Birth Date / Due Date" : "" }
                  >
                    {getFieldDecorator("baby_birthdate", {
                      rules: [
                        {
                          required: this.props.shortForm ? true : hasDate ? false : isDueDate ? false : true,
                          message: "This field is required.",
                        },
                      ],
                    })(
                      <DatePicker
                        format={"MM/DD/YYYY"}
                        className="w-100 baby-date-picker"
                        disabledDate={(current: Moment | null) =>
                          current !== null &&
                          current > moment().add(1, "years").endOf("day")
                        }
                        style={{marginTop: "0.30rem"}}
                        onChange={momentDate => {
                          if (momentDate) {
                          let date = momentDate.toDate();
                          let dateNow = new Date();
                          if (date > dateNow) {
                            this.setState({isDueDate: true, showModalDueDate: true, hasDate: true})
                          } else {
                            this.setState({isDueDate: false, hasDate: true})
                          }
                        }
                        }}
                      />
                    )}
                  </Form.Item>
                 </div>
                 ) }

                {this.props.step || this.props.updateBirthDate ? null : (
                   <div className="col-12 col-md-8">
                    <span className="child-input-label">Birthplace</span>
                   <Form.Item
                     validateStatus={lastNameError && !isDueDate ? "error" : ""}
                     help={lastNameError && !isDueDate ? lastNameError : ""}
                   >
                     {getFieldDecorator("baby_birthplace", {
                       rules: [
                         {
                           required: isDueDate ? false : true,
                           message: "This field is required.",
                         },
                       ],
                     })(
                       <Input
                         className="w-100"
                         maxLength={30}
                         placeholder={
                           ""
                         }
                         style={{textTransform: "capitalize"}}
                       />
                     )}
                   </Form.Item>
                   </div>
                )}  

                <div className={` ${this.props.step ? "col-12 col-md-6" : this.state.isDueDate ? "col-12 col-md-4" : "col-md-4 col-6"} ${this.props.updateBirthDate ? "d-none" : "d-block"}`}>
                {this.props.step ? (
                     <Form.Item
                     validateStatus={lastNameError && !isDueDate ? "error" : ""}
                     help={lastNameError && !isDueDate ? lastNameError : ""}
                     extra="Child's Last Name"
                   >
                     {getFieldDecorator("baby_last_name", {
                       rules: [
                         {
                           required: isDueDate ? false : true,
                           message: "This field is required.",
                         },
                       ],
                     })(
                       <Input
                         className="w-100"
                         maxLength={30}
                         placeholder={
                           "Last Name"
                         }
                       />
                     )}
                   </Form.Item>
                  ) : (
                    <>
                    <span className="child-input-label">Birth Time</span>
                    <Form.Item>
                    {getFieldDecorator("baby_birthtime", {
                      rules: [
                        {
                          required: false,
                          message: "This field is required.",
                        },
                      ],
                    })(
                      
                      <TimePicker use12Hours  className="w-100" format={'hh:mm A'} onChange={(e) => {
                        this.setState({birth_time:e})
                      }}           
                       />
                    )}
                    </Form.Item>
                    </>
                  )}
                </div>
                <div className={`col-md-4 col-6 ${this.state.isDueDate || this.props.step || this.props.updateBirthDate ? "d-none" : "d-block"}`}>
                    <span className="child-input-label">
                    Birth Height (in)
                    </span>
                    <Form.Item
                      validateStatus={babyHeightError ? "error" : ""}
                      help={babyHeightError || ""}                    
                    >
                      {getFieldDecorator("height", {
                        rules: [{ type: "number", min: 0 }],
                      })(
                        <InputNumber max={99.9} className="w-100" step={0.1} />
                      )}
                    </Form.Item>
                  </div>
                  <div className={`col-md-4 col-6 ${this.state.isDueDate || this.props.step || this.props.updateBirthDate  ? "d-none" : "d-block"}`}>
                    <span className="child-input-label w-100 d-block">
                    Birth Weight (lbs)
                    </span>
                    <Form.Item
                      validateStatus={babyWeightLbsError ? "error" : ""}
                      help={babyWeightLbsError || ""}
                    >
                      {getFieldDecorator("weight_lbs", {
                        rules: [{ type: "number", min: 0.0 }],
                      })(<InputNumber max={99} className="w-100" step={1} />)}
                    </Form.Item>
                  </div>
                  <div className={`col-md-4 col-6 ${this.state.isDueDate || this.props.step || this.props.updateBirthDate  ? "d-none" : "d-block"}`}>
                    <span className="child-input-label">
                    Birth Weight (oz)
                    </span>
                    <Form.Item
                      validateStatus={babyWeightOzError ? "error" : ""}
                      help={babyWeightOzError || ""}
                    >
                      {getFieldDecorator("weight_oz", {
                        rules: [{ type: "number", min: 0.0 }],
                      })(<InputNumber className="w-100" step={1} max={30} />)}
                    </Form.Item>
                  </div>
              </div>
            </div>
          </>
          <div
            className={`container ${
              this.props.extraInfo ? "d-block" : "d-none"
            }`}
          >
            <div className="row justify-content-center align-items-center">
              <div className="col-12 col-md-6">
                <Form.Item extra="Child's Middle Name">
                  {getFieldDecorator("baby_middle_name")(
                    <Input
                      className="w-100"
                      maxLength={30}
                    />
                  )}
                </Form.Item>
              </div>
              <div className="col-12 col-md-6">
                <Form.Item extra="Birth Place">
                  {getFieldDecorator("baby_birthplace")(
                    <Input
                      className="w-100"
                      maxLength={30}
                    />
                  )}
                </Form.Item>
              </div>
              <div className="col-12">
                <Form.Item
                  validateStatus={babyHeightError ? "error" : ""}
                  help={babyHeightError || ""}
                  extra="Birth Height (inches)"
                >
                  {getFieldDecorator("height", {
                    rules: [{ type: "number", min: 0 }],
                  })(
                    <InputNumber
                      max={99.9}
                      className="w-100"
                      step={0.1}
                    />
                  )}
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item
                  validateStatus={babyWeightLbsError ? "error" : ""}
                  help={babyWeightLbsError || ""}
                  extra="Birth Weight (pounds)"
                >
                  {getFieldDecorator("weight_lbs", {
                    rules: [{ type: "number", min: 0.0 }],
                  })(
                    <InputNumber
                      max={99}
                      className="w-100"
                      step={1}
                    />
                  )}
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item
                  validateStatus={babyWeightOzError ? "error" : ""}
                  help={babyWeightOzError || ""}
                  extra="Birth Weight (ounces)"
                >
                  {getFieldDecorator("weight_oz", {
                    rules: [{ type: "number", min: 0.0 }],
                  })(
                    <InputNumber
                      className="w-100"
                      step={1}
                      max={30}
                    />
                  )}
                </Form.Item>
              </div>
            </div>
          </div>
          {/* )} */}

          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div
                className={`col-lg-3 col-md-5 col-6 ${this.props.extraInfo || this.props.addChild ||  this.props.updateBirthDate || this.props.step  ? "d-none" : ""}`}
              >
                <Form.Item>
                  <Button
                    type="primary"
                    size="large"
                    ghost
                    shape="round"
                    disabled={
                      !isDueDate &&
                      (hasErrors(getFieldsError()) || this.state.loading)
                    }
                    className="w-100 mx-auto d-table save-child-button"
                    style={{ maxWidth: 200 }}
                    onClick={() => {
                      this.props.history.push("/");
                    }}
                  >
                   Cancel
                  </Button>
                </Form.Item>
              </div>
              <div
                className={ this.props.extraInfo || this.props.addChild ||  this.props.updateBirthDate || this.props.step ? "col-12 large-wrapper" : "col-lg-3 col-md-5 col-6"}
              >
                <Form.Item>
                  <Button
                    htmlType="submit"
                    type="primary"
                    size="large"
                    ghost
                    shape="round"
                    disabled={
                      !isDueDate &&
                      (hasErrors(getFieldsError()) || this.state.loading)
                    }
                    className="w-100 mx-auto d-table save-child-button green-button"
                    style={{ maxWidth: 200 }}
                  >
                    {this.props.extraInfo
                      ? "Update Child"
                      : this.props.step
                      ? "Next"
                      : "Save Changes"}
                  </Button>
                </Form.Item>
                
              </div>
            </div>
            {this.props.updateBirthDate || this.props.addChild || this.props.extraInfo || this.props.step ? null : (
              <div className="row justify-content-center align-items-center">
                <p className="delete-child-text clickable" onClick={this.props.onDelete}>Delete Child Profile</p>
              </div>
            )}
            
          </div>
        </Form>

        <Modal
          centered
          visible={showModalDueDate}
          className="popup-info"
          closable={true}
          okButtonProps={{ style: { display: "none" } }}
          cancelText="OK"
          cancelButtonProps={{
            htmlType: "button",
            className: "ant-btn-primary",
            style: { width: "100px" },
          }}
          onCancel={() => {
            this.setState({ showModalDueDate: false });
          }}
        >
          <div>
            <div className="img-wrapper">
              <img src={logoImg} alt="Plus Child" className="mx-auto d-table" />
            </div>

            <p className="text-center m-0 mt-5">
              {`If you don't have your baby’s name or gender, you can leave those fields blank and update them later. When you are ready to update, simply go to My Account > Manage Children.`}
            </p>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  user: state.user,
  babySelected: state.baby.babySelected,
});

export default Form.create()(withRouter(connect(mapStateToProps)(SaveChild)));
