import React from 'react';
import { Book, StripeProduct } from '../../interfaces';
import BookPreview from '../BookPreview/BookPreview';

interface Props {
  books: Book[];
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  stripeProducts: StripeProduct[];
  isGuestUser?: boolean;
  isPlusUser?: boolean;
}

const BookList: React.FC<Props> = ({
  books,
  loading,
  setLoading,
  stripeProducts,
  isGuestUser,
  isPlusUser
}) => {
  return (
    <div className='py-5'>
      <div className='container'>
        <div className='row justify-content-start align-items-center py-4'>
          {books.map((book, index) => {
            return (
              <BookPreview
                key={index}
                book={book}
                loading={loading}
                setLoading={setLoading}
                stripeProducts={stripeProducts}
                isGuestUser={isGuestUser}
                isPlusUser={isPlusUser}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default BookList;
