import { Button, Card, Icon, Input, notification, Select } from "antd";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BooksListItem } from "../../../../interfaces";
import { sagaMiddleware } from "../../../../store";
import { getBookById } from "../../../../store/sagas/books";

const AdminDetailBooksPage: React.FC = () => {
  const history = useHistory();
  const { Option } = Select;

  const [book, setBook] = useState<BooksListItem>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const id: any = queryString.parse(history.location.search)?.id;

    if (!!id && !!id.toString()) {
      // Get By Id
      setLoading(true);

      sagaMiddleware.run<any>(
        getBookById,
        id,
        (err?: string, response?: any) => {
          setLoading(false);

          if (!!err) {
            notification.error({
              message: "Error",
              description: err
            });

            history.push("/admin/books");
            return;
          } else if (!!response) {
            try {
              setBook(response);
            } catch (error) {
              notification.error({
                message: "Error",
                description:
                  "Failed to load book detail, please try again later"
              });

              history.push("/admin/books");
              return;
            }
          }
        }
      );
    } else {
      history.push("/admin/books");
      return;
    }
  }, [history]);

  return (
    <div className="m-5">
      {loading ? (
        <div className="d-table mx-auto my-3">
          <Icon type="loading" style={{ fontSize: 36 }} />
        </div>
      ) : (
        <div className="d-table mx-auto m-5">
          <div>
              <Card.Grid style={{ width: "50%" }}>
                <Card
                  cover={
                    <img alt={book?.title} src={book?.thumbnail_with_url} />
                  }
                ></Card>
              </Card.Grid>
              <Card.Grid style={{ width: "50%" }}>
                <h3 className="mt-2">Book ID</h3>
                <Input value={book?.id} className="w-100" />
                <h3 className="mt-2">Title</h3>
                <Input value={book?.title} className="w-100" />
                <h3 className="mt-2">Subtitle</h3>
                <Input value={book?.subtitle} className="w-100" />
                <h3 className="mt-2">Book Type</h3>
                <Select defaultValue="lucy" className="w-100">
                  <Option value="jack">Jack</Option>
                  <Option value="lucy">Lucy</Option>
                  <Option value="disabled" disabled>
                    Disabled
                  </Option>
                  <Option value="Yiminghe">yiminghe</Option>
                </Select>
                <div className="d-flex mt-3">
                  <div className="col-6 d-flex justify-content-center">
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="mt-1 mb-3"
                      disabled={loading}
                    >
                      GENERATE BOOK
                    </Button>
                  </div>
                  <div className="col-6 d-flex justify-content-center">
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="mt-1 mb-3"
                      disabled={loading}
                      onClick={() => history.push('/admin/books')}
                    >
                      CANCEL
                    </Button>
                  </div>
                </div>
              </Card.Grid>
          </div>
          ,
        </div>
      )}
    </div>
  );
};

export default AdminDetailBooksPage;
