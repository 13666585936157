import { Card, Icon, notification } from 'antd';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import VerticalDivider from '../../../../components/VerticalDivider/VerticalDivider';
import { sagaMiddleware } from '../../../../store';
import { getDetailAnswer } from '../../../../store/sagas/form';
import { capitalizeText } from '../../../../utils/string';

const typeAnswer = (
  type_answer: 'INPUT' | 'LIST' | 'RADIO' | 'CHECK',
  type_data: 'TEXT' | 'NUMBER' | 'DATE' | null
) => {
  if (type_answer === 'INPUT') {
    return `Text box (${capitalizeText(type_data ?? 'Text')})`;
  } else {
    return type_answer === 'LIST'
      ? 'Drop-down'
      : type_answer === 'RADIO'
      ? 'Multiple choice'
      : 'Checkboxes';
  }
};

const AdminDetailAnswerPage: React.FC = () => {
  const history = useHistory();

  const [detail, setDetail] = useState<any>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const id: any = queryString.parse(history.location.search)?.id;

    if (!!id && !!id.toString()) {
      // Get By Code
      setLoading(true);

      sagaMiddleware.run<any>(
        getDetailAnswer,
        parseInt(id.toString()),
        (err?: string, response?: any) => {
          setLoading(false);

          if (!!err) {
            notification.error({
              message: 'Error',
              description: err,
            });

            history.push('/admin/forms');
            return;
          } else if (!!response) {
            try {
              setDetail(response);
            } catch (error) {
              notification.error({
                message: 'Error',
                description: 'Failed to load form, please try again later',
              });

              history.push('/admin/forms');
              return;
            }
          }
        }
      );
    } else {
      history.push('/admin/forms');
      return;
    }
  }, [history]);

  return loading || !detail ? (
    <div className='d-table mx-auto my-3'>
      <Icon type='loading' style={{ fontSize: 36 }} />
    </div>
  ) : (
    <div className='container my-3'>
      <h1 className='title'>{detail.form?.title}</h1>
      {!!detail.form?.extra_question && !!detail.extra_question_answer && (
        <Card className='my-3 elevation-light'>
          <h3 className='m-0'>{detail.form?.extra_question}</h3>
          <hr />
          {detail.extra_question_answer}
        </Card>
      )}
      {detail.answers?.map((a: any, index: number) => (
        <Card className='my-3 elevation-light' key={index}>
          <h3 className='m-0'>{a.question?.question}</h3>
          <div className='d-flex align-items-center my-2'>
            <span>
              <strong>Type:</strong>{' '}
              {typeAnswer(a.question?.type_answer, a.question?.type_data)}
            </span>
            <VerticalDivider style={{ height: 16 }} />
            <span>
              <strong>Required:</strong>{' '}
              {a.question?.is_required === 1 ? 'yes' : 'no'}
            </span>
            <VerticalDivider style={{ height: 16 }} />
            <span>
              <strong>Has other:</strong>{' '}
              {a.question?.has_other === 1 ? 'yes' : 'no'}
            </span>
          </div>
          <hr />
          {!!a.other_answer
            ? a.other_answer
            : !!a.answer
            ? a.answer
            : a.options?.map((o: any) => o.description).join(', ')}
        </Card>
      ))}
    </div>
  );
};

export default AdminDetailAnswerPage;
