import { Button, Card, Icon, Modal, Spin, Timeline } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import BaseLayout from "../../../components/BaseLayout/BaseLayout";
import AlexandersContactSupportModal from "../../../components/ContactSupportModal/AlexandersContactSupportModal";
import { sagaMiddleware } from "../../../store";
import { getAlexandersOrdersDetail } from "../../../store/sagas/user";
import "./MyOrdersDetail.scss";

interface Order {
  alexandersOrderId: string;
  booksOrder: Array<any>;
  distinctBooksOrder: Array<any>;
  cost: number;
  createdAt: string;
  currentStatus: {
    alexandersData: any;
    date: string;
    status: string;
  };
  finished: boolean;
  id: number;
  orderId: string;
  shipMethod: string;
  userId: number;
  shippingAddress: any;
  trackingNumber: string;
  statuses: Array<{
    alexanders_data: any;
    date: string;
    status: string;
  }>;
}

const MyOrdersDetailPage: React.FC = () => {
  let { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [alexandersOrder, setAlexandersOrder] = useState<Order>();
  const [showSupportModal, setShowSupportModal] = useState<boolean>(false);

  const getDistinctBooks = useCallback((booksOrder: any) => {
    const result = [];
    const map = new Map();
    for (const item of booksOrder) {
      if (!map.has(item.book_id)) {
        map.set(item.book_id, true);
        result.push(item);
      }
    }

    return result;
  }, []);

  useEffect(() => {
    setLoading(true);

    sagaMiddleware.run<any>(
      getAlexandersOrdersDetail,
      id,
      (err?: string, data?: any) => {
        setLoading(false);

        if (!!err) {
          Modal.error({
            title: "Error",
            content: err,
          });
        } else if (!!data) {
          const alexandersOrder: Order = {
            alexandersOrderId: data.alexanders_order_id,
            booksOrder: data.books_order,
            distinctBooksOrder: getDistinctBooks(data.books_order),
            currentStatus: data.current_status,
            finished: data.finished,
            id: data.id,
            orderId: data.order_id,
            shipMethod: data.ship_method,
            userId: data.user_id,
            cost: data.cost.toFixed(2),
            createdAt: moment(data.created_at).format("MM[-]DD[-]YYYY"),
            shippingAddress: data.shipping_address,
            trackingNumber: data.tracking_number,
            statuses: data.statuses,
          };

          setAlexandersOrder(alexandersOrder);
        }
      }
    );
  }, [id]);

  const orderedStatus = alexandersOrder?.statuses.find(
    (status) => status.status === "ORDERED"
  );
  const inProgressStatus = alexandersOrder?.statuses.find(
    (status) => status.status === "PRINTED" || status.status === "IN PROGRESS"
  );
  const shippedStatus = alexandersOrder?.statuses.find(
    (status) => status.status === "SHIPPED"
  );

  const shippingMethodToShow = useMemo(() => {
    switch (alexandersOrder?.shipMethod) {
      case "FEDEXONERATEENVELOPE":
        return "FedEx 2-4 Day Air";
      case "UPSGROUND":
      case "UPSMIEXPBPM":
        return "UPS";
      default:
        return alexandersOrder?.shipMethod;
    }
  }, [alexandersOrder?.shipMethod]);

  return (
    <BaseLayout
      title="BabyPage - Order"
      pageTitle="ORDER"
      hideChildMenu
      hideHeaderChildMenu
    >
      {loading && (
        <div className="col-12 d-flex justify-content-center">
          <Spin
            indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
          />
        </div>
      )}
      {alexandersOrder && (
        <div className="row justify-content-center align-items-center m-md-3 my-order-detail">
          <div className="col-12 col-md-7 col-xl-5 p-md-3">
            <Card
              className="w-100 alexander-order-list-item"
              style={{
                border: "0",
              }}
            >
              <div className="row justify-content-center align-items-center">
                <div className="col-12 my-1">
                  <div className="row align-items-normal">
                    <div className="col-3 col-md-6 m-0">
                      {alexandersOrder.distinctBooksOrder.map((book, key) => (
                        <img
                          src={book.thumbnail}
                          alt={book.title}
                          className="alexanders-order-img mt-3"
                          style={{
                            top: `${5 * key}px`,
                            left: `${20 + 5 * key}px`,
                            zIndex: alexandersOrder.booksOrder.length - key,
                          }}
                          key={key}
                        />
                      ))}
                    </div>
                    <div className="col-8 col-md-6">
                      <div className="col-12 my-1">
                        <h2>
                          <strong>Order Information</strong>
                        </h2>
                      </div>
                      <div className="col-12 my-1">
                        <p
                          className="m-0"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          <strong>Order #:</strong>{" "}
                          {!!alexandersOrder.orderId.slice(9, 36)
                            ? alexandersOrder.orderId.slice(9, 36) : alexandersOrder.orderId }
                        </p>
                      </div>
                      <div className="col-12 my-1">
                        <p className="m-0">
                          <strong>Ship. Method:</strong> {shippingMethodToShow}
                        </p>
                      </div>
                      <div className="col-12 my-1">
                        <p className="m-0">
                          <strong>Cost:</strong> ${alexandersOrder.cost}
                        </p>
                      </div>
                      <div className="col-12 my-1">
                        <p className="m-0">
                          <strong>Products:</strong>{" "}
                          {alexandersOrder.booksOrder.length}
                        </p>
                      </div>
                      <div className="col-12 my-1">
                        <p className="m-0">
                          <strong>Address:</strong>{" "}
                          {alexandersOrder.shippingAddress.address1}
                        </p>
                      </div>
                      <div className="col-12 my-1">
                        <p className="m-0">
                          <strong>Tracking #: </strong>
                          {alexandersOrder.trackingNumber}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-10 col-md-4 p-md-3">
            <Card
              className="w-100 alexander-order-list-item"
              style={{
                border: "0",
              }}
            >
              <div className="row justify-content-center align-items-center">
                <div className="col-12 my-1">
                  <Timeline className="pt-3">
                    <Timeline.Item color={`${orderedStatus ? "blue" : "gray"}`}>
                      <p className="mb-0">
                        <strong>Ordered</strong>
                      </p>
                      <p
                        className="orange-bb"
                        style={{
                          minHeight: orderedStatus ? "auto" : "21px",
                        }}
                      >
                        <small>
                          {orderedStatus
                            ? moment(orderedStatus.date).format(
                                "MM[-]DD[-]YYYY"
                              )
                            : ""}{" "}
                        </small>
                      </p>
                    </Timeline.Item>
                    <Timeline.Item color={`${inProgressStatus ? "blue" : "gray"}`}>
                      <p className="mb-0">
                        <strong>In Progress</strong>
                      </p>
                      <p
                        className="orange-bb"
                        style={{
                          minHeight: inProgressStatus ? "auto" : "21px",
                        }}
                      >
                        <small>
                          {inProgressStatus
                            ? moment(inProgressStatus.date).format(
                                "MM[-]DD[-]YYYY"
                              )
                            : ""}
                        </small>
                      </p>
                    </Timeline.Item>
                    <Timeline.Item color={`${shippedStatus ? "blue" : "gray"}`}>
                      <p className="mb-0">
                        <strong>Shipped</strong>
                        {alexandersOrder.trackingNumber && (
                          <Button
                            type="primary"
                            ghost
                            size="small"
                            shape="round"
                            onClick={() => {
                              let url: string = "";
                              if (alexandersOrder.shipMethod.indexOf('UPS') < 0) {
                                url = `https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels=${alexandersOrder.trackingNumber}`;
                              } else {
                                url = `https://www.ups.com/track?loc=en_US&tracknum=${alexandersOrder.trackingNumber}`;
                              }

                              const winOpened = window.open(url, "_blank");
                              try {
                                winOpened!.focus();
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={{
                              padding: 0,
                              width: "60%",
                              fontSize: "12px",
                              marginLeft: "15px",
                              borderRadius: "9px",
                            }}
                          >
                            Track your order
                          </Button>
                        )}
                      </p>
                      <p
                        className="orange-bb"
                        style={{
                          minHeight: shippedStatus ? "auto" : "21px",
                        }}
                      >
                        <small>
                          {shippedStatus
                            ? moment(shippedStatus.date).format(
                                "MM[-]DD[-]YYYY"
                              )
                            : ""}
                        </small>
                      </p>
                    </Timeline.Item>
                  </Timeline>
                </div>
              </div>
            </Card>
          </div>
          <div
            className="col-12"
            style={{
              top: "-60px",
            }}
          >
            <Card
              className="w-100 alexander-order-list-item"
              style={{
                border: "0",
              }}
            >
              <div className="row justify-content-center align-items-center">
                <a onClick={() => setShowSupportModal(true)}>
                  <img
                    className="image"
                    src="/external_assets/email-support.png"
                    alt="BabyPage support"
                    style={{
                      width: "30px",
                      marginRight: "7px",
                    }}
                  />
                  Contact support
                </a>
              </div>
            </Card>
          </div>
          <AlexandersContactSupportModal
            visible={showSupportModal}
            close={() => setShowSupportModal(false)}
            alexandersOrder={alexandersOrder.orderId}
          />
        </div>
      )}
    </BaseLayout>
  );
};

export default MyOrdersDetailPage;
