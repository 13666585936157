import { Button, Card, Form, Input, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AppState, sagaMiddleware } from '../../../store';
import { authAdmin } from '../../../store/sagas/admin';
import './AdminLoginPage.scss'

function hasErrors(fieldsError: Record<string, string[] | undefined>) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

type Props = FormComponentProps<any> &
  RouteComponentProps<any, any, any> & {
    access_token?: string;
  };

class AdminLoginPage extends PureComponent<Props> {
  state = {
    loading: false,
  };

  componentDidMount() {
    // Redirect user if is logged
    if (!!this.props.access_token) {
      this.props.history.push('/admin');
    }

    this.props.form.validateFields();
  }

  onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.props.form.validateFields((err: any, values: any) => {
      if (!err && !this.state.loading) {
        this.setState({ loading: true });

        sagaMiddleware.run<any>(
          authAdmin,
          values,
          (response: any, error: any) => {
            this.setState({ loading: false });

            if (error) {
              notification.error({
                message: 'Error',
                description: error,
              });
              return;
            }

            this.props.history.push('/admin');
          }
        );
      }
    });
  };

  render() {
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched,
    } = this.props.form;

    const emailError = isFieldTouched('email') && getFieldError('email');
    const passwordError =
      isFieldTouched('password') && getFieldError('password');

    return (
      <div className='alignCenterFullScreen'>
        <Card className='elevation-5 card-admin'>
          <h2 className="text-center mb-3">BabyPage Admin</h2>
          <Form onSubmit={this.onSubmit}>
            <Form.Item
              validateStatus={emailError ? 'error' : ''}
              help={emailError || ''}
            >
              {getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                    message: 'This field is required.',
                  },
                  {
                    type: 'email',
                    message: 'The email provided is not an email.',
                  },
                ],
              })(
                <Input
                  size='large'
                  placeholder='Email address'
                  type='email'
                  maxLength={60}
                />
              )}
            </Form.Item>
            <Form.Item
              validateStatus={passwordError ? 'error' : ''}
              help={passwordError || ''}
            >
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter at least 6 characters.',
                  },
                  { min: 6, message: 'Please enter at least 6 characters.' },
                ],
              })(
                <Input.Password
                  size='large'
                  placeholder='Password'
                  maxLength={100}
                />
              )}
            </Form.Item>
            <Form.Item>
              <Button
                htmlType='submit'
                type='primary'
                icon={this.state.loading ? 'loading' : 'login'}
                size='large'
                disabled={hasErrors(getFieldsError()) || this.state.loading}
                className='w-100'
                shape='round'
              >
                Sign in
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  access_token: state.admin.access_token,
});

export default Form.create()(
  withRouter(connect(mapStateToProps)(AdminLoginPage))
);
