import { Background } from "./background";
import { Border, Illustration } from "./bpHelper";
import { QuestionAnswer } from "./question";

export interface SaveBabyPagePhoto {
  file?: File | Blob;
  base64?: string;
  isLocal?: boolean;
  base64_cropped: string;
  name?: string;
  croppedData?: Cropper.Data;
  imageData?: Cropper.ImageData;
  oldAspect?: number;
  side?: string;
  missing?: boolean;
  // origin?: number[]; // x, y
  // originPercent?: number[]; // x, y
}

export type Layout =
  | "layout1A"
  | "layout2A"
  | "layout2B"
  | "layout3A"
  | "layout3B"
  | "layout3C"
  | "layout3D"
  | "layout4A"
  | "layout4B"
  | "layout4C"
  | "layout4D"
  | "layout4E"
  | "layout6A"
  | "layout6B";

export interface SaveBabyPage {
  id?: number;
  photos: SaveBabyPagePhoto[];
  photosRightSide: SaveBabyPagePhoto[];
  answers: QuestionAnswer[];
  borderSelected?: Border;
  illustrationSelected?: Illustration;
  backgroundSelected?: Background;
  font: string;
  textAlign: "start" | "center" | "end";
  layout: Layout;
  layoutRightSide: Layout;
  somethingChanged: boolean;
  thumbnail?: string;
  thumbnailRightSide?: string;
  thumbnail_content?: string;
  label_url?: string;
  label_url_file?: string;
  label_left_text?: string | null;
  label_left_position?: string;
  label_right_text?: string | null;
  label_right_position?: string;
  labelStatePositionLeft?: {
    display?: string;
    justifyContent?: string;
    alignItems?: string;
  }
  labelStatePositionRight?: {
    display?: string;
    justifyContent?: string;
    alignItems?: string;
  }
  placeholder?: string | ArrayBuffer;
  isEdit?: boolean | undefined;
  hasMissing?: boolean | undefined;
  font_id?: number;
  original_title?: string;
  child_id?: number | undefined; 
}

export interface DraftBabyPage {
  id?: number;
  photos: SaveBabyPagePhoto[];
  photosRightSide: SaveBabyPagePhoto[];
  answers: QuestionAnswer[];
  borderSelected?: Border;
  illustrationSelected?: Illustration;
  backgroundSelected?: Background;
  font: string;
  textAlign: "start" | "center" | "end";
  layout: Layout;
  layoutRightSide: Layout;
  somethingChanged: boolean;
  thumbnail?: string;
  thumbnailRightSide?: string;
  thumbnail_content?: string;
  label_url?: string;
  label_url_file?: string;
  label_left_text?: string | null;
  label_left_position?: string;
  label_right_text?: string | null;
  label_right_position?: string;
  labelStatePositionLeft?: {
    display?: string;
    justifyContent?: string;
    alignItems?: string;
  }
  labelStatePositionRight?: {
    display?: string;
    justifyContent?: string;
    alignItems?: string;
  }
  placeholder?: string | ArrayBuffer;
  isEdit?: boolean | undefined;
  hasMissing?: boolean | undefined;
  title?: string | undefined;
  subTitle?: string | undefined;
  milestoneId?: string | undefined;
};

export const initialBabyPage: SaveBabyPage = {
  photos: [
    {
      base64_cropped: "",
    },
  ],
  photosRightSide: [
    {
      base64_cropped: '',
    },
  ],
  answers: [],
  layout: 'layout1A',
  layoutRightSide: 'layout1A',
  font: 'Montserrat-Regular',
  textAlign: 'center',
  somethingChanged: false,
};

export interface Photo {
    id: number;
    photo: string;
}
