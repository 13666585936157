import { Icon, Modal, Radio, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { User } from "../../interfaces";
import { AppState, sagaMiddleware } from "../../store";
import { switchAccount } from "../../store/sagas/user";
import rootSaga from "../../store/sagas";
import { fetchDashboard } from "../../store/sagas/dashboard";
import GuestUserValidateInvitationCodeForm from "../GuestUserValidateInvitationCodeForm/GuestUserValidateInvitationCodeForm";
import { startCase } from "lodash";

interface Props {
  closeGuestUserModal: () => void;
  isVisible: boolean;
  user: User;
  invitationCode: string;
}

const GuestUserModal: React.FC<Props> = ({
  isVisible,
  closeGuestUserModal,
  user,
  invitationCode,
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [radioOption, setRadioOption] = useState<number | undefined>(1);

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  useEffect(() => {
    setRadioOption(user.main_user ? user.id : user.id);
    if (invitationCode) {
      setRadioOption(0);
    }
  }, [user.main_user, user.id, invitationCode]);

  const handleRadioGroupChange = useCallback((e) => {
    setRadioOption(e.target.value);
  }, []);

  const loginAsDifferentUserHandler = (hostUser: any, goPersonalAccount: boolean = false) => {
    closeGuestUserModal();
    let params = {
      mainUserId: !!hostUser.guest_user_id ? hostUser.guest_user_id : hostUser.id,
      hostUserId: !!hostUser.host_user_id ? hostUser.host_user_id: hostUser.main_user.id,
    }

    // Updating "params" in case we want to return to personal account (get out of guest-users feature)
    if (!!goPersonalAccount) {
      params = {
        mainUserId: hostUser.main_user.id,
        hostUserId: hostUser.main_user.id,
      }
    }

    sagaMiddleware.run<any>(
      switchAccount,
      params,
      (error: string | null) => {
        if (error) {
          Modal.error({
            title: "Error",
            content: error,
          });

          return;
        }

        // Don't load these functions if we're on child-basic-info page.
        if (window.location.pathname !== "/step-2") {
          sagaMiddleware.run<any>(rootSaga, true);
        }
        
        history.push("/");

        if (window.location.pathname !== "/step-2") {
          sagaMiddleware.run<any>(fetchDashboard, goPersonalAccount, (error: string | null) => {
            if (error !== null) {
              Modal.error({
                title: "Error",
                content: error,
              });
            }
          });
        }

        Modal.info({
          title: "Hi there!",
          content: `You're seeing the ${startCase(
            hostUser.host_user.first_name
          )} ${startCase(
            hostUser.host_user.last_name
          )}'s account as a guest user. Go to menu and select switch account to see your own content`,
        });
      }
    );
  };

  const switchAccountFromModal = useCallback((hostUserId: number) => {
    sagaMiddleware.run<any>(
      switchAccount,
      {
        mainUserId: !!user.main_user ? user.main_user.id : user.id,
        hostUserId: hostUserId,
      },
      (error: string | null, newUser?: User) => {
        if (error) {
          Modal.error({
            title: "Error",
            content: error,
          });

          return;
        }

        const firstName = newUser?.main_user.host_users.find((host_user: any) => host_user.host_user_id === hostUserId).host_user.first_name;
        const lastName = newUser?.main_user.host_users.find((host_user: any) => host_user.host_user_id === hostUserId).host_user.last_name;
        Modal.info({
          title: "Hi there!",
          content: `You're seeing the ${startCase(
            firstName
          )} ${startCase(
            lastName
          )}'s account as a guest user. Go to menu and select switch account to see your own content`,
        });

        sagaMiddleware.run<any>(rootSaga, true);
        sagaMiddleware.run<any>(fetchDashboard, (error: string | null) => {
          if (error !== null) {
            Modal.error({
              title: "Error",
              content: error,
            });
          }
        });
      }
    );
  }, [user.main_user]);

  const hostUsers = user.main_user?.host_users ?? user.host_users;

  return (
    <Modal
      title={
        radioOption !== 0 ? "Choose an account" : "Enter an invitation code"
      }
      visible={!!user.id && isVisible}
      onCancel={() => closeGuestUserModal()}
      closable={true}
      footer={null}
    >
      <Radio.Group onChange={handleRadioGroupChange} value={radioOption}>
        <Radio
          style={radioStyle}
          value={!!user.main_user ? user.main_user.id : user.id}
          onClick={() => !!user.guest_user && loginAsDifferentUserHandler(user, true)}
        >
          Personal Account
        </Radio>

        {!!hostUsers && (
          <>
            {hostUsers.map((hostUser: any) => {
              if(!!hostUser.validated) {
                return (
                  <Radio
                    key={hostUser.host_user_id}
                    onClick={() => loginAsDifferentUserHandler(hostUser)}
                    style={radioStyle}
                    value={hostUser.host_user_id}
                  >
                    {`${startCase(hostUser.host_user.first_name)} ${startCase(
                      hostUser.host_user.last_name
                    )}`}{" "}
                    - <small>{hostUser.permission}</small>
                  </Radio>
                )
              } else {
                return null
              }
            })}
          </>
        )}

        <Radio style={radioStyle} value={0}>
          I have an invitation code
        </Radio>
      </Radio.Group>

      {radioOption === 0 &&
        (loading ? (
          <div className="mx-auto d-table">
            <Spin
              indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
            />
          </div>
        ) : (
          <GuestUserValidateInvitationCodeForm
            setLoading={setLoading}
            closeGuestUserModal={(hostUserId: number) => {
              closeGuestUserModal();
              switchAccountFromModal(hostUserId);
            }}
            invitationCodeFromLink={invitationCode}
          />
        ))}
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => ({
  user: state.user,
  invitationCode: state.guestUsers.deepLinkInfo?.invitation_code,
});

export default connect(mapStateToProps)(GuestUserModal);
