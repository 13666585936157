import {Card, Icon, Modal, Spin} from 'antd';
import React, {useCallback, useEffect, useState} from 'react';
import BaseLayout from '../../components/BaseLayout/BaseLayout';
import {PurchaseHistoryResponse} from '../../interfaces/purchase';
import {sagaMiddleware} from '../../store';
import {getAlexandersOrders} from '../../store/sagas/user';
import './MyOrders.scss';
import moment from "moment";
import {useHistory} from "react-router-dom";

interface Order {
  alexandersOrderId: string;
  booksOrder: Array<any>;
  distinctBooksOrder: Array<any>;
  cost: number;
  createdAt: string;
  currentStatus: {
    alexandersData: any;
    date: string;
    status: string;
  },
  finished: boolean;
  id: number;
  orderId: string;
  shipMethod: string;
  userId: number;
}

const MyOrdersPage: React.FC = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState<{
    history?: PurchaseHistoryResponse;
    alexandersOrders: Order[];
  }>({
    history: undefined,
    alexandersOrders: [],
  });

  const getDistinctBooks = useCallback((booksOrder: any) => {
    const result = [];
    const map = new Map();
    for (const item of booksOrder) {
      if (!map.has(item.book_id)) {
        map.set(item.book_id, true);
        result.push(item);
      }
    }

    return result;
  }, []);

  useEffect(() => {
    setLoading(true);

    sagaMiddleware.run<any>(
      getAlexandersOrders,
      (err?: string, data?: any) => {
        setLoading(false);

        if (!!err) {
          Modal.error({
            title: 'Error',
            content: err,
          });
        } else if (!!data) {
          const alexandersOrders: Order[] = [];

          if (!!data && !!data.length) {
            data.forEach((order: any) => {
              alexandersOrders.push({
                alexandersOrderId: order.alexanders_order_id,
                booksOrder: order.books_order,
                distinctBooksOrder: getDistinctBooks(order.books_order),
                currentStatus: order.current_status,
                finished: order.finished,
                id: order.id,
                orderId: order.order_id,
                shipMethod: order.ship_method,
                userId: order.user_id,
                cost: order.cost.toFixed(2),
                createdAt: moment(order.created_at).format('MM[-]DD[-]YYYY'),
              });
            });
          }

          setPageData({
            history: data,
            alexandersOrders: alexandersOrders,
          });
        }
      }
    );
  }, [getDistinctBooks]);

  return (
    <BaseLayout
      title='BabyPage - My Orders'
      pageTitle='Orders'
      hideChildMenu
      hideHeaderChildMenu
    >
      {loading && (
        <div className='col-12 d-flex justify-content-center'>
          <Spin
            indicator={<Icon type='loading' style={{fontSize: 24}} spin/>}
          />
        </div>
      )}
      <div className='row justify-content-center align-items-center m-md-3 my-orders'>
        {pageData.alexandersOrders.length < 1 && (
          <div className='col-12 col-md-6 col-xl-4 p-md-3'>
            <div className='row justify-content-center align-items-center'>
              <h3 className='my-1'>
                <strong>There are no orders yet.</strong>
              </h3>
            </div>
          </div>
        )}
        {pageData.alexandersOrders.map((alexanderOrder, index) => (
          <div className='col-12 col-md-6 col-xl-4 p-md-3' key={index}>
            <Card className='w-100 elevation-md-5 alexander-order-list-item'
                  onClick={() =>
                    history.push(`/my-orders/${alexanderOrder.alexandersOrderId}`)
                  }>
              <div className='row justify-content-center align-items-center'>
                <div className='col-12 my-1' key={index}>
                  <div className='row align-items-normal'>
                    <div className='col-3 m-0'>
                      {alexanderOrder.distinctBooksOrder.map((book, key) => (
                        <img
                          src={book.thumbnail}
                          alt={book.title}
                          className="alexanders-order-img mt-1"
                          style={{
                            top: `${5 * key}px`,
                            left: `${10 + (5 * key)}px`,
                            zIndex: alexanderOrder.booksOrder.length - key
                          }}
                          key={key}
                        />
                      ))}
                    </div>
                    <div className='col-9'>
                      <div className='col-12 my-1'>
                        <p className='m-0'
                           style={{
                             whiteSpace: 'nowrap'
                           }}
                        >
                          <strong>Order #:</strong> {!!alexanderOrder.orderId.slice(9, 36)
                            ? alexanderOrder.orderId.slice(9, 36) : alexanderOrder.orderId }
                        </p>
                      </div>
                      <div className='col-12 my-1'>
                        <p className='m-0'><strong>Cost:</strong> ${alexanderOrder.cost}</p>
                      </div>
                      <div className='col-12 my-1'>
                        <p className='m-0'><strong>Products:</strong> {alexanderOrder.booksOrder.length}</p>
                      </div>
                      <div className='col-12 my-1'>
                        <p className='m-0'><strong>Status:</strong> {alexanderOrder.currentStatus.status}</p>
                      </div>
                      <div className='col-12 my-1'>
                        <p className='m-0'><strong>Date:</strong> {alexanderOrder.createdAt}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
    </BaseLayout>
  );
};

export default MyOrdersPage;
