import { Alert, Icon, Tabs } from "antd";
import React, { useMemo, useState } from "react";
import { Border, Illustration, SimplifiedAnswer, Background, Milestone } from "../../interfaces";
import Content from "./Content";
import PlusAlert from "./PlusAlert";
import "./EditBorderIllustration.scss"

interface Props {
  textAnswers: string;
  labelUrl?: string;
  font: string;
  subTitle: string;
  birthDate: string;
  textAlign: "start" | "center" | "end";
  borderSelected?: Border;
  illustrationSelected?: Illustration;
  borders: Border[];
  backgroundSelected: Background | undefined;
  illustrations: Illustration[];
  setBorder: (border: Border) => void;
  setIllustration: (illustration: Illustration | undefined) => void;
  isPlus: boolean;
  showPlus: () => void;
  isTruncated: boolean;
  setIsTruncated: (isTruncated: boolean) => void;
  answersArray: SimplifiedAnswer[];
  setSortedAnswers: (orderedAnswers: SimplifiedAnswer[]) => void;
  setCustomAnswers: (changedAnswers: SimplifiedAnswer[]) => void;
  addCustomAnswer: (answer: string) => void;
  removeAnswer: (index: number) => void;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setSubTitle: React.Dispatch<React.SetStateAction<string>>;
  milestoneType?: Milestone;
  selectedPlus: { border: boolean; illustration: boolean; layout: boolean };
  setSelectedPlus: ({ border, illustration, layout }: any) => void;
  setMilestoneDate?: React.Dispatch<React.SetStateAction<string>>;
  milestoneDate?: string;
  setMilestoneAsFirstLine?: React.Dispatch<React.SetStateAction<boolean>>;
  milestoneAsFirstLine?: boolean;
}

const EditBorderIllustration: React.FC<Props> = ({
  textAnswers,
  labelUrl,
  font,
  subTitle,
  birthDate,
  textAlign,
  borderSelected,
  illustrationSelected,
  borders,
  backgroundSelected,
  illustrations,
  setBorder,
  setIllustration,
  isPlus,
  showPlus,
  isTruncated,
  setIsTruncated,
  answersArray,
  setSortedAnswers,
  setCustomAnswers,
  addCustomAnswer,
  removeAnswer,
  setTitle,
  setSubTitle,
  milestoneType,
  selectedPlus,
  setSelectedPlus,
  milestoneDate,
  setMilestoneDate,
  setMilestoneAsFirstLine,
  milestoneAsFirstLine
}) => {
  const [bFilter, setBFilter] = useState(0);

  const borderCategories = useMemo(
    () => Array.from(new Set(borders.map((b) => b.category))),
    [borders]
  );
  const bordersFiltered = useMemo(
    () =>
      !!borders && !!borders.length
        ? borders.filter((e) => e.category === borderCategories[bFilter])
        : [],
    [borders, bFilter, borderCategories]
  );

  const contentProps = {
    birthDate,
    font,
    subTitle,
    textAlign,
    borderSelected,
    illustrationSelected,
    textAnswers,
    labelUrl,
    showEditButton: true,
    backgroundSelected,
    isTruncated,
    setIsTruncated,
    answersArray,
    setSortedAnswers,
    setCustomAnswers,
    addCustomAnswer,
    removeAnswer,
    setTitle,
    setSubTitle,
    milestoneType: milestoneType,
    setSelectedPlus,
    milestoneDate,
    setMilestoneDate,
    setMilestoneAsFirstLine,
    milestoneAsFirstLine
  };

  /**
   * getBorderCategories
   * @param category
   * @param index
   */
  const getBorderCategories = (category: string, index: number) => {
    const categoryName = category === "Light" ? "Free" : category;
    return <Tabs.TabPane key={index.toString()} tab={categoryName} />;
  };

  return (
    <>
      <div className="mt-3 mobile-only"></div>
      <div className="row justify-content-center align-items-center h-100 my-3">
        <div className="col-12 col-lg-6">
          <Content {...contentProps} />
          {isTruncated && (
            <div className="m-3">
              <Alert
                message="Warning"
                description={`The text on your babypage currently surpasses the space available. Please edit your babypage to reduce the length of the text.`}
                type="warning"
                showIcon
              />
            </div>
          )}
        </div>
        <div className="col-12 col-lg-6 p-1">
          <Tabs defaultActiveKey="0">
            <Tabs.TabPane tab="Borders" key="0">
              <Tabs
                activeKey={bFilter.toString()}
                type="card"
                onChange={(key) => setBFilter(parseInt(key))}
              >
                {borderCategories.map((category: string, index: number) =>
                  getBorderCategories(category, index)
                )}
              </Tabs>
              <div className="border-illustration border-margin">
                {bordersFiltered.map((border: Border, index: number) => (
                  <img
                    key={index}
                    src={border.image_url_thumbnail}
                    alt={border.name}
                    className="border-item"
                    onClick={() => {
                      setBorder(border);
                      selectedPlus.border =
                        !isPlus && border.is_plus ? true : false;
                      setSelectedPlus(selectedPlus);
                    }}
                  />
                ))}
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Illustrations" key="1">
              <div className="border-illustration illustration-margin">
                {illustrations.map(
                  (illustration: Illustration, index: number) => (
                    <div
                      key={index}
                      className="illustration-item"
                      onClick={() => {
                        !!illustrationSelected &&
                        illustrationSelected.id === illustration.id
                          ? setIllustration(undefined)
                          : setIllustration(illustration);
                        selectedPlus.illustration =
                          !isPlus && illustration.is_plus ? true : false;
                        setSelectedPlus(selectedPlus);
                      }}
                    >
                      {!isPlus && illustration.is_plus && (
                        <PlusAlert style={{ marginLeft: 50 }} />
                      )}
                      <img
                        src={illustration.image_url}
                        alt={illustration.name}
                        className="w-100"
                      />
                      {!!illustrationSelected &&
                        illustrationSelected.id === illustration.id && (
                          <Icon
                            type="check-circle"
                            theme="filled"
                            style={{ fontSize: 18 }}
                          />
                        )}
                    </div>
                  )
                )}
              </div>
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default EditBorderIllustration;
