import {
  Button,
  Checkbox,
  Icon,
  Input,
  InputNumber,
  Modal,
  notification,
  Select,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { isCreditCard } from "validator";
import BaseLayout from "../../components/BaseLayout/BaseLayout";
import CustomDivider from "../../components/CustomDivider";
import PaypalButton from "../../components/Paypal/PaypalButton";
import { PAYPAL } from "../../env";
import {
  CartItem,
  Coupon,
  Customer,
  CustomerCard,
  Purchase,
  shippingOption,
  User,
  CountryOptions,
  DraftBabyPage,
} from "../../interfaces";
import { AppState, sagaMiddleware } from "../../store";
import {
  checkCoupon,
  createPurchaseSaga,
  getShippingMethods,
} from "../../store/sagas/checkout";
import { fetchBookById } from "../../store/sagas/books";
import { startCase, toLower } from "lodash";
import { addGiftCard, getCustomerInfo } from "../../store/sagas/customer";
import { UserSettings } from "../../pages/User/ProfilePage/ProfilePage";
import { trackAction } from "../../utils/marketing";
import "./Checkout.scss";
import { getPurchase, itemInvalid, purchaseValid } from "./CheckoutHelper";
import BookDisclaimer from "../../components/BookDisclaimer/BookDisclaimer";
import { PAGE_URL } from "../../env";
import { useManageModal } from "../../hooks/useManageModal";
import MessageTip from "./MessageTip";
import icon_warning from "../../assets/img/navy_warning.png";
const { Option } = Select;
const { success } = Modal;

/**
 * First option to card list
 */
const newCard: CustomerCard = {
  id: "",
};

function getDiscountForSpecificProduct(
  subTotal: number,
  coupon: Coupon,
): number {
  let discount = 0;

  if (!coupon) {
    return discount;
  }

  discount = !!coupon.percent_off
    ? (coupon.percent_off / 100) * subTotal
    : ((coupon?.amount_off || 0) > subTotal
        ? subTotal
        : coupon?.amount_off || 0) / 100;

  return discount;
}

interface Props {
  user: User;
  customer: Customer;
  draftBabyPage: DraftBabyPage;
  milestoneId?: string;
}

export interface PurchaseForm {
  selectedCard?: string;
  cardNumber?: number;
  cardNumberInvalid?: boolean;
  cardName?: string;
  cardCVV?: string;
  cardCVVInvalid?: boolean;
  cardMonth?: number;
  cardYear?: number;
  billingFirstName?: string;
  billingLastName?: string;
  billingEmail?: string;
  billingStreet?: string;
  billingCountry: string;
  billingState?: string;
  billingCity?: string;
  billingZipcode?: string;
  shippingFirstName?: string;
  shippingLastName?: string;
  shippingEmail?: string;
  shippingStreet?: string;
  shippingCountry: string;
  shippingState?: string;
  shippingCity?: string;
  shippingZipcode?: string;
  sameAsBilling: boolean;
  billingPhoneNumber?: string;
}

const currentMonth = moment().month() + 1;
const currentYear = moment().year();

const CheckoutPage: React.FC<Props> = ({ customer, user, milestoneId }) => {
  const history = useHistory();
  const [disableButton, setDisableButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [store, setStore] = useState<CartItem[]>([]);
  const [bookYearSub, setBookYearSub] = useState<CartItem | null>(null);
  const [form, setForm] = useState<PurchaseForm>({
    selectedCard: "",
    billingCountry: "US",
    shippingCountry: "US",
    sameAsBilling: true,
  });
  const [couponText, setCouponText] = useState("");
  const [coupon, setCoupon] = useState<Coupon | null>(null);
  const [useBalance, setUseBalance] = useState(false);
  const [shippingOptions, setShippingOptions] = useState<
    shippingOption[] | undefined
  >(undefined);
  const [selectedShippingOption, setSelectedShippingOption] = useState<
    shippingOption | undefined
  >(undefined);
  const [updateDisscount, setUpdateDisscount] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState<
    CountryOptions | undefined
  >({ name: "US", code: "US" });
  const [selectedShippingCountry, setSelectedShippingCountry] = useState<
    CountryOptions | undefined
  >({ name: "US", code: "US" });
  const [countryOptions, setCountryOptions] = useState<CountryOptions[]>([
    {
      name: "US",
      code: "US",
    },
    {
      name: "Canada",
      code: "CA",
    },
  ]);
  const [disclaimerCheck, setDisclaimerCheck] = useState(true);
  const [showBooksModal, setShowBooksModal] = useManageModal();
  const [showTip, setShowTip] = useState<boolean>(false);
  const [showBabyListModal, setShowBabyListModal] = useState(false);
  const [showAddressValidation, setShowAddressValidation] =
    useState<boolean>(false);
  const disccountRef = useRef(0);
  let lastIndexOfSubscriptions = customer?.subscriptions?.data.length - 1;
  const isTrialing =
    customer?.subscriptions?.data[lastIndexOfSubscriptions]?.status ===
    "trialing"
      ? true
      : customer?.iap_subscriptions?.is_trial_period
        ? true
        : false;

  const months = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ];
  const presentYear = moment().format("YYYY");
  let expireYears = [];
  // Max expire year is current year + 30
  for (let i = 0; i < 31; i++) {
    expireYears.push((parseInt(presentYear) + i).toString());
  }

  useEffect(() => {
    setLoading(true);
    trackAction("initiateCheckout", {
      google: {
        event: "begin_checkout",
      },
    });
  }, []);

  useEffect(() => {
    if (!form.billingFirstName) {
      const newForm = {
        ...form,
        billingFirstName: user.first_name,
        billingLastName: user.last_name,
        billingEmail: user.email,
        shippingFirstName: user.first_name,
        shippingLastName: user.last_name,
        shippingEmail: user.email,
      };

      setForm(newForm);
    }
  }, [user, form]);

  useEffect(() => {
    if (
      selectedCountry !== undefined &&
      selectedShippingCountry !== undefined
    ) {
      if (form.sameAsBilling) {
        setForm((state) => ({
          ...state,
          billingCountry: selectedCountry?.code,
          shippingCountry: selectedCountry?.code,
        }));
        setSelectedShippingCountry(selectedCountry);
      } else {
        setForm((state) => ({
          ...state,
          billingCountry: selectedCountry?.code,
          shippingCountry: selectedShippingCountry?.code,
        }));
      }
    }
  }, [form.sameAsBilling, selectedCountry, selectedShippingCountry]);

  useEffect(() => {
    if (!!user.store) {
      try {
        const localStore = JSON.parse(user.store);
        if (!!localStore && Array.isArray(localStore)) {
          if (localStore.length === 0) {
            history.push("/cart");
            return;
          }

          if (itemInvalid(localStore)) {
            history.push("/cart");
            return;
          }

          if (user.user_level === 1) {
            setStore(
              localStore.filter((e: CartItem) => e.type !== "subscription"),
            );
          } else {
            setStore(localStore);
          }
        }
      } catch (error) {}
    }
  }, [user, history]);

  useEffect(() => {
    setLoading(true);

    sagaMiddleware.run<any>(
      getShippingMethods,
      form.shippingCountry,
      (err?: string, response?: shippingOption[]) => {
        setLoading(false);

        if (!!err) {
          return notification.error({
            message: "Error",
            description: err,
          });
        }

        if (response) {
          let visibleShippingOptions: shippingOption[] = [];
          isTrialing
            ? (visibleShippingOptions = response.filter(
                (v, i) => v.value !== 0,
              ))
            : (visibleShippingOptions = response);

          setShippingOptions(visibleShippingOptions);
          setSelectedShippingOption(visibleShippingOptions[0]);
        }
      },
    );
  }, [form.shippingCountry]);

  const validateYearBookSub = useCallback(
    (coupon: Coupon): boolean => {
      if (
        !coupon.metadata ||
        coupon.metadata.type !== "Large Soft Cover Book"
      ) {
        // Not coupon for year subscription
        return true;
      }

      let bookOption: any = undefined;

      // Searching by books on store
      store.forEach((product) => {
        if (
          !bookOption &&
          product.type === "book" &&
          !!product.options &&
          Array.isArray(product.options)
        ) {
          bookOption = product.options.find(
            (option) =>
              option.selected && option.description === "saddle large",
          );
        }
      });

      if (
        !!bookOption &&
        !!coupon.metadata &&
        coupon.metadata.type === "Large Soft Cover Book"
      ) {
        if (coupon.metadata.customer === customer.id) {
          setBookYearSub(bookOption);
          return true;
        } else {
          // Invalid Customer use
          Modal.error({
            title: "Error",
            content: "This coupon is not for you",
          });
        }
      } else {
        // Store without books
        Modal.error({
          title: "Error",
          content: "Your cart haven't books, so the coupon will be ignored",
        });
      }

      return false;
    },
    [customer, store],
  );

  const applyCoupon = useCallback(
    (couponApplied: string) => {
      if (loading) return;

      setLoading(true);
      sagaMiddleware.run<any>(
        checkCoupon,
        couponApplied,
        (err?: string, found?: Coupon) => {
          setLoading(false);

          if (!!err) {
            Modal.error({
              title: "Error",
              content: err,
            });
            return;
          } else if (!!found) {
            if (!!coupon && found.id === coupon.id) {
              Modal.error({
                title: "Error",
                content: "Coupon already applied",
              });
              return;
            }

            const valid = validateYearBookSub(found) && found.valid;
            if (!valid) {
              setCoupon(null);
              setBookYearSub(null);
              notification.error({
                message: "Cannot Redeem Expired Coupon",
                description: "Coupon Expired",
              });
              if (user.user_level === 1) {
                setLoaded(false);
              }
              return;
            }

            if (!!found.metadata) {
              if (found.metadata.type === "Gift Card") {
                setLoading(true);

                sagaMiddleware.run<any>(
                  addGiftCard,
                  found.id,
                  (err?: string, description?: string) => {
                    setLoading(false);
                    if (!!err) {
                      Modal.error({
                        title: "Error",
                        content: err,
                      });
                    } else if (!!description) {
                      setUseBalance(true);
                      notification.success({
                        message: "Success",
                        description,
                      });
                    }
                  },
                );
              } else if (
                user.user_level !== 1 &&
                found.id === "one_year_subscription_discount"
              ) {
                notification.error({
                  message: "Only for PLUS users",
                  description: "Discount only valid for PLUS users",
                });
              } else if (
                user.user_level === 1 &&
                found.id === "one_year_subscription_discount"
              ) {
                notification.success({
                  message: "Success",
                  description: "PLUS subscription discount applied.",
                });
                setCoupon(found);
              } else {
                setCoupon(found);
                notification.success({
                  message: "Success",
                  description: `Coupon ${found.name} applied`,
                });
              }
            } else if (!!found.id) {
              setCoupon(found);
              notification.success({
                message: "Success",
                description: "Coupon applied",
              });
            }
          }
        },
      );
    },
    [loading, coupon, validateYearBookSub],
  );

  const userIsPlus = useMemo(() => {
    if (!!loaded) {
      return;
    }
    if (user.user_level === 1 && !coupon && !loaded && !isTrialing) {
      setLoaded(true);
      applyCoupon("one_year_subscription_discount");
    }

    return user.user_level === 1;
  }, [applyCoupon, loaded, coupon, user]);

  const subscription = useMemo(
    () => store.find((item) => item.type === "subscription"),
    [store],
  );

  const books = useMemo(
    () => store.filter((item) => item.type === "book"),
    [store],
  );

  const couponSubscription = useMemo(() => {
    let matchStripeID;
    if (!!coupon?.applies_to) {
      matchStripeID = coupon.applies_to.products.filter((stripeID) => {
        return subscription?.stripe_id === stripeID;
      });

      if (matchStripeID.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [subscription, coupon]);

  useEffect(() => {
    for (let book of books) {
      if (!!book.book_id) {
        setLoading(true);
        sagaMiddleware.run<any>(
          fetchBookById,
          parseInt(book.book_id.toString()),
          (error: any | null, bookBD?: any) => {
            if (error !== null || !bookBD) {
              setLoading(false);
              history.push("/");
              return;
            }
            try {
              book.babypages = bookBD.babypages;
              book.title = bookBD.title;
            } catch (error) {
              Modal.error({
                title: "Error",
                content: error.message ? error.message : "Error loading book",
              });
            }
            setLoading(false);
          },
        );
      }
    }
  }, [books]);

  const giftCards = useMemo(
    () => store.filter((item) => item.type === "giftcard"),
    [store],
  );

  const couponGiftCard = useMemo(() => {
    let matchStripeID;
    if (!!coupon?.applies_to) {
      matchStripeID = coupon.applies_to.products.filter((stripeID) => {
        return giftCards[0]?.stripe_id === stripeID;
      });

      if (matchStripeID.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [giftCards, coupon]);

  const couponBook = useMemo(() => {
    if (!!coupon?.applies_to) {
      let bookMatch = books.map((book) => {
        return coupon.applies_to?.products.filter((stripeID) => {
          return book.stripe_id === stripeID;
        });
      });

      if (bookMatch.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [books, coupon]);

  const [paymentTime, setPaymentTime] = useState("");

  useEffect(() => {
    if (!!subscription) {
      switch (subscription.id) {
        case "one year":
          setPaymentTime("year");
          break;
        case "three month":
          setPaymentTime("3-months");
          break;
        case "monthly":
          setPaymentTime("mo");
          break;
        default:
          break;
      }
    }
  }, [subscription]);

  const subTotal = useMemo(() => {
    const subTotal = store.reduce(
      (total: number, curr: any) => total + curr.total,
      0,
    );
    return !!subscription && !user.trialUsed
      ? subTotal - subscription.total
      : subTotal;
  }, [store, subscription]);

  const cards: CustomerCard[] = useMemo(
    () =>
      !!customer && !!customer.sources && !!customer.sources.data
        ? [newCard, ...customer.sources.data]
        : [newCard],
    [customer],
  );

  const card: CustomerCard | undefined = useMemo(
    () =>
      !!form.selectedCard
        ? cards.find((c) => c.id === form.selectedCard)
        : undefined,
    [cards, form.selectedCard],
  );

  const shippingObj: shippingOption | undefined = useMemo(
    () =>
      !!selectedShippingOption &&
      shippingOptions &&
      store.filter((p) => p.type !== "subscription" && p.type !== "giftcard")
        .length > 0
        ? shippingOptions.find((s) => s.price === selectedShippingOption.price)
        : undefined,
    [selectedShippingOption, shippingOptions, store],
  );

  const discount = useMemo(() => {
    if (!updateDisscount) {
      return disccountRef.current;
    }
    let discount = 0;

    if (!!coupon) {
      if (!!coupon.applies_to) {
        let specificDiscount = 0;
        store.map((item) => {
          coupon.applies_to?.products?.map((id) => {
            if (id === item.stripe_id) {
              if (!!coupon.percent_off) {
                if (!!subscription && !user.trialUsed) {
                  if (store.length === 1) {
                    specificDiscount = 0;
                  } else {
                    if (item.type !== "subscription") {
                      specificDiscount += getDiscountForSpecificProduct(
                        item.total,
                        coupon,
                      );
                    }
                  }
                } else {
                  specificDiscount += getDiscountForSpecificProduct(
                    item.total,
                    coupon,
                  );
                }
              } else if (!!coupon.amount_off) {
                specificDiscount = coupon.amount_off / 100;
              }
            }
          });
        });
        disccountRef.current = specificDiscount;
        return specificDiscount;
      }
    }

    discount = !!coupon
      ? !!coupon.percent_off
        ? (coupon.percent_off / 100) * subTotal
        : (coupon.amount_off || 0) / 100
      : 0;

    disccountRef.current = discount;

    return discount;
  }, [
    books,
    coupon,
    giftCards,
    subTotal,
    subscription,
    user.user_level,
    updateDisscount,
  ]);

  const balance = useMemo(
    () => (!!customer.bp_balance ? customer.bp_balance : 0),
    [customer.balance],
  );

  useEffect(() => {
    setUseBalance(balance > 0);
    amountBalanceToUse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balance]);

  const amountBalanceToUse = () => {
    let toUse = 0;

    if (!!balance && balance > 0) {
      let amountToPay = 0;
      if (!user.trialUsed) {
        if (!!couponSubscription) {
          amountToPay = subTotal + totalToBeCharged;
        } else if (subscription && store.length === 1) {
          amountToPay = subTotal + totalToBeCharged;
        } else {
          amountToPay = subTotal - discount + totalToBeCharged;
        }
      } else if (user.trialUsed) {
        amountToPay = subTotal - discount;
      }
      toUse = balance > amountToPay ? amountToPay : balance;
    }

    if (toUse <= 0) {
      return 0;
    }
    return toUse;
  };

  const total = useMemo(() => {
    let total = 0;

    if (useBalance) {
      if (!user.trialUsed && !!couponSubscription) {
        total = subTotal - balance;
      } else {
        total = subTotal - discount - balance;
      }
    } else if (!useBalance) {
      if (!user.trialUsed && !!couponSubscription) {
        total = subTotal;
      } else {
        total = subTotal - discount;
      }
    }

    return (total >= 0 ? total : 0) + (!!shippingObj ? shippingObj.value : 0);
  }, [subTotal, discount, balance, useBalance, shippingObj]);

  const disableShipping = useMemo(
    () =>
      store.filter((p) => p.type !== "subscription" && p.type !== "giftcard")
        .length === 0,
    [store],
  );

  const renderPaypal = useCallback(() => {
    if (!!subscription) {
      let buttonId = PAYPAL.buttons.monthly;

      if (subscription.id === "three month") {
        buttonId = PAYPAL.buttons.three_month;
      } else if (subscription.id === "one year") {
        buttonId = PAYPAL.buttons.one_year;
      }

      const productTrackingData = {
        facebook: {
          content_name: subscription.title,
          content_category: subscription.type,
          content_ids: [subscription.id],
          num_items: subscription.qty,
          value: subscription.total,
          currency: "USD",
        },
        amplitude: {
          id: subscription.id,
          name: subscription.title,
          category: subscription.type,
          number_items: subscription.qty,
          value: subscription.total,
          currency: "USD",
        },
        pinterest: {
          value: subscription.total,
          order_quantity: subscription.qty,
          currency: "USD",
          line_items: [
            {
              product_name: subscription.title,
              product_category: subscription.type,
              product_id: subscription.id,
              product_price: subscription.total,
              product_quantity: subscription.qty,
            },
          ],
        },
        google: {
          event: "add_to_cart",
          details: `User added ${subscription.qty} ${subscription.title} to the cart`,
        },
      };

      return (
        <div className="d-flex justify-content-center align-items-center flex-column">
          <h4 style={{ color: "#727272" }}>
            Please click here if you'd like to pay with PayPal.
          </h4>
          <PaypalButton
            buttonId={buttonId}
            userId={user.id!}
            subscription={productTrackingData}
            disabled={
              !!user.last_pay_pal_transaction_started_at &&
              moment()
                .utc()
                .add(4, "hours")
                .diff(
                  moment(user.last_pay_pal_transaction_started_at),
                  "hours",
                ) < 1
            }
          />
          <h4 style={{ color: "#727272" }}>
            Or fill out the info below to pay with credit card.
          </h4>
        </div>
      );
    }
    return null;
  }, [subscription, user.id, user.last_pay_pal_transaction_started_at]);

  const createPurchase = useCallback(() => {
    if (!purchaseValid(form, shippingObj, disableShipping)) {
      return;
    } else if (
      form.cardYear === currentYear &&
      form.cardMonth! < currentMonth
    ) {
      Modal.error({
        title: "Error",
        content: "Invalid card month",
      });
      return;
    }
    sagaMiddleware.run<any>(getCustomerInfo, (err?: string) => {
      if (!!err) {
        Modal.error({
          title: "Error",
          content: err,
        });
      }
    });

    const purchase: Purchase = getPurchase(
      form,
      store,
      !!shippingObj && !disableShipping ? shippingObj.value : 0,
      disableShipping,
      useBalance,
      balance,
      bookYearSub,
      !!coupon ? coupon : undefined,
      shippingObj,
    );

    let cardInfo = null;

    if (!!form.selectedCard) {
      purchase.card_token = form.selectedCard;
    } else {
      cardInfo = {
        cardNumber: form.cardNumber!,
        cardName: form.cardName!,
        cardCVV: form.cardCVV!,
        cardMonth: form.cardMonth!,
        cardYear: form.cardYear!,
        customerId:
          !!customer && !!customer.id
            ? customer.id
            : !customer.id && !!user.guest_user
              ? user.main_user.stripe_id
              : user.stripe_id,
      };
    }

    setLoading(true);
    setDisableButton(true);
    setUpdateDisscount(false);
    sagaMiddleware.run<any>(
      createPurchaseSaga,
      purchase,
      total,
      cardInfo,
      (err?: string) => {
        setLoading(false);

        if (!!err) {
          Modal.error({
            title: "Error",
            content: err,
          });
          setUpdateDisscount(true);
          setDisableButton(false);
        } else {
          if (milestoneId) {
            history.push(
              `/babypages/save?milestoneId=${milestoneId}&from=checkout`,
            );
          } else {
            if (!!subscription) {
              history.push("/?product=subscription");
            } else {
              history.push("/");
            }
          }
          // Quora Tracking - New purchase
          trackAction("trackNewPurchase", {
            google: {
              event: "new_purchase",
            },
          });
          if (user.source === "baby-list" && !user.trialUsed) {
            trackAction("babyListPurchase", {
              google: {
                event: "babylist_purchase",
              },
            });
          }

          success({
            title: "Thank you! Your purchase has been processed.",
            content: (
              <p>
                We love our customers, and we hope you love BabyPage. If you
                have any problems, please contact us at support@babypage.com
              </p>
            ),
          });
        }
      },
    );
  }, [
    history,
    form,
    store,
    useBalance,
    balance,
    coupon,
    shippingObj,
    bookYearSub,
    customer,
    disableShipping,
    user.stripe_id,
    total,
  ]);

  const cartItemsHtml = useMemo(() => {
    return store.map((item, index) => {
      let cartItemContainerClasses = "d-flex justify-content-between";

      let markup;
      if (item.type === "book") {
        const selectedBookOptions = item?.options.map((book) => {
          if (book.selected) {
            const bookData = {
              price: book.price,
              qty: book.qty,
              name: `${book.title} Book`,
              extra_pages: book.extra_pages ? book.extra_pages.qty : null,
              extra_pages_price: book.extra_pages
                ? book.extra_pages.price * book.extra_pages.qty
                : null,
              extra_pages_name: book.extra_pages
                ? book.extra_pages.title
                : null,
            };
            return bookData;
          }
        });

        const bookMarkup = selectedBookOptions.map((selectedBook) => {
          if (selectedBook) {
            return (
              <>
                {selectedBook.extra_pages && selectedBook.extra_pages_price ? (
                  <>
                    <div className={cartItemContainerClasses}>
                      <span>
                        {selectedBook.qty} - {selectedBook.name}
                      </span>
                      <strong>
                        ${(selectedBook.price * selectedBook.qty).toFixed(2)}
                      </strong>
                    </div>
                    <div className={cartItemContainerClasses}>
                      <span>
                        {selectedBook.extra_pages} -{" "}
                        {selectedBook.extra_pages_name}
                      </span>
                      <strong>
                        ${selectedBook.extra_pages_price.toFixed(2)}
                      </strong>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={cartItemContainerClasses}>
                      <span>
                        {selectedBook.qty} - {selectedBook.name}
                      </span>
                      <strong>
                        ${(selectedBook.price * selectedBook.qty).toFixed(2)}
                      </strong>
                    </div>
                  </>
                )}
              </>
            );
          } else {
            return null;
          }
        });
        markup = bookMarkup;
      }

      if (item.type === "giftcard") {
        markup = (
          <div className={cartItemContainerClasses}>
            <span>
              ${item.total} {item.subtitle}
            </span>
            <strong>${item.total.toFixed(2)}</strong>
          </div>
        );
      }

      if (item.type === "subscription") {
        markup = (
          <div className="d-flex flex-column">
            <div className={cartItemContainerClasses}>
              <span>{item.subtitle}</span>
              <strong>${item.total.toFixed(2)}</strong>
            </div>
            {!!subscription && !user.trialUsed && (
              <p className="mb-0 pb-1">
                <em>
                  {user.source === "baby-list"
                    ? `You will not be charged until after your free trial ends and you
                  may cancel at any time.`
                    : `You will not be charged until after the 7-day free trial ends and you may cancel at any time.`}
                </em>
              </p>
            )}
          </div>
        );
      }
      return markup;
    });
  }, [store]);

  // This date could be changed if the user changes the date on his device
  const getDayForSubscription = () => {
    const momentDate = moment().add(1, "week").format("MM/DD/YY");
    return momentDate;
  };

  const autoApplyCouponBabyList = useMemo(() => {
    if (
      user.source === "baby-list" &&
      user.user_level === 0 &&
      !user.trialUsed
    ) {
      setShowBabyListModal(true);
      applyCoupon("BABYLIST");
    }
  }, [user]);

  const toolTipTitle = () => {
    if (user.source === "baby-list") {
      return `You have a PLUS subscription in your cart, but you will not be charged for it until your free trial period expires.`;
    } else {
      return `You have a PLUS subscription in your cart, but you will not be charged for it until your 7-day free trial period expires.`;
    }
  };

  const totalToBeCharged = useMemo(() => {
    let totalToBeCharged = 0;
    if (subscription && !user.trialUsed) {
      if (!!couponSubscription && !!coupon) {
        totalToBeCharged =
          subscription.total -
          getDiscountForSpecificProduct(subscription.total, coupon);
      } else if (!!coupon?.percent_off && coupon.applies_to === null) {
        totalToBeCharged =
          subscription.total -
          getDiscountForSpecificProduct(subscription.total, coupon);
      } else if (store.length === 1 && coupon?.applies_to === null) {
        totalToBeCharged = subscription.total - discount;
      } else {
        totalToBeCharged = subscription.total;
      }
    }

    if (totalToBeCharged < 0) {
      totalToBeCharged = 0;
    }

    return totalToBeCharged;
  }, [store, subscription, coupon]);

  const leftOverDiscount = useMemo(() => {
    let leftOverDiscount = 0;
    if (!user.trialUsed) {
      if (!!couponSubscription && !user.trialUsed) {
        return 0;
      } else if (!!couponGiftCard || !!couponBook) {
        return 0;
      } else if (subscription && store.length === 1) {
        return 0;
      }
      subTotal - discount < 0
        ? (leftOverDiscount = discount - subTotal)
        : (leftOverDiscount = 0);
    }

    return leftOverDiscount;
  }, [discount, subTotal]);

  const leftOverBalance = useMemo(() => {
    let leftOverBalance = 0;

    if (useBalance) {
      if (!!couponSubscription) {
        leftOverBalance = amountBalanceToUse() - subTotal;
      } else if (subscription && store.length === 1) {
        leftOverBalance = amountBalanceToUse() - subTotal;
      } else {
        leftOverBalance =
          amountBalanceToUse() - subTotal + discount - leftOverDiscount;
      }

      if (leftOverBalance <= 0) {
        return 0;
      }

      return leftOverBalance;
    } else {
      return 0;
    }
  }, [useBalance, balance, coupon, discount, leftOverDiscount]);

  const addressToUse = form.sameAsBilling
    ? {
        street: form.billingStreet,
        city: form.billingCity,
        state: form.billingState,
        zipcode: form.billingZipcode,
        country: form.billingCountry,
      }
    : {
        street: form.shippingStreet || form.billingStreet,
        city: form.shippingCity || form.billingCity,
        state: form.shippingState || form.billingState,
        zipcode: form.shippingZipcode || form.billingZipcode,
        country: form.shippingCountry || form.billingCountry,
      };

  const isAddressEmpty =
    !addressToUse.street &&
    !addressToUse.city &&
    !addressToUse.state &&
    !addressToUse.zipcode;

  return (
    <BaseLayout
      title="BabyPage - Checkout"
      pageTitle="Checkout"
      hideChildMenu
      hideHeaderChildMenu
    >
      <div className="margin-bottom-navigation-small">
        {/*  {!!subscription && store.length === 1 && (
        <>
          <CustomDivider text="USE PAYPAL" />
          {renderPaypal()}
        </>
      )} */}

        <Modal
          centered={true}
          visible={showBabyListModal}
          footer={false}
          onCancel={() => setShowBabyListModal(false)}
        >
          <div className="container text-center">
            <p className="mt-4">
              Complete checkout to activate your{" "}
              <strong>3 month FREE trial</strong> and access your BabyPage PLUS
              membership features. You will not be charged until after your 3
              month trial period and you may cancel at any time.
            </p>
            <Button
              type="primary"
              shape="round"
              onClick={() => {
                setShowBabyListModal(false);
              }}
            >
              Continue
            </Button>
          </div>
        </Modal>
        <CustomDivider
          text="PAYMENT INFORMATION"
          customClasses={["paymentInfo"]}
        />
        <div className="container">
          {!!cards.length && (
            <Select
              className="w-100 my-1"
              value={form.selectedCard}
              onChange={(selectedCard: string | undefined) =>
                setForm((state) => ({ ...state, selectedCard }))
              }
            >
              {cards.map((card: CustomerCard, index: number) => (
                <Option key={index} value={card.id}>
                  {!!card.id ? `${card.brand} - ****${card.last4}` : "New Card"}
                </Option>
              ))}
            </Select>
          )}
          {!!card ? (
            <div className="row justify-content-center align-items-center">
              <div className="col-12 col-lg-6 my-1">
                <label>Card Number</label>
                <h3 className="m-0">**** **** **** {card.last4}</h3>
              </div>
              <div className="col-12 col-lg-6 my-1">
                <label>Card Holder Name</label>
                <h3 className="m-0">{card.name}</h3>
              </div>
              <div className="col-3 my-1">
                <label>CVV</label>
                <h3 className="m-0">***</h3>
              </div>
              <div className="col-4 my-1">
                <label>Expire Month</label>
                <h3 className="m-0">{card.exp_month}</h3>
              </div>
              <div className="col-5 my-1">
                <label>Expire Year</label>
                <h3 className="m-0">{card.exp_year!.toString()}</h3>
              </div>
            </div>
          ) : (
            <div className="row justify-content-center align-items-center">
              <div
                className={`col-12 col-lg-6 my-1${
                  form.cardNumberInvalid ? " has-error" : ""
                }`}
              >
                <label>Card Number</label>
                <InputNumber
                  maxLength={16}
                  className={`w-100${form.cardNumberInvalid ? " has-error" : ""}`}
                  value={form.cardNumber}
                  onChange={(cardNumber) =>
                    setForm((state) => ({
                      ...state,
                      cardNumber,
                      cardNumberInvalid:
                        !!cardNumber && !isCreditCard(cardNumber.toString()),
                    }))
                  }
                />
                {form.cardNumberInvalid && (
                  <div className="ant-form-explain">
                    Please provide a valid card.
                  </div>
                )}
              </div>
              <div className="col-12 col-lg-6 my-1">
                <label>Card Holder Name</label>
                <Input
                  maxLength={60}
                  className="w-100"
                  value={form.cardName || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    setForm((state) => ({ ...state, cardName: value }));
                  }}
                />
              </div>
              <div
                className={`col-3 my-1${form.cardCVVInvalid ? " has-error" : ""}`}
              >
                <label>CVV</label>
                <Input.Password
                  maxLength={4}
                  className={`w-100${form.cardCVVInvalid ? " has-error" : ""}`}
                  value={form.cardCVV}
                  onChange={(e) => {
                    e.persist();
                    const cardCVV = e.target.value.replace(/[^\d]/g, "");

                    setForm((state) => ({
                      ...state,
                      cardCVV,
                      cardCVVInvalid: !!cardCVV && cardCVV.length < 3,
                    }));
                  }}
                />
                {form.cardCVVInvalid && (
                  <div className="ant-form-explain">
                    Please provide a valid CVV.
                  </div>
                )}
              </div>
              <div className="col-4 my-1">
                <label>Expire Month</label>
                <Select
                  className="w-100 my-1"
                  value={form.cardMonth?.toString()}
                  onChange={(selectedMonth: string | undefined) =>
                    setForm((state) => ({
                      ...state,
                      cardMonth: parseInt(selectedMonth!),
                    }))
                  }
                >
                  {months.map((month: string, index: number) => (
                    <Option key={index} value={month}>
                      {month}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="col-5 my-1">
                <label>Expire Year</label>
                <Select
                  className="w-100 my-1"
                  value={form.cardYear?.toString()}
                  onChange={(selectedYear: string | undefined) =>
                    setForm((state) => ({
                      ...state,
                      cardYear: parseInt(selectedYear!),
                    }))
                  }
                >
                  {expireYears.map((year: string, index: number) => (
                    <Option key={index} value={year}>
                      {year}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          )}
        </div>
        <CustomDivider text="DISCOUNT" />
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-9 col-md-6 col-lg-5">
              <Input
                className="w-100"
                placeholder="Coupon Code"
                value={couponText}
                maxLength={30}
                onChange={(e) => setCouponText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    applyCoupon(couponText);
                  }
                }}
              />
            </div>
            <div className="col-3">
              <Button
                type="primary"
                shape="round"
                size="small"
                className="mobile-only"
                onClick={() => applyCoupon(couponText)}
                disabled={couponText.length === 0 || loading}
              >
                Apply
              </Button>
              <Button
                type="primary"
                shape="round"
                className="mobile-hidden"
                onClick={() => applyCoupon(couponText)}
                disabled={couponText.length === 0 || loading}
              >
                Apply
              </Button>
            </div>
          </div>
          {!!coupon && (
            <p className="light-green">Coupon {coupon.name} applied.</p>
          )}
        </div>
        <CustomDivider text="BILLING" />
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-6">
              <label>First Name</label>
              <Input
                className="w-100"
                maxLength={30}
                value={form.billingFirstName}
                onChange={(e) => {
                  const value = e.target.value;
                  setForm((state) => ({
                    ...state,
                    billingFirstName: value,
                  }));
                }}
              />
            </div>
            <div className="col-6">
              <label>Last Name</label>
              <Input
                className="w-100"
                maxLength={30}
                value={form.billingLastName}
                onChange={(e) => {
                  const value = e.target.value;
                  setForm((state) => ({
                    ...state,
                    billingLastName: value,
                  }));
                }}
              />
            </div>
            <div className="col-12">
              <label>Email</label>
              <Input
                className="w-100"
                type="email"
                maxLength={60}
                disabled
                value={form.billingEmail}
                onChange={(e) => {
                  const value = e.target.value;
                  setForm((state) => ({
                    ...state,
                    billingEmail: value,
                  }));
                }}
              />
            </div>
            <div className="col-12">
              <label>Street Address</label>
              <Input
                className="w-100"
                value={form.billingStreet}
                onChange={(e) => {
                  const value = e.target.value;
                  setForm((state) => ({
                    ...state,
                    billingStreet: value,
                  }));
                }}
              />
            </div>
            <div className="col-4">
              <label>Country</label>
              <Select
                className="w-100"
                value={selectedCountry?.name}
                onChange={(selectedCountryValue: any) => {
                  setSelectedCountry(
                    countryOptions.find(
                      (country: CountryOptions) =>
                        country.name === selectedCountryValue,
                    ),
                  );
                  if (form.sameAsBilling) {
                    setSelectedShippingCountry(
                      countryOptions.find(
                        (country: CountryOptions) =>
                          country.name === selectedCountryValue,
                      ),
                    );
                  }
                }}
              >
                {countryOptions.map(
                  (country: CountryOptions, index: number) => {
                    return (
                      <Option key={index} value={country.name}>
                        {country.name}
                      </Option>
                    );
                  },
                )}
              </Select>
            </div>
            <div className="col-8 col-sm-4">
              <label>State/Province</label>
              <Input
                className="w-100"
                value={form.billingState}
                onChange={(e) => {
                  const value = e.target.value;
                  setForm((state) => ({
                    ...state,
                    billingState: value,
                  }));
                }}
              />
            </div>
            <div className="col-8 col-sm-4">
              <label>City</label>
              <Input
                className="w-100"
                value={form.billingCity}
                onChange={(e) => {
                  const value = e.target.value;
                  setForm((state) => ({
                    ...state,
                    billingCity: value,
                  }));
                }}
              />
            </div>
            <div className="col-4 col-sm-6">
              <label>ZIP Code</label>
              <Input
                className="w-100"
                value={form.billingZipcode}
                onChange={(e) => {
                  const value = e.target.value;
                  setForm((state) => ({
                    ...state,
                    billingZipcode: value,
                  }));
                }}
                type="text"
              />
            </div>
            <div className="col-12 col-sm-6">
              <label>Billing Phone Number</label>
              <Input
                className="w-100"
                value={form.billingPhoneNumber}
                maxLength={30}
                onChange={(e) => {
                  const value = e.target.value;
                  setForm((state) => ({
                    ...state,
                    billingPhoneNumber: value.replace(/[^0-9]/g, ""),
                  }));
                }}
              />
            </div>
          </div>
        </div>
        {!disableShipping && (
          <>
            <CustomDivider text="SHIPPING" />
            <div className="container">
              <div className="row justify-content-center align-items-center">
                {!form.sameAsBilling && (
                  <>
                    {" "}
                    <div className="col-6">
                      <label>First Name</label>
                      <Input
                        maxLength={30}
                        className="w-100"
                        value={form.shippingFirstName}
                        onChange={(e) => {
                          const value = e.target.value;
                          setForm((state) => ({
                            ...state,
                            shippingFirstName: value,
                          }));
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <label>Last Name</label>
                      <Input
                        maxLength={30}
                        className="w-100"
                        value={form.shippingLastName}
                        onChange={(e) => {
                          const value = e.target.value;
                          setForm((state) => ({
                            ...state,
                            shippingLastName: value,
                          }));
                        }}
                      />
                    </div>
                    <div className="col-12">
                      <label>Email</label>
                      <Input
                        maxLength={60}
                        className="w-100"
                        type="email"
                        value={form.shippingEmail}
                        onChange={(e) => {
                          const value = e.target.value;
                          setForm((state) => ({
                            ...state,
                            shippingEmail: value,
                          }));
                        }}
                      />
                    </div>
                    <div className="col-12">
                      <label>Street Address</label>
                      <Input
                        className="w-100"
                        value={form.shippingStreet}
                        onChange={(e) => {
                          const value = e.target.value;
                          setForm((state) => ({
                            ...state,
                            shippingStreet: value,
                          }));
                        }}
                      />
                    </div>
                    <div className="col-4 col-sm-4">
                      <label>Country</label>
                      <Select
                        className="w-100"
                        value={selectedShippingCountry?.name}
                        onChange={(selectedCountryValue: any) => {
                          setSelectedShippingCountry(
                            countryOptions.find(
                              (country: CountryOptions) =>
                                country.name === selectedCountryValue,
                            ),
                          );
                        }}
                      >
                        {countryOptions.map(
                          (country: CountryOptions, index: number) => {
                            return (
                              <Option key={index} value={country.name}>
                                {country.name}
                              </Option>
                            );
                          },
                        )}
                      </Select>
                    </div>
                    <div className="col-8 col-sm-4">
                      <label>State/Province</label>
                      <Input
                        className="w-100"
                        value={form.shippingState}
                        onChange={(e) => {
                          const value = e.target.value;
                          setForm((state) => ({
                            ...state,
                            shippingState: value,
                          }));
                        }}
                      />
                    </div>
                    <div className="col-8 col-sm-4">
                      <label>City</label>
                      <Input
                        className="w-100"
                        value={form.shippingCity}
                        onChange={(e) => {
                          const value = e.target.value;
                          setForm((state) => ({
                            ...state,
                            shippingCity: value,
                          }));
                        }}
                      />
                    </div>
                    <div className="col-4 col-sm-12">
                      <label>ZIP Code</label>
                      <Input
                        className="w-100"
                        value={form.shippingZipcode}
                        onChange={(e) => {
                          const value = e.target.value;
                          setForm((state) => ({
                            ...state,
                            shippingZipcode: value,
                          }));
                        }}
                        type="text"
                      />
                    </div>
                  </>
                )}
                <div className="col-12 my-2">
                  <Checkbox
                    defaultChecked={form.sameAsBilling}
                    onChange={(e) => {
                      const value = e.target.value;
                      setForm((state) => ({
                        ...state,
                        sameAsBilling: e.target.checked,
                      }));
                    }}
                  >
                    Same as Billing
                  </Checkbox>
                </div>

                <div className="col-12">
                  <label>Shipping Speed: </label>
                  {!!shippingOptions &&
                    !!shippingOptions.length &&
                    selectedShippingOption && (
                      <Select
                        className="w-100 my-1"
                        value={selectedShippingOption.price}
                        onChange={(
                          selectedShippingOption: string | undefined,
                        ) =>
                          setSelectedShippingOption(
                            shippingOptions.find(
                              (shippingOption: shippingOption) =>
                                shippingOption.price === selectedShippingOption,
                            ),
                          )
                        }
                      >
                        {shippingOptions.map(
                          (shippingOption: shippingOption, index: number) => (
                            <Option key={index} value={shippingOption.price}>
                              {shippingOption.name}{" "}
                              <b>${shippingOption.price}</b>
                            </Option>
                          ),
                        )}
                      </Select>
                    )}
                  <p
                    className="my-1"
                    style={{ fontFamily: "ProximaNovaLight" }}
                  >
                    Prior to shipping, processing and printing requires 3-5
                    business days.{" "}
                    <a href={`${PAGE_URL}/support#shipping`} target="_blank">
                      More info.{" "}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </>
        )}

        <CustomDivider
          text="PURCHASE DETAILS"
          parentCustomClasses="my-5"
          customClasses={["paymentInfo"]}
        />
        <div className="container purchase-details-container">
          {/* Pricing info details */}
          {books.length > 0 ? (
            <>
              <div className="col-12 my-2 mb-4">
                <Checkbox
                  defaultChecked={false}
                  onChange={(e) => {
                    setDisclaimerCheck(!disclaimerCheck);
                    if (disclaimerCheck && !showTip) {
                      setShowTip(true);
                    }
                  }}
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  I certify I have proofread my book(s).
                </Checkbox>

                <MessageTip
                  open={showTip}
                  onVisibleChange={() => {
                    setShowTip(!showTip);
                  }}
                >
                  <div className=" my-4">
                    <img
                      style={{
                        display: "block",
                        margin: "0 auto",
                        width: 120,
                        height: "auto",
                      }}
                      src={icon_warning}
                      alt="warning-icon"
                      className="mb-4"
                    />
                    <Typography.Title
                      className="mb-3 m-0 text-center"
                      level={3}
                    >
                      Have you proofread your book(s)?
                    </Typography.Title>
                    <Typography.Text>
                      Be sure to check the following before placing your order:
                    </Typography.Text>
                    <ul className="mt-3 pl-3">
                      <li>
                        Check that pages are in order – babypages are NOT
                        automatically sorted
                      </li>
                      <li>
                        Confirm all images fit within trim lines to avoid
                        unintended cropping
                      </li>
                      <li>
                        Proofread all content including dates, spelling, and
                        punctuation
                      </li>
                    </ul>
                    <Button
                      type="link"
                      shape="round"
                      onClick={() => {
                        setShowBooksModal(true);
                        setShowTip(false);
                        trackAction(
                          "Book_Preview",
                          {
                            google: {
                              event: "book_preview_from_proofread",
                              details: `User ${user.email} has clicked the See Preview link`,
                            },
                          },
                          true,
                        );
                      }}
                      className="my-3 mx-auto d-table"
                    >
                      See Book Preview
                    </Button>
                  </div>
                </MessageTip>
                <div className="d-flex justify-content-between pt-1">
                  <span
                    className="book-preview-link"
                    onClick={() => {
                      setShowBooksModal(true);
                      trackAction(
                        "Book_Preview",
                        {
                          google: {
                            event: "book_preview",
                            details: `User ${user.email} has clicked the See Preview link`,
                          },
                        },
                        true,
                      );
                    }}
                  >
                    See Preview
                  </span>
                  <a
                    href={`https://babypage.com/support`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ fontSize: "14px" }}
                  >
                    Refund Policy
                  </a>
                </div>
              </div>

              <Modal
                visible={showBooksModal}
                centered
                onCancel={() => setShowBooksModal(false)}
                footer={null}
                bodyStyle={{ padding: 0 }}
                closable={false}
              >
                {!!loading ? (
                  <div className="my-3 mx-auto d-table">
                    <Spin
                      indicator={
                        <Icon type="loading" style={{ fontSize: 24 }} spin />
                      }
                    />
                  </div>
                ) : (
                  <div className="container ">
                    {books.map((item, index) => {
                      let marginClass =
                        index === 0 && books.length !== 1
                          ? "pt-4 pb-2"
                          : index === 0 && books.length === 1
                            ? "pt-4 pb-4"
                            : index === books.length - 1
                              ? "pt-2 pb-4"
                              : "py-2";

                      return (
                        <BookDisclaimer
                          book={item}
                          marginClass={marginClass}
                          setLoading={setLoading}
                          loading={loading}
                        />
                      );
                    })}
                  </div>
                )}
              </Modal>
            </>
          ) : null}

          {cartItemsHtml}
          <div
            style={{
              height: 1,
              border: "1px solid #ccc",
            }}
          ></div>

          <div className="d-flex justify-content-between pt-1">
            <span>Subtotal</span>
            <strong>
              {/* {!!subscription && !user.trialUsed && (
              <Tooltip
                title={`You have a subscription in your cart, the subscription is 7 days free, so you will not pay anything for it now, but you will start to charge after this free period.`}
              >
                <Icon style={{ marginRight: 5 }} type="info-circle" />
              </Tooltip>
            )} */}
              ${subTotal.toFixed(2)}
            </strong>
          </div>

          <div className="d-flex justify-content-between">
            <span>Discount</span>
            <strong>
              - ${" "}
              {!user.trialUsed &&
              !!subscription &&
              coupon?.percent_off &&
              (couponSubscription || !coupon.applies_to)
                ? (
                    discount +
                    getDiscountForSpecificProduct(subscription.total, coupon)
                  ).toFixed(2)
                : discount.toFixed(2)}
            </strong>
          </div>

          <div className="gift-card-row">
            <div className="d-flex justify-content-between">
              <span>Gift Card Applied</span>
              <strong>- ${amountBalanceToUse().toFixed(2)}</strong>
            </div>

            <div>
              {balance > 0 && (
                <p className="mb-0">
                  <Checkbox
                    checked={useBalance}
                    onChange={(e) => setUseBalance(e.target.checked)}
                  >
                    Do you want to use it?
                  </Checkbox>
                </p>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-between pb-1">
            <span>Shipping</span>
            <strong>
              $
              {!disableShipping && !!selectedShippingOption
                ? selectedShippingOption.price
                : "0.00"}
            </strong>
          </div>

          <div
            style={{
              height: 1,
              border: "1px dashed #ccc",
            }}
          ></div>

          <div className="d-flex justify-content-between pt-1">
            <span>TOTAL Charged Today</span>
            <strong>${total.toFixed(2)}</strong>
          </div>
          {!!subscription && !user.trialUsed && (
            <div className="d-flex justify-content-between pt-1">
              <span>TOTAL to be Charged {getDayForSubscription()}</span>
              <strong>
                <Tooltip title={toolTipTitle}>
                  <Icon style={{ marginRight: 5 }} type="info-circle" />
                </Tooltip>
                $
                {totalToBeCharged - leftOverBalance > 0
                  ? (
                      totalToBeCharged -
                      leftOverBalance -
                      leftOverDiscount
                    ).toFixed(2)
                  : "0.00"}
              </strong>
            </div>
          )}
        </div>
        {loading && (
          <div className="my-3 mx-auto d-table">
            <Spin
              indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
            />
          </div>
        )}

        <Button
          type="primary"
          shape="round"
          onClick={() => {
            if (books && books.length > 0) {
              setShowAddressValidation(true);
            } else {
              createPurchase();
            }
          }}
          disabled={
            books.length > 0 && !loading ? disclaimerCheck : disableButton
          }
          className="my-3 mx-auto d-table"
        >
          Complete Purchase
        </Button>

        {!!subscription ? (
          <>
            <div className="d-flex justify-content-center align-items-center flex-column my-4">
              <h2 className="reminder-header">AUTOMATIC RENEWAL REMINDER</h2>
              <ul className="mx-2 reminder-list">
                <li>
                  By completing your purchase, you are signing up for a BabyPage
                  PLUS subscription and agree to the subscription terms. You
                  understand that your subscription will continue to
                  automatically renew until you cancel.
                </li>
                <li>
                  Your payment method will be automatically charged every
                  subscription cycle and timing will be based on the PLUS
                  subscription selected; every 30 days, every 90 days, or every
                  365 days.
                </li>
                <li>
                  You may <Link to="/signin">cancel</Link> your subscription at
                  any time in your account settings, or by contacting us at{" "}
                  <a href="mailto: support@babypage.com">
                    support@babypage.com
                  </a>
                  . Cancellations will take effect at the end of your current
                  billing period. For more information, see our{" "}
                  <a
                    target="_blank"
                    href="https://babypage.com/terms-of-service/"
                  >
                    terms & conditions.
                  </a>
                </li>
              </ul>
              <p className="text-center mt-4 trial-text">
                <strong>7-Day Free Trial:</strong> Users within the 7-day free
                trial period are not entitled to the 10% off discount or free
                shipping benefits.
              </p>
            </div>
          </>
        ) : null}
      </div>
      <MessageTip
        open={showAddressValidation}
        onVisibleChange={() => {
          setShowAddressValidation(!showAddressValidation);
        }}
      >
        <div className=" my-4">
          <img
            style={{
              display: "block",
              margin: "0 auto",
              width: 90,
              height: "auto",
            }}
            src={icon_warning}
            alt="warning-icon"
            className="mb-4"
          />
          <Typography.Title className="mb-3 m-0 text-center" level={3}>
            Is this your correct shipping address?
          </Typography.Title>
          <Typography.Text style={{ fontSize: "23px" }}>
            {isAddressEmpty ? (
              <div className="text-center">Please fill the address form</div>
            ) : (
              <>
                <div className="text-center">{addressToUse.street}</div>
                <div className="text-center">
                  {addressToUse.city}, {addressToUse.state}{" "}
                  {addressToUse.zipcode}
                </div>
                <div className="text-center">{addressToUse.country}</div>
              </>
            )}
          </Typography.Text>
          <div
            className="mt-5"
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "40px",
            }}
          >
            <Button
              type="ghost"
              shape="round"
              onClick={() => {
                setShowAddressValidation(!showAddressValidation);
              }}
              style={{ width: "120px" }}
            >
              No
            </Button>
            <Button
              type="ghost"
              shape="round"
              onClick={() => {
                createPurchase();
                setShowAddressValidation(!showAddressValidation);
              }}
              style={{ width: "120px" }}
            >
              Yes
            </Button>
          </div>
        </div>
      </MessageTip>
    </BaseLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  user: state.user,
  customer: state.customer,
  milestoneId: state.bpHelper.milestoneId,
  products: state.product.products,
  draftBabyPage: state.bpHelper.draftBabyPage,
});

export default connect(mapStateToProps)(CheckoutPage);
