import { Button, Input, notification, Table, Tooltip } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BookListInterface, BooksListItem } from "../../../interfaces";
import { sagaMiddleware } from "../../../store";
import { getBooksAdmin } from "../../../store/sagas/books";
import { capitalizeTexts } from "../../../utils/string";

const AdminBooksPage: React.FC = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [debounce, setDebounce] = useState<NodeJS.Timeout>();
  const [booksData, setBooksData] = useState<BookListInterface>({
    data: [],
    total: 0,
    per_page: 10,
    current_page: 1,
    last_page: 1,
    next_page_url: null,
    prev_page_url: null,
    has_more_pages: false,
    first_item: 1,
    last_item: 1
  });

  useEffect(() => {
    setLoading(true);

    sagaMiddleware.run<any>(
      getBooksAdmin,
      "",
      (err?: string, response?: BookListInterface) => {
        setLoading(false);

        if (!!err) {
          notification.error({
            message: "Error",
            description: err
          });
        } else if (!!response) {
          setBooksData(response);
        }
      }
    );
  }, []);

  const filterBooks = useCallback((search: string = "", page: number = 1) => {
    setLoading(true);

    sagaMiddleware.run<any>(
      getBooksAdmin,
      search,
      (err?: string, response?: BookListInterface) => {
        setLoading(false);

        if (!!err) {
          notification.error({
            message: "Error",
            description: err
          });
        } else if (!!response) {
          setBooksData(response);
        }
      },
      page
    );
  }, []);

  const onChangeSearch = useCallback(
    (search: string = "") => {
      setSearch(search);

      if (debounce) clearTimeout(debounce);

      setDebounce(
        setTimeout(() => {
          filterBooks(search, 1);
        }, 100)
      );
    },
    [debounce, filterBooks]
  );

  return (
    <div className="m-3">
      <h1 className="title">Books List</h1>
      <div className="row justify-content-center align-items-center mx-3">
        <div className="col-6 col-lg-3 my-1">
          <Input
            value={search}
            onChange={e => {
              onChangeSearch(e.target.value);
            }}
            placeholder="Search book"
            className="w-100"
          />
        </div>
      </div>
      <Table
        dataSource={booksData.data}
        loading={loading}
        bordered
        pagination={{
          current: booksData.current_page,
          total: booksData.total,
          pageSize: 10
        }}
        onChange={e => filterBooks(search, e.current || 1)}
        className="my-3 elevation-5"
        rowKey={(record: BooksListItem) => record.id.toString()}
        onRow={(record: BooksListItem) => {
          return {
            onClick: () => history.push(`/admin/books/detail?id=${record.id}`)
          };
        }}
      >
        <Table.Column
          title="ID"
          dataIndex="id"
          key="id"
          render={(text: any, record: BooksListItem) => (
            <span>{record.id}</span>
          )}
        />
        <Table.Column
          title="Title"
          dataIndex="title"
          key="title"
          ellipsis
          render={(text: any, record: BooksListItem) => (
            <Tooltip title={record.title}>
              <span>{record.title}</span>
            </Tooltip>
          )}
        />
        <Table.Column
          title="Subtitle"
          dataIndex="subtitle"
          key="subtitle"
          ellipsis
          render={(text: any, record: BooksListItem) => (
            <Tooltip title={record.subtitle}>
              <span>{record.subtitle}</span>
            </Tooltip>
          )}
        />
        <Table.Column
          title="User Name"
          dataIndex="username"
          key="username"
          ellipsis
          render={(text: any, record: BooksListItem) => (
            <Tooltip
              title={`${record.parent_first_name} ${record.parent_last_name}`}
            >
              <span>
                {capitalizeTexts(record.parent_first_name ?? "")}{" "}
                {capitalizeTexts(record.parent_last_name ?? "")}
              </span>
            </Tooltip>
          )}
        />
        <Table.Column
          title="User Email"
          dataIndex="email"
          key="email"
          ellipsis
          render={(text: any, record: BooksListItem) => (
            <Tooltip title={record.parent_email}>
              <span>{record.parent_email}</span>
            </Tooltip>
          )}
        />
        <Table.Column
          title="Creation Date"
          dataIndex="created_at"
          key="created_at"
          render={(text: any, record: BooksListItem) => (
            <span>{moment(record.created_at).format("MMM[, ]Do[, ]YYYY")}</span>
          )}
        />
        <Table.Column
          title="Is in Cart"
          dataIndex="cart"
          render={(text: any, record: BooksListItem) =>
            record.in_cart ? (
              <Button icon="check" type="primary" />
            ) : (
              <Button icon="close" type="primary" />
            )
          }
        />
      </Table>
    </div>
  );
};

export default AdminBooksPage;
