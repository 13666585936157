import React from "react";

interface Props {
  isSelected?: boolean;
}

const DashBoardIcon: React.FC<Props> = ({isSelected}) =>  {

  if(isSelected) {
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="29"
      viewBox="0 0 20 29"
      className="mx-3 mx-sm-4"
    >
      <g fill="none" fillRule="evenodd">
        <g stroke="#F7ADA5">
          <path
            strokeLinecap="round"
            d="M7 19v.367C7 20.822 8.344 22 10 22c1.657 0 3-1.178 3-2.633V19"
          ></path>
          <path d="M9.97 10.5c.364 0 .727.128 1.025.385l5.893 5.054c.39.333.612.834.612 1.361v6.77c0 .95-.364 1.811-.956 2.434a3.135 3.135 0 01-2.279.996h-8.53c-.89 0-1.696-.382-2.279-.996A3.524 3.524 0 012.5 24.07v-6.775c0-.525.221-1.024.608-1.358l5.838-5.048c.298-.258.66-.388 1.024-.389z"></path>
        </g>
        <circle
          cx="2"
          cy="2"
          r="2"
          fill="#FFAFA5"
          transform="translate(8 1)"
        ></circle>
      </g>
    </svg>
    )
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="29"
        viewBox="0 0 20 29"
        className="mx-3 mx-sm-4"
    >
      <g fill="none" fillRule="evenodd" stroke="#FFF">
        <path d="M7 19v.367C7 20.822 8.344 22 10 22c1.657 0 3-1.178 3-2.633V19"></path>
        <path d="M9.97 10.5c.364 0 .727.128 1.025.385l5.893 5.054c.39.333.612.834.612 1.361v6.77c0 .95-.364 1.811-.956 2.434a3.135 3.135 0 01-2.279.996h-8.53c-.89 0-1.696-.382-2.279-.996A3.524 3.524 0 012.5 24.07v-6.775c0-.525.221-1.024.608-1.358l5.838-5.048c.298-.258.66-.388 1.024-.389z"></path>
      </g>
    </svg>
    );
  }
  
}

export default DashBoardIcon;
