import { Button, Card, Input, Layout, Row, Select, Tag } from "antd";
import { Dictionary } from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";
import { Frame } from "../../interfaces/frame";
import { MessageNotification } from "../../interfaces/messageNotification";
import FrameImageTabs from "./FrameImageTabs";
import InputFromTypeOfMilestone from "./InputFromTypeOfMilestone";

const { Content } = Layout;

interface Props {
  milestoneId: number;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  milestoneForm: any;
  handleImage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setMilestoneForm: Function;
  typeOfMilestoneValue: "holiday" | "weeks" | "months" | "years" | undefined;
  messageNotifications: MessageNotification[];
  groupedFrames: Dictionary<Frame[]>;
  currentTab: string;
  handleClickOnDeleteButton: Function;
}

const AdminMilestoneForm: React.FC<Props> = ({
  milestoneId,
  handleSubmit,
  milestoneForm,
  handleImage,
  setMilestoneForm,
  typeOfMilestoneValue,
  messageNotifications,
  groupedFrames,
  currentTab,
  handleClickOnDeleteButton
}) => {
  const history = useHistory();

  return (
    <form onSubmit={handleSubmit}>
      <Card
        cover={
          <img
            alt={milestoneForm.title}
            src={milestoneForm.url_image || milestoneForm.image_url}
            style={{
              maxHeight: "270px",
              maxWidth: "270px",
              margin: "0 auto"
            }}
          />
        }
      >
        <Layout>
          <Content>
            <Row>
              <div style={{ textAlign: "center" }}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  type="file"
                  onChange={handleImage}
                />
                <label
                  htmlFor="raised-button-file"
                  style={{ width: "100%", textAlign: "center" }}
                >
                  <Tag color="#627f71" style={{ cursor: "pointer" }}>
                    UPLOAD IMAGE
                  </Tag>
                </label>
              </div>
            </Row>
            <Row className="mb-10">
              <label>Title</label>
              <Input
                value={milestoneForm.title}
                className="w-100"
                maxLength={255}
                onChange={e => {
                  e.persist();

                  setMilestoneForm((milestoneForm: any) => ({
                    ...milestoneForm,
                    title: e.target?.value
                  }));
                }}
              />
            </Row>
            <Row className="mb-10">
              <label>Description</label>
              <Input
                value={milestoneForm.description}
                className="w-100"
                maxLength={255}
                onChange={e => {
                  e.persist();

                  setMilestoneForm((milestoneForm: any) => ({
                    ...milestoneForm,
                    description: e.target?.value
                  }));
                }}
              />
            </Row>
            <Row className="mb-10">
              <label>Type of Milestone</label>
              <Select
                defaultValue={typeOfMilestoneValue}
                value={milestoneForm.typeOfMilestone}
                className="w-100"
                onChange={(value: "holiday" | "weeks" | "months" | "years") =>
                  setMilestoneForm((milestoneForm: any) => ({
                    ...milestoneForm,
                    typeOfMilestone: value,
                    age_weeks: 1,
                    age_months: 1
                  }))
                }
              >
                <Select.Option value="holiday">Holiday</Select.Option>
                <Select.Option value="weeks">Weeks</Select.Option>
                <Select.Option value="months">Months</Select.Option>
                <Select.Option value="years">Years</Select.Option>
              </Select>
            </Row>
            <Row className="mb-10">
              <label>Message Notification</label>
              <Select
                value={
                  milestoneForm.message_id === 0 || !milestoneForm.message_id
                    ? "None"
                    : milestoneForm.message_id
                }
                className="w-100"
                onChange={(value: any) =>
                  setMilestoneForm((milestoneForm: any) => ({
                    ...milestoneForm,
                    message_id: value
                  }))
                }
              >
                {messageNotifications.map(message => (
                  <Select.Option key={message.id} value={message.id}>
                    {message.description}
                  </Select.Option>
                ))}
              </Select>
            </Row>
            <Row className="mb-10">
              <InputFromTypeOfMilestone
                milestoneForm={milestoneForm}
                setMilestoneForm={setMilestoneForm}
              />
            </Row>
            <Row className="mb-10">
              <Layout>
                <Content>
                  <FrameImageTabs
                    milestoneForm={milestoneForm}
                    setMilestoneForm={setMilestoneForm}
                    groupedFrames={groupedFrames}
                    currentTab={currentTab}
                  />
                </Content>
              </Layout>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Button
                type="primary"
                style={{ margin: "10px" }}
                htmlType="submit"
              >
                {milestoneId !== 0 ? "UPDATE" : "CREATE"}
              </Button>
              <Button
                type="primary"
                style={{ margin: "10px" }}
                onClick={() => history.push("/admin/milestones")}
              >
                CANCEL
              </Button>
              {milestoneId !== 0 && (
                <Button
                  type="primary"
                  style={{ margin: "10px" }}
                  onClick={() => handleClickOnDeleteButton(milestoneForm.id)}
                >
                  DELETE
                </Button>
              )}
            </Row>
          </Content>
        </Layout>
      </Card>
    </form>
  );
};

export default AdminMilestoneForm;
