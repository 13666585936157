import React from "react";

function BabyPageAdd() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="31"
      viewBox="0 0 31 31"
    >
      <g fill="none" fillRule="evenodd" transform="translate(.713 .757)">
        <circle cx="14.747" cy="14.747" r="14.747" fill="#658071"></circle>
        <path fill="#FFF" d="M13.406 6.703h2.681v16.088h-2.681z"></path>
        <path
          fill="#FFF"
          d="M22.79 14.734v.026c0 .733-.593 1.328-1.326 1.328H8.03a1.327 1.327 0 01-1.327-1.328v-.026c0-.733.594-1.328 1.327-1.328h13.434c.733 0 1.327.595 1.327 1.328z"
        ></path>
      </g>
    </svg>
  );
}

export default BabyPageAdd;