import React, { useMemo, useState, useEffect } from "react";
import { Icon, Modal, Spin, Button, Radio, Tooltip } from "antd";
import { Baby, BabyPage } from "../../interfaces";
import ChildBabypages from "../../components/SaveBook/ChildBabypages";
import { trackAction } from "../../utils/marketing";
import SearchBarIcon from "../BabyPageSearchBar/SearchBarIcon/SearchBarIcon";
import "./SelectBabypages.scss";
import { sagaMiddleware } from "../../store";
import { fetchBabies } from "../../store/sagas/baby";
import SortIcon from "../Icons/SortIcon";
import { RadioChangeEvent } from "antd/lib/radio";
import { capitalize } from "lodash";
import AscendingSort from "../Icons/AscendingSort";
import DescendingSort from "../Icons/DescendingSort";
import ArrowNext from "../Icons/ArrowNext";
import { useWindowSize } from "../../hooks/WindowsSize";
import SaveBookTooltipIcon from "./SaveBookTooltipIcon/SaveBookTooltipIcon";

interface Props {
  useOldBabypages: boolean;
  babies: Baby[];
  selectedBabypages: BabyPage[];
  setSelectedBabypages: React.Dispatch<React.SetStateAction<BabyPage[] | []>>;
  setControl: React.Dispatch<React.SetStateAction<{valid: boolean, step:number}>>;
  nextStep:React.EffectCallback;
}

const SelectBabypages: React.FC<Props> = ({
  useOldBabypages,
  babies,
  selectedBabypages,
  setSelectedBabypages,
  setControl,
  nextStep
}) => {
  const [search, setSearch] = useState<string>("");
  const sortOptions = [{
    value: 'date',
    label: 'Creation Date'
  }, {
    value: 'alpha',
    label: 'Alphabetical'
  },
  {
    value: 'milestone',
    label: 'Milestone Date'
  }
   ]
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [sortModalVisible, setSortModalVisible] = useState(false);
  const [sortBy, setSortBy] = useState<string>(sortOptions[2].value);
  const [reverseSort, setReverseSort] = useState<boolean>(false);
  const [width] = useWindowSize();
  const [loading, setLoading] = useState<boolean>(false);
  const filteredBabies = useMemo(
    () =>
      babies
        .map((baby) => ({
          ...baby,
          babyPages: baby.babyPages.filter(
            (bp) => bp.new_version !== useOldBabypages
          ),
        }))
        .filter((baby) => !!baby.babyPages.length),
    [babies, useOldBabypages, loading]
  );
  let selectBabypagesText = width >= 768 ? "Click to select the babypages you want to include in your book. Click again to unselect." : "Tap to select the babypages you want to include in your book. Tap again to unselect."

  useEffect(() => {
    setLoading(true);
    setReverseSort(false);
    sagaMiddleware.run<any>(
      fetchBabies,
      (err: string | null) => {
        setLoading(false);
        if (err) {
          Modal.error({
            title: "Error",
            content: err,
          });
        }
      },
      undefined,
      sortBy
    );
  }, [sortBy]);

  let babyPages = useMemo(() => {
    let arr = babies
      .filter((item) => item.babyPages)
      .map((item) => {
        let arr = [...item.babyPages].reverse();
        if (reverseSort) {
          return arr;
        } else {
          return item.babyPages;
        }
      });
    let allBabypages: BabyPage[] = [];
    for (let babypageList of arr) {
      for (let babypage of babypageList) {
        if (allBabypages.length === 100) {
          break;
        }
        allBabypages.push(babypage);
      }
      if (allBabypages.length === 100) {
        break;
      }
    }

    return [...allBabypages];
  }, [sortBy, babies, reverseSort]);

  const [allSelect, setAllSelect] = useState<boolean>(
    selectedBabypages.length === babyPages.length
      ? true
      : selectedBabypages.length === 100
      ? true
      : false
  );

  let selectAll100 = useMemo(() => {
    if (!allSelect) {
      let arr = babies
        .filter((item) => item.babyPages)
        .map((item) => {
          let arr = [...item.babyPages].reverse();
          if (reverseSort) {
            return arr;
          } else {
            return item.babyPages;
          }
        });

      let selected100Babypages: BabyPage[] = [];
      for (let babypageList of arr) {
        for (let babypage of babypageList) {
          if (selected100Babypages.length === 100) {
            break;
          }
          selected100Babypages.push(babypage);
        }
        if (selected100Babypages.length === 100) {
          break;
        }
      }
      if (selectedBabypages.length === 100) {
        return [];
      }
      return [...selected100Babypages];
    } else {
      return [];
    }
  }, [sortBy, allSelect, babies, reverseSort]);

  useEffect(() => {
    if (allSelect === false) {
      if (
        selectedBabypages.length === 100 ||
        selectedBabypages.length === selectAll100.length
      ) {
        setAllSelect(true);
      }
    } else if (allSelect === true) {
      if (selectedBabypages.length === babyPages.length - 1) {
        setAllSelect(false);
      }
    }
  }, [selectedBabypages]);

  return (
    <div className="select-babypages-margin-bottom">     
      <div className="input-group search-bar-container col-12 col-md-10 mx-auto">
        <div className="input-group-prepend ">
          <div className="input-group-text search-button-icon">
            {" "}
            <SearchBarIcon />{" "}
          </div>
        </div>
        <input
          type="text"
          className="form-control search-input"
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          onFocus={() => {
            trackAction(
              "Search_Babypages",
              {
                google: {
                  event: "search_babypage",
                },
              },
              true
            );
          }}
          placeholder="Search"
        />
        <div
          className="input-group-append clickable"
          onClick={() => setSortModalVisible(true)}
        >
          <div className="input-group-text sort-button-icon">
            {" "}
            <SortIcon />{" "}
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="d-flex justify-content-center col-9 col-md-12 mx-auto">
          <h1 className="book-instructions-primary text-center" >
            {!!useOldBabypages
              ? "Please select between 8 and 60 babypages." 
              : width < 1024 ? "Please select between 8 and 100 babypages. Click to select the babypages you want to include in your book. Click again to unselect." 
              : "Please select between 8 and 100 babypages." }
           { width >= 1024 ? <Tooltip title="Click to select the babypages you want to include in your book. Click again to unselect." trigger="hover">
              <span className="ml-2 book-save-icon"><SaveBookTooltipIcon /></span>
            </Tooltip> : null }

          </h1>
        </div>
        <div className="col-12 my-2">
          <p
          className="text-center mb-0"
          >
            <span
            className="clickable text-center clickable-text"
            onClick={() => {
              if (!allSelect) {
                setSelectedBabypages([...selectAll100]);
              } else {
                setSelectedBabypages([]);
              }
              setAllSelect(!allSelect);
            }}
            >
              {allSelect ? "Unselect All" : "Select All (Max 100)" }
            </span>
          </p>
        </div>
      </div>
     {!loading &&  
     <div
        className={`container-fluid create-book-container-width`}
        style={{
          backgroundColor: "#FFF",
          marginBottom: "50px",
          borderRadius: "15px"
        }}
      >
        <div className="create-book-scrollable-container create-book-scrollable-select-container">
        {!!filteredBabies &&
        filteredBabies.map((baby, index) => (
          <div key={index}>
            <ChildBabypages
              baby={baby}
              selectedBabypages={selectedBabypages}
              setSelectedBabypages={setSelectedBabypages}
              search={search}
              reverseSort={reverseSort}
              sortBy={sortBy}
            />
          </div>
        ))}
        </div>
        <div className="flex flex-col justify-content-center align-items-center select-babypages-button-container">
          <div className="d-flex flex-row justify-content-center mb-2 custom-margin">
            <div className="col-lg-3 col-6">
              <Button type="primary" ghost shape="round" className="w-100 book-creation-buttons" onClick={() => {
                setControl((prev)  => {
                  return {
                    ...prev,
                    step:prev.step - 1,
                  }
                });
              }}>
                  Previous
                </Button>     
            </div>
            <div className="col-lg-3  col-6">
              <Button
                type="primary"
                ghost
                shape="round"
                className="w-100 green-button book-creation-buttons d-flex flex-row align-items-center justify-content-center"
                disabled={selectedBabypages.length >=8 ? false : true}
                onClick={nextStep}
              >
                Next
                <ArrowNext />
              </Button>
            </div>
              
          </div>
          <div className="text-center w-100 selected-babypages">
                <p>Babypages Selected: {selectedBabypages.length}</p>
          </div>
        </div>
      </div> }
     
      {loading ? (
        <div className="mx-auto d-table my-3">
          <Spin
            indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
          />
        </div>
      ) : null}
      <Modal
        visible={sortModalVisible}
        onCancel={() => setSortModalVisible(false)}
        footer={null}
      >
        <h1 className="sort-title">Sort By</h1>
        <Radio.Group
          value={sortBy}
          style={{
            padding: 0,
          }}
          onChange={(e: RadioChangeEvent) => {
            setSortBy(e.target.value);
          }}
        >
          {sortOptions.map((item, index) => (
            <Radio style={{ display: "block" }} key={index} value={item.value}>
              {" "}
              {capitalize(item.label)}{" "}
            </Radio>
          ))}
        </Radio.Group>
        <div className="">
          <span
            className="clickable"
            onClick={() => {
              setReverseSort(!reverseSort);
            }}
          >
            {!reverseSort ? <DescendingSort /> : <AscendingSort />}
          </span>

          <span className="ml-2">
            {sortBy === "date"
              ? !reverseSort
                ? "Latest First"
                : "Earliest First"
              : sortBy === "milestone"
              ? !reverseSort
                ? "Earliest Milestones"
                : "Latest Milestones"
              : !reverseSort
              ? "A-Z"
              : "Z-A"}
          </span>
        </div>
      </Modal>
    </div>
  );
};
export default SelectBabypages;
