import { call, select } from "redux-saga/effects";
import { AppState } from "..";
import api from "../../api";
import { RelationshipInterface } from "../../interfaces";
import { parseRequestError } from "../../utils/error";
import { User } from "../../interfaces/user";

/**
 * This function get questions by milestone id
 **/
export function* getFamilyRelationships(
  callback: (err?: string, response?: RelationshipInterface[]) => void
) {

  const user: User = yield select((state: AppState) => state.user);

  if (!user.access_token) return;

  try {
    const response = yield call(
      api.get,
      `api/family-relationships`,
      {
        headers: {
          Authorization: user.access_token
        }
      }
    );

    callback(undefined, response.data.data);
  } catch (error) {
    callback(parseRequestError(error));
  }
}
