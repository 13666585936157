import { Button, Input, Table } from 'antd';
import React, { useState, useCallback } from 'react';
import { FormListInterface, FormListItem } from '../../../../interfaces/form';
import { useHistory } from 'react-router-dom';

interface Props {
  form: FormListInterface;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  filterForms: (search?: string, page?: number) => void;
}

const FormList: React.FC<Props> = ({ form, loading, filterForms }) => {
  const history = useHistory();

  const [search, setSearch] = useState('');
  const [debounce, setDebounce] = useState<NodeJS.Timeout>();

  const onChangeSearch = useCallback(
    (search: string = '') => {
      setSearch(search);

      if (debounce) clearTimeout(debounce);

      setDebounce(
        setTimeout(() => {
          filterForms(search, form.current_page);
        }, 400)
      );
    },
    [debounce, form.current_page, filterForms]
  );

  return (
    <>
      <Input
        value={search}
        onChange={e => onChangeSearch(e.target.value)}
        placeholder='Search'
        className='mx-auto d-table'
        style={{ width: '90%', maxWidth: 300 }}
      />
      <Button
        type='primary'
        ghost
        className='mx-auto my-3 d-table'
        onClick={() => history.push('/admin/forms/save')}
      >
        ADD NEW FORM
      </Button>
      <Table
        dataSource={form.data}
        loading={loading}
        bordered
        pagination={{
          current: form.current_page,
          total: form.total,
          pageSize: form.per_page,
        }}
        onChange={e => filterForms(search, e.current || 1)}
        className='m-3 elevation-5'
        rowKey={(record: FormListItem) => record.id.toString()}
      >
        <Table.Column title='Title' dataIndex='title' ellipsis />
        <Table.Column title='Code' dataIndex='code' ellipsis />
        <Table.Column
          title='Actions'
          dataIndex='id'
          render={(text: any, record: FormListItem) => (
            <>
              <Button
                icon='eye'
                type='primary'
                onClick={() =>
                  history.push(`/admin/forms/detail?code=${record.code}`)
                }
              />
              &nbsp;
              <Button
                icon='edit'
                type='primary'
                onClick={() =>
                  history.push(`/admin/forms/save?code=${record.code}`)
                }
              />
            </>
          )}
        />
      </Table>
    </>
  );
};

export default FormList;
