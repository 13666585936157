import { isEmail } from 'validator';

export interface CommonEmail {
  base: string;
  shouldBe: string;
  /**
   * If the email can have different domains
   * Ex:
   *  @yahoo.com
   *  @yahoo.com.br
   *  @yahoo.com.co
   *  @yahoo.com.uk
   *  @yahoo.com.*
   */
  canHaveDiffDomain?: boolean;
}

export const COMMON_EMAILS: CommonEmail[] = [
  {
    base: '@hotmail',
    shouldBe: '@hotmail.com',
  },
  {
    base: '@icloud',
    shouldBe: '@icloud.com',
  },
  {
    base: '@aol',
    shouldBe: '@aol.com',
  },
  {
    base: '@mail',
    shouldBe: '@mail.com',
  },
  {
    base: '@outlook',
    shouldBe: '@outlook.com',
  },
  {
    base: '@gmail',
    shouldBe: '@gmail.com',
  },
  {
    base: '@yahoo',
    shouldBe: '@yahoo.com',
    canHaveDiffDomain: true,
  },
];

export const isEmailValid = (email: string): boolean => {
  // Validate email writing (normal regex or checking function)
  if (!isEmail(email)) return false;

  // Check if is a Common email and do more validations
  for (const c of COMMON_EMAILS) {
    const indexBase = email.indexOf(c.base);

    if (indexBase !== -1) {
      if (c.canHaveDiffDomain) {
        return email.substr(indexBase, c.shouldBe.length) === c.shouldBe;
      } else {
        return email.substr(indexBase) === c.shouldBe;
      }
    }
  }

  return true;
};
