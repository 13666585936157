import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import { Carousel, Modal, Button, Icon, Spin } from "antd"
import { connect } from 'react-redux';
import { Baby, SaveBabyPagePhoto, SimpleBaby, User } from '../../interfaces';
import { AppState, sagaMiddleware } from '../../store';
import { BaseAction } from '../../store/actionTypes';
import { selectBaby } from '../../store/ducks/baby';
import addChildPhotoIcon from "../../assets/icons/IconPlacholder.png";
import SelectPhotoModal from '../SaveBabyPage/SelectPhotoModal';
import layout from 'antd/lib/layout';
import { PhotoSelected, initialPhotoState, selectOneImage, selectOneImageURL, finishOneCrop, profilePhotoClick } from '../../pages/BabyPage/Save/SaveHelper';
import { isEqual } from 'lodash';
import ImageEditModal from '../ImageEditModal/ImageEditModal';
import Cropper from "react-cropper";
import { saveProfilePicture } from '../../store/sagas/baby';
import { useHistory } from 'react-router';
import "./BabyCarousel.scss"
import { getBase64FromFileUrl } from '../../utils/image';
import { BASE_URL } from '../../env';
import AddProfilePhotoIcon from '../Icons/AddProfilePhotoIcon';

interface Props {
    simpleBabies: SimpleBaby[];
    selectBabyIndex: Function;
    babySelectedIndex?: number;
    babies: Baby[];
    babySelected: Baby | undefined;
    editPhoto?: boolean;
    setEditPhoto?: React.Dispatch<SetStateAction<boolean>>;
    addPhoto?:boolean;
    setAddPhoto?:React.Dispatch<SetStateAction<boolean>>;
    user: User;
}

export const BabyCarousel : React.FC<Props> = ({  
    simpleBabies,
    selectBabyIndex,
    babySelectedIndex,
    babies,
    babySelected,
    editPhoto,
    setEditPhoto,
    addPhoto,
    setAddPhoto,
    user
}) => {
    const [babyPhotoModal, setBabyPhotoModal] = useState(false);
    const [photo, setPhoto] = useState<SaveBabyPagePhoto>({base64_cropped: ""});      
    const babyPhotoCarouselRef = React.createRef<Carousel>();
    const inputRef = useRef<HTMLInputElement>(null);
    const cameraInputRef = useRef<HTMLInputElement>(null);
    const [cropperRef, setCropperRef] = useState<Cropper | null>(null);
    const [loading, setLoading] = useState(false);
    const [photoSelected, setPhotoSelected] = useState<PhotoSelected>(
      initialPhotoState
    );
    let slidesToShow = babies.length >= 3 ? 3 : babies.length
    const[finalPhotoModal, setFinalPhotoModal] = useState<boolean>(false);
    const isGuestUser = !!user.guest_user
    const history = useHistory();
    const onSelectImage = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) =>
      selectOneImage(
          e,
          photoSelected,
          photo,
          setPhoto,
          setPhotoSelected,
          inputRef,
          setLoading
        ),
      [ photoSelected, photo, setPhoto, inputRef]
    );

    const getBase64 = async () => {
      let base64 = await getBase64FromFileUrl(babySelected?.baby_profile_picture!,`${BASE_URL}api/avoid-cors?url=`)
      return base64;
    }

    useEffect(() => {
      if(!!babySelected?.baby_profile_picture?.length && !!setEditPhoto) {
        let profilePhoto = getBase64()

        profilePhoto.then((value) => {
          setPhoto({base64:value, base64_cropped:value})
        })
      }
    }, [babySelected])
   

    const onSelectImageURL = useCallback(
      (imageUrl: string) =>
      selectOneImageURL(
          imageUrl,
          photoSelected,
          photo,
          setPhoto,
          setPhotoSelected,
          inputRef,
          setLoading
        ),
      [ photoSelected, photo, setPhoto, inputRef]
    );

    const onCropComplete = useCallback(
        (croppedData: Cropper.Data, imageData: Cropper.ImageData) => {
          if (
            !!croppedData &&
            (!photoSelected.croppedData ||
              !isEqual(croppedData, photoSelected.croppedData))
          ) {
            setPhotoSelected((state) => ({
              ...state,
              croppedData,
              imageData,
            }));
          }
        },
        [photoSelected]
      );
    
      const onFinishCrop = useCallback(
        async (_: React.MouseEvent<HTMLElement, MouseEvent>) => {
          if (cropperRef !== null) {
            finishOneCrop(
              cropperRef,
              photoSelected,
              photo,
              setPhoto,
              setLoading,
              setPhotoSelected
            );
          }
          if(!!setEditPhoto) {
            setEditPhoto(true)
          } else {
            setFinalPhotoModal(true)
          }

        },
        [cropperRef, photoSelected, photo, setPhoto]
      );

      const onPhotoClick = useCallback(
        ( photo: SaveBabyPagePhoto) => {
          profilePhotoClick(
            photo,
            inputRef,
            setPhotoSelected,
          )
          if(!!setEditPhoto) {
            setEditPhoto(false)
          } else {
            setFinalPhotoModal(false)
          }
          
        },

        [layout, inputRef]
      );

    useEffect(() => {
        if(!!babySelectedIndex && babySelectedIndex > 2) {
            babyPhotoCarouselRef.current?.goTo(babySelectedIndex - 2)
        } else {
            babyPhotoCarouselRef.current?.goTo(0)
        }
    }, [babySelectedIndex])

    const uploadPhoto = useCallback( async () => {
      setLoading(true)
      if(!!babySelected) {

        let file = await dataUrlToFileUsingFetch(photo.base64_cropped, "output.png", "image/jpeg");

        const image = {
          image: file,
          folder: "photos",
          baby_id: babySelected.id,
         }

        sagaMiddleware.run<any>(saveProfilePicture, babySelected.id, image ,(error: string | null) => {
          if (error !== null) {
            setLoading(false)
            console.error(error);
          }try {
            setLoading(false)
            if(!!setEditPhoto) {
              setEditPhoto(false)
            } else {
               setFinalPhotoModal(false)
            }
          } catch {
            setLoading(false)
          }
        });
     }
    }
    , [photo, babySelectedIndex])
     
      
    const dataUrlToFileUsingFetch = async (
      url: string,
      fileName: string,
      mimeType: string
    ) => {
      const response = await fetch(url);
      const buffer = await response.arrayBuffer();
    
      return new File([buffer], fileName, { type: mimeType });
    };
   
    const babyListHtml = simpleBabies.map((baby, index) => {
        return (
            <div 
            className="baby-circle-container d-flex flex-column justify-items-center align-items-center"> 
                <div className={`${!babies[index].baby_profile_picture && babySelectedIndex === index ? "baby-circle-border" : ""} baby-circle clickable`} onClick={() => {
                    if(index !== babySelectedIndex) {
                        selectBabyIndex(index)
                    } else if (!isGuestUser) {
                      if(!!setAddPhoto && !babySelected?.baby_profile_picture) {
                        setAddPhoto(true)
                      } else {
                        setBabyPhotoModal(true);
                      }

                    }   
                }}>
                  {babies[index].baby_profile_picture ? 
                  <img 
                    className={ index === babySelectedIndex ? "baby-circle-border" : "baby-overlay"}
                    style={{borderRadius: "50%", width:"100%", height:"100%"}} 
                    src={babies[index].baby_profile_picture}
                    onClick={() => {
                      if(index === babySelectedIndex && !isGuestUser ) {
                        history.push(`profile?child`)
                      }
                    }}/> 
                  : <><img src={addChildPhotoIcon} /> <AddProfilePhotoIcon className='profile-photo-icon-xs' isSmall /></>}
                    
                </div>
                <p className="text-center mb-0 mt-1 baby-circle-title">{baby.baby_first_name}</p>
            </div>
        )
    })
  return (
    <>
    <div style={{backgroundColor: "#f0f0f0"}} className="w-100">
        <div className='col-md-4 col-10 mx-auto mt-4 mb-3' >
            <Carousel 
            ref={babyPhotoCarouselRef}
            className="profile-photo-carousel"
            slidesToShow={slidesToShow}
            swipe
            draggable
            infinite={false}
            dots
            arrows={false}>
                { babyListHtml }
            </Carousel>
        </div>

        <input
            ref={inputRef}
            type="file"
            accept="image/*"
            onChange={onSelectImage}
            style={{
            width: 0,
            height: 0,
            display: "none",
            }}
        />

          <input
            ref={cameraInputRef}
            type="file"
            accept="image/*"
            onChange={onSelectImage}
            style={{
            width: 0,
            height: 0,
            display: "none",
            }}
            capture="user"
        />

    <ImageEditModal
        loading={loading}
        cropperRef={cropperRef}
        maskClosable={false}
        setCropperRef={setCropperRef}
        visible={photoSelected.edit}
        image={photoSelected.base64}
        croppedData={photoSelected.croppedData}
        aspect={photoSelected.aspect}
        onCropComplete={onCropComplete}
        onOk={onFinishCrop}
        onCancel={() => setPhotoSelected(initialPhotoState)}
        onChangePhoto={() => {
          setPhotoSelected((state) => ({ ...state, edit: false }));
          if(!!setAddPhoto) {
            setAddPhoto(true)
          } else {
            setBabyPhotoModal(true)
          }
        }}
        roundCropper
      />

        <SelectPhotoModal 
        visible={!!setAddPhoto ? addPhoto : babyPhotoModal} 
        onCancel={() => {
          if(!!setAddPhoto) {
            setAddPhoto(false)
          } else {
            setBabyPhotoModal(false)
          }
        }}
        inputRef={inputRef}
        cameraInputRef={cameraInputRef}
        onSelectPhoto={onSelectImageURL}
        isProfile
        setBabyPhotoModal={setBabyPhotoModal}
        setAddPhoto={setAddPhoto}
        />

      <Modal visible={!!setEditPhoto ? editPhoto : finalPhotoModal} onCancel={() => {
        if(!!setEditPhoto) {
          setEditPhoto(false)
        } else {
          setFinalPhotoModal(false)
        }
        }}
        footer={null}
        className="mobile-profile-modal"
        >  
        <div className="row justify-content-center align-items-center h-100 w-100 mx-auto mt-n3" style={{position:"relative"}}> 

        <div className="d-flex flex-column justify-content-center">
        <h1 className='crop-modal-title mb-4 mt-2 text-center'  >
                  Crop photo
              </h1>
              <h1 className='crop-modal-subtitle mb-n2 text-center'>Move and Scale</h1>
        <div >    
        <img
            src={photo.base64_cropped}
            style={{borderRadius:"50%" }}
            alt="Baby 1"
            id="baby-photo"
            onClick={() => (!!onPhotoClick ? onPhotoClick(photo) : {})}
            className="baby-photo baby-profile-photo p-0 my-3"
          />
        <div className="row justify-content-center align-items-center mx-auto my-3 "> 
          <Button
            type="primary"
            ghost
            shape="round"
            disabled={loading}
            className="w-85 green-button light-btn"
            onClick={() => uploadPhoto()}
          >
              Finish
          </Button>
        </div>

        {loading ?
        ( <div className="mx-auto d-table">
                <Spin
                  indicator={
                    <Icon type="loading" style={{ fontSize: 24 }} spin />
                  }
                />
                </div>
                ) : (
                 <div className="row justify-content-center align-items-center mx-auto mt-md-3 mt-2 mb-n2 "> 
                    <p 
                    className='baby-photo-steps clickable'
                    onClick={() => (!!onPhotoClick ? onPhotoClick(photo) : {})}
                    >Edit Profile Photo</p>
                </div>
                )}
          </div>
        </div>
      </div>
      </Modal>
      </div>
    </>
  )
}


const mapStateToProps = (state: AppState) => ({
  simpleBabies: state.baby.simpleBabies,
  babySelectedIndex: state.baby.babySelectedIndex,
  babies: state.baby.babies,
  babySelected: state.baby.babySelected,
  user: state.user,
});

const mapDispatchToProps = (dispatch: Dispatch<BaseAction>) => ({
  selectBabyIndex: (index: number) => dispatch(selectBaby(index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BabyCarousel);


