import React from "react";

function PlusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
    >
      <text
        fill="#658071"
        fillRule="evenodd"
        fontFamily="Montserrat-Medium, Montserrat"
        fontSize="20"
        fontWeight="400"
        transform="translate(-546 -702)"
      >
        <tspan x="545" y="714">
          +
        </tspan>
      </text>
    </svg>
  );
}

export default PlusIcon;
