import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import BaseAdminLayout from "./components/BaseAdminLayout/BaseAdminLayout";
import AdminBooksPage from "./pages/Admin/Books/Books";
import AdminDetailBooksPage from "./pages/Admin/Books/DetailBooks/DetailBooks";
import FontDetailPage from "./pages/Admin/Fonts/FontDetailPage/FontDetailPage";
import FontListPage from "./pages/Admin/Fonts/FontListPage/FontListPage";
import FontNewPage from "./pages/Admin/Fonts/FontNew/FontNewPage";
import AdminDetailAnswerPage from "./pages/Admin/Forms/DetailAnswer/DetailAnswer";
import AdminDetailFormPage from "./pages/Admin/Forms/DetailForm/DetailForm";
import AdminFormsPage from "./pages/Admin/Forms/Forms";
import AdminGuidelinePage from "./pages/Admin/Forms/Guideline/Guideline";
import AdminSaveFormPage from "./pages/Admin/Forms/SaveForm/SaveForm";
import AdminGiftCardPage from "./pages/Admin/GiftCard/GiftCard";
import AdminIndexPage from "./pages/Admin/Index/Index";
import AdminLoginPage from "./pages/Admin/Login/AdminLoginPage";
import MilestoneDetailPage from "./pages/Admin/Milestones/MilestoneDetail/MilestoneDetailPage";
import MilestoneListPage from "./pages/Admin/Milestones/MilestoneList/MilestoneListPage";
import MilestoneNewPage from "./pages/Admin/Milestones/MilestoneNew/MilestoneNewPage";
import AdminDetailUsersPage from "./pages/Admin/Users/DetailUsers/DetailUsers";
import AdminUsersPage from "./pages/Admin/Users/Users";
import { AppState } from "./store";
import AdminMetricsPage from "./pages/Admin/Metrics/Metrics";

interface Props {
  access_token?: string;
}

const AdminRoutes: React.FC<Props> = ({ access_token }) => {
  return (
    <>
      <BaseAdminLayout>
        <Switch>
          {!!access_token && (
            <Route path="/admin" exact component={AdminIndexPage} />
          )}
          {!!access_token && (
            <Route path="/admin/forms" exact component={AdminFormsPage} />
          )}
          {!!access_token && (
            <Route
              path="/admin/forms/guideline"
              exact
              component={AdminGuidelinePage}
            />
          )}
          {!!access_token && (
            <Route
              path="/admin/forms/save"
              exact
              component={AdminSaveFormPage}
            />
          )}
          {!!access_token && (
            <Route
              path="/admin/forms/detail"
              exact
              component={AdminDetailFormPage}
            />
          )}
          {!!access_token && (
            <Route
              path="/admin/forms/answer"
              exact
              component={AdminDetailAnswerPage}
            />
          )}
          {!!access_token && (
            <Route
              path="/admin/gift-card"
              exact
              component={AdminGiftCardPage}
            />
          )}
          {!!access_token && (
            <Route path="/admin/users" exact component={AdminUsersPage} />
          )}
          {!!access_token && (
            <Route
              path="/admin/users/detail"
              exact
              component={AdminDetailUsersPage}
            />
          )}
          {!!access_token && (
            <Route path="/admin/books" exact component={AdminBooksPage} />
          )}
          {!!access_token && (
            <Route
              path="/admin/books/detail"
              exact
              component={AdminDetailBooksPage}
            />
          )}
          {!!access_token && (
            <Route
              path="/admin/milestones"
              exact
              component={MilestoneListPage}
            />
          )}
          {!!access_token && (
            <Route
              path="/admin/milestones/new"
              exact
              component={MilestoneNewPage}
            />
          )}
          {!!access_token && (
            <Route
              path="/admin/milestones/:id"
              exact
              component={MilestoneDetailPage}
            />
          )}
          {!!access_token && (
            <Route path="/admin/metrics" exact component={AdminMetricsPage} />
          )}
          {!!access_token && (
            <Route path="/admin/fonts" exact component={FontListPage} />
          )}
          {!!access_token && (
            <Route path="/admin/fonts/new" exact component={FontNewPage} />
          )}
          {!!access_token && (
            <Route path="/admin/fonts/:id" exact component={FontDetailPage} />
          )}
          <Route path="/admin/login" exact component={AdminLoginPage} />
          <Route render={() => <Redirect to="/admin/login" />} />
        </Switch>
      </BaseAdminLayout>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  access_token: state.admin.access_token
});

export default connect(mapStateToProps)(AdminRoutes);
