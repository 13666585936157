import { Button, Card, Icon, Modal, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import giftcard from '../../../assets/img/book_line.png';
import logobp from '../../../assets/img/logo-babypage.png';
import plusThumb from '../../../assets/img/plus-thumbnail.png';
import BaseLayout from '../../../components/BaseLayout/BaseLayout';
import { PurchaseHistoryResponse } from '../../../interfaces/purchase';
import {AppState, sagaMiddleware } from '../../../store';
import { purchaseHistory } from '../../../store/sagas/user';
import './PurchaseHistory.scss';
import {  StripeProduct } from "../../../interfaces";
import { connect } from "react-redux";
import { capitalizeTexts } from "../../../utils/string";

interface Purchase {
  orderId: string;
  orderDate: string;
  orderTotal: string;
  paymentMethod: string;
  products: {
    image: React.ReactElement<HTMLElement>;
    name: string;
    price: string;
    extra_pages?: number;
    price_pages?:number | null;
    extra_page_name?: string | null;
  }[];
}
interface Props {
  stripeProducts: StripeProduct[];
}

const PurchaseHistoryPage: React.FC<Props> = ({stripeProducts}) => {
  const [control, setControl] = useState<{
    after: string | null;
    lastPages: string[];
  }>({
    after: null,
    lastPages: [],
  });
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState<{
    history?: PurchaseHistoryResponse;
    purchases: Purchase[];
  }>({
    history: undefined,
    purchases: [],
  });

  useEffect(() => {
    setLoading(true);

    sagaMiddleware.run<any>(
      purchaseHistory,
      control.after,
      (err?: string, data?: PurchaseHistoryResponse) => {
        setLoading(false);

        if (!!err) {
          Modal.error({
            title: 'Error',
            content: err,
          });
        } else if (!!data) {
          const newPurchases: Purchase[] = [];

          if (!!data.order && !!data.order.length) {
            data.order.forEach(order => {
              newPurchases.push({
                orderId: order.id,
                orderDate: moment(order.created).format('MM[/]DD[/]YYYY'),
                orderTotal: parseFloat((order.amount / 100).toString()).toFixed(
                  2
                ),
                paymentMethod: '****' + order.source.last4,
                products: order.products.map(product => {
                  return {
                    image: (
                      <img
                        src={
                          !!product.thumbnail_with_url
                            ? product.thumbnail_with_url
                            : product.thumbnail
                            ? product.thumbnail
                            : product.type === 'subscription'
                            ? logobp
                            : plusThumb
                        }
                        alt={product.description}
                        className='product-thumb'
                      />
                    ),
                    name: `${product.quantity || 1}x ${product.description}`,
                    price: parseFloat(
                      (
                        ((product.quantity || 1) * product.amount) /
                        100
                      ).toString()
                    ).toFixed(2),
                     extra_pages: product.extra_pages ? product.extra_pages.qty : 0,
                     price_pages: product.extra_pages ? (product.extra_pages.price / 100) *  (product.extra_pages.qty) * product.quantity: null,
                     extra_page_name: product.extra_pages ? `${product.quantity}x ${(product.extra_pages.qty)} ${product.extra_pages.name}` : null
                  };
                }),
              });
            });
          }

          if (!!data.charges && !!data.charges.length) {
            data.charges.forEach(charge => {
              newPurchases.push({
                orderId: charge.id,
                orderDate: moment(charge.created).format('MM[/]DD[/]YYYY'),
                orderTotal: parseFloat(
                  (charge.amount / 100).toString()
                ).toFixed(2),
                paymentMethod: '****' + charge.source.last4,
                products: [
                  {
                    image: (
                      <img
                        src={giftcard}
                        alt={charge.description}
                        className='product-thumb'
                      />
                    ),
                    name: charge.description,
                    price: parseFloat((charge.amount / 100).toString()).toFixed(
                      2
                    ),
                  },
                ],
              });
            });
          }

          if (
            !!data.iap &&
            !!data.iap.data &&
            !!data.iap.data.length &&
            !control.lastPages.length
          ) {
            data.iap.data.forEach(iap => {
              newPurchases.push({
                orderId: iap.transaction_id,
                orderDate: moment(iap.purchase_date).format('MM[/]DD[/]YYYY'),
                orderTotal: parseFloat((iap.amount / 100).toString()).toFixed(
                  2
                ),
                paymentMethod: 'In App Purchase',
                products: [
                  {
                    image: (
                      <img
                        src={plusThumb}
                        alt={iap.title}
                        className='product-thumb'
                      />
                    ),
                    name: iap.title,
                    price: parseFloat((iap.amount / 100).toString()).toFixed(2),
                  },
                ],
              });
            });
          }

          setPageData({
            history: data,
            purchases: newPurchases,
          });
        }
      }
    );
  }, [control]);

  return (
    <>
      {loading && (
        <div className='col-12 d-flex justify-content-center'>
          <Spin
            indicator={<Icon type='loading' style={{ fontSize: 24 }} spin />}
          />
        </div>
      )}

      {!!pageData.purchases!.length ? (
      <>
      <div className='row justify-content-center align-items-center m-md-3'>
        {pageData.purchases.map((purchase, index) => (
          <div className='col-12 col-md-6 col-xl-4 p-md-3' key={index}>
            <Card className='w-100 elevation-md-5'>
              <div className='row justify-content-center align-items-center'>
                <div className='col-4 my-1'>
                  <h5 className='m-0'>
                    <strong>Order Date</strong>
                  </h5>
                  <p className='m-0'>{purchase.orderDate}</p>
                </div>
                <div className='col-4 my-1'>
                  <h5 className='m-0'>
                    <strong>Order Total</strong>
                  </h5>
                  <p className='m-0'>${purchase.orderTotal}</p>
                </div>
                <div className='col-4 my-1'>
                  <h5 className='m-0'>
                    <strong>Payment Method</strong>
                  </h5>
                  <p className='m-0'>{purchase.paymentMethod}</p>
                </div>
                <div className='col-12 my-1'>
                  <h5 className='m-0'>
                    <strong>Order #</strong>
                  </h5>
                  <p className='m-0'>{purchase.orderId}</p>
                </div>
                {!!purchase.products &&
                  purchase.products.map((product, index) => (
                    <div className='col-12 my-1' key={index}>
                      {product.extra_pages ? (
                        <>
                         <div className='row align-items-center'>
                         <div className='col-2'>{product.image}</div>
                         <div className='col-10'>
                           <h5 className='m-0'>
                             <strong>{product.name}</strong>
                           </h5>
                           <p className='m-0'>${product.price}</p>
                         </div>
                       </div>
                        <div className='row align-items-center'>
                        <div className='col-10 offset-2'>
                          <h5 className='m-0'>
                            <strong>{`${product.extra_page_name}`} </strong>
                          </h5>
                          <p className='m-0'>${product.price_pages}</p>
                        </div>
                      </div>
                      </>
                      ) : (
                      <div className='row align-items-center'>
                        <div className='col-2'>{product.image}</div>
                        <div className='col-10'>
                          <h5 className='m-0'>
                            <strong>{product.name}</strong>
                          </h5>
                          <p className='m-0'>${product.price}</p>
                        </div>
                      </div>
                      )}
                    </div>
                  ))}
              </div>
            </Card>
          </div>
        ))}
      </div>
      <div className='row justify-content-center align-items-center'>
        <div className='col-6'>
          <Button
            type='link'
            disabled={!control.lastPages.length || loading}
            onClick={() => {
              const newLastPages =
                control.lastPages.length > 1 ? [...control.lastPages] : [];
              if (!!newLastPages.length) {
                newLastPages.splice(newLastPages.length - 1, 1);
              }

              setControl({
                after:
                  control.lastPages.length > 1
                    ? control.lastPages[control.lastPages.length - 2]
                    : null,
                lastPages: newLastPages,
              });

              window.scrollTo(0, 0);
            }}
            className='w-100 d-table mx-auto'
            style={{ maxWidth: 300 }}
          >
            Previous
          </Button>
        </div>
        <div className='col-6'>
          <Button
            type='link'
            disabled={
              !pageData.history ||
              (!pageData.history.has_more &&
                (!pageData.history.iap || !pageData.history.iap.hasMore)) ||
              loading
            }
            onClick={() => {
              setControl({
                after: pageData.history!.starting_after,
                lastPages: [
                  ...control.lastPages,
                  pageData.history!.starting_after,
                ],
              });

              window.scrollTo(0, 0);
            }}
            className='w-100 d-table mx-auto'
            style={{ maxWidth: 300 }}
          >
            Next
          </Button>
        </div>
      </div>
      </>
      )  : (
        <div className='row justify-content-center align-items-center m-3 no-history-container'>
          <p className='no-history-text'>You have no purchase history</p> 
        </div>
      ) 
      }
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  stripeProducts: state.stripeProduct,
});

export default connect(mapStateToProps)(PurchaseHistoryPage);

