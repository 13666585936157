import { call, select } from "redux-saga/effects";
import { AppState } from "..";
import api from "../../api";
import { FontPaginator, Font, newFont } from "../../interfaces/font";
import { parseRequestError } from "../../utils/error";

/**
 * Get fonts
 */
export function* getFonts(
  isPlus: boolean,
  callback: (err?: string, response?: newFont[]) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );
  let endpoint = `api/font?lang=en`;
  try {  

    const response = yield call(api.get, endpoint, {
      headers: {
        Authorization: accessToken
      }
    });

    callback(undefined, response.data.data);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * Get font by id
 */
export function* getFontById(
  id: number,
  callback: (err?: string, response?: Font) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.admin.access_token
  );
  const lang = "en";
  let endpoint = `api/fonts/${id}?lang=${lang}`;

  try {
    const response = yield call(api.get, endpoint, {
      headers: {
        Authorization: accessToken
      }
    });

    callback(undefined, response.data.data);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * Update or create a font
 */
export function* saveFont(
  form: any,
  callback: (err?: string, response?: Font) => void,
  id: number
) {
  const accessToken = yield select(
    (state: AppState) => state.admin.access_token
  );
  const lang = "en";
  let endpoint = `api/fonts?lang=${lang}`;

  if (id !== 0) {
    endpoint = `api/fonts/${id}?lang=${lang}`;
  }

  try {
    const response = yield call(api.post, endpoint, form, {
      headers: {
        Authorization: accessToken
      }
    });

    callback(undefined, response.data.data);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * Delete font by id
 */
export function* deleteFont(id: number, callback: (err?: string) => void) {
  const accessToken = yield select(
    (state: AppState) => state.admin.access_token
  );
  const lang = "en";

  try {
    yield call(api.delete, `api/fonts/${id}?lang=${lang}`, {
      headers: {
        Authorization: accessToken
      }
    });

    callback(undefined);
  } catch (error) {
    callback(parseRequestError(error));
  }
}
