import React from 'react';
import './Banner.scss'
import { useHistory  } from "react-router"


interface Props {
    content?: string;
    content2?: string;
    isCustom?: boolean;
}

// i text content for banner is short and looks nice in 1 line only add content props
// if the text content is longer and doesn't look good in 1 line add content and content2 to props

const Banner: React.FC<Props> = ({content, content2, isCustom, children}) => {
    const history = useHistory();
    return (
        <div className="custom-alert-container clickable" style={{
            height: 'auto'
          }}
          onClick={() => {
            history.push("/cart/subscription/yearly")
          }}>
          <div className="custom-alert text-center">         
                {
                isCustom ? (
                    <>
                    {children}
                    </>
                )
                 : content2 ? (
                    <>
                    <p>{content}</p>
                    <p>{content2}</p>
                    </>
                ) : <p>{content}</p> 
                
                }          
          </div>
        </div>   
    )
}

export default Banner;