import React from 'react';
import { Alert } from "antd";
import logo from '../../../assets/img/logo-babypage.png';
import BaseLayout from '../../../components/BaseLayout/BaseLayout';
import Store from '../../../components/Store/Store';
import '../Auth.scss';

interface Props {}

const StepThree: React.FC<Props> = () => (
  <BaseLayout
    title="BabyPage - Step Three"
    pageTitle="Step Three"
    hideHeader
    hideChildMenu
    pageStyle={{ position: 'unset', minHeight: 'unset' }}
    contentStyle={{ padding: 0 }}
    hideBanner={true}
  >
    <Alert message="Free 7-Day Trial" className="custom-alert-pink text-center p-2" />
    
    <img src={logo} alt="Logo" className="AuthLogo" />

    <div className="AuthContainer mt-0">
      <Store showFree />
    </div>
  </BaseLayout>
);
export default StepThree;
