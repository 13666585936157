import {
  Border,
  Illustration,
  SaveBabyPage,
  initialBabyPage,
} from "../../interfaces";
import {
  BaseAction,
  GET_BORDERS,
  GET_ILLUSTRATIONS,
  GET_DRAFT_BABYPAGE,
} from "../actionTypes";

export interface BPHelperState {
  borders: Border[];
  illustrations: Illustration[];
  draftBabyPage: SaveBabyPage;
  milestoneId: string;
}

const initialState: BPHelperState = {
  borders: [],
  illustrations: [],
  draftBabyPage: initialBabyPage,
  milestoneId: "",
};

export const bpHelper = (
  state: BPHelperState = initialState,
  action: BaseAction
): BPHelperState => {
  switch (action.type) {
    case GET_BORDERS:
      return { ...state, borders: [...action.payload] };
    case GET_ILLUSTRATIONS:
      return { ...state, illustrations: [...action.payload] };
    case GET_DRAFT_BABYPAGE:
      return {
        ...state,
        draftBabyPage: { ...action.payload },
        milestoneId: action.payload.milestoneId,
      };
    default:
      return state;
  }
};
Object.freeze(bpHelper);

const getBorders = (payload: Border[]) => ({
  payload,
  type: GET_BORDERS,
});
Object.freeze(getBorders);

const getIllustrations = (payload: Illustration[]) => ({
  payload,
  type: GET_ILLUSTRATIONS,
});
Object.freeze(getIllustrations);

const saveDraftBabyPage = (payload: SaveBabyPage) => {
  return {
    payload,
    type: GET_DRAFT_BABYPAGE,
  };
};
Object.freeze(saveDraftBabyPage);

export { getBorders, getIllustrations, saveDraftBabyPage };
export default bpHelper;
