import {Badge, Button, Dropdown, PageHeader} from 'antd';
import {History, Location} from 'history';
import React, {useMemo, useState} from 'react';
import {Baby, User} from '../../interfaces';
import {capitalizeTexts} from '../../utils/string';
import ChildMenu from '../ChildMenu/ChildMenu';
import {DesktopMenuItems, DesktopUserItems, MenuItemObject} from './menuItems';
import ArrowDownChildMenu from './ArrowDownChildMenu'
import CartIcon from './CartIcon';
import addChildPhotoIcon from "../../assets/icons/IconPlacholder.png"
import AddProfilePhotoIcon from '../Icons/AddProfilePhotoIcon';

interface Props {
  logout: Function;
  drawerOpen: boolean;
  hideHeaderChildMenu?: boolean;
  setDrawerOpen: Function;
  pageTitle?: string;
  babySelected?: Baby;
  location: Location<any>;
  history: History<any>;
  user: User;
  storeLength: number;
  showGuestUserModal: Function;
  alexandersOrderCounter: number;
  pageSubtitle?: string
}

const Header: React.FC<Props> = ({
  location,
  history,
  logout,
  pageTitle,
  pageSubtitle,
  babySelected,
  drawerOpen,
  setDrawerOpen,
  hideHeaderChildMenu,
  user,
  storeLength,
 alexandersOrderCounter,
                                   showGuestUserModal,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const userName = useMemo(() => {
    if (!user.first_name || !user.last_name) return '';

    const userName = `${capitalizeTexts(user.first_name)} ${capitalizeTexts(
      user.last_name
    )}`;

    return userName.length > 25 ? userName.substr(0, 22) + '...' : userName;
  }, [user.first_name, user.last_name]);

   const isCartPage = location.pathname === "/cart" ? true : false
   const cartIconMargin = !!storeLength ? "cart-button" : "";
   const isGuestUser = !!user.guest_user
  return (
    <PageHeader
      title={
        <>
        { !!babySelected && 
           <div 
              className="baby-circle clickable header-logo" 
              onClick={() => {
                if(!isGuestUser) {
                  history.push(`/profile?child`)
                }
              }}>
           { babySelected.baby_profile_picture  ? 
                   <img 
                     style={{borderRadius: "50%", width:"50px", height:"50px"}} 
                     src={babySelected.baby_profile_picture}
                     /> 
                   : <><img src={addChildPhotoIcon} style={{width:"50px", height:"50px"}} /> <AddProfilePhotoIcon className='profile-photo-icon-header' isSmall /></> }
           </div>
        }
        </>
      }
      extra={[
        // Mobile Menu
        <React.Fragment key="1">
          <div className=" d-flex header-menu">
            <div className="m-auto d-table">
              {/* Page Title (if have) */}
              {pageTitle && pageSubtitle ? (
                <>
                  <h3 className="text-center my-0 page-title">{pageTitle}</h3>
                  <h4 className="text-center my-0 page-subtitle">{pageSubtitle}</h4>
                </>
              ) : pageTitle ? 
              <h3 className="text-center my-0 page-title">{pageTitle}</h3> 
                : null
              }
              {/* Child Dropdown */}
              {!hideHeaderChildMenu && (
                <Dropdown
                  visible={dropdownVisible}
                  onVisibleChange={() => setDropdownVisible(!dropdownVisible)}
                  overlay={
                    <ChildMenu
                      history={history}
                      setDropdownVisible={setDropdownVisible}
                    />
                  }
                  trigger={["click"]}
                >
                  <span className="ant-dropdown-link mx-auto d-table child-menu clickable">
                    <span>
                      {babySelected
                        ? capitalizeTexts(babySelected.baby_first_name)
                        : "Select a Child"}
                      <ArrowDownChildMenu />
                    </span>
                  </span>
                </Dropdown>
              )}
            </div>
          </div>
          {/* Drawer Toggle */}
           <>
           <Badge count={storeLength} className='cart-button-badge'>
              <CartIcon 
                 className={cartIconMargin}
                onClick={() => {
                  history.push("/cart");
                }}
                isSelected={isCartPage}
              />
           </Badge>
          
              
              
                <Button
                  className="menu-button"
                  type="link"
                  icon="menu"
                  size="large"
                  onClick={() => setDrawerOpen(!drawerOpen)}
                />
            </>       
        </React.Fragment>
      ]}
      className="header elevation-light"
    />
  );
};

export default Header;
