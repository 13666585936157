/**
 * Replace all ocurrences of some value in a text with a replaceValue
 */
export const replaceAll = (
  text: string,
  value: string,
  replaceValue: string
) => {
  let count = 0;
  while (text.indexOf(value) !== -1 && count < 200) {
    // Limit count to 200 to prevent memory leak errors
    text = text.replace(value, replaceValue);
    count++;
  }
  return text;
};

/**
 * Capitalize just first letter of a text
 */
export const capitalizeText = (text: string): string =>
  `${text.charAt(0).toUpperCase()}${text.slice(1)}`;

/**
 * Return capitalized text (all texts separated by commas)
 *
 * @param {string} text   gabriel de carvalho vaz
 * @return {string}       Gabriel De Carvalho Vaz
 */
export const capitalizeTexts = (text: string): string => {
  return text.indexOf(' ') !== -1
    ? text
        .split(' ')
        .map(e => capitalizeText(e))
        .join(' ')
    : capitalizeText(text);
};

export const replaceThumbURL = (url: string): string =>
  url.replace('thumb_', '');

/**
 * Get last segment from a url
 * Ex:
 *  @param {string} http://asdasdasd.com/asd.png
 *  @return {string} asd.png
 */
export const lastSegmentUrl = (
  url: string,
  removeThumbString: boolean = false
): string => {
  const lastSegment =
    url.indexOf('/') !== -1 ? url.substr(url.lastIndexOf('/') + 1) : url;
  return removeThumbString ? lastSegment.replace('thumb_', '') : lastSegment;
};

export const isURL = (url: string): boolean =>
  url.match(
    // eslint-disable-next-line
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  ) !== null;

export const addSuffix = (days: number): string => {
  let suffix = '';
  const ones = days % 10;

  if (ones === 1 && days !== 11) {
    suffix = 'st';
  } else if (ones === 2 && days !== 12) {
    suffix = 'nd';
  } else if (ones === 3 && days !== 13) {
    suffix = 'rd';
  } else {
    suffix = 'th';
  }

  return `${days}${suffix}`;
};

export const removeDiacritics = (
  text: string,
  replaceSpaces: boolean = false,
  lowerCase: boolean = false
) => {
  if (replaceSpaces) text = replaceAll(text, ' ', '_');
  if (lowerCase) text = text.toLowerCase();

  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const capitalizeRawPronoun = (
  text: string
) => {
  let regex = /(^|[.!?]\s+)([a-z])/g;
  return text.replace(regex, (m, $1, $2) => $1 + $2.toUpperCase());
};