import React from "react";

interface Props {
    classes?: string;
}

const ArrowNext: React.FC<Props> = ({
    classes
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={classes}
    >
      <path
        fill="none"
        stroke="#FFF"
        strokeDasharray="0,0"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10 6l6 6-6 6"
      ></path>
    </svg>
  );
  }
export default ArrowNext;
