import { Customer } from '../../interfaces';
import { BaseAction, CUSTOMER_CHANGE, USER_LOGOUT } from '../actionTypes';

const initialState: Customer = {};

export const customer = (
  state: Customer = initialState,
  action: BaseAction
): Customer => {
  switch (action.type) {
    case CUSTOMER_CHANGE:
      return { ...state, ...action.payload };
    case USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
Object.freeze(customer);

export const changeCustomer = (payload: any) => ({
  payload,
  type: CUSTOMER_CHANGE,
});
Object.freeze(changeCustomer);

export default customer;
