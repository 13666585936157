import React from "react";

interface Props {
  isSelected?: boolean;
}

const BookIcon: React.FC<Props> = ({isSelected}) => {
  if (isSelected) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="29"
        viewBox="0 0 20 29"
        className="mx-3 mx-sm-4"
    >
      <g fill="#FFAFA5" fillRule="evenodd">
        <circle cx="2" cy="2" r="2" transform="translate(8 1)"></circle>
        <path
          fillRule="nonzero"
          d="M12.554 15.15c.47.472.468 1.238-.033 1.739l-2.502 2.716-2.54-2.755a1.21 1.21 0 011.725-1.694l.114.112.703.693.703-.693.077-.076.084-.067.024-.036c.218-.19.495-.295.789-.295a1.2 1.2 0 01.856.356zm-.856-1.356a2.2 2.2 0 00-1.56.645l-.12.118-.112-.112a2.2 2.2 0 00-1.562-.647 2.204 2.204 0 00-2.208 2.208c-.003.58.226 1.139.634 1.549l2.932 3.181a.432.432 0 00.61.025l.024-.024 2.92-3.168c.864-.861.867-2.26.006-3.123a2.193 2.193 0 00-1.564-.652z"
        ></path>
        <path
          fillRule="nonzero"
          d="M16.928 24.669c.233 0 .43-.19.43-.431V12.159A2.16 2.16 0 0015.199 10H4.843a2.166 2.166 0 00-2.161 2.159v13.81a2.16 2.16 0 002.16 2.154h12.087c.24 0 .43-.191.43-.432a.434.434 0 00-.43-.431h-.365v-2.591h.365zm-13.247.266V12.164a1.169 1.169 0 011.16-1.165H15.2c.64 0 1.16.52 1.16 1.159v11.511H5.899c-.967.009-1.806.524-2.218 1.266zm11.882 2.325H5.908c-.827 0-1.497-.578-1.497-1.292 0-.712.67-1.291 1.497-1.299h9.655v2.591z"
        ></path>
      </g>
    </svg>
    )
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="29"
        viewBox="0 0 20 29"
        className="mx-3 mx-sm-4"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M12.554 15.15c.47.472.468 1.238-.033 1.739l-2.502 2.716-2.54-2.755a1.21 1.21 0 011.725-1.694l.114.112.703.693.703-.693.077-.076.084-.067.024-.036c.218-.19.495-.295.789-.295a1.2 1.2 0 01.856.356zm-.856-1.356a2.2 2.2 0 00-1.56.645l-.12.118-.112-.112a2.2 2.2 0 00-1.562-.647 2.204 2.204 0 00-2.208 2.208c-.003.58.226 1.139.634 1.549l2.932 3.181a.432.432 0 00.61.025l.024-.024 2.92-3.168c.864-.861.867-2.26.006-3.123a2.193 2.193 0 00-1.564-.652z"
        ></path>
        <path d="M12.554 15.15c.47.472.468 1.238-.033 1.739l-2.502 2.716-2.54-2.755a1.21 1.21 0 011.725-1.694l.114.112.703.693.703-.693.077-.076.084-.067.024-.036c.218-.19.495-.295.789-.295a1.2 1.2 0 01.856.356z"></path>
        <path d="M11.698 13.794a2.2 2.2 0 00-1.56.645l-.12.118-.112-.112a2.2 2.2 0 00-1.562-.647 2.204 2.204 0 00-2.208 2.208c-.003.58.226 1.139.634 1.549l2.932 3.181a.432.432 0 00.61.025l.024-.024 2.92-3.168c.864-.861.867-2.26.006-3.123a2.193 2.193 0 00-1.564-.652z"></path>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M16.928 24.669c.233 0 .43-.19.43-.431V12.159A2.16 2.16 0 0015.199 10H4.843a2.166 2.166 0 00-2.161 2.159v13.81a2.16 2.16 0 002.16 2.154h12.087c.24 0 .43-.191.43-.432a.434.434 0 00-.43-.431h-.365v-2.591h.365zm-13.247.266V12.164a1.169 1.169 0 011.16-1.165H15.2c.64 0 1.16.52 1.16 1.159v11.511H5.899c-.967.009-1.806.524-2.218 1.266zm11.882 2.325H5.908c-.827 0-1.497-.578-1.497-1.292 0-.712.67-1.291 1.497-1.299h9.655v2.591z"
        ></path>
        <path d="M16.928 24.669c.233 0 .43-.19.43-.431V12.159A2.16 2.16 0 0015.199 10H4.843a2.166 2.166 0 00-2.161 2.159v13.81a2.16 2.16 0 002.16 2.154h12.087c.24 0 .43-.191.43-.432a.434.434 0 00-.43-.431h-.365v-2.591h.365z"></path>
        <path d="M3.68 24.935V12.164a1.169 1.169 0 011.162-1.165h10.357c.64 0 1.16.52 1.16 1.159v11.511H5.899c-.967.009-1.806.524-2.218 1.266z"></path>
        <path d="M15.563 27.26H5.908c-.827 0-1.497-.578-1.497-1.292 0-.712.67-1.291 1.497-1.299h9.655v2.591z"></path>
      </g>
    </svg>
    );
  }
 
}

export default BookIcon;
