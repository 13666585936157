import { Icon, Spin, Modal, Button } from "antd";
import arrayMove from "array-move";
import { isEqual } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Cropper from "react-cropper";
import { SortEnd, SortEvent } from "react-sortable-hoc";
import { Layout, SaveBabyPagePhoto } from "../../interfaces";
import { Background } from "../../interfaces/background";
import {
  finishCrop,
  initialPhotoState,
  photoClick,
  PhotoSelected,
  selectImage,
  selectImageURL,
} from "../../pages/BabyPage/Save/SaveHelper";
import { possibleLayouts } from "../../utils/layouts";
import PhotoGallery from "../Gallery/PhotoGallery";
import ImageEditModal from "../ImageEditModal/ImageEditModal";
import SelectPhotoModal from "./SelectPhotoModal";
import PlusAlert from "./PlusAlert";

interface Props {
  photos: SaveBabyPagePhoto[];
  layout: Layout;
  setPhotos: (
    photos: SaveBabyPagePhoto[],
    layout: Layout,
    forceRecrop?: boolean
  ) => void;
  isPlus: boolean;
  showPlus: () => void;
  backgroundSelected?: Background;
  selectedPlus: { border: boolean; illustration: boolean; layout: boolean };
  setSelectedPlus: ({ border, illustration, layout }: any) => void;
  title?: string;
  step?: number;
}

const SelectPhotos: React.FC<Props> = ({
  photos,
  layout,
  setPhotos,
  isPlus,
  showPlus,
  backgroundSelected,
  selectedPlus,
  setSelectedPlus,
  title,
  step
}) => {
  const [cropperRef, setCropperRef] = useState<Cropper | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [photoSelected, setPhotoSelected] = useState<PhotoSelected>(
    initialPhotoState
  );
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const onSelectImage = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      selectImage(
        e,
        photoSelected,
        photos,
        setPhotos,
        layout,
        setPhotoSelected,
        inputRef,
        setLoading,
        setModalOpen
      ),
    [layout, photoSelected, photos, setPhotos, inputRef]
  );

  const onSelectImageURL = useCallback(
    (imageUrl: string) =>
      selectImageURL(
        imageUrl,
        photoSelected,
        photos,
        setPhotos,
        layout,
        setPhotoSelected,
        inputRef,
        setLoading,
        undefined,
        setModalOpen,
      ),
    [layout, photoSelected, photos, setPhotos, inputRef, setModalOpen]
  );

  const onPhotoClick = useCallback(
    (index: number, photo: SaveBabyPagePhoto) =>
      photoClick(
        index,
        photo,
        inputRef,
        layout,
        setPhotoSelected,
        setModalOpen,
        step
      ),
    [layout, inputRef]
  );

  const onCropComplete = useCallback(
    (croppedData: Cropper.Data, imageData: Cropper.ImageData) => {
      if (
        !!croppedData &&
        (!photoSelected.croppedData ||
          !isEqual(croppedData, photoSelected.croppedData))
      ) {
        setPhotoSelected((state) => ({
          ...state,
          croppedData,
          imageData,
        }));
      }
    },
    [photoSelected]
  );

  const onFinishCrop = useCallback(
    async (_: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (cropperRef !== null) {
        finishCrop(
          cropperRef,
          photoSelected,
          photos,
          setPhotos,
          layout,
          setLoading,
          setPhotoSelected
        );
      }
    },
    [cropperRef, photoSelected, photos, setPhotos, layout]
  );

  const onSortEnd = useCallback(
    (sort: SortEnd, _: SortEvent) => {
      if (sort.oldIndex !== sort.newIndex) {
        const newPhotos = arrayMove([...photos], sort.oldIndex, sort.newIndex);
        setPhotos(newPhotos, layout, true);
      }
    },
    [photos, setPhotos, layout]
  );

  useEffect(() => {
    if(photos[0].base64_cropped !== "" && localStorage.getItem('show1stPhotoModal') !== "false") {
      setModalVisible(true)
    }
  }, [photos])

  return (
    <>
      <h1 className="title title-tips my-4">{photos[0].base64_cropped === "" ? "Select your baby's photos"  :  " To reposition your photo(s) click on a photo."}</h1>
      {loading && (
        <div className="mx-auto d-table my-3">
          <Spin
            indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
          />
        </div>
      )}
      <input
        ref={inputRef}
        type="file"
        accept=".jpg, .jpeg, .png"
        onChange={(e) => {
          onSelectImage(e);
          e.target.value = "";
        }}
        style={{
          width: 0,
          height: 0,
          display: "none",
        }}
      />
      <div className="bp-gallery mx-auto mb-3">
        <PhotoGallery
          photos={photos}
          layout={layout}
          onClickPhoto={onPhotoClick}
          onSortEnd={onSortEnd}
          backgroundSelected={backgroundSelected}
        />
      </div>
      <div className="p-3">
        <div className="d-flex flex-wrap justify-content-center align-items-center">
          {possibleLayouts.map((e, index: number) => (
            <div key={index} style={{ position: "relative" }}>
              {!isPlus && !e.isFree && <PlusAlert style={{ marginLeft: 25 }} />}
              <img
                src={`/external_assets/collages/icons_${
                  layout === e.layout ? "salmon" : "gray"
                }/${e.layout}.png`}
                alt={`Collage ${e.layout}`}
                className="collage mx-1"
                style={{ position: "relative" }}
                onClick={() => {
                  setPhotoSelected(initialPhotoState);
                  setPhotos(photos, possibleLayouts[index].layout);
                  selectedPlus.layout = !isPlus && !e.isFree ? true : false;
                  setSelectedPlus(selectedPlus);
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <ImageEditModal
        loading={loading}
        cropperRef={cropperRef}
        maskClosable={false}
        setCropperRef={setCropperRef}
        visible={photoSelected.edit}
        image={photoSelected.index !== null ? photoSelected.base64 : ""}
        croppedData={photoSelected.croppedData}
        aspect={photoSelected.aspect}
        onCropComplete={onCropComplete}
        onOk={onFinishCrop}
        onCancel={() => setPhotoSelected(initialPhotoState)}
        onChangePhoto={() => {
          setPhotoSelected((state) => ({ ...state, edit: false }));
          setModalOpen(true);
        }}
      />
      <SelectPhotoModal
        visible={modalOpen}
        maskClosable={false}
        onCancel={() => setModalOpen(false)}
        inputRef={inputRef}
        onSelectPhoto={onSelectImageURL}
      />
      <Modal
      visible={modalVisible}
      closable={true}
      onCancel={() => setModalVisible(false)}
      footer={null}
    >
      <div className="row justify-content-center align-items-center mb-3">
        
        
        <h3 className="responsive-sub-title my-1 px-3 text">
          To reposition your photo(s) click on a photo. Then scroll to zoom in/out or click and drag to reposition the photo.
        </h3>

          <span onClick={() => setModalVisible(false)} className="responsive-sub-title my-1 px-3 text">
              Do you want to see this message again?
          </span>
        
       
      </div>
      <div className="row justify-content-center align-items-center">
    
          <Button  type="primary"
            size="small"
            shape="round"
            className="mx-3 py-3 px-3" 
            onClick={() => {
              setModalVisible(false)
          }}>
            Yes
          </Button>

     
       
          <Button  type="primary"
              size="small"
              shape="round"
              className="mx-3 py-3 px-3" 
              onClick={() => {
                localStorage.setItem("show1stPhotoModal", "false")
                setModalVisible(false)
            }}>
              No
          </Button>
    
      </div>
    </Modal>
    </>
  );
};

export default SelectPhotos;
