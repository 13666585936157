import { Col, Row } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import AdminEditableQuestionList from "../../../../components/AdminEditableQuestionList/AdminEditableQuestionList";
import AdminMilestoneFormContainer from "../../../../components/AdminMilestoneForm/AdminMilestoneFormContainer";

const MilestoneDetailPage: React.FC = () => {
  let { id } = useParams();

  return (
    <div className="m-3">
      <h1 className="title">Milestone Detail</h1>
      {!!id && (
        <Row>
          <Col span={12} style={{ padding: "5px" }}>
            <AdminMilestoneFormContainer milestoneId={parseInt(id)} />
          </Col>
          <Col span={12} style={{ padding: "5px" }}>
            <AdminEditableQuestionList milestoneId={parseInt(id)} />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default MilestoneDetailPage;
