import React from "react";
interface Props {
  isSelected?: boolean;
}

const SettingsIcon: React.FC<Props> = ({isSelected}) =>  {
  if( isSelected ) {
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="29"
      viewBox="0 0 20 29"
      className="mx-3 mx-sm-4"
    >
      <g fill="none" fillRule="evenodd">
        <circle
          cx="2"
          cy="2"
          r="2"
          fill="#FFAFA5"
          transform="translate(8 1)"
        ></circle>
        <path
          stroke="#F7ADA5"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10 22a3 3 0 11.002-6.002A3 3 0 0110 22z"
        ></path>
        <path
          stroke="#F7ADA5"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15.962 22.241c.048.265.174.51.362.703l.05.048a1.663 1.663 0 01.48 1.16 1.651 1.651 0 01-.48 1.158 1.663 1.663 0 01-1.157.48c-.216 0-.428-.044-.627-.127a1.64 1.64 0 01-.532-.354l-.05-.05a1.351 1.351 0 00-1.487-.27 1.364 1.364 0 00-.594.496 1.34 1.34 0 00-.225.74v.14a1.637 1.637 0 11-3.273 0v-.074a1.35 1.35 0 00-.884-1.237 1.356 1.356 0 00-1.489.271l-.048.049a1.635 1.635 0 01-2.317 0 1.667 1.667 0 01-.355-.531 1.649 1.649 0 010-1.254c.083-.199.204-.379.355-.53l.05-.05a1.344 1.344 0 00.27-1.488 1.35 1.35 0 00-1.236-.82h-.138a1.635 1.635 0 01-1.158-2.793 1.64 1.64 0 011.158-.479h.072a1.353 1.353 0 001.237-.884 1.356 1.356 0 00-.27-1.489l-.05-.048a1.663 1.663 0 01-.355-.532 1.619 1.619 0 01-.124-.626 1.651 1.651 0 01.478-1.159 1.663 1.663 0 011.16-.479 1.64 1.64 0 011.158.48l.048.049a1.352 1.352 0 001.488.27h.066a1.358 1.358 0 00.818-1.236v-.14A1.64 1.64 0 0110 10a1.635 1.635 0 011.637 1.636v.073a1.35 1.35 0 00.818 1.237 1.356 1.356 0 001.489-.271l.048-.049a1.64 1.64 0 011.159-.48 1.634 1.634 0 011.513 2.265 1.667 1.667 0 01-.355.53l-.05.05a1.344 1.344 0 00-.27 1.488v.066c.104.242.276.45.496.594.219.146.477.224.74.225h.138a1.637 1.637 0 011.159 2.793 1.636 1.636 0 01-1.159.479h-.072a1.35 1.35 0 00-1.237.819 1.35 1.35 0 00-.092.786z"
        ></path>
      </g>
    </svg>
    )
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="29"
        viewBox="0 0 20 29"
        className="mx-3 mx-sm-4"
    >
      <g fill="none" fillRule="evenodd" stroke="#FFF">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10 22a3 3 0 11.002-6.002A3 3 0 0110 22z"
        ></path>
        <path d="M10 10.5a1.136 1.136 0 011.137 1.136c0 .453.113.797.306 1.088.199.302.483.54.812.68a1.856 1.856 0 002.042-.376 1.31 1.31 0 01.418-.296 1.14 1.14 0 01.436-.085c.448 2.107.338 2.544.268 2.88l-.015.077a1.84 1.84 0 00.12 1.057c.145.418.385.697.683.893.3.202.654.309 1.018.31.413 0 .72.11.943.333a1.136 1.136 0 01-.806 1.939c-.452.001-.794.113-1.086.307a1.85 1.85 0 00-.68.812 1.856 1.856 0 00.375 2.042c.145.146.236.274.296.42.058.138.087.285.087.434-2.27.481-2.603.318-2.96.253a1.86 1.86 0 00-1.072.126 1.863 1.863 0 00-1.12 1.695c0 .413-.11.72-.333.943a1.136 1.136 0 01-1.606.002 1.148 1.148 0 01-.334-.815c-.009-.465-.134-.814-.345-1.11a1.854 1.854 0 00-.853-.655 1.856 1.856 0 00-2.028.382 1.285 1.285 0 01-.419.296 1.134 1.134 0 01-.434.086c-.449-2.108-.339-2.545-.269-2.881l.015-.077c.067-.364.021-.74-.126-1.072a1.849 1.849 0 00-1.695-1.122c-.412 0-.719-.11-.942-.333a1.135 1.135 0 01-.001-1.607c.214-.213.503-.333.814-.333.464-.009.814-.133 1.108-.345.298-.213.526-.51.656-.853a1.856 1.856 0 00-.38-2.028 1.268 1.268 0 01-.297-.42 1.12 1.12 0 01-.086-.433c2.269-.48 2.603-.318 2.957-.255.357.065.725.024 1.057-.117.417-.147.697-.388.894-.686.2-.3.308-.655.308-1.017 0-.412.111-.72.334-.943.213-.213.502-.332.803-.332z"></path>
      </g>
    </svg>
      );
  }
 
  
}

export default SettingsIcon;
