import React from "react";

function BabyPageRefresh() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="11" cy="11" r="11" fill="#B0B0B0"></circle>
        <path
          fill="#FFF"
          d="M17.995 11.074a.234.234 0 00-.064-.116.28.28 0 00-.07-.048.39.39 0 00-.167-.039c-.154-.002-.307-.002-.46-.002h-.337v-.063a6.164 6.164 0 00-.424-1.997 5.948 5.948 0 00-.917-1.553 6.027 6.027 0 00-1.826-1.533 6.146 6.146 0 00-2.29-.69 5.817 5.817 0 00-2.803.387 5.73 5.73 0 00-1.275.693.87.87 0 00-.362.71.97.97 0 00.037.259c.05.173.152.322.287.432a.929.929 0 00.57.203.77.77 0 00.175-.02c.084-.019.159-.05.228-.083a2.22 2.22 0 00.192-.11 5.105 5.105 0 011.177-.55 3.954 3.954 0 011.18-.184c.347 0 .695.048 1.046.144a4.84 4.84 0 011.059.44c.615.341 1.09.778 1.44 1.302.35.523.576 1.136.683 1.83.013.089.021.181.029.279l.006.102h-.343c-.145 0-.29 0-.437.002a.423.423 0 00-.161.035.322.322 0 00-.055.03.25.25 0 00-.056.056l-.003.003.003-.003a.215.215 0 00-.042.13c0 .028.004.05.009.069a.418.418 0 00.059.132c.553.834 1.107 1.665 1.661 2.497l.038.054c.01.012.02.025.033.038.018.018.04.038.07.057a.25.25 0 00.183.025.262.262 0 00.081-.038.352.352 0 00.071-.067.888.888 0 00.052-.07c.555-.831 1.11-1.662 1.662-2.496a.446.446 0 00.058-.132.3.3 0 00.003-.115m-3.388 3.356a.838.838 0 00-.783-.099 1.28 1.28 0 00-.292.149 4.485 4.485 0 01-1.129.55 3.904 3.904 0 01-1.196.186 4.749 4.749 0 01-1.934-.44 3.913 3.913 0 01-1.283-.962 4.761 4.761 0 01-.861-1.462 3.839 3.839 0 01-.239-1.22h.33l.458-.003h.004-.004a.387.387 0 00.16-.038.244.244 0 00.052-.031.258.258 0 00.053-.054.21.21 0 00.04-.128.373.373 0 00-.063-.192 786.795 786.795 0 00-1.676-2.52.6.6 0 00-.037-.05.349.349 0 00-.076-.072.255.255 0 00-.195-.037.246.246 0 00-.077.032.334.334 0 00-.072.062.644.644 0 00-.05.065c-.557.837-1.115 1.674-1.67 2.513a.431.431 0 00-.061.147.245.245 0 00.001.11.224.224 0 00.04.08.29.29 0 00.074.06.437.437 0 00.195.052l.007.001h-.007c.147.002.295.002.443.002h.344c.024.679.148 1.325.376 1.933a5.987 5.987 0 001.03 1.753 6.008 6.008 0 001.808 1.479 6.21 6.21 0 002.247.67c.21.023.42.034.627.034a5.91 5.91 0 002.12-.4 6 6 0 001.297-.69.92.92 0 00.294-.336.893.893 0 000-.814.904.904 0 00-.295-.33"
        ></path>
      </g>
    </svg>
  );
}

export default BabyPageRefresh;
