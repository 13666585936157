import { Button, Card, Modal, notification, Spin, Tabs } from "antd";
import moment, { Moment } from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import { DashboardInfo, Entry, User } from "../../interfaces";
import { Baby } from "../../interfaces/baby";
import { AppState, sagaMiddleware } from "../../store";
import {
  deleteEntryDashboard,
  saveEntryDashboard,
} from "../../store/sagas/dashboard";
import { trackAction } from "../../utils/marketing";
import EntryModal from "../EntryModal/EntryModal";
import { useWindowSize } from "../../hooks/WindowsSize";
import "./GrowthChart.scss";
import { connect } from "react-redux";
import { remainingOuncesByPound } from "../../utils/calculates";

const formatDate = (dateString: string): string => {
  return moment(dateString).format("MM[/]DD[/]YYYY");
};

export interface Props {
  baby: DashboardInfo | Baby;
  customTitle?: string;
  customFontColor?: string;
  customChartColor?: string;
  customFont?: string;
  customFromTo?: number[];
  defaultType?: "height" | "weight";
  onChangeType?: (type: "height" | "weight") => void;
  isGuestUser?: boolean;
  user?: User;
}

const GrowthChart: React.FC<Props> = ({
  baby,
  customFontColor,
  customChartColor,
  customFont,
  customFromTo,
  defaultType,
  onChangeType,
  isGuestUser,
  user,
}) => {
  const [tabSelected, setTabSelected] = useState(
    !!defaultType ? (defaultType === "height" ? "1" : "2") : "1"
  );
  const [entry, setEntry] = useState<Entry | null>(null);
  const [chartLoading, setChartLoading] = useState(false);
  const [width] = useWindowSize();
  const [heightChart, weightChart] = useMemo(
    () => [
      (!!customFromTo && !!baby.heights && !!baby.heights.length
        ? [...baby.heights].slice(customFromTo[0], customFromTo[1] + 1)
        : baby.heights
      )
        .filter(height => parseFloat(height.height) >= 0)
        .map(height => {
          const Inches = parseFloat(height.height);

          return {
            ...height,
            name: formatDate(height.entry_date),
            id: height.id,
            Inches: Inches > 99.9 ? 99.9 : Inches,
          };
        }),
      (!!customFromTo && !!baby.weights && !!baby.weights.length
        ? [...baby.weights].slice(customFromTo[0], customFromTo[1] + 1)
        : baby.weights
      )
        .filter(weight => parseInt(weight.oz) >= 0)
        .map(weight => {
          const Pounds = parseFloat(
            parseFloat((parseInt(weight.oz) / 16).toString()).toString()
          );

          return {
            ...weight,
            id: weight.id,
            name: formatDate(weight.entry_date),
            Pounds: Pounds > 114 ? 114 : Pounds,
          };
        }),
    ],
    [baby, customFromTo]
  );

  const createOrEditEntry = useCallback(
    (event: any, isHeight: boolean) => {
      if (!!event && !!event[0] && event[0]._index >= 0) {
        let payload: any;

        if (isHeight) {
          payload = heightChart[event[0]._index];
          let weight = baby.weights.find(
            (e) => e.entry_date === payload.entry_date
          );

          payload.oz = !!weight ? parseInt(weight.oz) : 0;
          payload.weight_id = !!weight ? weight.id : null;
        } else {
          payload = weightChart[event[0]._index];
          let height = baby.heights.find(
            (e) => e.entry_date === payload.entry_date
          );

          payload.height = !!height ? parseFloat(height.height) : 0;
          payload.height_id = !!height ? height.id : null;
        }

        // Edit Entry
        setEntry({
          baby_id: payload.baby_id,
          entry_date: moment(payload.entry_date),
          height: payload.height,
          weight_lbs: ~~(payload.oz / 16), // Get the quocient (Ex: 20 / 3 = 6)
          weight_oz: payload.oz % 16, // Get the rest (Ex: 20 % 3 = 2)
          isEdit: true,
          height_id: isHeight ? payload.id : payload.height_id,
          weight_id: isHeight ? payload.weight_id : payload.id,
        });
      } else {
        // Create new
        const entry: Entry = {
          entry_date: moment(),
          baby_id: baby.id,
          height: 0,
          weight_oz: 0,
          weight_lbs: 0,
        };

        setEntry(entry);
      }
    },
    [baby.heights, baby.weights, baby.id, heightChart, weightChart]
  );

  const onSave = useCallback(() => {
    if (entry !== null && moment.isMoment(entry.entry_date) && !chartLoading) {
      const entryToSave = {
        ...entry,
        entry_date: !entry.isEdit
          ? entry.entry_date.format("YYYY[-]MM[-]DD")
          : `${entry.entry_date.format("YYYY[-]MM[-]DD")} 00:00:00`,
        isEdit: !!entry.isEdit ? entry.isEdit : undefined,
      };
      setEntry(null);
      setChartLoading(true);

      sagaMiddleware.run<any>(
        saveEntryDashboard,
        entryToSave,
        (error: any | null) => {
          setChartLoading(false);

          if (error !== null) {
            Modal.error({
              title: "Error",
              content: error,
            });
          } else {
            trackAction(
              "CreateGrowthChart",
              {
                google: {
                  event: "create_growth_chart",
                },
              },
              true
            );
            notification.success({
              message: "Success",
              description: "Entry saved",
            });
          }
        }
      );
    }
  }, [entry, chartLoading]);

  const onDelete = useCallback(() => {
    if (entry !== null && moment.isMoment(entry.entry_date) && !chartLoading) {
      Modal.confirm({
        title: "Are you sure you want to remove this entry?",
        onOk() {
          const entryDateFormated = (entry.entry_date as Moment).format(
            "YYYY[-]MM[-]DD"
          );
          const height = baby.heights.find(
            (h) =>
              moment(h.entry_date).format("YYYY[-]MM[-]DD") ===
              entryDateFormated
          );
          const weight = baby.weights.find(
            (w) =>
              moment(w.entry_date).format("YYYY[-]MM[-]DD") ===
              entryDateFormated
          );

          if (!!height || !!weight) {
            setEntry(null);
            setChartLoading(true);

            sagaMiddleware.run<any>(
              deleteEntryDashboard,
              height?.id ?? 0,
              weight?.id ?? 0,
              (error: any | null, description?: string) => {
                setChartLoading(false);

                if (error !== null) {
                  Modal.error({
                    title: "Error",
                    content: error,
                  });
                } else {
                  notification.success({
                    message: "Success",
                    description,
                  });
                }
              }
            );
          } else {
            Modal.error({
              title: "Error",
              content: "Entry not found",
            });
          }
        },
      });
    }
  }, [entry, chartLoading, baby.heights, baby.weights]);

  const onChangeEntry = useCallback(
    (
      value: number | undefined,
      field: "height" | "weight_lbs" | "weight_oz"
    ) => {
      if (value !== undefined && entry !== null) {
        setEntry({ ...entry, [field]: value });
      }
    },
    [entry]
  );

  const onChangeDate = useCallback(
    (date: Moment | null, _: string) => {
      if (date !== null && entry !== null) {
        const newEntry = { ...entry, entry_date: date };

        const searchEntryHeight = baby.heights.filter(
          (height) =>
            moment(height.entry_date).format("DDMMYYYY") ===
            date.format("DDMMYYYY")
        );
        const searchEntryWeight = baby.weights.filter(
          (weight) =>
            moment(weight.entry_date).format("DDMMYYYY") ===
            date.format("DDMMYYYY")
        );

        if (searchEntryHeight.length > 0) {
          newEntry.height = parseFloat(searchEntryHeight[0].height);
          newEntry.height_id = searchEntryHeight[0].id;
        }

        if (searchEntryWeight.length > 0) {
          const oz = parseInt(searchEntryWeight[0].oz);
          newEntry.weight_lbs = ~~(oz / 16);
          newEntry.weight_oz = oz % 16;
          newEntry.weight_id = searchEntryWeight[0].id;
        }

        setEntry(newEntry);
      }
    },
    [entry, baby.heights, baby.weights]
  );
  const renderChart = (labels: string[], data: any[], isHeight: boolean) => (
    <Line
      data={{
        labels,
        datasets: [
          {
            label: isHeight ? "Inches" : "Pounds",
            fill: false,
            lineTension: 0.6,
            backgroundColor: "#000",
            borderColor: !!customChartColor ? customChartColor : "#ffafa5",
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: "#FFFFFF",
            pointBackgroundColor: !!customChartColor
              ? customChartColor
              : "#ffafa5",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "rgba(75,192,192,1)",
            pointHoverBorderColor: "#FFFFFF",
            pointHoverBorderWidth: 2,
            pointRadius: 4,
            pointHitRadius: 10,
            data,
          },
        ],
      }}
      onElementsClick={(e) => {
        if (user?.permission !== "Read-Only") {
          createOrEditEntry(e, isHeight);
        }
      }}
      options={{
        responsive: true,
        legend: {
          display: false,
          onClick: (e) => e.stopPropagation(),
        },
        title: { display: false },
        scales: {
          yAxes: [
            {
              ticks: {
                fontFamily: "Montserrat",
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontFamily: "Montserrat",
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: function (
              tooltipItem: Chart.ChartTooltipItem,
              chartData: Chart.ChartData
            ) {
              if (!chartData.datasets) return "";

              const dataset = chartData.datasets[0];
              const pound: string | number | undefined = tooltipItem.yLabel;
              const remaining = remainingOuncesByPound(Number(pound));
              return isHeight
                ? `${dataset.label}: ${pound}`
                : `${Math.floor(Number(pound))}lbs ${
                    remaining ? `${remaining}oz` : ""
                  }`;
            },
          },
        },
      }}
    />
  );

  return (
    <>
      <Spin spinning={chartLoading}>
        <Card
          className={`d-block dashboard-card ${width > 767 ? "mb-4" : ""}`}
          headStyle={{
            color: !!customFontColor ? customFontColor : "#000000",
            fontFamily: !!customFont ? customFont : "ProximaNovaLight",
          }}
          style={{
            padding: 0,
          }}
        >
          <div
            className={`col-12 card-title mb-3 ${
              width > 768 ? "mt-2" : "mt-2"
            }`}
          >
            <h2>GROWTH CHART</h2>
          </div>
          <Tabs
            type="card"
            animated
            activeKey={tabSelected}
            className="growth-chart-dashboard"
            onChange={(e) => {
              setTabSelected(e);

              if (onChangeType) {
                onChangeType(e === "1" ? "height" : "weight");
              }
            }}
          >
            <Tabs.TabPane tab="Height" key="1">
              <div className="mx-1">
                {renderChart(
                  heightChart.map((h) => h.name),
                  heightChart.map((h) => h.Inches),
                  true
                )}
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Weight" key="2">
              <div className="mx-1">
                {renderChart(
                  weightChart.map((h) => h.name),
                  weightChart.map((h) => parseFloat(h.Pounds.toString())),
                  false
                )}
              </div>
            </Tabs.TabPane>
          </Tabs>

          {!isGuestUser && (
            <div className="row">
              <div className="col-12 px-5 my-3">
                <Button
                  type="primary"
                  ghost
                  shape="round"
                  className="w-100 green-button dashboard-button"
                  onClick={() => createOrEditEntry(null, tabSelected === "1")}
                >
                  Add Entry
                </Button>
              </div>
            </div>
          )}
        </Card>
      </Spin>
      <EntryModal
        babyname={baby.baby_first_name}
        opened={entry !== null}
        entry={entry}
        isEntryValid={
          entry !== null &&
          (typeof entry.entry_date === "string"
            ? !!entry.entry_date
            : moment.isMoment(entry.entry_date)) &&
          (entry.height > 0 || entry.weight_lbs > 0 || entry.weight_oz > 0)
        }
        onSave={onSave}
        onDelete={onDelete}
        onClose={() => setEntry(null)}
        onChangeDate={onChangeDate}
        onChangeEntry={onChangeEntry}
      />
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  user_level: state.user.user_level,
  babySelected: state.baby.babySelected,
  dashboard: state.dashboard,
  user: state.user,
});

export default connect(mapStateToProps)(GrowthChart);