import { Icon } from 'antd';
import React from 'react';
import {
  SortableContainer,
  SortableElement,
  SortEndHandler,
} from 'react-sortable-hoc';
import { SaveBabyPagePhoto } from '../../interfaces';
import './Layout2A.scss';
import { getProportionalSize, dynamicSize } from '../../utils/size';
import { highRes } from '../../env';
import { Background } from '../../interfaces/background';
import './Layout1A.scss'

interface Props {
  photos: SaveBabyPagePhoto[];
  onClickPhoto?: (index: number, photo: SaveBabyPagePhoto) => void;
  onSortEnd: SortEndHandler;
  ignoreMargin?: boolean;
  loading: boolean;
  backgroundSelected?: Background;
}

interface LayoutProps {
  photo: SaveBabyPagePhoto;
  photoIndex: number;
  onClickPhoto?: (index: number, photo: SaveBabyPagePhoto) => void;
  padding: string;
}

const SortableItem = SortableElement(
  ({ photo, photoIndex, onClickPhoto, padding = '0px' }: LayoutProps) => {
    const checkMissing = () => {
      if(photo.base64_cropped !== '' && photo.missing) {
        return false
      } else if(photo.base64_cropped !== '' && !photo.missing){
        return true
      }
    }

    return checkMissing() ? (
      <div
        className='col-12 layout2A__item'
        style={{ padding }}
        onClick={() => (!!onClickPhoto ? onClickPhoto(photoIndex, photo) : {})}
      >
        <img
          src={photo.base64_cropped}
          alt='Baby 1'
          className='baby-photo w-100 h-100'
        />
      </div>
    ) : (
      <div
        className='col-12 layout2A__item'
        style={{ padding }}
        onClick={() => (!!onClickPhoto ? onClickPhoto(photoIndex, photo) : {})}
      >
        <div className='add-photo w-100 h-100'>
          <Icon type='plus-circle' theme='filled' style={{ fontSize: 36 }} />
        </div>
      </div>
    )
  }
);

// @ts-ignore
const SortableLayout = SortableContainer(props => props.children);

const Layout2A: React.FC<Props> = ({
  photos,
  onClickPhoto,
  onSortEnd,
  ignoreMargin,
  loading,
  backgroundSelected,
}) => {
  return (
    <div
      className='row justify-content-center align-items-center h-100 w-100 mx-auto'
      style={{
        backgroundColor: !!backgroundSelected
          ? backgroundSelected.color
          : '#ffffff',
      }}
    >
      <SortableLayout pressDelay={200} onSortEnd={onSortEnd}>
        <div className='layout2A' style={{position:"relative"}}>
        <div style={{width: "100%", height:"100%"}}>
          {!loading ? (
            <div className="click-through" style={{width: "93%", height:"93%", border:"2px dashed #e70c0c", zIndex: 995,  position: "absolute",  transform: "translate(-50%, -50%)", top:"50%", left:"50%"}} />
          ): null }
          {photos.map((photo: SaveBabyPagePhoto, index: number) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              photoIndex={index}
              photo={photo}
              onClickPhoto={onClickPhoto}
              padding={
                ignoreMargin
                  ? '0px'
                  : index === 0
                  ? `0px 0px ${
                      loading
                        ? 5
                        : 1.5
                    }px 0px`
                  : `${
                      loading
                        ? 5
                        : 1.5
                    }px 0px 0px 0px`
              }
            />
          ))}
          </div>
        </div>
      </SortableLayout>
    </div>
  );
};

export default Layout2A;
