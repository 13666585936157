import arrayMove from "array-move";
import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  SortableContainer,
  SortableElement,
  SortEnd,
  SortEvent
} from "react-sortable-hoc";
import { BabyPage } from "../../interfaces";
import BabyPageOrderable from "../BabyPageOrderable/BabyPageOrderable";
import SortSelectedBabypages from "./SortSelectedBabypages"
import { Button } from "antd";
import "./OrderBabypages.scss"
import ArrowNext from "../Icons/ArrowNext";

interface Props {
  selectedBabypages: BabyPage[];
  setSelectedBabypages: React.Dispatch<React.SetStateAction<BabyPage[] | []>>;
  setControl: React.Dispatch<React.SetStateAction<{valid: boolean, step:number}>>;
  nextStep:React.EffectCallback;
}

const OrderBabypages: React.FC<Props> = ({
  selectedBabypages,
  setSelectedBabypages,
  setControl,
  nextStep
}) => {
  const sortOptions = [
    {
      value:'',
      label:''
    },
    {
    value: 'date',
    label: 'Creation Date'
    }, 
    {
    value: 'alpha',
    label: 'Alphabetical'
    },
    {
    value: 'milestone',
    label: 'Milestone Date'
    }
   ]
  const [sortBy, setSortBy] = useState<string>(sortOptions[0].value);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [reverseSort, setReverseSort] = useState<boolean>(false);
  const [validate1stReverse, setValidate1stReverse] = useState<boolean>(false);

  const sortedBabypages = [
    ...selectedBabypages
  ]
  
const sortBabypages = (sortBy: string) => {
    if(sortBy === 'alpha') {
      setSelectedBabypages(
      sortedBabypages.sort((a,b) => {
        let titleA = a.title.toLowerCase(),
            titleB = b.title.toLowerCase();
          if (titleA > titleB) {
            return 1
          } else if  (titleA < titleB) {
            return -1
          } else {
            return 0
          }
      })
      )
    } else if (sortBy === 'milestone') {
      setSelectedBabypages(
      sortedBabypages.sort((a,b) => {
        if(a.original_title && b.original_title) {
          let milestoneDateA = new Date(a.original_title)
          let milestoneDateB = new Date(b.original_title)
          
          return milestoneDateA.valueOf() - milestoneDateB.valueOf()
        } else {
          return 0
        }
      })
      )
    } else if(sortBy === 'date') {
      setSelectedBabypages(
      sortedBabypages.sort((a,b) => {
        if(a.created_at && b.created_at) {
          let createdDateA = new Date(a.created_at)
          let createdDateB = new Date(b.created_at)
          return createdDateB.valueOf() - createdDateA.valueOf()
        } else {
          return 0
        }
      })
      )
    } else {
      return;
    }
  }


 useEffect(() => {
  if(validate1stReverse) {
    setSelectedBabypages([
      ...sortedBabypages.reverse()
    ])
  }
 }, [reverseSort])


  const onSortEnd = useCallback(
    (sort: SortEnd, _: SortEvent) => {
      if (sort.oldIndex !== sort.newIndex) {
        const newOrder = arrayMove(
          [...selectedBabypages],
          sort.oldIndex,
          sort.newIndex
        );
        setSelectedBabypages(newOrder);
      }
    },
    [selectedBabypages, setSelectedBabypages]
  );

  const SortableList = SortableContainer(({ items }: { items: BabyPage[] }) => {
    return (
      <div className="py-2 select-babypages-margin-bottom">
        <div className="container">
          <div className="row justify-content-start align-items-center py-4">
            {!!items &&
              items.map((babypage: BabyPage, index: number) => (
                <SortableItem key={index} index={index} value={babypage} />
              ))}
          </div>
        </div>
      </div>
    );
  });

  const SortableItem = SortableElement(({ value }: { value: BabyPage }) => (
    <BabyPageOrderable babypage={value} />
  ));

  return (
    <>
    <div className="d-flex my-4 justify-content-center align-items-center">
      <div className=" p-0 book-instructions-container">
        <h1 className="book-instructions-primary text-center ">
        To reorder your pages simply tap & hold, then drag into position.
        </h1>
      </div>
      <SortSelectedBabypages 
          sortOptions={sortOptions} 
          sortBy={sortBy} 
          setSortBy={setSortBy}
          dropdownVisible={dropdownVisible}
          setDropdownVisible={setDropdownVisible}
          reverseSort={reverseSort}
          setReverseSort={setReverseSort}
          setValidate1stReverse={setValidate1stReverse}
          sortBabypages={sortBabypages}
        /> 
      </div> 
      <div
        className={`container-fluid create-book-container-width`}
        style={{
          backgroundColor: "#FFF",
          marginBottom: "50px",
          borderRadius: "15px"
        }}
        >
        <div className="create-book-scrollable-container create-book-scrollable-order-container">
          <SortableList items={selectedBabypages} onSortEnd={onSortEnd} axis="xy" />
        </div>
        <div className="d-flex flex-row justify-content-center my-4">
            <div className="col-lg-3 col-6">
              <Button type="primary" ghost shape="round" className="w-100 book-creation-buttons" onClick={() => {
                setControl((prev)  => {
                  return {
                    ...prev,
                    step:prev.step - 1,
                  }
                })
              }}>
                  Previous
              </Button>     
            </div>
            <div className="col-lg-3 col-6">
              <Button
                  type="primary"
                  ghost
                  shape="round"
                  className="w-100 green-button book-creation-buttons d-flex flex-row align-items-center justify-content-center"
                  onClick={nextStep}
                >
                  Next
                  <ArrowNext />
              </Button>
            </div>     
          </div>
    </div>
    </>
  );
};

export default OrderBabypages;
