import { Button, Icon, Modal, notification, Popover,Spin, Tooltip} from "antd";
import cloneDeep from "lodash/cloneDeep";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Book, StripeProduct, BabyPage, User } from "../../interfaces";
import { AppState, sagaMiddleware } from "../../store";
import { deleteBook, duplicateBook, fetchBookById,inspectAllBabypagesImages } from "../../store/sagas/books";
import { addProductStore, updateUserStore } from "../../store/sagas/cart";
import { capitalizeTexts } from "../../utils/string";
import ModalCoverPlus from "../ModalCoverPlus/ModalCoverPlus";
import BookModal from "../BookModal/BookModal";
import { getBrowserWidth } from "../../utils/size";
import "./BookPreview.scss";
import { PRODUCTION } from "../../env";
import { connect } from "react-redux";
const { confirm } = Modal;

interface CompleteBook {
  id: number;
  babypages: [BabyPage];
  babypages_ids: [number];
  cover:string;
  new_version?: boolean;
  pages: [string];
  pages_with_url: [string];
  parent_email: string;
  parent_id: number;
  subtitle: string;
  subtitle_styles?: {
    fontFamily: string;
  };
  thumbnail : string;
  thumbnail_with_url: string;
  title: string;
  title_style?: {
    fontFamily: string;
  };
  type:string;
}

interface Props {
  book: Book;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  stripeProducts: StripeProduct[];
  isGuestUser: boolean | undefined;
  isPlusUser: boolean | undefined;
  user: User;
}

const BookPreview: React.FC<Props> = ({
  book,
  setLoading,
  loading,
  stripeProducts,
  isGuestUser,
  isPlusUser,
  user
}) => {
  const history = useHistory();
  const [control, setControl] = useState({ popover: false });
  const [showPlus, setShowPlus] = useState(false);
  const [pagesWithError, setPagesWithError] = useState([])
  const [bookLoading, setBookLoading] = useState(false)
  const [bookModal, setBookModal] = useState(false);
  const [bpsCorruptedModal, setBpsCorruptedModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [completeBook, setCompleteBook] = useState<CompleteBook>();
  const [editPurchased, setEditPurchased] = useState<boolean>(false);
  const [checkedBook, setCheckedBook] = useState<boolean>(false);
  const width = getBrowserWidth();
  
  useEffect(() => {
    if(!!book.id) {
      setBookLoading(true);
      sagaMiddleware.run<any>(
        fetchBookById,
        parseInt(book.id.toString()),
        (error: any | null, bookBD?: any) => {
          if (error !== null || !bookBD) {
            history.push("/");
            setBookLoading(false);
            return;
          }
  
          try {
            setCompleteBook(bookBD);
            setBookLoading(false);
          } catch (error) {
            Modal.error({
              title: "Error",
              content: error.message ? error.message : "Error loading book",
            });
            history.push("/");
            setBookLoading(false);
          }        
          
        }
      );
    }
  }, [book, loading])


  const showDeleteConfirm = () => {
    confirm({
      title: (
        <>
          <h3 className="confirmation-question">Are you sure?</h3>
          <p>Do you want to delete this BabyPage?</p>
        </>
      ),
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      width: 300,
      icon: "warning",
      onOk() {
        setLoading(true);

        sagaMiddleware.run<any>(deleteBook, book.id, (err: string | null) => {
          setLoading(false);
          if (err) {
            Modal.error({
              title: "Error",
              content: err,
            });
          } else {
            notification.success({
              message: "Success",
              description: "The book was deleted successfully!",
            });
          }
        });
        const store = JSON.parse(user.store!)
        if(!!store) {
          let newStoreWithoutDeletedBook = store.filter((item :any) => item.book_id !== book.id.toString())
          if(newStoreWithoutDeletedBook.length !== store.length) {
              sagaMiddleware.run<any>(
                updateUserStore,
                JSON.stringify(newStoreWithoutDeletedBook),
                (error?: string, description?: string) => {
                  if (!!error) {
                    Modal.error({
                      title: 'Error',
                      content: error,
                    });
                  }
                }
              );
          }
        }
      },
    });
  };

  const addToCart = useCallback(() => {
    setControl((state) => ({ ...state, popover: false }));

    const stripeBooksProduct = stripeProducts.filter(
      (sp) =>
        sp.active &&
        sp.caption === "book" &&
        sp.id !== "prod_IFKYM8tBfQm4JL" &&
        sp.description !== "Perfect Bound"
    );
    const stripeProduct = stripeProducts.find(
      (sp) =>
        sp.description === "Perfect Bound" || sp.id === "prod_IFKYM8tBfQm4JL"
    );

    const stripeProductSelected =
      stripeBooksProduct.find(
        (sp) =>
          sp.id === book.type
      ) || stripeProducts[2];

    if (stripeProduct === undefined) {
      Modal.error({
        title: "Error",
        content: "Product not found",
      });
      return;
    }

    stripeBooksProduct.unshift(cloneDeep(stripeProduct));

    const pageType = (id : string) => {
      const books = [
        { 
          // Soft 6x6
          book: PRODUCTION ? "prod_IFKYM8tBfQm4JL" : "prod_I7jSbgrXSo5swo", 
          extraPage: PRODUCTION ? "prod_KBaDC6z4xR3E0t" : "prod_K405fzq8nRA3yi",
          type: "6x6"
        }, 
        { 
          // Hard 8x8
          book: PRODUCTION ? "prod_BapkUaCa3Cqagm" : "prod_BWKvTyP8P3Unjy", 
          extraPage: PRODUCTION ? "prod_KBaAeZVUgYzmlW" : "prod_K4iB8gtqa1HryM",
          type: "8x8"
        }, 
        { 
          // Soft 8x8
         book: PRODUCTION ? "prod_BapjuDhk2DuUDt" : "prod_BWKtiPgZB0ndp6",
         extraPage: PRODUCTION ? "prod_KBaAeZVUgYzmlW" : "prod_K4iB8gtqa1HryM",
         type: "8x8"
        } 
      ]
     const match = books.filter((item) => { 
        return item.book === id
      })
      const page = stripeProducts.filter((item) => {
        return item.id === match[0].extraPage
      })

      return {
        active: page[0].active,
        attributes: page[0].attributes,
        description: page[0].description,
        id: page[0].id,
        images: page[0].images,
        name: page[0].name,
        metadata: page[0].metadata,
        price : page[0].skus.data[0].price / 100,
        qty: book?.babypages_ids.length - 30,
        selected: book?.babypages_ids.length > 30,
        sku_id: page[0].skus.data[0].id,
        sku_type: "sku",
        sku_inventory: page[0].skus.data[0].inventory,
        sku_image: page[0].skus.data[0].image,
        sku_attributes: page[0].skus.data[0].attributes,
        title: capitalizeTexts(page[0].name),
        package_dimensions: page[0].package_dimensions,
        sku_package_dimensions: page[0].skus.data[0].package_dimensions,
      };

    }

    setLoading(true);
    

    const options = stripeBooksProduct.map((p, index) => {

      const extraPage = pageType(p.id);

      return ({
      active: p.active,
      attributes: p.attributes,
      description: p.description,
      id: p.id,
      images: p.images,
      metadata: p.metadata,
      price: p.skus.data[0].price / 100,
      qty: stripeProductSelected.id === p.id ? 1 : 0,
      selected: (index === 0 && !book.type) || book.type === p.id,
      shippable: p.shippable,
      sku_id: p.skus.data[0].id,
      package_dimensions: p.package_dimensions,
      sku_package_dimensions: p.skus.data[0].package_dimensions,
      sku_type: p.skus.data[0].object,
      title: capitalizeTexts(p.name),
      sku_inventory: p.skus.data[0].inventory,
      sku_image: p.skus.data[0].image,
      sku_attributes: p.skus.data[0].attributes,
      extra_pages: book?.babypages_ids.length > 30 ?  extraPage : null,
      total_with_pages: book?.babypages_ids.length > 30 ? p.skus.data[0].price / 100 + (extraPage.price * extraPage.qty) : null
    })})

    setLoading(true);

    let selectedOptions = options.filter(option => {
      return option.selected
    })
    sagaMiddleware.run<any>(
      addProductStore,
      {
        book_id: book.id.toString(),
        babypage_id: null,
        event: "",
        gift: { status: false, email: "", receiver: "" },
        id: stripeProductSelected.id,
        iosHidden: false,
        item: stripeProduct.caption,
        options: options,
        prices: [],
        qty: 1,
        stripe_id: stripeProductSelected.id,
        subtitle: capitalizeTexts(stripeProductSelected.name),
        thumbnail: book.thumbnail_with_url,
        title: "BabyPage Book",
        total: book.babypages_ids.length > 30 && selectedOptions[0].total_with_pages ?  (selectedOptions[0].total_with_pages) : stripeProductSelected.skus.data[0].price / 100,
        type: stripeProduct.caption,
        extra_pages: book.over_page_limit,
        extra_pages_qty:  book.extra_pages,
        book_type: book.type,
        totalWithoutPages: book.babypages_ids.length > 30 && selectedOptions[0].total_with_pages ?  stripeProductSelected.skus.data[0].price / 100 : null
      },
      (error?: string, description?: string) => {
        setLoading(false);
        if (error) {
          Modal.error({
            title: "Error",
            content: error,
          });
        } else {
          const key = Date.now().toString();

          notification.success({
            message: "Success",
            description,
            key,
            btn: (
              <Button
                type="link"
                onClick={() => {
                  history.push("/cart");
                  notification.close(key);
                }}
              >
                Go to Cart
              </Button>
            ),
          });
        }
      }
    );
  }, [book, stripeProducts, setLoading, history]);

  const inspectBabypages = async (addingToCart: boolean = false) => {
    if(pagesWithError.length > 0 || checkedBook) {
      if(addingToCart) {
        addToCart();
      } 
      return ;
    }
      setBookLoading(true)
      sagaMiddleware.run<any>(
        inspectAllBabypagesImages,
        parseInt(book.id.toString()),
        (error: any | null, res?: any) => {
          setPagesWithError(res);
          
          try {
            if(res.length > 0) {
              setBpsCorruptedModal(true)  
              setControl((state) => ({ ...state, popover: false }));
            } else {
              if(addingToCart) {
                addToCart();
              } else {
                setControl((state) => ({ ...state, popover: true }));
              }
            }
            setCheckedBook(true);
            setBookLoading(false);
          } catch (error) {
            Modal.error({
              title: "Error",
              content: error.message ? error.message : "Error loading book",
            });
          }
        })
  }

  const popupContent = (
    <ul className="book-actions">
        <li
          className="book-action py-1"
          onClick={() => {
            setControl((state) => ({ ...state, popover: false }));
            setBookModal(true);
          }}
        >
          <Icon className="px-1" type="search" />
            Preview
        </li>
      {!isGuestUser && width > 768 ? (
        <Tooltip title="Purchased books cannot be edited. Please duplicate your book in order to make changes." trigger="hover">
          <li
            className={`${!!book.was_purchased ? "book-action-disabled" : "book-action"} py-1`}
            onClick={() => {
              if(!!book.was_purchased) {
                // setEditPurchased(true);
                // setControl((state) => ({ ...state, popover: false }));
                return ;
              }
              setControl((state) => ({ ...state, popover: false }));
              if(!isPlusUser && book.is_cover_plus !== 0)
                setShowPlus(true)
              else
                history.push(`/books/save?id=${book.id}`)
            }}
          >
             
            <Icon className="px-1" type="edit" />
              Edit
               
            </li>
            </Tooltip> 
      ) : (
        user.permission !== "Read-Only" ? (
        <li
            className={`${!!book.was_purchased ? "book-action-disabled" : "book-action"} py-1`}
            onClick={() => {
              if(!!book.was_purchased) {
                setEditPurchased(true);
                setControl((state) => ({ ...state, popover: false }));
                return ;
              }
              setControl((state) => ({ ...state, popover: false }));
              if(!isPlusUser && book.is_cover_plus !== 0)
                setShowPlus(true)
              else
                history.push(`/books/save?id=${book.id}`)
            }}
          >
             
            <Icon className="px-1" type="edit" />
              Edit
            </li>
        ) : null
      ) }
      {!isGuestUser && (
        <li
          className="book-action py-1"
          onClick={() => {
            setControl((state) => ({ ...state, popover: false }));
            showDeleteConfirm();
          }}
        >
          <Icon className="px-1" type="delete" />
          Delete
        </li>
      )}
      {!isGuestUser && (
        <li
          className="book-action py-1"
          style= {{ 
            pointerEvents: loading || bookLoading ? "none" : "all",
            opacity: loading || bookLoading ? "0.4" : "1"
          }}
          onClick={() => {
            setControl((state) => ({ ...state, popover: false }));
            setLoading(true);

            sagaMiddleware.run<any>(
              duplicateBook,
              book.id,
              (error: string | null) => {
                setLoading(false);
                if (error) {
                  Modal.error({
                    title: "Error",
                    content: error,
                  });
                } else {
                  notification.success({
                    message: "Success",
                    description: "The book was duplicated successfully!",
                  });
                }
              }
            );
          }}
        >
          <Icon className="px-1" type="copy" />
          Duplicate
        </li>
      )}
      <li className="book-action py-1" onClick={addToCart}>
        <Icon className="px-1" type="shopping-cart" />
        Add to cart
      </li>
    </ul>
  );

  return (
    <>
      <Popover
        placement="bottom"
        content={popupContent}
        trigger="click"
        visible={control.popover}
        onVisibleChange={(popover) =>{
          if(checkedBook && !pagesWithError.length){            
            setControl((state) => ({ ...state, popover }))
          } else if(!!pagesWithError.length) {
            setBpsCorruptedModal(true)     
          }
        }}
      >
        <div className="col-6 col-sm-4 col-md-4 col-lg-3 book-preview"  onClick={() => {
          inspectBabypages();
        }}>
          <img
            src={book.thumbnail_with_url}
            alt={book.title}
            className="w-100"
          />
          <div className="book-content" style={{ textAlign: "center" }}>
            <h1 className="title">{book.title}</h1>
            <p className="subtitle">{book.subtitle}</p>
            {bookLoading ?  
              <div className="mx-auto d-table my-3">
                <Spin
                  indicator={
                    <Icon type="loading" style={{ fontSize: 24 }} spin />
                  }
                />
              </div> : 
              <>
                {pagesWithError.length ? <Icon type="warning" style={{ fontSize: 24, margin: "17px auto" }}/>
              : 
              <>
                <Button
                  disabled={!(completeBook && !pagesWithError.length)}
                  type="primary"
                  style={{ margin: "5px auto" }}
                  onClick={(e) => {
                      e.stopPropagation();
                      inspectBabypages(true);
                  }}
                  className="mobile-hidden"
                >
                  Add to Cart
                </Button>
                <Button
                  disabled={!(completeBook && !pagesWithError.length)}
                  type="primary"
                  style={{ margin: "5px auto" }}
                  onClick={(e) => {
                     e.stopPropagation();
                     inspectBabypages(true);
                  }}
                  size="small"
                  className="mobile-only"
                >
                  Add to Cart
                </Button>
              </>}
                
            </> 
              }
           
          </div>
        </div>
      </Popover>
      {!!completeBook ? (<BookModal
            leftPage={completeBook.babypages[currentIndex].thumbnail}
            rightPage={completeBook.babypages[currentIndex].thumbnail_content}
            visible={bookModal}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            pagesLimit={completeBook.babypages.length - 1}
            onClose={() => setBookModal!(false)}
            useOldBabypages={false}
          />) : null
      }
      <ModalCoverPlus
        visible={showPlus}
        onClose={() => setShowPlus(false)}
        bookId={book.id}
      />

      <Modal
        className="book-corrupt-modal"        
        centered
        visible={bpsCorruptedModal}
        onCancel={() => setBpsCorruptedModal(false)}       
        width="auto"
 
      >
        <div className="book-corrupt-container">
          <div>The following babypages have missing images.  Please click on each page to edit before adding to your book.</div>
          <ul className="pages-currupt-container">
            {pagesWithError.map((item:any)=>
              <li 
                key={item.id} 
                className="pages-currupt" 
                onClick={()=>{
                  setBpsCorruptedModal(false);
                  if(!!item.left) {
                    history.push(`/babypages/save?id=${item.id}&repair=true`)
                  } else {
                    history.push(`/babypages/save?id=${item.id}&repair=true&both=true`)
                  }
                }} > 
                    
                    {item.event}: <em>{item.title}</em>
         
              </li>)}
          </ul>
        </div>     
      </Modal>

      <Modal
        className=""
        centered
        visible={editPurchased}
        onCancel={() => { setEditPurchased(false)}}
        onOk={() => setEditPurchased(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <div className="my-4 px-2 py-1">
          <p className="purchased-book"> 
            Purchased books cannot be edited. Please duplicate your book in order to make changes.
          </p>
        </div>     
      </Modal>
      
    </>

  );
};

const mapStateToProps = (state: AppState) => ({
  user: state.user,
});
export default connect(mapStateToProps)(BookPreview);

