import React, {useState} from 'react';
import {BabyPage} from '../../interfaces';
import "./BabyPageSearchBar.scss"
import SearchBarIcon from "./SearchBarIcon/SearchBarIcon"
import { searchBabypages } from "../../utils/searchBabypages";
import { trackAction } from "../../utils/marketing";
import SortIcon from '../Icons/SortIcon';

interface Props{
  babypages: BabyPage[];
  filteredBabypages: BabyPage[];
  setFilteredBabypages: React.Dispatch<React.SetStateAction<any[]>>;
  setSortModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const BabyPageSearchBar: React.FC<Props> = ({
  babypages,
  filteredBabypages,
  setFilteredBabypages, 
  setSortModalVisible
}) => {
  return (
          <div className="input-group search-bar-container">
           <div className="input-group-prepend ">
              <div className="input-group-text search-button-icon"> <SearchBarIcon /> </div>
            </div> 
            <input 
              type="text" 
              className="form-control search-input" 
              onChange={(e) => {
                searchBabypages( 
                   e.target.value,
                   babypages,
                  setFilteredBabypages
                )}
              }
              onFocus={(() => {
                trackAction("Search_Babypages", {
                  google: {
                    event: "search_babypage",
                  },
                },
                true)
              }) } 
              placeholder="Search"
            />
             <div className="input-group-append clickable" onClick={() => setSortModalVisible(true)}>
              <div className="input-group-text sort-button-icon"> <SortIcon /> </div>
            </div>  
          </div>
  )
}

export default BabyPageSearchBar;