import { getFonts } from "../store/sagas/font"
import { sagaMiddleware } from "../store"
import { newFont } from "../interfaces/font"
import React, { useEffect, useState } from "react"


export const Fonts  = (isPlus: boolean) => {

    const [fonts, setFonts] = useState<newFont[]>([])
    useEffect(() => {
        sagaMiddleware.run<any>(
            getFonts,
            isPlus,
            (error: any | null, fonts: newFont[]) => {

              try {
                const newFonts = document.createElement('style');
                fonts.map((font) => {
                  newFonts.appendChild(document.createTextNode("\
                  @font-face {\
                  font-family: " + font.name + ";\
                  src: url('" + font.source + "') format('truetype');\
                  }\
                  "));
                 document.head.appendChild(newFonts);
                })
                
                  setFonts(fonts)
                return fonts
              } catch (error) {
                return error;
              }        
              
            }
          );
            
    }, [])

    return fonts
}