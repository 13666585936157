import { Popover, Tabs, Select } from 'antd';
import arrayMove from "array-move";
import { isEqual } from "lodash";
import React, { useCallback, useMemo, useRef, useState, useEffect } from "react";
import Cropper from "react-cropper";
import { SortEnd, SortEvent } from "react-sortable-hoc";
import { Icon, Spin, Input, Button } from "antd";
import './Finish.scss';
import {
  Background,
  Border,
  Illustration,
  Layout,
  SaveBabyPagePhoto,
  SimplifiedAnswer,
  SaveBabyPage,
  Milestone,
} from "../../interfaces";
import {
  finishCrop,
  initialPhotoState,
  photoClick,
  PhotoSelected,
  selectImage,
  selectImageURL,
} from "../../pages/BabyPage/Save/SaveHelper";
import PhotoGallery from "../Gallery/PhotoGallery";
import ImageEditModal from "../ImageEditModal/ImageEditModal";
import BleedModal from "../BleedModal/BleedModal"
import Content from "./Content";
import SelectPhotoModal from "./SelectPhotoModal";
import labelBottomCenter from '../../assets/img/labelBottomCenter.png';
import labelBottomLeft from '../../assets/img/labelBottomLeft.png';
import labelBottomRight from '../../assets/img/labelBottomRight.png';
import labelTopCenter from '../../assets/img/labelTopCenter.png';
import labelTopLeft from '../../assets/img/labelTopLeft.png';
import labelTopRight from '../../assets/img/labelTopRight.png';
import { getBrowserWidth } from "../../utils/size";
import { Fonts } from "../../hooks/Fonts";
import FontSelect from "../FontSelect/FontSelect"
import { DatePicker } from 'antd';
import moment from 'moment';

const { Option } = Select;

interface Props {
  font: string;
  textAlign: "start" | "center" | "end";
  subTitle: string;
  birthDate: string;
  backgroundSelected?: Background;
  borderSelected?: Border;
  illustrationSelected?: Illustration;
  textAnswers: string;
  labelUrl?: string;
  setPhotos: (
    photos: SaveBabyPagePhoto[],
    layout: Layout,
    forceRecrop?: boolean
  ) => void;
  photos: SaveBabyPagePhoto[];
  layout: Layout;
  loading: boolean;
  isTruncated: boolean;
  setIsTruncated: (isTruncated: boolean) => void;
  answersArray: SimplifiedAnswer[];
  setSortedAnswers: (orderedAnswers: SimplifiedAnswer[]) => void;
  setCustomAnswers: (changedAnswers: SimplifiedAnswer[]) => void;
  addCustomAnswer: (answer: string) => void;
  removeAnswer: (index: number) => void;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setSubTitle: React.Dispatch<React.SetStateAction<string>>;
  ageWeeks: number;
  milestoneType?: Milestone;
  updateBPStep: React.Dispatch<React.SetStateAction<any>>;
  photosRightSide: SaveBabyPagePhoto[];
  layoutRightSide: Layout;
  babyPage: SaveBabyPage;
  setBabyPage: React.Dispatch<React.SetStateAction<SaveBabyPage>>;
  babyPageId?: any;
  setTriggerPhotoUpdate?: React.Dispatch<React.SetStateAction<boolean>>;
  step?: number;
  setMilestoneDate?: React.Dispatch<React.SetStateAction<string>>;
  milestoneDate?: string;
}

const Finish: React.FC<Props> = ({
  subTitle,
  birthDate,
  font,
  textAlign,
  backgroundSelected,
  borderSelected,
  illustrationSelected,
  textAnswers,
  labelUrl,
  setPhotos,
  photos,
  layout,
  loading,
  isTruncated,
  setIsTruncated,
  answersArray,
  setSortedAnswers,
  setCustomAnswers,
  addCustomAnswer,
  removeAnswer,
  setTitle,
  setSubTitle,
  ageWeeks,
  milestoneType,
  updateBPStep,
  photosRightSide,
  layoutRightSide,
  babyPage,
  setBabyPage,
  babyPageId,
  setTriggerPhotoUpdate,
  step,
  setMilestoneDate,
  milestoneDate
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [imgLoading, setImgLoading] = useState(false);
  const [cropperRef, setCropperRef] = useState<Cropper | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [photoSelected, setPhotoSelected] = useState<PhotoSelected>(
    initialPhotoState
  );
  
  const isPhotoOnly = milestoneType?.title === "Photo-Only Page";
  const [photoOnlyLabelLeft, setPhotoOnlyLabelLeft] = useState<Object>(babyPage?.labelStatePositionLeft ? babyPage.labelStatePositionLeft : {
    display: "flex", 
    justifyContent: "flex-start",
    alignItems:"flex-end"
  });
  const [photoOnlyLabelRight, setPhotoOnlyLabelRight] = useState<Object>(babyPage?.labelStatePositionRight ? babyPage.labelStatePositionRight :{
    display: "flex", 
    justifyContent: "flex-end",
    alignItems:"flex-end",
  });

  const [labelLeft, setLabelLeft] = useState(babyPage?.label_left_text ? babyPage.label_left_text : "");
  const [labelRight, setLabelRight] = useState(babyPage?.label_right_text ? babyPage.label_right_text : "");
  const [fontGeneral, setFontGeneral] = useState(babyPage.font);
  const [disabledLeft, setDisabledLeft] = useState(babyPage.label_left_text === null ? true : false);
  const [disabledRight, setDisabledRight] = useState(babyPage.label_right_text === null ? true : false);
  const [maxLabelLength, setMaxLabelLength] = useState(0);
  const [labelRightLength, setLabelRightLength] = useState(0);
  const [labelLeftLength, setLabelLeftLength] = useState(0);
  const [side, setSide] = useState("left");
  const [bleedModalVisible, setBleedModalVisible] = useState(true);

  const onSortEnd = useCallback(
    (sort: SortEnd, _: SortEvent) => {
      if (sort.oldIndex !== sort.newIndex) {
        const newPhotos = arrayMove([...photos], sort.oldIndex, sort.newIndex);
        const side = photos[0].side;
        setPhotos(newPhotos, side === "left" ? layout : layoutRightSide, true);
      }
    },
    [photos, setPhotos, layout, layoutRightSide]
  );

  const fonts = Fonts (isPhotoOnly)

  const onSelectImage = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      selectImage(
        e,
        photoSelected,
        photoSelected.side === "left" ? photos : photosRightSide,
        setPhotos,
        photoSelected.side === "left" ? layout : layoutRightSide,
        setPhotoSelected,
        inputRef,
        setImgLoading
      ),
    [layout, photoSelected, photos, setPhotos, inputRef, photosRightSide, layoutRightSide]
  );


  const onSelectImageURL = useCallback(
    (imageUrl: string) =>{
      selectImageURL(
        imageUrl,
        photoSelected,
        side === "left" ? photos : photosRightSide,
        setPhotos,
        photoSelected.side === "left" ? layout : layoutRightSide,
        setPhotoSelected,
        inputRef,
        setImgLoading,
        side
      )},
    [layout, photoSelected, photos,  inputRef, layoutRightSide, photosRightSide, side]
  );

  const onClickPhoto = useCallback(
    (index: number, photo: SaveBabyPagePhoto) => {
      photoClick(
        index,
        photo,
        inputRef,
        photo.side === "left" ? layout : layoutRightSide,
        setPhotoSelected,
        setModalOpen,
        step,
        side
)},
    [layout, inputRef, layoutRightSide]
  );


  const onCropComplete = useCallback(
    (croppedData: Cropper.Data, imageData: Cropper.ImageData) => {
      if (
        !!croppedData &&
        (!photoSelected.croppedData ||
          !isEqual(croppedData, photoSelected.croppedData))
      ) {
        setPhotoSelected((state) => ({
          ...state,
          croppedData,
          imageData,
        }));
      }
    },
    [photoSelected]
  );

  const onFinishCrop = useCallback(
    async (_: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (cropperRef !== null) {
        finishCrop(
          cropperRef,
          photoSelected,
          photoSelected.side === "left" ? photos : photosRightSide,
          setPhotos,
          photoSelected.side === "left" ? layout : layoutRightSide,
          setImgLoading,
          setPhotoSelected
        );
      }
    },
    [cropperRef, photoSelected, photos, setPhotos, layout, photosRightSide, layoutRightSide]
  );

  // Set Font & Set Text Alignment
  // Set Font Information
  const setFont = useCallback((font: string, font_id: number) => {
    setBabyPage((state) => ({
      ...state,
      font,
      font_id,
    }));
    setFontGeneral(font);
  }, []);

  const browserWidth = getBrowserWidth();
  const labelButtons = [
    {
      label: "Top Left",
      labelToSave: "top-left",
      icon: <img src={labelTopLeft} style={{ width: 30, height: 30}}/>,
      width: "45%",
      position: { display: "flex", justifyContent: "flex-start", alignItems: "flex-start" },
    },
    {
      label: "Top Center",
      labelToSave: "top-center",
      icon: <img src={labelTopCenter} style={{ width: 30, height: 30}}/>,
      width: "45%",
      position: { display: "flex", justifyContent: "center", alignItems: "flex-start" },
    },
    {
      label: "Top Right",
      labelToSave: "top-right",
      icon: <img src={labelTopRight} style={{ width: 30, height: 30}}/>,
      width: "45%",
      position: { display: "flex", justifyContent: "flex-end", alignItems: "flex-start" },
    },
    {
      label: "Bottom Left",
      labelToSave: "bottom-left",
      icon: <img src={labelBottomLeft} style={{ width: 30, height: 30}}/>,
      width: "45%",
      position: { display: "flex", justifyContent: "flex-start", alignItems: "flex-end" },
    },
    {
      label: "Bottom Center",
      labelToSave: "bottom-center",
      icon: <img src={labelBottomCenter} style={{ width: 30, height: 30}}/>,
      width: "45%",
      position: { display: "flex", justifyContent: "center", alignItems: "flex-end" },
    },
    {
      label: "Bottom Right",
      labelToSave: "bottom-right",
      icon: <img src={labelBottomRight} style={{ width: 30, height: 30}}/>,
      width: "45%",
      position: { display: "flex", justifyContent: "flex-end", alignItems: "flex-end" },
    },
    { label: "No Label", position: { display: "none" }, labelToSave:"no-label" },
  ];
  const photoProps = {
    photos,
    layout,
    onSortEnd,
    onClickPhoto,
    hidden: loading,
    backgroundSelected,
    isPhotoOnly,
    labelLeft,
    photoOnlyLabelLeft,
    fontGeneral,
    browserWidth,
    setLabelLeftLength,
    setMaxLabelLength,
    labelLeftPosition: babyPage.label_left_position,
    side: "left",
    setSide
  };

  const photoRightProps = {
    photos: photosRightSide || [],
    layout: layoutRightSide || [],
    onSortEnd,
    onClickPhoto,
    hidden: loading,
    backgroundSelected,
    isPhotoOnly,
    labelRight,
    photoOnlyLabelRight,
    fontGeneral,
    browserWidth,
    setLabelRightLength,
    labelRightPosition: babyPage.label_right_position,
    side: "right",
    setSide
  };

  const contentProps = {
    birthDate,
    font,
    textAlign,
    backgroundSelected,
    borderSelected,
    illustrationSelected,
    textAnswers,
    labelUrl,
    hidden: loading,
    isTruncated,
    setIsTruncated,
    subTitle,
    answersArray,
    setSortedAnswers,
    setCustomAnswers,
    addCustomAnswer,
    removeAnswer,
    setTitle,
    setSubTitle,
    ageWeeks,
    ignoreMargin: true,
    finish: true,
    milestoneType,
    babyPageId,
    setMilestoneDate,
    milestoneDate
  };
  const marginBottomClass = isPhotoOnly ? "" : "default-bp-margin-bottom"
  const difference = useMemo(() => {
    return  browserWidth >= 1024 ? 35 : 
            browserWidth >= 768 ? 28 :
            browserWidth >= 540 ? 25 :
            browserWidth >= 414 ? 23 :
            20

  }, [browserWidth])

  const leftLabelButtons = labelButtons.filter(item => {
    if (item.labelToSave === 'top-right' || item.labelToSave === 'bottom-right') {
      return false
    }
    return true
  });

  const rightLabelButtons = labelButtons.filter(item => {
    if(item.labelToSave === 'top-left' || item.labelToSave === 'bottom-left'){
      return false
    }
    return true
  });

  return loading && !isPhotoOnly? (
    <>
      <div
        style={{
          margin: "auto",
          backgroundColor: "#ffffff",
          width: "auto",
          height: "auto",
        }}
      >
        <PhotoGallery {...photoProps} />
      </div>
      <br />
      <Content {...contentProps} />
    </>
  ) : (
    <>
    <div className={`d-flex flex-column justify-content-center align-items-center mt-2`}>
        <h1 className="title title-tips my-lg-3 my-md-2 my-1">
          To reposition your photo(s) click on a photo.
        </h1>
    <div
        className="d-flex justify-content-center align-items-center" 
      >
        <div className="container">
          <div className="row">
            {imgLoading && (
              <div className="mx-auto d-table my-5">
                <Spin
                  indicator={
                    <Icon type="loading" style={{ fontSize: 24 }} spin />
                  }
                />
              </div>
            )}
          </div>

          <div
            className={`d-flex justify-content-center align-items-center h-100 mt-0 mt-md-0 row ${marginBottomClass}`}
          >
            <div
              className={`bp-gallery finish ${
                isPhotoOnly && "photo-only-container"
              }`}
            >
              <PhotoGallery {...photoProps} />
            </div>

            {isPhotoOnly ? (
              <div className="bp-gallery finish">
                <PhotoGallery {...photoRightProps} htmlElementId="content-bp" />
              </div>
            ) : (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  updateBPStep({ valid: true, step: 2 });
                }}
              >
                <Content {...contentProps} />
              </div>
            )}
          </div>

          {isPhotoOnly && (

          <Tabs defaultActiveKey="0">
            <Tabs.TabPane tab="Left Label" key="0">
              <div className="photo-only d-flex flex-column">
                <div className="">
                  <div
                    style={{
                      width: "60%",
                      marginLeft: "20%",
                    }}
                  >
                    <div className="input">
                      <label >Date</label>
                      <DatePicker
                        value={moment(subTitle,"MMMM Do, YYYY" )}
                        format={"MMMM Do, YYYY"}
                        placeholder="Date"
                        className="w-100 my-1"
                        onChange={(value) => {
                          if(value && setMilestoneDate) {
                            setSubTitle(value?.format("MMMM Do, YYYY"))
                            setMilestoneDate(value?.format("YYYY-MM-DD 00:00:00"))
                          }
                        }}
                      />
                   
                      <div className='mt-2'>
                      <label htmlFor="input-photo-only">Label Title</label>
                      <Input
                        id="input-photo-only"
                        className="mt-1"
                        placeholder=""
                        disabled={disabledLeft}
                        value={labelLeft}
                        onChange={(e) => {
                          if(maxLabelLength - difference <= labelLeftLength && maxLabelLength !== 0) {
                             
                          } else {
                          setLabelLeft(e.target.value);                         
                          setBabyPage({
                            ...babyPage,
                            label_left_text: e.target.value
                          });
                        }
                        }}
                        onKeyDown={(e) => {
                          if(e.keyCode === 8 && maxLabelLength - difference <= labelLeftLength && maxLabelLength !== 0 ) {
                            setLabelLeft(labelLeft.slice(0, labelLeft.length - 1))
                          }
                        }}
                        maxLength={20}
                      />
                      </div>
                    </div>
                    <div className="d-flex  justify-content-between">
                      <div className="mt-2">Label Position</div>
                      <div>{labelLeft.length}/20</div>
                    </div>
                    <div className="label-position">
                      <div className="text-center">
                        {leftLabelButtons.map((button, index) => {
                          const { label, icon, width, position, labelToSave } = button;
                          return (
                            <>
                            { browserWidth >= 1024 ? (
                              <Popover placement="top"
                                style={{
                                  textAlign:  'center'
                                }}
                                content={(
                                  <div>
                                    <p>{label}</p>
                                  </div>
                                )} trigger="hover">
                                <Button
                                  size="small"
                                  shape="round"
                                  className="p-3 my-2 mx-1 mobile-button"
                                  onClick={() => {
                                    setPhotoOnlyLabelLeft({ ...position });
                                    setBabyPage({
                                      ...babyPage,
                                      label_left_position: labelToSave,
                                      labelStatePositionLeft: position,
                                      label_left_text: babyPage.label_left_text === null ? "" : babyPage.label_left_text
                                    })
                                    setDisabledLeft(false);
                                      if(button.labelToSave === "no-label") {
                                        setBabyPage({
                                          ...babyPage,
                                          label_left_text: null,
                                          label_left_position: labelToSave,
                                          labelStatePositionLeft: undefined,
                                        })
                                        setLabelLeft("");
                                        setDisabledLeft(true);
                                      }
                                  }}
                                  style={{ 
                                  width: width ? width : "90%" ,
                                  height: label === 'No Label' ? 20 : 60,
                                  backgroundColor: ((labelToSave === babyPage.label_left_position && babyPage.label_left_text !== null) || 
                                                    (labelToSave === "no-label" && babyPage.label_left_text === null)) 
                                                    ? "#f8aba0" : "#ffffff" 
                                  }}
                                  key={index}>
                                  {label === 'No Label' ? label : icon}
                                </Button>
                              </Popover>
                              ) : (
                                <Button
                                size="small"
                                shape="round"
                                className="p-3 my-2 mx-1 mobile-button"
                                onClick={() => {
                                  setPhotoOnlyLabelLeft({ ...position });
                                  setBabyPage({
                                    ...babyPage,
                                    label_left_position: labelToSave,
                                    labelStatePositionLeft: position,
                                    label_left_text: babyPage.label_left_text === null ? "" : babyPage.label_left_text
                                  })
                                  setDisabledLeft(false);
                                  if(button.labelToSave === "no-label") {
                                    setBabyPage({
                                      ...babyPage,
                                      label_left_text: null,
                                      label_left_position: labelToSave,
                                      labelStatePositionLeft: undefined,
                                    })
                                    setLabelLeft("");
                                    setDisabledLeft(true);
                                  }
                                }}
                                style={{ 
                                  width: width ? width : "90%" ,
                                  height: label === 'No Label' ? 20 : 60,
                                  backgroundColor: ((labelToSave === babyPage.label_left_position && babyPage.label_left_text !== null) || 
                                                  (labelToSave === "no-label" && babyPage.label_left_text === null)) 
                                                  ? "#f8aba0" : "#ffffff" }}
                                key={index}
                              >
                                {label === 'No Label' ? label : icon}
                              </Button>
                              )
                            }
                              </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Right Label" key="1">
              <div className="photo-only d-flex flex-column">
                <div className="">
                  <div
                    style={{
                      width: "60%",
                      marginLeft: "20%",
                    }}
                  >
                    <div className="input">
                      <label htmlFor="input-photo-only">Label Title</label>
                      <Input
                        id="input-photo-only"
                        placeholder=""
                        value={labelRight}
                        disabled={disabledRight}
                          onChange={(e) => {
                          if(maxLabelLength - difference <= labelRightLength && maxLabelLength !== 0) {
                             
                          } else {
                          setLabelRight(e.target.value);                         
                          setBabyPage({
                            ...babyPage,
                            label_right_text: e.target.value
                          });
                        }
                        }}
                        onKeyDown={(e) => {
                          if(e.keyCode === 8 && maxLabelLength - difference <= labelRightLength && maxLabelLength !== 0 ) {
                            setLabelRight(labelRight.slice(0, labelRight.length - 1))
                          }
                        }}
                        maxLength={20}
                      />
                    </div>
                    <div className="d-flex  justify-content-between">
                      <div className="mt-2">Label Position</div>
                      <div>{labelRight.length}/20</div>
                    </div>
                    <div className="label-position">
                      <div className="text-center">
                        {rightLabelButtons.map((button, index) => {
                          const { label, icon, width, position, labelToSave } = button;
                          return (
                            <>
                            { browserWidth >= 1024 ? (
                              <Popover placement="top"
                              style={{
                                textAlign:  'center'
                              }}
                              content={(
                                  <div>
                                    <p>{label}</p>
                                  </div>
                                )} trigger="hover">
                                  <Button
                                    size="small"
                                    shape="round"
                                    className="p-3 my-2 mx-1"
                                    onClick={() => {
                                      setPhotoOnlyLabelRight({ ...position });
                                      setBabyPage({
                                        ...babyPage,
                                        label_right_position: labelToSave,
                                        labelStatePositionRight: position,
                                        label_right_text: babyPage.label_right_text === null ? "" : babyPage.label_right_text
                                      })
                                      setDisabledRight(false);
                                      if(button.labelToSave === "no-label") {
                                        setBabyPage({
                                          ...babyPage,
                                          label_right_text: null,
                                          label_right_position: labelToSave,
                                          labelStatePositionRight: undefined,                                        
                                        })
                                        setLabelRight("");
                                        setDisabledRight(true);
                                      }
                                    }}
                                    style={{ width: width ? width : "90%" ,
                                     height: label === 'No Label'? 20 : 60,
                                     backgroundColor: ((labelToSave === babyPage.label_right_position && babyPage.label_right_text !== null) || 
                                                      (labelToSave === "no-label" && babyPage.label_right_text === null)) 
                                                      ? "#f8aba0" : "#ffffff" }} 
                                    key={index}
                                  >
                                    {label === 'No Label' ? label : icon}
                                  </Button>
                              </Popover>
                            ) : (
                              <Button
                                    size="small"
                                    shape="round"
                                    className="p-3 my-2 mx-1"
                                    onClick={() => {
                                      setPhotoOnlyLabelRight({ ...position });
                                      setBabyPage({
                                        ...babyPage,
                                        label_right_position: labelToSave,
                                        labelStatePositionRight: position,
                                        label_right_text: babyPage.label_right_text === null ? "" : babyPage.label_right_text
                                      })
                                      setDisabledRight(false);
                                      if(button.labelToSave === "no-label") {
                                        setBabyPage({
                                          ...babyPage,
                                          label_right_text: null,
                                          label_right_position: labelToSave,
                                          labelStatePositionRight: undefined,                                        
                                        })
                                        setLabelRight("");
                                        setDisabledRight(true);
                                      }
                                    }}
                                    style={{ width: width ? width : "90%" ,
                                     height: label === 'No Label'? 20 : 60,
                                     backgroundColor: ((labelToSave === babyPage.label_right_position && babyPage.label_right_text !== null) || 
                                                      (labelToSave === "no-label" && babyPage.label_right_text === null)) 
                                                      ? "#f8aba0" : "#ffffff" }} 
                                    key={index}
                                  >
                                    {label === 'No Label' ? label : icon}
                                  </Button>
                            )}
                            
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tabs.TabPane>
          </Tabs>
          )}
          { isPhotoOnly ? (
            <div className="input mt-2 label-font-margin"  
            style={{
              width: "60%",
              marginLeft: "20%",
            }}>
              <label htmlFor="input-photo-only">Label Font</label>
              <FontSelect font={font} setFont={setFont} fonts={fonts} isPlus={isPhotoOnly} />     
          </div>
          ) : null }
        </div>
        </div>
      </div>

      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        onChange={onSelectImage}
        style={{
          width: 0,
          height: 0,
          display: "none",
        }}
      />

      <ImageEditModal
        loading={imgLoading}
        maskClosable={false}
        cropperRef={cropperRef}
        setCropperRef={setCropperRef}
        visible={photoSelected.edit}
        image={photoSelected.index !== null ? photoSelected.base64 : ""}
        croppedData={photoSelected.croppedData}
        aspect={photoSelected.aspect}
        onCropComplete={onCropComplete}
        onOk={onFinishCrop}
        onCancel={() => setPhotoSelected(initialPhotoState)}
        onChangePhoto={() => {
          setPhotoSelected({...photoSelected, edit: false})
          setModalOpen(true)
        }}
      />
      <SelectPhotoModal
        visible={modalOpen}
        onCancel={() => setModalOpen(false)}
        inputRef={inputRef}
        onSelectPhoto={onSelectImageURL}
        setTriggerPhotoUpdate={setTriggerPhotoUpdate}
        step={step}
      />
      <BleedModal visible={bleedModalVisible} onClose={() => {
        setBleedModalVisible(false);
      }}>

      </BleedModal>
    </>
  );
};

export default Finish;
