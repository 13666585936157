import { Modal, notification, Spin } from "antd";
import { Dictionary, groupBy } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Milestone } from "../../interfaces";
import { Frame } from "../../interfaces/frame";
import { MessageNotification } from "../../interfaces/messageNotification";
import { sagaMiddleware } from "../../store";
import { getFrames } from "../../store/sagas/frame";
import { getMessageNotificationsForAdmin } from "../../store/sagas/messageNotification";
import {
  deleteMilestone,
  getMilestoneByIdForAdmin,
  saveMilestone
} from "../../store/sagas/milestone";
import AdminMilestoneForm from "./AdminMilestoneForm";

interface Props {
  milestoneId: number;
}

const AdminMilestoneFormContainer: React.FC<Props> = ({ milestoneId }) => {
  const [loading, setLoading] = useState(false);
  const [milestone, setMilestone] = useState<Milestone>(Object);
  const [messageNotifications, setMessageNotifications] = useState<
    MessageNotification[]
  >([]);
  const [groupedFrames, setGroupedFrames] = useState<Dictionary<Frame[]>>({});
  const [frames, setFrames] = useState<Frame[]>([]);
  const [milestoneForm, setMilestoneForm] = useState<any>({});
  const history = useHistory();

  useEffect(() => {
    if (milestoneId !== 0) {
      setLoading(true);

      sagaMiddleware.run<any>(
        getMilestoneByIdForAdmin,
        milestoneId,
        (err?: string, response?: Milestone) => {
          setLoading(false);

          if (!!err) {
            notification.error({
              message: "Error",
              description: err
            });
          } else if (!!response) {
            setMilestone(response);
            setMilestoneForm({
              ...response,
              age_years: parseInt(response.age_months) / 12
            });
          }
        }
      );
    }
  }, [milestoneId]);

  useEffect(() => {
    setLoading(true);

    sagaMiddleware.run<any>(
      getMessageNotificationsForAdmin,
      (err?: string, response?: MessageNotification[]) => {
        setLoading(false);

        if (!!err) {
          notification.error({
            message: "Error",
            description: err
          });
        } else if (!!response) {
          setMessageNotifications(response);
        }
      }
    );
  }, []);

  useEffect(() => {
    setLoading(true);

    sagaMiddleware.run<any>(getFrames, (err?: string, response?: Frame[]) => {
      setLoading(false);

      if (!!err) {
        notification.error({
          message: "Error",
          description: err
        });
      } else if (!!response) {
        const frames = groupBy(response, frame => frame.folder);
        setGroupedFrames(frames);
        setFrames(response);
      }
    });
  }, []);

  const typeOfMilestoneValue = useMemo((): any => {
    if (!!milestoneForm.typeOfMilestone) return milestoneForm.typeOfMilestone;

    let typeOfMilestoneValue: string = "";

    if (
      parseFloat(milestoneForm.age_months) > 0 &&
      parseFloat(milestoneForm.age_months) % 12 === 0
    ) {
      typeOfMilestoneValue = "years";
    } else if (
      parseFloat(milestoneForm.age_months) > 0 &&
      parseFloat(milestoneForm.age_months) % 12 !== 0
    ) {
      typeOfMilestoneValue = "months";
    } else if (
      !(parseFloat(milestoneForm.age_months) > 0) &&
      milestoneForm.age_weeks > 0
    ) {
      typeOfMilestoneValue = "weeks";
    } else {
      typeOfMilestoneValue = "holiday";
    }

    setMilestoneForm((milestoneForm: any) => ({
      ...milestoneForm,
      typeOfMilestone: typeOfMilestoneValue
    }));

    return typeOfMilestoneValue;
  }, [
    milestoneForm.age_months,
    milestoneForm.age_weeks,
    milestoneForm.typeOfMilestone
  ]);

  const currentTab = useMemo((): string => {
    const currentFrame = frames.find(
      frame => frame.id === milestone.background_id
    );

    setMilestoneForm((milestoneForm: any) => ({
      ...milestoneForm,
      activeTabKey: !!currentFrame ? currentFrame.folder : "4th"
    }));

    return !!currentFrame ? currentFrame.folder : "4th";
  }, [milestone, frames]);

  const handleImage = useCallback((event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function() {
      setMilestoneForm((milestoneForm: any) => ({
        ...milestoneForm,
        url_image: reader.result,
        image: {
          file,
          value: reader.result
        }
      }));
    };
  }, []);

  const handleSubmit = useCallback(
    (event: any) => {
      event.preventDefault();

      const {
        title,
        description,
        image,
        active_date,
        age_months,
        age_weeks,
        message_id,
        background_id,
        typeOfMilestone,
        url_image
      } = milestoneForm;

      if (!url_image && !image) {
        notification.error({
          message: "Error",
          description: "An image is required."
        });

        return;
      }

      const payload: any = {
        title: title,
        description: description,
        image: image.file != null ? image.file : undefined,
        message:
          message_id === "None" || !message_id
            ? null
            : milestoneForm.message_id,
        background_id: background_id
      };

      if (payload.background_id === null) {
        delete payload.background_id;
      }
      switch (typeOfMilestone) {
        case "holiday":
          payload.active_date = moment(active_date).format("YYYY-MM-DD");
          payload.age_weeks = -999;
          payload.age_months = 0;
          break;
        case "weeks":
          payload.age_weeks = age_weeks;
          payload.age_months = 0;
          break;
        case "months":
          payload.age_months = age_months;
          payload.age_weeks = age_months * 4;
          break;
        case "years":
          payload.age_months = age_months * 12;
          payload.age_weeks = age_months * 12 * 4;
          break;
        default:
          break;
      }

      const formData: FormData = new FormData();
      for (const key in payload) {
        if (payload[key] !== undefined) {
          formData.append(key, payload[key]);
        }
      }

      setLoading(true);
      sagaMiddleware.run<any>(
        saveMilestone,
        milestoneId,
        formData,
        (err?: string, response?: Milestone) => {
          setLoading(false);

          if (!!err) {
            notification.error({
              message: "Error",
              description: err
            });
          } else {
            if (milestoneId === 0 && !!response) {
              history.push(`/admin/milestones/${response.id}`);
            } else {
              notification.success({
                message: "Success",
                description: "Milestone saved successfully"
              });
            }
          }
        }
      );
    },
    [milestoneForm, milestoneId, history]
  );

  const handleClickOnDeleteButton = useCallback(
    (id: number) => {
      Modal.confirm({
        title: "Are you sure you want to delete this Milestone?",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        width: 300,
        icon: "warning",
        onOk() {
          setLoading(true);

          sagaMiddleware.run<any>(deleteMilestone, id, (err?: string) => {
            setLoading(false);

            if (!!err) {
              notification.error({
                message: "Error",
                description: err
              });
            } else {
              history.push("/admin/milestones");
            }
          });
        }
      });
    },
    [history]
  );

  return (
    <>
      {loading ? (
        <div className="col-12 d-flex justify-content-center">
          <Spin style={{ fontSize: 36, margin: 50 }} />
        </div>
      ) : (
        <AdminMilestoneForm
          milestoneId={milestoneId}
          handleSubmit={handleSubmit}
          milestoneForm={milestoneForm}
          handleImage={handleImage}
          setMilestoneForm={setMilestoneForm}
          typeOfMilestoneValue={typeOfMilestoneValue}
          messageNotifications={messageNotifications}
          groupedFrames={groupedFrames}
          currentTab={currentTab}
          handleClickOnDeleteButton={handleClickOnDeleteButton}
        />
      )}
    </>
  );
};

export default AdminMilestoneFormContainer;
