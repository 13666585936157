// Interface for base action
export interface BaseAction {
  type: string;
  payload?: any;
}

// Actions
export const CUSTOMER_CHANGE = "customer/change";
export const USER_CHANGE = "user/change";
export const USER_LOGOUT = "user/logout";
export const ADMIN_CHANGE = "admin/change";
export const ADMIN_LOGOUT = "admin/logout";
export const SUM_CHILD_COUNT = "user/sumChildCount";
export const GET_BABIES = "babies/get";
export const SELECT_BABY = "babies/select";
export const SELECT_LAST_BABY = "babies/selectLast";
export const GET_PRODUCTS = "products/get";
export const GET_STRIPE_PRODUCTS = "stripe/getProducts";
export const GET_STRIPE_PLANS = "stripe/getPlans";
export const GET_DASHBOARD = "dashboard/fetch";
export const GET_MILESTONES = "milestones/getMilestones";
export const GET_BORDERS = "bpHelper/getBorders";
export const GET_ILLUSTRATIONS = "bpHelper/getIllustrations";
export const GET_DRAFT_BABYPAGE = "bpHelper/saveDraftBabyPage";
export const GET_BOOKS = "books/getBooks";
export const GET_COVERS = "books/getCovers";
export const SET_BOOK_TYPE = "books/setBookType";
export const GET_GROWTH_CHART = "growthChart/get";
export const GET_PHOTOS = "photos/get";
export const SELECT_GUEST_USER = "guest-users/select";
export const SAVE_DEEP_LINK_INFO = "guest-users/saveDeepLinkInfo";
export const CLEAR_DEEP_LINK_INFO = "guest-users/clearDeepLinkInfo";
export const CHANGE_MAINTENANCE = "maintenance/change"

/**
 * With duck pattern, we will have function who pass this actions
 * as argument, so we will use this actions JUST in ducks!
 */
