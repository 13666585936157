import { all } from 'redux-saga/effects';
import { fetchBabies } from './baby';
import { fetchBooks } from './books';
import { fetchBorders, fetchIllustrations } from './bpHelper';
import { getCustomerInfo } from './customer';
import { fetchGrowthCharts } from './growthChart';
import { fetchPhotos } from './image';
import { fetchMilestones } from './milestone';
import { fetchProducts } from './product';
import { fetchStripeProducts } from './stripe';
import { getAuthenticatedUser } from './user';
import { getMaintenance } from './maintenance';

/**
 * This root saga will run everytime user open the web app
 */
export default function* rootSaga(ignoreAuthenticatedUser = false) {
  yield all(
    ignoreAuthenticatedUser
      ? [
          getCustomerInfo(),
          fetchBabies(),
          fetchProducts(),
          fetchStripeProducts(),
          fetchBorders(),
          fetchIllustrations(),
          fetchMilestones(),
          fetchBooks(),
          fetchGrowthCharts(),
          fetchPhotos(),
          getMaintenance(),
        ]
      : [
          getAuthenticatedUser(),
          getCustomerInfo(),
          fetchBabies(),
          fetchProducts(),
          fetchStripeProducts(),
          fetchBorders(),
          fetchIllustrations(),
          fetchMilestones(),
          fetchBooks(),
          fetchGrowthCharts(),
          fetchPhotos(),
          getMaintenance(),
        ]
  );
}

export function* signupSaga() {
  yield all( 
    [
      fetchProducts(),
      fetchStripeProducts(),
      fetchBorders(),
      fetchIllustrations(),
  ]
  );
}
