import { Button, DatePicker, InputNumber, Modal } from 'antd';
import moment, { Moment } from 'moment';
import React from 'react';
import { Entry } from '../../interfaces/entry';

interface Props {
  babyname: string;
  entry: Entry | null;
  isEntryValid: boolean;
  opened: boolean;
  onSave: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onDelete: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onClose: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onChangeDate: (date: Moment | null, dateString: string) => void;
  onChangeEntry: (
    value: number | undefined,
    field: 'height' | 'weight_lbs' | 'weight_oz'
  ) => void;
}

const EntryModal: React.FC<Props> = ({
  babyname,
  entry,
  isEntryValid,
  opened,
  onSave,
  onDelete,
  onClose,
  onChangeDate,
  onChangeEntry,
}) =>
  !!entry ? (
    <Modal
      visible={opened}
      onOk={onSave}
      onCancel={onClose}
      okButtonProps={{
        disabled: !isEntryValid,
      }}
    >
      <h2 className='text-center caslon'>Growth Chart</h2>
      <h3 className='text-center'>
        Add a height and weight entry for {babyname}
      </h3>
      <label>Date</label>
      <DatePicker
        onChange={onChangeDate}
        defaultValue={moment(entry?.entry_date, 'MM-DD-YYYY')}
        disabledDate={(current: Moment | null) =>
          current !== null && current > moment().endOf('day')
        }
        placeholder='Date'
        className='w-100 my-1'
        popupStyle={{position:'fixed'}}
      />
      <label>Height (inches)</label>
      <InputNumber
        value={entry.height}
        placeholder='Height (inches)'
        step={0.1}
        min={0}
        max={99.9}
        onChange={e => onChangeEntry(e, 'height')}
        className='w-100 my-1'
      />
      <div className='row'>
        <div className='col-6'>
          <label>Weight (lbs)</label>
          <InputNumber
            value={entry.weight_lbs}
            placeholder='Weight (lbs)'
            step={1}
            min={0}
            max={99.9}
            onChange={e => onChangeEntry(e, 'weight_lbs')}
            className='w-100 my-1'
          />
        </div>
        <div className='col-6'>
          <label>Weight (oz)</label>
          <InputNumber
            value={entry.weight_oz}
            placeholder='Weight (oz)'
            step={1}
            min={0}
            max={15}
            onChange={e => onChangeEntry(e, 'weight_oz')}
            className='w-100 my-1'
          />
        </div>
      </div>
      {!!entry.isEdit && (
        <div className='mx-auto d-table my-2'>
          <Button type='danger' onClick={onDelete} size="small">
            Delete Entry
          </Button>
        </div>
      )}
    </Modal>
  ) : null;

export default EntryModal;
