import { Book, Cover } from "../../interfaces";
import {
  BaseAction,
  GET_BOOKS,
  GET_COVERS,
  USER_LOGOUT,
  SET_BOOK_TYPE,
} from "../actionTypes";

export type BookState = {
  books: Book[];
  loading: boolean;
  covers: Cover[];
  bookType: string;
};

const initialState: BookState = {
  books: [],
  loading: true,
  covers: [],
  bookType: "",
};

export const books = (
  state: BookState = initialState,
  action: BaseAction
): BookState => {
  switch (action.type) {
    case GET_BOOKS:
      return { ...state, books: [...action.payload], loading: false };
    case GET_COVERS:
      return { ...state, covers: [...action.payload] };
    case USER_LOGOUT:
      return initialState;
    case SET_BOOK_TYPE:
      return { ...state, bookType: action.payload };
    default:
      return state;
  }
};
Object.freeze(books);

const getBooks = (payload: Book[]) => ({
  payload,
  type: GET_BOOKS,
});
Object.freeze(getBooks);

const getCovers = (payload: Cover[]) => ({
  payload,
  type: GET_COVERS,
});

Object.freeze(getCovers);

const setBookType = (payload: string) => ({
  payload,
  type: SET_BOOK_TYPE,
});

Object.freeze(setBookType);

export { getBooks, getCovers, setBookType };
export default books;
