import { Button, Icon, Modal, notification, Tooltip  } from 'antd';
import html2canvas from 'html2canvas';
import moment from 'moment';
import queryString from 'query-string';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import BaseLayout from '../../../components/BaseLayout/BaseLayout';
import CustomDivider from '../../../components/CustomDivider';
import ModalPlus from '../../../components/ModalPlus/ModalPlus';
import RouteLeavingGuard from '../../../components/RouteLeavingGuard/RouteLeavingGuard';
import BookFinalPreview from '../../../components/SaveBook/BookFinalPreview';
import CoverList from '../../../components/SaveBook/CoverList';
import EditText from '../../../components/SaveBook/EditText';
import OrderBabypages from '../../../components/SaveBook/OrderBabypages';
import SelectBabypages from '../../../components/SaveBook/SelectBabypages';
import SaveBookTooltipIcon from '../../../components/SaveBook/SaveBookTooltipIcon/SaveBookTooltipIcon';
import { BASE_URL, highResContent } from '../../../env';
import { Baby, BabyPage, Cover, User } from '../../../interfaces';
import { AppState, sagaMiddleware } from '../../../store';
import {
  fetchBookById,
  fetchCovers,
  saveBookSaga
} from '../../../store/sagas/books';
import { checkUserModal } from '../../../store/sagas/form';
import {
  FileUploadedS3,
  FileUploadS3,
  savePhotosS3
} from '../../../store/sagas/image';
import {
  canvasToBlobPromise,
  getBase64FromFileUrl
} from '../../../utils/image';
import { trackAction } from '../../../utils/marketing';
import { capitalizeTexts, lastSegmentUrl } from '../../../utils/string';
import { delay } from '../../../utils/time';
import { getBrowserWidth } from "../../../utils/size";
import './Save.scss';
import { Fonts } from "../../../hooks/Fonts";
import ArrowNext from '../../../components/Icons/ArrowNext';
const { confirm } = Modal;
interface Props {
  covers: Cover[];
  user: User;
  babies: Baby[];
  babySelected?: Baby;
  bookType?: string;
}

const SaveBooksPage: React.FC<Props> = ({
  covers,
  user,
  babies,
  babySelected,
  bookType,
}) => {
  const history = useHistory();
  const location = useLocation();

  const contentRef = useRef<HTMLDivElement>(null);

  let id: any = queryString.parse(location.search).id;

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [somethingChange, setSomethingChange] = useState(false);
  const [saveStep, setSaveStep] = useState<
    null | "genImg" | "sendImg" | "saveBook"
  >(null);
  const [plusOpen, setPlusOpen] = useState(false);
  const [selectedCover, setSelectedCover] = useState<Cover | undefined>(
    undefined
  );
  const [selectedBabypages, setSelectedBabypages] = useState<BabyPage[]>([]);
  const fonts = Fonts (!!user.user_level)

  const [bookText, setBookText] = useState({
    title: `${
      babySelected ? babySelected.baby_first_name : ""
    } ${babySelected ? babySelected.baby_last_name : ""}`,
    subtitle: babySelected
      ? moment(babySelected.baby_birthdate).format("LL")
      : "",
    font: "a",
    font_id:0,
  });
  useEffect(() => {
    if(!!fonts.length) {
      setBookText((old) => {
        return {
          ...old,
          font: fonts[0].name,
          font_id:fonts[0].id
        }
        })
    }
   
  }, [fonts])
  const [useOldBabypages, setUseOldBabypages] = useState(false);
  const [control, setControl] = useState({
    valid: false,
    step: 0,
  });

  const buttonBarClasses = control.step === 1 ? "" : "row justify-content-center align-items-center"

  const sendToUpgradeToPlus = () => {  
    history.push("/cart/subscription/yearly");
  }

  const browserWidth = getBrowserWidth();

  const triggerTooltip = browserWidth >= 1024 ? "hover" : "click"

  // On start
  useEffect(() => {
    if (loaded) return;

    if (!!id) {
      setLoading(true);

      setControl((state) => ({
        ...state,
        valid: true,
      }));

      sagaMiddleware.run<any>(
        fetchBookById,
        parseInt(id.toString()),
        (error: any | null, bookBD?: any) => {
          if (error !== null || !bookBD) {
            history.push("/");
            setLoading(false);
            return;
          }

          try {
            if(!!bookBD.was_purchased) {
              history.push("/books")
            }
            const valid = user.user_level !==0 || covers.find((cover) => cover.image === bookBD.cover)?.plus === 0;
            
            if(valid)
              setSelectedCover(covers.find((cover) => cover.image === bookBD.cover));

            setUseOldBabypages(!bookBD.babypages[0].new_version);

            setSelectedBabypages(
              bookBD.babypages_ids.map((bpId: number) =>
                bookBD.babypages.find((bp: any) => bp.id === bpId)
              )
            );
            const extraRegular = bookBD.title_styles.fontFamily.includes("-Regular-Regular")
            const indexFirstRegular = bookBD.title_styles.fontFamily.indexOf("-");
            const fontFamilyWithoutExtraRegular = extraRegular && !!indexFirstRegular ? bookBD.title_styles.fontFamily.slice(0, indexFirstRegular + 8)  : bookBD.title_styles.fontFamily

            setBookText({
              title: bookBD.title,
              subtitle: bookBD.subtitle,
              font: fontFamilyWithoutExtraRegular, 
                font_id: bookBD.font_id,
            });

            setControl({
              valid: valid,
              step: 0,
            });
          } catch (error) {
            Modal.error({
              title: "Error",
              content: error.message ? error.message : "Error loading book",
            });
            history.push("/");
          }

          setLoaded(true);
          setLoading(false);
        }
      );
    }
  }, [
    location.search,
    babySelected,
    loaded,
    covers,
    babies,
    setControl,
    id,
    history,
    user.user_level,
  ]);

  useEffect(() => {
    const counts = babies.reduce(
      (total, baby) => ({
        new:
          total.new +
          baby.babyPages.filter((babypage) => babypage.new_version === true)
            .length,
        old:
          total.old +
          baby.babyPages.filter((babypage) => babypage.new_version === false)
            .length,
      }),
      { new: 0, old: 0 }
    );

    counts.new === 0 ? setUseOldBabypages(true) : setUseOldBabypages(false);
  }, [babies]);

  useEffect(() => {
    setLoading(true);
    sagaMiddleware.run<any>(fetchCovers, (error?: any) => {
      setLoading(false);
      if (error) {
        Modal.error({
          title: "Error",
          content: error,
        });
      }
    });
  }, []);

  useEffect(() => {
    if (
      (!!useOldBabypages &&
        selectedBabypages.length >= 8 &&
        selectedBabypages.length <= 60) ||
      (!useOldBabypages &&
        selectedBabypages.length >= 8 && selectedBabypages.length <= 100 )
    ) {
      setControl((state) => {
        return { ...state, valid: true };
      });
    } else {
      if(control.step === 1) {
        setControl((state) => {
          return { ...state, valid: false };
        });
      }
    }
  }, [useOldBabypages, selectedBabypages, setControl, control.step]);

  const allCovers = useMemo(
    () => {
      let sortedArray: any = {}
      covers.forEach((cover) => {
        if (sortedArray[cover.order] != undefined && sortedArray[cover.order].length != 0){
        return sortedArray[cover.order].push(cover)
        }
      sortedArray[cover.order] = [cover]
      })
      return sortedArray;
     },
    [covers]

  );

  const counts = useMemo(
    () =>
      babies.reduce(
        (total, baby) => ({
          new:
            total.new +
            baby.babyPages.filter((babypage) => babypage.new_version === true)
              .length,
          old:
            total.old +
            baby.babyPages.filter((babypage) => babypage.new_version === false)
              .length,
        }),
        { new: 0, old: 0 }
      ),
    [babies]
  );

  const showChangeConfirm = useCallback((value: boolean) => {
    confirm({
      title:
        "Are you sure you want to continue? If you change the BabyPage version to use, you will lose your current selection",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      width: 300,
      icon: "warning",
      onOk() {
        setUseOldBabypages(value);
        setSelectedBabypages([]);
      },
    });
  }, []);

  const selectCover = useCallback(() => {
    const props = {
      setSelectedCover,
      selectedCover,
      setControl,
      isPlus: !!user.user_level && user.user_level === 1,
      showPlus: () => setPlusOpen(true),
      setSomethingChange,
    };

    return (
      <>
      <h1 className="book-instructions-primary text-center mx-2 my-4">
        Scroll to browse design options &amp; click to select the cover you want to use.
      </h1>
      <div
        className={`container-fluid create-book-container-width`}
        style={{
          backgroundColor: "#FFF",
          marginBottom: "50px",
          borderRadius: "15px"
        }}
        >
          <div className="create-book-scrollable-cover-container create-book-scrollable-container">
            {Object.keys(allCovers).map(key => {
              return <CoverList
                key={key}
                text={allCovers[key][0]?.category}
                covers={allCovers[key]}
                {...props}
              />
            }
              )
            }
          </div>
          <div className="d-flex flex-row justify-content-center my-4">
              <div className="col-lg-3 col-6">
              <Button type="primary" ghost shape="round" className="w-100 book-creation-buttons" onClick={() => {
                history.goBack();
              }}>
                  Previous
              </Button>     
              </div>
              <div className="col-lg-3 col-6">
                  <Button
                    type="primary"
                    ghost
                    shape="round"
                    className="w-100 green-button book-creation-buttons d-flex flex-row align-items-center justify-content-center"
                    onClick={nextStep}
                    disabled={!selectedCover}
                  >
                    Next
                    <ArrowNext />
                  </Button>                  
              </div>  
            </div>
        </div>
      </>
    );
  }, [
    selectedCover,
    allCovers,
    user.user_level,
    setSomethingChange,
  ]);

  const selectBabypages = useCallback(() => {
    const props = {
      useOldBabypages,
      babies,
      selectedBabypages,
      setSelectedBabypages,
      setControl,
      nextStep
    };

    return (
      <div className="select-babypage pt-lg-4 pt-1">
        {counts.new >= 4 && counts.old >= 8 && (
          <>
            <div className="container ">
              <div className="row justify-content-start align-items-center">
                <div className="col-6 milestone-filter">
                  <Button
                    type="primary"
                    className="w-100"
                    shape="round"
                    ghost={useOldBabypages}
                    onClick={() => {
                      useOldBabypages && selectedBabypages.length
                        ? showChangeConfirm(false)
                        : setUseOldBabypages(false);
                    }}
                  >
                    New Babypages
                  </Button>
                </div>
                <div className="col-6 milestone-filter">
                  <Button
                    type="primary"
                    className="w-100"
                    shape="round"
                    ghost={!useOldBabypages}
                    onClick={() => {
                      !useOldBabypages && selectedBabypages.length
                        ? showChangeConfirm(true)
                        : setUseOldBabypages(true);
                    }}
                  >
                    Old BabyPages
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}    
        <SelectBabypages {...props} />
      </div>
    );
  }, [babies, useOldBabypages, selectedBabypages, showChangeConfirm, counts]);

  const orderBabypages = useCallback(() => {
    const props = {
      selectedBabypages,
      setSelectedBabypages,
      setControl,
      nextStep
    };

    return (
      <div className="select-babypage">
        <OrderBabypages {...props} />
      </div>
    );
  }, [selectedBabypages, control.step]);

  const editText = useCallback(() => {
    const props = {
      bookText,
      setBookText,
      selectedCover,
      loading,
      isPlus: !!user.user_level,
      setPlusOpen,
      setControl,
      nextStep,
      fonts
    };

    return <EditText {...props} />;
  }, [bookText, setBookText, selectedCover, loading, control.step]);

  const bookFinal = useCallback(() => {
    const props = {
      loading,
      bookText,
      selectedCover,
      selectedBabypages,
      useOldBabypages,
      setControl,
      nextStep
    };

    return <BookFinalPreview {...props} />;
  }, [loading, bookText, selectedCover, selectedBabypages, useOldBabypages, control.step]);

  const renderStep = useCallback(() => {
    switch (control.step) {
      case 0:
        return selectCover();
      case 1:
        return selectBabypages();
      case 2:
        return orderBabypages();
      case 3:
        return editText();
      case 4:
        return bookFinal();
      default:
        return false; //cambiar por ultimo paso
    }
  }, [
    control.step,
    selectCover,
    selectBabypages,
    orderBabypages,
    editText,
    bookFinal,
  ]);

  const saveBook = useCallback(async () => {
    setLoading(true);
    setSaveStep("genImg");

    let Cover: HTMLCanvasElement | null = null;

    try {
      const coverWithBase64 = {
        ...selectedCover!,
        image_b64: await getBase64FromFileUrl(
          selectedCover!.thumbnail,
          `${BASE_URL}api/avoid-cors?url=`
        ),
      };

      setSelectedCover(coverWithBase64);

      await delay(300);
      const coverImage = document.getElementById("book-cover");

      if (!!coverImage) {
        Cover = await html2canvas(coverImage, { ...highResContent, scale: 1 });
      }

      if (!!Cover) {
        // First send images to S3 and Get URL
        const photoToS3: FileUploadS3[] = [
          {
            image: await canvasToBlobPromise(Cover),
            folder: "books",
          },
        ];

        setSaveStep("sendImg");

        sagaMiddleware.run<any>(
          savePhotosS3,
          photoToS3,
          (err: string | null, responses?: FileUploadedS3[]) => {
            if (err !== null) {
              Modal.error({
                title: "Error",
                content: err,
              });
              setLoading(false);
              setSaveStep(null);
              return;
            } else if (!!responses) {
              const fontFamily = bookText.font

              const bookToSave = {
                id: !!id ? id : undefined,
                cover: selectedCover!.image,
                thumbnail: responses[0].thumbnail,
                title: bookText.title,
                subtitle: bookText.subtitle,
                font_id:bookText.font_id,
                pages: selectedBabypages.map((babypage) =>
                  lastSegmentUrl(babypage.thumbnail)
                ),
                babypages_ids: selectedBabypages.map((babypage) => babypage.id),
                title_styles: { fontFamily },
                subtitle_styles: { fontFamily },
                parent_id: user.id,
                type: bookType,
              };

              setSaveStep("saveBook");

              sagaMiddleware.run<any>(
                saveBookSaga,
                bookToSave,
                (error?: any, id?: number) => {
                  setLoading(false);
                  setSaveStep(null);

                  if (error) {
                    Modal.error({
                      title: "Error",
                      content: error,
                    });
                    return;
                  }

                  setSomethingChange(false);

                  notification.success({
                    message: "Success",
                    description: "Book saved successfully",
                  });

                  setTimeout(() => {
                    // Check if show rating form
                    sagaMiddleware.run<any>(checkUserModal, history);

                    history.push("/books?createdBook=true");
                  }, 300);
                }
              );
            } else {
              Modal.error({
                title: "Error",
                content: "Error saving Book",
              });
              setLoading(false);
              setSaveStep(null);
            }
          }
        );
      } else {
        setLoading(false);
        setSaveStep(null);
      }
    } catch (error) {
      setLoading(false);
      setSaveStep(null);

      Modal.error({
        title: "Error",
        content:
          "An error has occurred during Book generation, please try again later",
      });
    }
  }, [bookText, history, user, selectedCover, selectedBabypages, id]);

  const nextStep = useCallback(() => {
    if (control.step === 4) {
      window.scrollTo(0, 0);
      document.body.style.overflow='hidden'
      saveBook();
    } else {  
      if (!!contentRef.current) {
        contentRef.current.scrollTo(0, 0);
      }


      if (!!id) {
        setControl((prev)  => {
          return {
            ...prev,
            step:prev.step + 1,
          }
        })
      } else {
        setControl((prev)  => {
          const step = prev.step + 1
          return {
            valid: step === 2 || step === 3 || step === 4,
            step: step,
          }
        })
      }
    }
  }, [control.step, saveBook, contentRef, id]);

  return (
    <BaseLayout
      title="BabyPage - Save Books"
      pageTitle="Create Book"
      pageSubtitle={
          control.step === 0
            ? "Select Cover"
            : control.step === 1
            ? "Select Babypages"
            : control.step === 2
            ? "Reorder Pages"
            : control.step === 3
            ? "Edit Title"
            : "Preview"
      }
      hideHeaderChildMenu={true}
      hideChildMenu={true}
      hideBanner
      pageStyle={{  backgroundColor: "#f0f0f0" }}
    >
      <RouteLeavingGuard
        when={somethingChange}
        navigate={(path) => history.push(path)}
      />
      {loading && (
        <div className="d-flex flex-column justify-content-center align-items-center loading-fullscreen">
          <Icon type="loading" style={{ fontSize: 36 }} />
          {!!saveStep && (
            <p className="mt-3">
              {saveStep === "genImg"
                ? "Generating High Res. ..."
                : saveStep === "sendImg"
                ? "Saving High Res. Image..."
                : "Saving Book..."}
            </p>
          )}
        </div>
      )}
      <div className="save-book-content" ref={contentRef}>
        <div className="container h-100">{renderStep()}</div>
      </div>
      {/* <div className='button-bar-book-parent'>
        <div
          className={` button-bar-book ${buttonBarClasses} `}
          style={{ height: control.step === 1 ? 100 : 80 }}
        >
          {control.step === 1 && (
            <p className="button-bar-babypages">
              Selected BabyPages: {selectedBabypages.length}
            </p>
          )}
          <div className="row justify-content-center align-items-center">
            {control.step > 0 && (
              <>
                <Button
                  type="primary"
                  ghost
                  className="px-4"
                  shape="round"
                  disabled={loading}
                  onClick={() => {
                    if (!!contentRef.current) {
                      contentRef.current.scrollTo(0, 0);
                    }
                    setControl((old) => {
                      let step = old.step - 1;
                      return { step, valid: true };
                    });
                  }}
                >
                  PREVIOUS
                </Button>
                &nbsp;
              </>
            )}
            <Button
              type="primary"
              className="px-4"
              ghost
              shape="round"
              onClick={nextStep}
              disabled={!control.valid || loading}
            >
              {control.step !== 4 ? "NEXT" : "FINISH"}
            </Button>
          </div>
        </div> */}
      {/* </div> */}
      <ModalPlus visible={plusOpen} onClose={() => setPlusOpen(false)} onSaveBeforeUpgradeToPlus={sendToUpgradeToPlus} bookMessage={true} />
    </BaseLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  covers: state.books.covers,
  user: state.user,
  babies: state.baby.babies,
  babySelected: state.baby.babySelected,
  bookType: state.books.bookType,
});

export default connect(mapStateToProps)(SaveBooksPage);
