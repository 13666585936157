/* eslint-disable no-restricted-globals */
import { Button, Modal } from "antd";
import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { useHistory} from "react-router-dom";
import cardsImg from "../../assets/img/gift-card/cards.png";
import cardsImgMobile from "../../assets/img/gift-card/cards-mobile.png"
import newMomImg from "../../assets/img/gift-card/giftcard-newmom.jpg"
import newMomImgMobile from "../../assets/img/gift-card/giftcard-newmom-mobile.png"
import showerImg from "../../assets/img/gift-card/giftcard-babyshower.jpg"
import showerImgMobile from "../../assets/img/gift-card/giftcard-babyshower-mobile.png"
import holidayImg from "../../assets/img/gift-card/giftcard-holiday.jpg"
import holidayImgMobile from "../../assets/img/gift-card/giftcard-holiday-mobile.png"
import { useWindowSize } from "../../hooks/WindowsSize";
import { StripeProduct } from "../../interfaces";
import { AppState, sagaMiddleware } from "../../store";
import { addProductStore } from "../../store/sagas/cart";
import Select from "react-select";
import "./GiftCards.scss";

interface Props {
    stripeProducts: StripeProduct[];
    store?: string;
  }

const GiftCard: React.FC<Props> = ({
    stripeProducts,
    store
  }) => {
    const [width] = useWindowSize();
    const history = useHistory();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loading, setLoading] = useState(false);

    const [selectedOption, setSelectedOption] = useState({ value: 40, label: "$40.00" });
    let options = [{}]
    const range = [...Array(39 - 10 + 1)].map((_, i) => 10 + i * 10)
    for (const key of range) {
      const obj = { value: key, label: "$" + key + ".00" };
      options.push(obj)
    }
    const basicInfo = [
      {
        title: "New Mom",
        text: "Crazy with baby-prep, give mom something to make life easier.",
        imgMobile: newMomImgMobile,
        img: newMomImg,
      },
      {
        title: "Baby Showers",
        text: "Give a special mom-to-be something unique and unexpected!",
        imgMobile: showerImgMobile,
        img: showerImg,
      },
      {
        title: "Holiday",
        text: "Make the holidays brighter and share the warm and fuzzies.",
        imgMobile: holidayImgMobile,
        img: holidayImg,
      }
    ]
  
    const floatingCardsInfo = [
      {
        title: "$40 Gift Card",
        value: 40,
        perk1: "3-Month PLUS Membership",
        perk2: "Unlimited Babypages",
        perk3: "Free Shipping",
        perk4: "Small Softcover Book",
      },
      {
        title: "$75 Gift Card",
        value: 75,
        perk1: "1 Year PLUS Membership",
        perk2: "Unlimited Babypages",
        perk3: "Free Shipping",
        perk4: "Large Softcover Book",
      },
      {
        title: "$100 Gift Card",
        value: 100,
        perk1: "1 Year PLUS Membership",
        perk2: "Unlimited Babypages",
        perk3: "Free Shipping",
        perk4: "Large Hardcover Book",
      },
    ]

    const addGiftCard = useCallback(() => {
      const giftCard = stripeProducts.find((p) => p.caption === "giftcard");
      if (!!giftCard) {
        setLoading(true);
  
        sagaMiddleware.run<any>(
          addProductStore,
          {
            book_id: null,
            babypage_id: null,
            event: "",
            gift: { status: true, email: "", receiver: "", message: "", date: "" },
            id: giftCard.id,
            item: giftCard.type,
            options: [
              {
                id: giftCard.id,
                title: giftCard.name,
                description: giftCard.description,
                shippable: giftCard.shippable,
                attributes: giftCard.attributes,
                package_dimensions: giftCard.package_dimensions,
                active: giftCard.active,
                metadata: giftCard.metadata,
                images: giftCard.images,
                price: selectedOption.value,
                qty: 1,
                sku_id: giftCard.skus.data[0].id,
                sku_type: giftCard.skus.data[0].object,
                sku_image: giftCard.skus.data[0].image,
                sku_attributes: giftCard.skus.data[0].attributes,
                sku_package_dimensions: giftCard.skus.data[0].package_dimensions,
                sku_inventory: giftCard.skus.data[0].inventory,
                selected: true,
              },
            ],
            prices: [],
            qty: 1,
            stripe_id: giftCard.id,
            subtitle: giftCard.name,
            thumbnail: "",
            title: `BabyPage ${giftCard.name}`,
            total: selectedOption.value,
            type: giftCard.caption,
          },
          (error?: string, description?: string) => {
            setLoading(false);
  
            if (!!error) {
              Modal.error({
                title: "Error",
                content: error,
              });
            } else if (!!description) {
              const key = Date.now().toString();
  
              // Go directly to cartPage, instead to get notification
              history.push("/cart");
            }
          }
        );
      }
    }, [stripeProducts, store, history, selectedOption.value]);

    return (
      <div className="styled-container container-gift-card pb-0 margin-bottom-navigation-small">
      <div className="container pt-0">
        <div className="row"> 
          <div className="col-12 text-center px-0 pb-3 pb-md-5 mt-md-5 top-titles">
            <h2>GIVE SOMETHING DIFFERENT</h2>
            
            <div className="col-12 col-md-8 offset-md-2">
              <h1 className="mb-0">The gift that keeps on giving</h1>
            </div>

            <div className="row">
              <div className="col-12 col-md-8 offset-md-2">
                <p>Easy to order and easier to use, BabyPage gift cards give busy parents an extra-special way to enjoy magical memories.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          {basicInfo.map((item, index) => {
            return (
              <div className="col-12 col-md-4 text-center wrapper-gift-card" key={index}>
                <img src={width > 767 ? item.img : item.imgMobile} alt={item.title}/>
                <h4 className="mt-4 mb-2">{item.title}</h4>

                <div className="row">
                  <div className="col-10 offset-1">
                    <p className="mt-0 mb-5 pb-4">{item.text}</p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <div className="container-amount">
        <div className="container pt-0">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="mt-5 pt-3">Choose the perfect amount</h2>

              <div className="row">
                <div className="col-10 offset-1 col-md-8 offset-md-2">
                  <p className="mt-0 mb-5">
                    Select any amount in increments of $10. The gift card will be sent straight to their inbox and the
                    journey to fabulous baby books can begin!
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <img src={width > 767 ? cardsImg : cardsImgMobile} alt="BP Gift Cards" className="my-4" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-select-amount">
        <div className="container pt-0">
          <div className="row">
            <div className="col-12 text-center">
              <p className="mt-4">Examples of what they can get with a gift card:</p>
            </div>
          </div>

          <div className="row">
            {floatingCardsInfo.map((item, index) => {
              return (
                <div className="col-12 col-md-4 text-center container-floating-cards py-2 py-md-4 " key={index}>
                  <div
                    onClick={
                      () => {
                        setSelectedOption({ value: item.value, label: "$" + item.value + ".00" })
                      }
                    }
                    className={`floating-card py-3${selectedOption.value === item.value ? " active" : ""}`}
                  >
                    <p className="mb-2">{item.title}</p>
                    <ul className="pl-0 mt-0">
                      <li><p>{item.perk1}</p></li>
                      <li><p>{item.perk2}</p></li>
                      <li><p>{item.perk3}</p></li>
                      <li><p>{item.perk4}</p></li>
                    </ul>
                  </div>
                </div>
              )
            })}
          </div>

          <div className="row">
            <div className="col-10 offset-1 col-md-4 offset-md-4 text-center mt-3 mb-4 px-0">
              <Select
                className="custom-select"
                classNamePrefix="custom-select-child"
                defaultValue={selectedOption}
                value={selectedOption}
                onChange={(value: any) => setSelectedOption({value: value.value, label: value.label})}              
                options={options}
                isSearchable
              />
            </div>

            <div className="col-12 text-center">
              <Button
                className="btn plan-btn small"
                onClick = {addGiftCard}
              >
                Purchase Gift Card
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }

  const mapStateToProps = (state: AppState) => ({
    stripeProducts: state.stripeProduct,
    store: state.user.store,
    user: state.user,
    products: state.product.products,
  });
  
  export default connect(mapStateToProps)(GiftCard);