import { call, put, select } from "redux-saga/effects";
import { AppState } from "..";
import api from "../../api";
import { Milestone } from "../../interfaces";
import { User } from "../../interfaces/user";
import { isArrayEqual } from "../../utils/array";
import { parseRequestError } from "../../utils/error";
import { getMilestones, MilestoneState } from "../ducks/milestones";

/**
 * This function fetch all Milestones info
 **/
export function* fetchMilestones(callback?: Function) {
  const user: User = yield select((state: AppState) => state.user);

  if (!user.access_token || user.user_level === 3) return;

  try {
    const response = yield call(api.get, "api/get-milestones?lang=en", {
      headers: {
        Authorization: user.access_token
      }
    });

    if (response.data && response.data.code === 200) {
      const data = response.data.data;
      const milestones: MilestoneState = yield select(
        (state: AppState) => state.milestones
      );

      if (!isArrayEqual(milestones.milestones, data)) {
        yield put(getMilestones(response.data.data));
      }
    }

    if (callback) callback();
  } catch (error) {
    if (callback) callback(parseRequestError(error));
  }
}

/**
 * Get milestones for admin panel
 */
export function* getMilestonesForAdmin(
  filter: {
    title_name?: string;
  },
  callback: (err?: string, response?: Milestone[]) => void,
  page?: number
) {
  const accessToken = yield select(
    (state: AppState) => state.admin.access_token
  );

  try {
    let search = "";

    if (!!filter.title_name) {
      search += `&title_name=${filter.title_name}`;
    }

    if (!!page) {
      search += `&page=${page}`;
    }

    const response = yield call(
      api.get,
      `api/admin/milestones?lang=en${search}`,
      {
        headers: {
          Authorization: accessToken
        }
      }
    );

    callback(undefined, response.data.data);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * Get milestones by id for admin panel
 */
export function* getMilestoneByIdForAdmin(
  id: number,
  callback: (err?: string, response?: Milestone[]) => void,
  page?: number
) {
  const accessToken = yield select(
    (state: AppState) => state.admin.access_token
  );

  try {
    const response = yield call(api.get, `api/admin/milestone/${id}`, {
      headers: {
        Authorization: accessToken
      }
    });

    callback(undefined, response.data.data);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * Create milestone
 */
export function* saveMilestone(
  id: number,
  form: any,
  callback: (err?: string, response?: Milestone) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.admin.access_token
  );

  try {
    const endpoint = id !== 0 ? `api/admin/milestone/${id}` : "api/admin/milestone";
    const response = yield call(api.post, endpoint, form, {
      headers: {
        Authorization: accessToken
      }
    });

    callback(undefined, response.data.data);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * Delete milestone by id
 */
export function* deleteMilestone(id: number, callback: (err?: string) => void) {
  const accessToken = yield select(
    (state: AppState) => state.admin.access_token
  );

  try {
    yield call(api.delete, `api/admin/milestone/${id}`, {
      headers: {
        Authorization: accessToken
      }
    });

    callback(undefined);
  } catch (error) {
    callback(parseRequestError(error));
  }
}
