import React from "react";

function PinkCircle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z"></path>
        <path fill="#FFAFA5" d="M12 20a8 8 0 100-16 8 8 0 000 16z"></path>
        <path
          fill="#FFF"
          d="M12 15.2a3.2 3.2 0 100-6.4 3.2 3.2 0 000 6.4z"
        ></path>
      </g>
    </svg>
  );
}

export default PinkCircle;
