import React from "react";

function EyeOpen() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
    >
      <path
        fill="#658071"
        d="M7.942.9a7.945 7.945 0 017.811 6.498 7.945 7.945 0 01-15.622 0A7.945 7.945 0 017.942.9zm0 11.552a6.502 6.502 0 006.337-5.054 6.502 6.502 0 00-12.674 0 6.502 6.502 0 006.337 5.054zm0-1.805a3.249 3.249 0 110-6.498 3.249 3.249 0 010 6.498zm0-1.444a1.805 1.805 0 100-3.61 1.805 1.805 0 000 3.61z"
      ></path>
    </svg>
  );
}

export default EyeOpen;
