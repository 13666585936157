import moment from 'moment';
import  { BabyPage } from '../interfaces';

export let searchBabypages = (
    search:string, 
    babypages: BabyPage [], 
    setFilteredBabypages: React.Dispatch<React.SetStateAction<any[]>>
    ) => {


    let searchArray = search.split(" ");

    let allWordsMatch = searchArray.map((word:string) => {
      
      let wordMatchs = babypages.map((babypage: BabyPage, index: number) => {
        let fullTitle = `${babypage.title} ${babypage.subtitle}`

        if(fullTitle.toLowerCase().includes(word.toLowerCase())) {
          return babypage;
        } else {
          return ;
        }
       
      });


      return wordMatchs;

    })

   let filteredWordMatch = allWordsMatch.map(matchesArray => {
     return matchesArray.filter((item) => !!item )
    });

    let arrayFilteredBabypages : any = []

    arrayFilteredBabypages = filteredWordMatch.reduce((previousWord,currentWord) => previousWord.filter(wordMatch => currentWord.includes(wordMatch)));


    setFilteredBabypages([...arrayFilteredBabypages])
  }


  export default searchBabypages;