import {Avatar, Badge, Card, Drawer, Icon, Menu} from 'antd';
import {History, Location} from 'history';
import React, {useMemo} from 'react';
import {User} from '../../interfaces';
import {capitalizeTexts} from '../../utils/string';
import {MenuItemObject, MobileMenuItems, MobileUserItems} from './menuItems';
import { PAGE_URL } from '../../env';

interface Props {
  drawerOpen: boolean;
  setDrawerOpen: Function;
  logout: Function;
  location: Location<any>;
  history: History<any>;
  user: User;
  storeLength: number;
  showGuestUserModal: Function;
  alexandersOrderCounter: number;
}

const CustomDrawer: React.FC<Props> = ({
  drawerOpen,
  setDrawerOpen,
  logout,
  location,
  history,
  user,
  storeLength,
  alexandersOrderCounter,
  showGuestUserModal,
}) => {
  const userName = useMemo(() => {
    if (!user.first_name || !user.last_name) return '';

    const userName = `${capitalizeTexts(user.first_name)} ${capitalizeTexts(
      user.last_name
    )}`;

    return userName.length > 25 ? userName.substr(0, 22) + '...' : userName;
  }, [user.first_name, user.last_name]);

  let counter: any = {
    storeLength: storeLength,
    alexandersOrderCounter: alexandersOrderCounter,
  };

  return (
    <Drawer
      placement='right'
      closable={false}
      onClose={() => setDrawerOpen(!drawerOpen)}
      visible={drawerOpen}
    >
      <Card style={{
        marginTop: '0.7rem',
        display: "flex",
        justifyContent: "center",
        textAlign: "center"

      }}>
        <div style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "0.5rem",

        }}>
          <Avatar size={50} style={{backgroundColor: 'rgb(98, 127, 113)'}}>
            {`${user.first_name?.slice(0, 1)}${user.last_name?.slice(0, 1)}`}
          </Avatar>
        </div>
        <span>
            <b>
              {`${user.first_name} ${user.last_name}`}
            </b>
          </span>
        <br/>
        <span
          style={{cursor: "pointer"}}
          onClick={() => {
            setDrawerOpen(!drawerOpen)
            showGuestUserModal()
          }}
        >
          <b>
            <small>
              Switch Account
            </small>
          </b>
        </span>
      </Card>
      <Menu
        selectedKeys={[location.pathname]}
        mode='vertical'
        style={{
          border: 0,
        }}
      >
        {MobileMenuItems
          .filter(item =>
            user.user_level === 0 ?
              !item.hideFromFreeUser
            :
              user.user_level === 3 ?
                !item.hideFromUserGift
              :
                !!user.guest_user ? !item.hideFromGuestUser : true
          )
          .map((item: MenuItemObject) => (
            <Menu.Item
              key={item.key}
              style={{
                border: 0,
              }}
              className={!!user.guest_user && user.permission !== "Full Access" ? item.cssClass : ""}
              onClick={() => {
                (!user.guest_user || (!!user.guest_user && user.permission === "Full Access") || (item.cssClass === undefined)) && history.push(item.key)
              }}
            >
              {item.badge ? (
                <Badge dot={storeLength > 0}>
                  <Icon type={item.icon}/>
                </Badge>
              ) : (
                <Icon type={item.icon}/>
              )}
              {item.text}
              {item.counterBadge && (
                <Badge count={counter[item.counterBadge.counter]} style={{
                  left: item.counterBadge.left
                }}>
                </Badge>
              )}
            </Menu.Item>
          ))}
        <Menu.SubMenu
          style={{
            border: 0,
          }}
          title={
            <span className='submenu-title-wrapper'>
              <Icon type='user'/>
              {userName}
            </span>
          }
        >
          {MobileUserItems(logout)
            .filter(item =>
              user.user_level === 0 ?
                !item.hideFromFreeUser
              :
                user.user_level === 3 ?
                  !item.hideFromUserGift
                :
                  !!user.guest_user ? !item.hideFromGuestUser : true
            )
            .map(userItem => (
              <Menu.Item
                key={userItem.key}
                onClick={() => {
                  if (userItem.onClick) {
                    userItem.onClick();
                  } else {
                    if (userItem.newTab) {
                      const winOpened = window.open(userItem.key, '_blank');
                      try {
                        winOpened!.focus();
                      } catch (err) {
                      }
                    } else {
                      history.push(userItem.key);
                    }
                  }
                }}
              >
                <Icon type={userItem.icon}/>
                {userItem.text}
              </Menu.Item>
            ))}
        </Menu.SubMenu>
        <Menu.Item
          key={`${PAGE_URL}support`}
          style={{
            border: 0,
          }}
        >
          <Icon type='question-circle' />
          Support
          <a href={`${PAGE_URL}support`} target='_blank'></a>
        </Menu.Item>
      </Menu>
    </Drawer>
  );
};

export default CustomDrawer;
