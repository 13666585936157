import React from 'react';
import working from '../../../assets/img/working.png';

const AdminIndexPage: React.FC = () => (
  <div
    className='w-100 d-flex justify-content-center align-items-center'
    style={{ height: '100vh' }}
  >
    <div>
      <img
        src={working}
        alt='Working'
        className='d-table mx-auto'
        style={{ height: 200, width: 200 }}
      />
      <h3 className='primary text-center' style={{ maxWidth: 250 }}>
        Hey, we are working on a beautiful dashboard, for now, you can use the
        menu at left to navigate between the pages.
      </h3>
    </div>
  </div>
);

export default AdminIndexPage;
