import React from "react";
import { Cover } from "../../interfaces";
import CoverPreview from "../CoverPreview/CoverPreview";
import CustomDivider from "../CustomDivider";

interface Props {
  text: string;
  covers: Cover[];
  setSelectedCover: React.Dispatch<React.SetStateAction<Cover | undefined>>;
  selectedCover: Cover | undefined;
  setControl: React.Dispatch<
    React.SetStateAction<{
      valid: boolean;
      step: number;
    }>
  >;
  isPlus: boolean;
  showPlus: () => void;
  setSomethingChange: React.Dispatch<React.SetStateAction<boolean>>;
}

const CoverList: React.FC<Props> = ({
  covers,
  text,
  setSelectedCover,
  selectedCover,
  showPlus,
  setControl,
  isPlus,
  setSomethingChange
}) => {
  const props = {
    setSelectedCover,
    selectedCover,
    showPlus,
    isPlus,
    setControl,
    setSomethingChange
  };

  return (
    <div className="py-2">
      {covers.length !== 0 && <CustomDivider text={text} style={{textAlign: "center"}} />}
      <div className="container">
        <div className="row justify-content-start align-items-center py-4">
          {covers.map((cover, index) => {
            return <CoverPreview key={index} cover={cover} {...props} />;
          })}
        </div>
      </div>
    </div>
  );
};
export default CoverList;
