import { call, put, select } from "redux-saga/effects";
import { AppState } from "..";
import api from "../../api";
import { SaveBabyPage, Border, Illustration } from "../../interfaces";
import { User } from "../../interfaces/user";
import { isArrayEqual } from "../../utils/array";
import {
  getBorders,
  getIllustrations,
  saveDraftBabyPage,
} from "../ducks/bpHelper";

/**
 * This function fetch all Borders
 **/
export function* fetchBorders() {
  const user: User = yield select((state: AppState) => state.user);

  if (!user.access_token || user.user_level === 3) return;

  try {
    const response = yield call(api.get, "api/borders", {
      headers: {
        Authorization: user.access_token,
      },
    });

    if (response.data && response.data.code === 200) {
      const data = response.data.data;
      const borders: Border[] = yield select(
        (state: AppState) => state.bpHelper.borders
      );

      if (!isArrayEqual(borders, data)) {
        yield put(getBorders(data));
      }
    }
  } catch (error) {}
}

/**
 * This function fetch all Illustrations
 **/
export function* fetchIllustrations() {
  const user: User = yield select((state: AppState) => state.user);

  if (!user.access_token || user.user_level === 3) return;

  try {
    const response = yield call(api.get, "api/illustrations", {
      headers: {
        Authorization: user.access_token,
      },
    });

    if (response.data && response.data.code === 200) {
      const data = response.data.data;
      const illustrations: Illustration[] = yield select(
        (state: AppState) => state.bpHelper.illustrations
      );

      if (!isArrayEqual(illustrations, data)) {
        yield put(getIllustrations(data));
      }
    }
  } catch (error) {}
}

/**
 * This function save draft babypage before upgrade to PLUS
 **/
export function* setDraftBabyPage(data: SaveBabyPage) {
  const user: User = yield select((state: AppState) => state.user);

  if (!user.access_token || user.user_level === 3) return;

  try {
    yield put(saveDraftBabyPage(data));
  } catch (error) {}
}
