import { Modal } from 'antd';
import { saveAs } from 'file-saver';
import { BASE_URL, highRes } from '../env';
import { canvasToBlobPromise, getBase64FromFileUrl } from './image';

export const generateImage = (
  url: string,
  width: number,
  height: number,
  ignoreCors: boolean = false
): Promise<HTMLImageElement> =>
  new Promise(async (resolve, reject) => {
    const img = new Image();
    img.width = width;
    img.height = height;
    img.src = ignoreCors
      ? await getBase64FromFileUrl(url, `${BASE_URL}api/avoid-cors?url=`)
      : url;
    img.onerror = reject;
    img.onload = () => resolve(img);
  });

export const generateBabypageImg = (
  bpUrl: string,
  contentUrl: string,
  isPlus: boolean,
  returnBase64?: boolean
): Promise<Blob | string> =>
  new Promise(async (resolve, reject) => {
    const width = isPlus ? highRes.width : highRes.width / 4;
    const height = isPlus ? highRes.height : highRes.height / 4;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d')!;

    const bpImg = await generateImage(bpUrl, width, height, true);
    const contentImg =
      contentUrl !== ''
        ? await generateImage(contentUrl, width, height, true)
        : null;

    canvas.width = !!contentImg ? width * 2 : width;
    canvas.height = height;

    ctx.drawImage(bpImg, 0, 0, width, height);

    if (!!contentImg) {
      ctx.drawImage(contentImg, width, 0, width, height);
    }

    // As Base64 string
    if (returnBase64) {
      return resolve(canvas.toDataURL('image/jpeg'));
    } else {
      return canvasToBlobPromise(canvas, 'image/jpeg')
        .then(file => resolve(file))
        .catch(reject);
    }
  });

export const downloadBabypage = async (
  bpUrl: string,
  contentUrl: string,
  isPlus: boolean,
  fileName?: string
): Promise<void> => {
  try {
    const generatedFile = await generateBabypageImg(bpUrl, contentUrl, isPlus);
    saveAs(
      generatedFile,
      `${!!fileName ? fileName : Date.now().toString()}.jpg`
    );
  } catch (error) {
    Modal.error({
      title: 'Error',
      content: 'Failed to generate BabyPage',
    });
  }
};
