import React, { useEffect, useRef, useState, useCallback } from "react";
import { highResContent } from "../../env";
import { useWindowSize } from "../../hooks/WindowsSize";
import { BabyPage, Cover } from "../../interfaces";
import { dynamicSize, getProportionalSize } from "../../utils/size";
import BookModal from "../BookModal/BookModal";
import "./BookCover.scss";
import logo from "../../assets/img/logo-babypage.png";

interface Props {
  loading: boolean;
  bookText: {
    title: string;
    subtitle: string;
    font: string;
  };
  selectedCover: Cover | undefined;
  selectedBabypages?: BabyPage[];
  useOldBabypages?: boolean;
  modal?: boolean;
  setModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialSize = {
  title: 19.5,
  subtitle: 16.5,
};

const BookCover: React.FC<Props> = ({
  loading,
  bookText,
  selectedCover,
  selectedBabypages,
  useOldBabypages,
  modal,
  setModal,
}) => {
  const [width] = useWindowSize();
  const [sizes, setSizes] = useState(initialSize);
  const [currentIndex, setCurrentIndex] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const getSize = (size: number) =>
      getProportionalSize(
        dynamicSize(size),
        contentRef.current !== null
          ? loading
            ? highResContent.width
            : contentRef.current.offsetWidth
          : width
      );

    setSizes({
      title: getSize(initialSize.title),
      subtitle: getSize(initialSize.subtitle),
    });
  }, [contentRef, loading, width]);

  useEffect(() => {
    const title = bookText.title;
    const subtitle = bookText.subtitle;
    const startResizingAt =
      width <= 320
        ? 24
        : width <= 375
        ? 21
        : width <= 425
        ? 19
        : width <= 540
        ? 16
        : width <= 768
        ? 28
        : width <= 1024
        ? 30
        : width < 1440
        ? 24
        : 25;

    const getSize = (size: number) =>
      getProportionalSize(
        dynamicSize(size),
        contentRef.current !== null
          ? loading
            ? highResContent.width
            : contentRef.current.offsetWidth
          : width
      );

    let initialSizeTitle = getSize(initialSize.title);
    let initialSizeSubtitle = getSize(initialSize.subtitle);

    if (bookText.font === "Montserrat") {
      initialSizeTitle = getSize(initialSize.title * 0.90);
      initialSizeSubtitle = getSize(initialSize.subtitle * 0.90);
    } else if (bookText.font === "NunitoSans") {
      initialSizeTitle = getSize(initialSize.title * 1.05);
      initialSizeSubtitle = getSize(initialSize.subtitle * 1.05);
    } else if (bookText.font === "EBGaramond") {
      initialSizeTitle = getSize(initialSize.title * 1.1);
      initialSizeSubtitle = getSize(initialSize.subtitle * 1.1);
    } else if (bookText.font === "BigCaslon") {
      initialSizeTitle = getSize(initialSize.title * 1.05);
      initialSizeSubtitle = getSize(initialSize.subtitle * 1.05);
    } else if (bookText.font === "JosefinSans") {
      initialSizeTitle = getSize(initialSize.title);
      initialSizeSubtitle = getSize(initialSize.subtitle);
    } else if (bookText.font === "StyleScript-Regular") {
      initialSizeTitle = getSize(initialSize.title * 1.1);
      initialSizeSubtitle = getSize(initialSize.subtitle * 1.1);
    }

    const newTitleFontSize = resizeFont(
      initialSizeTitle,
      startResizingAt,
      title.length
    );
    const newSubtitleFontSize = resizeFont(
      initialSizeSubtitle,
      startResizingAt,
      subtitle.length
    );

    if (newSubtitleFontSize > newTitleFontSize) {
      setSizes({
        title: newTitleFontSize,
        subtitle: newTitleFontSize - 1,
      });

      return;
    }

    setSizes({
      title: newTitleFontSize,
      subtitle: newSubtitleFontSize,
    });
  }, [contentRef, loading, width, bookText.title, bookText.subtitle, bookText.font]);

  const resizeFont = useCallback(
    (initialFontSize: number, startResizingAt: number, textLenght: number) => {
      return (
        initialFontSize *
        (startResizingAt /
          (textLenght + 2 > startResizingAt ? textLenght + 4 : startResizingAt))
      );
    },
    []
  );

  const contentStyle: React.CSSProperties = {
    fontFamily: bookText.font,
  };

  if (loading) {
    contentStyle.height = highResContent.height;
    contentStyle.width = highResContent.width;
  }

  return (
    <>
      <div
        id="book-cover"
        className="cover-content elevation-light"
        style={contentStyle}
        ref={contentRef}
      >
        <div className="d-flex flex-column">
          <div
            className={`book-cover ${!!selectedBabypages ? "clickable" : ""}`}
            onClick={() => setModal!(true)}
          >
            {!!selectedCover && (
              <img
                src={
                  !!selectedCover.image_b64
                    ? selectedCover.image_b64
                    : selectedCover.thumbnail
                }
                alt={selectedCover.title}
              />
            )}
            <div className="book-text">
              <h1
                className="book-title"
                style={{ fontSize: sizes.title }}
              >
                {bookText.title}
              </h1>
              <h2
                className="book-subtitle"
                style={{ fontSize: sizes.subtitle, fontFamily: contentStyle.fontFamily }}
              >
                {bookText.subtitle}
              </h2>
            </div>
          </div>
        </div>
      </div>
      {!!selectedBabypages &&
        (!useOldBabypages ? (
          <BookModal
            leftPage={selectedBabypages[currentIndex].thumbnail}
            rightPage={selectedBabypages[currentIndex].thumbnail_content}
            visible={modal}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            pagesLimit={selectedBabypages.length - 1}
            onClose={() => setModal!(false)}
            useOldBabypages={useOldBabypages!}
          />
        ) : (
          <BookModal
            leftPage={selectedBabypages[currentIndex].thumbnail}
            rightPage={
              selectedBabypages[currentIndex + 1]
                ? selectedBabypages[currentIndex + 1].thumbnail
                : logo
            }
            visible={modal}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            pagesLimit={
              selectedBabypages[currentIndex + 1]
                ? selectedBabypages.length - 2
                : selectedBabypages.length - 1
            }
            onClose={() => setModal!(false)}
            useOldBabypages={useOldBabypages!}
          />
        ))}
    </>
  );
};

export default BookCover;
