import {
  Button,
  Card,
  Icon,
  Input,
  Modal,
  notification,
  Select,
  Slider,
  Spin
} from "antd";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import moment from "moment";
import queryString from "query-string";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BaseLayout from "../../../components/BaseLayout/BaseLayout";
import CustomDivider from "../../../components/CustomDivider";
import GrowthChart from "../../../components/Dashboard/GrowthChart";
import { Baby } from "../../../interfaces";
import { AppState, sagaMiddleware } from "../../../store";
import {
  getGrowthChartById,
  saveGrowthChartSaga
} from "../../../store/sagas/growthChart";
import { trackAction } from "../../../utils/marketing";
import { capitalizeTexts } from "../../../utils/string";
import "../../Dashboard/DashboardPage.scss";
import { chartColors, fontColors } from "./Colors";
import "./Save.scss";
const { Option } = Select;

interface Props {
  babySelected?: Baby;
}

interface SaveGrowthChart {
  child_id: number;
  color: string;
  font: string;
  fontcolor: string;
  from_date: number;
  title: string;
  to_date: number;
  type: "height" | "weight";
  editId: number;
}

const SaveGrowthChartPage: React.FC<Props> = ({ babySelected }) => {
  // eslint-disable-next-line
  const history = useHistory();
  const location = useLocation();

  const [lastBaby, setLastBaby] = useState<Baby | undefined>(undefined);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [growthChart, setGrowthChart] = useState<SaveGrowthChart | undefined>(
    undefined
  );

  useEffect(() => {
    if (!babySelected) return;
    if (!!lastBaby && isEqual(babySelected, lastBaby)) return;

    let id: any = queryString.parse(location.search).id; // If is update

    if (!!id && !lastBaby) {
      setLoading(true);
      setLastBaby(cloneDeep(babySelected));

      sagaMiddleware.run<any>(
        getGrowthChartById,
        id,
        (error: string | null, gc?: any) => {
          setLoading(false);
          if (error || !gc || !gc.length) {
            Modal.error({
              title: "Error",
              content: !!error ? error : "Growth Chart not found"
            });
            history.push("/growth-chart");
            return;
          } else {
            const from_date = babySelected.heights.findIndex(
              e => e.entry_date === gc[0].from_date
            );
            const to_date = babySelected.heights.findIndex(
              e => e.entry_date === gc[0].to_date
            );

            setGrowthChart({
              child_id: babySelected.id,
              color: gc[0].color,
              font: gc[0].font,
              fontcolor: gc[0].fontcolor,
              from_date: from_date !== -1 ? from_date : 0,
              type: gc[0].type,
              to_date: to_date !== -1 ? to_date : 0,
              title: gc[0].title,
              editId: gc[0].id
            });
          }
        }
      );
    } else {
      setLastBaby(cloneDeep(babySelected));

      setGrowthChart({
        child_id: babySelected.id,
        color: "#658071",
        font: "NunitoSans",
        fontcolor: "#000000",
        from_date: 0,
        editId: 0,
        type: "height",
        to_date:
          !!babySelected.heights && !!babySelected.heights.length
            ? babySelected.heights.length - 1
            : 0,
        title: `${babySelected.baby_first_name}'s Growth Chart`
      });
    }
  }, [babySelected, lastBaby, location.search, history]);

  const formattedDates: string[] = useMemo(
    () =>
      !!babySelected && !!babySelected.heights && !!babySelected.heights.length
        ? babySelected.heights.map(h =>
            moment(h.entry_date.substr(0, 10)).format("MM[/]DD[/]YYYY")
          )
        : [],
    [babySelected]
  );

  const saveGrowthChart = useCallback(() => {
    if (!babySelected || !growthChart) return;

    setLoading(true);

    sagaMiddleware.run<any>(
      saveGrowthChartSaga,
      {
        ...growthChart,
        editId: !!growthChart.editId ? growthChart.editId : undefined,
        from_date: babySelected.heights[growthChart.from_date].entry_date,
        to_date: babySelected.heights[growthChart.to_date].entry_date
      },
      (error: string | null) => {
        setLoading(false);

        if (!!error) {
          Modal.error({
            title: "Error",
            content: error
          });
        } else {
          notification.success({
            message: "Success",
            description: "Growth Chart saved successfully"
          });
          trackAction(
            "CreateGrowthChart",
            {
              google: {
                event: "create_growth_chart",
              }
            },
            true
          );
          history.push("/growth-chart");
        }
      }
    );
  }, [babySelected, growthChart, history]);

  return !babySelected ? null : (
    <BaseLayout
      title="BabyPage - Save Growth Chart"
      pageTitle="Save Growth Chart"
      hideChildMenu
      hideHeaderChildMenu
    >
      {loading && (
        <div className="d-flex justify-content-center my-3">
          <Spin
            indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
          />
        </div>
      )}
      {!!growthChart && (
        <div className="row justify-content-center align-items-center my-3">
          <div className="col-12 col-lg-6">
            <div className="m-md-5">
              <GrowthChart
                baby={babySelected}
                customTitle={growthChart.title}
                customFontColor={growthChart.fontcolor}
                customChartColor={growthChart.color}
                customFont={growthChart.font}
                customFromTo={[growthChart.from_date, growthChart.to_date]}
                defaultType={growthChart.type}
                onChangeType={type =>
                  setGrowthChart(state => ({ ...state!, type }))
                }
              />
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="mobile-only">
              <CustomDivider
                text="CUSTOMIZE"
                customClasses={["milestones-text"]}
              />
            </div>
            <div className="m-md-5">
              <Card
                className="d-block dashboard-card"
                title="Customize"
                style={{ padding: 0 }}
              >
                <div className="p-3">
                  <label>Growth Chart Title</label>
                  <Input
                    className="w-100"
                    value={growthChart.title}
                    onChange={e =>
                      setGrowthChart(state => ({
                        ...state!,
                        title: e.target.value
                      }))
                    }
                  />
                  <br />
                  <label>Font Color</label>
                  <div className="d-flex">
                    {fontColors.map((c, index) => (
                      <React.Fragment key={index}>
                        <div
                          className={`color-selection ${
                            growthChart.fontcolor === c ? " selected" : ""
                          }`}
                          style={{ backgroundColor: c }}
                          onClick={() =>
                            setGrowthChart(state => ({
                              ...state!,
                              fontcolor: c
                            }))
                          }
                        ></div>
                        &nbsp;
                      </React.Fragment>
                    ))}
                  </div>
                  <br />
                  <label>Chart Color</label>
                  <div className="d-flex">
                    {chartColors.map((c, index) => (
                      <React.Fragment key={index}>
                        <div
                          className={`color-selection ${
                            growthChart.color === c ? " selected" : ""
                          }`}
                          style={{ backgroundColor: c }}
                          onClick={() =>
                            setGrowthChart(state => ({ ...state!, color: c }))
                          }
                        ></div>
                        &nbsp;
                      </React.Fragment>
                    ))}
                  </div>
                  <br />
                  <label>Font</label>
                  <Select
                    value={growthChart.font}
                    onChange={(font: string) =>
                      setGrowthChart(state => ({
                        ...state!,
                        font
                      }))
                    }
                    style={{ fontFamily: growthChart.font }}
                    className="w-100 mb-3"
                  >
                    <Option
                      value="BigCaslon"
                      style={{ fontFamily: "BigCaslon" }}
                    >
                      BigCaslon
                    </Option>
                    <Option
                      value="EBGaramond"
                      style={{ fontFamily: "EBGaramond" }}
                    >
                      EBGaramond
                    </Option>
                    <Option
                      value="JosefinSans"
                      style={{ fontFamily: "JosefinSans" }}
                    >
                      JosefinSans
                    </Option>
                    <Option
                      value="Montserrat"
                      style={{ fontFamily: "Montserrat" }}
                    >
                      Montserrat
                    </Option>
                    <Option
                      value="NunitoSans"
                      style={{ fontFamily: "NunitoSans" }}
                    >
                      NunitoSans
                    </Option>
                  </Select>
                  {!!babySelected.heights && !!babySelected.heights.length && (
                    <>
                      <br />
                      <label>From/To</label>
                      <Slider
                        range
                        value={[growthChart.from_date, growthChart.to_date]}
                        min={0}
                        max={babySelected.heights.length - 1}
                        onChange={e => {
                          setGrowthChart(state => ({
                            ...state!,
                            // @ts-ignore
                            from_date: e[0],
                            // @ts-ignore
                            to_date: e[1]
                          }));
                        }}
                        tipFormatter={value => formattedDates[value]}
                      />
                    </>
                  )}
                </div>
              </Card>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="m-3">
              {babySelected.heights.length < 3 && (
                <h3 className="text-center">
                  Please create at least 3 entries before generating a Growth
                  Chart
                </h3>
              )}
              <Button
                type="primary"
                shape="round"
                className="w-100"
                disabled={
                  loading || !babySelected || babySelected.heights.length < 3
                }
                onClick={saveGrowthChart}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
    </BaseLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  babySelected: state.baby.babySelected
});

export default connect(mapStateToProps)(SaveGrowthChartPage);
