import React from "react";
import { Button } from "antd";
import "./FloatButton.scss";

interface Props {
  type?:
    | "link"
    | "default"
    | "ghost"
    | "primary"
    | "dashed"
    | "danger"
    | undefined;
  shape?: "circle" | "round" | "circle-outline" | undefined;
  icon: string;
  position: "top-right" | "top-right-banner" | "top-left" | "bottom-right" | "bottom-left";
  onClick?:
    | ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void)
    | undefined;
  ghost?: boolean;
  unFixed?: boolean;
  size?: "default" | "small" | "large" | undefined;
  hasHeader?: boolean;
}

const FloatButton: React.FC<Props> = ({
  type,
  shape,
  icon,
  position,
  onClick,
  ghost,
  unFixed,
  size,
  hasHeader
}) => (
  <Button
    className={`float-btn elevation-5 ${position}`}
    type={type}
    shape={shape}
    icon={icon}
    onClick={onClick}
    ghost={ghost}
    size={size}
    style={{
      position: !!unFixed ? "absolute" : "fixed", top: !!hasHeader ? "20px" : "110px" 
    }}
  />
);

export default FloatButton;
