// @ts-nocheck
import queryString from 'query-string';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import BaseLayout from '../../../components/BaseLayout/BaseLayout';
import SaveChild from '../../../components/SaveChild/SaveChild';
import { AppState } from '../../../store/index';
import { Modal } from 'antd';

interface Props {
  isPlus: boolean;
}

const SaveChildPage: React.FC<Props> = ({ isPlus }) => {
  const history = useHistory();
  const location = useLocation();

  const [id, redirectManage] = useMemo(() => {
    const locationParsed = queryString.parse(location.search);
    return [locationParsed.id, locationParsed.redirectManage === 'true'];
  }, [location.search]);

  useEffect(() => {
    const locationParsed = queryString.parse(location.search);

    if (!isPlus && !locationParsed.id) {
      Modal.error({
        title: 'Error',
        content: 'You should be PLUS to add more children',
      });

      history.push('/');
    }
  }, [location.search, isPlus, history]);

  const saveChild = (
    <SaveChild
      id={!!id ? id.toString() : undefined}
      afterSave={() =>
        !!id || redirectManage
          ? history.push('/profile?child')
          : history.push('/')
      }
      shortForm={false}
      addChild
    />
  );

  return (
    <BaseLayout
      title='BabyPage - Save Child'
      pageTitle='Save Child'
      hideChildMenu
      pageStyle={{backgroundColor: '#f0f0f0'}}
    >
      <div className='my-3'>
      <div className={`col-12 manage-children-title mb-1 mt-3`}>
          <h2>ADD CHILD</h2>
         
        </div>
        <div
        className={`container-fluid milestones-container-width`}
        style={{
          backgroundColor: "#FFF",
          marginBottom: "50px",
          borderRadius: "15px"
        }}
      >
        {saveChild}
        </div>
      </div>
    </BaseLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  isPlus: !!state.user.user_level && state.user.user_level === 1,
});

export default connect(mapStateToProps)(SaveChildPage);
