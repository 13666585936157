import { Button, Modal } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';


interface Props {
  customText?: string;
  customTextBold?: boolean;
  visible: boolean;
  onClose: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const ModalSharePlus: React.FC<Props> = ({
  customText,
  customTextBold,
  visible,
  onClose
}) => {
  const history = useHistory();

  return (
    <Modal
      centered
      visible={visible}
      footer={null}
      onCancel={onClose}
      width="auto"
    >
      <div>
        <p className="text-center">
        This is a PLUS feature. Upgrade to a PLUS Subscription to save with these exclusive features.
        </p>
        <Button
          type="primary"
          className="mx-auto d-table"
          shape="round"
          onClick={() => history.push('/cart/subscription/yearly')}
        >
          Upgrade to PLUS
        </Button>
      </div>
    </Modal>
  );
};

export default ModalSharePlus;
