import { Icon } from 'antd';
import React from 'react';
import { SaveBabyPagePhoto } from '../../interfaces';
import './Layout1A.scss'

interface Props {
  photos: SaveBabyPagePhoto[];
  onClickPhoto?: (index: number, photo: SaveBabyPagePhoto) => void;
  loading?:boolean;
}

const Layout1A: React.FC<Props> = ({ photos, onClickPhoto, loading }) => {
  const checkMissing = () => {
    if(photos[0].base64_cropped !== '' && photos[0].missing) {
      return false
    } else if(photos[0].base64_cropped !== '' && !photos[0].missing){
      return true
    }
  } 
  return(
  <div className="row justify-content-center align-items-center h-100 w-100 mx-auto" style={{position:"relative"}}> 
     <div style={{width: "100%", height:"100%"}}>
       {!loading ? (
         <>
          <div className="click-through" style={{width: "93%", height:"93%", border:loading ? "20px dashed #e70c0c" : "2px dashed #e70c0c", zIndex: 995,  position: "absolute",  transform: "translate(-50%, -50%)", top:"50%", left:"50%"}} />
         </>
       ) : null}     
    { checkMissing() ? (
      <img
        src={photos[0].base64_cropped}
        alt="Baby 1"
        onClick={() => (!!onClickPhoto ? onClickPhoto(0, photos[0]) : {})}
        className="col-12 baby-photo w-100 h-100 p-0"
      />
    ) : (
        <div
          className="col-12 add-photo w-100 h-100 p-0"
          onClick={() => (!!onClickPhoto ? onClickPhoto(0, photos[0]) : {})}
        >
          <Icon type="plus-circle" theme="filled" style={{ fontSize: 36 }} />
        </div>
      )}
    </div>
  </div>
)};

export default Layout1A;
