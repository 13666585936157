import React, { Dispatch, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import AdminRoutes from "./Admin.routes";
import { DeepLinkInterface } from "./interfaces/guestUser";
import AboutPage from "./pages/About/About";
import SignInPage from "./pages/Auth/SignInPage/SignInPage";
import ResetPassword from "./pages/Auth/ResetPassword/ResetPassword";
import SignInToken from "./pages/Auth/SignInToken";
import StepThree from "./pages/Auth/SignUpSteps/StepThree";
import StepTwo from "./pages/Auth/SignUpSteps/StepTwo";
import AfterSavePage from "./pages/BabyPage/AfterSave/AfterSave";
import BabyPagesPage from "./pages/BabyPage/BabyPages";
import MilestonesPage from "./pages/BabyPage/MilestonesPage/MilestonesPage";
import SaveBabyPagesPage from "./pages/BabyPage/Save/Save";
import BooksPage from "./pages/Books/BooksPage";
import SaveBooksPage from "./pages/Books/Save/Save";
import CartPage from "./pages/Cart/Cart";
import CheckoutPage from "./pages/Cart/Checkout";
import OrderConfirmationPage from "./pages/Cart/OrderConfirmation";
import DashboardPage from "./pages/Dashboard/DashboardPage";
import FormPage from "./pages/Form/FormPage";
import GrowthChartPage from "./pages/GrowthChart/GrowthChart";
import SaveGrowthChartPage from "./pages/GrowthChart/Save/Save";
import { LastLocationProvider } from "react-router-last-location";
import Maintenance from "./pages/Maintenance/Maintenance";
import MyOrdersPage from "./pages/MyOrders/MyOrders";
import MyOrdersDetailPage from "./pages/MyOrders/MyOrdersDetail/MyOrdersDetail";
import PayPalOrderConfirmationPage from "./pages/PayPalOrderConfirmation/PayPalOrderConfirmationPage";
import CreateGuestUserPage from "./pages/GuestUser/CreateGuestUserPage/CreateGuestUserPage";
import GuestUserPage from "./pages/GuestUser/GuestUsersPage";
import UpdateGuestUserPage from "./pages/GuestUser/UpdateGuestUserPage/UpdateGuestUserPage";
import StorePage from "./pages/Store/Store";
import GiftCardsPage from "./pages/GiftCards/GiftCards";
import ManageChildrenPage from "./pages/User/ManageChildrenPage/ManageChildrenPage";
import ProfilePage from "./pages/User/ProfilePage/ProfilePage";
import PurchaseHistoryPage from "./pages/User/PurchaseHistory/PurchaseHistory";
import SaveChildPage from "./pages/User/SaveChildPage/SaveChildPage";
import { AppState, sagaMiddleware } from "./store";
import { BaseAction } from "./store/actionTypes";
import { saveDeepLinkInfo } from "./store/ducks/guesUsers";
import { getGuestUserInfo } from "./store/sagas/guestUser";
import { initMarketingTools } from "./utils/marketing";
import { User } from './interfaces';

interface Props {
  access_token?: string;
  babies_count?: number;
  user_level?: number;
  saveDeepLinkInfoToStore: (payload: DeepLinkInterface) => void;
  user: User;
}

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    initMarketingTools();
  }, [pathname]);

  return null;
};

function GetQueryParams({ saveDeepLinkInfo }: { saveDeepLinkInfo: any }) {
  const query = new URLSearchParams(useLocation().search);
  const invitation_code = query.get("invitation_code");
  const users_exists = query.get("users_exists");

  if (invitation_code) {
    sagaMiddleware.run<any>(
      getGuestUserInfo,
      invitation_code,
      (err: string, data: any) => {
        if (!!err) {
          console.error('Guest user not found.');

          return;
        }

        saveDeepLinkInfo({
          invitation_code,
          users_exists,
          additional_data: data,
        });
      }
    );
  }

  return null;
}

const AppRoutes: React.FC<Props> = ({
  access_token,
  babies_count,
  user_level,
  saveDeepLinkInfoToStore,
  user,
}) => {
  const isLogged = useMemo(() => !!access_token, [access_token]);

  const isLoggedHasBabyNotLevel3 = useMemo(
    () => !!access_token && !!babies_count && user_level !== 3,
    [access_token, babies_count, user_level]
  );

  const isLoggedNotLevel3 = useMemo(() => !!access_token && user_level !== 3, [
    access_token,
    user_level,
  ]);

  const redirectOn404 = useMemo(() => (isLogged ? "/" : "/signin"), [isLogged]);
  const redirectOnAccess = useMemo(
    () => (user_level === 3 ? "/store" : !!user.guest_user ? "/" : "/step-2"),
    [user_level, user]
  );

  /**
   * When we have a protected route we need to put "access_token &&" before the Route definition
   */
  return (
    <Router>
      <LastLocationProvider>
        <ScrollToTop />
      <GetQueryParams saveDeepLinkInfo={saveDeepLinkInfoToStore} />
        <Switch>
          {isLoggedHasBabyNotLevel3 && (
            <Route path="/" exact component={DashboardPage} />
          )}
          {isLoggedHasBabyNotLevel3 && (
            <Route
              path="/order-confirmation"
              exact
              component={PayPalOrderConfirmationPage}
            />
          )}
          {isLogged && !babies_count && (
            <Route path="/step-2" exact component={StepTwo} />
          )}
          {isLogged && <Route path="/step-3" exact component={StepThree} />}
          {isLoggedHasBabyNotLevel3 && (
            <Route path="/milestones" exact component={MilestonesPage} />
          )}
          {isLoggedHasBabyNotLevel3 && (
            <Route path="/babypages" exact component={BabyPagesPage} />
          )}
          {isLoggedHasBabyNotLevel3 && (
            <Route path="/babypages/save" exact component={SaveBabyPagesPage} />
          )}
          {isLoggedHasBabyNotLevel3 && (
            <Route
              path="/babypages/after-save"
              exact
              component={AfterSavePage}
            />
          )}
          {isLoggedHasBabyNotLevel3 && (
            <Route path="/books" exact component={BooksPage} />
          )}
          {isLoggedHasBabyNotLevel3 && (
            <Route path="/books/save" exact component={SaveBooksPage} />
          )}
          {isLoggedHasBabyNotLevel3 && (
            <Route path="/growth-chart" exact component={GrowthChartPage} />
          )}
          {isLoggedHasBabyNotLevel3 && (
            <Route
              path="/growth-chart/save"
              exact
              component={SaveGrowthChartPage}
            />
          )}
          {isLogged && (
            <Route path="/cart/:product?/:type?" exact component={CartPage} />
          )}
          {isLogged && (
            <Route path="/checkout" exact component={CheckoutPage} />
          )}
          {isLogged && (
            <Route
              path="/order-confirmation"
              exact
              component={OrderConfirmationPage}
            />
          )}
          {isLogged && (
            <Route path="/my-orders" exact component={MyOrdersPage} />
          )}
          {isLogged && (
            <Route path="/my-orders/:id" exact component={MyOrdersDetailPage} />
          )}
          {isLogged && <Route path="/profile" exact component={ProfilePage} />}
          {isLogged && <Route path="/store" exact component={StorePage} />}
          {isLogged && <Route path="/giftcards" exact component={GiftCardsPage} />}
          {isLogged && (
            <Route
              path="/purchase-history"
              exact
              component={PurchaseHistoryPage}
            />
          )}
        {isLogged && (
          <Route path='/guest-users/create' exact component={CreateGuestUserPage} />
        )}
        {isLogged && (
          <Route
            path='/guest-users'
            component={GuestUserPage}
            exact
          />
        )}
        {isLogged && (
          <Route
            path="/guest-users/:id"
            exact
            component={UpdateGuestUserPage}
          />
        )}
        {isLoggedNotLevel3 && (
          <Route path="/save-child" exact component={SaveChildPage} />
        )}
        {isLoggedNotLevel3 && (
          <Route path="/manage-children" exact component={ManageChildrenPage} />
        )}
        {isLogged && <Route path="/about" exact component={AboutPage} />}
        {isLogged && <Route path="/form" exact component={FormPage} />}
        <Route path="/maintenance" exact component={Maintenance} />
        <Route path="/signin-token" exact component={SignInToken} />
        <Route path="/signin" exact component={SignInPage} />
        <Route path="/reset-password" exact component={ResetPassword} />

        <Route path="/admin" component={AdminRoutes} />

          {isLogged && (babies_count === 0 || user_level === 3) && (
            <Route render={() => <Redirect to={redirectOnAccess} />} />
          )}

          <Route render={() => <Redirect to={redirectOn404} />} />
        </Switch>
      </LastLocationProvider>
    </Router>
  );
};

const mapStateToProps = (state: AppState) => ({
  access_token: state.user.access_token,
  babies_count: state.user.babies_count,
  user_level: state.user.user_level,
  user: state.user,
});

const mapDispatchToProps = (dispatch: Dispatch<BaseAction>) => ({
  saveDeepLinkInfoToStore: (payload: DeepLinkInterface) =>
    dispatch(saveDeepLinkInfo(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);
