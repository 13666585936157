import { isEqual } from "lodash";
import { call, put, select } from "redux-saga/effects";
import rootSaga from ".";
import { AppState, sagaMiddleware } from "..";
import api from "../../api";
import { clientId, clientSecret } from "../../env";
import { UserListInterface, User } from "../../interfaces";
import { PurchaseHistoryResponse } from "../../interfaces/purchase";
import { UserSettings } from "../../pages/User/ProfilePage/ProfilePage";
import { parseRequestError } from "../../utils/error";
import { changeUser, logoutUser } from "../ducks/user";
import { trackAction } from "./../../utils/marketing";
import { createCustomer } from "./customer";
import { selectBaby } from "../ducks/baby";

/**
 * Authenticate user with a callback (To manage loading states)
 */
export function* authUser(
  body: { email: string; password: string },
  callback: (response: any | null, error?: string | null) => void
) {
  try {
    const response = yield call(api.post, "api/login", {
      username: body.email,
      password: body.password,
      grant_type: "password",
      lang: "en",
      operative_system: "web",
      token_device: "",
      client_id: clientId,
      client_secret: clientSecret,
    });

    if (response.data && response.data.code === 200) {
      yield put(
        changeUser({
          ...response.data.data,
        })
      );

      if (!response.data.data.stripe_id) {
        yield call(createCustomer);
      }
    }
    callback(response.data.data);
  } catch (error) {
    callback(null, parseRequestError(error));
  }
}

export function* resetPassword(
  body: { email: string, password: string, token: string }, 
  callback: (response: any | null, error?: string | null) => void
  )  {
    try {
      const response = yield call(api.post, "api/reset-password", 
      { 
        ...body,
        password_confirmation: body.password,
        lang: "en", 
        client_id: clientId,
        client_secret: clientSecret
      }
    );
    
      callback(response.data.data);
    } catch(error) {
      callback(null, parseRequestError(error));
    }
   
  }
 

/**
 * Authenticate user with Apple and with a callback (To manage loading states)
 */
 export function* authUserApple(
  body: any,
  callback: (response: any | null, error?: string | null) => void
) {
  try {
    const response = yield call(api.post, "api/apple-sign-in", {...body, source: "web"});

    if (!response.data.data.stripe_id) {
      yield call(createCustomer);
    }

    if (response.data.code === 201) {
      trackAction("CompleteAppleRegistration", {
        google: {
          event: "sign_up",
          method: "apple",
        },
      });
    }

    yield put(
      changeUser({
        ...response.data.data,
      })
    );

    callback(response.data.data);
  } catch (error) {
    callback(null, parseRequestError(error));
  }
}

/**
 * Authenticate user with facebook and with a callback (To manage loading states)
 */
export function* authUserFacebook(
  body: any,
  callback: (response: any | null, error?: string | null) => void
) {
  try {
    const response = yield call(api.post, "api/facebook-sign-in", {...body, source: "web"});

    if (!response.data.data.stripe_id) {
      yield call(createCustomer);
    }

    if (response.data.code === 201) {
      trackAction("CompleteFacebookRegistration", {
        google: {
          event: "sign_up",
          method: "facebook",
        },
      });
    }

    yield put(
      changeUser({
        ...response.data.data,
      })
    );

    callback(response.data.data);
  } catch (error) {
    callback(null, parseRequestError(error));
  }
}

/**
 * Authenticate user with facebook and with a callback (To manage loading states)
 */
export function* userFacebookExists(
  body: any,
  callback: (response: any | null, error?: string | null) => void
) {
  try {
    const response = yield call(api.post, "api/facebook-id-exists", body);

    if (response.data && response.data.code === 200) {
      if (!response.data.data.stripe_id) {
        yield call(createCustomer);
      }

      yield put(
        changeUser({
          ...response.data.data,
        })
      );
    }
    callback(response.data.data);
  } catch (error) {
    callback(null, parseRequestError(error));
  }
}

/**
 * Sign Up user with a callback (To manage loading states)
 */
export function* signUpUser(
  body: {
    firstName: string;
    lastName: string;
    email: string;
    signupEmail?: string;
    cfEmail: string;
    password: string;
    invitationCode?: string;
  },
  callback: (response: any | null, error?: string | null) => void
) {
  try {
    const requestBody: any = {
      first_name: body.firstName,
      last_name: body.lastName,
      username: body.email ?? body.signupEmail,
      password: body.password,
      password_confirmation: body.password,
      grant_type: "password",
      lang: "en",
      operative_system: "web",
      token_device: "",
      client_id: clientId,
      client_secret: clientSecret,
      source: "web"
    }

    if (!!body.invitationCode) {
      requestBody.invitation_code = body.invitationCode;
    }

    const response = yield call(api.post, "api/signup", requestBody);

    if (response.data && response.data.code === 201) {
      if (!response.data.data.stripe_id && !requestBody.invitation_code) {
        yield call(createCustomer);
      }

      trackAction("CompleteRegistration", {
        google: {
          event: "sign_up",
          method: "email and password",
        },
        pinterest: {
          event: "signup",
          method: "email and password"
        }
      });

      yield put(
        changeUser({
          ...response.data.data,
        })
      );

      const user = yield select((state: AppState) => state.user);

      if (!user.id) return;

      if (!!body.invitationCode) {
        const response = yield call(switchAccount, {
          mainUserId: user.main_user.id,
          hostUserId: user.id,
        });
      }
    }

    callback(response.data.data);
  } catch (error) {
    callback(null, parseRequestError(error));
  }
}

/**
 * Send an forget password email with a callback (To manage loading states)
 */
export function* forgetPasswordUser(
  body: {
    email: string;
  },
  callback: (response: any | null, error?: string | null) => void
) {
  try {
    const response = yield call(api.post, "api/reset-password-link", {
      ...body,
      lang: "en",
      client_id: clientId,
      client_secret: clientSecret,
    });

    callback(response.data.message);
  } catch (error) {
    callback(null, parseRequestError(error));
  }
}

/**
 * Get user authenticated
 */
export function* getAuthenticatedUser( callback?: () => void) {
  const user = yield select((state: AppState) => state.user);

  if (!user.access_token) return;

  try {
    const response = yield call(api.get, "api/authenticated-user", {
      headers: {
        Authorization: user.access_token,
      },
    });
    let stripeId : any ;
    if (response.data && response.data.code === 200) {
      if (!response.data.data.stripe_id) {
        stripeId = yield call(createCustomer);
      }

      if (!isEqual(user, { ...user, ...response.data.data })) {
        yield put(
          changeUser({
            ...response.data.data,
            stripe_id: !response.data.data.stripe_id ? stripeId : response.data.data.stripe_id
          })
        );
      }
    } else if (response.data && response.data.code === 401) {
      yield put(logoutUser());
    }
    if(callback) {
      callback();
    }
    
  } catch (error) {
    if (
      !!error &&
      !!error.response &&
      !!error.response.data &&
      error.response.data.code === 401
    ) {
      yield put(logoutUser());
    }
  }
}

/**
 * Get user authenticated
 */
export function* getAuthenticatedUserToken(
  token: string,
  callback: (error?: string, description?: any) => void
) {
  try {
    const response = yield call(api.get, "api/authenticated-user", {
      headers: {
        Authorization: token,
      },
    });

    if (response.data && response.data.code === 200) {
      yield put(
        changeUser({
          ...response.data.data,
          access_token: token,
        })
      );
      
      if (!response.data.data.stripe_id) {
        yield call(createCustomer);
      }

      callback(undefined, response.data.data);
    } else {
      callback("Invalid token");
      yield put(logoutUser());
    }
  } catch (error) {
    callback("Invalid token");
    yield put(logoutUser());
  }
}

/**
 * Remove Account babylist source
 */
export function* removeSource(
  settings: UserSettings,
  callback: (err?: string, description?: string) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    const response = yield call(
      api.put,
      "api/update-account?lang=en",
      settings,
      {
        headers: {
          Authorization: accessToken,
        },
      }
    );

    if (response.data && response.data.code === 200) {
      yield put(
        changeUser({
          ...response.data.data,
        })
      );
    }

    callback(undefined, "User updated");
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * Update Account
 */
export function* updateAccount(
  settings: UserSettings,
  callback: (err?: string, description?: string) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    const response = yield call(
      api.put,
      "api/update-account?lang=en",
      settings,
      {
        headers: {
          Authorization: accessToken,
        },
      }
    );

    if (response.data && response.data.code === 200) {
      yield put(
        changeUser({
          ...response.data.data,
        })
      );
    }

    callback(undefined, "User updated");
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * Update Password
 */
export function* updatePassword(
  body: {
    current_password: string;
    password: string;
    password_confirmation: string;
  },
  callback: (err?: string, description?: string) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    const response = yield call(api.put, "api/change-password?lang=en", body, {
      headers: {
        Authorization: accessToken,
      },
    });

    if (response.data && !!response.data.message) {
      callback(undefined, response.data.message);
    } else {
      throw new Error("Error updating password");
    }
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * Deactivate Account
 */
export function* deactivateAccount(callback: (err?: string) => void) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    const response = yield call(api.delete, "api/cancel-account?lang=en", {
      headers: {
        Authorization: accessToken,
      },
    });

    if (response.data && response.data.code === 200) {
      yield put(logoutUser());
    }

    callback(undefined);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * Unsubscribe user from PLUS
 */
export function* unsubscribePlan(
  body: { planId: string; subsId: string },
  callback: (err?: string) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;
  try {
    const response = yield call(
      api.put,
      `api/stripe/cancel-subscription/${body.subsId}?lang=en`,
      {
        plan_id: body.planId,
      },
      {
        headers: {
          Authorization: accessToken,
        },
      }
    );

    if (response.data && response.data.code === 200) {
      const trackActionData = {
        facebook: body.planId,
        amplitude: body.planId,
        pinterest: body.planId,
        google: {
          event: "cancel_subscription",
          details: `User canceled subscription: ${body.planId}`,
        },
      };

      if (body.planId === "monthly") {
        trackAction("Canceled Monthly Subscription", trackActionData, true);
      } else if (body.planId === "three month") {
        trackAction(
          "Canceled Three Months Subscription",
          trackActionData,
          true
        );
      } else if (body.planId === "one year") {
        trackAction("Canceled Annual Subscription", trackActionData, true);
      }
      sagaMiddleware.run<any>(getAuthenticatedUser);
      callback(undefined);
    } else {
      throw new Error("Error cancelling subscription");
    }
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * getPurchaseHistory
 */
export function* purchaseHistory(
  after: string | null,
  callback: (err?: string, data?: PurchaseHistoryResponse) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    const response = yield call(
      api.get,
      `api/purchase-history/10?lang=en${
        !!after ? `&starting_after=${after}` : ""
      }`,
      {
        headers: {
          Authorization: accessToken,
        },
      }
    );

    if (response.data && response.data.code === 200) {
      callback(undefined, response.data.data);
    } else {
      throw new Error("Error getting purchase history");
    }
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * Change account from guest to normal user
 */
export function* guestToUser(
  id: number,
  callback: (err?: string, data?: any) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    const response = yield call(api.get, `api/change-user-status/${id}`, {
      headers: {
        Authorization: accessToken,
      },
    });

    if (response.data && response.data.code === 200) {
      yield call(rootSaga);
      callback(undefined, response.data.data);
      return;
    }
    callback(undefined);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * Get users for admin panel
 */
export function* getUsers(
  search: string,
  plus: boolean,
  callback: (err?: string, response?: UserListInterface) => void,
  page?: number
) {
  const accessToken = yield select(
    (state: AppState) => state.admin.access_token
  );

  try {
    if (!!search) {
      search = `search=${search}`;
    }

    if (!!page) {
      search += `${!!search ? "&" : ""}page=${page}`;
    }

    const response = yield call(
      api.get,
      `api/admin/users?&lang=en&limit=10&plus=${plus}&${search}`,
      {
        headers: {
          Authorization: accessToken,
        },
      }
    );

    callback(undefined, response.data.data);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * Get users by ID for admin panel
 */
export function* getUserById(
  id: string,
  callback: (err?: string, response?: any) => void,
  admin: boolean = true
) {
  const accessToken = yield select((state: AppState) =>
    !!admin ? state.admin.access_token : state.user.access_token
  );

  try {
    const response = yield call(api.get, `api/get-user/${id}?&lang=en`, {
      headers: {
        Authorization: accessToken,
      },
    });

    callback(undefined, response.data.data);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

export function* getFirstViewOfBabyPageContent(
  callback: (err?: string, response?: any) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    const response = yield call(
      api.get,
      "api/first-view-of-baby-page-content",
      {
        headers: {
          Authorization: accessToken,
        },
      }
    );

    callback(undefined, response.data.data.first_view_of_baby_page_content);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * getAlexandersOrders
 */
export function* getAlexandersOrders(
    callback: (err?: string, data?: PurchaseHistoryResponse) => void
) {
  const accessToken = yield select(
      (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    const response = yield call(
        api.get,
        "api/alexanders-orders?lang=en",
        {
          headers: {
            Authorization: accessToken
          }
        }
    );

    if (response.data && response.data.code === 200) {
      callback(undefined, response.data.data);
    } else {
      throw new Error("Error getting Alexander's orders");
    }
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * getAlexandersOrders
 */
export function* getAlexandersOrdersDetail(
  id: string,
  callback: (err?: string, data?: PurchaseHistoryResponse) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    const response = yield call(
      api.get,
      `api/alexanders-orders/${id}?lang=en`,
      {
        headers: {
          Authorization: accessToken
        }
      }
    );

    if (response.data && response.data.code === 200) {
      callback(undefined, response.data.data);
    } else {
      throw new Error("Error getting Alexander's orders");
    }
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * contactSupportAlexandersOrders
 */
export function* contactSupportAlexandersOrders(
  data: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string;
    message: string;
  },
  callback: (err?: string, data?: PurchaseHistoryResponse) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    const response = yield call(
      api.post,
      `api/send-support-email?lang=en`,
      {
        ...data,
        lang: "en",
        grant_type: "password",
        client_id: clientId,
        client_secret: clientSecret
      },
      {
        headers: {
          Authorization: accessToken
        }
      }
    );

    if (response.data && response.data.code === 200) {
      callback(undefined, response.data.data);
    } else {
      throw new Error("Error sending message");
    }
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * Set PayPal Time
 */
export function* setPayPalTime(
  callback: (err?: string) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    const response = yield call(
      api.put,
      "api/users/pay-pal-transaction-time?lang=en",
      null,
      {
        headers: {
          Authorization: accessToken
        }
      }
    );

    if (response.data && response.data.code === 200) {
      throw new Error(response.data.message);
    }

    callback(undefined);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
   * sendWelcomeEmail
   */
  export function* sendWelcomeEmail(
    callback: (err?: string) => void
  ) {
    const accessToken = yield select(
      (state: AppState) => state.user.access_token
    );
  
    if (!accessToken) return;
  
    try {
      const response = yield call(
        api.post,
        `api/welcome-email?lang=en`,
        null,
        {
          headers: {
            Authorization: accessToken
          }
        }
      );
  
      if (response.data && response.data.code !== 200) {
        throw new Error(response.data.message);
      }
  
      callback(undefined);
    } catch (error) {
      callback(parseRequestError(error));
    }
  }

/**
 * Log In as guest user
 */
export function* switchAccount(
  body: {
    mainUserId: string;
    hostUserId: string;
  },
  callback?: (error: string | null, user? : User) => void
) {
  const user = yield select((state: AppState) => state.user);

  if (!user.access_token) return;

  try {
    const response = yield call(
      api.post,
      "api/guest-users/login",
      {
        grant_type: "guest_user",
        client_id: clientId,
        client_secret: clientSecret,
        lang: "en",
        token_device: "",
        operative_system: "web",
        main_user_id: body.mainUserId,
        host_user_id: body.hostUserId,
      },
      {
        headers: {
          Authorization: user.access_token,
        },
      }
    );

    if (response.data && response.data.code === 200) {
      // if (!response.data.data.stripe_id) {
      //   yield call(createCustomer);
      // }
       
      yield put(
        changeUser({
          ...response.data.data,
        })
      );
      yield put(selectBaby(0))
    }
    
    if (callback) {
      callback(null, {...response.data.data});
    }

    return response;
  } catch (error) {
    return parseRequestError(error);
  }
}
