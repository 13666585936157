import { Button, Icon, Modal, notification } from "antd";
import queryString from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";
import BaseLayout from "../../../components/BaseLayout/BaseLayout";
import FloatShareButton from "../../../components/FloatShareButton/FloatShareButton";
import ModalPlus from "../../../components/ModalPlus/ModalPlus";
import ModalSharePlus from "../../../components/ModalSharePlus/ModalSharePlus";
import ShareModal from "../../../components/ShareModal/ShareModal";
import PlusAlert from "../../../components/SaveBabyPage/PlusAlert";
import { StripeProduct } from "../../../interfaces";
import { AppState, sagaMiddleware } from "../../../store";
import { fetchBabyPageById } from "../../../store/sagas/babyPage";
import { FileUploadedS3, savePhotosShareS3 } from "../../../store/sagas/image";
import { downloadBabypage, generateBabypageImg } from "../../../utils/canvas";
//import { trackAction } from '../../../utils/marketing';
import { replaceThumbURL } from "../../../utils/string";
import "./AfterSave.scss";

interface Props {
  isPlus: boolean;
  stripeProducts: StripeProduct[];
}

const AfterSavePage: React.FC<Props> = ({ isPlus, stripeProducts }) => {
  const location = useLocation();
  const history = useHistory();

  const sendToUpgradeToPlus = () => {
    history.push("/cart/subscription/yearly");
  }

  const [babyPage, setBabyPage] = useState<any>(null);
  const [showPlus, setShowPlus] = useState(false);
  const [showSharePlus, setShowSharePlus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [share, setShare] = useState<string>("");
  const [downloadFreeBP, setDownloadFreeBP] = useState(false);
  const [buttonShareDisabled, setButtonShareDisabled] = useState(false);

  if (babyPage?.photos_right) {
    localStorage.setItem("onlyPhotoBPCreated", "true");
  }

  useEffect(() => {
    const id = queryString.parse(location.search).id;

    if (!!downloadFreeBP) {
      setLoading(true);
      downloadBabypage(
        replaceThumbURL(babyPage.thumbnail),
        !!babyPage.thumbnail_content
          ? replaceThumbURL(babyPage.thumbnail_content)
          : "",
        isPlus,
        `${babyPage.title}Babypage`
      )
        .then(() => {
          setLoading(false);
          setDownloadFreeBP(false);
        })
        .catch((err) =>
          Modal.error({
            title: "Error",
            content: "Error generating BabyPage",
          })
        );
    }

    if (!!id) {
      if (babyPage === null) {
        sagaMiddleware.run<any>(
          fetchBabyPageById,
          parseInt(id.toString()),
          (error: any | null, babyPageBD?: any) => {
            if (error !== null || !babyPageBD) {
              history.push("/babypages");
              return;
            }

            setBabyPage(babyPageBD);
          }
        );
      }
    } else {
      history.push("/babypages");
    }
  }, [location.search, history, downloadFreeBP, setLoading, babyPage, isPlus]);

  const createShareImage = useCallback(() => {
    setLoading(true);
    generateBabypageImg(
      isPlus ? replaceThumbURL(babyPage.thumbnail) : babyPage.thumbnail,
      !!babyPage.thumbnail_content
        ? isPlus
          ? replaceThumbURL(babyPage.thumbnail_content)
          : babyPage.thumbnail_content
        : "",
      isPlus
    )
      .then((image) => {
        sagaMiddleware.run<any>(
          savePhotosShareS3,
          image as Blob,
          babyPage.id,
          (err: string | null, response?: FileUploadedS3) => {
            setLoading(false);
            setButtonShareDisabled(false)
            if (err !== null) {
              Modal.error({
                title: "Error",
                content: err,
              });
              return;
            } else if (!!response) {
              if (isPlus) {
                setShare(response.high_url);
              } else {
                setShare(response.thumb_url);
              }
            } else {
              Modal.error({
                title: "Error",
                content: "Error saving Photo",
              });
            }
          }
        );
      })
      .catch((err) => {
        Modal.error({
          title: "Error",
          content: "Error generating BabyPage",
        });
        setLoading(false);
        setButtonShareDisabled(false)
      });
  }, [babyPage, isPlus]);

  const onCloseModalPlus = () => {
    setShowPlus(false);
    setButtonShareDisabled(false)
  }
  if(babyPage) {
    localStorage.setItem("BPcreated", "true");
  }
  

  return (
    <BaseLayout
      title="BabyPage - After Save"
      pageTitle=""
      hideChildMenu
      hideHeaderChildMenu
    >
      {!!babyPage ? (
        <div className="container py-5">
          {!loading ? (
            <div className="row justify-content-center align-items-center my-3">
            <div className="d-flex justify-content-end col-6 col-md-4 py-1 pl-1 pr-0">
              <img
                src={babyPage.thumbnail}
                alt="BabyPage"
                className="img-thumb"
              />
            </div>
            <div className="d-flex justify-content-start col-6 col-md-4 py-1 pr-1 pl-0">
              <img
                src={babyPage.thumbnail_content}
                alt="Content"
                className="elevation-light img-thumb"
              />
            </div>
          </div>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "180px", color:"#658071"}}
            >
              <Icon type="loading" style={{ fontSize: 36 }} />
            </div>
          )}
          
          <div className="row justify-content-center align-items-center my-3">
            <div className="col-10 col-md-8 col-lg-4 my-1">
              <Button
                type="primary"
                ghost
                shape="round"
                className="w-100"
                onClick={() =>
                  history.push(`/babypages/save?id=${babyPage.id}`)
                }
              >
                Edit your babypage
              </Button>
            </div>
            <div className="col-10 col-md-8 col-lg-4 my-1">
              <Button
                type="primary"
                ghost
                shape="round"
                className="w-100"
                onClick={isPlus ? createShareImage : () => { setShowPlus(true) }}
              >
                Share your babypage
              </Button>
              {!isPlus &&  (
                  <PlusAlert aftersave={true} />
                )}
            </div>
            <div className="col-10 col-md-8 col-lg-4 my-1">
              <Button
                type="primary"
                ghost
                shape="round"
                className="w-100 mobile-text-small"
                disabled={loading}
                onClick={() => {
                  if (isPlus) {
                    setLoading(true);

                    downloadBabypage(
                      replaceThumbURL(babyPage.thumbnail),
                      !!babyPage.thumbnail_content
                        ? replaceThumbURL(babyPage.thumbnail_content)
                        : "",
                      true,
                      `${babyPage.title}Babypage`
                    )
                      .then(() => {
                        setLoading(false);
                      })
                      .catch((err) =>
                        Modal.error({
                          title: "Error",
                          content: "Error generating BabyPage",
                        })
                      );
                  } else {
                    setShowPlus(true);
                  }
                }}
              >
                Download high res babypage
                
              </Button>
              {!isPlus &&  (
                  <PlusAlert aftersave={true} />
                )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <Icon type="loading" style={{ fontSize: 36 }} />
        </div>
      )}
      <ModalPlus
        visible={showPlus}
        onClose={() => onCloseModalPlus()}
        afterSaveBPMsg
      />
      <ModalSharePlus
        visible={showSharePlus}
        onClose={() => {
          setShowSharePlus(false)
          notification.success({
            message: "Success",
            description: "The BabyPage link was copied successfully!"
          });
}}
      />
      <ShareModal
        visible={share !== ""}
        onClose={() => setShare("")}
        image={share}
        setShowSharePlus={setShowSharePlus}
        isPlus={isPlus}
      />
      <FloatShareButton
        type="primary"
        shape="circle"
        icon="share-alt"
        position="top-right-banner"
        unFixed
        onClick={() => {
          setButtonShareDisabled(true)
          if(isPlus) {
            createShareImage()
          } else {
            setShowPlus(true)
          }
         
        }}
        disabled={buttonShareDisabled}
      />
    </BaseLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  isPlus: !!state.user.user_level && state.user.user_level === 1,
  products: state.product.products,
  stripeProducts: state.stripeProduct,
});

export default connect(mapStateToProps)(AfterSavePage);
