import { Icon } from 'antd';
import moment from 'moment';
import React, {useMemo} from 'react';
import TruncateMarkup from 'react-truncate-markup';
import { BabyPage } from '../../interfaces';
import './BabyPageItem.scss';

interface Props {
  babypage: BabyPage;
  setSelectedBabypages: React.Dispatch<React.SetStateAction<BabyPage[] | []>>;
  selectedBabypages: BabyPage[] | undefined;
}

const BabyPageItem: React.FC<Props> = ({
  babypage,
  setSelectedBabypages,
  selectedBabypages,
}) => {
  const isSelected = !!selectedBabypages
    ? !!selectedBabypages.find(selected => selected.id === babypage.id)
    : false;

    const title = useMemo(() => {
      if( babypage.event === "Custom Page" && babypage.title === "" ) {
        return (
        <>
          <h1 className="title">{babypage.event}</h1>
        </>
        )
      }
      else {
        return (
        <>
          <h1 className="title">{babypage.title}</h1>
        </>
        )
      }
    }, [babypage])
    const cursorClass = selectedBabypages!.length >= 100 && !isSelected ? "forbidden" : ""

  return (
    <>
      {!!selectedBabypages && (
        <div
          className={`col-6 col-sm-4 col-md-4 col-lg-3 babypage-item-preview ${cursorClass}`}
          onClick={() => {
            if (isSelected) {
              const updatedBabypages = selectedBabypages.filter(
                item => item.id !== babypage.id
              );
              setSelectedBabypages(updatedBabypages);
            } else {
              setSelectedBabypages(state => {
                let newState = state.length === 100 ? [...state] : [...state, babypage] 
                return [...newState]
              });
            }
          }}
        >
          <img
            src={babypage.thumbnail}
            alt={babypage.title}
            className={`babypage-image ${isSelected ? 'selected-cover' : ''}`}
          />
          <div className='babypage-content'>
              {title}     
            {!!babypage.subtitle && (
              <p className='subtitle'>{babypage.subtitle}</p>
            )}
          </div>
          {isSelected && (
            <Icon type='check-circle' theme='filled' style={{ fontSize: 60 }} />
          )}
        </div>
      )}
    </>
  );
};
export default BabyPageItem;
