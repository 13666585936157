import { Card, Carousel, Col, Descriptions, Empty, Icon, notification, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import moment from "moment";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import TruncateMarkup from "react-truncate-markup";
import { UsersListItem } from "../../../../interfaces";
import { sagaMiddleware } from "../../../../store";
import { getUserById } from "../../../../store/sagas/user";

const AdminDetailUsersPage: React.FC = () => {
  const history = useHistory();

  const [user, setUser] = useState<UsersListItem>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const id: any = queryString.parse(history.location.search)?.id;

    if (!!id && !!id.toString()) {
      // Get By Id
      setLoading(true);

      sagaMiddleware.run<any>(
        getUserById,
        id,
        (err?: string, response?: any) => {
          setLoading(false);

          if (!!err) {
            notification.error({
              message: "Error",
              description: err
            });

            history.push("/admin/users");
            return;
          } else if (!!response) {
            try {
              setUser(response);
            } catch (error) {
              notification.error({
                message: "Error",
                description:
                  "Failed to load user detail, please try again later"
              });

              history.push("/admin/users");
              return;
            }
          }
        }
      );
    } else {
      history.push("/admin/users");
      return;
    }
  }, [history]);

  return (
    <div className="m-5">
      {loading ? (
        <div className="d-table mx-auto my-3">
          <Icon type="loading" style={{ fontSize: 36 }} />
        </div>
      ) : (
        <div className="my-2">
          <h1 className="title mb-4">User Information</h1>
          <Descriptions bordered>
            <Descriptions.Item label="Firstname">
              {user?.first_name}
            </Descriptions.Item>
            <Descriptions.Item label="Lastname">
              {user?.last_name}
            </Descriptions.Item>
            <Descriptions.Item label="Email">{user?.email}</Descriptions.Item>
            <Descriptions.Item label="Member since">
              {moment(user?.created_at).format("MMM[, ]Do[, ]YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label="Last login">
              {moment(user?.last_login).format("MMM[, ]Do[, ]YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label="Membership status">
              {user?.user_level === 1 ? "PLUS user" : "Free user"}
            </Descriptions.Item>
            <Descriptions.Item label="N° Babies">
              {user?.total_babies}
            </Descriptions.Item>
            <Descriptions.Item label="N° Babypages">
              {user?.total_babypages}
            </Descriptions.Item>
            <Descriptions.Item label="N° Books">
              {user?.total_books}
            </Descriptions.Item>
          </Descriptions>
          <div className="mt-4">
            <h1 className="title">Registered Babies</h1>
            {!!user?.babies.length ? (
              <div>
                <Row gutter={16}>
                  {user.babies.map(baby => (
                    <Col span={6} key={baby.id}>
                      <Card
                        style={{ width: 300, marginTop: 16 }}
                        loading={loading}
                      >
                        <Meta
                          avatar={
                            <Icon
                              type={baby.gender === "Boy" ? "man" : "woman"}
                              style={{ fontSize: 28 }}
                            />
                          }
                          title={!baby.name ? baby.name : "Unknown"}
                          description={`Born on ${moment(
                            baby.birth_date
                          ).format("MMM[, ]Do[, ]YYYY")}`}
                        />
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            ) : (
              <div className="my-5">
                <Empty description="No Babies registered" />
              </div>
            )}
          </div>
          <div className="d-flex justify-content-center">
            <div className="my-4 col-4">
              <h1 className="title">Created BabyPages</h1>
              {!!user?.babyPages.length ? (
                <Card className="d-block dashboard-card">
                  <Carousel
                    slidesToShow={1}
                    draggable={true}
                    autoplay
                    autoplaySpeed={3000}
                    infinite={true}
                    dots={false}
                    slidesToScroll={1}
                    speed={1000}
                  >
                    {user.babyPages.map((babypage, index: number) => (
                      <div key={index}>
                        <div style={{ position: "relative" }}>
                          <img
                            className="carousel-img"
                            src={babypage.thumbnail}
                            alt={babypage.title}
                          />
                          <div className="babypage-detail">
                            <TruncateMarkup lines={2}>
                              <h3 className="babypage-title">
                                {babypage.event === "Custom Page"
                                  ? babypage.event
                                  : babypage.title}
                              </h3>
                            </TruncateMarkup>
                            {!!babypage.subtitle && (
                              <p className="babypage-subtitle">
                                {babypage.subtitle}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </Carousel>
                </Card>
              ) : (
                <div className="my-5">
                  <Empty description="No BabyPages registered" />
                </div>
              )}
            </div>
            <div className="my-4 col-4">
              <h1 className="title">Created Books</h1>
              {!!user?.books.length ? (
                <Card className="d-block dashboard-card">
                  <Carousel
                    slidesToShow={1}
                    draggable={true}
                    autoplay
                    autoplaySpeed={3000}
                    infinite={true}
                    dots={false}
                    slidesToScroll={1}
                    speed={1000}
                  >
                    {user.books.map((book, index: number) => (
                      <div key={index}>
                        <div style={{ position: "relative" }}>
                          <img
                            className="carousel-img"
                            src={book.thumbnail_with_url}
                            alt={book.title}
                          />
                        </div>
                      </div>
                    ))}
                  </Carousel>
                </Card>
              ) : (
                <div className="my-5">
                  <Empty description="No Books registered" />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminDetailUsersPage;
