export const fontColors: string[] = [
  "#000000",
  "#EF6F7A",
  "#F08B83",
  "#13668B",
  "#F0B754",
  "#EFB653",
  "#EF662F",
  "#9B0B0E",
  "#500A0A",
  "#A1CB5E"
];

export const chartColors: string[] = [
  "rgb(35,32,32)",
  "rgb(59,76,105)",
  "#658071",
  "rgb(88,88,88)",
  "rgb(239,111,122)",
  "rgb(239,111,122)",
  "rgb(240,183,84)",
  "rgb(253,238,52)",
  "rgb(155,11,14)",
  "rgb(239,102,47)"
];
