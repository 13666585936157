import { Modal } from "antd";
import React, { PropsWithChildren } from "react";

interface Props {
    open: boolean;
    onVisibleChange?: (value: boolean | React.MouseEvent<HTMLElement, MouseEvent>) => void;
    variant?: 'modal' | 'popover'
}

const MessageTip = ({open, onVisibleChange, children}: PropsWithChildren<Props> ): JSX.Element | null  => {
    
        return <Modal  
        centered
        visible={open}
        footer={null}
        closable
        onCancel={(_) => onVisibleChange!(false)}
        
      >
           <>{children}</>
      </Modal>

}

export default MessageTip;