import { Button, Modal, Spin } from "antd";
import React, { useState,  useEffect  } from "react";
import { connect } from "react-redux";
import { AppState } from "../../store";
import { replaceThumbURL } from "../../utils/string";
import ModalPlus from "../ModalPlus/ModalPlus";
import "./BookModal.scss";
import logo from "../../assets/img/logo_2.png"
import { useHistory } from "react-router";

interface Props {
  leftPage: string;
  rightPage: string;
  visible: boolean | undefined;
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  isPlus: boolean;
  pagesLimit: number;
  onClose: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  useOldBabypages: boolean;
}

const BookModal: React.FC<Props> = ({
  leftPage,
  rightPage,
  visible,
  currentIndex,
  setCurrentIndex,
  isPlus,
  pagesLimit,
  onClose,
  useOldBabypages
}) => {
  const [zoomed, setZoomed] = useState(false);
  const [showPlus, setShowPlus] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [loadedRight, setLoadedRight] = useState(false);
  // This state is to check whether the user when through all photos (loaded all photos) or not
  const [gotToEnd, setGotToEnd] = useState(false);
  const history = useHistory();
  const sendToUpgradeToPlus = () => {  
    history.push("/cart/subscription/yearly");
  }

  const onLoad = () => {
    if(!gotToEnd) {
      setTimeout(()=> {
        setLoaded(true);
      }, 250)
    } else {
      setLoaded(true);
    }
  }

  const onLoadRight = () => {
    if(!gotToEnd) {
      setTimeout(()=> {
        setLoadedRight(true);
      }, 250)
    } else {
      setLoadedRight(true);
    }
  }

 
  useEffect(() => {

    if(currentIndex >= pagesLimit) {
      setGotToEnd(true);
    }

    if(!gotToEnd) {
      setLoaded(false);
      setLoadedRight(false);
    } else {
      setLoaded(true);
      setLoadedRight(true);
    }
    
  }, [currentIndex])


  const images =  (
    <>
      <img 
        className={zoomed ? "modal-image zoomed" : "modal-image"} 
        src={leftPage} alt="BabyPage" 
        onLoad={() => {
          onLoad();
        }} 
        style={loaded ? {} : { display: 'none' }} 
      />
      {!loaded  &&
        <img src={logo} className="modal-image" ></img>
      }
      <img 
        className={zoomed ? "modal-image zoomed" : "modal-image"}
        src={rightPage} alt="Content" 
        onLoad={() => { 
          onLoadRight();
        }}
        style={loadedRight ? {} : { display: 'none' }} 
      />
      {!loadedRight && 
        <img src={logo}  className="modal-image" ></img>
      }
    </>
  );

  return (
    <Modal
      centered
      visible={visible}
      footer={null}
      onCancel={onClose}
      width="auto"
      bodyStyle={{ padding: 0 }}
      closable={false}
    >
      {/* {preLoadImg} */}
      <div className="d-flex justify-content-center align-items-center">
        {images}
      </div>
      <Button.Group className="actions elevation-5">
        <Button
          size="large"
          type="primary"
          icon="arrow-left"
          disabled={currentIndex === 0}
          onClick={() => !useOldBabypages ? setCurrentIndex(currentIndex - 1) : setCurrentIndex(currentIndex - 2)}
        />
        <Button
          size="large"
          type="primary"
          icon={zoomed ? "zoom-out" : "zoom-in"}
          className="mobile-hidden"
          onClick={() => (setZoomed(!zoomed))}
        />
        <Button
          size="large"
          type="primary"
          icon="arrow-right"
          disabled={currentIndex === pagesLimit}
          onClick={() => !useOldBabypages ? setCurrentIndex(currentIndex + 1) : setCurrentIndex(currentIndex + 2)}
        />
      </Button.Group>
      <ModalPlus visible={showPlus} onClose={() => setShowPlus(false)} onSaveBeforeUpgradeToPlus={sendToUpgradeToPlus} />
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => ({
  isPlus: !!state.user.user_level && state.user.user_level === 1
});

export default connect(mapStateToProps)(BookModal);
