import { StripeProduct } from '../../interfaces';
import { BaseAction, GET_STRIPE_PRODUCTS, USER_LOGOUT } from '../actionTypes';

export type StripeProductState = StripeProduct[];

const initialState: StripeProductState = [];

export const stripeProduct = (
  state: StripeProductState = initialState,
  action: BaseAction
): StripeProductState => {
  switch (action.type) {
    case GET_STRIPE_PRODUCTS:
      return [...action.payload];
    case USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
Object.freeze(stripeProduct);

const getStripeProducts = (payload: StripeProduct[]) => ({
  payload,
  type: GET_STRIPE_PRODUCTS,
});
Object.freeze(getStripeProducts);

export { getStripeProducts };
export default stripeProduct;
