import React from "react";

function Thrash() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="15"
      viewBox="0 0 13 15"
    >
      <path
        fill="#658071"
        d="M4.983.9l-.633.665H.55v1.33h11.4v-1.33h-3.8L7.517.9H4.983zM1.415 4.225l.967 8.82c.084.659.623 1.155 1.255 1.155h5.224c.632 0 1.173-.496 1.257-1.16l.967-8.815h-9.67z"
      ></path>
    </svg>
  );
}

export default Thrash;