import axios, { AxiosInstance } from 'axios';
import { BASE_URL, apiVersion } from './env';
import { logoutUser } from "./store/ducks/user";
import  store  from './store/index';

const api: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Accept': apiVersion,
    'Content-Type': 'application/json'
  }
});

api.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if(error.response.data.code === 401 ) {
      store.dispatch(logoutUser())
  }
  return Promise.reject(error);
});

export default api;
