// @ts-nocheck
import queryString from 'query-string';
import { Modal } from 'antd';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { connect } from 'react-redux';
import { User } from '../../interfaces';
import { AppState, sagaMiddleware } from '../../store';
import storkIllustration from '../../assets/img/illustrations/stork-illustration.png';
import { getAuthenticatedUser } from '../../store/sagas/user';
import SaveChild from '../SaveChild/SaveChild';
import "./AddChildInfoModal.scss"

interface Props {
  user: User;
  visible: boolean;
  close: () => void;
  closeAndShowSecondModal: () => void;
  babySelected: Baby;
}

const AddChildInfoModal: React.FC<Props> = ({ visible, close, closeAndShowSecondModal, user, babySelected }) => {
  const location = useLocation();
  const history = useHistory();

  const saveChild = (
    <SaveChild
      id={!!babySelected ? babySelected.id.toString() : null}
      afterSave={() => {
        close();
        
        sagaMiddleware.run<any>(getAuthenticatedUser);

        const query = queryString.parse(location.search);
        history.push(!!query.redirect && query.redirect.toString());
      }}
      hideCustomDivider
      extraInfo
      shortForm
    />
  );

  return (
    <Modal
      visible={visible}
      closable={true}
      onCancel={() => closeAndShowSecondModal()}
      footer={null}
    >
      <div className="row justify-content-center align-items-center add-info-container">
        <img src={storkIllustration} alt="BP Stork" className="AuthImgHeader mb-3 stork-img" />
        
        <h3 className="responsive-sub-title my-1 px-3 mb-3 text">
          Let's finish adding your child's info to complete their profile
        </h3>
        
        {saveChild}

        <span onClick={() => closeAndShowSecondModal()} className="close-link">No thanks, I'll complete it later</span>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => ({
  user: state.user
});

export default connect(mapStateToProps)(AddChildInfoModal);
