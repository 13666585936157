import React from "react";

interface Props {
    className?: string;
    onClick: () => void;
    isSelected: boolean;
}

const CartIcon: React.FC<Props> = ({className, onClick, isSelected}) => {
    const fillColor = isSelected ? "#FFAFA5" : "#909090"
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="16"
      viewBox="0 0 18 16"
      className={className}
      onClick={onClick}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={fillColor} transform="matrix(-1 0 0 1 18 0)">
          <path d="M6.988 13.045a1.398 1.398 0 10.001 2.796 1.398 1.398 0 000-2.796"></path>
          <path d="M14.442 13.045a1.398 1.398 0 10.001 2.796 1.398 1.398 0 000-2.796"></path>
          <path d="M.662 0a.652.652 0 10-.019 1.304h1.244a.32.32 0 01.312.235l2.78 9.45a1.961 1.961 0 001.876 1.403h7.16c.88 0 1.656-.594 1.888-1.442l1.694-6.212a.653.653 0 00-.63-.825H4.259l-.807-2.742A1.635 1.635 0 001.886 0H.662z"></path>
        </g>
      </g>
    </svg>
  );
}

export default CartIcon;
