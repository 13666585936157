import { Col, notification, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FontForm from "../../../../components/FontForm/FontForm";
import { Font } from "../../../../interfaces/font";
import { sagaMiddleware } from "../../../../store";
import { getFontById } from "../../../../store/sagas/font";

const FontDetailPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [fontForm, setFontForm] = useState<undefined | Font>(undefined);
  let { id } = useParams();

  useEffect(() => {
    setLoading(true);

    sagaMiddleware.run<any>(
      getFontById,
      id,
      (err?: string, response?: Font) => {
        setLoading(false);

        if (!!err) {
          notification.error({
            message: "Error",
            description: err
          });

          return;
        }

        setFontForm(response);
      }
    );
  }, [id]);

  return (
    <Row>
      <Col span={14} offset={5} style={{ padding: "5px" }}>
        <div className="m-3">
          <h1 className="title">Font Detail</h1>
          {loading ? (
            <div className="col-12 d-flex justify-content-center">
              <Spin style={{ fontSize: 36, margin: 50 }} />
            </div>
          ) : (
            <>
              {!!fontForm && (
                <FontForm
                  setLoading={setLoading}
                  fontForm={fontForm}
                  setFontForm={setFontForm}
                />
              )}
            </>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default FontDetailPage;
