import { Button, Modal, Avatar } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import logoImg from "../../assets/img/logo_2.png";
import "./ModalCoverPlus.scss";

const { confirm } = Modal;

interface Props {
  visible: boolean;
  onClose: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  bookId: number;
}

const ModalCoverPlus: React.FC<Props> = ({
  visible,
  onClose,
  bookId
}) => {
  const history = useHistory();

  const [showConfirm, setShowConfirm] = useState(false);

  const baseText = 
    `This book has a PLUS cover, which you likely selected during the limited-edition
    design release. If you want to make edits and keep this cover, please upgrade to a 
    PLUS account. If you do not upgrade and wish to edit this book, you will need to 
    select a Free cover design. If you print this book without edits, you can keep 
    the current cover design`;
  
  const confirmText = 
    `Do you want to change the
    current cover for a free one to 
    edit?`;

  const confirm = () => {
    return (
      <Modal
          centered
          visible={showConfirm}
          footer={null}
          onCancel={() =>setShowConfirm(false)}
          width="auto"
        >
          <div>
            <Avatar className="mx-auto d-table" size={75} src={logoImg} alt="PLUS Child" style={{ backgroundColor: "#dcdcdc"}}/>
            <p className="text-center m-1">Are you sure?</p>
            <span className="text-center m-1" style={{"whiteSpace": "pre-line"}}>{confirmText}</span>
          </div>
          <div className="mt-1" style={{"display": "flex"}}>
            <Button
              type="default"
              className="mx-auto d-table"
              onClick={() => setShowConfirm(false)}
            >
              CANCEL
            </Button>

            <Button
              type="default"
              className="mx-auto d-table"
              onClick={() => history.push(`/books/save?id=${bookId}`)}
            >
              EDIT
            </Button>
          </div>
        </Modal>
    );
  };

  return (
    <Modal
      centered
      visible={visible}
      footer={null}
      onCancel={onClose}
      width="auto"
    >
      <div>
        <p className="text-center lineWrap">{baseText}.</p>
        <div className="changedisplay">
          <Button
            type="primary"
            className="mx-auto d-table"
            shape="round"
            onClick={() => history.push("/cart/subscription/yearly")}
          >
            Upgrade to PLUS
          </Button>

          <Button
            type="primary"
            className="mx-auto d-table marginT"
            shape="round"
            onClick={() => setShowConfirm(true)}
          >
            Select a Free Cover
          </Button>
        </div>
        {confirm()}
      </div>
       
    </Modal>
  );
};

export default ModalCoverPlus;
