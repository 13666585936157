import React from "react";

interface Props {
  classes?: string;
}


const Pinterest: React.FC<Props> = ({
  classes
  }) => {
  return (
    <a href="https://www.pinterest.com/gobabypage/"  target="_blank">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 256 256"
        className={classes}
      >
          <path
            fill="#1a1a1a"
            strokeMiterlimit="10"
            d="M15 3C8.373 3 3 8.373 3 15c0 5.084 3.163 9.426 7.627 11.174-.105-.949-.2-2.406.042-3.442.218-.936 1.407-5.965 1.407-5.965s-.359-.719-.359-1.781c0-1.669.967-2.914 2.171-2.914 1.024 0 1.518.769 1.518 1.69 0 1.03-.655 2.569-.994 3.995-.283 1.195.599 2.169 1.777 2.169 2.133 0 3.772-2.249 3.772-5.495 0-2.873-2.064-4.882-5.012-4.882-3.414 0-5.418 2.561-5.418 5.208 0 1.031.397 2.137.893 2.739a.359.359 0 01.083.344c-.091.379-.293 1.194-.333 1.361-.052.22-.174.266-.401.16-1.499-.698-2.436-2.889-2.436-4.649 0-3.785 2.75-7.262 7.929-7.262 4.163 0 7.398 2.966 7.398 6.931 0 4.136-2.608 7.464-6.227 7.464-1.216 0-2.359-.632-2.75-1.378 0 0-.602 2.291-.748 2.853-.271 1.042-1.002 2.349-1.492 3.146C12.57 26.812 13.763 27 15 27c6.627 0 12-5.373 12-12S21.627 3 15 3z"
            fontFamily="none"
            fontSize="none"
            fontWeight="none"
            textAnchor="none"
            transform="scale(8.53333)"
          ></path>
      </svg>
    </a>
  );
}

export default Pinterest;
