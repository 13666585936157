import { call, put } from "redux-saga/effects";
import api from "../../api";
import { apiVersion } from "../../env";
import { changeMaintenance } from "../ducks/maintenance";


export function* getMaintenance(callback?: (error?: string, description?: any) => void) {
    try {
        const response = yield call(api.get, "api/system-status", {
            headers: {
                'Accept': apiVersion
              },
          });

          yield put (changeMaintenance({in_maintenance: !!response.data.data.in_maintenance, body_message: response.data.data.body_message}));
    } catch(error) {
        console.log(error)
    }
}
