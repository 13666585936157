import { Input, InputNumber, Modal, notification } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import cardValidator from 'card-validator';
import { User } from '../../interfaces';
import { AppState, sagaMiddleware } from '../../store';
import {
  addCustomerCard,
  updateCustomerCard
} from '../../store/sagas/customer';

interface Props {
  user: User;
  visible: boolean;
  close: () => void;
}

interface CardForm {
  cardNumber?: number;
  cardNumberInvalid?: boolean;
  cardName?: string;
  cardCVV?: string;
  cardCVVInvalid?: boolean;
  cardMonth?: number;
  cardYear?: number;
  cardValidationCard?: cardValidator.Card | null;
}

const currentMonth = moment().month() + 1;
const currentYear = moment().year();

const AddCardModal: React.FC<Props> = ({ visible, close, user }) => {
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState<CardForm>({});

  const updateDefaultCard = useCallback((card_id: string) => {
    setLoading(true);

    sagaMiddleware.run<any>(updateCustomerCard, card_id, (err?: string) => {
      setLoading(false);
      if (err) {
        Modal.error({
          title: 'Error',
          content: err
        });
      }
    });
  }, []);

  const addCard = useCallback(
    (form: CardForm) => {
      if (form.cardYear === currentYear && form.cardMonth! < currentMonth) {
        Modal.error({
          title: 'Error',
          content: 'Invalid card month'
        });
        return;
      }

      setLoading(true);
      sagaMiddleware.run<any>(
        addCustomerCard,
        {
          number: form.cardNumber!,
          exp_month: form.cardMonth!,
          exp_year: form.cardYear!,
          cvc: form.cardCVV!,
          name: form.cardName!
        },
        (err?: string, card_id?: string) => {
          setLoading(false);

          if (!!err) {
            Modal.error({
              title: 'Error',
              content: err
            });
          } else if (!!card_id) {
            notification.success({
              message: 'Success',
              description: 'Card added successfully'
            });

            if (
              !!user.stripe_subscriptions &&
              !!user.stripe_subscriptions.stripe_id
            ) {
              Modal.confirm({
                title:
                  'Do you want to set this new card as your subscription card?',
                okText: 'Yes',
                okType: 'danger',
                cancelText: 'No',
                width: 300,
                icon: 'warning',
                onOk() {
                  updateDefaultCard(card_id);
                }
              });
            } else {
              updateDefaultCard(card_id);
            }

            close();
          }
        }
      );
    },
    [close, user.stripe_subscriptions, updateDefaultCard]
  );

  useEffect(() => {
    if (!visible) {
      setForm({
        cardNumber: undefined,
        cardNumberInvalid: undefined,
        cardName: undefined,
        cardCVV: undefined,
        cardCVVInvalid: undefined,
        cardMonth: undefined,
        cardYear: undefined
      });
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      onCancel={close}
      onOk={() => addCard(form)}
      okButtonProps={{
        disabled:
          loading ||
          !form.cardNumber ||
          form.cardNumberInvalid ||
          !form.cardName ||
          !form.cardName.length ||
          !form.cardCVV ||
          form.cardCVVInvalid ||
          form.cardCVV.length < 3
      }}
    >
      <div className="row justify-content-center align-items-center">
        <div
          className={`col-12 col-lg-6 my-1${
            form.cardNumberInvalid ? ' has-error' : ''
          }`}
        >
          <label>Card Number</label>
          <InputNumber
            maxLength={16}
            className={`w-100${form.cardNumberInvalid ? ' has-error' : ''}`}
            value={form.cardNumber}
            onChange={cardNumber => {
              const cardValidation = cardValidator.number(
                cardNumber?.toString() ?? ''
              );
              const cardNumberInvalid = !!cardNumber && !cardValidation.isValid;
              const cardValidationCard = cardValidation.card;

              setForm(state => ({
                ...state,
                cardNumber,
                cardNumberInvalid,
                cardValidationCard,
                cardCVVInvalid:
                  !!state.cardCVV &&
                  !cardNumberInvalid &&
                  (!cardValidationCard ||
                    !cardValidationCard.code ||
                    state.cardCVV.length !== cardValidationCard.code.size)
              }));
            }}
          />
          {form.cardNumberInvalid && (
            <div className="ant-form-explain">Please provide a valid card.</div>
          )}
        </div>
        <div className="col-12 col-lg-6 my-1">
          <label>Card Holder Name</label>
          <Input
            maxLength={60}
            className="w-100"
            value={form.cardName || ''}
            onChange={e => {
              e.persist();
              setForm(state => ({
                ...state,
                cardName: e.target.value.replace(/[^A-Za-z ]/g, '')
              }));
            }}
          />
        </div>
        <div className={`col-3 my-1${form.cardCVVInvalid ? ' has-error' : ''}`}>
          <label>CVV</label>
          <Input.Password
            maxLength={form.cardValidationCard?.code?.size ?? 4}
            className={`w-100${form.cardCVVInvalid ? ' has-error' : ''}`}
            value={form.cardCVV}
            onChange={e => {
              e.persist();
              const cardCVV = e.target.value.replace(/[^\d]/g, '');

              setForm(state => ({
                ...state,
                cardCVV,
                cardCVVInvalid:
                  !!cardCVV &&
                  !state.cardNumberInvalid &&
                  (!state.cardValidationCard ||
                    !state.cardValidationCard.code ||
                    cardCVV.length !== state.cardValidationCard.code.size)
              }));
            }}
          />
          {form.cardCVVInvalid && (
            <div className="ant-form-explain">Please provide a valid CVV.</div>
          )}
        </div>
        <div className="col-4 my-1">
          <label>Expire Month</label>
          <InputNumber
            className="w-100"
            value={form.cardMonth}
            min={1}
            max={12}
            onChange={cardMonth => setForm(state => ({ ...state, cardMonth }))}
          />
        </div>
        <div className="col-5 my-1">
          <label>Expire Year</label>
          <InputNumber
            className="w-100"
            value={form.cardYear}
            min={moment().year()}
            max={moment().year() + 30}
            onChange={cardYear => setForm(state => ({ ...state, cardYear }))}
          />
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => ({
  user: state.user
});

export default connect(mapStateToProps)(AddCardModal);
