import React from "react";

interface Props {
  classes?: string;
}

const Twitter: React.FC<Props> = ({
  classes
  }) => {
  return (
    <a href="https://twitter.com/gobabypage" target="_blank">
       <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 512 512"
      className={classes}
    >
      <path  fill="#1a1a1a" d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8l164.9-188.5L26.8 48h145.6l100.5 132.9L389.2 48zm-24.8 373.8h39.1L151.1 88h-42l255.3 333.8z"></path>
    </svg>
    </a>
  );
}

export default Twitter;