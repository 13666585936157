import { BaseAction, GET_PHOTOS, USER_LOGOUT } from "../actionTypes";

export interface Photo {
  baby_id: number;
  url: string;
}
export type PhotoState = Photo[];

const initialState: PhotoState = [];

export const photos = (
  state: PhotoState = initialState,
  action: BaseAction
): PhotoState => {
  switch (action.type) {
    case GET_PHOTOS:
      return [...action.payload];
    case USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
Object.freeze(photos);

const getPhotos = (payload: PhotoState) => ({
  payload,
  type: GET_PHOTOS
});
Object.freeze(getPhotos);

export { getPhotos };
export default photos;
