import moment from 'moment';
import { call, put, select, all } from 'redux-saga/effects';
import { AppState, sagaMiddleware } from '..';
import api from '../../api';
import { Baby } from '../../interfaces';
import { isArrayEqual } from '../../utils/array';
import { parseRequestError } from '../../utils/error';
import {
  BabyState,
  getBabies,
  selectBaby,
  selectLastBaby,
} from '../ducks/baby';
import { sumChildCount } from '../ducks/user';
import { User } from '../../interfaces/user';
import { fetchMilestones } from './milestone';
import { fetchPhotos, FileUploadS3 } from './image';

/**
 * This function fetch all user's babies
 * If user has at least one baby,
 * will select the first baby
 **/
export function* fetchBabies(
  callback?: (error: string | null) => void,
  selectIndex?: number,
  sortBy?: string
) {
  const user: User = yield select((state: AppState) => state.user);
  if (!user.access_token || user.user_level === 3) return;

  try {
    const response = yield call(api.get, `api/babies?lang=en&sorted_by=${!!sortBy ? sortBy : "date"}`, {
      headers: {
        Authorization: user.access_token,
      },
    });

    if (response.data && response.data.code === 200) {
      const data = response.data.data;
      const babyState: BabyState = yield select(
        (state: AppState) => state.baby
      );

      if (!isArrayEqual(babyState.babies, data)) {
        yield put(getBabies([...data]));

        if (selectIndex !== undefined && selectIndex >= 0) {
          yield put(selectBaby(selectIndex));
        } else if (babyState.babySelectedIndex === undefined) {
          yield put(selectBaby(0));
        } else {
          // The update should validate if baby changes on reducer
          yield put(selectBaby(babyState.babySelectedIndex));
        }
      }
    }

    if (callback) callback(null);
  } catch (error) {
    if (callback) callback(parseRequestError(error));
  }
}

export function* getBabyStorage(
  id: number,
  callback: (baby: Baby | undefined) => void
) {
  const babies: Baby[] = yield select((state: AppState) => state.baby.babies);
  callback(babies.find(b => b.id === id));
}

/**
 * Add or Update a child
 */
export function* saveChild(
  child: any,
  callback?: (error?: string, description?: string) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  try {
    const response = yield call(
      api[!!child.id ? 'put' : 'post'],
      `api/${!!child.id ? 'update-child' : 'add-kiddo'}?lang=en`,
      !!child.id ? getChildForUpdate(child) : getChildForAdd(child),
      {
        headers: {
          Authorization: accessToken,
        },
      }
    );
    const message : string = !!child.id ? "Child Updated" : "Child Added" ;

    if (response.data && response.data.code === 200) {
      let index = -1;

      if (!!child.id) {
        const babies: Baby[] = yield select(
          (state: AppState) => state.baby.babies
        );
        index = babies.findIndex(b => b.id === child.id);
        sagaMiddleware.run<any>(fetchBabies, undefined, index);
      } else {
        yield put(getBabies([...response.data.data]));
        yield put(sumChildCount());
        yield put(selectLastBaby());
      }
    }

    yield all([fetchBabies(), fetchMilestones(), fetchPhotos()]);

    if (callback) callback(undefined, message);
  } catch (error) {
    if (callback) callback(parseRequestError(error));
  }
}

/**
 * Delete a child
 */
export function* deleteChild(
  id: number,
  callback?: (error?: string, description?: string) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  try {
    const response = yield call(api.delete, `api/babies/${id}?lang=en`, {
      headers: {
        Authorization: accessToken,
      },
    });

    if (response.data && response.data.code === 200) {
      const babyState: BabyState = yield select(
        (state: AppState) => state.baby
      );
      const babies = babyState.babies.filter(b => b.id !== id);
      yield put(getBabies(babies));
      yield put(selectBaby(0));
    }

    if (callback) callback(undefined, 'Baby deleted');
  } catch (error) {
    if (callback) callback(parseRequestError(error));
  }
}

export function* saveProfilePicture(
  childId: number,
  photo: FileUploadS3,
  callback?: (error?: string, description?: string) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  try {
    const formData: FormData = new FormData();
    formData.append("image", photo.image);
    formData.append("id", childId.toString());

    const response = yield call(
      api['post'],
      `api/profilePicture`,
        formData,
      {
        headers: {
          Authorization: accessToken,
        },
      }
    );
     const message : string = "PHOTO Uploaded" ;

    if (response.data && response.data.code === 200) {
      sagaMiddleware.run<any>(fetchBabies, undefined);   
    }

    yield all([fetchBabies(), fetchMilestones(), fetchPhotos()]);

    if (callback) callback(undefined, message);
  } catch (error) {
    if (callback) callback(parseRequestError(error));
  }
}

const getChildForAdd = (child: any) => ({
  ...child,
  baby_middle_name: !!child.baby_middle_name ? child.baby_middle_name : '',
  baby_birthplace: !!child.baby_birthplace ? child.baby_birthplace : '',
  height: !!child.height ? child.height : 0,
  weight_lbs: !!child.weight_lbs ? child.weight_lbs : 0,
  weight_oz: !!child.weight_oz ? child.weight_oz : 0,
  baby_birthdate: child.baby_birthdate ?`${moment(child.baby_birthdate).format(
    'YYYY[-]MM[-]DD'
  )} 00:00:00` : `${moment(child.baby_duedate).format(
    'YYYY[-]MM[-]DD'
  )} 00:00:00`,
  due_date: child.baby_duedate ? `${moment(child.baby_duedate).format(
    'YYYY[-]MM[-]DD'
  )} 00:00:00` : undefined,
  baby_time: '21:00:00',
  gender: 0,
});

const getChildForUpdate = (child: any) => {
  const weightLbs = !!child.weight_lbs ? child.weight_lbs : 0;
  const weightOz = !!child.weight_oz ? child.weight_oz : 0;

  return {
    ...child,
    gender: child.baby_gender,
    first: child.baby_first_name,
    middle: !!child.baby_middle_name ? child.baby_middle_name : '',
    last: child.baby_last_name,
    birthplace: !!child.baby_birthplace ? child.baby_birthplace : '',
    birthdate: !!child.baby_birthdate ? `${moment(child.baby_birthdate).format(
      'YYYY[-]MM[-]DD'
    )}` : undefined,
    due_date: child.baby_duedate ? `${moment(child.baby_duedate).format(
      'YYYY[-]MM[-]DD'
    )} 00:00:00` : undefined,
    time: '21:00:00',
    height: !!child.height ? child.height : 0,
    weight: weightLbs * 16 + weightOz,
  };
};
