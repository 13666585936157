import { FaFacebookF } from "react-icons/fa";
import React from "react";
import "./FacebookButton.scss";

interface Props {
  loading?: boolean;
  signInWithFacebook: Function;
  cssClass?: string;
}

const FacebookButton: React.FC<Props> = ({
  loading,
  signInWithFacebook,
  cssClass = "",
}) => (
  <>
    <button
      type="button"
      className={`FacebookButton w-100 ${cssClass}`}
      onClick={() => signInWithFacebook()}
      disabled={loading}
      style={{ marginTop: "0" }}
    >
      <FaFacebookF size="18px" style={{ verticalAlign: "text-top" }} />
      &nbsp; Facebook
    </button>
  </>
);

export default FacebookButton;
