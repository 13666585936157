import React from 'react';
import './Guideline.scss';

const AdminGuidelinePage: React.FC = () => (
  <div className="guideline container my-3">
    <h1 className="title">Guideline</h1>
    <h2>Why a guideline?</h2>
    <p>
      When we're creating forms is important to understand a little of user
      experience and how a form should be created, and we create this guideline
      to help you to make decisions during the form creation.
    </p>
    <h2>How to choose the type of question?</h2>
    <p>
      First, we need to understand what kind of response we are expecting and
      how we will handle this response. If we want a personal answer, it must be
      a <strong>"Text Box"</strong>, free for the user to explain and we will
      analyze each answer. If we want big data analytics, we should have options
      and see overall percentages of responses, such as understanding what
      features users like or dislike.
    </p>
    <p>
      When we have options, we need to understand how many options we will have
      and whether users can select multiple options. If we have a lot of
      options, it is best to have a <strong>"Drop-down"</strong> type, which
      will be a drop-down menu. If we have up to 10 options, we can choose
      either the <strong>"Multiple choice"</strong> type for single selections
      or the <strong>"Checkboxes"</strong> type for multiple selections.
    </p>
    <p>
      When we have an <strong>"Text Box"</strong>, we have the type{' '}
      <strong>"Text"</strong> for text and also the data types{' '}
      <strong>"Number"</strong> and <strong>"Date"</strong>, depending on what
      you need. Most of the time, selection options are best to analyze. You can
      also have the <strong>"Other"</strong> option where the user can type
      anything, and we can measure how much users prefer to type{' '}
      <strong>"Other"</strong> and also analyze which they most write in{' '}
      <strong>"Other"</strong>.
    </p>
  </div>
);

export default AdminGuidelinePage;
