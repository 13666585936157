import React from "react";

interface Props {
    className:string;
    isSmall: boolean;
}

const AddProfilePhotoIcon : React.FC<Props> = ({  
    className,
    isSmall
}) =>  {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={isSmall ? "16" : "24"}
      height={isSmall ? "16" : "24"}
      viewBox="0 0 24 24"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z"></path>
        <g transform="translate(1 1)">
          <circle cx="11" cy="11" r="11" fill="#658071"></circle>
          <path
            fill="#FFF"
            d="M11 5a1 1 0 011 1v4h4a1 1 0 010 2h-4v4a1 1 0 01-2 0v-4.001L6 12a1 1 0 010-2l4-.001V6a1 1 0 011-1z"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default AddProfilePhotoIcon;
