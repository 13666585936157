import { Button, Card, Icon, Input, Modal, notification, Select, Switch, Tooltip } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import CustomDivider from '../../../../components/CustomDivider';
import VerticalDivider from '../../../../components/VerticalDivider/VerticalDivider';
import { SaveForm, SaveFormQuestion, SaveFormQuestionOption } from '../../../../interfaces/form';
import { sagaMiddleware } from '../../../../store';
import { deleteOptionSaga, deleteQuestionSaga, getFormByCode, saveFormSaga } from '../../../../store/sagas/form';
import { removeDiacritics } from '../../../../utils/string';
import './SaveForm.scss';

const getErrorForm = (form: SaveForm): string | void => {
  if (!form.title) return 'Form title is required';
  if (
    form.questions.filter(
      q =>
        !!q.question &&
        !!q.type_answer &&
        (q.type_answer === 'INPUT' ? !!q.type_data : true) &&
        q.options.length === q.options.filter(o => !!o.description).length
    ).length !== form.questions.length
  )
    return 'Some questions are invalid, check titles and options';
};

const AdminSaveFormPage: React.FC = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<SaveForm>({
    title: 'Form Title',
    code: 'form_title',
    questions: [
      {
        question: 'Question title',
        is_required: false,
        type_answer: 'RADIO',
        type_data: 'TEXT',
        has_other: true,
        options: [
          {
            description: 'Option 1',
          },
          {
            description: 'Option 2',
          },
        ],
      },
    ],
  });

  useEffect(() => {
    const code: any = queryString.parse(history.location.search)?.code;

    if (!!code && !!code.toString()) {
      // Get By Code
      setLoading(true);

      sagaMiddleware.run<any>(
        getFormByCode,
        code,
        (err?: string, response?: any) => {
          setLoading(false);

          if (!!err) {
            notification.error({
              message: 'Error',
              description: err,
            });

            history.push('/admin/forms');
            return;
          } else if (!!response) {
            try {
              setForm({
                id: response.id,
                title: response.title,
                code: response.code,
                extra_question: response.extra_question,
                questions: response.questions.map((q: any) => ({
                  id: q.id,
                  question: q.question,
                  is_required: q.is_required === 1,
                  type_answer: q.type_answer,
                  type_data: q.type_data || 'TEXT',
                  has_other: q.has_other === 1,
                  options: q.options.map((o: any) => ({
                    id: o.id,
                    description: o.description,
                  })),
                })),
              });
            } catch (error) {
              notification.error({
                message: 'Error',
                description: 'Failed to load form, please try again later',
              });

              history.push('/admin/forms');
              return;
            }
          }
        }
      );
    }
  }, [history]);

  const deleteQuestion = useCallback((questionId: number, index: number) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this question?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      width: 300,
      icon: 'warning',
      onOk() {
        setLoading(true);

        sagaMiddleware.run<any>(
          deleteQuestionSaga,
          questionId,
          (err?: string) => {
            setLoading(false);

            if (!!err) {
              Modal.error({
                title: 'Error',
                content: err,
              });
            } else {
              notification.success({
                message: 'Success',
                description: 'Question deleted successfully',
              });

              setForm(state => ({
                ...state,
                questions: state.questions.filter((q, i) => i !== index),
              }));
            }
          }
        );
      },
    });
  }, []);

  const deleteOption = useCallback(
    (optionId: number, index: number, optionI: number) => {
      Modal.confirm({
        title: 'Are you sure you want to delete this option?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        width: 300,
        icon: 'warning',
        onOk() {
          setLoading(true);

          sagaMiddleware.run<any>(
            deleteOptionSaga,
            optionId,
            (err?: string) => {
              setLoading(false);

              if (!!err) {
                Modal.error({
                  title: 'Error',
                  content: err,
                });
              } else {
                notification.success({
                  message: 'Success',
                  description: 'Option deleted successfully',
                });

                setForm(state => ({
                  ...state,
                  questions: state.questions.map((q, i) =>
                    index === i
                      ? {
                          ...q,
                          options: q.options.filter((o, oi) => oi !== optionI),
                        }
                      : q
                  ),
                }));
              }
            }
          );
        },
      });
    },
    []
  );

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const errorForm = getErrorForm(form);
      if (!!errorForm) {
        Modal.error({
          title: 'Error',
          content: errorForm,
        });
        return;
      }

      setLoading(true);
      sagaMiddleware.run<any>(saveFormSaga, form, (err?: string) => {
        setLoading(false);

        if (!!err) {
          notification.error({
            message: 'Error',
            description: err,
          });
          return;
        }

        notification.success({
          message: 'Success',
          description: 'Form saved successfully',
        });
        history.push('/admin/forms');
      });
    },
    [form, history]
  );

  return (
    <div className='container my-3'>
      <h1 className='title'>Save Form</h1>
      <p className='text-center'>
        Before you create/edit a form, you might like these{' '}
        <strong>
          awesome{' '}
          <Link to='/admin/forms/guideline' target='_blank'>
            tips
          </Link>
        </strong>
        .
      </p>
      <p className='text-center'>
        Required fields are marked with <span className='secondary'>(*)</span>.
      </p>
      {loading ? (
        <div className='d-table mx-auto my-3'>
          <Icon type='loading' style={{ fontSize: 36 }} />
        </div>
      ) : (
        <form className='row justify-content-center' onSubmit={onSubmit}>
          <div className='col-12'>
            <Card className='w-100 elevation-light'>
              <div className='row justify-content-center'>
                <div className='col-12 col-md-6'>
                  <label>
                    Title <span className='secondary'>(*)</span>
                  </label>
                  <Input
                    value={form.title ?? ''}
                    onChange={e => {
                      e.persist();

                      setForm(state => {
                        const newState = { ...state };
                        newState.title = e.target?.value ?? '';
                        if (!state.id)
                          newState.code = removeDiacritics(
                            newState.title,
                            true,
                            true
                          );
                        return newState;
                      });
                    }}
                    className='w-100'
                    maxLength={255}
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <label>Code</label>
                  <Input value={form.code ?? ''} className='w-100' disabled />
                  <small>
                    This code is generated based on your first title and will
                    never change.
                  </small>
                </div>
                <div className='col-12'>
                  <label>Extra Question</label>
                  <Input
                    value={form.extra_question ?? ''}
                    onChange={e => {
                      e.persist();

                      setForm(state => ({
                        ...state,
                        extra_question: e.target.value,
                      }));
                    }}
                    className='w-100'
                    maxLength={255}
                  />
                  <small>
                    This extra question will appear at the end of the form
                    questions as a "Do you like to add anything?". You can do
                    any extra question, and also is not required, you can leave
                    this field empty.
                  </small>
                </div>
              </div>
            </Card>
          </div>
          <h2 className='text-center my-3'>Questions</h2>
          <div className='col-12'>
            {form.questions.map((question: SaveFormQuestion, index: number) => (
              <Card key={index} className='my-3 elevation-light'>
                <div className='row justify-content-center align-items-center'>
                  <div className='col-6 my-1'>
                    <Input
                      onChange={e => {
                        e.persist();

                        setForm(state => ({
                          ...state,
                          questions: state.questions.map((q, i) =>
                            i === index
                              ? { ...question, question: e.target.value }
                              : q
                          ),
                        }));
                      }}
                      value={question.question}
                      className='w-100'
                      placeholder='Question'
                      maxLength={255}
                    />
                  </div>
                  <div className='col-6 my-1'>
                    <Select
                      onChange={(value: 'INPUT' | 'LIST' | 'RADIO' | 'CHECK') =>
                        setForm(state => ({
                          ...state,
                          questions: state.questions.map((q, i) =>
                            i === index
                              ? { ...question, type_answer: value }
                              : q
                          ),
                        }))
                      }
                      value={question.type_answer}
                      className='w-100'
                    >
                      <Select.Option value='INPUT'>Text Box</Select.Option>
                      <Select.Option value='LIST'>Drop-down</Select.Option>
                      <Select.Option value='RADIO'>
                        Multiple choice
                      </Select.Option>
                      <Select.Option value='CHECK'>Checkboxes</Select.Option>
                    </Select>
                  </div>
                  {question.type_answer === 'INPUT' ? (
                    <>
                      <div className='col-6 my-1'>
                        <Select
                          onChange={(
                            value: 'TEXT' | 'NUMBER' | 'DATE' | null
                          ) =>
                            setForm(state => ({
                              ...state,
                              questions: state.questions.map((q, i) =>
                                i === index
                                  ? { ...question, type_data: value }
                                  : q
                              ),
                            }))
                          }
                          value={question.type_data}
                          className='w-100'
                        >
                          <Select.Option value='TEXT'>Text</Select.Option>
                          <Select.Option value='NUMBER'>Number</Select.Option>
                          <Select.Option value='DATE'>Date</Select.Option>
                        </Select>
                      </div>
                      <div className='col-6 my-1'>
                        <Input
                          className='w-100'
                          disabled
                          placeholder={`Free ${
                            question.type_data === 'DATE'
                              ? 'date'
                              : question.type_data === 'NUMBER'
                              ? 'number'
                              : 'text'
                          } answer`}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      {question.options.map(
                        (option: SaveFormQuestionOption, optionI: number) => (
                          <div
                            className='col-12 d-flex align-items-center my-1'
                            key={optionI}
                          >
                            <Icon
                              type={
                                question.type_answer === 'RADIO'
                                  ? 'check-circle'
                                  : question.type_answer === 'CHECK'
                                  ? 'check-square'
                                  : 'unordered-list'
                              }
                              style={{ fontSize: 18 }}
                            />
                            <Input
                              onChange={e => {
                                e.persist();

                                setForm(state => ({
                                  ...state,
                                  questions: state.questions.map((q, i) =>
                                    i === index
                                      ? {
                                          ...question,
                                          options: q.options.map((o, oi) =>
                                            oi === optionI
                                              ? {
                                                  ...o,
                                                  description: e.target.value,
                                                }
                                              : o
                                          ),
                                        }
                                      : q
                                  ),
                                }));
                              }}
                              value={option.description}
                              className='w-100 mx-2'
                              placeholder='Option'
                              maxLength={255}
                            />
                            <Button
                              htmlType='button'
                              icon='close'
                              style={{ fontSize: 18 }}
                              disabled={question.options.length < 2}
                              onClick={() => {
                                if (!option.id) {
                                  setForm(state => ({
                                    ...state,
                                    questions: state.questions.map((q, i) =>
                                      index === i
                                        ? {
                                            ...q,
                                            options: q.options.filter(
                                              (o, oi) => oi !== optionI
                                            ),
                                          }
                                        : q
                                    ),
                                  }));
                                } else {
                                  deleteOption(option.id, index, optionI);
                                }
                              }}
                            />
                          </div>
                        )
                      )}{' '}
                      <Button
                        htmlType='button'
                        size='small'
                        className='mx-auto mt-1 mb-2 d-table'
                        onClick={() =>
                          setForm(state => ({
                            ...state,
                            questions: state.questions.map((q, i) =>
                              index === i
                                ? {
                                    ...q,
                                    options: [
                                      ...q.options,
                                      { description: '' },
                                    ],
                                  }
                                : q
                            ),
                          }))
                        }
                      >
                        ADD OPTION
                      </Button>
                    </>
                  )}
                  <div className='col-12 d-flex justify-content-end align-items-center form-actions'>
                    <Tooltip title='Duplicate'>
                      <Button
                        shape='circle'
                        icon='copy'
                        onClick={() =>
                          setForm(state => {
                            const cloned = cloneDeep(state.questions[index]);

                            return {
                              ...state,
                              questions: [
                                ...state.questions,
                                {
                                  ...cloned,
                                  id: undefined,
                                  options: cloned.options.map(o => ({
                                    ...o,
                                    id: undefined,
                                  })),
                                },
                              ],
                            };
                          })
                        }
                      />
                    </Tooltip>
                    &nbsp;
                    <Tooltip title='Delete'>
                      <Button
                        shape='circle'
                        icon='delete'
                        onClick={() => {
                          if (!question.id) {
                            setForm(state => ({
                              ...state,
                              questions: state.questions.filter(
                                (q, i) => i !== index
                              ),
                            }));
                          } else {
                            deleteQuestion(question.id, index);
                          }
                        }}
                      />
                    </Tooltip>
                    <VerticalDivider />
                    Required&nbsp;
                    <Switch
                      checked={question.is_required}
                      onChange={is_required =>
                        setForm(state => ({
                          ...state,
                          questions: state.questions.map((q, i) =>
                            i === index ? { ...q, is_required } : q
                          ),
                        }))
                      }
                    />
                    {question.type_answer !== 'INPUT' && (
                      <>
                        &nbsp;
                        <Tooltip title='If the question has an "Other" option'>
                          Has other&nbsp;
                          <Switch
                            checked={question.has_other}
                            onChange={has_other =>
                              setForm(state => ({
                                ...state,
                                questions: state.questions.map((q, i) =>
                                  i === index ? { ...q, has_other } : q
                                ),
                              }))
                            }
                          />
                        </Tooltip>
                      </>
                    )}
                  </div>
                </div>
              </Card>
            ))}
            <Button
              htmlType='button'
              type='primary'
              ghost
              className='mx-auto mt-3 mb-1 d-table'
              onClick={() =>
                setForm(state => ({
                  ...state,
                  questions: [
                    ...state.questions,
                    {
                      question: '',
                      is_required: false,
                      type_answer: 'RADIO',
                      type_data: 'TEXT',
                      has_other: true,
                      options: [
                        {
                          description: 'Option 1',
                        },
                        {
                          description: 'Option 2',
                        },
                      ],
                    },
                  ],
                }))
              }
            >
              ADD QUESTION
            </Button>
          </div>
          <div className='col-12 my-2'>
            <CustomDivider style={{ margin: 0 }} />
          </div>
          <div className='col-12'>
            <Button
              htmlType='submit'
              type='primary'
              className='mx-auto mt-1 mb-3 d-table'
              disabled={loading}
            >
              SAVE FORM
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default AdminSaveFormPage;
