import { CartItem } from "./cart";

export interface Coupon {
  id: string;
  object: string;
  amount_off: number | null;
  created: 1572392527;
  currency: string | null;
  duration: any;
  duration_in_months: any;
  livemode: boolean;
  max_redemptions: number | null;
  metadata: any;
  name: string;
  percent_off: number | null;
  percent_off_precise: number | null;
  redeem_by: any;
  times_redeemed: number;
  valid: boolean;
  applies_to?: {
    products:string[]
  }
}

export interface Purchase {
  billing_first: string;
  billing_last: string;
  billing_email: string;
  billing_address: string;
  billing_city: string;
  billing_country: string;
  billing_state: string;
  billing_zip: string;
  shipping_first: string;
  shipping_last: string;
  shipping_email: string;
  shipping_address: string;
  shipping_city: string;
  shipping_country: string;
  shipping_state: string;
  shipping_zip: string;
  stripe_token: string;
  card_token: string;
  disable_ship: boolean;
  shipping_value: number;
  use_balance: boolean;
  balance: number;
  store: CartItem[];
  tax: boolean;
  coupon: Coupon[];
  one_year_subscription_book: any | null;
  shipping_phone_number: string;
  billing_phone_number: string;
  ship_method: AlexandersShippingMethods;
}

export enum AlexandersShippingMethods {
  UPS1D = "UPS1D",
  UPS2D = "UPS2D",
  UPSGROUND = "UPSGROUND",
  UPSMIEXPBPM = "UPSMIEXPBPM",
  USPSFISTCLASSLETTER = "USPSFISTCLASSLETTER",
  USPSPRIORITY = "USPSPRIORITY",
  USPSEXPRESSMAIL = "USPSEXPRESSMAIL",
  FEDEX1D = "FEDEX1D",
  FEDEXINTLPRPAK = "FEDEXINTLPRPAK",
  FEDEXGROUND = "FEDEXGROUND",
  FEDEXONERATEENVELOPE = "FEDEXONERATEENVELOPE",
}

export interface CountryOptions {
  name:string;
  code:string;
}
