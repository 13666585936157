import { call, put, select } from 'redux-saga/effects';
import { AppState, sagaMiddleware } from '..';
import api from '../../api';
import { User } from '../../interfaces';
import { CartItem } from '../../interfaces/cart';
import { parseRequestError } from '../../utils/error';
import { changeUser } from '../ducks/user';
import { trackAddToCart } from './../../utils/marketing';

/**
 * Add product to User Cart
 */
export function* addProductStore(
  product: CartItem,
  callback: (error?: string, description?: string) => void
) {
  let store = yield select((state: AppState) => state.user.store);
  let newStore: CartItem[] = [];

  if (!!store && store !== '[]') {
    try {
      store = JSON.parse(store);
      if (!!store && Array.isArray(store)) {
        if (
          product.type === 'print' &&
          !!store.find(
            p => parseInt(p.babypage_id) === parseInt(product.babypage_id)
          )
        ) {
          callback('You already have this BabyPage in your Cart', undefined);
          return;
        }

        if (
          product.type === 'book' &&
          !!store.find(p => parseInt(p.book_id) === parseInt(product.book_id))
        ) {
          callback('You already have this Book in your Cart', undefined);
          return;
        }

        // Clean "Store" instead to return error, so users can change their plan choose
        if (
          product.type === 'subscription' &&
          !!store.find(p => p.type === 'subscription')
        ) {
          store = newStore;
        }

        newStore = store;
      }
    } catch (error) {}
  }

  newStore.push(product);

  trackAddToCart(product);

  sagaMiddleware.run<any>(updateUserStore, JSON.stringify(newStore), callback);
}

/**
 * Update user Cart
 */
export function* updateUserStore(
  store: string,
  callback: (error?: string, description?: string) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  try {
    const response = yield call(
      api.post,
      'api/update-user-store',
      {
        store
      },
      {
        headers: {
          Authorization: accessToken
        }
      }
    );

    if (response.data && response.data.code === 200) {
      yield put(
        changeUser({
          store: response.data.data
        })
      );
      callback(undefined, 'Cart updated');
    } else {
      callback('Error updating Cart');
    }
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * Get user store
 */
export function* getUserStore(callback: (error?: string) => void) {
  const user: User = yield select((state: AppState) => state.user);

  try {
    const response = yield call(api.get, 'api/get-store?lang=en', {
      headers: {
        Authorization: user.access_token
      }
    });

    if (response.data && response.data.code === 200) {
      let store = '';
      if (!!response.data.data && !!response.data.data.store) {
        try {
          store = JSON.stringify(response.data.data.store);

          if (!store) {
            store = '';
          }
        } catch (err) {}
      }

      if ((!user.store && !!store) || user.store !== store) {
        yield put(changeUser({ store }));
      }

      callback();
    } else {
      callback('Error getting Cart');
    }
  } catch (error) {
    callback(parseRequestError(error));
  }
}
