import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { Modal, Tabs, Button, Spin, Icon } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { AppState } from '../../store';
import { connect } from 'react-redux';
import './SelectPhotoModal.scss';
import { PhotoState } from '../../store/ducks/photos';
import { Baby } from '../../interfaces';
import useFetchPhotos  from "../../hooks/useFetch"
import CameraIcon from '../Icons/CameraIcon';
import { IsMounted } from '../../hooks/IsMounted';
import { useWindowSize } from '../../hooks/WindowsSize';

declare const FB: any;
const { TabPane } = Tabs;

type Props = ModalProps & {
  inputRef: React.RefObject<HTMLInputElement>;
  cameraInputRef?: React.RefObject<HTMLInputElement>;
  babySelected?: Baby;
  photos: PhotoState;
  onSelectPhoto: (photoUrl: string) => void;
  setTriggerPhotoUpdate?: React.Dispatch<React.SetStateAction<boolean>>;
  step?: number;
  isProfile?: boolean;
  setBabyPhotoModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setAddPhoto?: React.Dispatch<React.SetStateAction<boolean>>;
};

const SelectPhotoModal: React.FC<Props> = ({
  inputRef,
  cameraInputRef,
  babySelected,
  photos,
  onSelectPhoto,
  setTriggerPhotoUpdate,
  step,
  isProfile,
  setBabyPhotoModal,
  setAddPhoto,
  ...props
}) => {
  const [userId, setUserId] = useState(0);
  const [loadingFace, setLoadingFace] = useState(false);
  const [width] = useWindowSize();
  const isMount = IsMounted();
  // eslint-disable-next-line
  const [facePhotos, setFacePhotos] = useState<string[]>([]);
  // eslint-disable-next-line
  const [nextCursor, setNextCursor] = useState<string>('');
  // eslint-disable-next-line
  // const [loadingGoogle, setLoadingGoogle] = useState(false);
  // eslint-disable-next-line
  // const [googlePhotos, setGooglePhotos] = useState<string[]>([]);

  const [page, setPage] = useState(1);
  const { loading, error, list, hasMore } = useFetchPhotos(!!babySelected ? babySelected.id : 0, page);
  const observer  = useRef<IntersectionObserver>();

    useEffect(() => {
      if (isMount) return; 
      else {
        setPage(1);
      }
    }, [babySelected?.id])   

  const lastPhotoRef = useCallback(node => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage(prev => prev + 1);
        }
      });
      if (node) observer.current.observe(node); 
    }, [loading, hasMore]
  );
  const getFacebookPhotos = useCallback(
    (firstUserId?: number) => {
      try {
        if (!loadingFace) setLoadingFace(true);

        const options: any = {
          fields: 'images',
        };

        if (!!nextCursor && nextCursor !== '') {
          options.after = nextCursor;
        }

        FB.api(
          `/${firstUserId || userId}/photos/uploaded`,
          (photosResponse: any) => {
            setNextCursor(
              !!photosResponse &&
                !!photosResponse.paging &&
                !!photosResponse.paging.cursors &&
                !!photosResponse.paging.cursors.after
                ? photosResponse.paging.cursors.after
                : ''
            );

            setLoadingFace(false);

            if (!!photosResponse.data) {
              setFacePhotos(curr => [
                ...curr,
                ...photosResponse.data.map((e: any) => e.images[0].source),
              ]);
            }
          },
          options
        );
      } catch (error) {
        setLoadingFace(false);
        Modal.error({
          title: 'Error',
          content:
            'Sorry, Facebook login is not available now, try again later',
        });
      }
    },
    [nextCursor, userId, loadingFace]
  );

  const checkFacebookLogin = useCallback(() => {
    if (!!FB) {
      setLoadingFace(true);

      let timeoutHandler = setTimeout(() =>  {
        setLoadingFace(false);
      }, 60000);

      try {
        FB.getLoginStatus((response: any) => {
          if (response.status === 'connected') {
            getFacebookPhotos(response.authResponse.userID);
            setUserId(response.authResponse.userID);
          } else {
            FB.login(
              (response: any) => {
                clearTimeout(timeoutHandler);
                if (response.status === 'connected') {
                  getFacebookPhotos(response.authResponse.userID);
                  setUserId(response.authResponse.userID);
                } else {
                  setLoadingFace(false);

                  if (response.status === 'not_authorized') {
                    Modal.error({
                      title: 'Error',
                      content:
                        'When you sign in with Facebook please follow all steps and give permission for BabyPage App',
                    });
                  }
                }
              },
              { scope: 'public_profile,email,user_photos', auth_type: 'reauthenticate' }
            );
          }
        }, true);
      } catch (error) {
        setLoadingFace(false);
        Modal.error({
          title: 'Error',
          content:
            'Sorry, Facebook login is not available now, try again later',
        });
      }
    }
  }, [getFacebookPhotos]);

  // const getGooglePhotos = useCallback(() => {}, []);
  let titleMarginClass = isProfile ? "p-3 w-15" : null;

  const uploadButtons = useMemo(() => {
    if(!isProfile) {
      return (
        <Button
        type='primary'
        shape='round'
        onClick={e => {
          if (!!inputRef.current && !!props.onCancel) {
            inputRef.current.click();
            if(step!== undefined && step>=2 && setTriggerPhotoUpdate !== undefined) {
              setTriggerPhotoUpdate(true);
            }  
            props.onCancel(e);
          }
        }}
        className={`mx-auto d-table my-3 ${titleMarginClass}`}
      >
        UPLOAD NEW
      </Button>
      )
    } else {
      if(width <= 480) {
        return (
          <div className="row justify-content-center items-align-center">
          <Button
            type='primary'
            shape='round'
            onClick={e => {
              if (!!inputRef.current && !!props.onCancel) {
                inputRef.current.click();  
                props.onCancel(e);
              }
            }}
            className={`mx-2 d-table my-3  ${titleMarginClass}`}
          >
            +
          </Button>
          <Button 
            className={`mx-2 d-table my-3 ${titleMarginClass}`}  
            type='primary'
            shape='round' 
            onClick={e => {
              if(!!cameraInputRef) {
                if (!!cameraInputRef.current && !!props.onCancel) {
                  cameraInputRef.current.click();
                  props.onCancel(e);
                }
              }
            }}
            >
              <CameraIcon ></CameraIcon>
          </Button>
          </div>
        )
      } else {
        return (
          <Button
          type='primary'
          shape='round'
          onClick={e => {
            if (!!inputRef.current && !!props.onCancel) {
              inputRef.current.click();
              if(step!== undefined && step>=2 && setTriggerPhotoUpdate !== undefined) {
                setTriggerPhotoUpdate(true);
              }  
              props.onCancel(e);
            }
          }}
          className={`mx-auto d-table my-3 ${titleMarginClass}`}
        >
          +
        </Button>
        )
      }
    }

  },[width])

  return (
    <Modal {...props} okButtonProps={{ hidden: true }} footer={false}  className={isProfile ? "mobile-profile-modal" : ""}>
      { isProfile ? 
            <div className="row justify-content-around align-items-center mt-n2 content-profile">
            <h1 className='crop-modal-title'  >
               Upload Photo
            </h1>
          </div> :
            null 
      }
      { uploadButtons } 
      <Tabs>
        <TabPane tab='Gallery' key='Gallery'>
          {!!list.length ? (
            <div className='photoList'>
              {list.map((photo, index) => { 
                if(list.length === index + 1) {
                  return (
                    <>
                    <img
                    key={index}
                    ref={lastPhotoRef}
                    src={photo.photo}
                    alt={`Gallery ${index + 1}`}
                    className='elevation-light'
                    onClick={e => {
                      if (!!props.onCancel) {
                        onSelectPhoto(photo.photo);
                        if(step!== undefined && step>=2 && setTriggerPhotoUpdate !== undefined) {
                          setTriggerPhotoUpdate(true);
                        }  
                        props.onCancel(e);
                      }
                    }}
                    />
                  { !!loading && <div className="text-center" style={{width:"100%"}}>
                    <br/>
                    <Spin
                      indicator={
                        <Icon type='loading' style={{ fontSize: 36, width: "100%" }} spin />
                      }
                    />
                    </div>}
                    </>
                  )
                } else {
                  return (
                    <img
                      key={index}
                      src={photo.photo}
                      alt={`Gallery ${index + 1}`}
                      className='elevation-light'
                      onClick={e => {
                        if (!!props.onCancel) {
                          onSelectPhoto(photo.photo);
                          if(step!== undefined && step>=2 && setTriggerPhotoUpdate !== undefined) {
                            setTriggerPhotoUpdate(true);
                          }
                          props.onCancel(e);
                        }
                      }}
                    />
                  )
                } 
            })
              }
              
            </div>
          ) : list.length === 0 && loading ? (
            <>
            <div  className="text-center" style={{width: "100%"}}>
            <Spin
                indicator={
                  <Icon type='loading' style={{ fontSize: 36, width: "100%" }} spin />
                }
            />
            </div>
            </>
            
          ) : list.length === 0  ?  
          <p className='text-center'>
            No photos previously uploaded.
          </p>
          : null
          }
          {isProfile && setBabyPhotoModal ? 
            <Button
              type='primary'
              shape='round'
              onClick={e => {
                if(!!setAddPhoto) {
                  setAddPhoto(false)
                } else {
                  setBabyPhotoModal(false)
                }
              }}
              className={`mx-auto d-table mt-4 mb-2 w-75 light-btn`}
            >
            Cancel Upload
          </Button> : 
          null 
        }
          

        </TabPane>
        <TabPane tab='Facebook' key='Facebook'>
          {!!facePhotos.length ? (
            <div className='photoList'>
              {facePhotos.map((photo, index) => (
                <img
                  key={index}
                  src={photo}
                  alt={`Gallery ${index + 1}`}
                  className='elevation-light'
                  onClick={e => {
                    if (!!props.onCancel) {
                      onSelectPhoto(photo);
                      if(step!== undefined && step>=2 && setTriggerPhotoUpdate !== undefined) {
                        setTriggerPhotoUpdate(true);
                      }  
                      props.onCancel(e);
                    }
                  }}
                />
              ))}
              {!!nextCursor && (
                <Button
                  type='primary'
                  shape='round'
                  disabled={loadingFace}
                  onClick={() => getFacebookPhotos()}
                  className='w-100 my-3'
                >
                  Load more
                </Button>
              )}
            </div>
          ) : loadingFace ? (
            <div className='mx-auto my-3 d-table'>
              <Spin
                indicator={
                  <Icon type='loading' style={{ fontSize: 36 }} spin />
                }
              />
            </div>
          ) : (
            <Button
              type='primary'
              shape='round'
              onClick={checkFacebookLogin}
              className='mx-auto d-table my-3 facebook-btn'
            >
              GET FACEBOOK PHOTOS
            </Button>
          )}
        </TabPane>
        {/* <TabPane tab='Google' key='Google'>
          {!!googlePhotos.length ? (
            <div className='photoList'>
              {googlePhotos.map((photo, index) => (
                <img
                  key={index}
                  src={photo}
                  alt={`Gallery ${index + 1}`}
                  className='elevation-light'
                  onClick={e => {
                    if (!!props.onCancel) {
                      onSelectPhoto(photo);
                      props.onCancel(e);
                    }
                  }}
                />
              ))}
            </div>
          ) : loadingGoogle ? (
            <div className='mx-auto my-3 d-table'>
              <Spin
                indicator={
                  <Icon type='loading' style={{ fontSize: 36 }} spin />
                }
              />
            </div>
          ) : (
            <Button
              type='primary'
              shape='round'
              onClick={getGooglePhotos}
              className='mx-auto d-table my-3 google-btn'
            >
              GET GOOGLE PHOTOS
            </Button>
          )}
        </TabPane> */}
      </Tabs>
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => ({
  photos: state.photos,
  babySelected: state.baby.babySelected,
});

export default connect(mapStateToProps)(SelectPhotoModal);
