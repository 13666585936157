import React from "react";
import { Link, useHistory } from "react-router-dom";
import "./Footer.scss";
import DashboardIcon from "./icons/DashboardIcon";
import BabypagesIcon from "./icons/BabypagesIcon";
import MilestonesIcon from "./icons/MilestonesIcon";
import BookIcon from "./icons/BookIcon";
import SettingsIcon from "./icons/SettingsIcon";
import { useWindowSize } from "../../hooks/WindowsSize";
import { AppState } from "../../store";
import { connect } from "react-redux";
import { User } from "../../interfaces";

interface Props {
  hideLinks?: boolean;
  user: User;
}

const BottomNavigation: React.FC<Props> = ({ hideLinks, user }) => {
  const history = useHistory();
  const location = history.location.pathname;
  const [width] = useWindowSize();
  const isGuestUser = !!user.guest_user
  const isReadOnly = user.permission === "Read-Only";


  return (
    <div className="row justify-content-center align-items-center bottom-nav elevation-5">
      <div
        className={`d-flex align-items-center justify-content-center${
          hideLinks ? "" : " w-100"
        } bottom-nav-text`}
      >
        <div
          className={`d-flex flex-column  align-items-center justify-content-center footer-item`}
        >
          <Link to="/">
            {location === "/" ? (
              <DashboardIcon isSelected={true} />
            ) : (
              <DashboardIcon isSelected={false} />
            )}
          </Link>
            <span
              className="text-center bottom-nav-text"
              style={{ color: location === "/" ? "#ffafa5" : "#909090" }}
            >
              Dashboard
            </span>
        </div>
        <div
          className={`d-flex flex-column  align-items-center justify-content-center footer-item`}
        >
          <Link to="/babypages">
            {location === "/babypages" ? (
              <BabypagesIcon isSelected={true} />
            ) : (
              <BabypagesIcon isSelected={false} />
            )}
          </Link>
            <span
              className="text-center bottom-nav-text"
              style={{
                color: location === "/babypages" ? "#ffafa5" : "#909090",
              }}
            >
              BabyPages
            </span>
        </div>
          { !isReadOnly ? (
              <div
              className={`d-flex flex-column  align-items-center justify-content-center footer-item`}
              >
                <Link to="/milestones">
                  {location === "/milestones" ? (
                    <MilestonesIcon isSelected={true} />
                  ) : (
                    <MilestonesIcon isSelected={false} />
                  )}
                </Link>
                <span
                  className="text-center bottom-nav-text"
                  style={{
                    color: location === "/milestones" ? "#ffafa5" : "#909090",
                  }}
                >
                  Milestones
                </span>
              </div>
            ) : null
          }      
        <div
          className={`d-flex flex-column  align-items-center justify-content-center footer-item`}
        >
          <Link to="/books">
            {location === "/books" ? (
              <BookIcon isSelected={true} />
            ) : (
              <BookIcon isSelected={false} />
            )}
          </Link>
            <span
              className="text-center bottom-nav-text"
              style={{ color: location === "/books" ? "#ffafa5" : "#909090" }}
            >
              My Books
            </span>
        </div>
        { !isGuestUser ? (
            <div
              className={`d-flex flex-column  align-items-center justify-content-center footer-item`}
            >
              <Link to="/profile">
                {location === "/profile" ? (
                  <SettingsIcon isSelected={true} />
                ) : (
                  <SettingsIcon isSelected={false} />
                )}
              </Link>
              <span
                className="text-center bottom-nav-text"
                style={{ color: location === "/profile" ? "#ffafa5" : "#909090" }}
              >
                Settings
              </span>
            </div>
          ) : null
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  user: state.user,
});

export default connect(mapStateToProps)(BottomNavigation);

