import React from "react";

interface Props {
  isSelected?: boolean;
}


const BabypagesIcon : React.FC<Props> = ({isSelected}) => {

  if(isSelected) {
    return (
<svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="29"
      viewBox="0 0 20 29"
      className="mx-3 mx-sm-4"
    >
      <g fill="none" fillRule="evenodd">
        <circle
          cx="2"
          cy="2"
          r="2"
          fill="#FFAFA5"
          transform="translate(8 1)"
        ></circle>
        <path
          stroke="#F7ADA5"
          d="M15.6 10.5a1.898 1.898 0 011.9 1.9v13.2c0 .525-.213 1-.556 1.344a1.894 1.894 0 01-1.344.556H4.4c-.525 0-1-.213-1.344-.556A1.894 1.894 0 012.5 25.6V12.4c0-.525.213-1 .556-1.344A1.894 1.894 0 014.4 10.5z"
        ></path>
        <path
          fill="#FFAFA5"
          d="M10 24h4v-4h-4v4zm-4 0h3v-4H6v4zm0-5h8v-5H6v5zm-1-6v12h10V13H5z"
        ></path>
      </g>
    </svg>
    )
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="29"
        viewBox="0 0 20 29"
        className="mx-3 mx-sm-4"
    >
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#FFF"
          d="M15.6 10.5a1.898 1.898 0 011.9 1.9v13.2c0 .525-.213 1-.556 1.344a1.894 1.894 0 01-1.344.556H4.4c-.525 0-1-.213-1.344-.556A1.894 1.894 0 012.5 25.6V12.4c0-.525.213-1 .556-1.344A1.894 1.894 0 014.4 10.5z"
        ></path>
        <path
          fill="#FFF"
          d="M10 24h4v-4h-4v4zm-4 0h3v-4H6v4zm0-5h8v-5H6v5zm-1-6v12h10V13H5z"
        ></path>
      </g>
    </svg>
    );
  }

}

export default BabypagesIcon;
