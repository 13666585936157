import { Modal, Radio } from "antd";
import React, { useMemo, useState, useLayoutEffect } from "react";
import { connect } from "react-redux";
import BabyPageList from "../../components/BabyPageList/BabyPageList";
import BaseLayout from "../../components/BaseLayout/BaseLayout";
import { Baby, BabyPage, StripeProduct } from "../../interfaces";
import { AppState, sagaMiddleware } from "../../store";
import { fetchBabies } from "../../store/sagas/baby";
import "./BabyPages.scss";
import { User } from "../../interfaces";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import { capitalize } from "lodash";
import AscendingSort from "../../components/Icons/AscendingSort";
import DescendingSort from "../../components/Icons/DescendingSort";

interface Props {
  babySelected?: Baby;
  stripeProducts: StripeProduct[];
  user: User;
  babies: Baby[];
}

const BabyPagesPage: React.FC<Props> = ({ babySelected, stripeProducts, user, babies }) => {
  const sortOptions = [{
    value: 'date',
    label: 'Creation Date'
  }, {
    value: 'alpha',
    label: 'Alphabetical'
  },
  {
    value: 'milestone',
    label: 'Milestone Date'
  }
   ]
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [sortModalVisible, setSortModalVisible] = useState(false); 
  const [sortBy, setSortBy] = useState(sortOptions[0].value); 
  const [reverseSort, setReverseSort] = useState(false);

  const [newBabypages, oldBabypages]: [BabyPage[], BabyPage[]] = useMemo(
    () =>
      !!babySelected
        ? !reverseSort ? [
            babySelected.babyPages.filter(
              (babypage) => babypage.new_version === true
            ),
            babySelected.babyPages.filter(
              (babypage) => babypage.new_version === false
            ),
          ] : [
            babySelected.babyPages.reverse().filter(
              (babypage) => babypage.new_version === true
            ),
            babySelected.babyPages.reverse().filter(
              (babypage) => babypage.new_version === false
            ),
          ]
        : [[], []],
    [babySelected, reverseSort, sortBy]
  );
      
  useLayoutEffect(()=>{
    setLoading(true);

    sagaMiddleware.run<any>(fetchBabies, (error: string | null) => {
      setLoading(false);
      if (error) {
          Modal.error({
            title: "Error",
            content: error,
          });  
      }
    },undefined, sortBy);
  },[babySelected, sortBy])

  const allBabypages = useMemo(() => {
    let allBabypages : any = []

    babies.map(baby => {
      allBabypages = [...allBabypages, ...baby.babyPages]
    })
    return allBabypages;
  }, [babies]) 

  return (
    <BaseLayout
      title="BabyPage - BabyPages"
      pageTitle="BabyPages"
      pageStyle={{ position: "unset", backgroundColor: "#f0f0f0" }}
      contentStyle={{ padding: 0 }}
    >
      <div className="pb-5" style={{backgroundColor: "#f0f0f0"}}>
        <BabyPageList
          setLoading={setLoading}
          loading={loading}
          text="BABYPAGES"
          newBabyPages={!user.guest_user || (!!user.guest_user && user.permission === "Full Access")}
          babypages={newBabypages}
          babySelected={babySelected}
          stripeProducts={stripeProducts}
          isGuestUser={user.guest_user}
          setModal={setModal}
          setSortModalVisible={setSortModalVisible}
          sortBy={sortBy}
          allBabypages={allBabypages.length}
        />
        { oldBabypages.length === 0 ? null :  
          <BabyPageList
            setLoading={setLoading}
            loading={loading}
            text="OLD BABYPAGES"
            babypages={oldBabypages}
            babySelected={babySelected}
            stripeProducts={stripeProducts}
            setModal={setModal}
            setSortModalVisible={setSortModalVisible}
            sortBy={sortBy}
            allBabypages={allBabypages.length}
          /> 
        }    
      </div>
      <Modal visible={modal} onCancel={() => setModal(false)} footer={null}>
        <h1 className="title">Hang on!</h1>
        <p className="subtitle">
          You need at least 8 BabyPages to create a book.
        </p>
        <p className="subtitle">
          You'll need to create a few more babypages to make your book.
        </p>
      </Modal>
      <Modal visible={sortModalVisible} onCancel={() => setSortModalVisible(false)} footer={null}>
        <h1 className="sort-title">Sort By</h1>
        <Radio.Group
              value={sortBy}
              style={{
                padding: 0
              }}
              onChange={(e: RadioChangeEvent) => {
               setSortBy(e.target.value);
              }}
            >
              {sortOptions.map ((item, index) =>  (
                <Radio style={{display:"block"}} key={index} value={item.value}> {capitalize(item.label)} </Radio>
              ))}
            </Radio.Group>
            <div className="">
              <span
                className="clickable"
                onClick={() => {
                  setReverseSort(!reverseSort);
                }}
              >
                {!reverseSort ? <DescendingSort /> : <AscendingSort /> }
              </span>

              <span className="ml-2">
                {sortBy === "date"
                  ? !reverseSort ? "Latest First" : "Earliest First"
                  : sortBy === "milestone"
                  ? !reverseSort ? "Earliest Milestones" : "Latest Milestones"
                  : !reverseSort ? "A-Z" : "Z-A"}
              </span>
            </div>
      </Modal>
    </BaseLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  babySelected: state.baby.babySelected,
  stripeProducts: state.stripeProduct,
  user: state.user,
  babies: state.baby.babies,
});


export default connect(mapStateToProps)(BabyPagesPage);
