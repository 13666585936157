import { call, select } from "redux-saga/effects";
import { AppState } from "..";
import api from "../../api";
import { MessageNotification } from "../../interfaces/messageNotification";
import { parseRequestError } from "../../utils/error";

/**
 * Get message notifications for admin panel
 */

export function* getMessageNotificationsForAdmin(
  callback: (err?: string, response?: MessageNotification[]) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.admin.access_token
  );

  try {
    const response = yield call(api.get, `api/admin/notifications`, {
      headers: {
        Authorization: accessToken
      }
    });

    callback(undefined, response.data.data);
  } catch (error) {
    callback(parseRequestError(error));
  }
}
