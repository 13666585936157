import React, { useState } from "react";
import { Modal, Button } from "antd";
import BaseLayout from "../../../components/BaseLayout/BaseLayout";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { AppState } from "../../../store";
import { Link } from "react-router-dom";
import SignInForm from "../../../components/SignIn/SignInForm";
import message from "../../../assets/img/homeMessage.png";
import homeMessageMobile from "../../../assets/img/homeMessageMobile.png";
import brandLogo from "../../../assets/img/brandLogo.png";
import { PRODUCTION } from "../../../env";

import "../Auth.scss";
import { useWindowSize } from "../../../hooks/WindowsSize";

interface Props {
  access_token?: string;
}

const SignInPage: React.FC<Props> = ({access_token}) => {
  const [modal, setModal] = useState(false);
  const [ width ] = useWindowSize();
  const history = useHistory()

  const linkToLanding = PRODUCTION ? "https://babypage.com" : "https://babypagestg.wpengine.com/"

  if(!!access_token) {
    history.push("/")
  }
  return (
    <BaseLayout
      title="BabyPage - SignIn"
      hideHeader
      hideChildMenu
      hideFooter
      pageStyle={{ position: "unset", minHeight: "unset" }}
      contentStyle={{ padding: 0 }}
    >
      <div className="container-fluid backgroundHome">
        <div className="row text-center homeHeight align-items-center">
          <div className="col-12 col-md-12">
          <div className="row justify-content-center mt-md-5 mt-2 mb-5 ml-md-2 ml-0">
              <div className="">
                <a href={linkToLanding} target="_blank"><img src={brandLogo} alt="logo" /></a>
              </div>
          </div>
            <div className="row justify-content-center mb-3">
              <div className="col-12 col-md-8 col-lg-6 col-xl-4">
                {width > 540 ? (<img src={message} alt="Message" />) : (<img src={homeMessageMobile} alt="Message" />)}
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-9 col-md-5 col-lg-4 col-xl-3 mt-2">
                <button
                  type="button"
                  className="homeBtn"
                  style={{ maxWidth: "290px" }}
                  onClick={() => {
                    setModal(true);
                    history.push("/signin?signup")
                  }}
                >
                  Create Account
                </button>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-9 col-md-5 col-lg-4 col-xl-3 mt-2">
                <button
                  type="button"
                  className="homeBtn"
                  style={{ maxWidth: "290px" }}
                  onClick={() => {
                    setModal(true);
                    history.push("/signin?signin")
                  }}
                >
                  Login to BabyPage
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        centered
        visible={modal}
        onCancel={() => setModal(false)}
        destroyOnClose
        footer={null}
        bodyStyle={{
          backgroundColor: "#e1c9bd",
        }}
      >
        <div className="container-fluid p-3">
          <div className="row justify-content-center text-center">
            <div className="col-12">
             <SignInForm />
            </div>
          </div>
        </div>
      </Modal>
    </BaseLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  access_token: state.user.access_token,
});

export default connect(mapStateToProps)(SignInPage);

