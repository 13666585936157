import React from "react";

interface Props {
  className?:string;
}

const CompletedMilestones: React.FC<Props> = ({className}) =>  {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={className}
    >
      <g fill="none" fillRule="evenodd" opacity="0.501">
        <path fill="#FFAFA5" d="M8 16A8 8 0 108 0a8 8 0 000 16z"></path>
        <path
          stroke="#FFF"
          strokeDasharray="0,0"
          strokeLinecap="round"
          strokeWidth="1.2"
          d="M4.8 8l1.943 1.943a.08.08 0 00.114 0L11.2 5.6h0"
        ></path>
      </g>
    </svg>
  );
}

export default CompletedMilestones;