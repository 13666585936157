import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Form, Input, Button, notification, Spin } from 'antd';
import { FormComponentProps } from "antd/lib/form";
import queryString from "query-string";
import { resetPassword } from "../../store/sagas/user";
import { sagaMiddleware } from "../../store";

function hasErrors(fieldsError: Record<string, string[] | undefined>) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

type Props = FormComponentProps<any> & RouteComponentProps<any, any, any>;

class ResetPasswordForm extends React.Component<Props> {

  

  // Call Ant.Design notification manager
  _openNotificationWithIcon = (
    type: "success" | "error",
    title: string,
    message: string
  ) => {
    notification[type]({
      message: title,
      description: message,
      onClose: () => {
        this.props.history.push("/");
      }
    });
  };

  state = {
    loading: false,
    email:"",
    token:"",
    disabled: false
  }

  componentWillMount() {

    const query = queryString.parse(this.props.location.search);

    if(!!query) {
      this.setState({email: !!query.email ? query.email : "", token: !!query.token ? query.token : ""})
    }
   
  }


  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {

    e.preventDefault();
    this.setState({ loading: true })

    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {

        let body = {
            ...values,
            token: this.state.token,
        }

        sagaMiddleware.run<any>(
          resetPassword,
          { ...body },
          (response: any, error: any) => {

            this.setState({ loading: false })

            if (error) {

              this._openNotificationWithIcon("error", "Error", error);
              return;

            }
            this.setState({disabled:true})
            // Success message
            this._openNotificationWithIcon("success", "Success", "Your password has been successfully changed. You will be redirected to the sign in page shortly.");

          }
        );
        
      } else {
        this.setState({ loading: false })
      }
    });
  };

  render() {
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched
    } = this.props.form;

    const emailError = getFieldError("email");
    const passwordError = isFieldTouched("password") && getFieldError("password");

    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <Form.Item
          validateStatus={emailError ? "error" : ""}
          help={emailError || ""}
          extra="Email Address"
        >
          {getFieldDecorator("email", {
            rules: [
              {
                required: true,
                message: "This field is required."
              },
              {
                type: "email",
                message: "The email provided is not an email."
              }
            ],
            initialValue: !!this.state.email ? `${this.state.email}` : ""
          })(<Input size="large" type="email" maxLength={60} disabled />)}
        </Form.Item>
        <Form.Item 
          validateStatus={passwordError ? "error" : ""}
          help={passwordError || ""}
          extra="Password" >
            { 
              getFieldDecorator('password', 
                {
                  rules: [
                    {
                      required: true,
                      message: "Please enter at least 6 characters."
                    },
                    {
                      min: 6,
                      message: "Please enter at least 6 characters."
                    }
                  ]
                }
              )
              (<Input.Password size="large" maxLength={100} />) 
            }
        </Form.Item>
        <Form.Item>
          {this.state.loading

            ? <Spin size="large"  />
            : <Button
                htmlType="submit"
                type="primary"
                disabled={
                  hasErrors(getFieldsError()) || this.state.disabled
                }
                className="w-50"
                shape="round"
              >
                Reset Password
              </Button>
          }
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create()(
  withRouter(connect()(ResetPasswordForm))
);