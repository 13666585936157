import { Button, Icon, Modal, Spin, Tabs } from "antd";
import React, { useEffect, useMemo, useState, useLayoutEffect, Dispatch } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {isMobile} from 'react-device-detect';
import BaseLayout from "../../components/BaseLayout/BaseLayout";
import BookList from "../../components/BookList/BookList";
import FloatButton from "../../components/FloatButton/FloatButton";
import { Baby, Book, StripeProduct, User } from "../../interfaces";
import { AppState, sagaMiddleware } from "../../store";
import { BookState } from "../../store/ducks/books";
import {
  fetchBooks,
  getStripeBookTypes,
  setBookType as setBookTypeSaga,
} from "../../store/sagas/books";
import "./BooksPage.scss";

const { TabPane } = Tabs;

interface Props {
  books: BookState;
  babies: Baby[];
  stripeProducts: StripeProduct[];
  user: User;
}

const BooksPage: React.FC<Props> = ({ books, babies, stripeProducts, user }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [headerImageUrl, setHeaderImageUrl] = useState(null);
  const [stripeBookTypes, setStripeBookTypes] = useState([]);
  const [showUserBooks, setShowUserBooks] = useState<boolean>(false)
  // Hiding books with no pages
  const [filteredBooks, setFilteredBooks] = useState<Book[]>(books.books.filter((book) => book.pages.length > 0));

  useEffect(() => {
    //Hiding books with no pages when global book state updates
    setFilteredBooks(books.books.filter((book) => book.pages.length > 0))
  }, [books])
  
  useEffect(() => {
    setLoading(true);

    sagaMiddleware.run<any>(
      getStripeBookTypes,
      (err?: string, response?: any) => {
        setLoading(false);

        if (err) {
          console.error(err);
          return;
        }

        setHeaderImageUrl(response.header_image_books);
        setStripeBookTypes(response.products);
      }
    );
  }, []);

  useEffect(() => {
    if(!!history.location.search) {
      setShowUserBooks(true)
    }
  }, [showUserBooks])

  useLayoutEffect(()=>{
    setLoading(true);
    sagaMiddleware.run<any>(fetchBooks, (error: string | null) => {
      setLoading(false);
      if (error) {
          Modal.error({
            title: "Error",
            content: error,
          });  
        }
    });
  },[books])

  const setBookType = (stripeId: string) => {
    if (counts.old < 8 && counts.new < 8) {
      Modal.info({
        title: "Notice",
        content: `You need at least 8 BabyPages to create a book.
                  Create a few more babypages to make your book.`,
      });

      return;
    }

    setLoading(true);
    sagaMiddleware.run<any>(setBookTypeSaga, stripeId, (err?: string) => {
      setLoading(false);

      if (err) {
        console.error(err);
        return;
      }

      history.push("/books/save");
    });
  };

  const counts = useMemo(
    () =>
      babies.reduce(
        (total, baby) => ({
          new:
            total.new +
            baby.babyPages.filter((babypage) => babypage.new_version === true)
              .length,
          old:
            total.old +
            baby.babyPages.filter((babypage) => babypage.new_version === false)
              .length,
        }),
        { new: 0, old: 0 }
      ),
    [babies]
  );
  const showCreateTab= !user.guest_user;
  return (
    <>
    <BaseLayout
      title="BabyPage - Books"
      pageTitle="Books"
      hideChildMenu
      hideHeaderChildMenu
      pageStyle={{ position: "unset", minHeight: "unset" }}
      contentStyle={{ padding: 0 }}
    >
      <div
        className="book-page-header"
        style={{
          backgroundImage: `url(${headerImageUrl})`,
        }}
      ></div>
      <Tabs defaultActiveKey="create-a-book" tabBarGutter={isMobile ? 60 : 0} className="margin-bottom-navigation" >
        {showCreateTab && <TabPane tab="Create a Book" key={showUserBooks ? "my-books" : "create-a-book"} >
          <div className="row">
            <div className="col">
              <p
                style={{
                  fontWeight: 700,
                  color: "#939aa9",
                  padding: "0 1rem",
                  justifyContent: "center",
                  textAlign: "center",
                  fontSize: "16px"
                }}
              >
                Once you save at least 8 babypages, you can create a book.
              </p>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-md-3">
            {stripeBookTypes.map((bookType: any) => (
              <div
                className="col"
                style={{
                  marginBottom: "1rem",
                }}
                key={bookType.id}
              >
                <div className="row justify-content-center">
                  <div
                    className="row"
                    style={{
                      paddingRight: "0px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{
                        width: "90%"
                      }}
                      src={bookType.image}
                      alt="Book Type Thumbnail"
                    />
                  </div>
                  <div className="col-7">
                    <div className="row justify-content-center align-items-baseline align-center text-center">
                      <div className="col-10">
                        <h3
                          className="m-0"
                          style={{
                            color: "rgb(108 111 121)",
                            fontSize: "0.9rem",
                          }}
                        >
                          <strong>{bookType.title}</strong>
                        </h3>
                        <p
                          style={{
                            fontWeight: 700,
                            color: "#939aa9",
                            marginBlockEnd: "unset"
                          }}
                        >
                          {bookType.description}
                        </p>
                        <p
                          style={{
                            color: "rgb(108 111 121)"
                          }}
                        >
                          <strong>Starting at ${(bookType.price / 100).toFixed(2)}</strong>
                        </p>
                        <button
                          style={{ width: "70%" }}
                          onClick={() => {
                            setBookType(bookType.id);
                          }}
                        >
                          Select
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </TabPane>}
        <TabPane tab="My Books" key={showUserBooks ? "create-a-book" : "my-books"} >
          <div className="pb-5 book-list">
            {books.books.length !== 0 && (
              <FloatButton
                type="primary"
                shape="circle"
                icon="reload"
                position="top-right"
                unFixed={true}
                onClick={() => {
                  setLoading(true);

                  sagaMiddleware.run<any>(fetchBooks, (err: string | null) => {
                    setLoading(false);
                    if (err) {
                      Modal.error({
                        title: "Error",
                        content: err,
                      });
                    }
                  });
                }}
                hasHeader
              />
            )}
             {!filteredBooks.length ? (
              <div className="py-4">
                <div className="container">
                  <div className="row justify-content-center align-items-center py-4 no-pages" id="placeholder">
                    <img
                      className="placeholder"
                      src={require('../../assets/img/chandelier.png')}
                      alt="Book Type Thumbnail"                    
                    />
                    <h2 className="no-books">
                      Hang on!
                    </h2>
                    <h3 className="centered px-3">
                    You haven't created any books yet. You need at least 8 BabyPages to create a book.
                    </h3>
                  </div>
                </div>
              </div>
            ) : (
              <BookList
                setLoading={setLoading}
                loading={loading}
                books={filteredBooks}
                stripeProducts={stripeProducts}
                isGuestUser={user.guest_user}
                isPlusUser={!!user.user_level}
              />
            )}
            {loading && (
              <div className="mx-auto d-table my-3">
                <Spin
                  indicator={
                    <Icon type="loading" style={{ fontSize: 24 }} spin />
                  }
                />
              </div>
            )}
          </div>
        </TabPane>
      </Tabs>
    </BaseLayout>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  books: state.books,
  babies: state.baby.babies,
  stripeProducts: state.stripeProduct,
  user: state.user,
});

export default connect(mapStateToProps)(BooksPage);
