import React, { useEffect } from 'react';
import BaseLayout from '../../components/BaseLayout/BaseLayout';
import './Maintenance.scss'
import { AppState } from '../../store';
import { connect } from 'react-redux';
import { Maintenance as maintenanceType } from "../../interfaces";
import { useHistory } from 'react-router-dom';
import CryBaby from '../../assets/img/crybaby.jpg';
import Facebook from '../../components/Icons/SocialMedia/Facebook';
import Instagram from '../../components/Icons/SocialMedia/Instagram';
import Pinterest from '../../components/Icons/SocialMedia/Pinterest';
import Twitter from '../../components/Icons/SocialMedia/Twitter';
import { useWindowSize } from '../../hooks/WindowsSize';


interface Props {
  maintenance?: maintenanceType;
}

const Maintenance: React.FC<Props> = ({maintenance}) => {
  const history = useHistory();
  const [width] = useWindowSize();
  let responsiveClass = width > 820 ? "" : "maintenance-container";

  useEffect(() => {
    responsiveClass = width > 820 ? "" : "maintenance-container"
  }, [width])

  useEffect(() => {
    if(!maintenance?.in_maintenance) {
      history.push("/")
    }
  }, [maintenance?.in_maintenance])
  return (
  <BaseLayout
    title='BabyPage - Maintenance'
    hideHeader
    hideChildMenu
    hideFooter
    hideBanner
    pageStyle={{ position: 'unset', minHeight: 'unset' }}
    contentStyle={{ padding: 0 }}
  >
    <div className="container">
    <div className={`d-flex flex-column justify-content-center align-items-center ${responsiveClass}`}>
      <img src={CryBaby} className='maintenance-image' />
      <div className="d-flex flex-column justify-content-center align-items-center my-4">
      <h1 className='maintenance-heading'>Uh-oh! Don't Cry!</h1>
      <p className='maintenance-text' >
       {maintenance?.body_message}
      </p>
      <p className='maintenance-text mb-0'>We apologize for any inconvenience.</p>
      </div>

      <div className="d-flex justify-content-center align-items-center">
          <Twitter classes='mx-2 clickable social-icon' />
          <Facebook classes='mx-2 clickable social-icon' />
          <Instagram classes='mx-2 clickable social-icon' />
          <Pinterest classes='mx-2 clickable social-icon' />
      </div>
    </div>
    </div>
  </BaseLayout>
)};

const mapStateToProps = (state: AppState) => ({
  babySelected: state.baby.babySelected,
  user: state.user,
  invitationCode: state.guestUsers.deepLinkInfo?.invitation_code,
  maintenance: state.maintenance
});


export default connect(mapStateToProps)(Maintenance);
