import { Background } from '../interfaces';

const backgrounds: Background[] = [
  { id: 1, color: '#ffeaea', isPlus: false },
  { id: 2, color: '#eaf2ff', isPlus: false },
  { id: 3, color: '#eafff0', isPlus: false },
  { id: 4, color: '#f5eaff', isPlus: false },
  { id: 5, color: '#fff2ea', isPlus: false },
  { id: 6, color: '#fffcea', isPlus: false },
  { id: 7, color: '#eaffff', isPlus: false },
  { id: 8, color: '#e9e9e9', isPlus: false },
  { id: 9, color: '#ffffff', isPlus: false }
];

export default backgrounds;
