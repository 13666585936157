// Base Aspect of iPhone 8
const ASPECT_BASE = 667 / 375;

/**
 * Get Browser Height (check the highest width of browser)
 */
export const getBrowserHeight = (): number =>
  Math.max(
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight,
    window.outerHeight
  );

/**
 * Get Browser Width (check the highest width of browser)
 */
export const getBrowserWidth = (): number =>
  Math.max(
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth,
    window.outerWidth
  );

export const getBrowserAspect = () => {
  const [width, height] = [getBrowserWidth(), getBrowserHeight()];
  return width > height ? width / height : height / width;
};

/**
 * Return a dynamic size based on browser width
 * (Base size is used in iPhone 6/7/8 width, 375px)
 */
export const dynamicSize = (size: number): number => {
  const width = getBrowserWidth();
  if (width < 768) {
    return width * (size / 375);
  } else {
    const aspectBrowser = getBrowserAspect();
    const proportion = aspectBrowser / ASPECT_BASE;
    return proportion * size;
  }
};

/**
 * Get Proportional Size for Fonts/Margins/Paddings, ...
 * @param {number} baseSize     // Base Size (Using Dynamic Size)
 * @param {number} proportion   // Proportion Size
 */
export const getProportionalSize = (
  baseSize: number,
  proportion: number,
  contentScale?: number
) => {
  if (!contentScale) contentScale = getContentScale();
  return (baseSize * proportion) / contentScale;
};

const getContentScale = (): number => {
  const width = getBrowserWidth();

  if (width >= 1980) {
    return 450;
  } else if (width >= 1440) {
    return 350;
  } else if (width >= 1024) {
    return 320;
  } else {
    return 280;
  }
};

export const isGreaterAllowedThanMaxHeight = async (
  file: File | Blob,
  maxHeightAllowed: number = 3300
) => {
  const { height: currentHeightOfFile } = await createImageBitmap(file);
  return currentHeightOfFile > maxHeightAllowed;
};
