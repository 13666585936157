import {Button, Input, Radio, Select} from "antd";
import Form from "antd/lib/form";
import {RadioChangeEvent} from "antd/lib/radio";
import React from "react";
import {useHistory} from "react-router";
import isEmail from "validator/lib/isEmail";
// import matches from "validator/lib/matches";

interface Props {
  handleSubmit: (event: any) => void;
  setGuestUserData: Function;
  guestUserData: any;
  familyRelationshipNames:any[];
  loading: boolean;
}

const GuestUserForm: React.FC<Props> = ({handleSubmit, setGuestUserData, guestUserData, familyRelationshipNames, loading}) => {
  const history = useHistory();

  return (
    <Form onSubmit={handleSubmit} className="w-100">
      <div className="container">
        <div className="row justify-content-center justify-content-md-start align-items-center pt-4">
          <div className="col-12 col-md-6">
            <label>Full Name</label>
            <Form.Item
            >
              <Input
                className="w-100"
                placeholder="Full Name"
                maxLength={50}
                value={guestUserData.fullName}
                disabled={!!guestUserData.id}
                onChange={(e) => {
                  const value = e.target.value;
                  setGuestUserData((state: any) => ({
                    ...state,
                    fullName: value
                  }));
                }}
              />
            </Form.Item>
          </div>
          <div className="col-12 col-md-6">
            <label>Email</label>
            <Form.Item
            >
              <Input
                className="w-100"
                placeholder="Email"
                maxLength={100}
                value={guestUserData.email}
                disabled={!!guestUserData.id}
                onChange={(e) => {
                  const value = e.target.value
                  setGuestUserData((state: any) => ({
                    ...state,
                    email: value
                  }));
                }}
              />
            </Form.Item>
          </div>
          <div className="col-12 col-md-6 justify-content-start">
            <Radio.Group
              value={guestUserData.permission}
              style={{
                padding: 0
              }}
              onChange={(e: RadioChangeEvent) => {
                const value = e.target.value
                setGuestUserData((state: any) => ({
                  ...state,
                  permission: value
                }));
              }}
            >
              <label>Permissions</label>
              <br/>
              <Radio key="Read-Only" value="Read-Only">
                Read-Only
              </Radio>
              <br/>
              <Radio key="Full-Access" value="Full Access">
                Full Access
              </Radio>
            </Radio.Group>
          </div>
          <div className='col-12 col-md-6'>
            <label>Relationship</label>
            <br/>
            <Select
              placeholder='Relationship'
              className='w-100'
              defaultValue=''
              value={familyRelationshipNames.length > 0 ? guestUserData.family_relationship_id : null}
              onChange={(value: any) => {
                setGuestUserData((state: any) => ({
                  ...state,
                  family_relationship_id: value
                }));
              }}
            >
              {familyRelationshipNames.map(f => (
                <Select.Option key={f.id} value={f.id}>
                  {f.name}
                </Select.Option>
              ))}

            </Select>
          </div>
        </div>
        <div className="row justify-content-center align-items-end"
             style={{
               position: 'absolute',
               bottom: 100,
               width: '100%'
             }}>
          <div className={"col-8 col-md-3"}>
            <Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                ghost
                shape="round"
                disabled={
                  loading ||
                  !guestUserData.fullName ||
                  !isEmail(guestUserData.email) ||
                  !guestUserData.permission
                }
                className="w-100 mx-auto d-table"
                style={{maxWidth: 300}}
              >
                {guestUserData.id ? 'Save' : 'Send Invitation'}
              </Button>
            </Form.Item>
          </div>
          <div className="col-8 col-md-3">
            <Form.Item>
              <Button
                htmlType="button"
                type="primary"
                shape="round"
                className="w-100 mx-auto d-table"
                onClick={() => history.push("/guest-users")}
                style={{maxWidth: 300}}
                disabled={loading}
              >
                Cancel
              </Button>
            </Form.Item>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default GuestUserForm;
