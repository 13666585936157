import { Button, Modal, Tooltip } from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import TruncateMarkup from "react-truncate-markup";
import { highResContent } from "../../env";
import { useWindowSize } from "../../hooks/WindowsSize";
import {
  Background,
  Border,
  Illustration,
  Milestone,
  SimplifiedAnswer,
} from "../../interfaces";
import { sagaMiddleware } from "../../store";
import { getFirstViewOfBabyPageContent } from "../../store/sagas/user";
import {
  dynamicSize,
  getBrowserAspect,
  getProportionalSize,
} from "../../utils/size";
import ContentEditAnswers from "./ContentEditAnswers";
import queryString from "query-string";
import { useLocation } from "react-router";
import { isMobile } from "react-device-detect";

interface Props {
  birthDate: string;
  font: string;
  textAlign: "start" | "center" | "end";
  subTitle: string;
  backgroundSelected?: Background;
  borderSelected?: Border;
  illustrationSelected?: Illustration;
  answersArray: SimplifiedAnswer[];
  textAnswers: string;
  labelUrl?: string;
  showEditButton?: boolean;
  hidden?: boolean; // Loading reference (if user is saving babypage)
  setIsTruncated: (isTruncated: boolean) => void;
  setSortedAnswers: (orderedAnswers: SimplifiedAnswer[]) => void;
  setCustomAnswers: (changedAnswers: SimplifiedAnswer[]) => void;
  addCustomAnswer: (answer: string) => void;
  removeAnswer: (index: number) => void;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setSubTitle: React.Dispatch<React.SetStateAction<string>>;
  ignoreMargin?: boolean;
  finish?: boolean;
  milestoneType?: Milestone;
  babyPageId?: any;
  setMilestoneDate?: React.Dispatch<React.SetStateAction<string>>;
  milestoneDate?: string;
  setMilestoneAsFirstLine?: React.Dispatch<React.SetStateAction<boolean>>;
  milestoneAsFirstLine?: boolean
}

let initialSize = {
  title: 13,
  subTitle: 16,
  mtTitle: 2,
  mtSubTitle: 2,
  mbSubTitle: 8,
  myAnswers: 3,
  ptContent: 20,
  pbContent: 20,
  ptContentIl: 10,
  borderHeight: 35,
  illustrationSize: 75,
};

const Content: React.FC<Props> = ({
  birthDate,
  subTitle,
  font,
  textAlign,
  backgroundSelected,
  borderSelected,
  illustrationSelected,
  answersArray,
  textAnswers,
  labelUrl,
  showEditButton,
  hidden,
  setIsTruncated,
  setSortedAnswers,
  setCustomAnswers,
  addCustomAnswer,
  removeAnswer,
  setTitle,
  setSubTitle,
  ignoreMargin,
  finish,
  milestoneType,
  babyPageId,
  setMilestoneDate,
  milestoneDate,
  setMilestoneAsFirstLine,
  milestoneAsFirstLine
}) => {
  const [width] = useWindowSize();
  const [sizes, setSizes] = useState({ ...initialSize });
  const [isEditButtonHighlighted, setIsEditButtonHighlighted] = useState(false);
  const [
    highlightEditButtonInterval,
    setHighlightEditButtonInterval,
  ] = useState<any>(false);
  const [modalEdit, setModalEdit] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    sagaMiddleware.run<any>(
      getFirstViewOfBabyPageContent,
      (err?: string, firstViewOfBabyPageContent?: string) => {
        if (!!err) {
          Modal.error({
            title: "Error",
            content: err,
          });

          return;
        }

        if (!firstViewOfBabyPageContent) {

          let contentMessage: string;
          if (babyPageId) {
            contentMessage = 'Click on the text side to edit the language of your babypage or click on the photo side to edit the photos.';
          } else {
            contentMessage = 'You can click on the pencil icon in the lower left hand corner to edit the page language, title, and sub-title.';
          }
          Modal.info({
            title: "Hi there!",
            content:
              contentMessage
          });

          setIsEditButtonHighlighted(true);
          setHighlightEditButtonInterval(
            setInterval(() => {
              setIsEditButtonHighlighted((highlight) => !highlight);
            }, 700)
          );
        }
      }
    );
  }, []);

  useEffect(() => {
    const getSize = (size: number) =>
      getProportionalSize(
        dynamicSize(size),
        contentRef.current !== null
          ? hidden
            ? highResContent.width
            : contentRef.current.offsetWidth
          : width
      );

    let titleInitialSize = initialSize.title;
    let subTitleInitialSize = initialSize.subTitle;

    if (font === "Montserrat") {
      titleInitialSize = initialSize.title * 0.85;
      subTitleInitialSize = initialSize.subTitle * 0.85;
    } else if (font === "JosefinSans") {
      titleInitialSize = initialSize.title * 0.95;
      subTitleInitialSize = initialSize.subTitle * 0.95;
    } else if (font === "EBGaramond") {
      titleInitialSize = initialSize.title * 1.05;
      subTitleInitialSize = initialSize.subTitle * 1.05;
    } else if (font === "BigCaslon") {
      titleInitialSize = initialSize.title * 1;
      subTitleInitialSize = initialSize.subTitle * 1;
    } else if(font === "StyleScript-Regular") {
      titleInitialSize = initialSize.title * 1.3
      subTitleInitialSize = initialSize.subTitle * 1.3;
    } 

    if(hidden && isBirthAnnoMilestone) {
      titleInitialSize = 120;
      subTitleInitialSize = 100;
    } else if (hidden) {
      titleInitialSize = font === "StyleScript-Regular" ? 100 : 80;
      subTitleInitialSize = font === "StyleScript-Regular" ? 120 : 100;
    }
    
    const initialTitleSize = getSize(titleInitialSize);
    const initialSubtitleSize = getSize(subTitleInitialSize);
    let newTitleFontSize = initialTitleSize;
    let newSubtitleFontSize = initialSubtitleSize



    setSizes({
      title: titleInitialSize,
      subTitle: subTitleInitialSize,
      mtTitle: hidden ? 20 : initialSize.mtTitle,
      mtSubTitle: hidden ? 0 : initialSize.mtSubTitle,
      mbSubTitle: hidden ? 0 : initialSize.mbSubTitle,
      myAnswers:initialSize.myAnswers,
      ptContent:hidden ? 100 : initialSize.ptContent,
      pbContent:hidden ? 200 :initialSize.pbContent,
      ptContentIl:hidden ? 100 :initialSize.ptContentIl,
      borderHeight:initialSize.borderHeight,
      illustrationSize:hidden ? 380 : initialSize.illustrationSize,
    });
  }, [contentRef, hidden, width, font, subTitle, birthDate]);

  const resizeFont = (
    initialFontSize: number,
    startResizingAt: number,
    textLenght: number
  ) => {
    return (
      initialFontSize *
      (startResizingAt /
        (textLenght > startResizingAt ? textLenght : startResizingAt))
    );
  };

  const [paddingTop, answerStyle] = useMemo(() => {
    let paddingTop = sizes.ptContent;
    const answerStyle: React.CSSProperties = {
      textAlign,
      maxWidth: "90%",
      margin: `0 auto`
    };

    if (!!illustrationSelected) {
      paddingTop = sizes.ptContentIl;
      answerStyle.marginBottom = 0;
    }
    return [paddingTop, answerStyle];
  }, [illustrationSelected, sizes, textAlign]);

  const contentStyle: React.CSSProperties = {
    // Changing the font-colour for BabyPage content div to avoid the .65 opacity we use globally
    color: "black",
    fontFamily: font,
    paddingTop: !labelUrl ? paddingTop : 0,
    backgroundColor: !!backgroundSelected
      ? backgroundSelected.color
      : "#ffffff",
  };

  if (!textAnswers || !textAnswers.length) {
    contentStyle.display = "flex";
    contentStyle.alignItems = "center";
    contentStyle.paddingTop = 0;
  }

  // Get bigger title / subtitle for "Birth Announcement" milestone
  const location = useLocation();
  let milestoneIdCode = queryString.parse(location.search).milestoneId;
  let isBirthAnnoMilestone = false;
  if (
    (!!milestoneIdCode && parseInt(milestoneIdCode.toString()) === 49) ||
    milestoneType?.title === "Birth Announcement"
  ) {
    isBirthAnnoMilestone = true;

    if(font === "Montserrat-Regular") {
      initialSize.title = 17;
      initialSize.subTitle = 14.5;
    } else {
      initialSize.title = 17;
      initialSize.subTitle = 15.5;
    }
    



    contentStyle.lineHeight = 1.4;
  }

  const [lines, fsAnswers] = useMemo(() => {
    const getSize = (size: number, contentScale?: number) =>
      getProportionalSize(
        dynamicSize(size),
        contentRef.current !== null
          ? hidden
            ? highResContent.width
            : contentRef.current.offsetWidth
          : width,
        contentScale
      );

    const aspect = getBrowserAspect();

    let baseSize = 18

    if (font === "Montserrat") {
      baseSize = baseSize * 0.95;
    } else if (font === "JosefinSans") {
      baseSize = baseSize * 1;
    } else if (font === "EBGaramond") {
      baseSize = baseSize * 1.05;
    } else if (font === "BigCaslon") {
      baseSize = baseSize * 1.05;
    }

    let minusIlustration = 0;
    if(illustrationSelected) {
      if(hidden) {
        if (textAnswers.length < 150) {
          minusIlustration = 16
        } else if (textAnswers.length < 200) {
          minusIlustration = 6.5 
        } else {
          minusIlustration = 3.5 
        }
         
      } else if(!hidden) {
        if (textAnswers.length < 150) {
          minusIlustration = 4
        } 
        else if (textAnswers.length < 200) {
          minusIlustration = 2
        } else {
          minusIlustration = 1
        }
        
      }
    }
    if (textAnswers.length < 35) {
      return [8, hidden ?  
              font === "StyleScript-Regular" ?
              145 - minusIlustration :
              150 - minusIlustration :
              font === "StyleScript-Regular" ?
              baseSize + 4 - minusIlustration :
              baseSize + 7 - minusIlustration];
    } else if (textAnswers.length < 100) {
      return [5, hidden ?
         font === "StyleScript-Regular" ?
         130 - minusIlustration :  
         115 - minusIlustration  :
         font === "StyleScript-Regular" ?
        baseSize + 1 - minusIlustration :
        baseSize - minusIlustration ];
    } else if (textAnswers.length < 150) {
      return [6, hidden ? 
                  font === "StyleScript-Regular" ? 
                  120 - minusIlustration :  
                  100 - minusIlustration  : 
                  font === "StyleScript-Regular" ?
                  baseSize - 1 - minusIlustration :
                  baseSize - 2 - minusIlustration];
    } else if (textAnswers.length < 200) {
      return [7, hidden ? 
        font === "StyleScript-Regular" ? 
        110 - minusIlustration :
        87 - minusIlustration   :
        baseSize - 4 - minusIlustration];
    } else if (textAnswers.length < 250) {
      return [8, hidden ? 
        font === "StyleScript-Regular" ? 
        102 - minusIlustration :
        77 - minusIlustration  :
        font === "StyleScript-Regular" ?
        baseSize - 3 - minusIlustration : 
        baseSize - 6 - minusIlustration ];
    } else if (textAnswers.length < 300) {
      return [8, hidden ? font === "Montserrat" ?
                           67.5 - minusIlustration :
                           font === "StyleScript-Regular" ? 
                           92 - minusIlustration : 
                           72.5 - minusIlustration : 
                           font === "StyleScript-Regular" ? 
                           baseSize - 3.5 - minusIlustration :
                           baseSize - 7.5 - minusIlustration ]; 
    } else if (textAnswers.length < 400) {
      return [ 9, hidden ? font === "Montserrat" ?
                          56 - minusIlustration  :
                          font === "NunitoSans" ?
                          64 - minusIlustration :
                          font === "JosefinSans" ?
                          63 - minusIlustration :
                          font === "StyleScript-Regular" ? 
                          80 - minusIlustration :
                          65 - minusIlustration :
                          font === "StyleScript-Regular" ? 
                          baseSize - 5 - minusIlustration :
                          baseSize - 8.7 - minusIlustration];
    } else if (textAnswers.length < 500) {
      return [ 10, hidden ? font === "Montserrat" ?
                            50 - minusIlustration  :
                            font === "JosefinSans" ?
                            56 - minusIlustration :
                            font === "StyleScript-Regular" ? 
                            75 - minusIlustration :
                            58 - minusIlustration  :
                            font === "StyleScript-Regular" ? 
                            baseSize - 6.5 - minusIlustration :
                            baseSize - 9.1 - minusIlustration] ;
    } else if (textAnswers.length < 550) {
      return [11, hidden ? font === "Montserrat" ? 
                          50 - minusIlustration  :
                          font === "StyleScript-Regular" ? 
                          70 - minusIlustration :
                          56 - minusIlustration  : 
                          font === "StyleScript-Regular" ? 
                          baseSize - 7.7 - minusIlustration :
                          baseSize - 9 - minusIlustration];
    } else if (textAnswers.length < 600) {
      return [hidden ? 12 : 11, hidden ? font === "Montserrat" ? 
                                        50 - minusIlustration  :
                                        font === "StyleScript-Regular" ? 
                                        72 - minusIlustration :
                                        54 - minusIlustration  :
                                        font === "StyleScript-Regular" ?
                                        baseSize - 6.5 - minusIlustration :
                                        baseSize - 9.5 - minusIlustration];
    } else {
      return [12, hidden ? 51 : baseSize - 9.8];
    }
  }, [contentRef, width, hidden, textAnswers, isBirthAnnoMilestone, font, illustrationSelected]);

  const editContent = useCallback(
    (
      editTitle?: string,
      editSubTitle?: string,
      editAnswers?: SimplifiedAnswer[]
    ) => {
      setTitle(editTitle || "");
      setSubTitle(editSubTitle || "");

      if (editAnswers) {
        setSortedAnswers(editAnswers);
      }
      setModalEdit(false);
    },
    [setTitle, setSubTitle, setSortedAnswers]
  );

  // If user is saving babypage
  if (hidden) {
    contentStyle.height = highResContent.height;
    contentStyle.width = highResContent.width;
  }

  return (
    <>
      <div
        id="content-bp"
        className={`bp-content${hidden ? "" : " elevation-light"}${
          ignoreMargin ? "" : " mx-auto"
        }${finish ? " finish" : ""}`}
        ref={contentRef}
        style={contentStyle}
      >
        <div
          className={`d-flex flex-column ${
            !labelUrl ? "w-80" : "w-100"
          } mx-auto${!!textAnswers ? " h-100" : ""}`}
        >
          {!!illustrationSelected && !labelUrl && (
            <img
              src={
                illustrationSelected.image_b64 || illustrationSelected.image_url
              }
              alt={illustrationSelected.name}
              className="mx-auto"
              style={{
                width: "auto",
                height: sizes.illustrationSize,
              }}
            />
          )}
          {!labelUrl && (
            <TruncateMarkup key={Date.now() + 10} lines={1}>
              <p
                className="mb-0"
                style={{
                  fontSize: sizes.title,
                  marginTop: sizes.mtTitle,
                  lineHeight: 1.2,
                  fontFamily: contentStyle.fontFamily,
                }}
              >
                {isBirthAnnoMilestone ? "" : subTitle}
              </p>
            </TruncateMarkup>
          )}
          {!labelUrl && (
            <TruncateMarkup key={Date.now() + 20} lines={1}>
              <p
                className="bp-content__event"
                style={{
                  fontSize: sizes.subTitle,
                  marginTop: sizes.mtSubTitle,
                  marginBottom: sizes.mbSubTitle,
                  lineHeight: 1.2,
                  fontFamily: contentStyle.fontFamily,
                }}
              >
                {birthDate}
              </p>
            </TruncateMarkup>
          )}
          <div className="d-flex flex-column justify-content-between h-100">
            {!!borderSelected && !labelUrl ? (
              <img
                src={borderSelected.image_b64 || borderSelected.image_url}
                alt={borderSelected.category}
                style={{
                  width: "100%",
                  display: "table",
                  margin: "0 auto",
                }}
              />
            ) : null}
            {!!labelUrl ? (
              <img src={labelUrl} alt="Label Texts" className="w-100 h-100" />
            ) : (
              !!textAnswers && (
                <div
                  id="bp-answers"
                  className="bp-content__answers"
                  style={{
                    ...answerStyle,
                    width: "100%",
                    fontSize: fsAnswers,
                    whiteSpace: "pre-wrap",
                    overflowWrap: "break-word",
                    lineHeight: textAnswers.length > 550 ? 1.3 : 1.5,
                  }}
                >
                  <TruncateMarkup
                    key={Date.now()}
                    lines={lines}
                    onTruncate={(truncated) => setIsTruncated(truncated)}
                  >
                    <p
                      className="m-0"
                      style={{
                        fontFamily: contentStyle.fontFamily,
                      }}
                    >
                      {textAnswers}
                    </p>
                  </TruncateMarkup>
                </div>
              )
            )}
            {!illustrationSelected &&
            !!textAnswers &&
            !!borderSelected &&
            !labelUrl ? (
              <img
                src={borderSelected.image_b64 || borderSelected.image_url}
                alt={borderSelected.category}
                style={{
                  width: "100%",
                  display: "table",
                  margin: "0 auto",
                  marginBottom: sizes.pbContent,
                }}
              />
            ) : (
              <div style={{ paddingBottom: sizes.pbContent }}></div>
            )}
          </div>
        </div>
        {!!showEditButton && (
          <Tooltip
            placement="right"
            title="you can delete, rewrite or edit your story by clicking on this button"
            arrowPointAtCenter
            trigger={isMobile ? "focus" : "hover"}
            destroyTooltipOnHide={true}
          >
            <Button
              type="primary"
              shape="circle"
              ghost
              icon="edit"
              className="edit-button"
              onClick={() => {
                setModalEdit(true);
                clearInterval(highlightEditButtonInterval);
                setHighlightEditButtonInterval(false);
                setIsEditButtonHighlighted(false);
              }}
              style={{
                boxShadow: isEditButtonHighlighted
                  ? "0px 0px 5px 8px #fbafa5"
                  : "none",
                transition: "0.5s ease-in 0.5 ease-out",
              }}
            />
          </Tooltip>
        )}
      </div>
      <ContentEditAnswers
        visible={modalEdit}
        onCancel={() => setModalEdit(false)}
        onOk={editContent}
        title={birthDate}
        subTitle={subTitle}
        answers={answersArray}
        setSortedAnswers={setSortedAnswers}
        addCustomAnswer={addCustomAnswer}
        removeAnswer={removeAnswer}
        isBirthAnnoMilestone={isBirthAnnoMilestone}
        milestoneType={milestoneType}
        setMilestoneDate={setMilestoneDate}
        milestoneDate={milestoneDate}
        setMilestoneAsFirstLine={setMilestoneAsFirstLine}
        milestoneAsFirstLine={milestoneAsFirstLine}
      />
    </>
  );
};

export default Content;
