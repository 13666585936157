import React, { useState } from "react";
import { DashboardInfo } from "../../interfaces";
import { connect } from "react-redux";
import { Card, Button } from "antd";
import { Link, useHistory } from "react-router-dom";
import { useWindowSize } from "../../hooks/WindowsSize";
import { AppState } from "../../store";
import ModalPlus from "../ModalPlus/ModalPlus";
import guestUserImg from "../../assets/img/icon-manage-guest-users.png"
import "./ManageUsers.scss"

interface Props {
  baby: DashboardInfo;
  user_level?: number;
}

const ManageUsers: React.FC<Props> = ({ baby, user_level }) => {
  const history = useHistory();
  const [plusOpen, setPlusOpen] = useState<boolean>(false);
  const [ width ] = useWindowSize();

  return (
    <>
      <Card className="d-block dashboard-card">
        <div className="col-12 card-title mt-2 mb-3">
          <h2>MANAGE GUEST USERS</h2>
        </div>
        <div className="row justify-content-center px-3">
          { width > 767 
            ? null 
            : <img src={guestUserImg} className="guest-user-image mx-auto" />
          }
          <div className="col-12 px-5 margin-mobile">
            <Button
              type="primary"
              ghost
              shape="round"
              className="w-100 green-button dashboard-button"
              onClick={() => {
                user_level === 1
                  ? history.push("/guest-users")
                  : setPlusOpen(true);
              }}
            >
              Share BabyPage Profile
            </Button>
          </div>
          {user_level === 1 ? null : (
            <div className="col-12 mt-3 text-center">
              <Link to="/cart/subscription/yearly">
                Not a PLUS member? Upgrade Now!
              </Link>
            </div>
          )}
        </div>
      </Card>

      <ModalPlus
        visible={plusOpen}
        onClose={() => {
          setPlusOpen(false);
        }}
      />
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  user_level: state.user.user_level,
});

export default connect(mapStateToProps)(ManageUsers);
