import React from "react";

function SearchBarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#658071"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        transform="translate(1 1)"
      >
        <circle cx="6.222" cy="6.222" r="6.222"></circle>
        <path d="M14 14l-3.383-3.383"></path>
      </g>
    </svg>
  );
}

export default SearchBarIcon;