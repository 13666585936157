import { Button, Card, Col, Row } from "antd";
import React from "react";
import { QuestionItem } from "../../interfaces";

interface Props {
  questions: QuestionItem[];
  setSelectedQuestion: Function;
}

const QuestionList: React.FC<Props> = ({ questions, setSelectedQuestion }) => {
  return (
    <>
      {questions.length > 0 &&
        questions.map(question => (
          <Row key={question.id} style={{ margin: "10px" }}>
            <Col>
              <Card
                hoverable={true}
                onClick={() => {
                  setSelectedQuestion(question);
                }}
              >
                <b>
                  <p>{question.title}</p>
                </b>
                <p>{question.question}</p>
              </Card>
            </Col>
          </Row>
        ))}
      <Row>
        <Col>
          <Button
            htmlType="submit"
            type="primary"
            className="mx-auto mt-1 mb-3 d-table"
            onClick={() => {
              setSelectedQuestion({
                id: 0,
                title: "",
                question: "",
                export: "",
                answers: [],
                multiple_choice: 0,
                milestone_id: 0,
                order: 0,
                created_at: "",
                deleted_at: "",
                updated_at: "",
                type: ""
              });
            }}
          >
            ADD QUESTION
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default QuestionList;
