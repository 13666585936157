import { Button, Card, Input, Layout, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { QuestionItem } from "../../interfaces";

const { Content } = Layout;

interface Props {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  selectedQuestion: QuestionItem;
  setSelectedQuestion: Function;
  handleClickOnDeleteButton: Function;
}

const QuestionForm: React.FC<Props> = ({
  selectedQuestion,
  handleSubmit,
  setSelectedQuestion,
  handleClickOnDeleteButton
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <Layout>
          <Content>
            <Row className="mb-10">
              <label>Title</label>
              <Input
                value={selectedQuestion.title}
                className="w-100"
                maxLength={255}
                onChange={e => {
                  e.persist();

                  setSelectedQuestion((selectedQuestion: any) => ({
                    ...selectedQuestion,
                    title: e.target?.value
                  }));
                }}
              />
            </Row>
            <Row className="mb-10">
              <label>Question</label>
              <TextArea
                value={selectedQuestion.question}
                className="w-100"
                maxLength={255}
                autoSize
                onChange={e => {
                  e.persist();

                  setSelectedQuestion((selectedQuestion: any) => ({
                    ...selectedQuestion,
                    question: e.target?.value
                  }));
                }}
              />
            </Row>
            <Row className="mb-10">
              <label>Answers</label>
              <TextArea
                value={selectedQuestion.answers}
                className="w-100"
                maxLength={255}
                autoSize
                onChange={e => {
                  e.persist();

                  setSelectedQuestion((selectedQuestion: any) => ({
                    ...selectedQuestion,
                    answers: e.target?.value
                  }));
                }}
              />
            </Row>
            <Row className="mb-10">
              <label>Export</label>
              <TextArea
                value={selectedQuestion.export}
                className="w-100"
                maxLength={255}
                autoSize
                onChange={e => {
                  e.persist();

                  setSelectedQuestion((selectedQuestion: any) => ({
                    ...selectedQuestion,
                    export: e.target?.value
                  }));
                }}
              />
            </Row>
            <Row className="mb-10">
              <label>Type of Answers</label>
              <Select
                value={selectedQuestion.multiple_choice}
                className="w-100"
                onChange={(value: number) =>
                  setSelectedQuestion((selectedQuestion: any) => ({
                    ...selectedQuestion,
                    multiple_choice: value
                  }))
                }
              >
                <Select.Option value={0}>Simple</Select.Option>
                <Select.Option value={1}>Multiple Choice</Select.Option>
              </Select>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Button
                type="primary"
                style={{ margin: "10px" }}
                htmlType="submit"
              >
                {selectedQuestion.id !== 0 ? "UPDATE" : "SAVE"}
              </Button>
              <Button
                type="primary"
                style={{ margin: "10px" }}
                onClick={() => {
                  setSelectedQuestion(null);
                }}
              >
                CANCEL
              </Button>
              {selectedQuestion.id !== 0 && (
                <Button
                  type="primary"
                  style={{ margin: "10px" }}
                  onClick={() => handleClickOnDeleteButton(selectedQuestion.id)}
                >
                  DELETE
                </Button>
              )}
            </Row>
          </Content>
        </Layout>
      </Card>
    </form>
  );
};

export default QuestionForm;
