import React, {useEffect} from "react"
import { newFont } from "../../interfaces/font"
import { Select, Spin, Icon } from "antd"

const { Option } = Select;

interface Props {
    font: string;
    setFont:(font: string, font_id:number) => void;
    fonts: newFont[];
    isPlus: boolean;
    selectedPlus?: { border: boolean; illustration: boolean; layout: boolean; font:boolean; };
    setSelectedPlus?: ({ border, illustration, layout, font }: any) => void;
    setPlusOpen?: React.Dispatch<React.SetStateAction<boolean>>;
} 


const FontSelect: React.FC<Props> = ({
    font,
    setFont,
    fonts,
    isPlus,
    selectedPlus,
    setSelectedPlus,
    setPlusOpen,
  }) => {

    const handleSelect  = (e:any) => {
        const selected = fonts.find((font) => font.name === e)
        if(!!selected) {
             if(!isPlus && !!selected.plus && !!setPlusOpen) {
                setPlusOpen(true);
            } else {
                setFont(selected.name, selected.id );
            }
        }  
    }

    useEffect(() => {
        const selectedFont = fonts.filter((currentFont) => currentFont.name === font);
        if(setSelectedPlus && selectedFont.length !== 0) {
            if(selectedFont[0].plus && !isPlus) {
                setSelectedPlus({
                    ...selectedPlus,
                    font: true,
                })
            } else {
                setSelectedPlus({
                    ...selectedPlus,
                    font: false,
                })
            } 
        }
    }, [font])

    return (
        fonts.length === 0 ? ( 
          <div className="container">
            <div className="row">
                <div className="mx-auto d-table my-5">
                    <Spin
                        indicator={
                        <Icon type="loading" style={{ fontSize: 24 }} spin />
                        }
                    />
                </div>
            </div>
          </div>
       ) : ( 
            <Select
                onChange={handleSelect}
                value={font} 
                style={{ fontFamily: font }}
                className="w-100 mb-3"
              >
                    {

            fonts.map((font, index) => {
            return (
                    <Option
                    key={index} 
                    value={font.name} 
                    style={{fontFamily: font.name}}
                    disabled={false}
                    > 
                        {font.name}
                        { isPlus ? null : font.plus ? 
                        ( <span className="ml-4" style={{color:"pink", fontFamily:"ProximaNova"}}>PLUS</span> )
                        : null
                        }
                    
                    </Option>
                )
                })}
              </Select>
        )
    )
}

export default FontSelect;