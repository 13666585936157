import { Button, DatePicker, Input, notification, Select, Table } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AnswersInterface,
  AnswersListInterface,
  FormListItem,
} from '../../../../interfaces/form';
import { sagaMiddleware } from '../../../../store';
import { getAnswers } from '../../../../store/sagas/form';
import { capitalizeTexts } from '../../../../utils/string';

interface Props {
  forms: FormListItem[];
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const AnswerList: React.FC<Props> = ({ forms, loading, setLoading }) => {
  const history = useHistory();

  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment().endOf('month'));
  const [userSearch, setUserSearch] = useState('');
  const [form, setForm] = useState<number>();
  const [answers, setAnswers] = useState<AnswersListInterface>({
    data: [],
    total: 0,
    per_page: 10,
    current_page: 1,
    last_page: 1,
    next_page_url: null,
    prev_page_url: null,
    has_more_pages: false,
    first_item: 1,
    last_item: 1,
  });
  const [debounce, setDebounce] = useState<NodeJS.Timeout>();

  useEffect(() => {
    setLoading(true);

    sagaMiddleware.run<any>(
      getAnswers,
      {
        startDate: moment()
          .startOf('month')
          .format('YYYY[-]MM[-]DD'),
        endDate: moment()
          .endOf('month')
          .format('YYYY[-]MM[-]DD'),
      },
      (err?: string, response?: AnswersListInterface) => {
        setLoading(false);

        if (!!err) {
          notification.error({
            message: 'Error',
            description: err,
          });
        } else if (!!response) {
          setAnswers(response);
        }
      },
      answers.current_page
    );
  }, [setLoading, answers.current_page]);

  const filterAnswers = useCallback(
    (filter: {
      startDate?: string;
      endDate?: string;
      formId?: number;
      user?: string;
    }) => {
      if (debounce) clearTimeout(debounce);

      setDebounce(
        setTimeout(() => {
          setLoading(true);

          sagaMiddleware.run<any>(
            getAnswers,
            filter,
            (err?: string, response?: AnswersListInterface) => {
              setLoading(false);

              if (!!err) {
                notification.error({
                  message: 'Error',
                  description: err,
                });
              } else if (!!response) {
                setAnswers(response);
              }
            }
          );
        }, 400)
      );
    },
    [setLoading, debounce]
  );

  return (
    <>
      <div className='row justify-content-center align-items-center mx-3'>
        <div className='col-12 col-lg-6 my-1'>
          <DatePicker.RangePicker
            value={[startDate, endDate]}
            onChange={e => {
              const newStart = e[0] ?? moment().startOf('month');
              const newEnd = e[1] ?? moment().endOf('month');

              setStartDate(newStart);
              setEndDate(newEnd);

              filterAnswers({
                startDate: newStart.format('YYYY[-]MM[-]DD'),
                endDate: newEnd.format('YYYY[-]MM[-]DD'),
                formId: !!form ? form : undefined,
                user: !!userSearch ? userSearch : undefined,
              });
            }}
            className='w-100'
          />
        </div>
        <div className='col-6 col-lg-3 my-1'>
          <Select
            placeholder='Form'
            value={form}
            onChange={(e: any) => {
              setForm(e);

              filterAnswers({
                startDate: startDate.format('YYYY[-]MM[-]DD'),
                endDate: endDate.format('YYYY[-]MM[-]DD'),
                formId: !!e ? e : undefined,
                user: !!userSearch ? userSearch : undefined,
              });
            }}
            className='w-100'
          >
            <Select.Option key='all' value={0}>
              All forms
            </Select.Option>
            {forms.map(f => (
              <Select.Option key={f.id} value={f.id}>
                {f.title}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className='col-6 col-lg-3 my-1'>
          <Input
            placeholder='User'
            value={userSearch}
            onChange={e => {
              setUserSearch(e.target.value);

              filterAnswers({
                startDate: startDate.format('YYYY[-]MM[-]DD'),
                endDate: endDate.format('YYYY[-]MM[-]DD'),
                formId: !!form ? form : undefined,
                user: !!e.target.value ? e.target.value : undefined,
              });
            }}
            className='w-100'
          />
        </div>
      </div>
      <Table
        dataSource={answers.data}
        loading={loading}
        bordered
        pagination={{
          current: answers.current_page,
          total: answers.total,
          pageSize: answers.per_page,
        }}
        onChange={e =>
          setAnswers(state => ({ ...state, current_page: e.current || 1 }))
        }
        className='m-3 elevation-5'
        rowKey={(record: AnswersInterface) => record.id.toString()}
      >
        <Table.Column
          title='Form'
          dataIndex='form'
          key='form'
          ellipsis
          render={(text: any, record: AnswersInterface) => (
            <span>{record.form?.title}</span>
          )}
        />
        <Table.Column
          title='User'
          dataIndex='user'
          key='user'
          ellipsis
          render={(text: any, record: AnswersInterface) => (
            <span>
              {capitalizeTexts(record.user?.first_name ?? '')}{' '}
              {capitalizeTexts(record.user?.last_name ?? '')}
            </span>
          )}
        />
        <Table.Column
          title='Answered At'
          dataIndex='created_at'
          key='created_at'
          ellipsis
          render={(text: any, record: AnswersInterface) => (
            <span>{moment(record.created_at).format('MMM[, ]Do[, ]YYYY')}</span>
          )}
        />
        <Table.Column
          title='Actions'
          dataIndex='id'
          render={(text: any, record: any) => (
            <Button
              icon='eye'
              type='primary'
              onClick={() =>
                history.push(`/admin/forms/answer?id=${record.id}`)
              }
            />
          )}
        />
      </Table>
    </>
  );
};

export default AnswerList;
