import { User } from '../../interfaces';
import {
  BaseAction,
  SUM_CHILD_COUNT,
  USER_CHANGE,
  USER_LOGOUT
} from '../actionTypes';
declare const FB: any;

const initialState: User = {};

export const user = (state: User = initialState, action: BaseAction): User => {
  switch (action.type) {
    case USER_CHANGE:
      return { ...state, ...action.payload };
    case SUM_CHILD_COUNT:
      return { ...state, babies_count: (state.babies_count || 0) + 1 };
    case USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
Object.freeze(user);

export const changeUser = (payload: any) => ({
  payload,
  type: USER_CHANGE
});
Object.freeze(changeUser);

export const logoutUser = () => {
  try {
    FB.getLoginStatus((response: any) => {
      if (response.status === 'connected') {
        FB.logout();
      }
    });
  } catch (error) {}

  return {
    type: USER_LOGOUT
  };
};
Object.freeze(logoutUser);

export const sumChildCount = () => ({
  type: SUM_CHILD_COUNT
});
Object.freeze(sumChildCount);

export default user;
