// login-token
import { Icon, Modal, notification, Spin } from 'antd';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { sagaMiddleware } from '../../store';
import { getAuthenticatedUserToken } from '../../store/sagas/user';
import rootSaga from '../../store/sagas';

const SignInToken: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const access_token: any = queryString.parse(location.search).token;
    const subscription: any = queryString.parse(location.search).subscription;

    if (!!access_token) {
      sagaMiddleware.run<any>(
        getAuthenticatedUserToken,
        access_token,
        (error?: string, user?: any) => {
          if (!!error) {
            Modal.error({
              title: 'Error',
              content: error,
            });
            history.push('/');
          } else {
            sagaMiddleware.run<any>(rootSaga, true);

            if (!!subscription && subscription !== '') {
              history.push(`/cart/subscription/${subscription}?babyList=true`);
            } else if (user.babies_count > 0) {
              history.push('/');
            } else {
              history.push('/step-2'); // Send user to next steps
            }
          }
        }
      );
    } else {
      history.push('/');
    }
  }, [history, location]);

  return (
    <div className='loading-fullscreen d-flex justify-content-center align-items-center'>
      <Spin indicator={<Icon type='loading' style={{ fontSize: 24 }} spin />} />
    </div>
  );
};

export default SignInToken;
