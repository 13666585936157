import { Product } from '../../interfaces';
import { BaseAction, GET_PRODUCTS, USER_LOGOUT } from '../actionTypes';

export interface ProductState {
  products: Product[];
}

const initialState: ProductState = {
  products: []
};

export const product = (
  state: ProductState = initialState,
  action: BaseAction
): ProductState => {
  switch (action.type) {
    case GET_PRODUCTS:
      return { ...state, products: [...action.payload] };
    case USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
Object.freeze(product);

const getProducts = (payload: Product[]) => ({
  payload,
  type: GET_PRODUCTS
});
Object.freeze(getProducts);

export { getProducts };
export default product;
