import { Alert, Icon, Select, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import justCenter from "../../assets/icons/icon-just-center.png";
import justLeft from "../../assets/icons/icon-just-left.png";
import justRight from "../../assets/icons/icon-just-right.png";
import backgrounds from "../../data/backgrounds";
import {
  Baby,
  Background,
  Border,
  Illustration,
  Milestone,
  SimplifiedAnswer
} from "../../interfaces";
import Content from "./Content";
import { Fonts } from "../../hooks/Fonts";
import FontSelect from "../FontSelect/FontSelect"
import queryString from "query-string";
import { useLocation } from "react-router";

const { Option } = Select;

interface Props {
  baby?: Baby;
  borderSelected?: Border;
  illustrationSelected?: Illustration;
  backgroundSelected?: Background;
  subTitle: string;
  birthDate: string;
  textAnswers: string;
  labelUrl?: string;
  font: string;
  textAlign: "start" | "center" | "end";
  setFont:(font: string, font_id:number) => void;
  setTextAlign: (textAlign: "start" | "center" | "end") => void;
  setBackground: (background: Background | undefined) => void;
  isPlus: boolean;
  showPlus: () => void;
  isTruncated: boolean;
  setIsTruncated: (isTruncated: boolean) => void;
  answersArray: SimplifiedAnswer[];
  setSortedAnswers: (orderedAnswers: SimplifiedAnswer[]) => void;
  setCustomAnswers: (changedAnswers: SimplifiedAnswer[]) => void;
  addCustomAnswer: (answer: string) => void;
  removeAnswer: (index: number) => void;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setSubTitle: React.Dispatch<React.SetStateAction<string>>;
  selectedPlus: { border: boolean; illustration: boolean; layout: boolean; font: boolean; };
  setSelectedPlus: ({ border, illustration, layout, font }: any) => void;
  milestoneType?: Milestone;
  setMilestoneDate?: React.Dispatch<React.SetStateAction<string>>;
  milestoneDate?: string;
  setMilestoneAsFirstLine?: React.Dispatch<React.SetStateAction<boolean>>;
  milestoneAsFirstLine?: boolean;
}

const EditTextBackground: React.FC<Props> = ({
  baby,
  subTitle,
  borderSelected,
  illustrationSelected,
  backgroundSelected,
  birthDate,
  textAnswers,
  labelUrl,
  font,
  textAlign,
  setFont,
  setTextAlign,
  setBackground,
  isPlus,
  showPlus,
  isTruncated,
  setIsTruncated,
  answersArray,
  setSortedAnswers,
  setCustomAnswers,
  addCustomAnswer,
  removeAnswer,
  setTitle,
  setSubTitle,
  milestoneType,
  setSelectedPlus,
  selectedPlus,
  setMilestoneDate,
  milestoneDate,
  setMilestoneAsFirstLine,
  milestoneAsFirstLine
}) => {
  const contentProps = {
    birthDate,
    subTitle,
    font,
    textAlign,
    borderSelected,
    illustrationSelected,
    showEditButton: true,
    backgroundSelected,
    textAnswers,
    labelUrl,
    isTruncated,
    setIsTruncated,
    answersArray,
    setSortedAnswers,
    setCustomAnswers,
    addCustomAnswer,
    removeAnswer,
    setTitle,
    setSubTitle,
    milestoneType: milestoneType,
    setSelectedPlus,
    selectedPlus,
    setMilestoneDate,
    milestoneDate,
    setMilestoneAsFirstLine,
    milestoneAsFirstLine
  };
  const fonts = Fonts (isPlus)
  const location = useLocation();
  let missingContent: any = queryString.parse(location.search).both;
  return !!baby ? (
    <div className="row justify-content-center align-items-center mt-3">
      <div className="col-12">
        {/* {!!fonts.length ? 
          fonts[0].name !== "" ? (
            <>
            <p style={{fontFamily: fonts[0].name, fontSize: 25}}>hola</p>
            <p style={{ fontSize: 25}}>hola</p>
            </>
  
          ) : null 
        : null}
         */}
        <Content {...contentProps} />
        {isTruncated && (
          <div className="m-3">
            <Alert
              message="Warning"
              description={`
              Text surpasses space available in the overlay.
              The remaining text will not be reflected in the BabyPage.
              You may adjust the overlay to show additional text.
            `}
              type="warning"
              showIcon
            />
          </div>
        )}
      </div>
      <div className="col-12 p-1">
        <Tabs defaultActiveKey="0">
          <Tabs.TabPane tab="Backgrounds" key="0">
            <div className="border-illustration">
              {backgrounds.map((background: Background, index: number) => (
                <div
                  key={index}
                  className={`select-background${
                    !!backgroundSelected &&
                    backgroundSelected.id === background.id
                      ? " selected"
                      : ""
                  }`}
                  style={{ backgroundColor: background.color }}
                  onClick={() =>
                    isPlus || !background.isPlus
                      ? !backgroundSelected ||
                        background.id !== backgroundSelected.id
                        ? setBackground(background)
                        : setBackground(undefined)
                      : showPlus()
                  }
                >
                  {!!backgroundSelected &&
                    background.id === backgroundSelected.id && (
                      <Icon
                        type="check-circle"
                        theme="filled"
                        style={{ fontSize: 18 }}
                      />
                    )}
                </div>
              ))}
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Font" key="1">
            <div className="p-3">
              <FontSelect font={font} setFont={setFont} fonts={fonts} isPlus={isPlus} selectedPlus={selectedPlus} setSelectedPlus={setSelectedPlus} /> 
              <img
                src={justLeft}
                alt="Justify Text Left"
                onClick={() => setTextAlign("start")}
                className={`icon-size clickable bg-secondary-hover ${textAlign ===
                  "start" && "bg-secondary"}`}
              />
              <img
                src={justCenter}
                alt="Justify Text Center"
                onClick={() => setTextAlign("center")}
                className={`icon-size clickable bg-secondary-hover ${textAlign ===
                  "center" && "bg-secondary"}`}
              />
              <img
                src={justRight}
                alt="Justify Text Right"
                onClick={() => setTextAlign("end")}
                className={`icon-size clickable bg-secondary-hover ${textAlign ===
                  "end" && "bg-secondary"}`}
              />
            </div>
          </Tabs.TabPane>
        </Tabs>
      {!!missingContent ? 
        <div className="d-flex align-items-center justify-content-center mt-2">
          <span className="repair-text mx-2">Click next and finish the editing proccess to fix this babypage.  <Icon type="warning" style={{color:"#faad14"}}></Icon></span>
        </div> : null
      }
      </div>
    </div>
  ) : null;
};

export default EditTextBackground;
