import React from 'react';
import './VerticalDivider.scss';

interface Props {
  style?: React.CSSProperties | undefined;
}

const VerticalDivider: React.FC<Props> = ({ style }) => (
  <div className="VerticalDivider" style={style}></div>
);

export default VerticalDivider;
