import { Checkbox, DatePicker, Icon, Input, Radio } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { RadioChangeEvent } from "antd/lib/radio";
import moment, { Moment } from "moment";
import React, { useEffect, useMemo } from "react";
import { QuestionAnswer, QuestionItem, Milestone } from "../../interfaces";
import { multipleAnswers } from "../../utils/bpQuestions";

interface Props {
  question: QuestionItem;
  answer: QuestionAnswer | undefined;
  changeAnswer: (answer: QuestionAnswer) => void;
  setQuestions: any;
  milestone?: Milestone;
  setMilestoneDate?: React.Dispatch<React.SetStateAction<string>>;
  qIndex?: number;
  customAnswer: string;
  setCustomAnswer: React.Dispatch<React.SetStateAction<string>>;
  milestoneDate?: string;
}

const Question: React.FC<Props> = ({
  question,
  answer,
  changeAnswer,
  setQuestions,
  milestone,
  setMilestoneDate,
  qIndex,
  customAnswer,
  setCustomAnswer,
  milestoneDate,
}) => {
  const checked = useMemo(() => (answer ? answer.answer_string : []), [answer]);
  const uniqueAnswers = useMemo(
    () => Array.from(new Set(question.answers)),
    [question.answers],
  );

  useEffect(() => {
    setCustomAnswer("");
  }, [qIndex]);

  return (
    <>
      <p className="message m-0">{question.question}</p>
      {question.multiple_choice === 1 ? (
        <>
          <Checkbox.Group
            options={uniqueAnswers}
            value={checked}
            className="asnwers-checkbox"
            onChange={(newChecked: CheckboxValueType[]) => {
              const answer_string = [...newChecked] as string[];

              const newAnswer: QuestionAnswer = answer
                ? {
                    ...answer,
                    answer_string,
                    question_export: question.export,
                    answer: multipleAnswers(question.export, answer_string),
                  }
                : {
                    question_id: question.id,
                    position: !!question.order ? question.order : 0,
                    answer_string,
                    answer: multipleAnswers(question.export, answer_string),
                    type: question.type,
                    question_export: question.export,
                  };

              changeAnswer(newAnswer);
            }}
          />
          <Input
            className="w-100 my-2"
            addonAfter={
              <Icon
                type="plus-circle"
                className="clickable"
                onClick={() => {
                  if (customAnswer === "") return;

                  const otherAnswer = customAnswer;
                  setCustomAnswer("");

                  const answer_string = [...checked, otherAnswer] as string[];
                  const newAnswer: QuestionAnswer = answer
                    ? {
                        ...answer,
                        answer_string,
                        answer: multipleAnswers(question.export, answer_string),
                      }
                    : {
                        question_id: question.id,
                        position: !!question.order ? question.order : 0,
                        answer_string,
                        answer: question.export,
                        type: question.type,
                      };

                  changeAnswer(newAnswer);
                  setQuestions((questions: QuestionItem[]) =>
                    questions.map((q: QuestionItem) =>
                      q.id === question.id
                        ? { ...q, answers: [...q.answers, otherAnswer] }
                        : q,
                    ),
                  );
                }}
              />
            }
            value={customAnswer}
            placeholder="Enter something different"
            onChange={(e) => setCustomAnswer(e.target.value)}
          />
        </>
      ) : question.type !== "first_event" && question.type !== "pregnancy" ? (
        <Radio.Group
          className="asnwers-checkbox"
          value={checked && !!checked.length ? checked[0] : undefined}
          onChange={(e: RadioChangeEvent) => {
            const answer_string = [e.target.value] as string[];

            const newAnswer: QuestionAnswer = answer
              ? {
                  ...answer,
                  answer_string,
                  answer: multipleAnswers(question.export, answer_string),
                }
              : {
                  question_id: question.id,
                  position: !!question.order ? question.order : 0,
                  answer_string,
                  answer: multipleAnswers(question.export, answer_string),
                  type: question.type,
                };

            changeAnswer(newAnswer);
          }}
        >
          {uniqueAnswers.map((answer: string, index: number) => (
            <Radio key={index} value={answer}>
              {answer}
            </Radio>
          ))}
        </Radio.Group>
      ) : (
        <DatePicker
          format={"MMMM Do, YYYY"}
          className="w-100"
          placeholder="Event Date"
          disabledDate={(current: Moment | null) =>
            current !== null && current > moment().endOf("day")
          }
          value={
            checked.length
              ? moment(checked[0], "MMMM Do, YYYY").isValid()
                ? moment(checked[0], "MMMM Do, YYYY")
                : null
              : milestone?.date_for_title
                ? moment(milestone.date_for_title).isValid()
                  ? milestone.age_weeks !== -700
                    ? moment(milestone.date_for_title)
                    : moment(milestoneDate, "MMMM Do, YYYY") // date for pregnancy
                  : null
                : null
          }
          onChange={(momentDate) => {
            let dateAnswer = "";
            if (momentDate !== null) {
              dateAnswer = momentDate.format("MMMM Do, YYYY");
              setMilestoneDate!(dateAnswer);
              if (milestone?.age_weeks === -800) {
                setMilestoneDate!(momentDate?.format("YYYY-MM-DD 00:00:00"));
              }
            }

            const newAnswer: QuestionAnswer = answer
              ? { ...answer, answer_string: [dateAnswer] }
              : {
                  question_id: question.id,
                  position: !!question.order ? question.order : 0,
                  answer_string: [dateAnswer],
                  answer: question.export,
                  type: question.type,
                };

            changeAnswer(newAnswer);
          }}
        />
      )}
    </>
  );
};

export default Question;
