import { Modal } from "antd";
import { isEmailValid } from "../../components/SignIn/data/emails";
import {
  AlexandersShippingMethods,
  CartItem,
  Coupon,
  Purchase,
  shippingOption,
  User,
} from "../../interfaces";
import { PurchaseForm } from "./Checkout";
const { info } = Modal;

export const getPurchase = (
  form: PurchaseForm,
  store: CartItem[],
  shippingValue: number,
  disableShipping: boolean,
  useBalance: boolean,
  balance: number,
  bookYearSub: any | null,
  coupon?: Coupon,
  shippingObj?: shippingOption
): Purchase => ({
  billing_first: form.billingFirstName!,
  billing_last: form.billingLastName!,
  billing_email: form.billingEmail!,
  billing_address: form.billingStreet!,
  billing_city: form.billingCity!,
  billing_country: form.billingCountry,
  billing_state: form.billingState!,
  billing_zip: form.billingZipcode!.toString(),
  shipping_first: !!form.sameAsBilling
    ? form.billingFirstName!
    : form.shippingFirstName!,
  shipping_last: !!form.sameAsBilling
    ? form.billingLastName!
    : form.shippingLastName!,
  shipping_email: !!form.sameAsBilling
    ? form.billingEmail!
    : form.shippingEmail!,
  shipping_address: !!form.sameAsBilling
    ? form.billingStreet!
    : form.shippingStreet!,
  shipping_city: !!form.sameAsBilling ? form.billingCity! : form.shippingCity!,
  shipping_country: form.shippingCountry,
  shipping_state: !!form.sameAsBilling
    ? form.billingState!
    : form.shippingState!,
  shipping_zip: !!form.sameAsBilling
    ? form.billingZipcode!.toString()
    : form.shippingZipcode!.toString(),
  stripe_token: "",
  card_token: "",
  disable_ship: disableShipping,
  shipping_value: shippingValue,
  use_balance: useBalance,
  balance,
  store: !!bookYearSub
    ? store.map((i) => ({
        ...i,
        options: i.options.map((o) =>
          o.description === bookYearSub.description
            ? { ...o, selected: false }
            : o
        ),
      }))
    : store,
  tax: false,
  coupon: !!coupon ? [coupon] : [],
  one_year_subscription_book: bookYearSub,
  shipping_phone_number: form.billingPhoneNumber || "",
  billing_phone_number: form.billingPhoneNumber || "",
  ship_method: shippingObj?.alexanders_key || AlexandersShippingMethods.FEDEXONERATEENVELOPE,
});

export const purchaseValid = (
  form: PurchaseForm,
  shippingObj: shippingOption | undefined,
  disableShipping: boolean
): boolean => {
  if (
    !form.selectedCard &&
    (!form.cardNumber ||
      !form.cardName ||
      !form.cardCVV ||
      form.cardCVV.length < 3 ||
      !form.cardMonth ||
      !form.cardYear ||
      form.cardNumberInvalid)
  ) {
    info({
      title: "Please fill your payment information.",
    });
    return false;
  }

  if (
    !form.billingFirstName ||
    !form.billingLastName ||
    !form.billingStreet ||
    !form.billingState ||
    !form.billingZipcode
  ) {
    info({
      title: "Please fill your billing information.",
    });
    return false;
  }
  if (
    !disableShipping &&
    (!shippingObj ||
      (!form.sameAsBilling &&
        (!form.shippingFirstName ||
          !form.shippingLastName ||
          !form.shippingStreet ||
          !form.shippingState ||
          !form.shippingZipcode)))
  ) {
    info({
      title: "Please fill your shipping information.",
    });
    return false;
  }

  return true;
};

export const itemInvalid = (localStore: CartItem[]): boolean =>
  !!localStore.find((item: CartItem) => {
    if (
      item.type === "giftcard" &&
      (!item.gift.receiver ||
        !item.gift.email ||
        !isEmailValid(item.gift.email))
    ) {
      info({
        title:
          "Please fill your gift card information with a valid name and email.",
      });

      return true;
    } else if (
      item.type === "book" &&
      !item.options.find((o) => !!o.selected)
    ) {
      info({
        title:
          "You have a book without selecting any options, if you don't want it, remove it from the cart.",
      });

      return true;
    }
    return false;
  });

export const userIsPlusY = (user: User) =>
  !!user.user_level &&
  user.user_level === 1 &&
  ((!!user.in_app_purchase &&
    user.in_app_purchase.title === "One Year Subscription") ||
    (!!user.stripe_subscriptions &&
      user.stripe_subscriptions.name === "one year"));
