// @ts-nocheck
import { Button, Carousel, Modal, Icon } from "antd";
import { startCase } from "lodash";
import queryString from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { useLastLocation } from "react-router-last-location";
import { Dispatch } from "redux";
import logo2 from "../../../assets/img/logo_2.png";
import BaseLayout from "../../../components/BaseLayout/BaseLayout";
import GuestUserValidateInvitationCodeForm from "../../../components/GuestUserValidateInvitationCodeForm/GuestUserValidateInvitationCodeForm";
import SaveChild from "../../../components/SaveChild/SaveChild";
import { User } from "../../../interfaces";
import { sagaMiddleware } from "../../../store";
import { BaseAction } from "../../../store/actionTypes";
import { logoutUser } from "../../../store/ducks/user";
import { getAuthenticatedUser, switchAccount } from "../../../store/sagas/user";
import "../Auth.scss";
import logo from "../../../assets/img/logo-babypage.png";
import storkIllustration from "../../../assets/img/illustrations/stork-illustration.png";
import tutorialImg1 from "../../../assets/img/tutorial/Step1.png";
import tutorialImg2 from "../../../assets/img/tutorial/Step2.png";
import tutorialImg3 from "../../../assets/img/tutorial/Step3.png";
import tutorialImg4 from "../../../assets/img/tutorial/Step4.png";
import tutorialImg5 from "../../../assets/img/tutorial/Step5.png";
import tutorialImg6 from "../../../assets/img/tutorial/Step6.png";
import "./StepTwo.scss"
import { useWindowSize } from "../../../hooks/WindowsSize";

interface Props {
  logout: () => void;
  user: User;
}

const StepTwo: React.FC<Props> = ({ logout, user }) => {
  const lastLocation = useLastLocation();
  const [showModal, setShowModal] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const [isVisibleInvitationCodeModal, setIsVisibleInvitationCodeModal] =
    useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoadingInvitationCodeModal, setIsLoadingInvitationCodeModal] =
    useState(false);
  const [ width ] = useWindowSize();

  const goBackAsGuest = () => {
    setBackBtnDisabled(true);

    sagaMiddleware.run<any>(
      switchAccount,
      {
        mainUserId: user.main_user.id,
        hostUserId: user.main_user.host_users[0].host_user_id,
      },
      (error: string | null) => {
        if (error) {
          Modal.error({
            title: "Error",
            content: error,
          });

          return;
        }

        Modal.info({
          title: "Hi there!",
          content: `You're seeing the ${startCase(
            user.main_user.host_users[0].host_user.first_name
          )} ${startCase(
            user.main_user.host_users[0].host_user.last_name
          )}'s account as a guest user. Go to menu and select switch account to see your own content`,
        });

        if (!user.guest_user) {
          sagaMiddleware.run<any>(rootSaga, true);
        }

        history.push("/");

        if (!user.guest_user) {
          sagaMiddleware.run<any>(fetchDashboard, (error: string | null) => {
            if (error !== null) {
              Modal.error({
                title: "Error",
                content: error,
              });
            }
          });
        }
      }
    );
  };

  const switchAccountFromModal = useCallback((hostUserId: number) => {
    // setLoading(true);

    sagaMiddleware.run<any>(
      switchAccount,
      {
        mainUserId: user.id,
        hostUserId: hostUserId,
      },
      (error: string | null) => {
        // setLoading(false);
        if (error) {
          Modal.error({
            title: "Error",
            content: error,
          });

          return;
        }

        history.push("/");
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveChild = (
    <SaveChild
      afterSave={async () => {
        sagaMiddleware.run<any>(getAuthenticatedUser, () => {
          const query = queryString.parse(location.search);
          history.push(
          user.source === "baby-list" ? "/dashboard" :
          !!query.redirect
            ? query.redirect.toString()
            : "/step-3" + location.search
        );
        });
      }}
      hideCustomDivider
      step
      shortForm={true}
    />
  );

  const tutorialItems = [
    {
      text: "Enter your child’s information",
      img: tutorialImg1,
    },
    {
      text: "Select photos from your phone or other services",
      img: tutorialImg2,
    },
    {
      text: "Answer a few questions to create your content",
      img: tutorialImg3,
    },
    {
      text: "Customize your content with illustrations",
      img: tutorialImg4,
    },
    {
      text: "Add to & keep childhood memories in digital form",
      img: tutorialImg5,
    },
    {
      text: "Select your cover & order books to cherish",
      img: tutorialImg6,
    },
  ];

  return (
    <BaseLayout
      title="BabyPage - Step Two"
      pageTitle="Step Two"
      hideHeader
      hideChildMenu
      hideFooter
      hideBanner
      pageStyle={{ position: "unset", minHeight: "unset" }}
      contentStyle={{ padding: 0 }}
      hideBanner={true}   
    >
      <div className="row">
        <div className="col-6">
          <img src={logo} alt="Logo" className="AuthLogo" />
        </div>

        <div
          className={`col-6 d-flex flex-column flex-md-row align-items-center mt-3 pr-md-5 justify-content-${
            !!user.facebook_id || !!user.apple_id ? "end" : "start"
          } justify-content-md-end`}
        >
          {(!!user.facebook_id || !!user.apple_id) && (
            <>
              <Button
                type="primary"
                onClick={() => setIsVisibleInvitationCodeModal(true)}
                size="small"
                className="ant-btn-round ant-btn-background-ghost p-3 mb-3 mb-md-0 mr-5 mr-md-2 ml-0"
              >
                I have an invitation code
              </Button>
            </>
          )}

        </div>
      </div>

      <div className="AuthContainer custom-style">
        <div
          className={`container styled-container`}
        >
          <div className="row">
            <div className="col-12 col-md-5 text-center">
              <img
                src={storkIllustration}
                alt="BP Stork"
                className="AuthImgHeader mb-3"
              />
            </div>

            <div className="col-12 col-md-7">
              <h1 className="responsive-title my-1 px-3">Add Your Child</h1>

              <h3 className="responsive-sub-title my-1 px-3 mb-5">
                This is just to get us started. You can add more information
                later and, if you select a Plus subscription, you can add
                multilple children, too!
              </h3>
              {saveChild}
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Enter an invitation code"
        visible={isVisibleInvitationCodeModal}
        onCancel={() => setIsVisibleInvitationCodeModal(false)}
        closable={true}
        footer={null}
      >
        <GuestUserValidateInvitationCodeForm
          setLoading={setIsLoadingInvitationCodeModal}
          closeGuestUserModal={(hostUserId: number) => {
            setIsVisibleInvitationCodeModal(false);
            switchAccountFromModal(hostUserId);
          }}
        />
      </Modal>

      <Modal
        centered
        className="modal-tutorial"
        visible={showModal}
        closable={true}
        okButtonProps={{ style: { display: "none" } }}
        cancelText="Skip Tutorial"
        cancelButtonProps={{
          htmlType: "button",
          className: "ant-btn-round",
        }}
        onCancel={() => setShowModal(false)}
      >
        <div className="container styled-container">
          <div className="row">
            <div className="col-12 tutorial-carousel">
              <Carousel 
              swipe draggable autoplay 
              autoplaySpeed={3500}  
              arrows={true} 
              nextArrow={ <div ><Icon  type="right"/></div>} 
              prevArrow={<div ><Icon type="left"/></div>}
              >
                {tutorialItems.map((item, index) => (
                  <div className="item-container" key={index}>
                    { width >= 1024 ? 
                    <>
                      <div className="text">
                        <p className="" >{item.text}</p>
                      </div>
                      <div className="image">
                        <img src={item.img} alt={`Tutorial - ${index}`} />
                      </div>
                    </> :
                    <>
                      <div className="image">
                        <img src={item.img} alt={`Tutorial - ${index}`} />
                      </div>
                      <div className="text mt-3 mb-2">
                        <p className="" >{item.text}</p>
                      </div>
                    </>
                    }
                    
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title="Enter an invitation code"
        visible={isVisibleInvitationCodeModal}
        onCancel={() => setIsVisibleInvitationCodeModal(false)}
        closable={true}
        footer={null}
      >
        <GuestUserValidateInvitationCodeForm
          setLoading={setIsLoadingInvitationCodeModal}
          closeGuestUserModal={(hostUserId: number) => {
            setIsVisibleInvitationCodeModal(false);
            switchAccountFromModal(hostUserId);
          }}
        />
      </Modal>
    </BaseLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch: Dispatch<BaseAction>) => ({
  logout: () => dispatch(logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepTwo);
