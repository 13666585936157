import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { PAGE_URL } from '../../../env';
import BaseLayout from '../../../components/BaseLayout/BaseLayout';
import './ResetPassword.scss';
import ResetPasswordForm from '../../../components/ResetPassword/ResetPasswordForm';
import logo from '../../../assets/img/logo-babypage.png';


const ResetPassword: React.FC = () => {

  const location = useLocation();
  const history = useHistory();

  useEffect( () => {

    if (location.search === "") {
      history.push("/")
    }

  }, [])
  
  return (
    <BaseLayout
      title='BabyPage - Reset Password'
      hideHeader
      hideChildMenu
      hideFooter
      pageStyle={{ position: 'unset', minHeight: 'unset' }}
      contentStyle={{ padding: 0 }}
    >
      <a href={PAGE_URL}>
        <img src={logo} alt="Logo" className="AuthLogo" />
      </a>
      
      <div className="container" >
        <div className="row justify-content-center align-items-center" style={{height: "650px"}}>
          <div className="col-11 col-md-10 col-lg-8 col-xl-9 ">
            <div className="row mb-3">
              <div className="col">
                  <h1 className="title-weight title-size mb-0">Reset Password</h1>
                  <p>Reset your password to continue capturing your children's milestones.</p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <ResetPasswordForm />
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </BaseLayout>
  );
};

export default ResetPassword;