import { Icon, Radio } from 'antd';
import React from 'react';
import plusThumb from '../../assets/img/plus-thumbnail.png';
import { CartItem } from '../../interfaces/cart';
import { User } from "../../interfaces";
import Rattle from '../Icons/Rattle';
import Thrash from '../Icons/Thrash';
interface Props {
  item: CartItem;
  onChangeItem: (item: CartItem) => void;
  onRemoveItem: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  user: User;
  itemIndex: number;
}

const Subscription: React.FC<Props> = ({
  item,
  onChangeItem,
  onRemoveItem,
  user,
  itemIndex
}) => {
  const cartDividerStyle = itemIndex > 0 ? "cart-divider" : "";

  return (
  <div className={`row cart-item ${cartDividerStyle}`}>
    <div className='col-md-5 col-4 text-center'>
      <Rattle />
    </div>
    <div className='col-md-7 col-8'>
      <div className='row justify-content-start align-items-baseline'>
        <div className='col-10'>
          <h3 className='m-0 cart-item-title'>
            <strong>BabyPage PLUS Subscription</strong>
          </h3>
        </div>
      </div>
      <Radio.Group
        onChange={e => {
          const description = e.target.value;
          const option = item.options.find(e => e.description === description);
          if (!!option) {
            onChangeItem({
              ...item,
              item: option.description,
              description: option.description,
              stripe_id: option.stripe_id,
              id: option.stripe_id,
              total: option.price,
              subtitle: option.title,
              options: item.options.map(e => ({ ...e, selected: e.description === description })),
            });
          }
        }}
        value={item.description}
        className='my-1'
      >
        {item.options.map((option, index) => (
          <Radio
            key={index}
            value={option.description}
            style={{ whiteSpace: 'inherit', marginBottom: 5, display:'block' }}
          >
           <span className='subscription-option-price'> ${option.price.toFixed(2)} </span> 
            <span className='subscription-option-title'>
            for{' '}
              {option.description === 'monthly'
              ? '1 Month'
              : option.description === 'three month'
              ? '3 Months'
              : '1 Year'}{' '}
              </span>
          </Radio>
        ))}
      </Radio.Group>
      <div className="row justify-content-start cart-sub-actions-margin"> 
        <span className="ml-4"> <Thrash /> </span>
        <p className="cart-book-actions mx-2" onClick={onRemoveItem} >Remove</p>
     </div>
    </div>
  </div>
);
}

export default Subscription;
