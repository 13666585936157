import React from "react";
import { connect } from "react-redux";
import BaseLayout from "../../components/BaseLayout/BaseLayout";
import GiftCard from "../../components/GiftCards/GiftCards";
import Store from "../../components/Store/Store";
import { AppState } from "../../store";

interface Props {
  isPlus: boolean;
}

const GiftCardsPage: React.FC<Props> = ({ isPlus }) => {
  return (
    <BaseLayout title="BabyPage - Giftcards" pageTitle="Gift Cards" hideHeaderChildMenu hideChildMenu >
      <GiftCard  />
    </BaseLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  isPlus: !!state.user.user_level
});

export default connect(mapStateToProps)(GiftCardsPage);
