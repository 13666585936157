import { call, put, select } from 'redux-saga/effects';
import { AppState } from '..';
import api from '../../api';
import { Product } from '../../interfaces';
import { User } from '../../interfaces/user';
import { isArrayEqual } from '../../utils/array';
import { getProducts } from '../ducks/product';

/**
 * This function fetch all products
 **/
export function* fetchProducts() {
  const accessToken: User = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    const response = yield call(api.get, 'api/products', {
      headers: {
        Authorization: accessToken,
      },
    });

    if (response.data && response.data.code === 200) {
      const data = response.data.data;
      const products: Product[] = yield select(
        (state: AppState) => state.product.products
      );

      if (!isArrayEqual(products, data)) {
        yield put(getProducts(response.data.data));
      }
    }
  } catch (error) {}
}
