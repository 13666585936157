// @ts-nocheck
import React, {useCallback, useState} from 'react';
import BaseLayout from '../../../components/BaseLayout/BaseLayout';
import { AppState, sagaMiddleware } from '../../../store';
import { connect } from 'react-redux';
import { Baby, User } from '../../../interfaces';
import { useHistory } from 'react-router-dom';
import SaveChild from '../../../components/SaveChild/SaveChild';
import BabyCarousel from '../../../components/Dashboard/BabyCarousel';
import AddBabypage from '../../../components/Icons/addBabypage';
import addChildPhotoIcon from "../../../assets/icons/IconPlacholder.png";
import { Modal, notification } from 'antd';
import { deleteChild } from '../../../store/sagas/baby';
import AddProfilePhotoIcon from '../../../components/Icons/AddProfilePhotoIcon';
import ModalPlus from '../../../components/ModalPlus/ModalPlus';
import "./ManageChildrenPage.scss"


interface Props {
  babySelected?: Baby | undefined;
  babies: Baby[];
  isPlus: boolean;
  user: User;
}

const ManageChildrenPage: React.FC<Props> = ({ babySelected, babies, isPlus, user }) => {
  const history = useHistory();
  const [editPhoto, setEditPhoto] = useState(false);
  const [addPhoto, setAddPhoto] = useState(false);
  const [showPlus, setShowPlus] = useState(false);
  const sendToUpgradeToPlus = () => {
    history.push("/cart/subscription/yearly");
  }
  const [loading, setLoading] = useState(false)

  const onDelete = useCallback(() => {
    Modal.confirm({
      title: 'Are you sure you want to delete this child profile?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      width: 300,
      icon: 'warning',
      onOk() {
        setLoading(true);

        sagaMiddleware.run<any>(
          deleteChild,
          babySelected?.id,
          (err?: string, description?: string) => {
            setLoading(false);

            if (!!err) {
              Modal.error({
                title: 'Error',
                content: err
              });
            } else if (!!description) {
              notification.success({
                message: 'Success',
                description
              });
            }
          }
        );
      }
    });
  }, [babySelected]);

  const saveChild = (
    <SaveChild
    id={!!babySelected.id ? babySelected.id.toString() : undefined}
      afterSave={() =>
           history.push('/')
      }
      shortForm={false}
      onDelete={onDelete}
    />
  );
return (
  <>
  <ModalPlus visible={showPlus} onClose={() => setShowPlus(false)} manageChildrenMsg={user.user_level === 0} onSaveBeforeUpgradeToPlus={sendToUpgradeToPlus} />
    <div className={`col-12 manage-children-title mb-1 mt-3`}>
          <h2>MANAGE CHILDREN</h2>
          <div onClick={() => {
            if (!user.guest_user) {
              isPlus ? history.push("/save-child") :  setShowPlus(true);
            }   
          }}
            className="clickable"
          >
          <AddBabypage />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-12 col-12">
            <BabyCarousel setEditPhoto={setEditPhoto} editPhoto={editPhoto} addPhoto={addPhoto} setAddPhoto={setAddPhoto} />
          </div>
        </div>
        <div
        className={`container-fluid milestones-container-width`}
        style={{
          backgroundColor: "#FFF",
          marginBottom: "50px",
          borderRadius: "15px"
        }}
      >
          <div className="d-flex flex-column justify-content-center align-items-center mt-2 mb-3">
          <h2 className='profile-photo-title'>Profile Photo</h2>
          <div className="clickable" style={{width:"100px", height:"100px", border:"solid 2px #658071", borderRadius:"50%"}} onClick={() =>{
             if(!!babySelected?.baby_profile_picture) {
              setEditPhoto(true);
             }
              }}>
            {!!babySelected?.baby_profile_picture ? (
              <>
              <img src={babySelected?.baby_profile_picture} style={{borderRadius:"50%"}} alt="" />
                <AddProfilePhotoIcon className='profile-photo-icon' isSmall={false}  />
              </>
            ) : <><img src={addChildPhotoIcon} style={{ borderRadius:"50%"}} onClick={() => {
              setAddPhoto(true)
            }}/> <AddProfilePhotoIcon className='profile-photo-icon' isSmall={false} /> </>}
            
          </div>
        </div>
      { saveChild }
      </div>
  </>
);
}
const mapStateToProps = (state: AppState) => ({
  babySelected: state.baby.babySelected,
  babies: state.baby.babies,
  isPlus: !!state.user.user_level && state.user.user_level === 1,
  user: state.user,
});

export default connect(mapStateToProps)(ManageChildrenPage);
