import {
  Button,
  DatePicker,
  Input,
  Modal,
  notification,
  Select,
  Table,
  Tooltip
} from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { GiftCard, GiftCardInterface } from '../../../interfaces/giftCard';
import { sagaMiddleware } from '../../../store';
import { getGiftCards, resendGiftCard } from '../../../store/sagas/giftCard';
import { capitalizeTexts } from '../../../utils/string';

const AdminGiftCardPage: React.FC = () => {
  // Page Loading
  const [loading, setLoading] = useState(true);

  // Filters
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment().endOf('month'));
  const [userSearch, setUserSearch] = useState('');

  // Data
  const [giftCards, setGiftCards] = useState<GiftCardInterface>({
    data: [],
    total: 0,
    per_page: 10,
    current_page: 1,
    last_page: 1,
    next_page_url: null,
    prev_page_url: null,
    has_more_pages: false,
    first_item: 1,
    last_item: 1
  });

  // Modal
  const [modalInfo, setModalInfo] = useState<{
    modal: boolean;
    email: string;
    gift?: GiftCard;
  }>({
    modal: false,
    email: '',
    gift: undefined
  });

  // Debounce
  const [debounce, setDebounce] = useState<NodeJS.Timeout>();

  useEffect(() => {
    setLoading(true);

    sagaMiddleware.run<any>(
      getGiftCards,
      {
        startDate: moment()
          .startOf('month')
          .format('YYYY[-]MM[-]DD'),
        endDate: moment()
          .endOf('month')
          .format('YYYY[-]MM[-]DD')
      },
      (err?: string, response?: GiftCardInterface) => {
        setLoading(false);

        if (!!err) {
          notification.error({
            message: 'Error',
            description: err
          });
        } else if (!!response) {
          setGiftCards(response);
        }
      },
      giftCards.current_page
    );
  }, [setLoading, giftCards.current_page]);

  const filterGifts = useCallback(
    (filter: { startDate?: string; endDate?: string; user?: string }) => {
      if (debounce) clearTimeout(debounce);

      setDebounce(
        setTimeout(() => {
          setLoading(true);

          sagaMiddleware.run<any>(
            getGiftCards,
            filter,
            (err?: string, response?: GiftCardInterface) => {
              setLoading(false);

              if (!!err) {
                notification.error({
                  message: 'Error',
                  description: err
                });
              } else if (!!response) {
                setGiftCards(response);
              }
            }
          );
        }, 400)
      );
    },
    [setLoading, debounce]
  );

  const resendEmail = useCallback((email: string, giftcard_id: number) => {
    Modal.confirm({
      title: `Send email to ${email}?`,
      okText: 'Yes',
      okType: 'primary',
      cancelText: 'No',
      width: 300,
      icon: 'warning',
      onOk() {
        setLoading(true);
        sagaMiddleware.run<any>(
          resendGiftCard,
          {
            email,
            giftcard_id
          },
          (err?: string) => {
            setLoading(false);

            if (!!err) {
              notification.error({
                message: 'Error',
                description: err
              });
            } else {
              notification.success({
                message: 'Success',
                description:
                  'Email sent successfully, you will be able to see in some minutes.',
                duration: 7
              });

              setModalInfo({ modal: false, email: '', gift: undefined });
            }
          }
        );
      }
    });
  }, []);

  return (
    <div className="m-3">
      <h1 className="title">Gift Cards</h1>
      <div className="row justify-content-center align-items-center mx-3">
        <div className="col-12 col-lg-6 my-1">
          <DatePicker.RangePicker
            value={[startDate, endDate]}
            onChange={e => {
              const newStart = e[0] ?? moment().startOf('month');
              const newEnd = e[1] ?? moment().endOf('month');

              setStartDate(newStart);
              setEndDate(newEnd);

              filterGifts({
                startDate: newStart.format('YYYY[-]MM[-]DD'),
                endDate: newEnd.format('YYYY[-]MM[-]DD'),
                user: !!userSearch ? userSearch : undefined
              });
            }}
            className="w-100"
          />
        </div>
        <div className="col-6 col-lg-3 my-1">
          <Input
            placeholder="User"
            value={userSearch}
            onChange={e => {
              setUserSearch(e.target.value);

              filterGifts({
                startDate: startDate.format('YYYY[-]MM[-]DD'),
                endDate: endDate.format('YYYY[-]MM[-]DD'),
                user: !!e.target.value ? e.target.value : undefined
              });
            }}
            className="w-100"
          />
        </div>
      </div>
      <Table
        dataSource={giftCards.data}
        loading={loading}
        bordered
        pagination={{
          current: giftCards.current_page,
          total: giftCards.total,
          pageSize: giftCards.per_page
        }}
        onChange={e =>
          setGiftCards(state => ({ ...state, current_page: e.current || 1 }))
        }
        className="my-3 elevation-5"
        rowKey={(record: GiftCard) => record.id.toString()}
      >
        <Table.Column
          title="Buyer"
          dataIndex="user"
          key="user"
          ellipsis
          render={(text: any, record: GiftCard) => (
            <span>
              {capitalizeTexts(record.user?.first_name ?? '')}{' '}
              {capitalizeTexts(record.user?.last_name ?? '')}
            </span>
          )}
        />
        <Table.Column
          title="Receiver"
          dataIndex="receiver_name"
          key="receiver_name"
          ellipsis
          render={(text: any, record: GiftCard) => (
            <span>
              {capitalizeTexts(record.receiver_name)} ({record.receiver_email})
            </span>
          )}
        />
        <Table.Column
          title="Total"
          dataIndex="total"
          key="total"
          ellipsis
          render={(text: any, record: GiftCard) => (
            <span>$ {record.total.toFixed(2)}</span>
          )}
        />
        <Table.Column
          title="Bought At"
          dataIndex="created_at"
          key="created_at"
          ellipsis
          render={(text: any, record: GiftCard) => (
            <span>{moment(record.created_at).format('MMM[, ]Do[, ]YYYY')}</span>
          )}
        />
        <Table.Column
          title="Actions"
          dataIndex="id"
          render={(text: any, record: GiftCard) => (
            <Tooltip title="Resend email">
              <Button
                icon="mail"
                type="primary"
                onClick={() =>
                  setModalInfo({
                    modal: true,
                    email: record.receiver_email,
                    gift: record
                  })
                }
              />
            </Tooltip>
          )}
        />
      </Table>
      <Modal
        title="Resend Gift Card"
        visible={modalInfo.modal}
        onCancel={() => setModalInfo(state => ({ ...state, modal: false }))}
        closable={!loading}
        cancelButtonProps={{
          disabled: loading,
          icon: 'close-circle',
          shape: 'round',
          className: 'default-btn'
        }}
        okType="primary"
        okButtonProps={{
          disabled: !modalInfo.gift || !modalInfo.email || loading,
          icon: loading ? 'loading' : 'check-circle',
          htmlType: 'button',
          shape: 'round',
          className: 'default-btn'
        }}
        onOk={() => {
          if (!!modalInfo.gift) {
            resendEmail(modalInfo.email, modalInfo.gift.id);
          }
        }}
      >
        {!!modalInfo.gift && !!modalInfo.gift.user && (
          <>
            <p>Send the Gift "{modalInfo.gift.code}" to:</p>
            <Select
              value={modalInfo.email}
              onChange={(email: string) =>
                setModalInfo(state => ({ ...state, email }))
              }
              className="w-100"
            >
              <Select.Option value={modalInfo.gift.receiver_email}>
                Receiver ({modalInfo.gift.receiver_email})
              </Select.Option>
              {modalInfo.gift.receiver_email !== modalInfo.gift.user.email && (
                <Select.Option value={modalInfo.gift.user.email}>
                  Buyer ({modalInfo.gift.user.email})
                </Select.Option>
              )}
              <Select.Option value="hello@babypage.com">
                hello@babypage.com
              </Select.Option>
              <Select.Option value="support@babypage.com">
                support@babypage.com
              </Select.Option>
              <Select.Option value="katie@babypage.com">
                katie@babypage.com
              </Select.Option>
              <Select.Option value="yasine@babypage.com">
                yasine@babypage.com
              </Select.Option>
            </Select>
          </>
        )}
      </Modal>
    </div>
  );
};

export default AdminGiftCardPage;
