export const networkError =
  'We are unable to connect to server, check your internet and try again later';


export const parseRequestError = (error: any): string => {
  const message =
    parseInt(error.code) === 0
      ? networkError
      : !!error.response &&
        !!error.response.data &&
        !!error.response.data.message
      ? error.response.data.message
      : error.message;
  return message === 'Network Error'
    ? networkError
    : message ===
      'Failed to authenticate because of bad credentials or an invalid authorization header.'
    ? 'Your session has been expired.'
    : message;
};
