/**
 * Normalizes the weight and height answers in the provided answers array.
 *
 * @param {any[]} answers - The array of answers to be normalized.
 * @returns {any[]} - The normalized array of answers, with the height and weight answers separated.
 */
export function normalizeWeightAnswer(answers: any[]): any[] {
  if (answers === null) {
    return [];
  }

  const weightHeightAnswers = answers.filter(
    (answer) => answer.type === "height_weight",
  );
  if (weightHeightAnswers.length === 0) {
    return answers;
  }

  const digits = weightHeightAnswers.flatMap((answer: any) =>
    extractDigitsFromHeightWeightAnswer(answer),
  );
  const position = weightHeightAnswers[0].position;
  addHeightAnswer(answers, digits[0], position);
  addWeightAnswer(answers, digits[1], digits[2], position);

  return answers.filter((answer) => answer.type !== "height_weight");
}

/**
 * Adds a height answer to the provided answers array.
 *
 * @param {any[]} answers - The array of answers to which the height answer will be added.
 * @param {number} height - The height value to be added.
 * @param {number} position - The position of the new answer.
 * @returns {number} - The new length of the answers array.
 */
function addHeightAnswer(
  answers: any[],
  height: number,
  position: number,
): number {
  const newAnswer = {
    answerString: [String(height)],
    answers: "unused property", //this might not be in use
    question_id: null,
    type: "height",
    position: position,
  };
  return answers.push(newAnswer);
}

/**
 * Adds a weight answer to the provided answers array.
 *
 * @param {any[]} answers - The array of answers to which the weight answer will be added.
 * @param {number} weight - The weight value to be added.
 * @param {number} oz - The ounces value to be added.
 * @param {number} position - The position of the new answer.
 * @returns {number} - The new length of the answers array.
 */
function addWeightAnswer(
  answers: any[],
  weight: number,
  oz: number,
  position: number,
): number {
  const totalweight = weight * 16 + oz;
  const newAnswer = {
    answerString: [String(totalweight)],
    answers: "unused property", //this might not be in use
    question_id: null,
    type: "weight",
    position: position,
  };
  return answers.push(newAnswer);
}

/**
 * Extracts the digits from a height and weight answer.
 *
 * @param {any} answer - The answer object from which to extract the digits.
 * @returns {number[]} - An array of digits extracted from the answer.
 */
function extractDigitsFromHeightWeightAnswer(answer: any): number[] {
  if (answer.type === "height_weight") {
    const { answers } = answer;
    const matches = answers.match(/\d+(\.\d+)?/g);
    return matches ? matches.map(Number) : [];
  }
  return [];
}
