import { Maintenance } from '../../interfaces';
import {CHANGE_MAINTENANCE} from '../actionTypes'
import { BaseAction } from '../actionTypes';

const initialState: Maintenance = {
    in_maintenance:false,
    body_message:"",
};

export const maintenance = (
  state: Maintenance = initialState,
  action: BaseAction
): Maintenance => {
  switch (action.type) {
    case CHANGE_MAINTENANCE:
      return {  ...action.payload };
    default:
      return state;
  }
};
Object.freeze(maintenance);

export const changeMaintenance = (payload: any) => {
  return {
  payload,
  type: CHANGE_MAINTENANCE,
}
};
Object.freeze(changeMaintenance);

export default maintenance;
