import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import BaseLayout from '../../components/BaseLayout/BaseLayout';
import { sagaMiddleware } from '../../store';
import { checkPaypalTransaction } from '../../store/sagas/checkout';

const OrderConfirmationPage: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const queryParams = queryString.parse(location.search);

    // Verify paypal payment
    if (!!queryParams && queryParams.tx) {
      sagaMiddleware.run<any>(checkPaypalTransaction, queryParams);
    }
  }, [location]);

  return (
    <BaseLayout
      title="BabyPage - Order Confirmation"
      pageTitle="Order Confirmation"
      hideChildMenu
      hideHeaderChildMenu
    >
      <div className="container my-3">
        <h1 style={{ color: '#727272' }}>
          Thank you! Your purchase has been processed.
        </h1>
        <h3 style={{ color: '#727272' }}>
          We love our customers, and we hope you love BabyPage. If you have any
          problems, please contact us at{' '}
          <a href="mailto:support@babypage.com">support@babypage.com</a>
        </h3>
      </div>
    </BaseLayout>
  );
};

export default OrderConfirmationPage;
