import { Card } from "antd";
import React, { useMemo } from "react";
import { DashboardInfo } from "../../interfaces";
import { capitalizeTexts } from "../../utils/string";
import CustomDivider from "../CustomDivider";
import "./Babyage.scss";
import { useWindowSize } from "../../hooks/WindowsSize";

export interface Props {
  baby: DashboardInfo;
}

const BabyAge: React.FC<Props> = ({ baby }) => {
  const babyName = useMemo(() => {
    const babyName = `${baby.baby_first_name}${
      !!baby.baby_middle_name
        ? ` ${baby.baby_middle_name} `
        : ""
    } ${baby.baby_last_name}`;

    return babyName.length > 35 ? babyName.substr(0, 32) + "..." : babyName;
  }, [baby.baby_first_name, baby.baby_middle_name, baby.baby_last_name]);
  const [width ] = useWindowSize();
  const sectionTitle = baby.baby_hasborn ? `${babyName.toUpperCase()}'S AGE` : "DUE DATE COUNTDOWN"
  
  return (
    <>
      <Card className="d-block dashboard-card babyage-card">
        <div className={`col-12 card-title mt-2 `}>
          <h2>{sectionTitle}</h2>
        </div>
        <div className="row justify-content-center px-3">
          {!!baby.baby_age.due_date ? (
            <>
              <div className="col-12 text-center">
                <h4 className="text-center gray">
                  <strong className="baby-age-date">Countdown</strong>
                </h4>
              </div>
              <div className="col-3">
                <h1 className="text-center babyAge">
                  {baby.baby_age.due_date.weeks}
                </h1>
                <h4 className="text-center gray">
                  <strong className="baby-age-date">WEEKS</strong>
                </h4>
              </div>
              <div className="col-3">
                <h1 className="text-center babyAge">
                  {baby.baby_age.due_date.days}
                </h1>
                <h4 className="text-center gray">
                  <strong className="baby-age-date">DAYS</strong>
                </h4>
              </div>
            </>
          ) : (
            <>
              <div className="col-3">
                <h1 className="text-center babyAge">
                  {!!baby.baby_age ? baby.baby_age.years : 0}
                </h1>
                <h4 className="text-center gray">
                  <strong className="baby-age-date">YEARS</strong>
                </h4>
              </div>
              <div className="col-3">
                <h1 className="text-center babyAge">
                  {!!baby.baby_age ? baby.baby_age.months : 0}
                </h1>
                <h4 className="text-center gray">
                  <strong className="baby-age-date">MONTHS</strong>
                </h4>
              </div>
              <div className="col-3">
                <h1 className="text-center babyAge">
                  {!!baby.baby_age ? baby.baby_age.weeks : 0}
                </h1>
                <h4 className="text-center gray">
                  <strong className="baby-age-date">WEEKS</strong>
                </h4>
              </div>
              <div className="col-3">
                <h1 className="text-center babyAge">
                  {!!baby.baby_age ? baby.baby_age.days : 0}
                </h1>
                <h4 className="text-center gray">
                  <strong className="baby-age-date">DAYS</strong>
                </h4>
              </div>
            </>
          )}
          <div className={`col-12 ${width > 767 ? "mt-4" : "mt-2 mb-2"}`}>
            <h4 className="text-center baby-age-birthday">
              {baby.baby_first_name}
              {baby.baby_first_name[baby.baby_first_name.length - 1] === "s"
                ? "'"
                : "'s"}{" "}
              {!!baby.baby_age.due_date ? "due date" : "birthday"}:{" "}
              {!!baby.baby_age ? baby.baby_age.strBirthDate : ""}
            </h4>
          </div>
        </div>
      </Card>
    </>
  );
};

export default BabyAge;
