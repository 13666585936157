import React from "react";
import { Cover } from "../../interfaces";
import "./CoverPreview.scss";
import ImageProgress from "../ImageProgress/ImageProgress";
interface Props {
  cover: Cover;
  setSelectedCover: React.Dispatch<React.SetStateAction<Cover | undefined>>;
  selectedCover: Cover | undefined;
  isPlus: boolean;
  showPlus: () => void;
  setControl: React.Dispatch<
    React.SetStateAction<{
      valid: boolean;
      step: number;
    }>
  >;
  setSomethingChange: React.Dispatch<React.SetStateAction<boolean>>;
}

const CoverPreview: React.FC<Props> = ({
  cover,
  setSelectedCover,
  selectedCover,
  isPlus,
  showPlus,
  setControl,
  setSomethingChange,
}) => {
  return (
    <div
      className="col-6 col-sm-4 col-md-4 col-lg-3 cover-preview"
      onClick={() => {
        if (isPlus || !cover.plus) {
          if (
            !selectedCover ||
            (!!selectedCover && selectedCover.id !== cover.id)
          ) {
            setSelectedCover(cover);
            setControl((state) => ({ ...state, valid: true }));
            setSomethingChange(true);
          }
        } else {
          showPlus();
        }
      }}
    >
      <ImageProgress
        thumbnail={cover.thumbnail}
        title={cover.title}
        isSelected={!!selectedCover && selectedCover.id === cover.id}
      />
    </div>
  );
};

export default CoverPreview;
