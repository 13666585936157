import React from "react";
import { Button } from "antd";
import "./FloatShareButton.scss";

interface Props {
  type?:
    | "link"
    | "default"
    | "ghost"
    | "primary"
    | "dashed"
    | "danger"
    | undefined;
  shape?: "circle" | "round" | "circle-outline" | undefined;
  icon: string;
  position: "top-right" | "top-right-banner" | "top-left" | "bottom-right" | "bottom-left";
  onClick?:
    | ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void)
    | undefined;
  unFixed?: boolean;
  disabled?: boolean;
}

const FloatShareButton: React.FC<Props> = ({
  type,
  shape,
  icon,
  position,
  onClick,
  unFixed,
  disabled
}) => (
  <Button
    className={`float-btn elevation-5 ${position}`}
    type={type}
    shape={shape}
    icon={icon}
    onClick={onClick}
    style={{
      position: !!unFixed ? "absolute" : "fixed"
    }}
    disabled={disabled}
  />
);

export default FloatShareButton;
