import moment from "moment";
import React from "react";
import { BabyPage } from "../../interfaces";

interface Props {
  babypage: BabyPage;
}

const BabyPageOrderable: React.FC<Props> = ({ babypage }) => {
  return (
    <div className="col-6 col-sm-4 col-md-4 col-lg-3 babypage-item-preview">
      <img src={babypage.thumbnail} alt={babypage.title} className="w-100" />
      <div className="babypage-content">
        <h1 className="title">{babypage.title}</h1>
        <p className="subtitle">{babypage.subtitle}</p>
      </div>
    </div>
  );
};
export default BabyPageOrderable;
