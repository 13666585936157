import React from "react";

function ArrowDownChildMenu() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      className="dropdown-arrow"
    >
      <path
        fill="#111"
        fillRule="evenodd"
        d="M17.5 9.75l-5.5 5.5-5.5-5.5z"
      ></path>
    </svg>
  );
}

export default ArrowDownChildMenu;
