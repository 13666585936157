import {
  Button,
  Card,
  Icon,
  Input,
  Layout,
  Modal,
  notification,
  Row,
  Switch,
  Tag
} from "antd";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Font } from "../../interfaces/font";
import { sagaMiddleware } from "../../store";
import { deleteFont, saveFont } from "../../store/sagas/font";

const { Content } = Layout;

interface Props {
  fontForm: any;
  setFontForm: Function;
  setLoading: Function;
}

const FontForm: React.FC<Props> = ({ fontForm, setFontForm, setLoading }) => {
  const history = useHistory();

  const handleFile = useCallback(
    (event: any) => {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function() {
        setFontForm((fontForm: any) => ({
          ...fontForm,
          font_url: reader.result,
          source: file.name,
          source_file: file
        }));
      };
    },
    [setFontForm]
  );

  const handleSubmit = useCallback(
    (event: any) => {
      event.preventDefault();

      const { family, plus, is_active, source, source_file, id } = fontForm;

      if (!source) {
        notification.error({
          message: "Error",
          description: "A font file is required."
        });

        return;
      }

      const payload: any = {
        family: family,
        plus: !!plus,
        source: source_file !== null ? source_file : undefined,
        is_active: !!is_active
      };

      const formData: FormData = new FormData();
      for (const key in payload) {
        if (payload[key] !== undefined) {
          formData.append(key, payload[key]);
        }
      }

      setLoading(true);
      sagaMiddleware.run<any>(
        saveFont,
        formData,
        (err?: string, response?: Font) => {
          setLoading(false);

          if (!!err) {
            notification.error({
              message: "Error",
              description: err
            });
          } else {
            notification.success({
              message: "Success",
              description: "Font saved successfully"
            });

            history.push("/admin/fonts");
          }
        },
        id
      );
    },
    [fontForm, setLoading, history]
  );

  const handleClickOnDeleteButton = useCallback(
    (id: number) => {
      Modal.confirm({
        title: "Are you sure you want to delete this Font?",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        width: 300,
        icon: "warning",
        onOk() {
          setLoading(true);

          sagaMiddleware.run<any>(deleteFont, id, (err?: string) => {
            setLoading(false);

            if (!!err) {
              notification.error({
                message: "Error",
                description: err
              });
            } else {
              history.push("/admin/fonts");
            }
          });
        }
      });
    },
    [history, setLoading]
  );

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <Layout>
          <Content>
            <Row>
              <div style={{ textAlign: "center" }}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="input-file"
                  type="file"
                  onChange={handleFile}
                />
                <label
                  htmlFor="input-file"
                  style={{ width: "100%", textAlign: "center" }}
                >
                  {fontForm.source && (
                    <>
                      <Tag>{fontForm.source}</Tag>
                      <br />
                      <br />
                    </>
                  )}
                  <Tag color="#627f71" style={{ cursor: "pointer" }}>
                    CLICK HERE TO UPLOAD A FONT
                  </Tag>
                </label>
              </div>
            </Row>
            <Row className="mb-10">
              <label>Family</label>
              <Input
                className="w-100"
                maxLength={255}
                value={fontForm.family}
                onChange={e => {
                  e.persist();

                  setFontForm((fontForm: any) => ({
                    ...fontForm,
                    family: e.target.value
                  }));
                }}
              />
            </Row>
            <Row className="mb-10">
              <label>Is PLUS?</label>{" "}
              <Switch
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
                checked={!!fontForm.plus}
                onChange={(checked: boolean) => {
                  setFontForm((fontForm: any) => ({
                    ...fontForm,
                    plus: checked
                  }));
                }}
              />
            </Row>
            <Row className="mb-10">
              <label>Is Active?</label>{" "}
              <Switch
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
                checked={!!fontForm.is_active}
                onChange={(checked: boolean) => {
                  setFontForm((fontForm: any) => ({
                    ...fontForm,
                    is_active: checked
                  }));
                }}
              />
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Button
                type="primary"
                style={{ margin: "10px" }}
                htmlType="submit"
              >
                {fontForm.id !== 0 ? "UPDATE" : "CREATE"}
              </Button>
              <Button
                type="primary"
                style={{ margin: "10px" }}
                onClick={() => history.push("/admin/fonts")}
              >
                CANCEL
              </Button>
              {fontForm.id !== 0 && (
                <Button
                  type="primary"
                  style={{ margin: "10px" }}
                  onClick={() => handleClickOnDeleteButton(fontForm.id)}
                >
                  DELETE
                </Button>
              )}
            </Row>
          </Content>
        </Layout>
      </Card>
    </form>
  );
};

export default FontForm;
