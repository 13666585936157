import { DatePicker, InputNumber } from "antd";
import moment from "moment";
import React from "react";

interface Props {
  milestoneForm: any;
  setMilestoneForm: Function;
}

const InputFromTypeOfMilestone: React.FC<Props> = ({
  milestoneForm,
  setMilestoneForm
}) => {
  return (
    <>
      {milestoneForm.typeOfMilestone === "holiday" && (
        <>
          <label>Active Date</label>
          <DatePicker
            value={moment(milestoneForm.active_date) || moment()}
            format={"DD/MM/YYYY"}
            placeholder="Date"
            className="w-100 my-1"
            onChange={momentDate => {
              setMilestoneForm((milestoneForm: any) => ({
                ...milestoneForm,
                active_date: momentDate
              }));
            }}
          />
        </>
      )}
      {milestoneForm.typeOfMilestone === "weeks" && (
        <>
          <label>Weeks</label>
          <InputNumber
            min={1}
            max={4}
            value={milestoneForm.age_weeks}
            className="w-100"
            onChange={value => {
              setMilestoneForm((milestoneForm: any) => ({
                ...milestoneForm,
                age_weeks: value
              }));
            }}
          />
        </>
      )}
      {milestoneForm.typeOfMilestone === "months" && (
        <>
          <label>Months</label>
          <InputNumber
            min={1}
            max={200}
            value={parseInt(milestoneForm.age_months)}
            className="w-100"
            onChange={value => {
              setMilestoneForm((milestoneForm: any) => ({
                ...milestoneForm,
                age_months: value
              }));
            }}
          />
        </>
      )}
      {milestoneForm.typeOfMilestone === "years" && (
        <>
          <label>Years</label>
          <InputNumber
            min={1}
            max={15}
            value={parseInt(milestoneForm.age_years)}
            className="w-100"
            onChange={value => {
              setMilestoneForm((milestoneForm: any) => ({
                ...milestoneForm,
                age_years: value,
                age_months: value
              }));
            }}
          />
        </>
      )}
    </>
  );
};

export default InputFromTypeOfMilestone;
