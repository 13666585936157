import React from "react";
import MilestoneCard from "../Milestones/MilestoneCard";
import { Milestone } from "../../interfaces";
import "./MilestoneList.scss";
interface Props {
  milestones: Milestone[];
  milestoneTitle: String;
}

const MilestonesList: React.FC<Props> = ({ milestones, milestoneTitle }) => {
  return (
    <>
      <div
        className="container-fluid milestones-container-width"
        style={{
          backgroundColor: "#FFF",
          marginBottom: "50px",
          borderRadius: "15px"
        }}
      >
        <div className="row mb-0">
          <div className="col text-center">
          <p className="milestones-container-text-bold mb-1">{milestoneTitle}</p>
          </div>
        </div>
        <div className="row justify-content-center">
          {milestones.map((milestone: Milestone, index: number) => {
            return <MilestoneCard key={index} milestone={milestone} />;
          })}
        </div>
      </div>
    </>
  );
};

export default MilestonesList;
