import { STRIPE_KEY } from './env';
declare const Stripe: any;

export { Stripe };

export const createCardToken = (card: {
  number: number;
  exp_month: number;
  exp_year: number;
  cvc: number;
  name: string;
}): Promise<string> =>
  new Promise((resolve, reject) => {
    Stripe.setPublishableKey(STRIPE_KEY);

    Stripe.card.createToken(card, (_: any, response: any) => {
      if (!!response.error) {
        return reject({ message: response.error.message });
      }
      return resolve(response.id);
    });
  });
