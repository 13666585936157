import React from "react";
import BaseLayout from "../../components/BaseLayout/BaseLayout";
import { WEB_VERSION, PAGE_URL } from "../../env";

const AboutPage: React.FC = () => (
  <BaseLayout
    title="BabyPage - About"
    pageTitle="About"
    hideChildMenu
    hideHeaderChildMenu
  >
    <div className="container my-3">
      <h1 className="title"><strong>About BabyPage</strong></h1>
      <ul>
        <li style={{ fontSize: 18 }}>
          <strong>Version: </strong>
          {WEB_VERSION}
        </li>
        <li style={{ fontSize: 18 }}>
          <a
            href={`${PAGE_URL}terms`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>Terms of Service</strong>
          </a>
        </li>
        <li style={{ fontSize: 18 }}>
          <a
            href={`${PAGE_URL}privacy`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>Privacy Policy</strong>
          </a>
        </li>
        <li style={{ fontSize: 18 }}>
          <a
            href={`${PAGE_URL}support`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>Support</strong>
          </a>
        </li>
      </ul>
    </div>
  </BaseLayout>
);

export default AboutPage;
