import { Layout } from '../interfaces';

export const possibleLayouts: {
  layout: Layout;
  info: number[][];
  isFree: boolean;
}[] = [
  {
    layout: 'layout1A',
    info: [[1, 1]],
    isFree: true,
  },
  {
    layout: 'layout2A',
    info: [[2, 1], [2, 1]],
    isFree: true,
  },
  {
    layout: 'layout2B',
    info: [[1, 2], [1, 2]],
    isFree: true,
  },
  {
    layout: 'layout3A',
    info: [[1, 1], [1, 2], [1, 1]],
    isFree: false,
  },
  {
    layout: 'layout3B',
    info: [[1, 2], [1, 1], [1, 1]],
    isFree: false,
  },
  {
    layout: 'layout3C',
    info: [[1, 1], [1, 1], [2, 1]],
    isFree: false,
  },
  {
    layout: 'layout3D',
    info: [[2, 1], [1, 1], [1, 1]],
    isFree: false,
  },
  {
    layout: 'layout4A',
    info: [[1, 1], [1, 1], [1, 1], [1, 1]],
    isFree: false,
  },
  {
    layout: 'layout4B',
    info: [[2, 1], [1, 1.5], [1, 1.5], [1, 1.5]],
    isFree: false,
  },
  {
    layout: 'layout4C',
    info: [[1, 1.5], [1, 1.5], [1, 1.5], [2, 1]],
    isFree: false,
  },
  {
    layout: 'layout4D',
    info: [[1, 2], [1.5, 1], [1.5, 1], [1.5, 1]],
    isFree: false,
  },
  {
    layout: 'layout4E',
    info: [[1.5, 1], [1.5, 1], [1.5, 1], [1, 2]],
    isFree: false,
  },
  {
    layout: 'layout6A',
    info: [[1, 1.5], [1, 1.5], [1, 1.5], [1, 1.5], [1, 1.5], [1, 1.5]],
    isFree: false,
  },
  {
    layout: 'layout6B',
    info: [[1.5, 1], [1.5, 1], [1.5, 1], [1.5, 1], [1.5, 1], [1.5, 1]],
    isFree: false,
  }
];

/**
 * Return an array for aspects for each image in layout
 * Example:
 *  case layout3A
 *    1 image: {width: 1, height: 1}
 *    2 image: {width: 1, height: 2}
 *    3 image: {width: 1, height: 1}
 * Based on /helpers/layouts.jpg
 */
export const getLayoutInfos = (layout: Layout): number[][] => {
  const possibleLayout = possibleLayouts.filter(item => item.layout === layout);
  if (!possibleLayout.length) return possibleLayouts[0].info;
  return possibleLayout[0].info;
};

/**
 * Return the aspect for a photo (index) of layout
 */
export const getAspectFromLayout = (
  layout: Layout,
  index: number = 0
): number => {
  const layoutInfo = getLayoutInfos(layout);
  return layoutInfo[index][0] / layoutInfo[index][1];
};
