import { Tabs, notification } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useState, useCallback } from 'react';
import { FormListInterface, FormListItem } from '../../../interfaces/form';
import { sagaMiddleware } from '../../../store';
import { getForms } from '../../../store/sagas/form';
import AnswerList from './AnswerList/AnswerList';
import FormList from './FormList/FormList';

const AdminFormsPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [forms, setForms] = useState<FormListItem[]>([]);
  const [formData, setFormData] = useState<FormListInterface>({
    data: [],
    total: 0,
    per_page: 10,
    current_page: 1,
    last_page: 1,
    next_page_url: null,
    prev_page_url: null,
    has_more_pages: false,
    first_item: 1,
    last_item: 1,
  });

  useEffect(() => {
    setLoading(true);

    sagaMiddleware.run<any>(
      getForms,
      '',
      (err?: string, response?: FormListInterface) => {
        setLoading(false);

        if (!!err) {
          notification.error({
            message: 'Error',
            description: err,
          });
        } else if (!!response) {
          setForms(cloneDeep(response.data));
          setFormData(response);
        }
      }
    );
  }, []);

  const filterForms = useCallback((search: string = '', page: number = 1) => {
    setLoading(true);

    sagaMiddleware.run<any>(
      getForms,
      search,
      (err?: string, response?: FormListInterface) => {
        setLoading(false);

        if (!!err) {
          notification.error({
            message: 'Error',
            description: err,
          });
        } else if (!!response) {
          setFormData(response);
        }
      },
      page
    );
  }, []);

  return (
    <Tabs defaultActiveKey='1'>
      <Tabs.TabPane tab='Forms' key='1'>
        <FormList
          form={formData}
          loading={loading}
          setLoading={setLoading}
          filterForms={filterForms}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab='Answers' key='2'>
        <AnswerList forms={forms} loading={loading} setLoading={setLoading} />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default AdminFormsPage;
