import { AxiosResponse } from "axios";
import { call, put, select } from "redux-saga/effects";
import { AppState } from "..";
import api from "../../api";
import { DefaultResponse } from "../../interfaces/http";
import { User } from "../../interfaces/user";
import { parseRequestError } from "../../utils/error";
import { getPhotos, PhotoState } from "../ducks/photos";

export interface FileUploadS3 {
  image: File | Blob;
  folder: "bps" | "books" | "photos" | "bps_content";
  baby_id?: number;
  old_image?: string;
  gallery_image?: string;
}

export interface FileUploadedS3 {
  high_res: string;
  thumbnail: string;
  high_url: string;
  thumb_url: string;
}

export function* savePhotosS3(
  photos: FileUploadS3[],
  callback: (error: string | null, responses?: FileUploadedS3[]) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    type Response = AxiosResponse<DefaultResponse<FileUploadedS3>>;

    const responses: Response[] = [];

    for (const photo of photos) {
      const formData: FormData = new FormData();
      formData.append("image", photo.image);
      formData.append("folder", photo.folder);
      if (!!photo.gallery_image) formData.append("gallery_image", photo.gallery_image);
      if (!!photo.old_image) formData.append("old_image", photo.old_image);
      if (!!photo.baby_id) formData.append("baby_id", photo.baby_id.toString());

      const response: Response = yield call(
        api.post,
        "api/save-image-s3",
        formData,
        {
          headers: {
            Authorization: accessToken
          }
        }
      );

      responses.push(response);
    }

    if (
      responses.length === 0 ||
      responses.length !== photos.length ||
      responses.find((response: Response) => response.data.code !== 200)
    ) {
      throw new Error("Error saving images, try again later");
    }

    callback(
      null,
      responses.map((response: Response) => response.data.data)
    );
  } catch (error) {
    callback(parseRequestError(error));
  }
}

export function* savePhotosShareS3(
  photo: File | Blob,
  babypageId: number,
  callback: (error: string | null, response?: FileUploadedS3) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    type Response = AxiosResponse<DefaultResponse<FileUploadedS3>>;

    const formData: FormData = new FormData();
    formData.append("image", photo);
    formData.append("babypage_id", babypageId.toString());

    const response: Response = yield call(
      api.post,
      "api/save-image-share",
      formData,
      {
        headers: {
          Authorization: accessToken
        }
      }
    );

    callback(null, response.data.data);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

export function* fetchBabyPhotos(
  baby_id: string,
  page:number,
  callback: (error: string | null, babyPhotos?: any
    ) => void) {
  const user: User = yield select((state: AppState) => state.user);

  if (!user.access_token || user.user_level === 3) return;

  try {
    const response: any = yield call(api.get, `api/get-photos/${baby_id}?lang=en&limit=9&page=${page}`, {
      headers: {
        Authorization: user.access_token
      }
    });
    if (response.data && response.data.code === 200) {
      callback(null, response.data.data);
    } else {
      throw new Error('The selected id is invalid.');
    }
  }catch (error) {
    callback(parseRequestError(error));
  }
}

export function* fetchPhotos(callback?: (error: string | null) => void) {
  const user: User = yield select((state: AppState) => state.user);

  if (!user.access_token || user.user_level === 3) return;

  try {
    const response: any = yield call(api.get, "api/get-photos?lang=en", {
      headers: {
        Authorization: user.access_token
      }
    });

    if (!!response.data.data && Array.isArray(response.data.data)) {
      let photos: PhotoState = [];

      response.data.data.forEach((e: any) => {
        if (!!e.photos && !!e.photos.length) {
          photos = [
            ...photos,
            ...e.photos.map((p: string) => ({ url: p, baby_id: e.id }))
          ];
        }
      });

      yield put(getPhotos([...photos]));
    }

    if (callback) callback(null);
  } catch (error) {
    if (callback) callback(parseRequestError(error));
  }
}
