import { Card, Layout, Modal, notification, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { QuestionItem } from "../../interfaces";
import { sagaMiddleware } from "../../store";
import {
  deleteQuestion,
  getQuestionsByMilestoneId,
  saveQuestion
} from "../../store/sagas/questions";
import QuestionForm from "./QuestionForm";
import QuestionList from "./QuestionList";

const { Content } = Layout;

interface Props {
  milestoneId: number;
}

const AdminEditableQuestionList: React.FC<Props> = ({ milestoneId }) => {
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState<QuestionItem[]>([]);
  const [selectedQuestion, setSelectedQuestion] = useState<QuestionItem | null>(
    null
  );
  let { id } = useParams();

  useEffect(() => {
    setLoading(true);

    sagaMiddleware.run<any>(
      getQuestionsByMilestoneId,
      id,
      (err?: string, response?: QuestionItem[]) => {
        setLoading(false);

        if (!!err) {
          notification.error({
            message: "Error",
            description: err
          });
        } else if (!!response) {
          setQuestions(response);
        }
      }
    );
  }, [id]);

  const handleSubmit = useCallback(
    (event: any) => {
      event.preventDefault();

      if (selectedQuestion === null) return false;

      const {
        id,
        title,
        question,
        export: questionExport,
        answers,
        multiple_choice,
        milestone_id,
        order
      } = selectedQuestion;

      const payload: any = {
        id,
        title,
        question,
        export: questionExport,
        answers,
        multiple_choice,
        milestone_id: milestone_id !== 0 ? milestone_id : milestoneId,
        order: id !== 0 ? order : questions.length
      };

      setLoading(true);
      sagaMiddleware.run<any>(
        saveQuestion,
        selectedQuestion.id,
        payload,
        (err?: string, response?: QuestionItem | undefined) => {
          setLoading(false);

          if (!!err) {
            notification.error({
              message: "Error",
              description: err
            });
          } else {
            let messageSuccess = "Question updated successfully";
            if (selectedQuestion.id === 0) {
              messageSuccess = "Question added successfully";

              if (!!response) {
                setQuestions(questions => {
                  return [...questions, response];
                });
              }
            } else {
              setQuestions(questions => {
                return questions.map((question: QuestionItem) => {
                  if (question.id !== id) return question;

                  return selectedQuestion;
                });
              });
            }

            notification.success({
              message: "Success",
              description: messageSuccess
            });

            setSelectedQuestion(null);
          }
        }
      );
    },
    [selectedQuestion, questions, milestoneId]
  );

  const handleClickOnDeleteButton = useCallback((id: number) => {
    Modal.confirm({
      title: "Are you sure you want to delete this Question?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      width: 300,
      icon: "warning",
      onOk() {
        setLoading(true);

        sagaMiddleware.run<any>(deleteQuestion, id, (err?: string) => {
          setLoading(false);

          if (!!err) {
            notification.error({
              message: "Error",
              description: err
            });
          } else {
            setSelectedQuestion(null);

            setQuestions(questions => {
              return questions.filter(question => question.id !== id);
            });

            notification.success({
              message: "Success",
              description: "Question deleted successfully"
            });
          }
        });
      }
    });
  }, []);

  return (
    <>
      {loading ? (
        <div className="col-12 d-flex justify-content-center">
          <Spin style={{ fontSize: 36, margin: 50 }} />
        </div>
      ) : (
        <Card title="Questions">
          <Layout>
            <Content>
              {selectedQuestion ? (
                <QuestionForm
                  handleSubmit={handleSubmit}
                  selectedQuestion={selectedQuestion}
                  setSelectedQuestion={setSelectedQuestion}
                  handleClickOnDeleteButton={handleClickOnDeleteButton}
                />
              ) : (
                <QuestionList
                  questions={questions}
                  setSelectedQuestion={setSelectedQuestion}
                />
              )}
            </Content>
          </Layout>
        </Card>
      )}
    </>
  );
};

export default AdminEditableQuestionList;
