import { Button, Input, Modal, notification } from "antd";
import Form from "antd/lib/form";
import React, { useCallback, useEffect, useState } from "react";
import isNumeric from "validator/lib/isNumeric";
import matches from "validator/lib/matches";
import { sagaMiddleware } from "../../store";
import { validateInvitationCode } from "../../store/sagas/guestUser";

interface Props {
  setLoading: Function;
  closeGuestUserModal: Function;
  invitationCodeFromLink?: string;
}

const GuestUserValidateInvitationCodeForm: React.FC<Props> = ({
  setLoading,
  closeGuestUserModal,
  invitationCodeFromLink,
}) => {
  const [invitationCode, setInvitationCode] = useState(invitationCodeFromLink);

  useEffect(() => {
    if (invitationCodeFromLink) {
      setInvitationCode(invitationCodeFromLink);
    }
  }, [invitationCodeFromLink]);

  const handleSubmit = useCallback(
    (event: any) => {
      event.preventDefault();

      setLoading(true);

      sagaMiddleware.run<any>(
        validateInvitationCode,
        {
          invitation_code: invitationCode,
        },
        (err: string, data: any) => {
          setLoading(false);

          if (!!err) {
            Modal.error({
              title: "Error",
              content: err,
            });

            return;
          }

          notification.success({
            message: "The invitation has been validated successfully",
          });

          closeGuestUserModal(data.host_user_id);
        }
      );
    },
    [invitationCode, closeGuestUserModal, setLoading]
  );

  return (
    <Form onSubmit={handleSubmit} className="w-100">
      <div className="container">
        <div className="row justify-content-center justify-content-md-start align-items-center">
          <div className="col-12">
            <Form.Item>
              <Input
                className="w-100"
                placeholder="Invitation Code"
                maxLength={8}
                value={invitationCode}
                onChange={(e: any) =>
                  setInvitationCode(e.target.value.replace(/[^\d]/g, ""))
                }
              />
            </Form.Item>
          </div>
        </div>
        <div className="row justify-content-center align-items-end">
          <div className="col-8">
            <Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                shape="round"
                disabled={
                  !invitationCode ||
                  !isNumeric(invitationCode || "0") ||
                  !matches(invitationCode || "0", /^[0-9]{8}$/)
                }
                className="w-100 mx-auto d-table"
                style={{ maxWidth: 300 }}
              >
                Continue
              </Button>
            </Form.Item>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default GuestUserValidateInvitationCodeForm;
