import { BaseAction, GET_DASHBOARD, USER_LOGOUT } from '../actionTypes';
import { DashboardInfo } from '../../interfaces';

export type DashboardState = {
  dashboard: DashboardInfo[];
  loading: boolean;
};

const initialState: DashboardState = {
  dashboard: [],
  loading: true
};

export const dashboard = (
  state: DashboardState = initialState,
  action: BaseAction
): DashboardState => {
  switch (action.type) {
    case GET_DASHBOARD:
      return { dashboard: [...action.payload], loading: false };
    case USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
Object.freeze(dashboard);

const getDashboard = (payload: DashboardInfo[]) => ({
  payload,
  type: GET_DASHBOARD
});
Object.freeze(getDashboard);

export { getDashboard };
export default dashboard;
