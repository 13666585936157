import React from "react";
import { connect } from "react-redux";
import BaseLayout from "../../components/BaseLayout/BaseLayout";
import Store from "../../components/Store/Store";
import { AppState } from "../../store";

interface Props {
  isPlus: boolean;
}

const StorePage: React.FC<Props> = ({ isPlus }) => {
  return (
    <BaseLayout title="BabyPage - Store" pageTitle="Store" hideChildMenu>
      <Store isPlus={isPlus} />
    </BaseLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  isPlus: !!state.user.user_level
});

export default connect(mapStateToProps)(StorePage);
