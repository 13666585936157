// @ts-nocheck
import React from "react";
import {User } from "../../interfaces";
import { connect } from 'react-redux';
import { AppState, sagaMiddleware } from '../../store';
import { Modal } from 'antd';
import { getAuthenticatedUser } from '../../store/sagas/user';
import SaveChild from '../SaveChild/SaveChild';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';

interface Props {
    user: User;
    visible: boolean;
    close: () => void;
    closeAndShowSecondModal: () => void;
    babySelected: Baby;
  }
  
  const UpdateBirthModal: React.FC<Props> = ({ visible, close, closeAndShowSecondModal, user, babySelected }) => {
    const location = useLocation();
    const history = useHistory();
  
    const saveChild = (
        <SaveChild
          id={!!babySelected ? babySelected.id.toString() : null}
          afterSave={() => {
            close();
        
            sagaMiddleware.run<any>(getAuthenticatedUser);
    
            const query = queryString.parse(location.search);
            history.push(!!query.redirect && query.redirect.toString());
          }}
          hideCustomDivider
          updateBirthDate
        />
      );
      return (
          <>
        <Modal
            visible={visible}
            closable={true}
            onCancel={() => closeAndShowSecondModal()}
            footer={null}
            style={{display: visible ? "block" : "none"}}
        >
            <div className="row justify-content-center align-items-center">     
                <h3 className="responsive-sub-title my-1 px-3 mb-1 text">
                We noticed your due date has passed.
                </h3>
                <h4 className="mb-4 px-3">
                If your little one has arrived, congrats! Add their birth date now!
                </h4>
                
                {saveChild}

                <span onClick={() => closeAndShowSecondModal()} className="close-link">No thanks, I'll complete it later</span>
            </div>
        </Modal>

          </>
      )
  }

  const mapStateToProps = (state: AppState) => ({
    user: state.user
  });
  
  export default connect(mapStateToProps)(UpdateBirthModal);