import { useState, useEffect, useCallback } from "react";
import { Photo } from "../interfaces";
import { sagaMiddleware } from "../store"
import { fetchBabyPhotos } from "../store/sagas/image"


function useFetchPhotos(baby_id: number, page: number) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [list, setList] = useState<Photo[]>([]);
  const [hasMore, setHasMore ] = useState(false)

  const fetchPhotos = useCallback(async () => {
    try {
       setLoading(true);
       setError(false);
     sagaMiddleware.run<any>(
        fetchBabyPhotos,
        baby_id,
        page,
        async(error: string | null, babyPhotos: any ) => {
          if (error !== null || babyPhotos.length===0 ) {
            setLoading(false)
            return;
          }
          try {
              setList((prev) => { 
                return [...prev, ...babyPhotos.data]
             });
             
             setHasMore(babyPhotos.has_more_pages)
             setLoading(false)
          } catch(err) {
            setError(true);   
          }
        }
        )
    } catch (err) {
      setError(true);     
    }
  }, [baby_id, page]);

  useEffect(() => {
    fetchPhotos();
  }, [fetchPhotos, page]);

  useEffect(() => {
    setList([]);
    }, [baby_id])

  return { loading, error, list, hasMore };
}

export default useFetchPhotos;