import { GrowthChartBaby } from '../../interfaces/growthChart';
import { BaseAction, GET_GROWTH_CHART, USER_LOGOUT } from '../actionTypes';

export type GrowthChartState = GrowthChartBaby[];

const initialState: GrowthChartState = [];

export const growthChart = (
  state: GrowthChartState = initialState,
  action: BaseAction
): GrowthChartState => {
  switch (action.type) {
    case GET_GROWTH_CHART:
      return [...action.payload];
    case USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
Object.freeze(growthChart);

const getGrowthChart = (payload: GrowthChartBaby[]) => ({
  payload,
  type: GET_GROWTH_CHART,
});
Object.freeze(getGrowthChart);

export { getGrowthChart };
export default growthChart;
