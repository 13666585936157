import React, { useCallback, useState } from 'react';
import { sagaMiddleware } from '../../store';
import { updatePassword } from '../../store/sagas/user';
import { Modal, Input } from 'antd';

interface Props {
  visible: boolean;
  finishSuccess: (description: string) => void;
  cancel: () => void;
}

const ChangePassword: React.FC<Props> = ({
  visible,
  finishSuccess,
  cancel
}) => {
  const [loading, setLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');

  const changePassword = useCallback(
    (actualPass: string, newPass: string) => {
      setLoading(true);

      sagaMiddleware.run<any>(
        updatePassword,
        {
          current_password: actualPass,
          password: newPass,
          password_confirmation: newPass
        },
        (err?: string, description?: string) => {
          setLoading(false);

          if (!!err) {
            Modal.error({
              title: 'Error',
              content: err
            });
          } else if (!!description) {
            finishSuccess(description);
          setCurrentPassword("");
          setPassword("");
          }
        }
      );
    },
    [finishSuccess]
  );

  const currError = !!currentPassword.length && currentPassword.length < 6;
  const passError = !!password.length && password.length < 6;

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        cancel()
        setCurrentPassword("");
        setPassword("");
      }}
      onOk={() => changePassword(currentPassword, password)}
      okButtonProps={{
        disabled: loading || currentPassword.length < 6 || password.length < 6
      }}
    >
      <div className={currError ? 'has-error' : ''}>
        <label>Current Password</label>
        <Input.Password
          placeholder="Current Password"
          onChange={e => setCurrentPassword(e.target.value)}
          maxLength={100}
          value={currentPassword}
          className={`w-100${currError ? ' has-error' : ''}`}
        />
        {currError && (
          <div className="ant-form-explain">
            Please enter at least 6 characters.
          </div>
        )}
      </div>
      <br />
      <div className={passError ? 'has-error' : ''}>
        <label>New Password</label>
        <Input.Password
          placeholder="New Password"
          onChange={e => setPassword(e.target.value)}
          maxLength={100}
          value={password}
          className={`w-100${passError ? ' has-error' : ''}`}
        />
        {passError && (
          <div className="ant-form-explain">
            Please enter at least 6 characters.
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ChangePassword;
