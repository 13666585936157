import { Modal, Button, Icon, Spin } from 'antd';
import React, {useEffect, useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import BabyPagePreview from '../../components/BabyPagePreview/BabyPagePreview';
import BabyPageSearchBar from '../../components/BabyPageSearchBar/BabyPageSearchBar';
import { BabyPage, StripeProduct, Baby } from '../../interfaces';
import BabyPageAdd from './icons/BabyPageAdd';
import BabyPageRefresh from './icons/BabyPageRefresh';
import {  sagaMiddleware } from "../../store";
import { fetchBabies } from "../../store/sagas/baby";
import { setBookType as setBookTypeSaga } from "../../store/sagas/books";
import "./BabyPageList.scss"

interface Props {
  text: string;
  newBabyPages?: boolean;
  babypages: BabyPage[];
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loading:boolean;
  stripeProducts: StripeProduct[];
  babySelected?: Baby;
  isGuestUser?: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSortModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  sortBy: string;
  allBabypages: number;
}

const BabyPageList: React.FC<Props> = ({
  text,
  newBabyPages,
  babypages,
  setLoading,
  loading,
  stripeProducts,
  babySelected,
  isGuestUser,
  setModal,
  setSortModalVisible,
  sortBy,
  allBabypages
}) => {
  const history = useHistory();

  let [filteredBabypages, setFilteredBabypages] = useState<any[]>([...babypages])

  useEffect(() => {
    setFilteredBabypages([...babypages])
  }, [babypages])

  const BabyPagePreviewList = filteredBabypages.map((babyPage, index) => {
    const bpProps = {
      babyPage,
      stripeProducts,
      setLoading,
      hasFullAccess: newBabyPages,
      isGuestUser,
      babySelected
    };
    return <BabyPagePreview key={index} {...bpProps} />;
  })

  const refreshBabypages = () => {
          setLoading(true);
          sagaMiddleware.run<any>(fetchBabies, (err: string | null) => {
            setLoading(false);
            if (err) {
              Modal.error({
                title: "Error",
                content: err,
              });
            }
          }, undefined, sortBy);
  }

  const hiddenClasses = babypages.length === 0 && !newBabyPages ? "hidden" : ""
  const hasBabypages = babypages.length !== 0

  return (
    <div className='py-2'>
      <div className="mx-auto row babypagelist-container justify-content-center align-items-center my-4">
        <div className="col-md-6 col-12 responsive-col">
        { newBabyPages && 
          <Button
            type="primary"
            ghost
            className="w-100 light-btn mx-md-auto green-button px-5"
            shape="round"
            onClick={() => {
              sagaMiddleware.run<any>(setBookTypeSaga, "", (err?: string) => {
                if (err) {
                  console.error(err);
                  return;
                }

                return allBabypages < 8
                  ? setModal(true)
                  : history.push("/books/save");
              });
            }}
          >
            Make a Book
          </Button>
}
        </div>
         
      
        <div className="col-md-6 col-12 responsive-col">
          { newBabyPages && 
                (
                  <BabyPageSearchBar
                    babypages={babypages}
                    filteredBabypages={filteredBabypages}
                    setFilteredBabypages={setFilteredBabypages}
                    setSortModalVisible={setSortModalVisible}
                  />
                )
          }
        </div>
      </div> 
  <div
        className={`container-fluid milestones-container-width ${hiddenClasses}`}
        style={{
          backgroundColor: "#FFF",
          marginBottom: "50px",
          borderRadius: "15px"
        }}
      >
        <div className="row mb-0">
          <div className="col text-center">
            <p className="milestones-container-text-bold">Babypages</p>
          </div>
          { hasBabypages && 
            <div className="ml-n5 clickable refresh-btn" onClick={refreshBabypages}>
              <BabyPageRefresh />
            </div>
          }
        </div>
        <div className="row justify-content-start babypages-container-margin">
          { loading ? (    
          <div className="mx-auto d-table my-3">
            <Spin
              indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
            />
          </div>
        ) :
            <>
             { newBabyPages && 
                <Link
                  to='/milestones'
                  className='col-6 col-sm-4 col-md-4 col-lg-3 d-flex align-items-stretch babypage-preview pr-0'
                >
                  <div className='babypage-add mx-1'>
                    <BabyPageAdd />
                  </div>
                </Link>
              }
               {hasBabypages && BabyPagePreviewList }
            </>
          }

        </div>
      </div>
    </div>
  );
};
export default BabyPageList;
