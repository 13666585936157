import React from "react";
import { Card } from "antd";
import { Link } from "react-router-dom";
import { Milestone } from "../../interfaces";
import CompletedMilestones from "./icons/CompletedMilestones";
import "./MilestoneCard.scss";

interface Props {
  milestone: Milestone;
}

const MilestoneCard: React.FC<Props> = ({ milestone }) => {
  // Checking if device is iOS to "force" refresh after loading route
  const isIOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
  const milestoneCompletedOverlay = milestone.is_used ? "completed-overlay" : "";
  const imageOpacity = milestone.is_used ? "image-opacity" : "";

  const CustomCards = () => {
    return (
      <Link
        className="col-6 col-md-4 col-lg-3 p-0 m-2 milestones-max-width-column"
        to={`/babypages/save?milestoneId=${milestone.id}`}
      >
        <div className={`milestones-card-container text-center  ${milestoneCompletedOverlay}`}>
          {
            milestone.is_used ?  <CompletedMilestones className="d-block completed-icon" /> : null
          }
       
          <img
            src={milestone.image}
            alt={milestone.title}
            className={`milestones-image ${imageOpacity}`}
          />
          <p className={`mb-0 milestones-text-semiBold px-2 pb-0 ${imageOpacity}`}>
            {milestone.title}
          </p>
        </div>
      </Link>
    );
  };

  const CustomCardsIOS = () => {
    return (
      <a
      className="col-6 col-md-4 col-lg-3 p-0 m-2 milestones-max-width-column"
      href={`/babypages/save?milestoneId=${milestone.id}`}
    >
      <div className={`milestones-card-container text-center  ${milestoneCompletedOverlay}`}>
        {
          milestone.is_used ?  <CompletedMilestones className="d-block completed-icon" /> : null
        }
        <img
          src={milestone.image}
          alt={milestone.title}
          className={`milestones-image ${imageOpacity}`}
        />
        <p className={`mb-0 milestones-text-semiBold px-2 pb-0 ${imageOpacity}`}>
          {milestone.title}
        </p>
      </div>
    </a>
    );
  };

  return <>{!isIOS ? <CustomCards /> : <CustomCardsIOS />}</>;
};

export default MilestoneCard;
