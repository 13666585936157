import React, {useState} from 'react';
import { DashboardInfo } from '../../interfaces';
import { Button, Card, Carousel } from 'antd';
import './RecentMilestones.scss';
import { Link } from 'react-router-dom';
import CompletedMilestones from '../Milestones/icons/CompletedMilestones';
import { useWindowSize } from '../../hooks/WindowsSize';
import CarouselSelect from './CarouselSelect';

export interface Props {
  baby: DashboardInfo;
}

const RecentMilestones: React.FC<Props> = ({ baby }) => {
  // Checking if device is iOS to "force" refresh after loading route
  const [width] = useWindowSize();
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [count, setCount] = useState<number>(0);
  const recentMilestones = baby.limited_milestones.concat(baby.holiday_milestones)
  const sectionTitle = !!baby.baby_hasborn ? "RECENT MILESTONES" : "PREGNANCY MILESTONES"
  const marginButton = width > 767 ? "mt-3" : width <= 320 ? "mt-5" : "mt-3 mb-4"

  return (
    <>
      <Card
        className="d-block dashboard-card"
        style={{ padding: 0 }}
      >
        <div className={`col-12 card-title ${width > 767 ? "mt-2" : "mt-2"} mb-3`}>
          <h2>{sectionTitle}</h2>
        </div>
        <div className='milestone-carousel'>
        <Carousel
          slidesToShow={2}
          swipe
          afterChange={(index) => {
            if(currentIndex > 4 && index < currentIndex && Number.isInteger(currentIndex / 5)) {
              setCount(count-1)
             } else if (currentIndex >= 4 && index >= currentIndex && Number.isInteger(index / 5)) {
              setCount(count + 1);
            }
            setCurrentIndex(index)
          }}
          draggable
          autoplay
          autoplaySpeed={15000}
          infinite={false}
          dots={true}
          arrows={false}
        >
          {baby.limited_milestones
            .concat(baby.holiday_milestones).map((milestone, index: number) => {
              const milestoneCompletedOverlay = milestone.is_used ? "completed-overlay" : "";
              const imageOpacity = milestone.is_used ? "image-opacity" : "";

            return(
           
            <div key={index}>
              <Link to={`/babypages/save?milestoneId=${milestone.id}`} >
                <div style={{ border: "2px #d8d8d8 solid", borderRadius: "11.5px"  }}
                      className={`dashboard-milestone-card ${milestoneCompletedOverlay}`}
                      >
                  {
                    milestone.is_used ? <CompletedMilestones className="d-block completed-icon"/> : null
                  }
                  <img
                    className={`milestones-carousel-image ${imageOpacity}`}
                    src={milestone.image}
                    alt={milestone.title}
                  />
                    <p className={`text-center milestone-link px-2 ${imageOpacity}`} style={{marginBottom: 0}}>{milestone.title}</p> 
                </div>
              </Link>
            </div>
            
          )})}
        </Carousel>
        </div>
        <div className={`col-12 px-5 ${marginButton}`}>
            <Link to="/milestones">
              <Button type="primary" ghost shape="round" className="w-100 green-button dashboard-button">
                Go to Milestones
              </Button>
            </Link>
          </div>
      </Card>
    </>
  );
};

export default RecentMilestones;
