import React, { useEffect, useState, useRef } from "react";
import { Baby, BabyPage } from "../../interfaces";
import BabyPageItem from "../BabyPageItem/BabyPageItem";
import CustomDivider from "../CustomDivider";
import { searchBabypages } from "../../utils/searchBabypages";


interface Props {
  baby: Baby;  
  selectedBabypages: BabyPage[];
  setSelectedBabypages: React.Dispatch<React.SetStateAction<BabyPage[] | []>>;
  reverseSort: boolean;
  sortBy: string;
  search?: string;
}

const ChildBabypages: React.FC<Props> = ({
  baby,  
  selectedBabypages,
  setSelectedBabypages,
  reverseSort,
  sortBy,
  search
}) => {
    const [filteredBabypages, setFilteredBabypages] = useState<BabyPage[]>(
      [...baby.babyPages] 
    )

    const isFirstRun = useRef(true);
    useEffect (() => {
      if (isFirstRun.current) {
        isFirstRun.current = false;
        return;
      }
       let arr = [...filteredBabypages].reverse()
        setFilteredBabypages([...arr])
    }, [reverseSort]);

   

      useEffect(() => {
        searchBabypages(search ? search : "", baby.babyPages, setFilteredBabypages)
      }, [search, baby])

  return (
    <div className="mb-4">
        {filteredBabypages.length > 0 ? (
          <h2 className="baby-title">{baby.baby_first_name.toUpperCase()}</h2>
        ) : null }
        
            <div className="container">
              <div className="row justify-content-start align-items-center">
                {filteredBabypages.map((babypage, index) => (
                  <BabyPageItem
                    key={index}
                    babypage={babypage}
                    selectedBabypages={selectedBabypages}
                    setSelectedBabypages={setSelectedBabypages}
                  />
                ))}
              </div>
            </div>
    </div>
  );
};
export default ChildBabypages;