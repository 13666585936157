import React, { useCallback, useEffect } from "react";
import { SortEndHandler } from "react-sortable-hoc";
import { Layout, SaveBabyPagePhoto } from "../../interfaces";
import Layout1A from "../Layouts/Layout1A";
import Layout2A from "../Layouts/Layout2A";
import Layout2B from "../Layouts/Layout2B";
import Layout3A from "../Layouts/Layout3A";
import Layout3B from "../Layouts/Layout3B";
import Layout3C from "../Layouts/Layout3C";
import Layout3D from "../Layouts/Layout3D";
import Layout4A from "../Layouts/Layout4A";
import Layout4B from "../Layouts/Layout4B";
import Layout4C from "../Layouts/Layout4C";
import Layout4D from "../Layouts/Layout4D";
import Layout4E from "../Layouts/Layout4E";
import Layout6A from "../Layouts/Layout6A";
import Layout6B from "../Layouts/Layout6B";
import { highRes } from "../../env";
import { Background } from "../../interfaces/background";
import "./PhotoGallery.scss";

interface Props {
  photos: SaveBabyPagePhoto[];
  layout: Layout;
  onClickPhoto?: (index: number, photo: SaveBabyPagePhoto) => void;
  onSortEnd: SortEndHandler;
  hidden?: boolean;
  backgroundSelected?: Background;
  htmlElementId?: string;
  isPhotoOnly?: boolean;
  photoOnlyLabelRight?: any;
  labelRight?: string;
  photoOnlyLabelLeft?: any;
  labelLeft?: string;
  fontGeneral?: string;
  labelLeftPosition?: string;
  labelRightPosition?: string;
  browserWidth?: number;
  setLabelLeftLength?: React.Dispatch<React.SetStateAction<number>>;
  setLabelRightLength?: React.Dispatch<React.SetStateAction<number>>;
  setMaxLabelLength?: React.Dispatch<React.SetStateAction<number>>;
  side?: string;
  setSide?: React.Dispatch<React.SetStateAction<string>>
}

const PhotoGallery: React.FC<Props> = (props: Props) => {
  const renderLayout = useCallback(() => {
    const { layout, photos, onClickPhoto, onSortEnd, hidden } = props;
    const layoutProps = {
      photos,
      onClickPhoto,
      onSortEnd,
      loading: !!hidden,
      backgroundSelected: undefined,
    };
    switch (layout) {
      case "layout2A":
        return <Layout2A {...layoutProps} />;
      case "layout2B":
        return <Layout2B {...layoutProps} />;
      case "layout3A":
        return <Layout3A {...layoutProps} />;
      case "layout3B":
        return <Layout3B {...layoutProps} />;
      case "layout3C":
        return <Layout3C {...layoutProps} />;
      case "layout3D":
        return <Layout3D {...layoutProps} />;
      case "layout4A":
        return <Layout4A {...layoutProps} />;
      case "layout4B":
        return <Layout4B {...layoutProps} />;
      case "layout4C":
        return <Layout4C {...layoutProps} />;
      case "layout4D":
        return <Layout4D {...layoutProps} />;
      case "layout4E":
        return <Layout4E {...layoutProps} />;
      case "layout6A":
        return <Layout6A {...layoutProps} />;
      case "layout6B":
        return <Layout6B {...layoutProps} />;
      default:
        return <Layout1A {...layoutProps} />;
    }
  }, [props]);

  const style: React.CSSProperties = {};


  if (props.hidden) {
    style.height = highRes.height;
    style.width = highRes.width;
  } else {
    style.height = "100%";
    style.width = "100%";
  }

  style.position = 'relative';

  const left_ref:any = React.createRef();
  const right_ref:any = React.createRef();
  const div_ref : any = React.createRef();

  useEffect(() => {
    let maxWidth = 250;
    if(div_ref.current && props.setMaxLabelLength) {
      props.setMaxLabelLength(maxWidth);
    }
     if (left_ref.current && props.setLabelLeftLength) {
      props.setLabelLeftLength(left_ref.current.getBoundingClientRect().width)
    }

    if(right_ref.current && props.setLabelRightLength) {
      props.setLabelRightLength(right_ref.current.getBoundingClientRect().width)
    }
  }, [props.browserWidth, props.labelLeft, props.labelRight, props.labelLeftPosition, props.labelRightPosition, props.hidden]);


  return (
    <div id={`${props.htmlElementId ? props.htmlElementId : "photo-grid-bp"}`} style={style} onClick={() => {
      if(!!props.side && !!props.setSide) {
        props.setSide(props.side)
      }
    }}>
      <>
        {renderLayout()}
        {props.isPhotoOnly && props.labelLeft && props.browserWidth &&(
          <div
            ref={div_ref}
            className="position-absolute click-through"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              display: "flex",
              top: 0,
              justifyContent: "flex-start",
              alignItems: "flex-start",
              ...props.photoOnlyLabelLeft,
              fontSize: props.hidden ? `1100%` : props.browserWidth >= 1024 ? '120%' : 
                                                 props.browserWidth >= 768 ? '90%' :
                                                 props.browserWidth >= 540 ? '75%' :
                                                 props.browserWidth >= 414 ? '70%' :
                                                '65%'                                                
                                                }}
          >
            <p 
            ref={left_ref}  
            style={{
              paddingRight: "5.5%",
              paddingLeft: props.labelLeftPosition === "top-center" ||
                            props.labelLeftPosition === "bottom-center" ?
                            "4.5%" : "8.5%" ,
              paddingBottom: props.labelLeftPosition === "top-left" || props.labelLeftPosition === "top-center" ? "4.5%" : "6.5%",
              paddingTop: props.labelLeftPosition === "top-left" || props.labelLeftPosition === "top-center" ? "6.5%" : "4.5%",
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              lineHeight: '1',
              fontFamily: props.fontGeneral,
              wordBreak: 'break-all',
              marginBottom: '0'      
            }}>
              {props.labelLeft}
            </p>
          </div>
        )}
        {props.isPhotoOnly && props.labelRight && props.browserWidth && (
          <div
            className="position-absolute click-through"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              display: "flex",
              top: 0,
              justifyContent: "flex-start",
              alignItems: "flex-start",
              ...props.photoOnlyLabelRight,
              fontSize: props.hidden ? `1100%` :  props.browserWidth >= 1024 ? '120%' : 
                                                  props.browserWidth >= 768 ? '90%' :
                                                  props.browserWidth >= 540 ? '75%' :
                                                  props.browserWidth >= 414 ? '70%' :
                                                '65%'   
            }}
          >
            <p 
            ref={right_ref}
            style={{
              paddingRight: props.labelRightPosition === "top-center" ||
                            props.labelRightPosition === "bottom-center" ?
                            "4.5%" : "8.5%" ,
              paddingLeft: "5.5%",
              paddingBottom: props.labelRightPosition === "top-right" || props.labelRightPosition === "top-center" ? "4.5%" : "6.5%",
              paddingTop: props.labelRightPosition === "top-right" || props.labelRightPosition === "top-center" ? "6.5%" : "4.5%",
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              lineHeight: '1',
              fontFamily: props.fontGeneral,
              wordBreak: 'break-all',
              marginBottom: '0'
            }}>
              {props.labelRight}
            </p>
          </div>
        )}
      </>
    </div>
  );
};

export default PhotoGallery;
