import cloneDeep from "lodash/cloneDeep";
import { call, put, select } from "redux-saga/effects";
import { AppState, sagaMiddleware } from "..";
import api from "../../api";
import { parseRequestError } from "../../utils/error";
import { trackAction } from "../../utils/marketing";
import { BabyState, getBabies, selectBaby } from "../ducks/baby";
import { fetchBabies } from "./baby";
import { normalizeWeightAnswer } from "../../utils/answerAdapter";

// Add or Update a BabyPage
export function* saveBabyPageSaga(
  babyPage: any,
  callback: (error?: string, id?: number) => void,
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token,
  );

  if (!accessToken) return;

  const method = !!babyPage.id ? "put" : "post";
  const url = !!babyPage.id
    ? `update-babypage/${babyPage.id}`
    : "save-babypage";
  babyPage.id = undefined; // Remove ID from post information

  // NOTE: This is a temporary fix to normalize the weight answers
  // The logic with in the app should be refactor
  const updatedAnswers = normalizeWeightAnswer(babyPage.answers);
  babyPage.answers = updatedAnswers;
  try {
    const response = yield call(api[method], `api/${url}`, babyPage, {
      headers: {
        Authorization: accessToken,
      },
    });

    if (response.data && response.data.code === 200) {
      if (method === "post") {
        trackAction("create_babypage", {
          google: {
            event: "baby_page_created",
          },
        });
      }
      sagaMiddleware.run<any>(fetchBabies);
      callback(undefined, response.data.data.babypage_id);
    } else {
      throw new Error("Error saving BabyPage");
    }
  } catch (error) {
    callback(parseRequestError(error));
  }
}

export function* fetchBabyPageById(
  id: number,
  callback: (error: string | null, babyPage?: any) => void,
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token,
  );

  if (!accessToken) return;

  try {
    const response = yield call(api.get, `api/get-babypage/${id}`, {
      headers: {
        Authorization: accessToken,
      },
    });

    if (response.data && response.data.code === 200) {
      callback(null, response.data.data);
    } else {
      throw new Error("The selected id is invalid.");
    }
  } catch (error) {
    callback(parseRequestError(error));
  }
}

export function* duplicateBabyPage(
  id: number,
  callback: (error: string | null) => void,
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token,
  );

  if (!accessToken) return;

  try {
    const response = yield call(api.get, `api/duplicate-babypage/${id}`, {
      headers: {
        Authorization: accessToken,
      },
    });

    if (response.data && response.data.code === 200) {
      const babyState: BabyState = yield select(
        (state: AppState) => state.baby,
      );

      let index = -1;
      const babyIndex = babyState.babies.findIndex((e) => {
        index = e.babyPages.findIndex((babyPage) => babyPage.id === id);
        return index !== -1;
      });

      if (babyIndex >= 0 && index >= 0) {
        const newBabyPage = cloneDeep(
          babyState.babies[babyIndex].babyPages[index],
        );
        newBabyPage.id = response.data.data.babypage_id;

        babyState.babies[babyIndex].babyPages = [
          newBabyPage,
          ...babyState.babies[babyIndex].babyPages,
        ];

        yield put(getBabies(babyState.babies));

        if (babyState.babySelectedIndex === babyIndex) {
          yield put(selectBaby(babyIndex));
        }
      }

      callback(null);
    } else {
      throw new Error("The selected id is invalid.");
    }
  } catch (error) {
    callback(parseRequestError(error));
  }
}

export function* deleteBabyPage(
  id: number,
  callback: (error: string | null) => void,
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token,
  );

  if (!accessToken) return;

  try {
    const response = yield call(api.delete, `api/delete-babypage/${id}`, {
      headers: {
        Authorization: accessToken,
      },
    });

    if (response.data && response.data.code === 200) {
      const babyState: BabyState = yield select(
        (state: AppState) => state.baby,
      );

      let index = -1;
      const babyIndex = babyState.babies.findIndex((e) => {
        index = e.babyPages.findIndex((babyPage) => babyPage.id === id);
        return index !== -1;
      });

      if (babyIndex >= 0 && index >= 0) {
        const updatedBabypages = babyState.babies[babyIndex].babyPages.filter(
          (babypage) => {
            return babypage.id !== id;
          },
        );

        babyState.babies[babyIndex].babyPages = updatedBabypages;

        yield put(getBabies(babyState.babies));

        if (babyState.babySelectedIndex === babyIndex) {
          yield put(selectBaby(babyIndex));
        }
      }

      callback(null);
    } else {
      throw new Error("The selected id is invalid.");
    }
  } catch (error) {
    callback(parseRequestError(error));
  }
}
