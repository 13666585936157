import { Button, Modal } from "antd";
import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { BabyPage, StripeProduct } from "../../interfaces";
import { AppState, sagaMiddleware } from "../../store";
import { downloadBabypage, generateBabypageImg } from "../../utils/canvas";
import { replaceThumbURL } from "../../utils/string";
import ModalPlus from "../ModalPlus/ModalPlus";
import "./PhotoModal.scss";
import { FileUploadedS3, savePhotosShareS3 } from "../../store/sagas/image";
import ShareModal from "../ShareModal/ShareModal";

interface Props {
  image: string;
  content?: string;
  width?: string | number;
  height?: string | number;
  babyPage?: BabyPage;
  visible: boolean;
  isPlus: boolean;
  stripeProducts: StripeProduct[];
  onClose: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  hasFullAccess?: boolean | undefined;
  isGuestUser?: boolean | undefined;
}

const PhotoModal: React.FC<Props> = ({
  image,
  content,
  visible,
  isPlus,
  babyPage,
  onClose,
  stripeProducts,
  width,
  height,
  hasFullAccess,
  isGuestUser,
}) => {
  const history = useHistory();
  const [zoomed, setZoomed] = useState(false);
  const [showSharePlus, setShowSharePlus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [share, setShare] = useState<string>("");
  const [downloadFreeBP, setDownloadFreeBP] = useState(false);
  const [upgradeToPlus, setUpgradeToPlus] = useState(false);

  const sendToUpgradeToPlus = () => {
    history.push("/cart/subscription/yearly");
  }

  useEffect(() => {
    if (!!downloadFreeBP && !!babyPage) {
      setLoading(true);
      downloadBabypage(
        replaceThumbURL(babyPage.thumbnail),
        !!babyPage.thumbnail_content
          ? replaceThumbURL(babyPage.thumbnail_content)
          : "",
        isPlus,
        `${babyPage.title}Babypage`
      )
        .then(() => {
          setLoading(false);
          setDownloadFreeBP(false);
        })
        .catch(err =>
          Modal.error({
            title: "Error",
            content: "Error generating BabyPage"
          })
        );
    }
  }, [downloadFreeBP, setLoading, babyPage, isPlus])

  const style: React.CSSProperties = {};
  if (!!height) style.height = height;
  if (!!width) style.width = width;

  const images = zoomed ? (
    <>
      <img
        className="modal-image zoomed"
        src={replaceThumbURL(image)}
        alt="BabyPage"
        style={style}
      />
      {!!content && (
        <img
          className="modal-image zoomed"
          src={replaceThumbURL(content)}
          alt="Content"
          style={style}
        />
      )}
    </>
  ) : (
    <>
      <img className="modal-image" src={image} alt="BabyPage" style={style} />
      {!!content && (
        <img
          className="modal-image"
          src={content}
          alt="Content"
          style={style}
        />
      )}
    </>
  );

  const createShareImage = useCallback((babyPage) => {
    setLoading(true);
    generateBabypageImg(
      isPlus ? replaceThumbURL(babyPage.thumbnail) : babyPage.thumbnail,
      !!babyPage.thumbnail_content
        ? isPlus
          ? replaceThumbURL(babyPage.thumbnail_content)
          : babyPage.thumbnail_content
        : "",
      isPlus
    )
      .then(image => {
        sagaMiddleware.run<any>(
          savePhotosShareS3,
          image as Blob,
          babyPage.id,
          (err: string | null, response?: FileUploadedS3) => {
            setLoading(false);
            if (err !== null) {
              Modal.error({
                title: "Error",
                content: err
              });
              return;
            } else if (!!response) {
              if (isPlus) {
                setShare(response.high_url);
              } else {
                setShare(response.thumb_url);
              }
            } else {
              Modal.error({
                title: "Error",
                content: "Error saving Photo"
              });
            }
          }
        );
      })
      .catch(err => {
        Modal.error({
          title: "Error",
          content: "Error generating BabyPage"
        });
        setLoading(false);
      });
  }, [isPlus]);


  return (
    <Modal
      centered
      visible={visible}
      footer={null}
      onCancel={onClose}
      width="auto"
      bodyStyle={{ padding: 0 }}
      closable={false}
    >
      <ModalPlus
        visible={upgradeToPlus}
        onClose={() => setUpgradeToPlus(false)}
        afterSaveBPMsg
      />
      <div className="d-flex justify-content-center align-items-center">
        {images}
      </div>
      {!!babyPage && (
        <Button.Group className="actions elevation-5">
          {(!!babyPage.new_version && (!!hasFullAccess || !isGuestUser)) && (
            <Button
              size="large"
              type="primary"
              icon="edit"
              disabled={loading}
              onClick={() =>
                !!babyPage
                  ? history.push(`/babypages/save?id=${babyPage.id}`)
                  : {}
              }
            />
          )}
          <Button
            size="large"
            type="primary"
            icon={zoomed ? "zoom-out" : "zoom-in"}
            className="mobile-hidden"
            disabled={loading}
             onClick={() => ( setZoomed(!zoomed))}
          />
          {isPlus ? 
            <Button
              size="large"
              type="primary"
              icon="download"
              disabled={loading}
              onClick={() => {
                  setDownloadFreeBP(true);
              }}
            />
          :
            null
          }
          <Button
            size="large"
            type="primary"
            icon={"share-alt"}
            disabled={loading}
            onClick={() => isPlus ? createShareImage(babyPage) : setUpgradeToPlus(true)}
          />
        </Button.Group>
      )}
      <ShareModal
        visible={share !== ""}
        onClose={() => setShare("")}
        image={share}
        setShowSharePlus={setShowSharePlus}
        isPlus={isPlus}
        />
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => ({
  isPlus: !!state.user.user_level && state.user.user_level === 1,
  stripeProducts: state.stripeProduct
});

export default connect(mapStateToProps)(PhotoModal);
