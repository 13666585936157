import { Col, Row, Spin } from "antd";
import React, { useState } from "react";
import FontForm from "../../../../components/FontForm/FontForm";
import { Font } from "../../../../interfaces/font";

const FontNewPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [fontForm, setFontForm] = useState<Font>({
    id: 0,
    name: "",
    family: "",
    source: "",
    format: "",
    weight: "",
    style: "",
    is_active: false,
    plus: false
  });

  return (
    <Row>
      <Col span={14} offset={5} style={{ padding: "5px" }}>
        <div className="m-3">
          <h1 className="title">You're creating a new Font</h1>
          {loading ? (
            <div className="col-12 d-flex justify-content-center">
              <Spin style={{ fontSize: 36, margin: 50 }} />
            </div>
          ) : (
            <FontForm
              setLoading={setLoading}
              fontForm={fontForm}
              setFontForm={setFontForm}
            />
          )}
        </div>
      </Col>
    </Row>
  );
};

export default FontNewPage;
