import { call, select } from "redux-saga/effects";
import { AppState } from "..";
import api from "../../api";
import { parseRequestError } from "../../utils/error";
import { Frame } from "../../interfaces/frame";

/**
 * Get frames 
 */

export function* getFrames(
  callback: (err?: string, response?: Frame[]) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.admin.access_token
  );

  try {
    const response = yield call(api.get, `api/get-frames?lang=en`, {
      headers: {
        Authorization: accessToken
      }
    });

    callback(undefined, response.data.data);
  } catch (error) {
    callback(parseRequestError(error));
  }
}
