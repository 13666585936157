import { Button, Modal, notification } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import "./ShareModal.scss";
import { shareFacebook } from "../../utils/share";
import CopyToClipboard from "react-copy-to-clipboard";

interface Props {
  image: string;
  visible: boolean;
  onClose: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  setShowSharePlus?: React.Dispatch<React.SetStateAction<boolean>>;
  isPlus?: boolean;
}

const ShareModal: React.FC<Props> = ({ image, visible, onClose, setShowSharePlus, isPlus }) => {
  const [modal, setModal] = useState(false);

  useEffect(() => {
    setModal(visible);
  }, [visible]);

  const checkFreeUser = useCallback(() => {
    if(setShowSharePlus && !isPlus) {
      setShowSharePlus(true);
    }
  }, [setShowSharePlus, isPlus])

  return (
    <Modal
      centered
      visible={modal}
      footer={null}
      onCancel={onClose}
      width="auto"
      bodyStyle={{ padding: 0 }}
    >
      <div className="p-5">
        <h1 className="share-title">
          Share this in your social media networks
        </h1>
        <div className="row justify-content-center align-items-center share-buttons">
          <div className="col-12 col-md-6 my-1">
            <Button
              type="primary"
              size="large"
              shape="round"
              icon="facebook"
              className="w-100"
              onClick={() => {
                shareFacebook(image, setModal);
              }}
            >
              Facebook
            </Button>
          </div>
          <div className="col-12 col-md-6 my-1">
            <CopyToClipboard
              text={image}
              onCopy={() => {
                if (isPlus) {
                  notification.success({
                    message: "Success",
                    description: "The BabyPage link was copied successfully!"
                  })
                }
              }}
            >
              <Button
                type="primary"
                size="large"
                shape="round"
                icon="copy"
                className="w-100"
                onClick={checkFreeUser}
              >
                Copy Link
              </Button>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShareModal;
