import {
  DeepLinkInterface,
  GuestUserInterface,
} from "../../interfaces/guestUser";
import {
  BaseAction,
  CLEAR_DEEP_LINK_INFO,
  SAVE_DEEP_LINK_INFO,
  SELECT_GUEST_USER,
} from "../actionTypes";

export interface GuestUsersState {
  selectedGuestUser: GuestUserInterface;
  deepLinkInfo: DeepLinkInterface;
}

export const initialDeepLinkInfo = {
  invitation_code: "",
  users_exists: "",
  additional_data: {
    full_name: '',
    email: '',
  },
};

const initialState: GuestUsersState = {
  selectedGuestUser: {
    fullName: "",
    email: "",
    permission: "Read-Only",
    family_relationship_id:""
  },
  deepLinkInfo: initialDeepLinkInfo,
};

export const guestUser = (
  state: GuestUsersState = initialState,
  action: BaseAction
): GuestUsersState => {
  switch (action.type) {
    case SELECT_GUEST_USER:
      return {
        ...state,
        selectedGuestUser: action.payload,
      };
    case SAVE_DEEP_LINK_INFO:
      return {
        ...state,
        deepLinkInfo: action.payload,
      };
    case CLEAR_DEEP_LINK_INFO:
      return {
        ...state,
        deepLinkInfo: initialDeepLinkInfo,
      };
    default:
      return state;
  }
};
Object.freeze(guestUser);

const selectGuestUser = (payload: GuestUserInterface) => ({
  payload,
  type: SELECT_GUEST_USER,
});
Object.freeze(selectGuestUser);

const saveDeepLinkInfo = (payload: DeepLinkInterface) => ({
  payload,
  type: SAVE_DEEP_LINK_INFO,
});
Object.freeze(selectGuestUser);

const clearDeepLinkInfo = () => ({
  type: CLEAR_DEEP_LINK_INFO,
});
Object.freeze(clearDeepLinkInfo);

export { selectGuestUser, saveDeepLinkInfo, clearDeepLinkInfo };
export default guestUser;
