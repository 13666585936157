import {Icon, Modal, notification, Spin} from "antd";
import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useHistory} from "react-router";
import {useParams} from "react-router-dom";
import BaseLayout from '../../../components/BaseLayout/BaseLayout';
import GuestUserForm from "../../../components/GuestUserForm/GuestUserForm";
import {GuestUserInterface} from "../../../interfaces/guestUser";
import {AppState, sagaMiddleware} from '../../../store';
import {updateGuestUser} from "../../../store/sagas/guestUser";
import { getFamilyRelationships } from "../../../store/sagas/familyRelationship";

interface Props {
  selectedGuestUser: GuestUserInterface;
}

const UpdateGuestUserPage: React.FC<Props> = ({selectedGuestUser}) => {
  const history = useHistory();
  let { id } = useParams();
  let [familyRelationshipNames, setFamilyRelationshipNames] = useState([]);
  let [loading, setLoading] = useState<boolean>(true);
  let [guestUserData, setGuestUserData] = useState<GuestUserInterface>({
    fullName: '',
    email: '',
    permission: 'Read-Only',
    family_relationship_id:''
  });

  useEffect(() => {
    setLoading(true);
    
    sagaMiddleware.run<any>(
      getFamilyRelationships,
      (err?: string, response?: any) => {
        setLoading(false);

        if (!!err) {
          Modal.error({
            title: 'Error',
            content: err
          });

          return;
        }

        setFamilyRelationshipNames(response);

      });
  }, []);

  useEffect(() => {
    setGuestUserData({
      ...selectedGuestUser,
      id: id
    });
    setLoading(false);
  }, [selectedGuestUser, id]);

  const handleSubmit = useCallback(
    (event: any) => {
      event.preventDefault();

      setLoading(true);

      const body : any = {
        full_name: guestUserData.fullName,
        email: guestUserData.email,
        permission: guestUserData.permission,
      };

      if (!!guestUserData.family_relationship_id) {
        body.family_relationship_id = guestUserData.family_relationship_id;
      }

      sagaMiddleware.run<any>(
        updateGuestUser,
        guestUserData.id,
        body,
        (err?: string) => {
          setLoading(false);

          if (!!err) {
            Modal.error({
              title: "Error",
              content: err,
            });

            return;
          }

          notification.success({ message: "Guest User updated successfully" });
          history.push("/guest-users");
        }
      );
    },
    [guestUserData, history]
  );

  return (
    <BaseLayout
      title='BabyPage - Invite User'
      pageTitle='Invite User'
      hideHeaderChildMenu
      hideChildMenu
    >
      {loading ? (
        <div className="mx-auto d-table">
          <Spin
            indicator={<Icon type="loading" style={{fontSize: 24}} spin/>}
          />
        </div>
      ) : guestUserData.id && (
        <GuestUserForm
        handleSubmit={handleSubmit}
        setGuestUserData={setGuestUserData}
        guestUserData={guestUserData}
        familyRelationshipNames={familyRelationshipNames}
        loading={loading}/>
      )}
    </BaseLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  selectedGuestUser: state.guestUsers.selectedGuestUser
});

export default connect(mapStateToProps)(UpdateGuestUserPage);
