import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { storageKey } from '../env';
import {
  Admin as AdminState,
  Customer as CustomerState,
  User as UserState,
  Maintenance as MaintenanceState,
} from '../interfaces';
import adminReducer from './ducks/admin';
import babyReducer, { BabyState } from './ducks/baby';
import bookReducer, { BookState } from './ducks/books';
import bpHelperReducer, { BPHelperState } from './ducks/bpHelper';
import customerReducer from './ducks/customer';
import dashboardReducer, { DashboardState } from './ducks/dashboard';
import growthChartReducer, { GrowthChartState } from './ducks/growthChart';
import milestoneReducer, { MilestoneState } from './ducks/milestones';
import photosReducer, { PhotoState } from './ducks/photos';
import productReducer, { ProductState } from './ducks/product';
import stripeProductReducer, {
  StripeProductState,
} from './ducks/stripeProduct';
import userReducer from './ducks/user';
import guestUsersReducer, { GuestUsersState } from './ducks/guesUsers';
import maintenanceReducer from './ducks/maintenance';

export interface AppState {
  admin: AdminState;
  user: UserState;
  customer: CustomerState;
  bpHelper: BPHelperState;
  baby: BabyState;
  product: ProductState;
  dashboard: DashboardState;
  milestones: MilestoneState;
  books: BookState;
  growthChart: GrowthChartState;
  photos: PhotoState;
  stripeProduct: StripeProductState;
  guestUsers: GuestUsersState;
  maintenance: MaintenanceState;
}

const persistedReducer = persistReducer(
  {
    key: storageKey,
    storage,
    /**
     * Which states we will save in localStorage
     */
    whitelist: [
      'maintenance',
      'admin',
      'user',
      'customer',
      'baby',
      'product',
      'dashboard',
      'milestones',
      'bpHelper',
      'books',
      'growthChart',
      'photos',
      'stripeProduct',
      'guestUsers',
    ],
  },
  combineReducers({
    maintenance: maintenanceReducer,
    admin: adminReducer,
    dashboard: dashboardReducer,
    customer: customerReducer,
    user: userReducer,
    growthChart: growthChartReducer,
    bpHelper: bpHelperReducer,
    baby: babyReducer,
    product: productReducer,
    stripeProduct: stripeProductReducer,
    milestones: milestoneReducer,
    books: bookReducer,
    photos: photosReducer,
    guestUsers: guestUsersReducer,
  })
);

// Saga Middleware will be used to do Async Requests
export const sagaMiddleware = createSagaMiddleware();

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

export const persistor = persistStore(store);

export default store;
