import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Dispatch } from "redux";
import { Baby, Maintenance, User } from "../../interfaces";
import { AppState, sagaMiddleware } from "../../store";
import { BaseAction } from "../../store/actionTypes";
import { clearDeepLinkInfo } from "../../store/ducks/guesUsers";
import { logoutUser } from "../../store/ducks/user";
import { getGuestUserInfo } from "../../store/sagas/guestUser";
import BottomNavigation from "../Footer/BottomNavigation";
import GuestUserModal from "../GuestUserModal/GuestUserModal";
import CustomDrawer from "../Header/CustomDrawer";
import Header from "../Header/Header";
import Banner from "../Banner/Banner";
import "./BaseLayout.scss";
import { getMaintenance } from "../../store/sagas/maintenance";

interface Props {
  children?: any;
  title?: string;
  pageTitle?: string;
  pageSubtitle?: string;
  pageStyle?: React.CSSProperties | undefined;
  contentStyle?: React.CSSProperties | undefined;
  hideHeader?: boolean;
  hideFooter?: boolean;
  hideFooterLinks?: boolean;
  hideChildMenu?: boolean;
  hideHeaderChildMenu?: boolean;
  logout: Function;
  babySelected?: Baby;
  user: User;
  invitationCode: string;
  clearDeepLinkInfoFromStore: () => void;
  hideBanner?: boolean;
  maintenance?: Maintenance;
}

const BaseLayout: React.FC<Props> = ({
  children,
  title,
  pageTitle,
  pageSubtitle,
  pageStyle,
  contentStyle,
  hideHeader,
  hideFooter,
  hideFooterLinks,
  hideHeaderChildMenu,
  logout,
  babySelected,
  user,
  invitationCode,
  clearDeepLinkInfoFromStore,
  hideBanner,
  maintenance
}) => {
  // React hook to paint drawer on screen
  const location = useLocation();
  const history = useHistory();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [guestUserModalVisible, setGuestUserModalVisible] = useState(false);

  useEffect(() => {
    if(maintenance?.in_maintenance) {
      history.push("/maintenance")
    }
  }, [maintenance?.in_maintenance])

  useEffect(() => {
    sagaMiddleware.run<any>(getMaintenance);
  }, [location.pathname])

  useEffect(() => {
    if (!!invitationCode && window.location.pathname === "/") {
      sagaMiddleware.run<any>(
        getGuestUserInfo,
        invitationCode,
        (err: string, data: any) => {
          if (!err) {
            setGuestUserModalVisible(true);
          }
        }
      );
    }
  }, [invitationCode]);
  const showBanner = !!hideBanner ? false : !!user && user.user_level === 0;

  const storeLength = useMemo(() => {
    if (!!user.store) {
      try {
        const store = JSON.parse(user.store);
        if (!!store && Array.isArray(store)) return store.length;
      } catch (error) {}
    }
    return 0;
  }, [user]);

  const alexandersOrderCounter = useMemo(() => {
    return user.alexanders_orders_count || 0;
  }, [user]);

  return (
    <div className="BasePage" style={{...pageStyle, position: drawerOpen ? "fixed" : "static"}}>    
       
      {!hideHeader && (
        <Header
          pageTitle={pageTitle}
          pageSubtitle={pageSubtitle}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          logout={logout}
          location={location}
          history={history}
          babySelected={babySelected}
          hideHeaderChildMenu={hideHeaderChildMenu}
          user={user}
          storeLength={storeLength}
          alexandersOrderCounter={alexandersOrderCounter}
          showGuestUserModal={() => setGuestUserModalVisible(true)}
        />
      )}
      {
        showBanner ? <Banner content="UPGRADE TO PLUS +" />
                   : null
      }
      {/* Helmet attributes (update html title) */}
      {title && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
      <div className="BaseContent" style={contentStyle}>
        {children}
      </div>
      {!hideFooter && <BottomNavigation hideLinks={hideFooterLinks} />}
      <CustomDrawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        logout={logout}
        location={location}
        history={history}
        user={user}
        storeLength={storeLength}
        showGuestUserModal={() => setGuestUserModalVisible(true)}
        alexandersOrderCounter={alexandersOrderCounter}
      />

      {!!guestUserModalVisible && (
        <GuestUserModal
          isVisible={guestUserModalVisible}
          closeGuestUserModal={() => {
            clearDeepLinkInfoFromStore();
            setGuestUserModalVisible(false);
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  babySelected: state.baby.babySelected,
  user: state.user,
  invitationCode: state.guestUsers.deepLinkInfo?.invitation_code,
  maintenance: state.maintenance
});

const mapDispatchToProps = (dispatch: Dispatch<BaseAction>) => ({
  logout: () => dispatch(logoutUser()),
  clearDeepLinkInfoFromStore: () => dispatch(clearDeepLinkInfo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BaseLayout);
