import { Avatar, Button, Card, Col, Input, notification, Row, Spin } from "antd";
import Meta from "antd/lib/card/Meta";
import Chunk from "lodash/chunk";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Milestone } from "../../../../interfaces/milestone";
import { sagaMiddleware } from "../../../../store";
import { getMilestonesForAdmin } from "../../../../store/sagas/milestone";

const MilestoneListPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [milestoneSearch, setMilestoneSearch] = useState("");
  const [debounce, setDebounce] = useState<NodeJS.Timeout>();
  const [milestoneList, setMilestoneList] = useState<Milestone[]>([]);
  const [fragmentedMilestoneList, setFragmentedMilestoneList] = useState<
    Milestone[][]
  >([]);
  const history = useHistory();

  const chunkMilestoneList = useCallback(
    (milestoneList: Milestone[]): Milestone[][] => {
      return Chunk(milestoneList, 3);
    },
    []
  );

  useEffect(() => {
    setLoading(true);

    sagaMiddleware.run<any>(
      getMilestonesForAdmin,
      {},
      (err?: string, response?: Milestone[]) => {
        setLoading(false);

        if (!!err) {
          notification.error({
            message: "Error",
            description: err
          });
        } else if (!!response) {
          setMilestoneList(response);
          setFragmentedMilestoneList(chunkMilestoneList(response));
        }
      }
    );
  }, [chunkMilestoneList]);

  const filterMilestoneList = useCallback(
    milestoneSearch => {
      setLoading(true);

      const searchWordsArray = milestoneSearch
        .trim()
        .split(" ")
        .filter((word: string) => word.length > 0)
        .map((word: string) => word.toLowerCase());

      if (searchWordsArray.length < 1) {
        setFragmentedMilestoneList(chunkMilestoneList(milestoneList));
      } else {
        const filteredMilestones = milestoneList.filter(
          (milestone: Milestone) => {
            const matchesArray = searchWordsArray.filter(
              (word: string) =>
                milestone.title_name.toLowerCase().indexOf(word) >= 0
            );
            return matchesArray.length > 0;
          }
        );

        setFragmentedMilestoneList(chunkMilestoneList(filteredMilestones));
      }

      setLoading(false);
    },
    [milestoneList, chunkMilestoneList]
  );

  const onChangeMilestoneSearch = useCallback(
    milestoneSearch => {
      if (debounce) clearTimeout(debounce);

      setDebounce(
        setTimeout(() => {
          filterMilestoneList(milestoneSearch);
        }, 400)
      );
    },
    [debounce, filterMilestoneList]
  );

  return (
    <div className="m-3">
      <h1 className="title">Milestones</h1>
      <div className="row justify-content-center align-items-center mx-3">
        <div className="col-8 col-lg-4 my-1">
          <Input
            placeholder="Search"
            value={milestoneSearch}
            onChange={e => {
              setMilestoneSearch(e.target.value);
              onChangeMilestoneSearch(e.target.value);
            }}
            className="w-100"
          />
        </div>
      </div>
      <Row style={{ textAlign: "center" }}>
        <Button
          type="primary"
          disabled={loading}
          style={{ margin: "10px" }}
          onClick={() => {
            history.push('/admin/milestones/new');
          }}
        >
          CREATE A NEW MILESTONE
        </Button>
      </Row>
      {loading && (
        <div className="col-12 d-flex justify-content-center">
          <Spin style={{ fontSize: 36, margin: 50 }} />
        </div>
      )}
      {fragmentedMilestoneList.length > 0 &&
        fragmentedMilestoneList.map((chunk: any, index: number) => (
          <Row key={index}>
            {chunk.map((milestone: Milestone) => {
              return (
                <Col span={8} key={milestone.id}>
                  <Card
                    style={{ margin: 10 }}
                    hoverable={true}
                    onClick={() =>
                      history.push(`/admin/milestones/${milestone.id}`)
                    }
                  >
                    <Meta
                      avatar={
                        <Avatar
                          src={milestone.url_image || milestone.image_url}
                        />
                      }
                      title={milestone.title_name}
                      description={milestone.description}
                    />
                  </Card>
                </Col>
              );
            })}
          </Row>
        ))}
    </div>
  );
};

export default MilestoneListPage;
