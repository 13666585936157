import { isEqual } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { Baby, SimpleBaby } from '../../interfaces';
import {
  BaseAction,
  GET_BABIES,
  SELECT_BABY,
  SELECT_LAST_BABY,
  USER_LOGOUT
} from '../actionTypes';

export interface BabyState {
  babies: Baby[];
  simpleBabies: SimpleBaby[];
  babySelected?: Baby;
  babySelectedIndex?: number;
}

const initialState: BabyState = {
  babies: [],
  simpleBabies: []
};

export const baby = (
  state: BabyState = initialState,
  action: BaseAction
): BabyState => {
  let babySelectedIndex = state.babySelectedIndex;

  switch (action.type) {
    case GET_BABIES:
      return {
        ...state,
        babies: [...action.payload],
        simpleBabies: action.payload.map((e: Baby) => ({
          id: e.id,
          baby_first_name: e.baby_first_name,
          baby_last_name: e.baby_last_name
        }))
      };
    case SELECT_BABY:
      babySelectedIndex = action.payload as number;
      if (
        state.babySelected &&
        isEqual(state.babies[babySelectedIndex], state.babySelected)
      ) {
        return state;
      }

      return {
        ...state,
        babySelected:
          babySelectedIndex >= 0
            ? cloneDeep(state.babies[babySelectedIndex])
            : undefined,
        babySelectedIndex
      };
    case SELECT_LAST_BABY:
      if (state.babies.length === 0) {
        return state;
      }
      babySelectedIndex = state.babies.length - 1;

      return {
        ...state,
        babySelected:
          babySelectedIndex >= 0
            ? cloneDeep(state.babies[babySelectedIndex])
            : undefined,
        babySelectedIndex
      };
    case USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
Object.freeze(baby);

const getBabies = (payload: Baby[]) => ({
  payload,
  type: GET_BABIES
});
Object.freeze(getBabies);

const selectBaby = (payload?: number) => ({
  payload,
  type: SELECT_BABY
});
Object.freeze(selectBaby);

const selectLastBaby = () => ({
  type: SELECT_LAST_BABY
});
Object.freeze(selectLastBaby);

export { getBabies, selectBaby, selectLastBaby };
export default baby;
