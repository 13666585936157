import { notification } from "antd";
declare const FB: any;

export const shareFacebook = (
  image: string,
  setModal: React.Dispatch<React.SetStateAction<boolean>>
) => {
  FB.ui(
    {
      method: "share",
      href: image,
      display: "popup"
    },
    function(response: any) {
      if (response && !response.error_message) {
        notification.success({
          message: "Success",
          description: "Posting completed succesfully!"
        });
        setModal(false);
      }
    }
  );
};
