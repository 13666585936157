import React from "react";
import "./PlusAlert.scss";

import { getBrowserWidth } from "../../../utils/size";

interface Props {
  [key: string]: any;
}

const PlusAlert: React.FC<Props> = ({ ...props }) => {
  const width = getBrowserWidth();
  
  let style  = {};

  if(props.preview) {
    style = {
      fontSize: "10px",
      marginTop: -6,
      marginLeft: 70,
    }
  } else if (props.aftersave) {
    if(width >= 1024) {
      style = {
        marginTop: -15,
        marginLeft: 320,
      }
    } else if (width >= 768) {
      style = {
        marginTop: -15,
        marginLeft: 340,
      }
    } else if (width <= 414) {
      style = {
        marginTop: -15,
        marginLeft: 250,
      }
    }
    
  }
  
  
  return (
  <div {...props} className="text-center plus-alert" style={style}>
    PLUS
  </div>
);
}
export default PlusAlert;
