import React from "react";

interface Props {
  classes?: string;
}


const Instagram: React.FC<Props> = ({
  classes
  }) => {
  return (
    <a href="https://www.instagram.com/babypage/" target="_blank">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 256 256"
        className={classes}
      >  
          <path
            fill="#1a1a1a"
            strokeMiterlimit="10"
            d="M9.998 3C6.139 3 3 6.142 3 10.002v10C3 23.861 6.142 27 10.002 27h10C23.861 27 27 23.858 27 19.998v-10C27 6.139 23.858 3 19.998 3zM22 7a1 1 0 110 2 1 1 0 010-2zm-7 2c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6zm0 2a4 4 0 100 8 4 4 0 000-8z"
            fontFamily="none"
            fontSize="none"
            fontWeight="none"
            textAnchor="none"
            transform="scale(8.53333)"
          ></path>
      </svg>
    </a>
  );
}

export default Instagram;
