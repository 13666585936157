import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Icon,
  Input,
  InputNumber,
  Modal,
  notification,
  Radio,
  Select
} from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { RadioChangeEvent } from 'antd/lib/radio';
import moment from 'moment';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { sagaMiddleware } from '../../store';
import { answerFormSaga, getFormByCode } from '../../store/sagas/form';

const FormPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState<any>();
  const history = useHistory();

  useEffect(() => {
    let code = queryString.parse(history.location.search).code;

    if (!code || !code.toString()) {
      history.push('/');
      return;
    }

    setLoading(true);

    sagaMiddleware.run<any>(
      getFormByCode,
      code.toString(),
      (err?: string, response?: any) => {
        setLoading(false);

        if (!!err) {
          notification.error({
            message: 'Error',
            description: err
          });

          history.push('/');
          return;
        } else if (!!response) {
          setForm(response);
        }
      },
      false
    );
  }, [history]);

  const submitForm = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const invalid = form.questions.find(
        (q: any) =>
          !!q.is_required &&
          (q.type_answer === 'INPUT'
            ? !q.answer
            : !q.other_answer && (!q.options_id || !q.options_id.length))
      );

      if (invalid) {
        Modal.error({
          title: 'Error',
          content: `Question "${invalid.question}" is required`
        });
        return;
      }

      setLoading(true);

      sagaMiddleware.run<any>(
        answerFormSaga,
        {
          form_id: form.id,
          extra_question_answer: !!form.extra_question_answer
            ? form.extra_question_answer
            : undefined,
          answers: form.questions
            .filter((q: any) =>
              q.type_answer === 'INPUT'
                ? !!q.answer
                : !!q.other_answer || (!!q.options_id && !!q.options_id.length)
            )
            .map((q: any) => {
              const answer: any = {
                question_id: q.id
              };

              if (q.type_answer === 'INPUT') {
                answer.answer = q.answer;
              } else if (!!q.other_answer) {
                answer.other_answer = q.other_answer;
              } else {
                answer.options_id = q.options_id;
              }

              return answer;
            })
        },
        (err?: string) => {
          setLoading(false);

          if (!!err) {
            notification.error({
              message: 'Error',
              description: err
            });
          } else {
            notification.success({
              message: 'Success',
              description: 'Thank you for answering our form!'
            });

            history.push('/');
          }
        }
      );
    },
    [form, history]
  );

  return loading || !form ? (
    <div className="d-table mx-auto my-3">
      <Icon type="loading" style={{ fontSize: 36 }} />
    </div>
  ) : (
    <div className="container my-3">
      <h1 className="title">{form.title}</h1>
      <form className="row justify-content-center" onSubmit={submitForm}>
        {form.questions.map((q: any, index: number) => (
          <div key={q.id} className="col-12 col-lg-7 my-2">
            <Card className="w-100 elevation-light">
              <h2>
                {q.question}{' '}
                {!!q.is_required && <span className="secondary">(*)</span>}
              </h2>

              {q.type_answer === 'INPUT' ? (
                q.type_data === 'TEXT' ? (
                  <Input.TextArea
                    autoSize={{ minRows: 1, maxRows: 6 }}
                    className="w-100"
                    maxLength={1024}
                    value={q.answer || ''}
                    onChange={e => {
                      e.persist();

                      setForm((state: any) => ({
                        ...state,
                        // QM / IM (QuestionMap, IndexMap) to don't confuse with the top map
                        questions: state.questions.map((qm: any, im: number) =>
                          index === im ? { ...q, answer: e.target.value } : qm
                        )
                      }));
                    }}
                  />
                ) : q.type_data === 'NUMBER' ? (
                  <InputNumber
                    className="w-100"
                    value={q.answer}
                    onChange={answer =>
                      setForm((state: any) => ({
                        ...state,
                        // QM / IM (QuestionMap, IndexMap) to don't confuse with the top map
                        questions: state.questions.map((qm: any, im: number) =>
                          index === im ? { ...q, answer } : qm
                        )
                      }))
                    }
                  />
                ) : (
                  <DatePicker
                    className="w-100"
                    onChange={(
                      date: moment.Moment | null,
                      dateString: string
                    ) =>
                      setForm((state: any) => ({
                        ...state,
                        // QM / IM (QuestionMap, IndexMap) to don't confuse with the top map
                        questions: state.questions.map((qm: any, im: number) =>
                          index === im
                            ? { ...q, answer: dateString || undefined }
                            : qm
                        )
                      }))
                    }
                  />
                )
              ) : (
                <>
                  {q.type_answer === 'LIST' ? (
                    <>
                      <Select
                        value={!!q.options_id ? q.options_id[0] : undefined}
                        onChange={(e: any) =>
                          setForm((state: any) => ({
                            ...state,
                            // QM / IM (QuestionMap, IndexMap) to don't confuse with the top map
                            questions: state.questions.map(
                              (qm: any, im: number) =>
                                index === im
                                  ? {
                                      ...q,
                                      options_id: !!e ? [e] : undefined,
                                      other_answer: undefined
                                    }
                                  : qm
                            )
                          }))
                        }
                      >
                        {q.options.map((o: any) => (
                          <Select.Option key={o.id} value={o.id}>
                            {' '}
                            {o.description}{' '}
                          </Select.Option>
                        ))}
                      </Select>
                    </>
                  ) : q.type_answer === 'RADIO' ? (
                    <>
                      <br />
                      <Radio.Group
                        value={!!q.options_id ? q.options_id[0] : undefined}
                        onChange={(e: RadioChangeEvent) =>
                          setForm((state: any) => ({
                            ...state,
                            // QM / IM (QuestionMap, IndexMap) to don't confuse with the top map
                            questions: state.questions.map(
                              (qm: any, im: number) =>
                                index === im
                                  ? {
                                      ...q,
                                      options_id: !!e.target.value
                                        ? [e.target.value]
                                        : undefined,
                                      other_answer: undefined
                                    }
                                  : qm
                            )
                          }))
                        }
                      >
                        {q.options.map((o: any) => (
                          <Radio key={o.id} value={o.id}>
                            {o.description}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </>
                  ) : (
                    <>
                      <br />
                      <Checkbox.Group
                        options={q.options.map((o: any) => ({
                          label: o.description,
                          value: o.id
                        }))}
                        value={q.options_id || []}
                        onChange={(options_id: CheckboxValueType[]) =>
                          setForm((state: any) => ({
                            ...state,
                            // QM / IM (QuestionMap, IndexMap) to don't confuse with the top map
                            questions: state.questions.map(
                              (qm: any, im: number) =>
                                index === im
                                  ? {
                                      ...q,
                                      options_id,
                                      other_answer: undefined
                                    }
                                  : qm
                            )
                          }))
                        }
                      />
                    </>
                  )}
                  {!!q.has_other && (
                    <Input
                      type="text"
                      maxLength={255}
                      className="w-100 mt-1"
                      placeholder="Other"
                      value={q.other_answer || ''}
                      onChange={e => {
                        e.persist();

                        setForm((state: any) => ({
                          ...state,
                          // QM / IM (QuestionMap, IndexMap) to don't confuse with the top map
                          questions: state.questions.map(
                            (qm: any, im: number) =>
                              index === im
                                ? {
                                    ...q,
                                    other_answer: e.target.value,
                                    options_id: undefined
                                  }
                                : qm
                          )
                        }));
                      }}
                    />
                  )}
                </>
              )}
            </Card>
          </div>
        ))}
        {!!form.extra_question && (
          <div className="col-12 col-lg-7 my-2">
            <Card className="w-100 elevation-light">
              <h2>{form.extra_question}</h2>
              <Input.TextArea
                className="w-100 mt-1"
                rows={6}
                maxLength={1024}
                value={form.extra_question_answer || ''}
                onChange={e => {
                  e.persist();

                  setForm((state: any) => ({
                    ...state,
                    extra_question_answer: e.target.value
                  }));
                }}
              />
            </Card>
          </div>
        )}
        <div className="col-12 my-3">
          <Button
            htmlType="submit"
            type="primary"
            className="mx-auto mt-1 mb-3 d-table"
            disabled={loading}
          >
            SUBMIT
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FormPage;
