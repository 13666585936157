import { Button, Modal } from "antd";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

interface Props {
  customText?: string;
  customTextBold?: boolean;
  visible: boolean;
  onClose: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  message?: string;
  step?: number;
  manageChildrenMsg?: boolean;
  afterSaveBPMsg?: boolean;
  onSaveFreeFeatures?: () => void;
  onSaveBeforeUpgradeToPlus?: () => void;
  isPhotoOnly?: boolean;
  savingBabyPage?: boolean;
  bookMessage?: boolean;
}

const ModalPlus: React.FC<Props> = ({
  customText,
  customTextBold,
  visible,
  onClose,
  afterSaveBPMsg,
  onSaveFreeFeatures,
  onSaveBeforeUpgradeToPlus,
  savingBabyPage,
  bookMessage
}) => {
  const history = useHistory();
  const mainMessage = savingBabyPage ?  "You have PLUS features within your babypage. Upgrade to a PLUS Subscription to save with these exclusive features." 
                                     : bookMessage ? "You have selected a BabyPage PLUS feature. Upgrade to a PLUS Subscription to access this and all other PLUS features."
                                     : "This is a PLUS feature. Upgrade to a PLUS Subscription to save with these exclusive features."


  const isSaveFreeFeatures = !!onSaveFreeFeatures;

  const ModalPlusButtons = () => (
    <div  className="row justify-content-center align-items-center" style={{ padding: 20 }}>
    <Button
      type="primary"
      className="mx-2"
      shape="round"
      onClick={!!onSaveBeforeUpgradeToPlus ? onSaveBeforeUpgradeToPlus :  () => {
        history.push("/cart/subscription/yearly")
      }}
    >
      Upgrade to PLUS
    </Button>
    {!!onSaveFreeFeatures ? (
      <Button
      type="primary"
      className="mx-2"
      shape="round"
      onClick={onSaveFreeFeatures}
    >
      Continue
    </Button>
    ) : null}
      
</div>
  );

  return (
    <Modal
      centered
      visible={visible}
      footer={null}
      onCancel={onClose}
      width="auto"
    >
      <div className="mt-4">
        {customText ? (
          <p className="text-center m-0">
            {customTextBold ? <strong>{customText}</strong> : customText}
          </p>
        ) : (
          <p
            className="text-center m-0"
            style={{ maxWidth: isSaveFreeFeatures ? 300 : "auto" }}
          >
            <strong>{mainMessage}</strong>
          </p>
        )}
        <ModalPlusButtons />
      </div>
    </Modal>
  );
};

export default ModalPlus;
