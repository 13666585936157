import { Button, Card, Icon, notification } from 'antd';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import VerticalDivider from '../../../../components/VerticalDivider/VerticalDivider';
import { sagaMiddleware } from '../../../../store';
import { getFormByCode, getReport } from '../../../../store/sagas/form';
import { capitalizeText } from '../../../../utils/string';

const typeAnswer = (
  type_answer: 'INPUT' | 'LIST' | 'RADIO' | 'CHECK',
  type_data: 'TEXT' | 'NUMBER' | 'DATE' | null
) => {
  if (type_answer === 'INPUT') {
    return `Text box (${capitalizeText(type_data ?? 'Text')})`;
  } else {
    return type_answer === 'LIST'
      ? 'Drop-down'
      : type_answer === 'RADIO'
      ? 'Multiple choice'
      : 'Checkboxes';
  }
};

const AdminDetailFormPage: React.FC = () => {
  const history = useHistory();

  const [form, setForm] = useState<any>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const code: any = queryString.parse(history.location.search)?.code;

    if (!!code && !!code.toString()) {
      // Get By Code
      setLoading(true);

      sagaMiddleware.run<any>(
        getFormByCode,
        code,
        (err?: string, response?: any) => {
          setLoading(false);

          if (!!err) {
            notification.error({
              message: 'Error',
              description: err,
            });

            history.push('/admin/forms');
            return;
          } else if (!!response) {
            try {
              setForm(response);
            } catch (error) {
              notification.error({
                message: 'Error',
                description: 'Failed to load form, please try again later',
              });

              history.push('/admin/forms');
              return;
            }
          }
        }
      );
    } else {
      history.push('/admin/forms');
      return;
    }
  }, [history]);

  const showReport = useCallback(
    (id?: number) => {
      setLoading(true);

      sagaMiddleware.run<any>(
        getReport,
        {
          formId: !!id ? undefined : form?.id,
          formQuestionId: !!id ? id : undefined,
        },
        (err?: string, response?: any) => {
          setLoading(false);

          if (!!err || !response || response.length === 0) {
            notification.error({
              message: 'Error',
              description: `This ${
                !!id ? 'question' : 'form'
              } doesn't have any answers`,
            });
          } else {
            if (!!id) {
              setForm((state: any) => ({
                ...state,
                questions: state.questions.map((q: any) => {
                  if (q.id !== id) return q;
                  const options = q.options.map((o: any) => ({
                    ...o,
                    percent:
                      response.find((r: any) => r.id === o.id)?.percent ?? '0',
                  }));

                  const other = response.find((r: any) => r.id === 0);

                  if (!!other) {
                    q.other_data = other;
                  }

                  return {
                    ...q,
                    options,
                  };
                }),
              }));
            } else {
              setForm((state: any) => ({
                ...state!,
                questions: state.questions.map((q: any) => {
                  const questionData = response.find(
                    (r: any) => r.form_question_id === q.id
                  );

                  if (!questionData) return q;

                  const options = q.options.map((o: any) => ({
                    ...o,
                    percent:
                      questionData.options?.find((qo: any) => qo.id === o.id)
                        ?.percent ?? '0',
                  }));

                  const other = questionData.options?.find(
                    (r: any) => r.id === 0
                  );

                  if (!!other) {
                    q.other_data = other;
                  }

                  return {
                    ...q,
                    options,
                  };
                }),
              }));
            }
          }
        }
      );
    },
    [form]
  );

  return loading || !form ? (
    <div className='d-table mx-auto my-3'>
      <Icon type='loading' style={{ fontSize: 36 }} />
    </div>
  ) : (
    <div className='container my-3'>
      <h1 className='title'>{form.title}</h1>
      <Button
        type='primary'
        size='small'
        className='d-table mx-auto'
        onClick={() => showReport()}
      >
        Show All Reports
      </Button>
      {form.questions?.map((q: any, index: number) => (
        <Card className='my-3 elevation-light' key={index}>
          <h3 className='m-0'>{q.question}</h3>
          <div className='d-flex align-items-center my-2'>
            <span>
              <strong>Type:</strong> {typeAnswer(q.type_answer, q.type_data)}
            </span>
            <VerticalDivider style={{ height: 16 }} />
            <span>
              <strong>Required:</strong> {q.is_required === 1 ? 'yes' : 'no'}
            </span>
            <VerticalDivider style={{ height: 16 }} />
            <span>
              <strong>Has other:</strong> {q.has_other === 1 ? 'yes' : 'no'}
            </span>
          </div>
          {q.type_answer !== 'INPUT' && (
            <>
              <h3 className='my-1'>Options:</h3>
              <div className='d-flex flex-wrap align-items-center'>
                {q.options.map((o: any, index: number) => (
                  <React.Fragment key={index}>
                    {index !== 0 && <VerticalDivider style={{ height: 16 }} />}
                    {o.description}
                    {!!o.percent && <strong>&nbsp;{`(${o.percent}%)`}</strong>}
                  </React.Fragment>
                ))}
                {q.has_other === 1 && (
                  <React.Fragment>
                    <VerticalDivider style={{ height: 16 }} /> Other
                    {(!!q.other_data?.percent || !!q.options[0]?.percent) && (
                      <strong>
                        &nbsp;{`(${q.other_data?.percent ?? 0}%)`}
                      </strong>
                    )}
                  </React.Fragment>
                )}
              </div>
              <Button
                type='primary'
                size='small'
                className='d-table mx-auto my-1'
                onClick={() => showReport(q.id)}
              >
                Show Reports
              </Button>
            </>
          )}
        </Card>
      ))}
    </div>
  );
};

export default AdminDetailFormPage;
