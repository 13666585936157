import { Modal } from 'antd';
import { History } from 'history';
import moment from 'moment';
import { call, select } from 'redux-saga/effects';
import { AppState } from '..';
import api from '../../api';
import {
  AnswersListInterface,
  FormListInterface,
  SaveForm
} from '../../interfaces/form';
import { parseRequestError } from '../../utils/error';

export function* getForms(
  search: string,
  callback: (err?: string, response?: FormListInterface) => void,
  page?: number
) {
  const accessToken = yield select(
    (state: AppState) => state.admin.access_token
  );

  try {
    if (!!search) {
      search = `search=${search}`;
    }

    if (!!page) {
      search += `${!!search ? '&' : ''}page=${page}`;
    }

    const response = yield call(api.get, `api/admin/form?${search}`, {
      headers: {
        Authorization: accessToken
      }
    });

    callback(undefined, response.data.data);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

export function* getFormByCode(
  code: string,
  callback: (err?: string, response?: any) => void,
  admin: boolean = true
) {
  const accessToken = yield select((state: AppState) =>
    !!admin ? state.admin.access_token : state.user.access_token
  );

  try {
    const response = yield call(api.get, `api/form/${code}`, {
      headers: {
        Authorization: accessToken
      }
    });

    callback(undefined, response.data.data);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

export function* saveFormSaga(
  form: SaveForm,
  callback: (err?: string) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.admin.access_token
  );

  try {
    yield call(api.post, 'api/admin/form', form, {
      headers: {
        Authorization: accessToken
      }
    });

    callback(undefined);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

export function* getAnswers(
  filter: {
    startDate?: string;
    endDate?: string;
    formId?: number;
    user?: string;
  },
  callback: (err?: string, response?: AnswersListInterface) => void,
  page?: number
) {
  const accessToken = yield select(
    (state: AppState) => state.admin.access_token
  );

  try {
    let search = '';

    if (!!filter.startDate) {
      search += `start_date=${filter.startDate}`;
    }

    if (!!filter.endDate) {
      search += `${!!search ? '&' : ''}end_date=${filter.endDate}`;
    }

    if (!!filter.formId) {
      search += `${!!search ? '&' : ''}form_id=${filter.formId}`;
    }

    if (!!filter.user) {
      search += `${!!search ? '&' : ''}user=${filter.user}`;
    }

    if (!!page) {
      search += `${!!search ? '&' : ''}page=${page}`;
    }

    const response = yield call(api.get, `api/admin/form/answers?${search}`, {
      headers: {
        Authorization: accessToken
      }
    });

    callback(undefined, response.data.data);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

export function* getDetailAnswer(
  answerId: number,
  callback: (err?: string, response?: any) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.admin.access_token
  );

  try {
    const response = yield call(api.get, `api/admin/form/answers/${answerId}`, {
      headers: {
        Authorization: accessToken
      }
    });

    callback(undefined, response.data.data);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

export function* getReport(
  filter: {
    formId?: number;
    formQuestionId?: number;
  },
  callback: (err?: string, response?: any) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.admin.access_token
  );

  try {
    const response = yield call(
      api.get,
      `api/admin/form/report?${
        !!filter.formId
          ? `form_id=${filter.formId}`
          : `form_question_id=${filter.formQuestionId}`
      }`,
      {
        headers: {
          Authorization: accessToken
        }
      }
    );

    callback(undefined, response.data.data);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

export function* deleteQuestionSaga(
  questionId: number,
  callback: (err?: string) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.admin.access_token
  );

  try {
    yield call(api.delete, `api/admin/form/question/${questionId}`, {
      headers: {
        Authorization: accessToken
      }
    });

    callback();
  } catch (error) {
    callback(parseRequestError(error));
  }
}

export function* deleteOptionSaga(
  optionId: number,
  callback: (err?: string) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.admin.access_token
  );

  try {
    yield call(api.delete, `api/admin/form/option/${optionId}`, {
      headers: {
        Authorization: accessToken
      }
    });

    callback();
  } catch (error) {
    callback(parseRequestError(error));
  }
}

export function* checkUserModal(history: History) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    const lastAsk = localStorage.getItem('lastAsk');

    if (!lastAsk || moment().diff(moment(lastAsk), 'days') > 6) {
      const response = yield call(api.get, 'api/daily-use', {
        headers: {
          Authorization: accessToken
        }
      });

      if (!response?.data?.data?.show_form) {
        // Don't show the modal
        return;
      }

      Modal.confirm({
        title: `Hi, we've noticed that you enjoy using BabyPage.`,
        content: `Would you be willing to take a quick survey about your BabyPage experience?`,
        centered: true,
        okText: 'Yes',
        okType: 'primary',
        cancelText: 'No',
        width: 300,
        icon: null,
        onOk() {
          localStorage.setItem('lastAsk', moment().toISOString());
          history.push('/form?code=survey_babypage');
        },
        onCancel() {
          localStorage.setItem('lastAsk', moment().toISOString());
        }
      });
    }
  } catch (error) {}
}

export function* answerFormSaga(answer: any, callback: (err?: string) => void) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  try {
    yield call(api.post, 'api/form', answer, {
      headers: {
        Authorization: accessToken
      }
    });

    callback();
  } catch (error) {
    callback(parseRequestError(error));
  }
}
