import { BaseAction, GET_MILESTONES, USER_LOGOUT } from '../actionTypes';
import { MilestonesBaby } from '../../interfaces';

export type MilestoneState = {
  milestones: MilestonesBaby[];
  loading: boolean;
};

const initialState: MilestoneState = {
  milestones: [],
  loading: true
};

export const milestones = (
  state: MilestoneState = initialState,
  action: BaseAction
): MilestoneState => {
  switch (action.type) {
    case GET_MILESTONES:
      return { milestones: [...action.payload], loading: false };
    case USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
Object.freeze(milestones);

const getMilestones = (payload: MilestonesBaby[]) => ({
  payload,
  type: GET_MILESTONES
});
Object.freeze(getMilestones);

export { getMilestones };
export default milestones;
