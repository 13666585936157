import React from "react";

function CameraIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="22"
      viewBox="0 0 26 22"
      className="camera-icon"
    >
      <path
        fill="#FFF"
        d="M16.25 0c.362 0 .7.177.901.472l1.845 2.706h3.754C24.545 3.178 26 4.6 26 6.356v11.651c0 1.755-1.455 3.178-3.25 3.178H3.25c-1.795 0-3.25-1.423-3.25-3.178V6.356C0 4.6 1.455 3.178 3.25 3.178h3.754L8.849.472C9.049.177 9.388 0 9.75 0h6.5zM13 6.74a5.296 5.296 0 100 10.593 5.296 5.296 0 000-10.592zm0 2.408a2.889 2.889 0 110 5.778 2.889 2.889 0 010-5.778z"
      ></path>
    </svg>
  );
}

export default CameraIcon;
