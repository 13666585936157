import { Button, Form, Input, Modal, notification } from "antd";
import { FormComponentProps } from "antd/lib/form";
import queryString from "query-string";
import React, { Dispatch, PureComponent } from "react";
import { connect } from "react-redux";
import { AiFillQuestionCircle } from "react-icons/ai";
import { RouteComponentProps, withRouter } from "react-router";
import { isEmail } from "validator";
import { clientId, clientSecret } from "../../env";
import { DeepLinkInterface } from "../../interfaces/guestUser";
import { AppState, sagaMiddleware } from "../../store";
import { BaseAction } from "../../store/actionTypes";
import { clearDeepLinkInfo } from "../../store/ducks/guesUsers";
import rootSaga, { signupSaga } from "../../store/sagas";
import { fetchDashboard } from "../../store/sagas/dashboard";
import {
  authUser,
  authUserFacebook,
  forgetPasswordUser,
  signUpUser,
  userFacebookExists,
  switchAccount,
  authUserApple,
} from "../../store/sagas/user";
import { startCase } from "lodash";
import { isEmailValid } from "./data/emails";
import FacebookButton from "./FacebookButton";
import AppleButton from "./AppleButton";
import { trackAction } from "../../utils/marketing";
import logoImg from "../../assets/img/logo_2.png";
import eyeClosed from "../../assets/icons/icon-close-eye.png";
import eyeOpen from "../../assets/icons/icon-open-eye.png";

declare const FB: any;
declare const AppleID: any;

function hasErrors(fieldsError: Record<string, string[] | undefined>) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

type Props = FormComponentProps<any> &
  RouteComponentProps<any, any, any> & {
    access_token?: string;
    deepLinkInfo: DeepLinkInterface;
    deepLinkInfoChanged: boolean;
    clearDeepLinkInfo: () => void;
  };

class SignInForm extends PureComponent<Props> {
  state = {
    signin: this.props.location.search === "?signin" ? true : false,
    passwordHidden: true,
    loading: false,
    faceLoading: false,
    confirmDirty: false,
    invitationCodeInfoModal: {
      open: false,
    },
    forgetPasswordModal: {
      open: false,
      email: "",
      valid: false,
    },
    customEmailModal: {
      open: false,
      first_name: "",
      last_name: "",
      email: "",
      valid: false,
      authResponse: {},
      meResponse: {},
    },
  };

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);

    // Redirect user if is logged
    if (!!this.props.access_token) {
      this.props.history.push(
        !!query.redirect ? query.redirect.toString() : "/"
      );
    }

    // Check if is an "redirect to signup"
    if (!!query.signup) {
      this.setState({ signin: !this.state.signin });

      setTimeout(() => {
        // To disabled submit button at the beginning.
        this.props.form.validateFields();
      }, 50);
    } else {
      // To disabled submit button at the beginning.
      this.props.form.validateFields();
    }
  }

  componentDidUpdate(prevProps: any) {
    if (
      this.props.deepLinkInfo.invitation_code !==
        prevProps.deepLinkInfo.invitation_code &&
      this.props.deepLinkInfo.invitation_code !== "" &&
      this.props.deepLinkInfo.additional_data.usersExists === false
    ) {
      this.setState({ signin: false });
    } else if (
      this.props.deepLinkInfo.invitation_code ===
        prevProps.deepLinkInfo.invitation_code &&
      this.props.deepLinkInfo.invitation_code !== "" &&
      this.props.deepLinkInfo.additional_data.usersExists === false &&
      this.state.signin
    ) {
      this.props.clearDeepLinkInfo();
    }
    this.props.location.search === "?signin"
      ? this.setState({ signin: true })
      : this.setState({ signin: false });

    if (prevProps.location.search !== this.props.location.search) {
      this.props.form.resetFields();
    }
  }

  hidePassword = () => {
    this.setState({ passwordHidden: !this.state.passwordHidden });
  };

  // To custom email validation
  handleConfirmBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  // To custom email validation
  validateEmail = (_rule: any, value: any, callback: any) => {
    if (value && !isEmailValid(value)) {
      callback("The email provided is not an email.");
    } else {
      callback();
    }
  };

  // To custom email validation
  validateToNextEmail = (_rule: any, value: any, callback: any) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["cfEmail"], { force: true });
    }
    callback();
  };

  // To custom email validation
  compareToFirstEmail = (_rule: any, value: any, callback: any) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("signupEmail")) {
      callback(" Email and Email Confirmation must be the same.");
    } else {
      callback();
    }
  };

  // Call Ant.Design notification manager
  _openNotificationWithIcon = (
    type: "success" | "error",
    title: string,
    message: string
  ) => {
    notification[type]({
      message: title,
      description: message,
    });
  };

  // On Submit (Sign in or Sign up)
  onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.props.form.validateFields((err: any, values: any) => {
      if (!err && !this.state.loading) {
        this.setState({ loading: true });

        // Prepare the saga (if is sign in or sign up)
        let saga: any = authUser;

        if (!this.state.signin) {
          saga = signUpUser;
          values.email = values.signupEmail;

          // Quora Tracking - New signUp
          trackAction("trackNewSignUp", {
            google: {
              event: "new_signUp",
            },
          });
        }

        // Run saga middleware
        sagaMiddleware.run<any>(saga, values, (response: any, error: any) => {
          this.setState({ loading: false });

          if (error) {
            this._openNotificationWithIcon("error", "Error", error);
            return;
          }

          if (!!values.invitationCode) {
            //Send guest directly to host's dashboard
            this.loginAsGuestUser(response.main_user.id, response.id);

            this.props.clearDeepLinkInfo();

            Modal.info({
              title: "Hi there!",
              content: `You're seeing the ${startCase(
                response.first_name
              )} ${startCase(
                response.last_name
              )}'s account as a guest user. Go to menu and select switch account to see your own content.`,
            });
          } else {
            if(this.state.signin) {
               sagaMiddleware.run<any>(rootSaga, true);
            } else {
              sagaMiddleware.run<any>(signupSaga)
            }

            if (response.user_level === 3) {
              this.props.history.push("/store");
              return;
            }

            const query = queryString.parse(this.props.location.search);

            if (response.babies_count > 0) {
              this.props.history.push(
                !!query.redirect ? query.redirect.toString() : "/"
              );
            } else {
              const firstHostUserId =
                response.host_users.length > 0
                  ? response.host_users[0].host_user_id
                  : null;

              if (!firstHostUserId) {
                this.props.history.push(
                  `/step-2${
                    !!query.redirect
                      ? `?redirect=${query.redirect.toString()}`
                      : this.props.location.search
                  }`
                );

                return;
              }

              this.loginAsGuestUser(response.id, firstHostUserId);

              Modal.info({
                title: "Hi there!",
                content: `You're seeing the ${startCase(
                  response.host_users[0].host_user.first_name
                )} ${startCase(
                  response.host_users[0].host_user.last_name
                )}'s account as a guest user. Go to menu and select switch account to see your own content.`,
              });
            }
          }
        });
      }
    });
  };

  // Open Invitation-Code Info Modal
  invitationCodeInfoToggle = () => {
    this.setState({
      invitationCodeInfoModal: {
        open: !this.state.invitationCodeInfoModal.open,
      },
    });
  };

  // Open forget password Modal
  forgetPasswordToggle = () => {
    this.setState({
      forgetPasswordModal: {
        open: !this.state.forgetPasswordModal.open,
        valid: false,
        email: "",
      },
    });
  };

  // Submit forget password form
  forgetPassword = () => {
    if (!this.state.forgetPasswordModal.valid) return;

    this.setState({ loading: true });

    sagaMiddleware.run<any>(
      forgetPasswordUser,
      { email: this.state.forgetPasswordModal.email },
      (response: any, error: any) => {
        this.setState({ loading: false });

        if (error) {
          this._openNotificationWithIcon("error", "Error", error);
          return;
        }

        // Success message
        this._openNotificationWithIcon("success", "Success", response);

        // Close modal
        this.forgetPasswordToggle();
      }
    );
  };

  finishAuth = (response: any) => {
    // this._openNotificationWithIcon(
    //   'success',
    //   'Success',
    //   `Welcome to BabyPage, ${capitalizeTexts(response.first_name)}!`
    // );

    if (response.user_level === 3) {
      this.props.history.push("/store");
      return;
    }

    // Run root saga (To get babies, ...)
    sagaMiddleware.run<any>(rootSaga, true);

    let query: any = {};

    try {
      query = queryString.parse(this.props.location.search) || {};
    } catch (err) {}

    if (!!response.babies_count) {
      this.props.history.push(
        !!query && !!query.redirect ? query.redirect.toString() : "/"
      );
    } else {
      this.props.history.push(
        `/step-2${
          !!query && !!query.redirect
            ? `?redirect=${query.redirect.toString()}`
            : this.props.location.search
        }`
      ); // Send user to next steps
    }
  };

  finishFacebookAuth = (authResponse: any, meResponse: any) => {
    if (
      !!this.state.customEmailModal.open &&
      !!this.state.customEmailModal.email &&
      !!this.state.customEmailModal.first_name &&
      !!this.state.customEmailModal.last_name
    ) {
      meResponse.first_name = this.state.customEmailModal.first_name;
      meResponse.last_name = this.state.customEmailModal.last_name;
      meResponse.email = this.state.customEmailModal.email;

      this.setState({
        faceLoading: true,
      });
    }

    let bodyRequest = {
      ...meResponse,
      facebook_id: meResponse.id || authResponse.userID,
      grant_type: "facebook_credentials",
      client_id: clientId,
      client_secret: clientSecret,
      token_device: "",
      lang: "en",
      operative_system: "web",
      modified_email: this.state.customEmailModal.open,
    };

    if (this.props.deepLinkInfo.invitation_code) {
      bodyRequest.invitation_code = this.props.deepLinkInfo.invitation_code;
    }

    sagaMiddleware.run<any>(
      authUserFacebook,
      bodyRequest,
      (response: any, error: any) => {
        this.setState({
          faceLoading: false,
          customEmailModal: {
            open: false,
            email: "",
            first_name: "",
            last_name: "",
            valid: false,
            authResponse: {},
            meResponse: {},
          },
        });

        if (error) {
          this._openNotificationWithIcon("error", "Error", error);
          return;
        }

        if (!!this.props.deepLinkInfo.invitation_code) {
          //Send guest directly to host's dashboard
          this.loginAsGuestUser(response.main_user.id, response.id);

          this.props.clearDeepLinkInfo();

          Modal.info({
            title: "Hi there!",
            content: `You're seeing the ${startCase(
              response.first_name
            )} ${startCase(
              response.last_name
            )}'s account as a guest user. Go to menu and select switch account to see your own content.`,
          });

          return;
        }

        this.finishAuth(response);
      }
    );
  };

  afterResponse = (authResponse: any) => {
    try {
      FB.api(
        "/me",
        { fields: "id,name,first_name,last_name,email" },
        (meResponse: any) => {
          if (
            !meResponse.first_name ||
            !meResponse.first_name.length ||
            !meResponse.last_name ||
            !meResponse.last_name.length ||
            !meResponse.email ||
            !meResponse.email.length
          ) {
            sagaMiddleware.run<any>(
              userFacebookExists,
              {
                facebook_id: meResponse.id || authResponse.userID,
                grant_type: "facebook_credentials",
                client_id: clientId,
                client_secret: clientSecret,
                token_device: "",
                lang: "en",
                operative_system: "web",
              },
              (existData: any, error: any) => {
                if (error) {
                  this.setState({
                    faceLoading: false,
                    customEmailModal: {
                      open: true,
                      email: "",
                      first_name: meResponse.first_name || "",
                      last_name: meResponse.last_name || "",
                      valid: false,
                      authResponse: authResponse,
                      meResponse: { ...meResponse },
                    },
                  });

                  return;
                }

                this.finishAuth(existData);
              }
            );
            return;
          }

          this.finishFacebookAuth(authResponse, meResponse);
        }
      );
    } catch (error) {
      this.setState({ faceLoading: false });
      this._openNotificationWithIcon(
        "error",
        "Error",
        "Sorry, Facebook login is not available now, try again later"
      );
    }
  };

  // Open Facebook login
  signInWithFacebook = () => {
    if (!!FB) {
      this.setState({
        faceLoading: true,
      });

      try {
        FB.getLoginStatus((response: any) => {
          if (response.status === "connected") {
            this.afterResponse({ ...response.authResponse });
          } else {
            FB.login(
              (response: any) => {
                if (response.status === "connected") {
                  this.afterResponse({ ...response.authResponse });
                } else {
                  this.setState({ faceLoading: false });
                  if (response.status === "not_authorized") {
                    this._openNotificationWithIcon(
                      "error",
                      "Error",
                      "When you sign in with Facebook please follow all steps and give permission for BabyPage App"
                    );
                  }
                }
              },
              {
                scope: "public_profile,email,user_photos",
                auth_type: "reauthenticate",
              }
            );
          }
        }, true);
      } catch (error) {
        this.setState({ faceLoading: false });
        this._openNotificationWithIcon(
          "error",
          "Error",
          "Sorry, Facebook login is not available now, try again later"
        );
      }
    } else {
      this._openNotificationWithIcon(
        "error",
        "Error",
        "Sorry, Facebook login is not available now, try again later"
      );
    }
  };

  appleResponse = (appleAuthResponse: any) => {
    try {
      let appleObject = {};

      if (!!appleAuthResponse.user) {
        // Sign Up
        if (
          !!appleAuthResponse.user.email &&
          !!appleAuthResponse.user.name.firstName &&
          !!appleAuthResponse.user.name.lastName
        ) {
          appleObject = {
            grant_type: "apple_id",
            client_id: clientId,
            client_secret: clientSecret,
            lang: "en",
            token_device: "",
            operative_system: "web",
            apple_token: appleAuthResponse.authorization.id_token,
            apple_id: appleAuthResponse.user.email,
            first_name: appleAuthResponse.user.name.firstName,
            last_name: appleAuthResponse.user.name.lastName,
          };
        }
      } else {
        // Sign In
        appleObject = {
          grant_type: "apple_id",
          client_id: clientId,
          client_secret: clientSecret,
          lang: "en",
          token_device: "",
          operative_system: "web",
          apple_token: appleAuthResponse.authorization.id_token,
        };
      }

      sagaMiddleware.run<any>(
        authUserApple,
        {
          ...appleObject,
        },
        (existData: any, error: any) => {
          if (error) {
            this.setState({ faceLoading: false });
            this._openNotificationWithIcon(
              "error",
              "Error",
              "Sorry, Apple login is not available now, try again later"
            );
            return;
          }

          this.finishAuth(existData);
          this.setState({ faceLoading: false });
        }
      );

      return;
    } catch (error) {
      this.setState({ faceLoading: false });
      this._openNotificationWithIcon(
        "error",
        "Error",
        "Sorry, Apple login is not available now, try again later"
      );
    }
  };

  signInWithApple = async () => {
    if (!!AppleID) {
      this.setState({
        faceLoading: true,
      });

      try {
        const response = await AppleID.auth.signIn();

        if (!!response.authorization) {
          this.appleResponse(response);
        } else {
          this.setState({ faceLoading: false });
          this._openNotificationWithIcon(
            "error",
            "Error",
            "Sorry, Apple login is not available now, try again later"
          );
        }
      } catch (error) {
        if (error == "popup_closed_by_user") {
          this.setState({ faceLoading: false });
          this._openNotificationWithIcon(
            "error",
            "Error",
            "When you sign in with Apple please follow all steps and give permission for BabyPage App"
          );
        } else {
          this.setState({ faceLoading: false });
          this._openNotificationWithIcon(
            "error",
            "Error",
            "Sorry, Apple login is not available now, try again later"
          );
        }
      }
    } else {
      this.setState({ faceLoading: false });
      this._openNotificationWithIcon(
        "error",
        "Error",
        "Sorry, Apple login is not available now, try again later"
      );
    }
  };

  private loginAsGuestUser(mainUserId: number, hostUserId: number) {
    sagaMiddleware.run<any>(
      switchAccount,
      {
        mainUserId,
        hostUserId,
      },
      (error: string | null) => {
        if (error) {
          Modal.error({
            title: "Error",
            content: error,
          });
          return;
        }
        sagaMiddleware.run<any>(rootSaga, true);
        this.props.history.push("/");
        sagaMiddleware.run<any>(fetchDashboard, (error: string | null) => {
          if (error !== null) {
            Modal.error({
              title: "Error",
              content: error,
            });
          }
        });
      }
    );
  }

  render() {
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } =
      this.props.form;

    const firstNameError =
      isFieldTouched("firstName") && getFieldError("firstName");
    const lastNameError =
      isFieldTouched("lastName") && getFieldError("lastName");
    const cfEmailError = isFieldTouched("cfEmail") && getFieldError("cfEmail");
    const emailError = isFieldTouched("email") && getFieldError("email");
    const emailErrorSignup =
      isFieldTouched("signupEmail") && getFieldError("signupEmail");
    const passwordError =
      isFieldTouched("password") && getFieldError("password");
    const invitationCodeError =
      isFieldTouched("invitationCode") && getFieldError("invitationCode");

    const modalErrorClassName = !!(this.state.forgetPasswordModal.open
      ? !!this.state.forgetPasswordModal.email.length &&
        !this.state.forgetPasswordModal.valid
      : !this.state.customEmailModal.valid)
      ? "has-error"
      : "";

    return (
      <>
        <Form className="enter-form" onSubmit={this.onSubmit}>
          {this.props.history.location.search === "?signin" ? (
            <>
              <div className="row my-3">
                <div className="col-12">
                  <p
                    className="test-bold"
                    style={{ textTransform: "uppercase" }}
                  >
                    Login
                  </p>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-12">
                  <Form.Item
                    validateStatus={emailError ? "error" : ""}
                    help={emailError || ""}
                  >
                    {getFieldDecorator("email", {
                      rules: [
                        {
                          required: true,
                          message: "This field is required.",
                        },
                        {
                          type: "email",
                          message: "The email provided is not an email.",
                        },
                      ],
                      initialValue:
                        this.props.deepLinkInfo.additional_data.email,
                    })(
                      <Input
                        size="large"
                        type="email"
                        maxLength={60}
                        placeholder="Email"
                      />
                    )}
                  </Form.Item>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-12">
                  <Form.Item
                    validateStatus={passwordError ? "error" : ""}
                    help={passwordError || ""}
                  >
                    {getFieldDecorator("password", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter at least 6 characters.",
                        },
                        {
                          min: 6,
                          message: "Please enter at least 6 characters.",
                        },
                      ],
                    })(
                      <Input
                        size="large"
                        maxLength={100}
                        placeholder="Password"
                        type={this.state.passwordHidden ? "password" : "text"}
                        suffix={
                          <img
                            src={
                              this.state.passwordHidden ? eyeClosed : eyeOpen
                            }
                            className="clickable"
                            onClick={this.hidePassword}
                          />
                        }
                      />
                    )}
                  </Form.Item>
                </div>
              </div>
              <div className="row justify-content-center my-auto">
                <div className="col-12 col-md-8 test-margin-bottom">
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      type="primary"
                      // icon={this.state.loading ? 'loading' : 'login'}
                      size="default"
                      disabled={
                        hasErrors(getFieldsError()) || this.state.loading
                      }
                      className="w-100 signin-button-color"
                      shape="round"
                    >
                      <span className="test-regular test-button">
                        Login to Babypage
                      </span>
                    </Button>
                  </Form.Item>
                </div>
              </div>
              <div className="row justify-content-center my-1">
                <div className="col-auto">
                  {this.state.signin && (
                    <Button
                      htmlType="button"
                      type="link"
                      size="default"
                      className="w-100 btn-link-fix text-center"
                      onClick={this.forgetPasswordToggle}
                    >
                      <span className="test-regular">
                        Forgot your password?
                      </span>
                    </Button>
                  )}
                </div>
              </div>
              <div className="row justify-content-center my-auto">
                <div className="col-auto px-0 align-self-center">
                  <span className="text-center test-regular">
                    {this.state.signin
                      ? "I'd like to create an account"
                      : "Already have an account?"}
                  </span>
                </div>
                <div className="col-auto px-1 align-self-center">
                  <Button
                    type="link"
                    size="default"
                    className="w-100 btn-link-fix"
                    onClick={() => {
                      this.setState({ signin: !this.state.signin });
                      this.props.history.push("/signin?signup");
                      setTimeout(() => {
                        this.props.form.validateFields();
                      }, 50);
                    }}
                  >
                    <span className="test-regular">
                      {this.state.signin ? "Sign Up" : "Sign in"}
                    </span>
                  </Button>
                </div>
              </div>
              <div className="row justify-content-center mt-4">
                <div className="col-12 align-self-center ">
                  <p className="test-semiBold">Or Continue With</p>
                </div>
                <div className="col-6">
                  <FacebookButton
                    signInWithFacebook={this.signInWithFacebook}
                    loading={this.state.faceLoading}
                    cssClass="mb-2"
                  />
                </div>
                <div className="col-6">
                  <AppleButton
                    signInWithApple={this.signInWithApple}
                    loading={this.state.faceLoading}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row my-3">
                <div className="col-12">
                  <p
                    className="test-bold"
                    style={{ textTransform: "uppercase" }}
                  >
                    Create Account
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <Form.Item
                    validateStatus={firstNameError ? "error" : ""}
                    help={firstNameError || ""}
                  >
                    {getFieldDecorator("firstName", {
                      rules: [
                        {
                          required: true,
                          message: "This field is required.",
                        },
                      ],
                      initialValue:
                        this.props.deepLinkInfo.additional_data.full_name.split(
                          " "
                        )[0],
                    })(<Input size="large" placeholder="First name" />)}
                  </Form.Item>
                </div>
                <div className="col-6">
                  <Form.Item
                    validateStatus={lastNameError ? "error" : ""}
                    help={lastNameError || ""}
                  >
                    {getFieldDecorator("lastName", {
                      rules: [
                        {
                          required: true,
                          message: "This field is required.",
                        },
                      ],
                      initialValue:
                        this.props.deepLinkInfo.additional_data.full_name.split(
                          " "
                        )[1],
                    })(<Input size="large" placeholder="Last name" />)}
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Form.Item
                    validateStatus={emailErrorSignup ? "error" : ""}
                    help={emailErrorSignup || ""}
                  >
                    {getFieldDecorator("signupEmail", {
                      rules: [
                        {
                          required: true,
                          message: "This field is required.",
                        },
                        {
                          type: "email",
                          message: "The email provided is not an email.",
                        },
                      ],
                      initialValue:
                        this.props.deepLinkInfo.additional_data.email,
                    })(<Input size="large" type="email" placeholder="Email" />)}
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Form.Item
                    validateStatus={cfEmailError ? "error" : ""}
                    help={cfEmailError || ""}
                  >
                    {getFieldDecorator("cfEmail", {
                      rules: [
                        {
                          required: true,
                          message: "This field is required.",
                        },
                        {
                          type: "email",
                          message: "The email provided is not an email.",
                        },
                        {
                          validator: this.compareToFirstEmail,
                        },
                      ],
                      initialValue:
                        this.props.deepLinkInfo.additional_data.email,
                    })(
                      <Input
                        size="large"
                        type="email"
                        placeholder="Confirm Email"
                      />
                    )}
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Form.Item
                    validateStatus={passwordError ? "error" : ""}
                    help={passwordError || ""}
                  >
                    {getFieldDecorator("password", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter at least 6 characters.",
                        },
                        {
                          min: 6,
                          message: "Please enter at least 6 characters.",
                        },
                      ],
                    })(
                      <Input
                        size="large"
                        placeholder="Password"
                        type={this.state.passwordHidden ? "password" : "text"}
                        suffix={
                          <img
                            src={
                              this.state.passwordHidden ? eyeClosed : eyeOpen
                            }
                            className="clickable"
                            onClick={this.hidePassword}
                          />
                        }
                      />
                    )}
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Form.Item
                    validateStatus={invitationCodeError ? "error" : ""}
                    help={invitationCodeError || ""}
                  >
                    {getFieldDecorator("invitationCode", {
                      rules: [
                        { min: 8, message: "Please enter 8 characters." },
                        {
                          pattern: /^[0-9]+$/,
                          message: " Please enter only numbers.",
                        },
                      ],
                      initialValue: this.props.deepLinkInfo.invitation_code,
                    })(
                      <Input
                        size="large"
                        maxLength={8}
                        placeholder="Invitation Code"
                        suffix={
                          <AiFillQuestionCircle
                            size={"1.1rem"}
                            style={{ color: "#658071", cursor: "pointer" }}
                            onClick={this.invitationCodeInfoToggle}
                          />
                        }
                      />
                    )}
                  </Form.Item>
                </div>
                {/* <div className="col-1 pl-0 helpIconWrapper">
                  <AiFillQuestionCircle
                    size={"1.1rem"}
                    style={{ color: "#658071" }}
                    onClick={this.invitationCodeInfoToggle}
                  />
                </div> */}
              </div>
              <div className="row justify-content-center my-0">
                <div className="col-12 col-md-8 test-margin-bottom">
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      type="primary"
                      // icon={this.state.loading ? 'loading' : 'arrow-right'}
                      size="default"
                      disabled={
                        hasErrors(getFieldsError()) || this.state.loading
                      }
                      className="w-100 mb-2 signin-button-color"
                      shape="round"
                    >
                      <span className="test-regular test-button">
                        Create Account{" "}
                      </span>
                    </Button>
                  </Form.Item>
                </div>
              </div>
              <div className="row justify-content-center my-0">
                <div className="col-auto px-0 align-self-center">
                  <span className="text-center test-regular">
                    {this.state.signin
                      ? "I'd like to create an account"
                      : "Already have an account?"}
                  </span>
                </div>
                <div className="col-auto px-1">
                  <Button
                    type="link"
                    size="default"
                    className="w-100 btn-link-fix"
                    onClick={() => {
                      this.setState({ signin: !this.state.signin });
                      this.props.history.push("/signin?signin");
                      setTimeout(() => {
                        this.props.form.validateFields();
                      }, 50);
                    }}
                  >
                    <span className="test-regular">
                      {this.state.signin ? "Sign Up" : "Sign in"}
                    </span>
                  </Button>
                </div>
              </div>
              <div className="row mt-4 mb-2 justify-content-center">
                <div className="col-12">
                  <p className="test-semiBold">Or Continue with</p>
                </div>
                <div className="col-6">
                  <FacebookButton
                    signInWithFacebook={this.signInWithFacebook}
                    loading={this.state.faceLoading}
                    cssClass="mb-2"
                  />
                </div>
                <div className="col-6">
                  <AppleButton
                    signInWithApple={this.signInWithApple}
                    loading={this.state.faceLoading}
                  />
                </div>
              </div>
            </>
          )}
        </Form>

        <Modal
          centered
          visible={this.state.invitationCodeInfoModal.open}
          onCancel={this.invitationCodeInfoToggle}
          closable={!this.state.loading}
          className="popup-info"
          okButtonProps={{ style: { display: "none" } }}
          cancelText="OK"
          cancelButtonProps={{
            htmlType: "button",
            className: "ant-btn-primary",
            style: { width: "100px" },
          }}
        >
          <div>
            <div className="img-wrapper">
              <img src={logoImg} alt="Plus Child" className="mx-auto d-table" />
            </div>

            <p className="text-center m-0 mt-5">
              If you received an invitation to access a BabyPage account, please
              enter the code that was provided to you via email.
            </p>
          </div>
        </Modal>

        <Modal
          title="Reset Password"
          visible={this.state.forgetPasswordModal.open}
          onCancel={this.forgetPasswordToggle}
          closable={!this.state.loading}
          okType="primary"
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{
            disabled:
              !this.state.forgetPasswordModal.valid || this.state.loading,
            htmlType: "button",
            shape: "round",
            className: "py-3 ant-btn-primary mb-2 new-standard-btn",
          }}
          onOk={this.forgetPassword}
          okText="Reset Password"
        >
          <p>
            Reset your password to continue capturing your children's
            milestones.
          </p>
          <div className={modalErrorClassName}>
            <label>Email Address</label>
            <Input
              size="large"
              type="email"
              onChange={(e) => {
                const email = e.target.value;
                this.setState({
                  forgetPasswordModal: {
                    ...this.state.forgetPasswordModal,
                    email,
                    valid: isEmail(email),
                  },
                });
              }}
              onPressEnter={this.forgetPassword}
              value={this.state.forgetPasswordModal.email}
              className={modalErrorClassName}
            />
            {this.state.forgetPasswordModal.email.length &&
            !this.state.forgetPasswordModal.valid ? (
              <div className="ant-form-explain">
                The email provided is not an email.
              </div>
            ) : null}
          </div>
        </Modal>

        <Modal
          title="Complete registration"
          visible={this.state.customEmailModal.open}
          onCancel={() =>
            this.setState({
              customEmailModal: {
                open: false,
                email: "",
                first_name: "",
                last_name: "",
                valid: false,
                authResponse: {},
                meResponse: {},
              },
            })
          }
          cancelButtonProps={{
            disabled: this.state.faceLoading,
            icon: "close-circle",
            shape: "round",
            className: "default-btn",
          }}
          closable={!this.state.faceLoading}
          okType="primary"
          okButtonProps={{
            disabled:
              !this.state.customEmailModal.valid || this.state.faceLoading,
            icon: this.state.faceLoading ? "faceLoading" : "check-circle",
            htmlType: "button",
            shape: "round",
            className: "default-btn",
          }}
          onOk={() =>
            this.finishFacebookAuth(
              this.state.customEmailModal.authResponse,
              this.state.customEmailModal.meResponse
            )
          }
        >
          <label>First Name</label>
          <Input
            size="large"
            type="text"
            onChange={(e) => {
              const first_name = e.target.value;
              this.setState({
                customEmailModal: {
                  ...this.state.customEmailModal,
                  first_name,
                  valid:
                    !!first_name &&
                    !!this.state.customEmailModal.last_name &&
                    isEmail(this.state.customEmailModal.email),
                },
              });
            }}
            onPressEnter={() => {
              if (
                this.state.customEmailModal.valid &&
                !this.state.faceLoading
              ) {
                this.finishFacebookAuth(
                  this.state.customEmailModal.authResponse,
                  this.state.customEmailModal.meResponse
                );
              }
            }}
            value={this.state.customEmailModal.first_name}
            className="my-1"
          />
          <label>Last Name</label>
          <Input
            size="large"
            type="text"
            onChange={(e) => {
              const last_name = e.target.value;
              this.setState({
                customEmailModal: {
                  ...this.state.customEmailModal,
                  last_name,
                  valid:
                    !!last_name &&
                    !!this.state.customEmailModal.first_name &&
                    isEmail(this.state.customEmailModal.email),
                },
              });
            }}
            onPressEnter={() => {
              if (
                this.state.customEmailModal.valid &&
                !this.state.faceLoading
              ) {
                this.finishFacebookAuth(
                  this.state.customEmailModal.authResponse,
                  this.state.customEmailModal.meResponse
                );
              }
            }}
            value={this.state.customEmailModal.last_name}
            className="my-1"
          />
          <label>Email Address</label>
          <Input
            size="large"
            type="email"
            onChange={(e) => {
              const email = e.target.value;
              this.setState({
                customEmailModal: {
                  ...this.state.customEmailModal,
                  email,
                  valid:
                    !!this.state.customEmailModal.first_name &&
                    !!this.state.customEmailModal.last_name &&
                    isEmail(email),
                },
              });
            }}
            onPressEnter={() => {
              if (
                this.state.customEmailModal.valid &&
                !this.state.faceLoading
              ) {
                this.finishFacebookAuth(
                  this.state.customEmailModal.authResponse,
                  this.state.customEmailModal.meResponse
                );
              }
            }}
            value={this.state.customEmailModal.email}
            className="my-1"
          />
          <div className={modalErrorClassName}>
            {!this.state.customEmailModal.valid ? (
              <p className="ant-form-explain text-center">
                Please fill out all your information
              </p>
            ) : null}
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  access_token: state.user.access_token,
  deepLinkInfo: state.guestUsers.deepLinkInfo,
});

const mapDispatchToProps = (dispatch: Dispatch<BaseAction>) => ({
  clearDeepLinkInfo: () => dispatch(clearDeepLinkInfo()),
});

export default Form.create()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(SignInForm))
);
