import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { Provider, connect } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import AppRoutes from "./App.routes";
import appPopup from "./assets/img/ios-app-popup.jpg";
import store, { persistor, sagaMiddleware, AppState } from "./store";
import rootSaga from "./store/sagas";
import { isIphone } from "./utils/accessibility";
import { getMaintenance } from "./store/sagas/maintenance";

interface Props {
  user_level?: number;
}

const App: React.FC<Props> = ({
  user_level,
}) => {
  const [modal, setModal] = useState(false);
  const isPlus = !!user_level && user_level === 1;

  useEffect(() => {
    sagaMiddleware.run<any>(getMaintenance);
  }, [])

  useEffect(() => {
    setTimeout(() => {
      // Run Global functions (Fetch babies, ...)
      // Removed this root saga called to prevent duplicate request with plus users
      // sagaMiddleware.run<any>(rootSaga);
    }, 500);

    if (!!isPlus && isIphone() && !localStorage.getItem("closediPhoneAlert")) {
      setModal(true);
    }
  }, [isPlus]);

  return (
    <>
      <PersistGate persistor={persistor} loading={null}>
        <AppRoutes />
      </PersistGate>
      <Modal
        centered
        visible={modal}
        onCancel={() => setModal(false)}
        footer={null}
        width="auto"
        bodyStyle={{
          padding: 0
        }}
      >
        <div className="d-flex justify-content-center align-items-center">
          <img
            src={appPopup}
            alt="App Popup"
            className="clickable"
            style={{
              width: 300,
              height: 300
            }}
            onClick={() => {
              const winOpened = window.open(
                "https://apps.apple.com/us/app/babypage/id1362796822?utm_campaign=5d98fd57b8-EMAIL_CAMPAIGN_2018_05_10_COPY_01&utm_medium=email&utm_source=Production%20User%20List&utm_term=0_dc5a4498e0-5d98fd57b8-424824993",
                "_blank"
              );
              try {
                winOpened!.focus();
              } catch (err) {}
            }}
          />
          <Button
            size="small"
            shape="round"
            type="primary"
            onClick={() => {
              localStorage.setItem("closediPhoneAlert", "true");
              setModal(false);
            }}
            style={{
              position: "absolute",
              bottom: 10,
              left: 10,
              fontSize: 12
            }}
          >
            Please don't show me this again
          </Button>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  user_level: state.user.user_level,
});

export default connect(mapStateToProps)(App);