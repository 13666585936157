import cloneDeep from "lodash/cloneDeep";
import { call, put, select } from "redux-saga/effects";
import { AppState } from "..";
import api from "../../api";
import { BookListInterface } from "../../interfaces";
import { User } from "../../interfaces/user";
import { isArrayEqual } from "../../utils/array";
import { parseRequestError } from "../../utils/error";
import { trackAction } from "../../utils/marketing";
import {
  getBooks,
  getCovers,
  setBookType as setBookTypeDuck,
} from "../ducks/books";
import { BookState } from "./../ducks/books";
import { sagaMiddleware } from "./../index";

export function* fetchBooks(callback?: (error: string | null) => void) {
  const user: User = yield select((state: AppState) => state.user);

  if (!user.access_token || user.user_level === 3) return;

  try {
    const response = yield call(api.get, "api/books", {
      headers: {
        Authorization: user.access_token,
      },
    });

    if (response.data && response.data.code === 200) {
      const data = response.data.data;
      const books: BookState = yield select((state: AppState) => state.books);

      if (!isArrayEqual(books.books, data)) {
        yield put(getBooks(data));
      }
    }

    if (callback) callback(null);
  } catch (error) {
    if (callback) callback(parseRequestError(error));
  }
}

export function* duplicateBook(
  id: number,
  callback: (error: string | null) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    const response = yield call(api.get, `api/duplicate-book/${id}`, {
      headers: {
        Authorization: accessToken,
      },
    });

    if (response.data && response.data.code === 200) {
      const bookState: BookState = yield select(
        (state: AppState) => state.books
      );

      const bookToDuplicate = bookState.books.findIndex(
        (book) => book.id === id
      );

      if (bookToDuplicate >= 0) {
        const newBook = cloneDeep(bookState.books[bookToDuplicate]);
        newBook.id = response.data.data.id;

        bookState.books = [newBook, ...bookState.books];

        yield put(getBooks(bookState.books));
      }

      callback(null);
    } else {
      throw new Error("The selected id is invalid.");
    }
  } catch (error) {
    callback(parseRequestError(error));
  }
}

export function* deleteBook(
  id: number,
  callback: (error: string | null) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    const response = yield call(api.delete, `api/books/${id}?lang=en`, {
      headers: {
        Authorization: accessToken,
      },
    });

    if (response.data && response.data.code === 200) {
      const bookState: BookState = yield select(
        (state: AppState) => state.books
      );

      const bookToDelete = bookState.books.findIndex((book) => book.id === id);

      if (bookToDelete >= 0) {
        const updatedBooks = bookState.books.filter((book) => {
          return book.id !== id;
        });

        bookState.books = updatedBooks;

        yield put(getBooks(bookState.books));
      }

      callback(null);
    } else {
      throw new Error("The selected id is invalid.");
    }
  } catch (error) {
    callback(parseRequestError(error));
  }
}

export function* fetchCovers(callback?: (error: string | null) => void) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    const response = yield call(api.get, "api/covers?lang=en", {
      headers: {
        Authorization: accessToken,
      },
    });

    if (response.data && response.data.code === 200) {
      const data = response.data.data;
      const books: BookState = yield select((state: AppState) => state.books);

      if (!isArrayEqual(books.covers, data)) {
        yield put(getCovers(data));
      }
    }

    if (callback) callback(null);
  } catch (error) {
    if (callback) callback(parseRequestError(error));
  }
}

// Add or Update a BabyPage
export function* saveBookSaga(
  book: any,
  callback: (error?: any, id?: number) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  const method = !!book.id ? "put" : "post";
  const url = !!book.id ? `books/${book.id}` : "books";
  book.id = undefined; // Remove ID from post information

  try {
    const response = yield call(api[method], `api/${url}`, book, {
      headers: {
        Authorization: accessToken,
      },
    });

    if (response.data && response.data.code === 200) {
      if(method === "post") {
        trackAction(
          "SaveBook",
          {
            google: {
              event: "create_book",
            },
          },
          true
        );
      } 
      sagaMiddleware.run<any>(fetchBooks);
      callback(null, response.data.data);
    } else {
      throw new Error("Error saving Book");
    }
  } catch (error) {
    callback(parseRequestError(error));
  }
}

export function* fetchBookById(
  id: number,
  callback: (error: string | null, Book?: any) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    const response = yield call(api.get, `api/books/${id}`, {
      headers: {
        Authorization: accessToken,
      },
    });

    if (response.data && response.data.code === 200) {
      callback(null, response.data.data);
    } else {
      throw new Error("The selected id is invalid.");
    }
  } catch (error) {
    callback(parseRequestError(error));
  }
}

export function* inspectAllBabypagesImages(
  id: number,
  callback: (error: string | null, Book?: any) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    const response = yield call(api.get, `api/missing-images/${id}`, {
      headers: {
        Authorization: accessToken,
      },
    });

    if (response.data && response.data.code === 200) {
      callback(null, response.data.data);
    } else {
      throw new Error("The selected id is invalid.");
    }
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * Get books for admin panel
 */
export function* getBooksAdmin(
  search: string,
  callback: (err?: string, response?: BookListInterface) => void,
  page?: number
) {
  const accessToken = yield select(
    (state: AppState) => state.admin.access_token
  );

  try {
    if (!!search) {
      search = `search=${search}`;
    }

    if (!!page) {
      search += `${!!search ? "&" : ""}page=${page}`;
    }

    const response = yield call(
      api.get,
      `api/admin/books?&lang=en&limit=10&${search}`,
      {
        headers: {
          Authorization: accessToken,
        },
      }
    );

    callback(undefined, response.data.data);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * Get books by ID for admin panel
 */
export function* getBookById(
  id: string,
  callback: (err?: string, response?: any) => void,
  admin: boolean = true
) {
  const accessToken = yield select((state: AppState) =>
    !!admin ? state.admin.access_token : state.user.access_token
  );

  try {
    const response = yield call(api.get, `api/admin/books/${id}?&lang=en`, {
      headers: {
        Authorization: accessToken,
      },
    });

    callback(undefined, response.data.data);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * Get book types from Stripe
 */
export function* getStripeBookTypes(
  callback: (err?: string, response?: { a: number }) => void
) {
  const user: User = yield select((state: AppState) => state.user);

  if (!user.access_token) return;

  try {
    const response = yield call(api.get, "api/stripe/books?&lang=en", {
      headers: {
        Authorization: user.access_token,
      },
    });

    callback(undefined, response.data.data);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * Set Book Type
 */
export function* setBookType(
  stripeId: string,
  callback: (err?: string) => void
) {
  try {
    yield put(setBookTypeDuck(stripeId));

    callback(undefined);
  } catch (error) {
    callback(parseRequestError(error));
  }
}
