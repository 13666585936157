import { Button, Card, Icon, Modal, notification, Spin } from "antd";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import logo from "../../assets/img/logo_2.png";
import BaseLayout from "../../components/BaseLayout/BaseLayout";
import FloatButton from "../../components/FloatButton/FloatButton";
import PhotoModal from "../../components/PhotoModal/PhotoModal";
import ShareModal from "../../components/ShareModal/ShareModal";
import { Baby } from "../../interfaces";
import { AppState, sagaMiddleware } from "../../store";
import { GrowthChartState } from "../../store/ducks/growthChart";
import { deleteGrowthChart, fetchGrowthCharts } from "../../store/sagas/growthChart";
import { capitalizeTexts } from "../../utils/string";
import "./GrowthChart.scss";
const { confirm } = Modal;

interface Props {
  babySelected?: Baby;
  growthChart: GrowthChartState;
}

const GrowthChartPage: React.FC<Props> = ({ babySelected, growthChart }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<string>("");
  const [share, setShare] = useState<string>("");

  const babyGrowth = useMemo(() => {
    if (!!babySelected) {
      const babyGrowth = growthChart.find(g => g.id === babySelected.id);

      if (!!babyGrowth) {
        return {
          ...babyGrowth,
          babyGrowthCharts: babyGrowth.babyGrowthCharts.map(chart => ({
            ...chart,
            from_date: moment(chart.from_date.substr(0, 10)).format(
              "MM[/]DD[/]YYYY"
            ),
            to_date: moment(chart.to_date.substr(0, 10)).format(
              "MM[/]DD[/]YYYY"
            )
          }))
        };
      }
    }

    return undefined;
  }, [babySelected, growthChart]);

  return !babySelected ? null : (
    <BaseLayout
      title="BabyPage - Growth Chart"
      pageTitle="Growth Chart"
    >
      {loading && (
        <div className="mx-auto d-table my-3">
          <Spin
            indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
          />
        </div>
      )}
      {!!babyGrowth && !!babyGrowth.babyGrowthCharts.length ? (
        <div className="my-3 container">
          <div className="my-5 mobile-only"></div>
          <div className="row justify-content-center align-items-center">
            {babyGrowth.babyGrowthCharts.map((g, index) => (
              <div key={index} className="col-12 col-md-6 col-lg-4 my-2">
                <Card className="w-100 elevation-5">
                  <div className="d-flex">
                    <img
                      src={logo}
                      alt="Logo"
                      style={{ height: 75, width: 75 }}
                    />
                    <div className="pl-3">
                      <h3 className="my-1">
                        <strong>{g.title}</strong>
                      </h3>
                      <h4 className="my-1">From: {g.from_date}</h4>
                      <h4 className="my-1">To: {g.to_date}</h4>
                    </div>
                  </div>
                  <img src={g.thumbnail} alt="Growth Chart" className="w-100" />
                  <Button.Group className="d-table mx-auto">
                    <Button
                      size="large"
                      icon="edit"
                      disabled={loading}
                      onClick={() =>
                        history.push(`/growth-chart/save?id=${g.id}`)
                      }
                    />
                    <Button
                      size="large"
                      icon="search"
                      disabled={loading}
                      onClick={() => setImage(g.thumbnail)}
                    />
                    <Button
                      size="large"
                      icon="share-alt"
                      disabled={loading}
                      onClick={() => {
                        setShare(g.thumbnail);
                      }}
                    />
                    <Button
                      size="large"
                      icon="delete"
                      disabled={loading}
                      onClick={() =>
                        confirm({
                          title:
                            "Are you sure you want to delete this Growth Chart?",
                          okText: "Yes",
                          okType: "danger",
                          cancelText: "No",
                          icon: "warning",
                          onOk() {
                            setLoading(true);
                            sagaMiddleware.run<any>(
                              deleteGrowthChart,
                              g.id,
                              (error: string | null) => {
                                setLoading(false);

                                if (!!error) {
                                  Modal.error({
                                    title: "Error",
                                    content: error
                                  });
                                } else {
                                  notification.success({
                                    message: "Success",
                                    description:
                                      "Growth Chart removed successfully"
                                  });
                                }
                              }
                            );
                          }
                        })
                      }
                    />
                  </Button.Group>
                </Card>
              </div>
            ))}
          </div>
          <FloatButton
            type="primary"
            shape="circle"
            icon="plus"
            position="bottom-right"
            size="large"
            onClick={() => {
              history.push("/growth-chart/save");
            }}
          />
        </div>
      ) : (
        <div className="growth-full d-flex justify-content-center align-items-center flex-column">
          <h3 className="text-center m-0">
            {babySelected.baby_first_name} does not have any growth chart entries.
          </h3>
          <Link to="/growth-chart/save">Create a new chart</Link>
        </div>
      )}
      <FloatButton
        type="primary"
        shape="circle"
        icon="reload"
        position="top-right-banner"
        unFixed
        onClick={() => {
          setLoading(true);

          sagaMiddleware.run<any>(fetchGrowthCharts, (err: string | null) => {
            setLoading(false);

            if (err) {
              Modal.error({
                title: "Error",
                content: err
              });
            }
          });
        }}
      />
      <PhotoModal
        visible={image !== ""}
        onClose={() => setImage("")}
        image={image}
        width="90%"
      />
      <ShareModal
        visible={share !== ""}
        onClose={() => setShare("")}
        image={share}
      />
    </BaseLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  babySelected: state.baby.babySelected,
  growthChart: state.growthChart
});

export default connect(mapStateToProps)(GrowthChartPage);
