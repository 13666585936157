import { call, put, select } from 'redux-saga/effects';
import { AppState } from '..';
import api from '../../api';
import { User } from '../../interfaces/user';
import { parseRequestError } from '../../utils/error';
import { getStripeProducts } from '../ducks/stripeProduct';

export function* fetchStripeProducts(callback?: (error?: string) => void) {
  const accessToken: User = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    const response = yield call(api.get, 'api/stripe/all-products/100', {
      headers: {
        Authorization: accessToken,
      },
    });

    if (
      response.data &&
      response.data.code === 200 &&
      !!response.data.data &&
      !!response.data.data.data
    ) {
      yield put(getStripeProducts(response.data.data.data));
    }

    if (callback) callback(undefined);
  } catch (error) {
    if (callback) callback(parseRequestError(error));
  }
}
