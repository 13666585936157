import { call, select } from 'redux-saga/effects';
import { AppState } from '..';
import api from '../../api';
import { GiftCardInterface } from '../../interfaces/giftCard';
import { parseRequestError } from '../../utils/error';

export function* getGiftCards(
  filter: {
    startDate?: string;
    endDate?: string;
    user?: string;
  },
  callback: (err?: string, response?: GiftCardInterface) => void,
  page?: number
) {
  const accessToken = yield select(
    (state: AppState) => state.admin.access_token
  );

  try {
    let search = '';

    if (!!filter.startDate) {
      search += `start_date=${filter.startDate}`;
    }

    if (!!filter.endDate) {
      search += `${!!search ? '&' : ''}end_date=${filter.endDate}`;
    }

    if (!!filter.user) {
      search += `${!!search ? '&' : ''}user=${filter.user}`;
    }

    if (!!page) {
      search += `${!!search ? '&' : ''}page=${page}`;
    }

    const response = yield call(api.get, `api/admin/gift-cards?${search}`, {
      headers: {
        Authorization: accessToken
      }
    });

    callback(undefined, response.data.data);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

export function* resendGiftCard(
  body: {
    email: string;
    giftcard_id: number;
  },
  callback: (err?: string) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.admin.access_token
  );

  try {
    yield call(api.post, 'api/admin/gift-cards', body, {
      headers: {
        Authorization: accessToken
      }
    });

    callback();
  } catch (error) {
    callback(parseRequestError(error));
  }
}
