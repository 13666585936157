import React, {
    useEffect,
    useState,
  } from "react";
import { Modal, Spin, Icon } from "antd";
import { CartItem, User } from "../../interfaces";
import BookModal from "../../components/BookModal/BookModal";
import { trackAction } from "../../utils/marketing";
import { AppState } from "../../store";
import { connect } from "react-redux";

  interface Props {
    user: User;
    book: CartItem;
    marginClass: string;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    loading: boolean;
  }
  


  const BookDisclaimer: React.FC<Props> = ({user, book, marginClass, setLoading, loading}) => {
    const [bookModal, setBookModal] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [completeBook, setCompleteBook] = useState<CartItem>(book);
    const onPreviewAndTrack = (email: string, bookTitle: string = 'unknown') => {
      trackAction(
        "Pages_Preview",
        {
          google: {
            event: "pages_preview",
            details: `User ${email} has clicked the preview of "${bookTitle}"`,
          },
        },
        true
      );
    }
    return (
      <>
      {loading ? (
        <div className="my-3 mx-auto d-table">
          <Spin
            indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
          />
        </div>
      ) : (
        <div className='row align-items-center'>
          <div className="col-6">
            <div >
              <img src={book.thumbnail} className={marginClass}/>
            </div> 
          </div>
          <div className="col-6">
            {!!completeBook ? (
            <strong>{completeBook.title}</strong>
            ) : null }
                        
            <p className="align-middle" 
              style={{
              cursor:'pointer',
              color:'#f8aba0'
              }}
              onClick={() => {
                setBookModal(true);
                onPreviewAndTrack(user.email!, book.title)
              }}
            >See Preview</p>
          </div>
        </div>
      )}
        
        {!!completeBook?.babypages ? (
        <BookModal
            leftPage={completeBook.babypages[currentIndex].thumbnail}
            rightPage={completeBook.babypages[currentIndex].thumbnail_content}
            visible={bookModal}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            pagesLimit={completeBook.babypages.length - 1}
            onClose={() => {
              setBookModal!(false)
              setCurrentIndex(0)
            }}
            useOldBabypages={false}
          />
        ) : null}

      </>
    )

  }

  const mapStateToProps = (state: AppState) => ({
    user: state.user,
  });

  export default connect(mapStateToProps)(BookDisclaimer);
