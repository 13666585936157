import { Input, Select } from "antd";
import React, { useCallback } from "react";
import "./EditTextFont.scss";
import FontSelect from "../FontSelect/FontSelect"
import { newFont } from "../../interfaces/font";

const { Option } = Select;

interface Props {
  bookText: {
    title: string;
    subtitle: string;
    font: string;
    font_id:number;
  };
  setBookText: React.Dispatch<
    React.SetStateAction<{
      title: string;
      subtitle: string;
      font: string;
      font_id:number;
    }>
  >;
  isPlus: boolean;
  setPlusOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fonts: newFont[];
}

const EditTextFont: React.FC<Props> = ({ bookText, setBookText, isPlus, setPlusOpen, fonts }) => {

  const setFont = useCallback(
    (font: string, font_id:number) => {
      setBookText(state => ({ ...state, font, font_id }));
    },
    [setBookText]
  );

  return (
    <div className="edit-text py-2 px-3 px-lg-0">
      <label htmlFor="font">Font Family</label>
      <FontSelect font={bookText.font} setFont={setFont} fonts={fonts} isPlus={isPlus} setPlusOpen={setPlusOpen} />    
      <label htmlFor="book-title">Book Title</label>
      <Input
        id="book-title"
        placeholder="Basic usage"
        value={bookText.title}
        onChange={(e) => setBookText({ ...bookText, title: e.target.value })}
        maxLength={36}
        className="mb-0"
      />
      <p className="ml-2 mt-1">{bookText.title.length}/36</p>
      <label htmlFor="book-subtitle">Book Subtitle</label>
      <Input
        id="book-subtitle"
        placeholder="Basic usage"
        value={bookText.subtitle}
        onChange={(e) => setBookText({ ...bookText, subtitle: e.target.value })}
        maxLength={36}
        className="mb-0"
      />
      <p className="ml-2 mt-1">{bookText.subtitle.length}/36</p>
    </div>
  );
};

export default EditTextFont;
