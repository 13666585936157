import React from "react";
import { PAYPAL } from "../../env";
import { trackAction } from "../../utils/marketing";
import "./PaypalButton.scss";

const rootUrl =
  PAYPAL.mode === "sandbox"
    ? "https://www.sandbox.paypal.com"
    : "https://www.paypal.com";

interface Props {
  buttonId: string;
  userId: number;
  subscription: any;
  registerBeginPurchaseEvent?: () => void;
  disabled?: boolean;
}

const PaypalButton: React.FC<Props> = ({
  buttonId,
  userId,
  subscription,
  registerBeginPurchaseEvent,
  disabled = false
}) => (
  <form action={`${rootUrl}/cgi-bin/webscr`} method="post" target="_top">
    <input type="hidden" name="cmd" value="_s-xclick" />
    <input type="hidden" name="hosted_button_id" value={buttonId} />
    <input type="hidden" name="custom" value={userId} />
    <input
      className={ disabled ? "disable" : ""}
      type="image"
      src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/buy-logo-large.png"
      name="submit"
      alt="PayPal - The safer, easier way to pay online!"
      onClick={() => {
        trackAction('Purchase', subscription);
        if (!!registerBeginPurchaseEvent) registerBeginPurchaseEvent();
      }}
      disabled={disabled}
    />
    <img alt="" src={`${rootUrl}/en_US/i/scr/pixel.gif`} width="1" height="1" />
  </form>
);

export default PaypalButton;
