import { Button, Icon, InputNumber } from "antd";
import React from "react";
import { CartItem } from "../../interfaces/cart";

interface Props {
  item: CartItem;
  itemIndex: number;
  store: CartItem[];
  setStore: React.Dispatch<React.SetStateAction<CartItem[]>>;
  updateStore: (newStore: CartItem[]) => void;
  onRemoveItem: (index: number) => void;
}

const BabyPrint: React.FC<Props> = ({
  item,
  itemIndex,
  store,
  setStore,
  updateStore,
  onRemoveItem
}) => {
  return (
    <div className="row cart-item">
      <div className="col-4">
        <img src={item.thumbnail} alt="Thumbnail" className="w-100" />
      </div>
      <div className="col-8">
        <div className="row justify-content-center align-items-baseline">
          <div className="col-10">
            <h3 className="m-0">
              <strong>{item.event}</strong>
            </h3>
            <p>
              {item.title} / $
              {!!item.options && !!item.options.length
                ? item.options[0].price.toFixed(2)
                : "2.50"}{" "}
              each
            </p>
          </div>
          <div className="col-2">
            <Icon
              type="delete"
              onClick={() => onRemoveItem(itemIndex)}
              style={{ fontSize: 24 }}
            />
          </div>
        </div>
        <div className="row justify-content-center align-items-center my-1">
          <div className="col-6">
            <InputNumber
              className="w-100"
              value={!!item.qty && !isNaN(item.qty) ? item.qty : 1}
              min={1}
              max={20}
              step={1}
              onChange={value => {
                if (!value || isNaN(value) || value < 1) value = 1;
                if (value > 20) value = 20;

                const qty = value!;
                const total =
                  value! *
                  (!!item.options && !!item.options.length
                    ? item.options[0].price
                    : 2.5);

                const newStore = store.map((e, i) => {
                  if (i === itemIndex)
                    return {
                      ...e,
                      qty,
                      total,
                      options: [{ ...e.options[0], qty }]
                    };
                  return e;
                });

                setStore(newStore);
                updateStore(newStore);
              }}
            />
          </div>
          <div className="col-6">
            <Button.Group>
              <Button
                icon="minus"
                onClick={() => {
                  const newStore = store.map((e, i) => {
                    if (i === itemIndex && !!e.qty && e.qty > 1)
                      return {
                        ...e,
                        qty: e.qty - 1,
                        total:
                          (e.qty - 1) *
                          (!!item.options && !!item.options.length
                            ? item.options[0].price
                            : 2.5),
                        options: [{ ...e.options[0], qty: e.qty - 1 }]
                      };
                    return e;
                  });

                  setStore(newStore);
                  updateStore(newStore);
                }}
                disabled={(item.qty || 1) === 1}
              />
              <Button
                icon="plus"
                onClick={() => {
                  const newStore = store.map((e, i) => {
                    if (i === itemIndex && (!e.qty || e.qty < 20))
                      return {
                        ...e,
                        qty: (e.qty || 1) + 1,
                        total:
                          ((e.qty || 1) + 1) *
                          (!!item.options && !!item.options.length
                            ? item.options[0].price
                            : 2.5),
                        options: [{ ...e.options[0], qty: (e.qty || 1) + 1 }]
                      };
                    return e;
                  });

                  setStore(newStore);
                  updateStore(newStore);
                }}
                disabled={(item.qty || 1) === 20}
              />
            </Button.Group>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BabyPrint;
