import { Icon, Layout, Menu, Modal } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';
import logo from '../../assets/img/logo.svg';
import { BaseAction } from '../../store/actionTypes';
import { logoutAdmin } from '../../store/ducks/admin';
import './BaseAdminLayout.scss';

const { Content, Sider } = Layout;

interface Props {
  logout: () => void;
}

const BaseAdminLayout: React.FC<Props> = ({ children, logout }) => {
  const [collapsed, setCollapsed] = useState(true);
  const history = useHistory();

  return history.location.pathname === "/admin/login" ? (
    <Layout>{children}</Layout>
  ) : (
    <Layout>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={setCollapsed}
        className="bg-primary admin-sider"
      >
        <img src={logo} alt="Logo" className="w-100" />
        <Menu
          selectedKeys={[history.location.pathname || "/admin"]}
          mode="inline"
          className="admin-menu"
        >
          <Menu.Item
            key="/admin"
            onClick={() => {
              history.push("/admin");
            }}
          >
            <Icon type="home" />
            <span>Home</span>
          </Menu.Item>
          <Menu.Item
            key="/admin/users"
            onClick={() => {
              history.push("/admin/users");
            }}
          >
            <Icon type="user" />
            <span>Users</span>
          </Menu.Item>
          <Menu.Item
            key="/admin/fonts"
            onClick={() => {
              history.push("/admin/fonts");
            }}
          >
            <Icon type="font-colors" />
            <span>Fonts</span>
          </Menu.Item>
          <Menu.Item
            key="/admin/books"
            onClick={() => {
              history.push("/admin/books");
            }}
          >
            <Icon type="book" />
            <span>Books</span>
          </Menu.Item>
          <Menu.Item
            key="/admin/milestones"
            onClick={() => {
              history.push("/admin/milestones");
            }}
          >
            <Icon type="calendar" />
            <span>Milestones</span>
          </Menu.Item>
          <Menu.Item
            key="/admin/metrics"
            onClick={() => {
              history.push("/admin/metrics");
            }}
          >
            <Icon type="area-chart" />
            <span>Metrics</span>
          </Menu.Item>
          <Menu.Item
            key="/admin/forms"
            onClick={() => {
              history.push("/admin/forms");
            }}
          >
            <Icon type="form" />
            <span>Dynamic Forms</span>
          </Menu.Item>
          <Menu.Item
            key="/admin/gift-card"
            onClick={() => {
              history.push("/admin/gift-card");
            }}
          >
            <Icon type="gift" />
            <span>Gift Cards</span>
          </Menu.Item>
          <Menu.Item
            key="/logout"
            onClick={() => {
              Modal.confirm({
                title: "Do you wanna exit admin application?",
                okText: "Yes",
                okType: "danger",
                cancelText: "No",
                icon: "warning",
                onOk() {
                  logout();
                  history.push("/admin/login");
                }
              });
            }}
          >
            <Icon type="logout" />
            <span>Exit</span>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Content style={{ minHeight: "100vh" }}>{children}</Content>
      </Layout>
    </Layout>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<BaseAction>) => ({
  logout: () => dispatch(logoutAdmin()),
});

export default connect(null, mapDispatchToProps)(BaseAdminLayout);
