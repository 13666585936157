import React, { useState, useCallback } from 'react';
import { Prompt } from 'react-router';
import { Location } from 'history';
import { Modal } from 'antd';

interface Props {
  when: boolean | undefined;
  navigate: (path: string) => void;
  beforeNavigate?: () => void;
}

const initialModal = {
  visible: false,
  confirmedNavigation: false,
  lastLocation: null
};

const RouteLeavingGuard: React.FC<Props> = ({ beforeNavigate, when, navigate }) => {
  const [modal, setModal] = useState<{
    visible: boolean;
    confirmedNavigation: boolean;
    lastLocation: Location<any> | null;
  }>(initialModal);

  const handleBlockedNavigation = useCallback(
    (lastLocation: Location<any>) => {
      if (!modal.confirmedNavigation) {
        setModal({ confirmedNavigation: false, visible: true, lastLocation });
        return false;
      }
      return true;
    },
    [modal]
  );

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Modal
        visible={modal.visible}
        onOk={() => {
          if (modal.lastLocation !== null) {
            if (!!beforeNavigate) {
              beforeNavigate();
            }

            const pathName: string = modal.lastLocation.pathname;
            const manageChild: string = modal.lastLocation.search;
            
            setTimeout(() => {
              if(manageChild === "?child") {
                navigate(`${pathName}${manageChild}`)
              }
              navigate(pathName);
            }, 100);

            setModal({ ...initialModal, confirmedNavigation: true });
          } else {
            setModal(initialModal);
          }
        }}
        onCancel={() => setModal(initialModal)}
      >
        <h3 className="text-center">
          Your changes are not saved. Do you still want to leave?
        </h3>
      </Modal>
    </>
  );
};

export default RouteLeavingGuard;
