import { Avatar, Tabs } from "antd";
import { Dictionary } from "lodash";
import React from "react";
import { Frame } from "../../interfaces/frame";

const { TabPane } = Tabs;

interface Props {
  milestoneForm: any;
  setMilestoneForm: Function;
  groupedFrames: Dictionary<Frame[]>;
  currentTab: string;
}

const FrameImageTabs: React.FC<Props> = ({
  milestoneForm,
  setMilestoneForm,
  groupedFrames,
  currentTab
}) => {
  return (
    <Tabs
      tabPosition={"top"}
      style={{ minHeight: 220 }}
      defaultActiveKey={currentTab}
      activeKey={milestoneForm.activeTabKey}
      onChange={activeKey => {
        setMilestoneForm((milestoneForm: any) => ({
          ...milestoneForm,
          activeTabKey: activeKey
        }));
      }}
    >
      {Object.keys(groupedFrames).map(key => (
        <TabPane tab={`${key.toUpperCase()}`} key={key}>
          {groupedFrames[key].map((frame, key) => {
            let styles = {};

            if (frame.id === milestoneForm.background_id) {
              styles = {
                margin: "10px",
                height: "40px",
                width: "40px",
                borderStyle: "solid",
                borderColor: "rgb(245, 34, 44)"
              };
            } else {
              styles = {
                margin: "10px",
                height: "40px",
                width: "40px"
              };
            }

            return (
              <span
                key={key}
                onClick={() =>
                  setMilestoneForm((milestoneForm: any) => ({
                    ...milestoneForm,
                    background_id: frame.id
                  }))
                }
              >
                <Avatar src={frame.thumbnail || frame.frame} style={styles} />
              </span>
            );
          })}
        </TabPane>
      ))}
    </Tabs>
  );
};

export default FrameImageTabs;
