import { Icon, Input } from "antd";
import React from "react";
import { SimplifiedAnswer } from "../../interfaces";
import "./AnswerOrderable.scss";

interface Props {
  item: SimplifiedAnswer;
  removeAnswer: (itemId: number) => void;
  setEditAnswers: React.Dispatch<React.SetStateAction<SimplifiedAnswer[]>>;
  editAnswers: SimplifiedAnswer[];
  index: number;
  setDeletedAnswerCheck: React.Dispatch<React.SetStateAction<boolean>>;
}

const AnswerOrderable: React.FC<Props> = ({
  item,
  removeAnswer,
  setEditAnswers,
  editAnswers,
  index,
  setDeletedAnswerCheck,
}) => {
  const disabledInput =
    item.type === "height_weight" ||
    item.type === "height" ||
    item.type === "weight";
  return (
    <div className="answer-orderable">
      <Icon type="drag" className="answer-draggable" />
      <Input
        className="w-100 my-1"
        value={item.answer}
        disabled={disabledInput}
        addonAfter={
          <Icon
            type="delete"
            className="clickable"
            onClick={() => {
              // removeAnswer(index);
              setDeletedAnswerCheck(true);
              setEditAnswers(editAnswers.filter((e, i) => i !== index));
            }}
          />
        }
        onChange={(e) => {
          e.persist();
          const answer = e.target.value;

          setEditAnswers((state) =>
            state.map((a, i) => {
              if (index === i) {
                a.answer = answer;
                if(a.type === "weight" || a.type === "height" || a.type === "height_weight") {
                  a.question_id = Math.floor((Math.random() * 100000) + 10000);
                  a.type = "custom";
                }
              }
              return a;
            }),
          );
        }}
      />
    </div>
  );
};
export default AnswerOrderable;
