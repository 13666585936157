import React from "react";


function AddBabypage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      className="add-icon"
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="11" cy="11" r="11" fill="#B0B0B0"></circle>
        <path fill="#FFF" d="M10 5h2v12h-2z"></path>
        <path fill="#FFF" d="M17 10v2H5v-2z"></path>
      </g>
    </svg>
  );
}

export default AddBabypage;