import { Input, notification, Switch, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserListInterface, UsersListItem } from "../../../interfaces";
import { sagaMiddleware } from "../../../store";
import { getUsers } from "../../../store/sagas/user";
import { capitalizeTexts } from "../../../utils/string";

const AdminUsersPage: React.FC = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [debounce, setDebounce] = useState<NodeJS.Timeout>();
  const [usersData, setUsersData] = useState<UserListInterface>({
    data: [],
    total: 0,
    per_page: 10,
    current_page: 1,
    last_page: 1,
    next_page_url: null,
    prev_page_url: null,
    has_more_pages: false,
    first_item: 1,
    last_item: 1
  });
  const [plus, setPlus] = useState(false);

  useEffect(() => {
    setLoading(true);

    sagaMiddleware.run<any>(
      getUsers,
      "",
      plus,
      (err?: string, response?: UserListInterface) => {
        setLoading(false);

        if (!!err) {
          notification.error({
            message: "Error",
            description: err
          });
        } else if (!!response) {
          setUsersData(response);
        }
      }
    );
  }, [plus]);

  const filterUsers = useCallback(
    (search: string = "", page: number = 1, plusStatus: boolean = false) => {
      setLoading(true);

      sagaMiddleware.run<any>(
        getUsers,
        search,
        plusStatus,
        (err?: string, response?: UserListInterface) => {
          setLoading(false);

          if (!!err) {
            notification.error({
              message: "Error",
              description: err
            });
          } else if (!!response) {
            setUsersData(response);
          }
        },
        page
      );
    },
    []
  );

  const onChangeFilters = useCallback(
    (search: string = "", plus: boolean = false) => {
      setSearch(search);
      setPlus(plus);

      if (debounce) clearTimeout(debounce);

      setDebounce(
        setTimeout(() => {
          filterUsers(search, 1, plus);
        }, 100)
      );
    },
    [debounce, filterUsers]
  );

  return (
    <div className="m-3">
      <h1 className="title">Users List</h1>
      <div className="row justify-content-center align-items-center mx-3">
        <div className="col-6 col-lg-3 my-1">
          <Input
            value={search}
            onChange={e => {
              onChangeFilters(e.target.value, plus);
            }}
            placeholder="Search user"
            className="w-100"
          />
        </div>
        <div className="col-6 col-lg-3 my-1">
          PLUS users{" "}
          <Switch
            checked={plus}
            onChange={e => {
              onChangeFilters(search, e);
            }}
          />
        </div>
      </div>
      <Table
        dataSource={usersData.data}
        loading={loading}
        bordered
        pagination={{
          current: usersData.current_page,
          total: usersData.total,
          pageSize: 10
        }}
        onChange={e => filterUsers(search, e.current || 1, plus)}
        className="my-3 elevation-5"
        rowKey={(record: UsersListItem) => record.id.toString()}
        onRow={(record: UsersListItem) => {
          return {
            onClick: () => {
              if (!record.deleted_at) {
                history.push(`/admin/users/detail?id=${record.id}`);
              }
            }
          };
        }}
      >
        <Table.Column
          title="Name"
          dataIndex="name"
          key="name"
          ellipsis
          align="center"
          render={(text: any, record: UsersListItem) => (
            <Tooltip title={`${record.first_name} ${record.last_name}`}>
              <span>
                {capitalizeTexts(record.first_name ?? "")}{" "}
                {capitalizeTexts(record.last_name ?? "")}
              </span>
            </Tooltip>
          )}
        />
        <Table.Column
          title="Email"
          dataIndex="email"
          key="email"
          ellipsis
          align="center"
          render={(text: any, record: UsersListItem) => (
            <Tooltip title={record.email}>
              <span>{record.email}</span>
            </Tooltip>
          )}
        />
        <Table.Column
          title="Created"
          dataIndex="created_at"
          key="created_at"
          ellipsis
          align="center"
          render={(text: any, record: UsersListItem) => (
            <span>{moment(record.created_at).format("MMM[, ]Do[, ]YYYY")}</span>
          )}
        />
        <Table.Column
          title="Last Login"
          dataIndex="last_login"
          key="last_login"
          ellipsis
          align="center"
          render={(text: any, record: UsersListItem) => (
            <span>{moment(record.last_login).format("MMM[, ]Do[, ]YYYY")}</span>
          )}
        />
        <Table.Column
          title="Deleted"
          dataIndex="deleted_at"
          key="deleted_at"
          ellipsis
          align="center"
          render={(text: any, record: UsersListItem) => (
            <span>
              {!!record.deleted_at &&
                moment(record.deleted_at).format("MMM[, ]Do[, ]YYYY")}
            </span>
          )}
        />
        <Table.Column
          title="N° Babies"
          dataIndex="babies"
          key="babies"
          ellipsis
          align="center"
          render={(text: any, record: UsersListItem) => (
            <span>{record.total_babies}</span>
          )}
        />
        <Table.Column
          title="N° Babypages"
          dataIndex="babypages"
          key="babypages"
          ellipsis
          align="center"
          render={(text: any, record: UsersListItem) => (
            <span>{record.total_babypages}</span>
          )}
        />
        <Table.Column
          title="N° Books"
          dataIndex="books"
          key="books"
          ellipsis
          align="center"
          render={(text: any, record: UsersListItem) => (
            <span>{record.total_books}</span>
          )}
        />
        <Table.Column
          title="Status"
          dataIndex="plus"
          align="center"
          render={(text: any, record: UsersListItem) =>
            !record.deleted_at ? (
              record.user_level === 1 ? (
                <Tag color="#658071">Plus user</Tag>
              ) : (
                <Tag color="#ffafa5">Free user</Tag>
              )
            ) : (
              <Tag color="red">Deleted</Tag>
            )
          }
        />
      </Table>
    </div>
  );
};

export default AdminUsersPage;
