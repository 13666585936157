import { Admin } from '../../interfaces';
import { ADMIN_CHANGE, ADMIN_LOGOUT, BaseAction } from '../actionTypes';

const initialState: Admin = {};

export const admin = (
  state: Admin = initialState,
  action: BaseAction
): Admin => {
  switch (action.type) {
    case ADMIN_CHANGE:
      return { ...state, ...action.payload };
    case ADMIN_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
Object.freeze(admin);

export const changeAdmin = (payload: any) => ({
  payload,
  type: ADMIN_CHANGE,
});
Object.freeze(changeAdmin);

export const logoutAdmin = () => ({
  type: ADMIN_LOGOUT,
});

Object.freeze(logoutAdmin);

export default admin;
