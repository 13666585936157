import {
  Card, Col, Input,



  Row
} from "antd";
import React from "react";

const AdminMetricsPage: React.FC = () => {
  // // const history = useHistory();

  // const [loading, setLoading] = useState(false);
  // const [search, setSearch] = useState("");
  // // const [debounce, setDebounce] = useState<NodeJS.Timeout>();
  // // const [metrics, setMetrics] = useState(false);

  // useEffect(() => {
  //   setLoading(true);

  //   sagaMiddleware.run<any>(
  //     getMetricsAdmin,
  //     "",
  //     (err?: string, response?: BookListInterface) => {
  //       setLoading(false);

  //       if (!!err) {
  //         notification.error({
  //           message: "Error",
  //           description: err
  //         });
  //       } else if (!!response) {
  //         //setBooksData(response);
  //       }
  //     }
  //   );
  // }, []);

  return (
    <div className="m-3">
      <h1 className="title">Metrics</h1>
      <div className="row justify-content-center align-items-center mx-3">
        <div className="col-6 col-lg-3 my-1">
          <Input
            // value={search}
            placeholder="Search book"
            className="w-100"
          />
        </div>
      </div>
      <div style={{ background: "#ECECEC", padding: "30px" }} className="m-3">
        <Row gutter={16}>
          <Col span={8}>
            <Card title="Card title" bordered={false}>
              Card content
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Card title" bordered={false}>
              Card content
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Card title" bordered={false}>
              Card content
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AdminMetricsPage;
