import React, { useState } from "react";
import { BabyPage, Cover } from "../../interfaces";
import BookCover from "../BookCover/BookCover";
import { Button } from "antd";

interface Props {
  loading: boolean;
  bookText: {
    title: string;
    subtitle: string;
    font: string;
  };
  selectedCover: Cover | undefined;
  selectedBabypages: BabyPage[];
  useOldBabypages: boolean;
  setControl: React.Dispatch<React.SetStateAction<{valid: boolean, step:number}>>;
  nextStep:React.EffectCallback;
}

const BookFinalPreview: React.FC<Props> = ({
  loading,
  bookText,
  selectedCover,
  selectedBabypages,
  useOldBabypages,
  setControl,
  nextStep
}) => {
  const [modal, setModal] = useState(false);
  const props = {
    loading,
    bookText,
    selectedCover,
    selectedBabypages,
    useOldBabypages,
    modal,
    setModal
  };
  return (
    <div className="py-2">
      <div className="container">
        <div className="row justify-content-start align-items-center py-2">
          <div className="col-12">
            <p className="book-preview-text">
              Tap the book cover to open the preview
            </p>
            <BookCover {...props} />
          </div>
        </div>
        <div className="d-flex flex-row justify-content-center my-1">
            <div className="col-lg-3 col-6">
              <Button type="primary" ghost shape="round" className="w-100 book-creation-buttons" onClick={() => {
                setControl((prev)  => {
                  return {
                    ...prev,
                    step:prev.step -1,
                  }
                })
              }}>
                  Previous
              </Button>     
            </div>
            <div className="col-lg-3 col-6">
                <Button
                  type="primary"
                  ghost
                  shape="round"
                  className="w-100 green-button book-creation-buttons"
                  onClick={nextStep}
                >
                  Save Book
                </Button>
            </div>   
          </div>
      </div>
    </div>
  );
};

export default BookFinalPreview;
