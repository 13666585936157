import React from "react";

interface Props {
  classes?:string;
}

  const SortIcon: React.FC<Props> = ({
    classes
  }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={classes}
    >
      <path
        fill="none"
        fillRule="evenodd"
        stroke="#909090"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 2.909h5.444m3.112 0H15M1 8h7m3.111 0H15M1 13.091h3.889m3.111 0h7M6.444 1v3.818m4.667 1.273v3.818m-6.222 1.273V15"
      ></path>
    </svg>
  );
}

export default SortIcon;