import { call, select } from "redux-saga/effects";
import { AppState } from "..";
import api from "../../api";
import { Coupon, Purchase, ShippingMethod } from "../../interfaces";
import { createCardToken } from "../../stripe";
import { parseRequestError } from "../../utils/error";
import { changeUser } from "../ducks/user";
import { trackAction, trackPurchase } from "./../../utils/marketing";
import { getCustomerInfo } from "./customer";
import { getAuthenticatedUser } from "./user";

/**
 * Create a new Purchase
 */
export function* createPurchaseSaga(
  purchase: Purchase,
  total: number,
  cardInfo: {
    cardNumber: number;
    cardName: string;
    cardCVV: number;
    cardMonth: number;
    cardYear: number;
    customerId: string;
  } | null,
  callback: (error?: string) => void
) {
  

  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  try {
    if (!!cardInfo) {
      const token = yield call(createCardToken, {
        number: cardInfo.cardNumber,
        exp_month: cardInfo.cardMonth,
        exp_year: cardInfo.cardYear,
        cvc: cardInfo.cardCVV,
        name: cardInfo.cardName,
      });
      if (!!token) {
        const response = yield call(
          api.post,
          "api/stripe/create-card",
          {
            source: token,
            customer_id: cardInfo.customerId,
          },
          {
            headers: {
              Authorization: accessToken,
            },
          }
        );

        if (
          !!response &&
          !!response.data &&
          !!response.data.data &&
          !!response.data.data.id
        ) {
          trackAction('AddPaymentInfo', {
            google: {
              event: "add_payment_info"
            }
          });
          purchase.card_token = response.data.data.id;
        } else {
          throw new Error("Invalid Card");
        }
      } else {
        throw new Error("Invalid Card");
      }
    }

    const mainUserId = yield select(
      (state: AppState) => state.user.main_user?.id
    );
    const mainUserStripeId = yield select(
      (state: AppState) => state.user.main_user?.stripe_id
    );
  
    if (mainUserId) purchase.stripe_token = mainUserStripeId;

    yield call(api.post, "api/create-purchase?lang=en", purchase, {
      headers: {
        Authorization: accessToken,
      },
    });

    // trackAction("OrderConfirmation", {
    //   google: {
    //     event: "purchase",
    //   },
    // });
    yield call(getAuthenticatedUser);
    yield call(getCustomerInfo);

    trackPurchase(purchase.store, total);
    callback(undefined);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * Check user coupon
 */
export function* checkCoupon(
  coupon: string,
  callback: (error?: string, coupon?: Coupon) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  try {
    const response = yield call(
      api.get,
      `api/stripe/retrieve-coupon/${coupon}`,
      {
        headers: {
          Authorization: accessToken,
        },
      }
    );

    if (response.data && response.data.code === 200 && !!response.data.data) {
      callback(undefined, response.data.data);
    } else {
      callback("Coupon not valid");
    }
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * Check paypal transaction
 */
export function* checkPaypalTransaction(
  data: any,
  callback?: (error?: string) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  try {
    const response = yield call(api.post, "api/verify-paypal-payment", data, {
      headers: {
        Authorization: accessToken,
      },
    });

    if (response.data && response.data.code === 200 && !!response.data.data) {
      const { user } = response.data.data;
      const trackActionData = {
        facebook: response.data.data,
        amplitude: response.data.data,
        pinterest: response.data.data,
        google: {
          event: "subscription",
        },
      };
      trackAction("Subscribe", trackActionData);
      changeUser({ ...user });
    }

    if (!!callback) callback(undefined);
  } catch (error) {
    if (!!callback) callback(parseRequestError(error));
  }
}

/**
 * Get shipping methods
 */
export function* getShippingMethods(
  country:any,
  callback: (err?: string, response?: ShippingMethod[]) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  try {
    const response = yield call(api.get, `api/stripe/shipping-methods?country=${country}`, {
      headers: {
        Authorization: accessToken,
      },
    });

    if (response.data && response.data.code === 200 && !!response.data.data) {
      callback(undefined, response.data.data);
    } else {
      callback("There was an error trying to get shipping methods");
    }
  } catch (error) {
    callback(parseRequestError(error));
  }
}
