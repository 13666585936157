import { call, select } from "redux-saga/effects";
import { AppState } from "..";
import api from "../../api";
import { QuestionItem } from "../../interfaces";
import { parseRequestError } from "../../utils/error";

/**
 * This function fetch all products
 **/
export function* fetchQuestions(
  event: string,
  callback: (questions: QuestionItem[]) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );
  if (!accessToken) return;

  try {
    const response = yield call(
      api.post,
      "api/questions?lang=en",
      { event },
      {
        headers: {
          Authorization: accessToken
        }
      }
    );

    if (response.data && response.data.code === 200) {
      callback(response.data.data ? response.data.data : []);
    } else {
      callback([]);
    }
  } catch (error) {
    callback([]);
  }
}

/**
 * This function get questions by milestone id
 **/
export function* getQuestionsByMilestoneId(
  milestoneId: number,
  callback: (err?: string, response?: QuestionItem[]) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.admin.access_token
  );
  if (!accessToken) return;

  try {
    const response = yield call(
      api.get,
      `api/admin/questions?milestone=${milestoneId}`,
      {
        headers: {
          Authorization: accessToken
        }
      }
    );

    callback(undefined, response.data.data);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * This function delete questions by id
 **/
export function* deleteQuestion(
  id: number,
  callback: (err?: string, response?: QuestionItem[]) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.admin.access_token
  );
  if (!accessToken) return;

  try {
    const response = yield call(api.delete, `api/admin/question/${id}`, {
      headers: {
        Authorization: accessToken
      }
    });

    callback(undefined, response.data.data);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * Update question
 */
export function* saveQuestion(
  id: number,
  data: any,
  callback: (err?: string, response?: QuestionItem) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.admin.access_token
  );

  try {
    const url = id !== 0 ? `api/admin/question/${id}` : `api/admin/question`;
    const response = yield call(api.post, url, data, {
      headers: {
        Authorization: accessToken
      }
    });

    callback(undefined, response.data.data);
  } catch (error) {
    callback(parseRequestError(error));
  }
}
