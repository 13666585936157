import { Button, Icon, Modal, Spin } from 'antd';
import 'cropperjs/dist/cropper.css';
import React from 'react';
import Cropper from 'react-cropper';
import './ImageEditModal.scss';
import { getBrowserWidth } from "../../utils/size"

interface Props {
  loading: boolean;
  visible: boolean;
  maskClosable:boolean;
  image: string;
  cropperRef: Cropper | null;
  setCropperRef: React.Dispatch<React.SetStateAction<Cropper | null>>;
  croppedData?: Cropper.Data;
  aspect: number;
  onCropComplete: (
    croppedData: Cropper.Data,
    imageData: Cropper.ImageData
  ) => void;
  onOk: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onChangePhoto: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  roundCropper?: boolean;
}

class ImageEditModal extends React.PureComponent<Props> {
  render() {
    const { visible, maskClosable, onOk, onCancel, roundCropper } = this.props;
    const width = getBrowserWidth();

    const modalProps = { visible, maskClosable ,onOk, onCancel };

    // Checking if device is iOS to "force" img-cropping
    const isIOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);

    const roundClasses = roundCropper ? "round-cropper" : ""

    return (
      <Modal
      wrapClassName='image-edit-modal'
        {...modalProps}
        okButtonProps={{
          className: roundCropper  ? "hidden" : '',
          disabled: this.props.loading,
        }}
        cancelButtonProps={{
          className: !!isIOS || roundCropper  ? "hidden" : '',
        }}
        closable={!isIOS}
        zIndex={998}
        style={{top:10}}
        className={roundCropper ? "mobile-profile-modal" : ""}
      >
        <div className={`cropper-wrapper mt-3  ${roundCropper ? "negative" :""}`}>
          { roundCropper ? (
            <div className="row justify-content-around align-items-center mt-1 ">
              <h1 className='crop-modal-title'  >
                  Crop photo
              </h1>
            </div>
          ) : null
          }
          <Cropper
          className={`${roundClasses}`}
            ref={ref => {
              this.props.setCropperRef(ref);
            }}
            src={this.props.image}
            minCropBoxWidth={15}
            aspectRatio={this.props.aspect}
            data={this.props.croppedData}
            ready={() => {
              if (!this.props.croppedData) {
                this.props.onCropComplete(
                  this.props.cropperRef!.getData(),
                  this.props.cropperRef!.getImageData()
                );
              } else {
                this.props.cropperRef!.setData(this.props.croppedData);
              }
            }}
            zoom={() =>
              this.props.onCropComplete(
                this.props.cropperRef!.getData(),
                this.props.cropperRef!.getImageData()
              )
            }
            cropend={() => {
              if (this.props.cropperRef!.getData().height >= this.props.cropperRef!.getImageData().naturalHeight - 0.00001) {
                this.props.cropperRef?.setCropBoxData({...this.props.cropperRef.getCropBoxData(), height: this.props.cropperRef.getCropBoxData().height - 0.3})
              } else if (this.props.cropperRef!.getData().width >= this.props.cropperRef!.getImageData().naturalWidth - 0.00001) {
                this.props.cropperRef?.setCropBoxData({...this.props.cropperRef.getCropBoxData(), width: this.props.cropperRef.getCropBoxData().width - 0.3})
              }
              this.props.onCropComplete(
                this.props.cropperRef!.getData(),
                this.props.cropperRef!.getImageData()
              )
              }
            }
            viewMode={2}
            style={{
              height: 
                      width > 1366 ? 456 :
                      width >= 500 ? 
                               360 : 
                      width >= 375 ? 
                               310 : 
                               280,
            }}
          />
        </div>
        <div className="row justify-content-around align-items-center mt-1 ">
          { roundCropper ? (<>
          <div className="d-flex justify-content-between w-100 mx-3">
          <h1 className='baby-photo-steps ml-2 clickable'  onClick={this.props.onChangePhoto}>
          Change Photo
          </h1>
          <h1 className='baby-photo-steps  mr-2 clickable' onClick={onOk}>
           Next
          </h1>
          </div>
          </>) : (
            <h1 className='title title-tips' >
            Scroll to zoom in/out or 
            click and drag to reposition.
          </h1>
          )} 
        </div>

        {roundCropper ? null : (
             <div className='row justify-content-around align-items-center mt-1'>
             <Button
               type='primary'
               ghost
               shape='circle'
               icon='edit'
               onClick={this.props.onChangePhoto}
             />
             {this.props.loading && (
               <Spin
                 indicator={<Icon type='loading' style={{ fontSize: 24 }} spin />}
               />
             )}
             <Button
               type='primary'
               className='rotate-btn'
               shape='circle'
               icon='reload'
               onClick={() => {
                 if (!!this.props.cropperRef) {
                   const cropped = this.props.cropperRef.rotateTo(
                     !!this.props.croppedData
                       ? this.props.croppedData.rotate === 360
                         ? 0
                         : this.props.croppedData.rotate + 90
                       : 90
                   );
   
                   this.props.onCropComplete(
                     cropped.getData(),
                     cropped.getImageData()
                   );
                 }
               }}
             />
           </div>
        )}
     
      </Modal>
    );
  }
}

export default ImageEditModal;
