import { Modal } from "antd";
import axios from "axios";
// @ts-ignore
import dataURLtoBlob from "blueimp-canvas-to-blob";
import Cropper from "cropperjs";
import { apiVersion, BASE_URL } from "../env";
import {
  BpPhotoDB,
  Layout,
  SaveBabyPagePhoto,
  initialBabyPage,
} from "../interfaces";
import { calculateCropped } from "../pages/BabyPage/Save/SaveHelper";
import { getAspectFromLayout, getLayoutInfos } from "./layouts";
var toBlob = require("canvas-to-blob");

/**
 * This function check photo layouts and recrop
 * @param {SaveBabyPagePhoto[]} photos
 * @param {Layout} layout
 * @param {boolean} checkOldLayout    // If should check with old layout aspect
 * @param {Layout} oldLayout
 * @param {number[][]} layoutInfo
 *
 * In case you just want to recrop all pass just photos and layout (Example Drag & Drop)
 * In case you want to compare in a layout change, pass oldLayout info
 */
export const checkPhotoLayouts = (
  photos: SaveBabyPagePhoto[],
  layout: Layout,
  layoutInfo: number[][],
  checkOldLayout: boolean = false,
  oldLayout?: Layout,
): Promise<SaveBabyPagePhoto[]> =>
  Promise.all(
    photos.map(async (photo: SaveBabyPagePhoto, index: number) => {
      let oldLayoutInfo: number[][] | null = null;
      let shouldRecropPhoto: boolean =
        !!photo.file && !!photo.croppedData && !!photo.base64_cropped;

      if (checkOldLayout && !!oldLayout && !!layoutInfo) {
        oldLayoutInfo = getLayoutInfos(oldLayout);
        shouldRecropPhoto =
          shouldRecropPhoto &&
          oldLayoutInfo[index][0] / oldLayoutInfo[index][1] !==
            layoutInfo[index][0] / layoutInfo[index][1];
      } else if (!!photo.oldAspect) {
        shouldRecropPhoto =
          shouldRecropPhoto &&
          photo.oldAspect !== layoutInfo[index][0] / layoutInfo[index][1];
      }

      if (shouldRecropPhoto) {
        try {
          const aspect = getAspectFromLayout(layout, index);

          //  <--------------------- THIS TRIGGERS THE SCROLLBAR-------------->
          photo.base64_cropped = await getCroppedImg(
            await getImage(photos[index].file!),
            aspect,
            photos[index].croppedData!.rotate,
          );
          photo.oldAspect = aspect;
        } catch (error) {
          Modal.error({
            title: "Error",
            content:
              "An error occurred while cropping the image. Try again later",
          });

          photo.file = undefined;
          photo.base64_cropped = "";
        }
      }

      return photo;
    }),
  );

export const getCroppedImg = (
  image: HTMLImageElement,
  aspectRatio: number,
  rotation: number = 0,
  data?: Cropper.Data,
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const content = document.getElementById("save-content");
    if (!!content) {
      content.appendChild(image);
      content.classList.toggle("no-overflow");

      new Cropper(image, {
        autoCrop: true,
        data,
        aspectRatio,
        viewMode: 2,
        ready: (e: any) => {
          if (rotation === 0) {
            resolve(
              e.target.cropper.getCroppedCanvas().toDataURL("image/jpeg"),
            );
          } else {
            const cropped = e.target.cropper.rotateTo(rotation);
            resolve(cropped.getCroppedCanvas().toDataURL("image/jpeg"));
          }

          content.removeChild(image);
          content.classList.toggle("no-overflow");
        },
      });
    } else {
      reject("Image not loaded");
    }
  });
};

export const getBase64 = (
  file: File | Blob,
): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const getImage = (file: File | Blob): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const url = URL.createObjectURL(file);
    const img = new Image();

    img.onload = function () {
      // if (
      //   Math.max(img.naturalWidth, img.width) <= 1500 &&
      //   Math.max(img.naturalHeight, img.height) <= 1500
      // ) {
      return resolve(img);
      // } else {
      //   return resizeImage(img)
      //     .then(resized => {
      //       return getImage(resized)
      //         .then(resolve)
      //         .catch(reject);
      //     })
      //     .catch(reject);
      // }
    };

    img.src = url;
  });

export const resizeImage = (image: HTMLImageElement): Promise<Blob | File> =>
  new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) return reject("Context not working");

    let width = Math.max(image.naturalWidth, image.width);
    let height = Math.max(image.naturalHeight, image.height);

    if (width > height) {
      canvas.height = (height * 1500) / width;
      canvas.width = 1500;
    } else {
      canvas.width = (width * 1500) / height;
      canvas.height = 1500;
    }

    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

    return canvasToBlobPromise(canvas).then(resolve).catch(reject);
  });

export const canvasToBlobPromise = (
  canvas: HTMLCanvasElement,
  type = "image/jpeg",
): Promise<Blob | any> =>
  new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob !== null) {
        return resolve(blob);
      } else {
        return reject({
          message: "Your browser can not process canvas images.",
        });
      }
    }, type);
  });

export const getFileFromUrl = async (
  url: string,
  avoidCorsUrl: string = "",
): Promise<File | Blob | null> => {
  try {
    const file = (
      await axios.get(`${avoidCorsUrl}${url}`, {
        responseType: "blob",
        headers: {
          Accept: apiVersion,
        },
      })
    ).data;
    return file;
  } catch (error) {
    if (error.response.status === 404) {
      const file = (
        await axios.get(
          `${avoidCorsUrl}${"https://babypage-staging.s3.amazonaws.com/public/missingImagePlaceholder/add_babypage_placeholder.png"}`,
          {
            responseType: "blob",
            headers: {
              Accept: apiVersion,
            },
          },
        )
      ).data;
      return file;
    }
    return null;
  }
};

export const getBase64FromFileUrl = async (
  url: string,
  avoidCorsUrl: string = "",
) => {
  const file: File | Blob | null = await getFileFromUrl(url, avoidCorsUrl);
  if (file === null) {
    return "";
  } else {
    return ((await getBase64(file)) || "").toString();
  }
};

export const b64toBlob = (
  b64Data: string,
  contentType = "image/jpeg",
  sliceSize = 512,
): Blob => {
  if (b64Data.indexOf(",") !== -1) {
    b64Data = b64Data.split(",")[1];
  }

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const getPhotoFile = async (
  { zoom, name, origin, rotation, originPercent, croppedData }: BpPhotoDB,
  layout: Layout,
  index: number,
  source: "web" | "ios" | "android" = "web",
): Promise<SaveBabyPagePhoto> => {
  const file: File | Blob | null = await getFileFromUrl(
    name,
    `${BASE_URL}api/avoid-cors?url=`,
  );

  if (file === null) {
    return initialBabyPage.photos[0];
  } else {
    const base64 = ((await getBase64(file)) || "").toString();
    zoom = zoom > 1 ? zoom : 1;
    const image = await getImage(file);

    // if (
    //   !!originPercent &&
    //   originPercent.length === 2 &&
    //   (source === "ios" || source === "android")
    // ) {
    if (!!originPercent && originPercent.length === 2 && source === "android") {
      const rotated = rotation % 180 === 90;
      originPercent = originPercent.map((p) => (p > 1 ? p / 100 : p));

      const height = rotated ? image.naturalWidth : image.naturalHeight;
      const width = rotated ? image.naturalHeight : image.naturalWidth;

      origin = [width * originPercent[0], height * originPercent[1]];

      croppedData = undefined;
    }

    if (!origin || origin.length !== 2) {
      origin = [];
    }

    const imageData = {
      aspectRatio: image.naturalWidth / image.naturalHeight,
      height: image.height,
      width: image.width,
      left: 0,
      naturalHeight: image.naturalHeight,
      naturalWidth: image.naturalWidth,
      rotate: rotation,
      scaleX: 1,
      scaleY: 1,
      top: 0,
    };

    const aspect = getAspectFromLayout(layout, index);
    croppedData = !!croppedData
      ? croppedData
      : calculateCropped(zoom, imageData, origin, rotation, aspect, source);

    return {
      file,
      base64,
      base64_cropped: await getCroppedImg(image, aspect, rotation, {
        ...croppedData,
      }),
      name,
      imageData,
      croppedData,
      oldAspect: aspect,
    };
  }
};

export const isValidImage = (type: string): boolean =>
  [
    "image/jpeg",
    "image/jpg",
    "image/webp",
    "image/png",
    "png",
    "jpeg",
    "jpg",
    "webp",
  ].includes(type);

const compatibleBrowsersWebp = ["Chrome", "Firefox", "Microsoft Edge", "Opera"];

export const canUseWebp = (browser: string): boolean => {
  return compatibleBrowsersWebp.includes(browser);
};

export const inspectAllBabypagesImages = (book: any[]): Promise<any> => {
  return Promise.all(
    book.map((bps) =>
      axios
        .head(bps.thumbnail)
        .then((r) => axios.head(bps.thumbnail.replace("thumb_", "")))
        .catch((err) => ({ ...err, bps })),
    ),
  ).then((responses) =>
    responses
      .filter(
        (res) => !res.status && res.response && res.response.status === 403,
      )
      .map((item) => item.bps),
  );
};
