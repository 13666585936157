import {Input, Modal, notification} from 'antd';
import React, {useCallback, useEffect, useState} from 'react';
import isEmail from "validator/lib/isEmail";
import {sagaMiddleware} from '../../store';
import {contactSupportAlexandersOrders} from '../../store/sagas/user';

interface Props {
  visible: boolean;
  close: () => void;
  alexandersOrder: string
}

interface SupportForm {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  message: string;
}

const AlexandersContactSupportModal: React.FC<Props> = ({visible, close, alexandersOrder}) => {
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState<SupportForm>({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  });

  const addCard = useCallback(
    (form: SupportForm) => {
      setLoading(true);
      sagaMiddleware.run<any>(
        contactSupportAlexandersOrders,
        {
          first_name: form.firstName,
          last_name: form.lastName,
          email: form.email,
          phone: form.phoneNumber,
          text_message: `${form.message} <br/> ----- <br/> Message to contact support from the order # : <br/> ${alexandersOrder}`,
        },
        (err?: string, card_id?: string) => {
          setLoading(false);

          if (!!err) {
            Modal.error({
              title: 'Error',
              content: err
            });
          } else if (!!card_id) {
            notification.success({
              message: 'Success',
              description: 'Message sent successfully'
            });

            close();
          }
        }
      );
    },
    []
  );

  useEffect(() => {
    if (!visible) {
      setForm({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: '',
      });
    }
  }, [visible]);

  return (
    <Modal
      title="Contact Support"
      visible={visible}
      onCancel={close}
      onOk={() => addCard(form)}
      okButtonProps={{
        disabled:
          loading ||
          !form.firstName ||
          !form.lastName ||
          !isEmail(form.email) ||
          !form.message
      }}
      okText="Send Message"
    >
      <div className="row justify-content-center align-items-center">
        <div
          className={`col-12 my-1`}
        >
          <div className="col-12 my-1">
            <label>First Name</label>
            <Input
              maxLength={100}
              className="w-100"
              value={form.firstName || ''}
              onChange={e => {
                e.persist();
                setForm(state => ({
                  ...state,
                  firstName: e.target.value.replace(/[^A-Za-z]/g, '')
                }));
              }}
            />
          </div>
          <div className="col-12 my-1">
            <label>Last Name</label>
            <Input
              maxLength={100}
              className="w-100"
              value={form.lastName || ''}
              onChange={e => {
                e.persist();
                setForm(state => ({
                  ...state,
                  lastName: e.target.value.replace(/[^A-Za-z]/g, '')
                }));
              }}
            />
          </div>
          <div className="col-12 my-1">
            <label>Email</label>
            <Input
              type="email"
              maxLength={100}
              className="w-100"
              value={form.email || ''}
              onChange={e => {
                e.persist();
                setForm(state => ({
                  ...state,
                  email: e.target.value
                }));
              }}
            />
          </div>
          <div className="col-12 my-1">
            <label>Phone Number</label>
            <Input
              maxLength={30}
              value={form.phoneNumber}
              onChange={e => {
                e.persist();
                setForm(state => ({
                  ...state,
                  phoneNumber: e.target.value.replace(/[^0-9]/g, '')
                }));
              }}
            />
          </div>
          <div className="col-12 my-1">
            <label>Message</label>
            <Input.TextArea
              maxLength={255}
              autoSize={{ minRows: 5, maxRows: 5 }}
              className="w-100 no-scalable"
              value={form.message || ''}
              onChange={e => {
                e.persist();
                setForm(state => ({
                  ...state,
                  message: e.target.value
                }));
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AlexandersContactSupportModal;
