import React from 'react';
import './CustomDivider.scss';

interface Props {
  text?: string;
  customClasses?: string[];
  style?: React.CSSProperties | undefined;
  parentCustomClasses?: string;
}

const CustomDivider: React.FC<Props> = ({ text, customClasses = [], style, parentCustomClasses = "" }) => (
  <div className={`Divider ${parentCustomClasses}`} style={style}>
    {text && (
      <span className={`DividerText ${customClasses.join(' ')}`}>{text}</span>
    )}
  </div>
);

export default CustomDivider;
