import { call, put, select } from 'redux-saga/effects';
import { AppState } from '..';
import api from '../../api';
import { User } from '../../interfaces/user';
import {Customer} from '../../interfaces/customer'
import { parseRequestError } from '../../utils/error';
import { changeCustomer } from '../ducks/customer';
import { isEqual } from 'lodash';
import { changeUser } from '../ducks/user';
import { createCardToken } from '../../stripe';

/**
 * Create Customer
 */
export function* createCustomer(callback?: (err?: string) => void) {
  const user: User = yield select((state: AppState) => state.user);

  if (!user.access_token) return;
  if (!!user.stripe_id) return;

  try {
    const response = yield call(
      api.post,
      `api/stripe/create-customer?lang=en`,
      { email: user.email },
      {
        headers: {
          Authorization: user.access_token,
        },
      }
    );

    if (!!response.data.data) {
      yield put(changeUser({ stripe_id: response.data.data.id }));
    }

    yield call(getCustomerInfo);

    if (callback) callback(undefined);
    return response.data.data.id;
  } catch (error) {
    if (callback) callback(parseRequestError(error));
  }
  return null
}

/**
 * Create Guest user Customer
 */
 export function* createGuestCustomer(callback?: (err?: string) => void) {
  const user: User = yield select((state: AppState) => state.user);

  if (!user.access_token) return;
  if (!!user.main_user.stripe_id) return;

  try {
    const response = yield call(
      api.post,
      `api/stripe/create-customer?lang=en`,
      { main_user_id: user.main_user.id },
      {
        headers: {
          Authorization: user.access_token,
        },
      }
    );
    if (!!response.data.data) {
      yield put(changeUser({ main_user: { ...user.main_user, stripe_id: response.data.data.id } }));
    }

    yield call(getCustomerInfo);

    if (callback) callback(undefined);
    
    return response.data.data.id;
  } catch (error) {
    if (callback) callback(parseRequestError(error));
  }
  return null
}

/**
 * Get Customer Info
 */
export function* getCustomerInfo(callback?: (err?: string) => void) {
  const user: User = yield select((state: AppState) => state.user);
  const customer: Customer = yield select ((state: AppState) => state.customer)
  const mainUserId = yield select(
    (state: AppState) => state.user.main_user?.id
  );
  const mainUserStripeId = yield select(
    (state: AppState) => state.user.main_user?.stripe_id
  );

  if (!user.access_token || (!user.stripe_id && !customer.id)) return;
  let stripeId = user.stripe_id ? user.stripe_id : customer.id;
  if (mainUserId && !!user.guest_user) stripeId = mainUserStripeId;

  try {
    const response = yield call(
      api.get,
      `api/stripe/retrieve-customer/${stripeId}?lang=en`,
      {
        headers: {
          Authorization: user.access_token,
        },
      }
    );

    if (response.data && response.data.code === 200) {
      const oldCustomer = yield select((state: AppState) => state.customer);

      if (!isEqual(oldCustomer, response.data.data)) {
        yield put(changeCustomer(response.data.data));
      }

      if (callback) callback(undefined);
    } else {
      throw new Error('Error getting customer info');
    }
  } catch (error) {
    if (callback) callback(parseRequestError(error));
  }
}

export function* addCustomerCard(
  card: {
    number: number;
    exp_month: number;
    exp_year: number;
    cvc: number;
    name: string;
  },
  callback: (err?: string, card_id?: string) => void
) {
  const user: User = yield select((state: AppState) => state.user);
  const customer: Customer = yield select ((state: AppState) => state.customer)
  let stripeId = user?.stripe_id ?? customer?.id;
  
  if (!user.access_token || !stripeId) {
    return callback('Invalid user, please try to refresh your page');
  }

  try {
    const token = yield call(createCardToken, card);

    if (!!token) {
      const response = yield call(
        api.post,
        'api/stripe/create-card',
        {
          source: token,
          customer_id: stripeId,
        },
        {
          headers: {
            Authorization: user.access_token,
          },
        }
      );

      if (
        !!response &&
        !!response.data &&
        !!response.data.data &&
        !!response.data.data.id
      ) {
        yield call(getCustomerInfo);
        return callback(undefined, response.data.data.id);
      }
    }

    throw new Error('Invalid Card');
  } catch (error) {
    callback(parseRequestError(error));
  }
}

export function* updateCustomerCard(
  default_source: string,
  callback: (err?: string) => void
) {
  const user: User = yield select((state: AppState) => state.user);

  if (!user.access_token || !user.stripe_id) return;

  try {
    const response = yield call(
      api.put,
      `api/stripe/update-customer/${user.stripe_id}?lang=en`,
      { default_source },
      {
        headers: {
          Authorization: user.access_token,
        },
      }
    );

    if (response.data && response.data.code === 200) {
      const oldCustomer = yield select((state: AppState) => state.customer);

      if (!isEqual(oldCustomer, response.data.data)) {
        yield put(changeCustomer(response.data.data));
      }

      callback(undefined);
    } else {
      throw new Error('Error updating customer info');
    }
  } catch (error) {
    callback(parseRequestError(error));
  }
}

export function* deleteCustomerCard(
  cardId: string,
  callback: (err?: string) => void
) {
  const user: User = yield select((state: AppState) => state.user);

  if (!user.access_token || !user.stripe_id) return;

  try {
    const response = yield call(
      api.delete,
      `api/stripe/customer/${user.stripe_id}/delete-card/${cardId}?lang=en`,
      {
        headers: {
          Authorization: user.access_token,
        },
      }
    );

    if (response.data && response.data.code === 200) {
      yield call(getCustomerInfo);

      callback(undefined);
    } else {
      throw new Error('Error getting customer info');
    }
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * Add Gift Card Balance
 */
export function* addGiftCard(
  gift_card_id: string,
  callback: (err?: string, description?: string) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    const response = yield call(
      api.post,
      'api/add-giftcard-balance?lang=en',
      { gift_card_id },
      {
        headers: {
          Authorization: accessToken,
        },
      }
    );

    if (response.data && !!response.data.message) {
      yield put(changeCustomer({ ...response.data.data }));
      callback(undefined, 'Gift Card successfully redeemed');
    } else {
      throw new Error('Error adding Gift Card');
    }
  } catch (error) {
    callback(parseRequestError(error));
  }
}
