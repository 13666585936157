import {Button, Divider, Icon, List, Modal, Spin, Table} from 'antd';
import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useHistory} from "react-router";
import {Link} from "react-router-dom";
import {Dispatch} from "redux";
import BaseLayout from '../../components/BaseLayout/BaseLayout';
import {GuestUserInterface} from "../../interfaces/guestUser";
import {AppState, sagaMiddleware} from '../../store';
import {BaseAction} from "../../store/actionTypes";
import {selectGuestUser} from "../../store/ducks/guesUsers";
import {deleteGuestUser, getGuestUsers} from "../../store/sagas/guestUser";
import "./GuestUser.scss"

interface Props {
  dispatchSelectGuestUser: (guestUser: GuestUserInterface) => void;
}

const GuestUsersPage: React.FC<Props> = ({dispatchSelectGuestUser}) => {
  const history = useHistory();

  let [loading, setLoading] = useState<boolean>(false);
  let [guestUsers, setGuestUsers] = useState<any[]>([]);

  useEffect(() => {
    setLoading(true);

    sagaMiddleware.run<any>(
      getGuestUsers,
      (err: string | null, data?: any[]) => {
        setLoading(false);

        if (!!err) {
          Modal.error({
            title: 'Error',
            content: err
          });

          return;
        }

        if (!!data) {
          setGuestUsers(data);
        }
      });
  }, []);

  const handleDelete = useCallback((id: number) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this Guest User?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      width: 300,
      icon: 'warning',
      onOk() {
        setLoading(true);

        sagaMiddleware.run<any>(
          deleteGuestUser,
          id,
          (err: string | null, data?: any[]) => {
            setLoading(false);

            if (!!err) {
              Modal.error({
                title: 'Error',
                content: err
              });

              return;
            }

            setGuestUsers((guestUsers: any) => guestUsers.filter((guestUser: any) => guestUser.id !== id ));
          });
      },
    });
  }, []);

  const handleUpdate = useCallback(
    (guestUser: any) => {
      dispatchSelectGuestUser({
        fullName: guestUser.full_name,
        email: guestUser.email,
        permission: guestUser.permission,
        family_relationship_id: guestUser.family_relationship_id
      });
      history.push(`/guest-users/${guestUser.id}`);
    },
    [history, dispatchSelectGuestUser]
  );

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Permission',
      dataIndex: 'permission',
      key: 'permission',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text: any, record: any) => (
        <span>
        <Button
          type="primary"
          shape="circle"
          icon="edit"
          onClick={() => handleUpdate(record)}
          disabled={loading}
        />
        <Divider type="vertical"/>
        <Button
          type="danger"
          shape="circle"
          icon="delete"
          onClick={() => handleDelete(record.id)}
          disabled={loading}
        />
      </span>
      ),
    },
  ];

  return (
    <BaseLayout title="BabyPage - Guest Users" pageTitle="Guest Users" hideHeaderChildMenu hideChildMenu>
      <div className="container my-3">
        {loading && (
          <div className="col-12 d-flex justify-content-center">
            <Spin
              indicator={<Icon type="loading" style={{fontSize: 24}} spin/>}
            />
          </div>
        )}
        <div className='row justify-content-center'>
          <div className='col-12 col-md-12'>
            {!loading && !guestUsers && (
              <h3 className="text-center">You don't have guest users yet</h3>
            )}
            {!loading && guestUsers && (
              <>
                <List className="mobile-only mobile-guest-user-list color-gray"
                pagination={{pageSize:5}}
                      itemLayout="horizontal"
                      dataSource={guestUsers}
                      renderItem={(item: any) => (
                        <List.Item
                          actions={[
                            <Button
                              type="primary"
                              shape="circle"
                              icon="edit"
                              onClick={() => handleUpdate(item)}
                              disabled={loading}
                            />,
                            <Button
                              type="danger"
                              shape="circle"
                              icon="delete"
                              onClick={() => handleDelete(item.id)}
                              disabled={loading}
                            />
                          ]}
                        >
                          <List.Item.Meta
                            title={item.email}
                          />
                          {item.permission}
                        </List.Item>
                      )}
                      style={{
                        color: 'rgba(0, 0, 0, 0.45)',
                        wordBreak: 'break-word',
                      }}
                />
                <Table className="mobile-hidden guest-user-table" columns={columns} dataSource={guestUsers} rowKey={"id"} pagination={{ pageSize:5 }}/>
              </>
            )}
          </div>
          <div className='col-8 col-md-4 col-lg-3 mb-4'
               style={{
                 position: 'absolute',
                 bottom: 90,
                 width: '100%'
               }}>
            <Link to='/guest-users/create'>
              <Button type='primary' ghost shape='round' className='w-100'>
                Invite new user
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: Dispatch<BaseAction>) => ({
  dispatchSelectGuestUser: (payload: GuestUserInterface) => dispatch(selectGuestUser(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GuestUsersPage);
