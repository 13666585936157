import { Baby, QuestionItem } from "../interfaces";
import { capitalizeTexts, replaceAll, capitalizeRawPronoun } from "./string";

export const MALE_PRONOUN = "he";
export const FEMALE_PRONOUN = "she";
export const MALE_POSSESSIVE_PRONOUN = "his";
export const FEMALE_POSSESSIVE_PRONOUN = "her";
export const MALE_CONTRACTION_PRONOUN = "he's";
export const FEMALE_CONTRACTION_PRONOUN = "she's";
export const MALE_REFLEXIVE_PRONOUN = "himself";
export const FEMALE_REFLEXIVE_PRONOUN = "herself";
export const MALE_ACCUSATIVE_PRONOUN = "him";
export const FEMALE_ACCUSATIVE_PRONOUN = "her";
export const RAW_PRONOUN = "[HE/SHE]";
export const RAW_POSSESSIVE_PRONOUN = "[HIS/HER]";
export const RAW_CONTRACTION_PRONOUN = "[HE'S/SHE'S]";
export const RAW_REFLEXIVE_PRONOUN = "[HIMSELF/HERSELF]";
export const RAW_ACCUSATIVE_PRONOUN = "[HIM/HER]";
export const RAW_NAME_SPACE = "[BABY NAME]";
export const RAW_NAME = "[BABYNAME]";
export const RAW_ANSWER = "[ANSWER]";
export const RAW_WEIGHTLBS = "[WEIGHTLBS]";
export const RAW_WEIGHTOZ = "[WEIGHTOZ]";
export const RAW_HEIGHT = "[HEIGHT]";
export const POSITIVE_INVISIBLE_ANSWERS = [
  "yes",
  "yes.",
  "yes!",
  "yep",
  "yep.",
  "yep!",
  "yap",
  "yap.",
  "yap!",
  "did",
  "Barely",
  "Almost"
];
export const NEGATIVE_ANSWERS = [
  "not yet",
  "not yet.",
  "not yet!",
  "no",
  "no.",
  "no!",
  "nope",
  "nope.",
  "nope!",
  "did not",
  "not this year"
];

/**
 * Replace Pronouns of a String
 * Exs:
 *  - [BABYNAME] => Gabriel
 *  - [MALE_PRONOUN] => he
 */
export const replacePronouns = (initialValue: string, baby: Baby): string => {
  let newStringValue = initialValue;

  const gender = baby.baby_gender;
  const genderIsMale = gender === 1;
  const babyName = baby.baby_first_name.charAt(0).toUpperCase() + baby.baby_first_name.slice(1);;

  const genderProunoun = genderIsMale ? MALE_PRONOUN : FEMALE_PRONOUN;

  const possessiveProunoun = genderIsMale
    ? MALE_POSSESSIVE_PRONOUN
    : FEMALE_POSSESSIVE_PRONOUN;

  const reflexivePronoun = genderIsMale
    ? MALE_REFLEXIVE_PRONOUN
    : FEMALE_REFLEXIVE_PRONOUN;

  const accusativePronoun = genderIsMale
    ? MALE_ACCUSATIVE_PRONOUN
    : FEMALE_ACCUSATIVE_PRONOUN;

  const contractionPronoun = genderIsMale
    ? MALE_CONTRACTION_PRONOUN
    : FEMALE_CONTRACTION_PRONOUN;

  if (initialValue.indexOf(RAW_NAME) !== -1) {
    newStringValue = replaceAll(newStringValue, RAW_NAME, babyName);
  }

  if (initialValue.indexOf(RAW_NAME_SPACE) !== -1) {
    newStringValue = replaceAll(newStringValue, RAW_NAME_SPACE, babyName);
  }

  if (initialValue.indexOf(RAW_PRONOUN) !== -1) {
    newStringValue = replaceAll(newStringValue, RAW_PRONOUN, genderProunoun);
    newStringValue = capitalizeRawPronoun(newStringValue);
  }

  if (initialValue.indexOf(RAW_POSSESSIVE_PRONOUN) !== -1) {
    newStringValue = replaceAll(
      newStringValue,
      RAW_POSSESSIVE_PRONOUN,
      possessiveProunoun
    );
  }

  if (initialValue.indexOf(RAW_REFLEXIVE_PRONOUN) !== -1) {
    newStringValue = replaceAll(
      newStringValue,
      RAW_REFLEXIVE_PRONOUN,
      reflexivePronoun
    );
  }

  if (initialValue.indexOf(RAW_ACCUSATIVE_PRONOUN) !== -1) {
    newStringValue = replaceAll(
      newStringValue,
      RAW_ACCUSATIVE_PRONOUN,
      accusativePronoun
    );
  }

  if (initialValue.indexOf(RAW_CONTRACTION_PRONOUN) !== -1) {
    newStringValue = replaceAll(
      newStringValue,
      RAW_CONTRACTION_PRONOUN,
      contractionPronoun
    );
  }

  return newStringValue;
};

export const cleanQuestions = (
  questions: QuestionItem[],
  baby: Baby
): QuestionItem[] =>
  questions.map((question: QuestionItem) => {
    question.question = replacePronouns(question.question, baby);
    question.answers = !!question.answers
      ? question.answers.map((answer: string) => replacePronouns(answer, baby))
      : [];
    question.export = replacePronouns(question.export, baby);

    return question;
  });

export const exportAnswer = (
  answers: string[],
  exportString: string
): string => {
  exportString =
    answers.length === 1
      ? exportSingleAnswer(answers[0], exportString)
      : exportMultipleAnswers(answers, exportString);
  return exportString;
};

export const exportSingleAnswer = (
  answer: string,
  exportString: string
): string => {
  if (NEGATIVE_ANSWERS.includes(answer.toLocaleLowerCase())) {
    exportString = replaceAll(exportString, exportString, "");
  } else if (POSITIVE_INVISIBLE_ANSWERS.includes(answer.toLocaleLowerCase())) {
    return exportString;
  } else if (exportString.indexOf(RAW_ANSWER) !== -1) {
    return replaceAll(exportString, RAW_ANSWER, answer);
  }
  return exportString;
};

export const exportMultipleAnswers = (
  answers: string[],
  exportString: string
): string => {
  if (exportString.indexOf(RAW_ANSWER) !== -1) {
    answers = answers.sort();
    let finalAnswers: string = "";

    answers.forEach((answer: string, index: number) => {
      finalAnswers += `${answer}${
        index === answers.length - 1
          ? ""
          : index === answers.length - 2
          ? " and "
          : ", "
      }`;
    });

    return replaceAll(exportString, RAW_ANSWER, finalAnswers);
  }
  return exportString;
};

export const exportGrowthAnswer = (
  height: number | null,
  lbs: number | null,
  oz: number | null,
  baby: Baby
): string | null => {
  const babyNameIs = `${baby.baby_first_name} is`;
  let exportString = "";

  if (!lbs && !!height) {
    exportString = `${babyNameIs} ${height} inches.`;
  } else if (!!lbs && !!height && !oz) {
    exportString = `${babyNameIs} ${height} inches and ${lbs} lbs.`;
  } else if (!!lbs && !!height && !!oz) {
    exportString = `${babyNameIs} ${height} inches and ${lbs} lbs ${oz} oz.`;
  } else if (!height && !!lbs && !oz) {
    exportString = `${babyNameIs} ${lbs} lbs.`;
  } else if (!height && !!lbs && !!oz) {
    exportString = `${babyNameIs} ${lbs} lbs ${oz} oz.`;
  } else if (!height && !lbs && !!oz) {
    exportString = `${babyNameIs} 0 lbs ${oz} oz.`;
  } else {
    return null;
  }

  return exportString;
};

export const multipleAnswers = (question_export: string, answer_string: string[]) => {
  let joinedArray = answer_string.join(', ');
  let concatenatedAnswer = `${question_export.replace('[ANSWER]', joinedArray)}`
  return concatenatedAnswer;
}
