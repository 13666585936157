import { call, put, select } from 'redux-saga/effects';
import { AppState } from '..';
import api from '../../api';
import { User } from '../../interfaces';
import { isArrayEqual } from '../../utils/array';
import { parseRequestError } from '../../utils/error';
import { getGrowthChart, GrowthChartState } from '../ducks/growthChart';

/**
 * This function fetch all babies's Growth Charts
 **/
export function* fetchGrowthCharts(callback?: (error: string | null) => void) {
  const user: User = yield select((state: AppState) => state.user);

  if (!user.access_token || user.user_level === 3) return;

  try {
    const response = yield call(api.get, 'api/get-baby-growth-charts?lang=en', {
      headers: {
        Authorization: user.access_token,
      },
    });

    if (response.data && response.data.code === 200) {
      const data = response.data.data;
      const growthChart: GrowthChartState = yield select(
        (state: AppState) => state.growthChart
      );

      if (!isArrayEqual(growthChart, data)) {
        yield put(getGrowthChart(data));
      }
    }

    if (callback) callback(null);
  } catch (error) {
    if (callback) callback(parseRequestError(error));
  }
}

export function* getGrowthChartById(
  id: number,
  callback: (error: string | null, growthChart?: any) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    const response = yield call(
      api.get,
      `api/get-baby-growth-chart/${id}?lang=en`,
      {
        headers: {
          Authorization: accessToken,
        },
      }
    );

    callback(null, response.data.data);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

export function* saveGrowthChartSaga(
  body: any,
  callback: (error: string | null) => void
) {
  const user: User = yield select((state: AppState) => state.user);

  if (!user.access_token) return;

  try {
    yield call(
      api[!!body.editId ? 'put' : 'post'],
      `api/${!!body.editId ? 'update' : 'save'}-baby-growth-chart?lang=en`,
      { ...body, fontsize: null, frame: null, parent_id: user.id },
      {
        headers: {
          Authorization: user.access_token,
        },
      }
    );

    yield call(fetchGrowthCharts);
    callback(null);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

export function* deleteGrowthChart(
  id: number,
  callback: (error: string | null) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    yield call(api.delete, `api/delete-baby-growth-chart/${id}?lang=en`, {
      headers: {
        Authorization: accessToken,
      },
    });

    yield call(fetchGrowthCharts);
    callback(null);
  } catch (error) {
    callback(parseRequestError(error));
  }
}
