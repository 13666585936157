import { useEffect, useState } from "react";

type useManageModalType = [boolean, (isOpen: boolean) => void];

export const useManageModal = (): useManageModalType => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!open) {
      document.documentElement.style.overflowY = "visible";
    } else {
      document.documentElement.style.overflowY = "hidden";
    }
  }, [open]);

  const onToggleModal = (isOpen: boolean): void => {
    setOpen(!!isOpen);
  };

  return [open, onToggleModal];
};
