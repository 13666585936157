import React from "react";

interface Props {
  isSelected?: boolean;
}


const MilestonesIcon : React.FC<Props> = ({isSelected}) => {
  if(isSelected) {
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="29"
      viewBox="0 0 20 29"
      className="mx-3 mx-sm-4"
    >
      <g fill="none" fillRule="evenodd">
        <circle
          cx="2"
          cy="2"
          r="2"
          fill="#FFAFA5"
          transform="translate(8 1)"
        ></circle>
        <path
          stroke="#F7ADA5"
          d="M11.333 12.55c0 .802-.597 1.45-1.333 1.45s-1.333-.648-1.333-1.45C8.667 11.75 10 10 10 10s1.333 1.75 1.333 2.55z"
        ></path>
        <path
          stroke="#F7ADA5"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M18 16.784v1.06c0 .19-.021.368-.053.536-.085.43-.275.818-.551 1.133-.032.032-.053.063-.085.095a2.622 2.622 0 01-1.981.892 2.663 2.663 0 01-2.607-2.12 2.18 2.18 0 01-.063-.536c0 .19-.011.368-.053.536A2.663 2.663 0 0110 20.5a2.674 2.674 0 01-2.617-2.12 2.867 2.867 0 01-.053-.536c0 .19-.021.368-.053.536A2.674 2.674 0 014.66 20.5a2.635 2.635 0 01-1.929-.83 2.507 2.507 0 01-.678-1.29A2.187 2.187 0 012 17.844v-1.06C2 15.798 2.996 15 4.215 15h11.57c1.219 0 2.215.798 2.215 1.784z"
        ></path>
        <path
          stroke="#F7ADA5"
          d="M17.502 26.5a.998.998 0 01.706 1.707.994.994 0 01-.706.293H2.498a.998.998 0 01-.706-1.707.995.995 0 01.706-.293zM17 20v6M3 20v6"
        ></path>
      </g>
    </svg>
    )
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="29"
        viewBox="0 0 20 29"
        className="mx-3 mx-sm-4"
    >
      <g fill="none" fillRule="evenodd" stroke="#FFF">
        <path d="M11.333 12.55c0 .802-.597 1.45-1.333 1.45s-1.333-.648-1.333-1.45C8.667 11.75 10 10 10 10s1.333 1.75 1.333 2.55z"></path>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M18 16.784v1.06c0 .19-.021.368-.053.536-.085.43-.275.818-.551 1.133-.032.032-.053.063-.085.095a2.622 2.622 0 01-1.981.892 2.663 2.663 0 01-2.607-2.12 2.18 2.18 0 01-.063-.536c0 .19-.011.368-.053.536A2.663 2.663 0 0110 20.5a2.674 2.674 0 01-2.617-2.12 2.867 2.867 0 01-.053-.536c0 .19-.021.368-.053.536A2.674 2.674 0 014.66 20.5a2.635 2.635 0 01-1.929-.83 2.507 2.507 0 01-.678-1.29A2.187 2.187 0 012 17.844v-1.06C2 15.798 2.996 15 4.215 15h11.57c1.219 0 2.215.798 2.215 1.784z"
        ></path>
        <path d="M17.502 26.5a.998.998 0 01.706 1.707.994.994 0 01-.706.293H2.498a.998.998 0 01-.706-1.707.995.995 0 01.706-.293zM17 20v6M3 20v6"></path>
      </g>
    </svg>
    );
  }
  
}

export default MilestonesIcon;
