import { call, put } from 'redux-saga/effects';
import api from '../../api';
import { clientId, clientSecret } from '../../env';
import { parseRequestError } from '../../utils/error';
import { changeAdmin } from '../ducks/admin';

export function* authAdmin(
  body: { email: string; password: string },
  callback: (response: any, error?: string) => void
) {
  try {
    const response = yield call(api.post, 'api/admin/login', {
      username: body.email,
      password: body.password,
      grant_type: 'password',
      lang: 'en',
      operative_system: 'web',
      token_device: '',
      client_id: clientId,
      client_secret: clientSecret,
    });

    yield put(
      changeAdmin({
        ...response.data.data,
      })
    );

    callback(response.data.data);
  } catch (error) {
    callback({}, parseRequestError(error));
  }
}
