import React, { useEffect } from "react";
import { Cover } from "../../interfaces";
import BookCover from "../BookCover/BookCover";
import EditTextFont from "../EditTextFont/EditTextFont";
import { Button } from "antd";
import ArrowNext from "../Icons/ArrowNext";
import { useWindowSize } from "../../hooks/WindowsSize";
import { newFont } from "../../interfaces/font";

interface Props {
  bookText: {
    title: string;
    subtitle: string;
    font: string;
    font_id:number;
  };
  setBookText: React.Dispatch<
    React.SetStateAction<{
      title: string;
      subtitle: string;
      font: string;
      font_id:number;
    }>
  >;
  selectedCover: Cover | undefined;
  loading: boolean;
  isPlus: boolean;
  setPlusOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setControl: React.Dispatch<React.SetStateAction<{valid: boolean, step:number}>>;
  nextStep:React.EffectCallback;
  fonts: newFont[];
}

const EditText: React.FC<Props> = ({
  bookText,
  setBookText,
  selectedCover,
  loading,
  isPlus,
  setPlusOpen,
  setControl,
  nextStep,
  fonts
}) => {
  const coverProps = {
    bookText,
    selectedCover,
    loading
  };
  const [width] = useWindowSize();
  let responsiveButtonBarClass = width <= 450 ? "button-bar-book" : ""

  return (
    <div className="py-2">
      <div className="container">
        <div className="row justify-content-start align-items-center py-md-4 py-0">
          <div className="col-12 col-sm-6">
            <BookCover {...coverProps} />
          </div>
          <div className="col-12 col-sm-6">
            <EditTextFont bookText={bookText} setBookText={setBookText} isPlus={isPlus} setPlusOpen={setPlusOpen} fonts={fonts} />
          </div>
        </div>
        <div className={responsiveButtonBarClass}>
        <div className="d-flex flex-row justify-content-center my-4">
            <div className="col-lg-3 col-6">
              <Button type="primary" ghost shape="round" className="w-100 book-creation-buttons" onClick={() => {
                setControl((prev)  => {
                  return {
                    ...prev,
                    step:prev.step -1,
                  }  
                })
              }}>
                Previous
              </Button>     
            </div>
            <div className="col-lg-3 col-6">
              <Button
                type="primary"
                ghost
                shape="round"
                className="w-100 green-button book-creation-buttons d-flex flex-row align-items-center justify-content-center"
                onClick={nextStep}
              >
                Next
                <ArrowNext />
              </Button>
            </div>
          </div>
        </div>  
      </div>
    </div>
  );
};

export default EditText;
