import {
  Button,
  Icon,
  Input,
  notification,
  Row,
  Spin,
  Table,
  Tooltip
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Font, FontPaginator } from "../../../../interfaces/font";
import { sagaMiddleware } from "../../../../store";
import { getFonts } from "../../../../store/sagas/font";

const FontListPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [searchParameter, setSearchParameter] = useState("");
  const [fontPaginator, setFontPaginator] = useState<undefined | FontPaginator>(
    undefined
  );
  const [debounce, setDebounce] = useState<NodeJS.Timeout>();
  const history = useHistory();

  useEffect(() => {
    setLoading(true);

    const page = 1;

    sagaMiddleware.run<any>(
      getFonts,
      page,
      "",
      (err?: string, response?: FontPaginator) => {
        setLoading(false);

        if (!!err) {
          notification.error({
            message: "Error",
            description: err
          });

          return;
        }

        setFontPaginator(response);
      }
    );
  }, []);

  const filterFonts = useCallback(
    (searchParameter: string = "", page: number = 1) => {
      setLoading(true);

      sagaMiddleware.run<any>(
        getFonts,
        page,
        searchParameter,
        (err?: string, response?: FontPaginator) => {
          setLoading(false);

          if (!!err) {
            notification.error({
              message: "Error",
              description: err
            });

            return;
          }

          setFontPaginator(response);
        }
      );
    },
    []
  );

  const handleFilterChange = useCallback(
    (search: string = "") => {
      setSearchParameter(search);

      if (debounce) clearTimeout(debounce);

      setDebounce(
        setTimeout(() => {
          filterFonts(search, 1);
        }, 100)
      );
    },
    [debounce, filterFonts]
  );

  return (
    <div className="m-3">
      <h1 className="title">Fonts</h1>
      <div className="row justify-content-center align-items-center mx-3">
        <div className="col-8 col-lg-4 my-1">
          <Input
            placeholder="Search"
            value={searchParameter}
            onChange={e => handleFilterChange(e.target.value)}
            className="w-100"
          />
        </div>
      </div>
      <Row style={{ textAlign: "center" }}>
        <Button
          type="primary"
          disabled={loading}
          style={{ margin: "10px" }}
          onClick={() => {
            history.push("/admin/fonts/new");
          }}
        >
          CREATE A FONT
        </Button>
      </Row>
      {loading ? (
        <div className="col-12 d-flex justify-content-center">
          <Spin style={{ fontSize: 36, margin: 50 }} />
        </div>
      ) : (
        <>
          {fontPaginator !== undefined && (
            <Table
              dataSource={fontPaginator.data}
              loading={loading}
              bordered
              pagination={{
                current: fontPaginator.current_page,
                total: fontPaginator.total,
                pageSize: 10
              }}
              onChange={e => {
                filterFonts(searchParameter, e.current || 1);
              }}
              className="my-3 elevation-5"
              rowKey={(record: Font) => record.id.toString()}
            >
              <Table.Column
                title="Id"
                dataIndex="id"
                key="id"
                ellipsis
                render={(text: any) => (
                  <Tooltip title={`${text}`}>
                    <span>{text}</span>
                  </Tooltip>
                )}
              />
              <Table.Column
                title="Family"
                dataIndex="family"
                key="family"
                ellipsis
                render={(text: any) => (
                  <Tooltip title={`${text}`}>
                    <span>{text}</span>
                  </Tooltip>
                )}
              />
              <Table.Column
                title="Source"
                dataIndex="source"
                key="source"
                ellipsis
                render={(text: any) => (
                  <Tooltip title={`${text}`}>
                    <span>{text}</span>
                  </Tooltip>
                )}
              />
              <Table.Column
                title="Format"
                dataIndex="format"
                key="format"
                ellipsis
                render={(text: any) => (
                  <Tooltip title={`${text}`}>
                    <span>{text}</span>
                  </Tooltip>
                )}
              />
              <Table.Column
                title="Is Active?"
                dataIndex="is_active"
                key="is_active"
                ellipsis
                align="center"
                render={(text: any) =>
                  !!text ? (
                    <Icon
                      type="check-circle"
                      theme="filled"
                      style={{ color: "#73d13d", fontSize: "30px" }}
                    />
                  ) : (
                    <Icon
                      type="close-circle"
                      theme="filled"
                      style={{ color: "#f5222d", fontSize: "30px" }}
                    />
                  )
                }
              />
              <Table.Column
                title="Is PLUS?"
                dataIndex="plus"
                key="plus"
                ellipsis
                align="center"
                render={(text: any) =>
                  !!text ? (
                    <Icon
                      type="check-circle"
                      theme="filled"
                      style={{ color: "#73d13d", fontSize: "30px" }}
                    />
                  ) : (
                    <Icon
                      type="close-circle"
                      theme="filled"
                      style={{ color: "#f5222d", fontSize: "30px" }}
                    />
                  )
                }
              />
              <Table.Column
                title="Actions"
                dataIndex="id-actions"
                render={(text: any, record: any) => (
                  <Button
                    icon="eye"
                    type="primary"
                    onClick={() => history.push(`/admin/fonts/${record.id}`)}
                  />
                )}
              />
            </Table>
          )}
        </>
      )}
    </div>
  );
};

export default FontListPage;
