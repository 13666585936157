import { Icon } from "antd";
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  SortableContainer,
  SortableElement,
  SortEndHandler,
} from "react-sortable-hoc";
import { highRes } from "../../env";
import { SaveBabyPagePhoto } from "../../interfaces";
import { dynamicSize, getProportionalSize } from "../../utils/size";
import { getBase64 } from "../../utils/image";
import "./Layout4A.scss";
import { Background } from "../../interfaces/background";
import "./Layout1A.scss";

interface Props {
  photos: SaveBabyPagePhoto[];
  onClickPhoto?: (index: number, photo: SaveBabyPagePhoto) => void;
  onSortEnd: SortEndHandler;
  ignoreMargin?: boolean;
  loading: boolean;
  backgroundSelected?: Background;
}

interface LayoutProps {
  photo: SaveBabyPagePhoto;
  photoIndex: number;
  onClickPhoto?: (index: number, photo: SaveBabyPagePhoto) => void;
  padding?: string;
  height?: string;
  photos: SaveBabyPagePhoto[];
}

export const getPlaceholderB64 = async () => {
  const placeHolderFile = (
    await axios.get(
      `${""}${"https://babypage-staging.s3.amazonaws.com/public/missingImagePlaceholder/add_babypage_placeholder.png"}`,
      { responseType: "blob" },
    )
  ).data;
  const placeHolderB64 = await getBase64(placeHolderFile);

  return placeHolderB64;
};

const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

const SortableItem = SortableElement(
  ({
    photo,
    photoIndex,
    onClickPhoto,
    padding = "0px",
    height = "100%",
    photos,
  }: LayoutProps) => {
    const checkMissing = () => {
      if (photo?.base64_cropped !== "" && photo?.missing) {
        return false;
      } else if (photo?.base64_cropped !== "" && !photo?.missing) {
        return true;
      }
    };
    const [placeHolderB64, setPlaceHolderB64] = useState<string | null>(null);

    useEffect(() => {
      // Fetch the placeholder when the component mounts
      getPlaceholderB64()
        .then((result) => {
          if (result instanceof ArrayBuffer) {
            // Convert ArrayBuffer to base64 string
            const base64String = arrayBufferToBase64(result);
            setPlaceHolderB64(base64String); // Save the base64 string in state
          } else if (typeof result === "string") {
            setPlaceHolderB64(result); // If it's already a string, directly set it
          } else {
            console.warn("Unexpected result type:", result);
          }
        })
        .catch((error) => {
          console.error("Error getting placeholder:", error);
        });
    }, []);

    return checkMissing() ? (
      <div
        key={photoIndex}
        onClick={() => {
          if (onClickPhoto) {
            if (photo) {
              onClickPhoto(photoIndex, photo);
            } else {
              onClickPhoto(photoIndex, {
                base64_cropped: "",
                side: "left",
              });
            }
          }
        }}
        className="d-flex align-items-center"
        style={{ padding, height, width: height }}
      >
        <img
          src={
            photo?.base64_cropped
              ? photo.base64_cropped
              : placeHolderB64
                ? placeHolderB64
                : undefined
          }
          alt="Baby 1"
          className="baby-photo w-100 h-100"
        />
      </div>
    ) : (
      <div
        key={photoIndex}
        onClick={() => (!!onClickPhoto ? onClickPhoto(photoIndex, photo) : {})}
        className="d-flex align-items-center"
        style={{ padding, height, width: height }}
      >
        <div className="add-photo w-100 h-100">
          <Icon type="plus-circle" theme="filled" style={{ fontSize: 36 }} />
        </div>
      </div>
    );
  },
);

// @ts-ignore
const SortableLayout = SortableContainer((props) => props.children);

const Layout4A: React.FC<Props> = ({
  photos,
  onClickPhoto,
  ignoreMargin,
  onSortEnd,
  loading,
  backgroundSelected,
}) => {
  return (
    <div
      className="w-100 h-100"
      style={{
        backgroundColor: !!backgroundSelected
          ? backgroundSelected.color
          : "#ffffff",
      }}
    >
      <div style={{ width: "100%", height: "100%" }}>
        {!loading ? (
          <div
            className="click-through"
            style={{
              width: "93%",
              height: "93%",
              border: "2px dashed #e70c0c",
              zIndex: 995,
              position: "absolute",
              transform: "translate(-50%, -50%)",
              top: "50%",
              left: "50%",
            }}
          />
        ) : null}
        <SortableLayout pressDelay={200} onSortEnd={onSortEnd} axis="xy">
          <div className="Layout4A h-100">
            <SortableItem
              index={0}
              photoIndex={0}
              photo={photos[0]}
              onClickPhoto={onClickPhoto}
              height="50%"
              padding={
                ignoreMargin
                  ? "0px"
                  : `0px ${loading ? 5 : 1.5}px ${loading ? 5 : 1.5}px 0px`
              }
              photos={photos}
            />
            <SortableItem
              index={1}
              photoIndex={1}
              photo={photos[1]}
              onClickPhoto={onClickPhoto}
              height="50%"
              padding={
                ignoreMargin
                  ? "0px"
                  : `0px 0px ${loading ? 5 : 1.5}px ${loading ? 5 : 1.5}px`
              }
              photos={photos}
            />
            <SortableItem
              index={2}
              photoIndex={2}
              photo={photos[2]}
              onClickPhoto={onClickPhoto}
              height="50%"
              padding={
                ignoreMargin
                  ? "0px"
                  : `${loading ? 5 : 1.5}px ${loading ? 5 : 1.5}px 0px 0px`
              }
              photos={photos}
            />
            <SortableItem
              index={3}
              photoIndex={3}
              photo={photos[3]}
              onClickPhoto={onClickPhoto}
              height="50%"
              padding={
                ignoreMargin
                  ? "0px"
                  : `${loading ? 5 : 1.5}px 0px 0px ${loading ? 5 : 1.5}px`
              }
              photos={photos}
            />
          </div>
        </SortableLayout>
      </div>
    </div>
  );
};

export default Layout4A;
