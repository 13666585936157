import { Modal } from "antd";
import { PAGE_URL } from "../../env";
const { confirm } = Modal;

export interface MenuItemObject {
  key: string;
  icon: string;
  text: string;
  newTab?: boolean;
  hideFromUserGift?: boolean;
  onClick?: Function;
  badge?: boolean;
  hideFromGuestUser?: boolean;
  cssClass?: string;
  hideFromFreeUser?: boolean;
  counterBadge?: {
    left: string;
    counter: string;
  };
}

export const DesktopMenuItems: MenuItemObject[] = [
  {
    key: "/",
    icon: "dashboard",
    text: "Dashboard",
    hideFromUserGift: true,
  },
  {
    key: "/milestones",
    icon: "calendar",
    text: "Milestones",
    hideFromUserGift: true,
    cssClass: "disabled",
  },
  {
    key: "/babypages",
    icon: "layout",
    text: "BabyPages",
    hideFromUserGift: true,
  },
  {
    key: "/books",
    icon: "book",
    text: "Books",
    hideFromUserGift: true,
  },
  {
    key: "/store",
    icon: "shopping",
    text: "Store",
  },
];

export const DesktopUserItems: (logout: Function, showGuestUserModal: Function) => MenuItemObject[] = (logout, showGuestUserModal) => [
  {
    key: '/switch-account',
    icon: 'retweet',
    text: 'Switch Account',
    onClick: () => showGuestUserModal(),
  },
  {
    key: '/profile',
    icon: 'user',
    text: 'My Account',
    hideFromGuestUser: true,
  },
  {
    key: "/growth-chart",
    icon: "line-chart",
    text: "Growth Chart",
    hideFromUserGift: true,
    hideFromGuestUser: true,
  },
  {
    key: '/giftcards',
    icon: 'gift',
    text: 'Gift Cards',
    hideFromGuestUser: true,
  },
  {
    key: '/guest-users',
    icon: 'team',
    text: 'Manage guest users',
    hideFromGuestUser: true,
    hideFromFreeUser: true,
  },
  {
    key: `/about`,
    icon: "info-circle",
    text: "About",
  },
  {
    key: `${PAGE_URL}support`,
    icon: "question-circle",
    text: "Support",
    newTab: true,
  },
  {
    key: "logout",
    icon: "logout",
    text: "Sign Out",
    onClick: () =>
      confirm({
        title: "Are you sure you want to logout?",
        onOk() {
          logout();
        },
      }),
  },
];

export const MobileMenuItems: MenuItemObject[] = [
  {
    key: '/',
    icon: 'dashboard',
    text: 'Dashboard',
    hideFromUserGift: true,
  },
  {
    key: '/milestones',
    icon: 'calendar',
    text: 'Milestones',
    hideFromUserGift: true,
    cssClass: "disabled",
  },
  {
    key: '/babypages',
    icon: 'layout',
    text: 'BabyPages',
    hideFromUserGift: true,
  },
  {
    key: '/books',
    icon: 'book',
    text: 'Books',
    hideFromUserGift: true,
  },
  {
    key: '/store',
    icon: 'shopping',
    text: 'Store',
  },
  {
    key: '/giftcards',
    icon: 'gift',
    text: 'Gift Cards',
  },
  {
    key: '/cart',
    icon: 'shopping-cart',
    text: 'Cart',
    counterBadge: {
      left: '90px',
      counter: 'storeLength',
    },
  },
  {
    key: '/my-orders',
    icon: 'shop',
    text: 'Orders',
    counterBadge: {
      left: '75px',
      counter: 'alexandersOrderCounter',
    },
  },
  {
    key: '/guest-users',
    icon: 'team',
    text: 'Manage guest users',
    hideFromGuestUser: true,
    hideFromFreeUser: true,
  },
];

export const MobileUserItems: (logout: Function) => MenuItemObject[] = logout => [
  {
    key: '/profile',
    icon: 'user',
    text: 'Settings',
    hideFromGuestUser: true,
  },
  {
    key: '/growth-chart',
    icon: 'line-chart',
    text: 'Growth Chart',
    hideFromUserGift: true,
    hideFromGuestUser: true,
  },
  {
    key: `/about`,
    icon: 'info-circle',
    text: 'About',
  },
  {
    key: 'logout',
    icon: 'logout',
    text: 'Sign Out',
    onClick: () =>
      confirm({
        title: 'Are you sure you want to logout?',
        onOk() {
          logout();
        },
      }),
  },
];
