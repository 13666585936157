import { Icon, Input, Modal } from 'antd';
import React, { useCallback, useState } from 'react';
import { sagaMiddleware } from '../../store';
import { addGiftCard } from '../../store/sagas/customer';

interface Props {
  visible: boolean;
  finishSuccess: (description: string) => void;
  cancel: () => void;
}

const RedeemCode: React.FC<Props> = ({ visible, finishSuccess, cancel }) => {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');

  const redeemCode = useCallback(
    (code: string) => {
      setLoading(true);
      sagaMiddleware.run<any>(
        addGiftCard,
        code,
        (err?: string, description?: string) => {
          setLoading(false);
          if (!!err) {
            Modal.error({
              title: 'Error',
              content: err
            });
          } else if (!!description) {
            finishSuccess(description);
            setCode('');
          }
        }
      );
    },
    [finishSuccess]
  );

  return (
    <Modal
      visible={visible}
      onCancel={cancel}
      onOk={() => redeemCode(code)}
      okText="Redeem Your Gift Card"
      okButtonProps={{
        disabled: loading || code.length === 0
      }}
    >
      <div className="mx-auto d-table">
        <Icon type="gift" style={{ fontSize: 96, color: '#000000' }} />
      </div>
      <p className="text-center">Enter Your Gift Card Code</p>
      <label>Code</label>
      <Input
        onChange={e => setCode(e.target.value)}
        value={code}
        className="w-100"
      />
    </Modal>
  );
};

export default RedeemCode;
