import { Menu } from 'antd';
import { History } from 'history';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { SimpleBaby, User } from '../../interfaces';
import { AppState } from '../../store';
import { BaseAction } from '../../store/actionTypes';
import { selectBaby } from '../../store/ducks/baby';
import { capitalizeTexts } from '../../utils/string';
import ModalPlus from '../ModalPlus/ModalPlus';
import "./ChildMenu.scss"
type Props = {
  isPlus: boolean;
  simpleBabies: SimpleBaby[];
  selectBabyIndex: Function;
  setDropdownVisible: Function;
  babySelectedIndex?: number;
  history: History<any>;
  user: User;
};

const ChildMenu: React.FC<Props> = ({
  isPlus,
  simpleBabies,
  selectBabyIndex,
  babySelectedIndex,
  setDropdownVisible,
  history,
  user,
}) => {
  const [showPlus, setShowPlus] = useState(false);
  const sendToUpgradeToPlus = () => {
    history.push("/cart/subscription/yearly");
  }

  return (
    <>
      <Menu
        selectedKeys={
          babySelectedIndex !== undefined
            ? [babySelectedIndex.toString()]
            : undefined
        }
      >
        {simpleBabies.map((baby: SimpleBaby, index: number) => (
          <Menu.Item
          className='child-menu'
            key={index}
            onClick={() => {
              if (index !== babySelectedIndex) {
                selectBabyIndex(index);
              }

              setDropdownVisible(false);
            }}
          >
            {
              baby.baby_first_name.length > 25
                ? baby.baby_first_name.substr(0, 22) + '...'
                : baby.baby_first_name
            }
          </Menu.Item>
        ))}

        {!user.guest_user && (
          <Menu.Item
            key={'-1'}
            onClick={() => {
              if (isPlus || simpleBabies.length === 0) {
                setDropdownVisible(false);
                history.push('/save-child')
              } else {
                setDropdownVisible(false);
                setShowPlus(true);
              }
            }}
          >
            Add new
          </Menu.Item>
        )}
      </Menu>
      <ModalPlus visible={showPlus} onClose={() => setShowPlus(false)} manageChildrenMsg={user.user_level === 0} onSaveBeforeUpgradeToPlus={sendToUpgradeToPlus} />
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  isPlus: !!state.user.user_level && state.user.user_level === 1,
  simpleBabies: state.baby.simpleBabies,
  babySelectedIndex: state.baby.babySelectedIndex,
  user: state.user,
});

const mapDispatchToProps = (dispatch: Dispatch<BaseAction>) => ({
  selectBabyIndex: (index: number) => dispatch(selectBaby(index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChildMenu);
