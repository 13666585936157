import cloneDeep from 'lodash/cloneDeep';
import { call, put, select } from 'redux-saga/effects';
import { AppState } from '..';
import api from '../../api';
import { Baby, DashboardInfo, Entry } from '../../interfaces';
import { isArrayEqual } from '../../utils/array';
import { parseRequestError } from '../../utils/error';
import { BabyState, getBabies, selectBaby } from '../ducks/baby';
import { DashboardState, getDashboard } from '../ducks/dashboard';
import { fetchBabies } from './baby';

/**
 * This function fetch all Dashboard info
 **/
export function* fetchDashboard(goPersonalAccount: boolean = false, callback?: (error: string | null) => void) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  const mainUserId = yield select(
    (state: AppState) => state.user.main_user?.id
  );

  let queryParms = '';
  if (mainUserId && !goPersonalAccount) queryParms = `?main_user_id=${mainUserId}`;

  try {
    const response = yield call(api.get, `api/dashboard${queryParms}`, {
      headers: {
        Authorization: accessToken,
      },
    });

    if (response.data && response.data.code === 200) {
      const data = response.data.data;
      const dashboard: DashboardState = yield select(
        (state: AppState) => state.dashboard
      );

      if (!isArrayEqual(dashboard.dashboard, data)) {
        yield put(getDashboard(data));
      }
    }

    if (callback) callback(null);
  } catch (error) {
    yield put(getDashboard([]));
    if (callback) callback(parseRequestError(error));
  }
}

/**
 * Save an entry on DB
 **/
export function* saveEntryDashboard(
  entry: Entry,
  callback: (error: string | null) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    let finalData : any;
    if(entry.isEdit) {
      if(entry.height > 0) {
        const response = yield call(api.put, 'api/update-entry?lang=en', {...entry, entry_id: entry.height_id}, {
          headers: {
            Authorization: accessToken,
          },
        });
        if (response.data && response.data.code === 200) {
          finalData = response.data.data;
        }
      } if (entry.weight_lbs > 0 || entry.weight_oz > 0) {
        const response = yield call(api.put, 'api/update-entry?lang=en', {...entry, entry_id: entry.weight_id}, {
          headers: {
            Authorization: accessToken,
          },
        });
        if (response.data && response.data.code === 200) {
          finalData = response.data.data;
        }
      }
    } else {
      const response = yield call(api.post, 'api/add-entry?lang=en', entry, {
        headers: {
          Authorization: accessToken,
        },
      });
      if (response.data && response.data.code === 200) {
        finalData = response.data.data;
      }
    }
    if (!!finalData) {
      const {
        dashboard,
        baby,
      }: { dashboard: DashboardState; baby: BabyState } = yield select(
        (state: AppState) => ({
          dashboard: state.dashboard,
          baby: state.baby,
        })
      );

      // Update Dashboard
      yield put(
        getDashboard(
          dashboard.dashboard.map((each: DashboardInfo) => {
            if (each.id === finalData.id) {
              return { ...each, heights: finalData.heights, weights: finalData.weights };
            }
            return each;
          })
        )
      );

      // Update Babies
      yield put(
        getBabies(
          baby.babies.map((each: Baby) => {
            if (each.id === finalData.id) {
              return { ...each, heights: finalData.heights, weights: finalData.weights };
            }
            return each;
          })
        )
      );

      // Update Selected Baby
      yield put(selectBaby(baby.babySelectedIndex));
    }

    callback(null);
  } catch (error) {
    callback(parseRequestError(error));
  }
}

/**
 * Delete an Entry
 **/
export function* deleteEntryDashboard(
  heightId: number,
  weightId: number,
  callback: (error: string | null, description?: string) => void
) {
  const accessToken = yield select(
    (state: AppState) => state.user.access_token
  );

  if (!accessToken) return;

  try {
    const response = yield call(
      api.delete,
      `api/delete-entry/${heightId}/${weightId}`,
      {
        headers: {
          Authorization: accessToken,
        },
      }
    );

    if (response.data && response.data.code === 200) {
      const {
        dashboard,
        babySelectedIndex,
      }: {
        dashboard: DashboardState;
        babySelectedIndex?: number;
      } = yield select((state: AppState) => ({
        dashboard: state.dashboard,
        babySelectedIndex: state.baby.babySelectedIndex,
      }));

      if (babySelectedIndex !== undefined) {
        const newDashboard = cloneDeep(dashboard.dashboard);

        const heightIndex = newDashboard[babySelectedIndex].heights.findIndex(
          h => h.id === heightId
        );
        const weightIndex = newDashboard[babySelectedIndex].weights.findIndex(
          h => h.id === weightId
        );

        if (heightIndex !== -1) {
          newDashboard[babySelectedIndex].heights.splice(heightIndex, 1);
        }

        if (weightIndex !== -1) {
          newDashboard[babySelectedIndex].weights.splice(weightIndex, 1);
        }

        yield put(getDashboard(newDashboard));
        yield call(fetchBabies);
        callback(null, response.data.message);
        return;
      }

      yield call(fetchDashboard);
      yield call(fetchBabies);

      callback(null, response.data.message);
    } else {
      throw new Error('Error removing Entry');
    }
  } catch (error) {
    callback(parseRequestError(error));
  }
}
