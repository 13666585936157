import { Modal, Button } from "antd";
import React from "react";
import bleedExample from "../../assets/img/bleedtestline.png"
import "./BleedModal.scss"

interface Props {
    visible: boolean;
    onClose: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}


const BleedModal: React.FC<Props> = ({visible, onClose}) => {

    if(localStorage.BPcreated) {
        visible = !visible
    }

    return (
        <Modal 
        centered
        visible={visible}
        footer={null}
        onCancel={onClose}
        width="auto" >
            <>
            <div className="d-flex flex-column justify-content-center">
                    <img src={bleedExample} className="mt-4 bleed-img mx-auto" />
                    <p className="mt-4 w-75 text-center mx-auto " style={{fontFamily:"ProximaNovaLight", fontWeight:700}}>
                        Be sure to keep important details within the dashed line to avoid trimming during the printing process.
                    </p>
            </div>
            </>
        </Modal>
    )

}


export default BleedModal;